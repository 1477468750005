<template>
    <div class>
        <el-dialog
            :visible="info.isShow"
            @close="handleClose"
            :close-on-click-modal="false"
            width="800px"
            title="结算信息修改"
        >
            <el-card class="box-card" shadow="hover" v-loading="initLoading">
                <el-form
                    :inline="true"
                    :model="bankForm"
                    :rules="rules"
                    ref="bankForm"
                    label-position="right"
                    label-width="140px"
                >
                    <!-- 结算信息 -->
                    <template>
                        <div class="m-title">结算信息</div>
                        <el-form-item label="代理商角色">
                            <el-select
                                size="medium"
                                v-model="bankForm.role_id"
                                clearable
                                disabled
                                placeholder="请选择代理角色"
                            >
                                <el-option
                                    v-for="item in roleList"
                                    :label="item.name"
                                    :value="item.id"
                                    :key="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="代理商名称">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                disabled
                                v-model="bankForm.agent_name"
                                placeholder="请输入代理商名称"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="银行账户名称">
                            <el-input
                                size="medium"
                                disabled
                                suffix-icon="el-icon-aux"
                                v-model="bankForm.account_name"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="账户类型">
                            <el-select
                                size="medium"
                                v-model="bankForm.account_type"
                                disabled
                                placeholder="请选择账户类型"
                            >
                                <el-option label="对私" :value="1"></el-option>
                                <!-- <el-option label="对公" :value="2"></el-option> -->
                            </el-select>
                        </el-form-item>
                        <el-form-item label="银行名称" prop="bank_id">
                            <el-select
                                size="medium"
                                v-model="bankForm.bank_id"
                                filterable
                                @change="handleBankChange"
                                placeholder="请选择银行名称"
                            >
                                <el-option
                                    v-for="item in bankList"
                                    :label="item.bank_name"
                                    :value="item.id"
                                    :key="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="银行归属地区" prop="bank_prov">
                            <el-cascader
                                separator="-"
                                filterable
                                :disabled="bankForm.bank_id == ''"
                                v-model="areaTempArr"
                                :options="areaList"
                                :props="cascaderProps"
                                placeholder="请选择银行归属地区"
                                @change="handleAreaChange"
                            ></el-cascader>
                        </el-form-item>
                        <el-form-item label="结算支行名称" prop="branch_bank_id">
                            <el-select
                                size="medium"
                                v-model="bankForm.branch_bank_id"
                                filterable
                                :disabled="bankForm.bank_prov == ''"
                                @change="handleBranchChange"
                                placeholder="请选择结算支行名称"
                            >
                                <el-option
                                    v-for="item in branchList"
                                    :label="item.bank_branch"
                                    :value="item.id"
                                    :key="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="联行号">
                            <el-input
                                size="medium"
                                disabled
                                suffix-icon="el-icon-aux"
                                v-model="bankForm.line_num"
                                placeholder="请输入联行号"
                            ></el-input>
                        </el-form-item>-->
                        <el-form-item label="银行账号" prop="bank_code">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                v-model="bankForm.bank_code_show"
                                placeholder="请输入银行账号"
                                @blur="checkField(bankForm.bank_code_show,'bank_code')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="银行卡预留手机" prop="bankcard_phone">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                v-model="bankForm.bankcard_phone_show"
                                placeholder="请输入银行卡预留手机"
                                @blur="checkField(bankForm.bankcard_phone_show,'bankcard_phone')"
                            ></el-input>
                        </el-form-item>
                        <br />
                        <el-form-item>
                            <el-button
                                type="primary"
                                :loading="loading"
                                style="font-size: 12px;"
                                @click="handleSubmit(bankForm)"
                            >保存设置</el-button>
                        </el-form-item>
                    </template>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import areaList from '../../../utils/area';
import rules from './addAgentValid';
export default {
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            initLoading: false, // 初始化loading
            loading: false, // 提交loading
            roleList: [], // 角色列表
            bankList: [], // 银行列表
            bankSign: '', // 选中银行的标识
            branchList: [], // 银行支行列表
            areaList, // 省市区数据
            areaTempArr: [], // 省市区列表
            cascaderProps: { // 省市区指定属性
                value: 'areaName',
                label: 'areaName'
            },
            rules: rules,
            bankForm: {
                id: '', // 代理商id
                bank_id: '', // 银行id
                // branch_bank_id: '', // 支行id
                // line_num: '', // 联行号
                bank_code: '', // 银行账号
                // bank_prov: '', // 开户省
                // bank_city: '', // 开户市
                // bank_area: '', // 开户区
                // branch_bank: '', // 支行名称
                bankcard_phone: '' // 银行卡预留手机号
            }
        }
    },
    created() {
        this.getData()
        this.getRole(); //获取代理商列表
        this.getBank(); //获取银行列表
    },
    mounted() {
    },
    methods: {
        handleClose(ref) {
            this.$emit('infoClose', ref)
        },
        handleSubmit(bankForm) { //后台提交
            this.$refs.bankForm.validate(valid => {
                if (valid) {
                    this.loading = true; //防重复
                    this.$api.post('agent/update_agent_bank', bankForm, res => {
                        let that = this;
                        if (res.code == 0) {
                            this.$message({
                                type: 'success',
                                message: res.msg,
                                duration: 1500,
                                onClose() {
                                    that.loading = false;
                                    that.handleClose(1);
                                }
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.msg,
                                duration: 1500,
                                onClose() {
                                    that.loading = false;
                                }
                            });
                        }
                    })
                }
            })
        },
        checkField(n, v) {
            if (n.indexOf('*') == -1) { // 判断内容中是否存*
                this.bankForm[v] = n;
            }
        },
        handleAreaChange(areaArr) { // 处理银行归属地区数据
            if (areaArr.length != 0) {
                this.bankForm.bank_prov = areaArr[0];
                this.bankForm.bank_city = areaArr[1];
                this.bankForm.bank_area = areaArr[2];
                if (this.bankForm.bank_id) {
                    this.getBranch();
                }
            } else {
                this.bankForm.bank_prov = '';
                this.bankForm.bank_city = '';
                this.bankForm.bank_area = '';
            }
        },
        handleBankChange(bankVal) { // 处理银行选中时获取值
            if (bankVal) {
                let bank = this.bankList.filter(item => {
                    return item.id == bankVal;
                });
                this.bankSign = bank[0].bank_symbol;
                if (this.bankForm.bank_prov) { //银行和省不为空的情况选择银行可取支行
                    this.getBranch();
                }
            } else {
                this.areaTempArr = []; // 清空银行时将归属地清空
                this.bankForm.bank_prov = '';
                this.bankForm.bank_city = '';
                this.bankForm.bank_area = '';
            }
        },
        handleBranchChange(branchVal) { // 支行选中的处理
            if (branchVal) {
                let branchLineNum = this.branchList.filter(item => {
                    return item.id == branchVal;
                });
                this.bankForm.line_num = branchLineNum[0].line_num; // 联行号
                this.bankForm.branch_bank = branchLineNum[0].bank_branch; // 支行名称
            }
        },
        getBranch(isInit) { // 获取支行
            this.$api.post('bank/branch', { bank_symbol: this.bankSign, bank_prov: this.bankForm.bank_prov }, res => {
                if (res.code == 0) {
                    if (!isInit) { // 不是初始化的时候才会清空
                        this.bankForm.branch_bank_id = '';
                        this.bankForm.line_num = '';
                    }
                    this.branchList = [];
                    this.branchList = res.data.bank
                } else {
                    this.$message.error('获取支行信息失败，请重新选择银行或归属地进行获取!');
                }
            });
        },
        getRole() { // 获取代理商角色
            this.$api.post("agent/get_role_list", {}, res => {
                if (res.code == 0) {
                    this.roleList = res.data;
                }
            })
        },
        getBank() { // 获取银行列表
            this.$api.post("bank/list", {}, res => {
                if (res.code == 0) {
                    this.bankList = res.data;
                }
            })
        },
        getData() { // 获取结算信息
            this.initLoading = true;
            return new Promise((resolve) => {
                this.$api.post('agent/get_update_back', { id: this.info.row.id, type: 2 }, res => {
                    if (res.code == 0) {
                        let data = res.data;
                        this.bankForm = { ...this.bankForm, ...data };
                        this.bankSign = data.bank_symbol; // 银行标识
                        this.areaTempArr.push(data.bank_prov, data.bank_city, data.bank_area); // 归属地
                        resolve(data);
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.initLoading = false;
                })
            })

        }
    }
}
</script>

<style scoped lang="scss">
.el-form-item {
    margin-bottom: 22px;
}
.m-title {
    color: #303133;
    font-size: 14px;
    font-weight: 700;
    padding-left: 10px;
    margin: 10px 0;
    border-left: 4px solid #409eff;
}
</style>
