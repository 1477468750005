<template>
    <div class="UM_blockedList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="blockedForm" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="blockedForm.phone" placeholder="请输入代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="blockedForm.real_name"
                        placeholder="请输入代理商真实姓名"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item>
                    <el-select
                        size
                        v-model="blockedForm.blockedMethod"
                        clearable
                        placeholder="请选择冻结方式"
                    >
                        <el-option label="登录冻结" value="0"></el-option>
                        <el-option label="提现冻结" value="1"></el-option>
                        <el-option label="划拨冻结" value="2"></el-option>
                    </el-select>
                </el-form-item>-->
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="代理商冻结开始日期"
                        end-placeholder="代理商冻结结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(blockedForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-s-promotion" @click>占位</el-button>
            </div>-->
            <el-table
                :data="blockedTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 真实姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="真实姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 邀请码
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rateMethod"
                    label="邀请码"
                    min-width="120"
                ></el-table-column>-->
                <!-- 昵称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="nickname"
                    label="昵称"
                    min-width="120"
                ></el-table-column>
                <!-- 冻结方式 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="type"
                    label="冻结方式"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 1">提现冻结</span>
                        <span v-else>登录冻结</span>
                    </template>
                </el-table-column>
                <!-- 冻结原因 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="reason"
                    label="冻结原因"
                    min-width="120"
                ></el-table-column>
                <!-- 冻结时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="冻结时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作人 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="admin_id"
                    label="操作人"
                    min-width="120"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-popconfirm title="是否解除冻结状态？" @confirm="handleUnblocked(scope.row)">
                            <el-button type="text" size="small" slot="reference">解冻</el-button>
                        </el-popconfirm>&nbsp;
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
export default {
    name: "UM_blockedList",
    data() {
        return {
            rangeDate: '',
            blockedFlag: true,
            blockedForm: {
                phone: '',
                real_name: '',
                start_create_time: '',
                end_create_time: '',
                page: 1,
                limit: 10,
            },
            blockedTable: [],
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true
        }
    },
    mounted() {
        this.getData({
            page: this.page.currentPage,
            limit: this.page.pageSize
        });
    },
    components: {
        paginations
    },
    methods: {
        onSearch(blockedForm) {
            this.getData(blockedForm);
        },
        handleUnblocked(row) { //解冻
            let { id, user_id, type } = row;
            if (this.blockedFlag) {
                this.blockedFlag = false;
                this.$api.post('user/unfreeze', { id, user_id, type }, res => {
                    if (res.code == 0) {
                        this.$message.success(res.msg);
                        this.blockedFlag = true;
                        this.getData(this.blockedForm);
                    } else {
                        this.blockedFlag = true;
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.blockedForm.page = this.page.currentPage;
            this.blockedForm.limit = this.page.pageSize;
            this.getData(this.blockedForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.blockedForm.page = this.page.currentPage;
            this.blockedForm.limit = limit;
            this.getData(this.blockedForm);
        },
        getData(params) {
            this.$api.post("user/freeze_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.blockedTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        pickerChange(DateArr) { //时间选择器
            this.blockedForm.start_create_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.blockedForm.end_create_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
};
</script>

<style lang="scss" scope>
.UM_blockedList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
