<template>
    <div class="BS_PlatformSetting">
        <el-card class="box-card" shadow="hover">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="后台系统设置" name="site">
                    <el-form
                        :model="siteForm"
                        label-position="right"
                        label-width="100px"
                        width="500"
                    >
                        <el-form-item label="系统名称：">
                            <el-input
                                size="medium"
                                v-model="siteForm.site_title"
                                :maxlength="8"
                                placeholder="请输入系统名称"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="邀请码配置：">
                            <el-input
                                :disabled="isEdit"
                                size="medium"
                                v-model="siteForm.invitation_code_prefix"
                                :maxlength="2"
                                placeholder="请定义邀请码前1-2位字母"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                    <el-alert style="font-size:12px;" title="提示 !" type="error" :closable="false">
                        <span slot>邀请码为字母+数字组成，首字母最多定义2位，后6位数字随机生成，不允许修改。</span>
                    </el-alert>
                    <div style="margin-top:20px">
                        <el-button
                            type="primary"
                            :loading="siteLoading"
                            style="font-size: 12px;"
                            @click="siteSubmit(siteForm)"
                        >保存设置</el-button>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="APP前端设置" name="app">
                    <el-form :model="appForm" label-position="right" label-width="80px">
                        <!-- <el-form-item label="APP名称：">
                            <el-input
                                size="medium"
                                v-model="appForm.app_name"
                                :maxlength="7"
                                placeholder="请输入APP名称"
                            ></el-input>
                        </el-form-item>-->
                        <el-form-item label="客服电话：">
                            <el-input
                                size="medium"
                                v-model="appForm.app_consumer_service_tel"
                                placeholder="请输入客服电话"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="服务时间：">
                            <el-input
                                size="medium"
                                v-model="appForm.app_business_hours"
                                placeholder="请输入服务时间"
                            ></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="启动页：">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :show-file-list="false"
                                :on-success="handleStartImg"
                            >
                                <div slot="tip" class="el-upload__tip">
                                    <span>支持JPEG、PNG格式文件，建议尺寸1080X1920像素</span>
                                </div>
                                <img v-if="viewImg.startImg" :src="viewImg.startImg" class="avatar" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>-->
                        <el-form-item label="注册背景：">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :show-file-list="false"
                                :on-success="handleRegisterImg"
                            >
                                <div slot="tip" class="el-upload__tip">
                                    <span>支持JPEG、PNG格式文件，建议尺寸1080X1920像素</span>
                                </div>
                                <img
                                    v-if="viewImg.registerImg"
                                    :src="viewImg.registerImg"
                                    class="avatar"
                                />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="下载背景：">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :show-file-list="false"
                                :on-success="handleDownloadImg"
                            >
                                <div slot="tip" class="el-upload__tip">
                                    <span>支持JPEG、PNG格式文件，建议尺寸1080X1920像素</span>
                                </div>
                                <img
                                    v-if="viewImg.downloadImg"
                                    :src="viewImg.downloadImg"
                                    class="avatar"
                                />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                    <div style="margin-top:20px">
                        <el-button
                            type="primary"
                            :loading="appLoading"
                            style="font-size: 12px;"
                            @click="appSubmit(appForm)"
                        >保存设置</el-button>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isEdit: false, // 邀请码配置是否可编辑
            uploadUrl: '',
            activeName: 'site', //默认显示的tab
            siteLoading: false, //后台防重复
            appLoading: false, //app防重复
            viewImg: { //展示的地址
                startImg: '',
                registerImg: '',
                downloadImg: '',
            },
            siteForm: {}, //后台设置
            appForm: {} //app设置
        }
    },
    mounted() {
        this.uploadUrl = this.$api.uploadUrl;//上传文件地址
        this.getInfo({ k: 'app' });//初始化
        this.getInfo({ k: 'site' });//初始化
    },
    methods: {
        handleClick() {

        },
        siteSubmit(siteForm) { //后台提交
            this.siteLoading = true; //防重复
            siteForm.k = 'site';
            this.$api.post('setting/update_setting', siteForm, res => {
                let that = this;
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.siteLoading = false;
                            that.getInfo({ k: 'site' });
                        }
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.siteLoading = false;
                        }
                    });
                }
            })
        },
        appSubmit(appForm) { //app提交
            this.appLoading = true; //防重复
            let reqData = this._.omit(appForm, ['app_start_page_url', 'app_register_page_url', 'app_download_page_url']);
            reqData.k = 'app';

            this.$api.post('setting/update_setting', reqData, res => {
                let that = this;
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.appLoading = false;
                            that.getInfo({ k: 'app' });
                        }
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.appLoading = false;
                        }
                    });
                }
            })
        },
        handleStartImg(res) { //启动页
            this.upImg({
                res,
                img: 'startImg',
                path: 'app_start_page'
            });
        },
        handleRegisterImg(res) { //注册页
            this.upImg({
                res,
                img: 'registerImg',
                path: 'app_register_page'
            });
        },
        handleDownloadImg(res) { //下载页
            this.upImg({
                res,
                img: 'downloadImg',
                path: 'app_download_page'
            });
        },
        getInfo(reqData) { //获取详情信息
            this.$api.post('setting/getSetting', reqData, res => {
                if (res.code == 0) {
                    if (reqData.k == "app") {
                        this.appForm = res.data;
                        let { app_start_page_url, app_register_page_url, app_download_page_url } = res.data;
                        this.viewImg.startImg = app_start_page_url;
                        this.viewImg.registerImg = app_register_page_url;
                        this.viewImg.downloadImg = app_download_page_url;
                    } else {
                        this.siteForm = res.data;
                        this.isEdit = res.data.invitation_code_prefix == '' ? false : true;
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        upImg(res) { //公用
            let resData = res.res;
            if (resData.code === 0) {
                this.viewImg[res.img] = resData.data.file_url; //展示路径
                this.appForm[res.path] = resData.data.file_path //请求路径
            } else {
                //错误提示
                this.$message.error(res.msg);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.BS_PlatformSetting {
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background-color: #fdfdfd;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 40px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }
    .avatar {
        width: 120px;
        height: 120px;
        display: block;
    }
    .el-form {
        width: 500px;
    }
}
</style>
