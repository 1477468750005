<template>
    <div class="HC_helpInfo">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <el-card shadow="hover" style="min-width:500px">
            <el-form
                :model="infoForm"
                class="demo-form-inline"
                label-position="left"
                label-width="80px"
            >
                <el-form-item label="标题：">
                    <el-input
                        clearable
                        v-model="infoForm.title"
                        placeholder="请输入标题"
                        style="width:500px"
                    ></el-input>
                    <br />
                    <div style="font-size:12px;color:#606266;">3-15位，可由汉字，字母和数字，特殊符号组成，且不能以空格开头。</div>
                </el-form-item>
                <el-form-item label="所属分类：">
                    <el-select
                        size
                        v-model="infoForm.category_id"
                        clearable
                        placeholder="请选择所属分类"
                        style="width:500px"
                    >
                        <el-option
                            v-for="item in classList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序：">
                    <el-input
                        clearable
                        v-model="infoForm.sort"
                        placeholder="请输入序号"
                        style="width:500px"
                    ></el-input>
                </el-form-item>
                <el-form-item label="内容：">
                    <tinymce ref="editor" :value="infoForm.path" @getVal="getVal" />
                </el-form-item>
                <el-form-item label="状态：">
                    <el-radio v-model="infoForm.status" :label="1">显示</el-radio>
                    <el-radio v-model="infoForm.status" :label="0">隐藏</el-radio>
                </el-form-item>
            </el-form>
            <div style="margin-top:15px;">
                <el-button type="primary" @click="onSubmit(infoForm)" :loading="loading">保&nbsp;存</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import tinymce from '../../../components/Editor/Editor';
export default {
    name: "HC_helpInfo",
    data() {
        return {
            classList: [],
            type: null, //0.添加 1.编辑
            infoForm: {
                title: '', //文章标题
                category_id: '', //所属分类
                path: '', //内容
                sort: '', //排序
                status: 1, //状态
            },
            loading: false //按钮loading
        }
    },
    components: {
        tinymce
    },
    mounted() {
        let { type, id } = this.$route.query;
        this.type = type; //其他地方会用到
        if (type == 1) { //编辑
            this.getData({ id });
        }
        this.getClass(); //获取分类数据
    },
    methods: {
        onSubmit(infoForm) { //提交
            if (this.type == 0) {
                this.saveArticle('help/add', infoForm);
            } else {
                this.saveArticle('help/edit', infoForm);
            }
        },
        getVal(val) { //富文本的值
            this.infoForm.path = val;
        },
        getData(params) { //获取详情
            this.$api.post('help/info', params, res => {
                if (res.code == 0) {
                    this.infoForm = res.data;
                    this.imgPath = res.data.image_path;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        saveArticle(url, params) { //请求
            this.loading = true;
            let that = this
            this.$api.post(url, params, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() { //关闭
                            that.$router.back();
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            })
        },
        getClass() { //获取分类
            this.$api.post("help/category_list", {}, res => {
                if (res.code == 0) {
                    this.classList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        radioChange() {
            this.infoForm.path = ''; //清空path
        }
    }
}
</script>

<style scoped lang="scss">
.HC_helpInfo {
    ::v-deep .el-form-item__label {
        font-size: 12px;
        font-weight: 700;
        // line-height: 20px;
    }
    .el-form {
        // max-width: 500px;
    }
    ::v-deep .el-radio__label {
        font-size: 12px;
    }
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background-color: #fdfdfd;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 40px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }
    .avatar {
        width: 200px;
        height: 200px;
        display: block;
    }
}
</style>
