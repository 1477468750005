<template>
    <div class="TerminalMerchantListCloseBind">
        <el-dialog
            title="终端解绑"
            :visible="closeBind.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-card class="box-card" shadow="hover">
                <template>
                    <div class="title">终端&商户信息</div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <span class="labels">终端政策：</span>
                            <span>{{closeBind.row.brand_name}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">终端SN：</span>
                            <span>{{closeBind.row.terminal_sn}}</span>
                        </el-col>
                        <!-- <el-col :span="8">
                            <span class="labels">终端编号：</span>
                            <span>{{closeBind.row.terminal_number}}</span>
                        </el-col> -->
                        <!-- <el-col :span="8">
                            <span class="labels">激活状态：</span>
                            <span>{{closeBind.row.is_active == 1? '已激活': '未激活'}}</span>
                        </el-col>-->
                        <el-col :span="8">
                            <span class="labels">商户编号：</span>
                            <span>{{closeBind.row.merchant_number}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">商户名称：</span>
                            <span>{{closeBind.row.name}}</span>
                        </el-col>
                        <!-- <el-col :span="8">
                            <span class="labels">商户备注手机号：</span>
                            <span>{{closeBind.row.tel}}</span>
                        </el-col> -->
                        <!-- <el-col :span="8">
                            <span class="labels">商户备注名称：</span>
                            <span>{{closeBind.row.nickname}}</span>
                        </el-col> -->
                        <el-col :span="8">
                            <span class="labels">绑定时间：</span>
                            <span>{{closeBind.row.create_time}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">归属代理商账号：</span>
                            <span>{{closeBind.row.phone}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">归属代理商姓名：</span>
                            <span>{{closeBind.row.real_name}}</span>
                        </el-col>
                        <!-- <el-col :span="8">
                            <span class="labels">激活时间：</span>
                            <span>{{closeBind.row.active_time}}</span>
                        </el-col>-->
                    </el-row>
                </template>
                <el-button
                    :loading="btnLoading"
                    type="primary"
                    style="font-size: 12px;"
                    @click="handleSubmit(closeBind.row)"
                >确 认 解 绑</el-button>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'TerminalMerchantListCloseBind',
    props: {
        closeBind: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            terminalCollection: {},
            terminalList: [],
            closeBindNum: '',
            closeBindName: "",
            loading: false,
            btnLoading: false // 按钮
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        handleSubmit(newVal) { // 提交
            let _this = this;
            this.$confirm('是否确认进行解绑操作？', '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        let reqData = {
                            terminal_id: newVal.terminal_id,
                            merchant_id: newVal.id
                        };
                        this.$api.post('unbind_terminal/unbind', reqData, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1500,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                        _this.handleClose(1);
                                    }
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg,
                                    duration: 1500,
                                    onClose() {
                                        done();
                                        instance.confirmButtonLoading = false;
                                    }
                                });
                            }
                        });
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleClose(ref) {
            this.$emit('closeBindClose', ref)
        },
        handleSearchTerminal(terminal_sn) { // 搜索终端
            if (terminal_sn != '') {
                let { brand_id, user_id } = this.closeBind.row;
                this.loading = true;
                this.$api.post('terminal/get_user_brand_stock_terminal', { user_id, brand_id, terminal_sn }, res => {
                    if (res.code == 0) {
                        if (res.data.length != 0) {
                            this.terminalList = res.data;
                        }
                    }
                    this.loading = false;
                })
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.TerminalMerchantListCloseBind {
    ::v-deep .el-dialog {
        min-width: 800px;
    }
    ::v-deep .labels {
        font-size: 12px;
        font-weight: 700;
    }
    ::v-deep .note {
        font-size: 10px;
        font-weight: 700;
        color: #ff0000;
    }
    ::v-deep .title {
        margin: 10px 0;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #40b8ff;
        padding: 0 0 0 10px;
        .el-button--text {
            color: #303133;
            font-size: 13px;
            font-weight: 700;
        }
    }
    ::v-deep .el-row {
        margin: 10px 0;
        font-size: 12px;
        &:last-child {
            margin-bottom: 0;
        }
        .el-col {
            margin: 10px 0;
        }
    }
    ::v-deep .el-table .cell {
        padding: 0;
    }

    ::v-deep .el-table--small td,
    .el-table--small th {
        padding: 0;
    }
    .tableScope {
        display: flex;
        flex-direction: column;
        div {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            ::v-deep .el-input__inner {
                text-align: center;
                font-size: 12px;
            }
        }
        :not(:last-child) {
            border-bottom: 1px solid #ebeef5;
        }
        .isNull {
            background-color: #f5f7fa;
        }
        .notNull {
            background-color: none;
        }
    }
}
</style>
