<template>
    <div class="bigBoss">
        <el-dialog
            title="详情"
            width="550px"
            :visible="infoIsShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <div class="title">
                <p>
                    <span>终端类型名称：</span>
                    <span>{{detail.brandName}}</span>
                </p>
                <p>
                    <span>出入/入库台数：</span>
                    <span>{{detail.terminalNum}}</span>
                </p>
            </div>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="terminal_sn" placeholder="请输入SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(terminal_sn)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
            <el-table
                :data="queryInfoList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- Sn -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="sn"
                    label="SN码"
                    min-width="120"
                ></el-table-column>
                <!-- 出库/入库时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="出库/入库时间"
                    min-width="120"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                small
                layout="prev, pager, next"
                @current-change="getCurrent"
                :total="page.total"
            ></el-pagination>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "MAM_queryInfo",
    props: {
        infoIsShow: {
            type: Boolean,
            default: false
        },
        listId: {
            type: [String, Number]
        },
        detail: {
            type: Object
        }
    },
    data() {
        return {
            terminal_sn: '',
            queryInfoList: [],
            title: {
                brandName: '',
                terminalNum: ''
            },
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: false
        };
    },
    mounted() {
        this.getData({
            page: this.page.currentPage,
            limit: this.page.pageSize,
            id: this.listId
        });
    },
    methods: {
        onSearch(terminal_sn) {
            this.loading = true;
            this.getData({
                page: 1,
                limit: this.page.pageSize,
                id: this.listId,
                terminal_sn
            });
        },
        handleClose(ref) { //关闭弹窗
            this.$emit('infoClose', ref);
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.loading = true;
            this.getData({
                page: this.page.currentPage,
                limit: this.page.pageSize,
                id: this.listId
            });
        },
        getData(params) { //获取数据
            this.$api.post('terminal_in_out/get_terminal_in_out_detail', params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.queryInfoList = data.list; //列表
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style scoped lang="scss">
.bigBoss {
    font-size: 12px;
    .el-input__inner,
    .tips {
        font-size: 12px;
    }

    .el-form-item {
        margin-bottom: 5px;
    }

    ::v-deep .el-form-item__label {
        padding: 0;
        font-size: 12px;
    }
    .titleTip {
        padding: 10px 10px;
        background-color: #ecf8ff;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #50bfff;
        margin: 10px 0;
    }
    .title p {
        font-size: 12px;
        :first-child {
            font-weight: 700;
        }
    }
}
</style>