<template>
    <div class="MAM_terminalInfo">
        <el-dialog
            title="终端&商户详情"
            :visible="detail.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-card class="box-card" shadow="hover">
                <div class="title">基础信息</div>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <span class="labels">终端类型：</span>
                        <span>{{detail.info.brand_name}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">终端SN：</span>
                        <span>{{detail.info.terminal_sn}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">终端编号：</span>
                        <span>{{detail.info.terminal_number}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">激活状态：</span>
                        <span>{{detail.info.is_active == 1? '已激活': '未激活'}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">商户编号：</span>
                        <span>{{detail.info.merchant_number}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">商户名称：</span>
                        <span>{{detail.info.name}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">商户备注手机号：</span>
                        <span>{{detail.info.tel}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">商户备注名称：</span>
                        <span>{{detail.info.nickname}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">创建时间：</span>
                        <span>{{detail.info.create_time}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">归属代理商账号：</span>
                        <span>{{detail.info.phone}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">归属代理商姓名：</span>
                        <span>{{detail.info.real_name}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">激活时间：</span>
                        <span>{{detail.info.active_time}}</span>
                    </el-col>
                </el-row>
                <div class="title">终端费率</div>
                <div class="rate-title">
                    <el-alert :title="rateCode" type="info" :closable="false"></el-alert>
                </div>
                <el-tabs v-model="activeName" type="border-card">
                    <el-tab-pane
                        v-for="(item,index) in resData.config"
                        :label="item.title"
                        :name="item.title"
                        :key="index"
                    >
                        <el-table
                            :data="item.list"
                            max-height="300px"
                            align="center"
                            size="small"
                            border
                            style="width: 100%; margin-top: 20px"
                        >
                            <el-table-column align="center" prop="title" label="交易方式" width="180"></el-table-column>
                            <el-table-column align="center" label="说明" min-width="150">
                                <template slot-scope="scope">
                                    <div class="tableScope">
                                        <div
                                            class="tableTitle first"
                                            v-if="scope.row.base_rate != 0"
                                        >基础费率（%）</div>
                                        <div
                                            class="tableTitle second"
                                            v-if="scope.row.overrun_cap != 0 && scope.row.overrun_type == 2"
                                        >交易封顶（元）</div>
                                        <div
                                            class="tableTitle second"
                                            v-if="scope.row.overrun_rate != 0 && scope.row.overrun_type == 1"
                                        >交易封顶（%）</div>
                                        <div
                                            class="tableTitle third"
                                            v-if="scope.row.sec_fee != 0"
                                        >秒到费用（元/笔）</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="当前费率" min-width="150">
                                <template slot-scope="scope">
                                    <div class="tableScope">
                                        <!-- 基础费率 -->
                                        <div
                                            class="first"
                                            v-if="scope.row.base_rate != 0"
                                        >{{scope.row.base_rate}}</div>
                                        <!-- 交易封顶-费率 -->
                                        <div
                                            class="second"
                                            v-if="scope.row.overrun_rate != 0 && scope.row.overrun_type == 1"
                                        >{{scope.row.overrun_rate}}</div>
                                        <!-- 交易封顶-元 -->
                                        <div
                                            class="second"
                                            v-if="scope.row.overrun_cap != 0 && scope.row.overrun_type == 2"
                                        >{{scope.row.overrun_cap}}</div>
                                        <!-- 秒到费用 -->
                                        <div
                                            class="third"
                                            v-if="scope.row.sec_fee != 0"
                                        >{{scope.row.sec_fee}}</div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "MAM_terminalInfo",
    props: {
        detail: {
            type: Object
        }
    },
    data() {
        return {
            id: null,
            resData: {},
            activeName: 'T+0',
        }
    },
    computed: {
        rateCode() { // 模板
            if (JSON.stringify(this.resData) == '{}') {
                return '';
            } else {
                return this.resData.info.rate_code_name;
            }
        }
    },
    mounted() {
        let { brand_id, terminal_id, user_id } = this.detail.info;
        this.getInfo({
            brand_id, //终端类型id
            terminal_id, //终端id
            user_id //代理商id
        });
    },
    methods: {
        audit() {
            this.auditIsShow = true;
        },
        handleClose(ref) {
            this.$emit('infoClose', ref)
        },
        getInfo(params) { //获取详情信息
            this.$api.post('merchant/detail', params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.resData = data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    }
};
</script>

<style lang="scss" scope>
.MAM_terminalInfo {
    .rate-title {
        height: 40px;
        margin-bottom: 5px;
    }
    .el-dialog {
        min-width: 700px;
    }
    .labels {
        font-size: 12px;
        font-weight: 700;
    }
    .title {
        margin: 10px 0;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #40b8ff;
        padding: 0 0 0 10px;
        .el-button--text {
            color: #303133;
            font-size: 13px;
            font-weight: 700;
        }
    }
    .el-row {
        margin: 10px 0;
        font-size: 12px;
        &:last-child {
            margin-bottom: 0;
        }
        .el-col {
            margin: 10px 0;
        }
    }
    .el-table .cell {
        padding: 0;
    }
    .el-tabs--border-card > .el-tabs__header .el-tabs__item {
        font-size: 12px;
    }
    .tableScope {
        .tableTitle {
            font-weight: 700;
        }
        line-height: 32px;
        :not(:last-child) {
            border-bottom: 1px solid #ebeef5;
        }
        .first {
            padding-bottom: 8px;
        }
        .second {
            padding: 8px 0;
        }
        .third {
            padding-top: 8px;
        }
    }
}
</style>
