<template>
    <div>
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
                <span>终端统计</span>
            </div>
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item>
                    <el-select
                        size
                        v-model="formInline.realStatus"
                        clearable
                        placeholder="请选择终端类型"
                    >
                        <el-option label="终端类型1" value="0"></el-option>
                        <el-option label="终端类型2" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="formInline.referralsName"
                        placeholder="请输入代理商名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="formInline.referralsPhone"
                        placeholder="请输入代理商账号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSubmit(formInline)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click>导出数据</el-button>
            </div> -->
            <el-table
                :data="terminalTable.list"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brandName"
                    label="终端类型"
                    min-width="120"
                ></el-table-column>
                <!-- 代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="machinePrice"
                    label="代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rateMethod"
                    label="代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 个人终端数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rateMethod"
                    label="个人终端数量"
                    min-width="120"
                ></el-table-column>
                <!-- 个人激活终端数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rateMethod"
                    label="个人激活终端数量"
                    min-width="120"
                ></el-table-column>
                <!-- 个人购买数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rateMethod"
                    label="个人购买数量"
                    min-width="120"
                ></el-table-column>
                <!-- 推荐盟友数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rateMethod"
                    label="推荐盟友数量"
                    min-width="120"
                ></el-table-column>
                <!-- 团队终端数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rateMethod"
                    label="团队终端数量"
                    min-width="120"
                ></el-table-column>
                <!-- 团队激活终端数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rateMethod"
                    label="团队激活终端数量"
                    min-width="120"
                ></el-table-column>
                <!-- 团队购买数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rateMethod"
                    label="团队购买数量"
                    min-width="120"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
export default {
    name: "DM_terminalList",
    data() {
        return {
            formInline: {
                firstName: '', //一级代理姓名
                firstId: '',    //一级代理账号
                realName: '',   //代理商真实姓名
                userName: '',   //代理商账号
                inviteCode: '', //代理商邀请码
                realStatus: '', //实名认证状态
                referralsName: '', // 推荐人真实姓名
                referralsPhone: '', //推荐人手机号
                createTime: '', //代理商注册时间
                loginTime: '',  //最近登录时间
                address: '' //地区

            },
            terminalTable: {
                list: [],
            },
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 100  //总数
            },
            loading: true
        }
    },
    mounted() {
        this.getTableList(this.page.currentPage, this.page.pageSize);
    },
    components: {
        paginations
    },
    methods: {
        onSubmit() {
            // 表单提交
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.loading = true;
            this.getTableList(this.page.currentPage, this.page.pageSize);
        },
        getPageSize(limit) {
            // 获取条数发生改变的值
            this.page.pageSize = limit;
            this.loading = true;
            this.getTableList(this.page.currentPage, limit);
        },
        getTableList(page, limit) {
            this.$api.post(this.$port.brandList, { page, limit }, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.terminalTable.list = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scope>
.el-input__inner {
    font-size: 12px;
}
.el-form-item {
    margin-bottom: 5px;
}
</style>
