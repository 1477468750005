<template>
    <div class="UM_terminalOffset">
        <el-dialog
            title="终端抵消欠款"
            width="1000px"
            :visible="offsetLoanIsShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-form :model="offsetForm" label-position="top">
                <div class="title">
                    <span>当前欠款金额：</span>
                    <span>{{ arrears_money }}</span>
                </div>
                <!-- 终端类型 -->
                <el-form-item label="终端类型：">
                    <el-select
                        size="medium"
                        @change="getTerminal(offsetForm.brand_id)"
                        v-model="offsetForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <!-- 选择终端 -->
                <el-form-item label="选择终端：">
                    <el-form-item>
                        <el-row style="margin-bottom:10px;">
                            <el-col :span="10">
                                <el-input
                                    size="medium"
                                    clearable
                                    v-model="search"
                                    placeholder="搜索终端"
                                ></el-input>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <el-button
                                    size="medium"
                                    type="primary"
                                    icon="el-icon-search"
                                    style="font-size: 12px;"
                                    @click="searchTerminal"
                                >搜 索</el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-transfer
                        :data="terminalList"
                        :props="{key:'id',label:'sn'}"
                        :titles="['借款方库存', '已选']"
                        v-model="offsetForm.terminal_id"
                        v-loading="loading"
                        filter-placeholder="搜索SN码"
                        element-loading-text="拼命加载中"
                        @change="transferChange"
                    ></el-transfer>
                </el-form-item>
                <div class="title">
                    <span>终端单价金额：</span>
                    <span>{{ offsetForm.terminal_price }}</span>
                </div>
                <div class="title">
                    <span>可撤回终端数量：</span>
                    <span>{{ allow_terminal_num }}</span>
                </div>
                <div class="title">
                    <span>已选终端数量：</span>
                    <span>{{ offsetForm.terminal_num }}</span>
                </div>
                <div class="title">
                    <span>可抵消金额：</span>
                    <span>{{ offsetForm.offset_money }}</span>
                </div>
                <el-form-item>
                    <el-button
                        type="primary"
                        style="font-size: 12px;margin-top:10px"
                        @click="submit"
                        :loading="submitLoad"
                    >确 认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "UM_terminalOffset",
    props: {
        offsetLoanIsShow: {
            type: Boolean,
            default: false
        },
        detail: {
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            submitLoad: false,
            brandList: [], //终端类型列表
            terminalList: [], //终端列表
            search: '', //搜索终端的变量
            arrears_money: 0, //欠款金额
            allow_terminal_num: 0, //允许撤回的终端数
            offsetForm: {
                brand_id: '', //终端类型id
                terminal_id: [], //终端id
                terminal_num: 0, //撤回的数量
                offset_money: 0, //抵消金额
                borrow_user_id: this.detail.userId,//借款代理商id
                terminal_price: 0  //终端价格
            }
        };
    },
    mounted() {
        this.arrears_money = this.detail.amount;
        this.getBrand();
    },
    methods: {
        handleClose(ref) { //关闭弹窗
            this.$emit('terminalOffsetClose', ref);
        },
        submit() { //提交
            this.submitLoad = true;
            let reqData = this._.cloneDeep(this.offsetForm);
            reqData.terminal_id = reqData.terminal_id.join(',') //处理终端id
            let that = this;
            this.$api.post('user_offset_log/terminal_withdraw', reqData, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.handleClose(1);
                        }
                    })
                } else {
                    this.$message.error(res.msg);
                    this.submitLoad = false;
                }
            })
        },
        getBrand() {
            this.$api.post('brand/brand_list', {}, res => { //获取终端类型
                if (res.code == 0) {
                    this.brandList = res.data.list;
                } else {
                    this.$$message.success(res.msg);
                }
            });
        },
        getTerminal() { //获取终端 & 文本
            let getTerminalData = {
                brand_id: this.offsetForm.brand_id,
                borrow_user_id: this.detail.userId,
            }
            this.offsetForm.terminal_id = [];
            this.offsetForm.terminal_num = 0;
            this.offsetForm.offset_money = 0;
            this.getTerminalPub(getTerminalData);
        },
        searchTerminal() { //搜索终端
            let getTerminalData = {
                brand_id: this.offsetForm.brand_id,
                borrow_user_id: this.detail.userId,
                condition: this.search,
                select_terminal_id: this.offsetForm.terminal_id
            };
            this.getTerminalPub(getTerminalData);
        },
        transferChange(sel) {
            let allowNum = this.allow_terminal_num;
            if (sel.length > allowNum) {
                this.$message({
                    type: 'error',
                    message: '选中数量不能大于可撤回数量!',
                    duration: 2000
                })
                let selData = this.offsetForm.terminal_id;
                this.offsetForm.terminal_id = selData.splice(0, 18);
            }
            this.offsetForm.terminal_num = this.offsetForm.terminal_id.length;
            this.offsetForm.offset_money = this.offsetForm.terminal_id.length * this.offsetForm.terminal_price;
        },
        getTerminalPub(reqdata) {
            this.loading = true;
            this.$api.post('user_offset_log/terminal_withdraw_info', reqdata, res => {
                let data = res.data;
                if (res.code == 0) {
                    this.arrears_money = data.arrears_money; //欠款金额
                    this.allow_terminal_num = data.allow_terminal_num; //可撤回数量
                    this.offsetForm.terminal_price = data.terminal_price; //终端价格
                    this.terminalList = data.list; //穿梭列表
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.UM_terminalOffset {
    font-size: 12px !important;
    .title {
        margin: 10px 0 10px 0;
        color: #606266;
        font-size: 12px;
        :nth-child(1) {
            font-weight: 700;
        }
    }
    .el-input__inner,
    ::v-deep .el-radio__label {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
        ::v-deep .el-form-item__label {
            padding: 0;
            font-weight: 700;
            font-size: 12px;
        }
        ::v-deep .el-form-item__content {
            line-height: 0;
        }
    }
    ::v-deep .el-checkbox__label {
        font-size: 12px;
    }
    ::v-deep .el-transfer {
        font-size: 12px;
        .el-transfer-panel__header {
            .el-checkbox__label {
                font-size: 13px;
                font-weight: 700;
            }
        }
        .el-transfer-panel{
            width: 40%;
        }
    }
}
</style>