<template>
    <div class="DM_upsideInfo">
        <!-- 倒挂订单审核 -->
        <el-dialog
            title="审核"
            :visible="infoIsShow"
            @close="handleClose"
            :close-on-click-modal="false"
        >
            <el-card class="box-card" shadow="hover">
                <el-row class="rowTitle">
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">挂账类型：</span>
                        <span>{{viewData.type_name}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">终端类型：</span>
                        <span>{{viewData.brand_name}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">SN码：</span>
                        <span>{{viewData.terminal_sn}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">挂账时间：</span>
                        <span>{{viewData.create_time}}</span>
                    </el-col>
                    <div v-if="viewData.type == 1">
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">交易方式：</span>
                            <span>{{viewData.pay_type}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">卡类型：</span>
                            <span>{{viewData.card_type}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">交易金额：</span>
                            <span>{{viewData.transaction_money}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">结算周期：</span>
                            <span>{{viewData.settle_type}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">终端挂账费率：</span>
                            <span>{{viewData.old_terminal_rate}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">终端当前费率：</span>
                            <span>{{viewData.now_terminal_rate}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">费率类型：</span>
                            <span>{{viewData.is_rate_rise == 1? '涨价后' : '涨价前'}}</span>
                        </el-col>
                    </div>
                    <div v-if="viewData.type == 4 || viewData.type == 5">
                        <el-col :span="24">
                            <span class="labels" v-show="viewData.type == 4">达标规则：</span>
                            <span class="labels" v-show="viewData.type == 5">模板：</span>
                            <span>{{viewData.rule}}</span>
                        </el-col>
                    </div>
                </el-row>
                <el-table
                    :data="upsideInfoTable"
                    size="mini"
                    height="200"
                    border
                    style="width: 100%;margin-top:20px"
                    v-loading="loading"
                >
                    <!-- 代理名称 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        fixed
                        prop="username"
                        label="代理名称"
                        min-width="80"
                    ></el-table-column>
                    <!-- 代理手机号 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="phone"
                        label="代理手机号"
                        min-width="120"
                    ></el-table-column>
                    <!-- 层级 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="level"
                        label="层级"
                        min-width="120"
                    ></el-table-column>
                    <!-- 挂账政策 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="old_policy"
                        label="挂账政策"
                        min-width="150"
                    ></el-table-column>
                    <!-- 当前政策 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="now_policy"
                        label="当前政策"
                        min-width="120"
                    ></el-table-column>
                </el-table>
                <el-button
                    size="medium"
                    type="primary"
                    v-if="viewData.is_pass == 1"
                    style="font-size: 12px;margin-top:20px;"
                    @click="submitAudit()"
                >审核通过</el-button>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "DM_upsideInfo",
    props: {
        infoIsShow: {
            type: Boolean,
            default: false
        },
        detail: {
            type: Object
        }
    },
    data() {
        return {
            upsideInfoTable: [], //表格
            viewData: {},
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            id: null,
            loading: true,
        }
    },
    mounted() {
        this.id = this.detail.id;
        this.getData();
    },
    methods: {
        handleClose(ref) {
            this.$emit('infoClose', ref)
        },
        getData() {
            this.$api.post("upside_down/upside_down_detail", { id: this.detail.id, type: this.detail.type }, res => {
                let data = res.data;
                if (res.code == 0) {
                    this.upsideInfoTable = data.list;
                    this.viewData = data;
                    //渲染结束后取消加载
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                    //渲染结束后取消加载
                    this.loading = false;
                }
            })
        },
        submitAudit() { //审核通过
            this.$api.post('upside_down/upside_down_audit', { id: this.detail.id, type: this.detail.type }, res => {
                if (res.code == 0) {
                    let that = this;
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000,
                        onClose() {
                            that.handleClose(1);
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
};
</script>

<style lang="scss" scope>
.DM_upsideInfo {
    .purseTitle {
        font-size: 12px;
        margin: 10px 0;
        color: #909399;
        :nth-child(1) {
            font-weight: 700;
            color: #7f7f7f;
            padding-left: 10px;
            border-left: 4px solid #909399;
        }
    }
    .rowTitle {
        color: #909399;
        font-size: 12px;
        .el-col {
            margin: 5px 0;
        }
        .labels {
            font-weight: 700;
        }
    }
}
</style>
