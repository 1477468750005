<template>
    <div class="rateList">
        <!-- 费率修改记录 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="rateForm" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="rateForm.phone" placeholder="请输入代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="rateForm.name" placeholder="请输入代理商姓名"></el-input>
                </el-form-item>
                <!-- 终端类型 -->
                <el-form-item>
                    <el-select
                        size="medium"
                        v-model="rateForm.brand_id"
                        filterable
                        clearable
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="rateForm.sn" placeholder="请输入机具SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="rateForm.merchant_number"
                        placeholder="请输入商户编号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="rateForm.is_active" clearable placeholder="请选择激活状态">
                        <el-option label="未激活" value="0"></el-option>
                        <el-option label="已激活" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="rateForm.status" clearable placeholder="请选择审核状态">
                        <el-option label="待审核" value="0"></el-option>
                        <el-option label="审核通过" value="1"></el-option>
                        <el-option label="审核拒绝" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="rateForm.type" clearable placeholder="请选择调价方式">
                        <el-option label="手动调整" value="1"></el-option>
                        <el-option label="系统调整" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(rateForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click>占位</el-button>
            </div>-->
            <el-table :data="rateTable" size="mini" border style="width: 100%" v-loading="loading">
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 代理商姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="代理商姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="120"
                ></el-table-column>
                <!-- 终端SN -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_sn"
                    label="终端SN"
                    min-width="200"
                ></el-table-column>
                <!-- 激活状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="active_status"
                    label="激活状态"
                    min-width="150"
                    :formatter="activeStatus"
                ></el-table-column>
                <!-- 商户编号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="merchant_number"
                    label="商户编号"
                    min-width="200"
                ></el-table-column>
                <!-- 商户名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="merchant_name"
                    label="商户名称"
                    min-width="150"
                ></el-table-column>
                <!-- 调价方式 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="type"
                    label="调价方式"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <span v-show="scope.row.type == 1">手动调整</span>
                        <span v-show="scope.row.type == 2">系统调整</span>
                    </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="创建时间"
                    min-width="150"
                ></el-table-column>
                <!-- 审核时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="check_time"
                    label="审核时间"
                    min-width="150"
                ></el-table-column>
                <!-- 审核状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="审核状态"
                    min-width="150"
                    :formatter="auditStatus"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
export default {
    name: "MAM_rateList",
    data() {
        return {
            brandList: [],
            rateForm: {
                phone: '', //代理商账号
                name: '', //代理商姓名
                brand_id: '', //终端类型
                sn: '', //机具sn
                merchant_number: '', //商户编号
                is_active: '', //激活状态
                status: '' //审核状态
            },
            rateTable: [],
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: false
        }
    },
    mounted() {
        this.getData({
            page: this.page.currentPage,
            limit: this.page.pageSize,
        })
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$$message.success(res.msg);
            }
        });
    },
    components: {
        paginations
    },
    methods: {
        onSearch() {
            // 表单提交
            let searchData = this._.cloneDeep(this.rateForm);
            searchData.page = 1;
            searchData.limit = this.page.pageSize;
            this.getData(searchData);
        },
        //
        handleInfo(row) { //编辑
            this.$router.push({ path: '/rateInfo', query: { id: row.id } });
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            let searchData = this._.cloneDeep(this.rateForm);
            searchData.page = this.page.currentPage;
            searchData.limit = this.page.pageSize;
            this.getData(searchData);
        },
        getPageSize(limit) {
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            let searchData = this._.cloneDeep(this.rateForm);
            searchData.page = this.page.currentPage;
            searchData.limit = limit;
            this.getData(searchData);
        },
        getData(params) {
            this.$api.post("terminal/getRateApplyList", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.rateTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        auditStatus(row) { //审核状态
            let status;
            switch (row.status) {
                case 0:
                    status = '待审核';
                    break;
                case 1:
                    status = '审核通过';
                    break;
                case 2:
                    status = '审核拒绝';
                    break;
                case 3:
                    status = '审核通过';
                    break;
            }
            return status;
        },
        activeStatus(row) { //激活状态
            let active_status;
            switch (row.active_status) {
                case 0:
                    active_status = '未激活';
                    break;

                default:
                    active_status = '已激活';
                    break;
            }
            return active_status;
        }
    }
};
</script>

<style lang="scss" scope>
.rateList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
