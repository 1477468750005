/** 采购管理 */
import ProcurementOrderList from '../../views/procurementManagement/ProcurementOrderList'; // 采购订单

export default [
    {
        path: '/procurementOrder',
        name: 'procurementOrder',
        meta: {
            title: '采购订单'
        },
        component: ProcurementOrderList
    }
]