<template>
    <div class="login-container">
        <div class="login-content-bg">
            <div class="login-content-left">
                <img :src="loginContentLeft" alt width="100%" height="100%" />
            </div>
            <div class="login-content-right">
                <el-form
                    ref="loginForm"
                    :model="loginForm"
                    class="login-form"
                    auto-complete="on"
                    label-position="left"
                >
                    <div class="login-title">{{baseTitle}}</div>
                    <el-form-item prop="username">
                        <span class="svg-container">
                            <i class="iconfont icon-yonghu"></i>
                        </span>
                        <el-input
                            ref="username"
                            size="medium"
                            v-model="loginForm.username"
                            autocomplete="off"
                            placeholder="代理商账号"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <span class="svg-container">
                            <i class="iconfont icon-mima"></i>
                        </span>
                        <el-input
                            ref="password"
                            size="medium"
                            v-model="loginForm.password"
                            autocomplete="off"
                            :type="passwordType"
                            placeholder="代理商密码"
                        ></el-input>
                        <span class="show-pwd" @click="changetype">
                            <i
                                class="iconfont"
                                :class="passwordType == 'password'? 'icon-guanbi':'icon-xianshimima'"
                            ></i>
                        </span>
                    </el-form-item>
                    <el-form-item>
                        <span class="svg-container">
                            <i class="iconfont icon-yanzhengma"></i>
                        </span>
                        <el-input
                            size="medium"
                            v-model="loginForm.verification_code"
                            placeholder="验证码"
                            maxlength="4"
                            @keyup.native="handleKeyLogin"
                        ></el-input>
                        <div class="verifyCode">
                            <img @click="handleChangeCode" :src="verifyCode" alt />
                        </div>
                    </el-form-item>
                    <el-button
                        :loading="loading"
                        type="primary"
                        style="width:100%;height:47px;margin-bottom:30px;"
                        @click.native.prevent="handleLogin"
                    >登录</el-button>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import ElFormItem from "element-ui/packages/form/src/form-item";
import ElInput from "element-ui/packages/input/src/input";

export default {
    components: {
        ElInput,
        ElFormItem    },
    name: "index",
    data() {
        return {
            verifyCode: '',
            baseTitle: '',
            passwordType: 'password',
            loginForm: {
                username: '',
                password: '',
                verification_code: '',
                session_id: ''
            },
            loginRules: {
                username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
            },
            loading: false,
            loginContentLeft: require("../../assets/images/login_bg_left.png")
        }
    },
    mounted() {

        this.handleChangeCode();
        this.getInfo(); //初始化
        if (this.$route.query.token) {
            this.loginForm.username = 'admin';
            this.loginForm.password = '';
            this.loginForm.token = this.$route.query.token;
            this.handleJumpHomePage();
        }
    },
    methods: {
        // 切换显示方式
        changetype() {
            if (this.passwordType === 'password') {
                this.passwordType = ''
            } else {
                this.passwordType = 'password'
            }
        },
        handleLogin() {//点击登录
            this.handleJumpHomePage();
        },
        handleKeyLogin(e) { //回车登录
            if (e.keyCode == 13) {
                this.handleJumpHomePage();
            }
        },
        handleChangeCode() { // 切换验证码
            this.$api.get('create_captcha?type=1', res => {
                let { img, session_id } = res.data;
                this.loginForm.session_id = session_id;
                this.verifyCode = img;
            })
        },
        getInfo() { //获取详情
            this.$api.post('setting/getSetting', { k: 'site' }, res => {
                // console.log(res);
                if (res.code == 0) {
                    this.baseTitle = res.data.site_title;
                    document.title = res.data.site_title;
                    sessionStorage.setItem('title', res.data.site_title);
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        handleJumpHomePage() { //处理跳转首页
            this.loading = true;
            this.$api.post('login', this.loginForm, res => {
                if (res.code == 0) {
                    let that = this;
                    let data = res.data;
                    sessionStorage.setItem('token', data.token);
                    sessionStorage.setItem('username', data.info.username);
                    sessionStorage.setItem('organization_name', data.info.organization_name);
                    sessionStorage.setItem('repayment_type', data.repayment_type);
                    sessionStorage.setItem('admin_id', data.info.id);
                    this.$message({
                        type: 'success',
                        message: '登录成功',
                        duration: 1000,
                        onClose() {
                            that.$router.push({ path: '/' });
                            // is_update_password 1.需求修改 2.无需修改
                            if (res.data.info.is_update_password == 2) {
                                that.$alert('系统检测到您的登录密码还是初始密码，为了您的账号安全请尽快修改登录密码!', '温馨提示', {
                                    type: 'warning',
                                    center: true,
                                    confirmButtonText: '去修改',
                                    confirmButtonClass: 'apply-button',
                                    callback: action => {
                                        if (action === 'confirm') {
                                            that.$router.push({ path: '/editPassword' });
                                        }
                                    }
                                });
                            }
                        }
                    });
                } else {
                    this.handleChangeCode();
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
.login-container {
    width: 100%;
    min-height: 100%;
    position: relative;
    background: url("../../assets/images/login_bg.png") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
    .login-content-left {
        margin-left: 50px;
        width: 350px;
    }
    .login-content-right {
        width: 350px;
    }
    .login-content-bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1000px;
        height: 500px;
        background-color: #ffffff;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 2px 25px 15px rgba(0, 0, 0, 0.1);
        padding: 50px;
        box-sizing: border-box;
    }
    .login-form {
        .login-title {
            font-size: 30px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #409eff;
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid #cccccc;
        }
        .el-form-item {
            color: #454545;
            border: 1px solid #c0c4cc;
            border-radius: 5px;
            margin-bottom: 22px;
        }
        .svg-container {
            // position: absolute;
            // top: 0;
            // left: 0;
            height: 100%;
            padding: 6px 5px 6px 15px;
            color: #fff;
            vertical-align: middle;
            width: 30px;
            display: inline-block;
            background-color: #c0c4cc;
        }
        .show-pwd {
            position: absolute;
            right: 25px;
            top: 6px;
            color: #303133;
            cursor: pointer;
            user-select: none;
            .iconfont {
                font-size: 20px;
            }
        }
        .verifyCode {
            display: flex;
            justify-content: flex-end;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            overflow: hidden;
            img {
                cursor: pointer;
                height: 100%;
            }
        }
    }
}
</style>