<template>
	<!-- 考核扣费记录 -->
	<div class="InspectionFeeList">
		<el-card class="search-card" shadow="hover">
			<el-form :inline="true" :model="searchForm">
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.sn"
						placeholder="请输入终端SN"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.user_name"
						placeholder="请输入代理商名称"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.user_code"
						placeholder="请输入代理商编号"
					></el-input>
				</el-form-item>
				<el-form-item
					v-show="searchForm.user_name || searchForm.user_code"
				>
					<el-select
						v-model="searchForm.is_lower_level"
						clearable
						placeholder="是否包含下级"
					>
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select
						v-model="searchForm.type"
						clearable
						placeholder="请选择扣款类型"
					>
						<el-option label="未激活扣款" value="1"></el-option>
						<el-option label="伪激活扣款" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-date-picker
						v-model="searchForm.create_time"
						type="datetimerange"
						range-separator="至"
						start-placeholder="操作开始日期"
						end-placeholder="操作结束日期"
						value-format="yyyy-MM-dd HH:mm:ss"
						:default-time="['00:00:00', '23:59:59']"
					></el-date-picker>
				</el-form-item>
				<div class="search-card-button">
					<el-button
						type="primary"
						icon="el-icon-search"
						style="font-size: 12px"
						@click="handleSearch(searchForm)"
					>
						查询
					</el-button>
					<el-button
						type="info"
						icon="el-icon-refresh-left"
						style="font-size: 12px"
						@click="searchForm = { ...{}, ...searchReset }"
					>
						重置
					</el-button>
				</div>
			</el-form>
		</el-card>
		<el-card style="margin-top: 20px;" shadow="hover">
			<div style="margin-bottom: 20px;">
				<el-button
					type="primary"
					icon="el-icon-folder-opened"
					@click="importFile.isShow = true"
				>
					导入
				</el-button>
				<el-button
					type="primary"
					icon="el-icon-download"
					:loading="downLoading"
					@click="handleDownLoad"
				>
					导出
				</el-button>
			</div>
			<div class="table-header-title">
				<div>
					<el-button
						type="text"
						@click="getData(searchForm, 2)"
						:loading="countLoading"
					>
						查看统计信息
					</el-button>
				</div>
				<div>
					<span class="rm-word-aux">
						共搜索到
						{{ sum.total }} 条数据
					</span>
				</div>
				<div>
					<span class="rm-label">应扣金额：</span>
					<span class="rm-word-aux">
						{{ sum.total_dec_money }} 元
					</span>
				</div>
				<div>
					<span class="rm-label">实扣金额：</span>
					<span class="rm-word-aux">
						{{ sum.total_dec_money }} 元
					</span>
				</div>
			</div>
			<el-table
				:data="tableList"
				size="small"
				border
				style="width: 100%"
				v-loading="loading"
				max-height="500px"
			>
				<!-- 序号 -->
				<el-table-column
					type="index"
					header-align="center"
					align="center"
					fixed
					label="序号"
					width="100"
					:index="handleTableIndex"
				></el-table-column>
				<!-- 终端SN -->
				<el-table-column
					header-align="center"
					align="center"
					prop="terminal_sn"
					label="终端SN"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 代理商名称 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="real_name"
					label="代理商名称"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 代理商编号 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="user_code"
					label="代理商编号"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 扣款类型 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="type"
					label="扣款类型"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 应扣罚金（元） -->
				<el-table-column
					header-align="center"
					align="center"
					prop="dec_money"
					label="应扣罚金（元）"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 操作日期 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="create_time"
					label="操作日期"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 操作员 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="username"
					label="操作员"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 扣款状态 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="status"
					label="扣款状态"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 操作 -->
				<el-table-column
					header-align="center"
					align="center"
					fixed="right"
					label="操作"
					min-width="150"
				>
					<template #default="{row}">
						<el-button
							type="text"
							size="small"
							@click="handleCheckDetail(row)"
						>
							详情
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页部分 -->
			<el-pagination
				background
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:page-sizes="[10, 20, 50, 100]"
				:current-page="currentPage"
				:page-size="pageSize"
				:total="total"
				layout="total, sizes, prev, pager, next, jumper"
			></el-pagination>
		</el-card>
		<InspectionFeeListDetail
			v-if="detail.isShow"
			:higher="detail"
			@onClose="detail.isShow = false"
		/>
		<InspectionFeeListImport
			v-if="importFile.isShow"
			:higher="importFile"
			@onClose="importFile.isShow = false"
		/>
	</div>
</template>

<script>
import InspectionFeeListDetail from "./components/InspectionFeeListDetail.vue";
import InspectionFeeListImport from "./components/InspectionFeeListImport";
export default {
	name: "InspectionFeeList",
	data() {
		return {
			importFile: {
				isShow: false,
				data: {},
			},
			detail: {
				isShow: false,
				data: {},
			},
			searchReset: {}, // 重置搜索条件
			searchForm: {
				page: 1,
				limit: 10,
				sn: "", // 终端SN
				user_name: "", // 代理商名称
				user_code: "", // 代理商编号
				is_lower_level: "", // 是否包含下级
				type: "", // 扣款类型
				create_time: "", // 操作日期
			},
			tableList: [],
			currentPage: 1, //当前页码
			pageSize: 10, //条数
			total: 0, //总数
			loading: false,
			downLoading: false,
			countLoading: false,
			sum: {
				total: "--",
				total_dec_money: "--",
			},
		};
	},
	components: {
		InspectionFeeListDetail,
		InspectionFeeListImport,
	},
	watch: {
		"searchForm.user_code"() {
			// 监听归属代理商编号
			let { user_code, user_name } = this.searchForm;
			if (user_code == "" && user_name == "") {
				this.searchForm.is_lower_level = "";
			}
		},
		"searchForm.user_name"() {
			// 监听归属代理商名称
			let { user_code, user_name } = this.searchForm;
			if (user_code == "" && user_name == "") {
				this.searchForm.is_lower_level = "";
			}
		},
	},
	created() {
		this.searchReset = Object.assign({}, this.searchForm);
		this.getData(
			{
				//初始化表格数据
				page: 1,
				limit: 10,
			},
			1
		);
	},
	methods: {
		handleSearch(searchForm) {
			this.sum.total = "--";
			this.sum.total_dec_money = "--";
			//搜索
			this.currentPage = 1;
			searchForm.page = 1;
			this.getData(searchForm, 1);
		},
		handleCheckDetail(r) {
			this.detail.isShow = true;
			this.detail.data = r;
		},
		// 分页部分
		handleCurrentChange(page) {
			//当前选中页
			this.currentPage = page;
			this.searchForm.page = page;
			this.searchForm.limit = this.pageSize;
			this.getData(this.searchForm, 1);
		},
		handleSizeChange(limit) {
			//条数
			this.currentPage = 1;
			this.pageSize = limit;
			this.searchForm.page = 1;
			this.searchForm.limit = limit;
			this.getData(this.searchForm, 1);
		},
		getData(params, type) {
			//获取数据
			if (type === 1) {
				this.loading = true;
			} else {
				this.countLoading = true;
			}
			this.$api.post(
				"terminal/terminal_nactive_dec_money",
				{ ...params, ...{ select_type: type } },
				(res) => {
					if (res.code == 0) {
						const { count, list, total } = res.data;
						if (type === 1) {
							this.total = count; //总数
							this.tableList = list;
							this.sum.total = count;
						} else {
							this.sum = { ...this.sum, ...total };
						}
					} else {
						this.$message.error(res.msg);
					}
					//渲染结束后取消加载
					this.loading = false;
					this.countLoading = false;
				}
			);
		},
		handleDownLoad() {
			//导出
			let handleExportExcel = this.common.handleExportExcel;
			this.downLoading = true;
			let searchVal = this._.omit(this.searchForm, ["page", "limit"]);
			let value = {};
			this._.forEach(searchVal, (item, index) => {
				if (item != "") {
					value[index] = item;
				}
			});
			handleExportExcel({
				type: 17,
				value: JSON.stringify(value),
			}).then(() => {
				this.downLoading = false;
			});
		},
		handleTableIndex(index) {
			// 处理序号
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
	},
};
</script>

<style lang="scss" scoped></style>
