<template>
    <div class="serviceRenewList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input clearable v-model="searchForm.account" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.status" clearable placeholder="请选择账号状态">
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="续费中" value="2"></el-option>
                        <el-option label="已过期" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-table
                :data="renewList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="account"
                    label="账号"
                    min-width="120"
                ></el-table-column>
                <!-- 密码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="password"
                    label="密码"
                    min-width="120"
                ></el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="创建时间"
                    min-width="150"
                ></el-table-column>
                <!-- 有效期 -->
                <el-table-column header-align="center" align="center" label="有效期" min-width="200">
                    <template slot-scope="scope">
                        <span>{{`${scope.row.effective_start_date} 至 ${scope.row.effective_end_date}`}}</span>
                    </template>
                </el-table-column>
                <!-- 剩余天数 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="days"
                    label="剩余天数"
                    min-width="120"
                ></el-table-column>
                <!-- 账号状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status_name"
                    label="账号状态"
                    min-width="120"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    label="操作"
                    min-width="150"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.status != 2"
                            style="margin-right:10px"
                            @click="handleRenew(scope.row)"
                            type="text"
                            size="small"
                        >续费</el-button>
                        <el-popover
                            placement="right"
                            width="600"
                            trigger="click"
                            @show="handleRecord(scope.row)"
                        >
                            <serviceRenewRecord :recordId="recordId"></serviceRenewRecord>
                            <el-button type="text" size="small" slot="reference">续费记录</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <serviceApplyOrder v-if="info.isShow" :info="info" @close="handleRenewClose"></serviceApplyOrder>
    </div>
</template>

<script>
import serviceApplyOrder from './components/serviceApplyOrder';
import serviceRenewRecord from './components/serviceRenewRecord';
export default {
    name: "serviceRenewList",
    data() {
        return {
            recordId: null,
            recordIsShow: false,
            info: {
                type: 2,
                id: null,
                isShow: false
            },
            renewList: [], //续费列表
            searchForm: {
                page: 1,
                limit: 10,
                account: '', //代理商账号
                status: '', //账号状态

            },
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        })
    },
    components: {
        serviceApplyOrder,
        serviceRenewRecord
    },
    methods: {
        handleSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleRenew(row) { //续费
            this.info.isShow = true;
            this.info.id = row.id;
        },
        handleRenewClose(ref) { //关闭续费
            this.info.isShow = false;
            this.info.id = null;
            if (ref) {
                this.getData({
                    page: 1,
                    limit: 10
                })
            }
        },
        handleRecord(row) { //续费记录
            this.recordId = row.id;
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("online_service/get_online_service_renew_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.renewList = data.list;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
