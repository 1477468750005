<template>
	<div class="DM_transctionList">
		<!-- 交易明细 -->
		<el-card class="search-card" shadow="hover">
			<el-form
				:inline="true"
				:model="searchForm"
				class="demo-form-inline"
			>
				<el-form-item>
					<el-select
						v-model="searchForm.brand_id"
						filterable
						clearable
						style="width:100%"
						placeholder="请选择终端类型"
					>
						<el-option
							v-for="(item, index) in brandList"
							:key="index"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.sn"
						placeholder="请输入SN码"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.terminal_number"
						placeholder="请输入终端编号"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.merchant_number"
						placeholder="请输入商户编号"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-select
						v-model="searchForm.pay_type"
						filterable
						clearable
						style="width:100%"
						placeholder="请选择交易方式"
					>
						<el-option
							v-for="(item, index) in payTypeList"
							:key="index"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select
						v-model="searchForm.card_type"
						clearable
						placeholder="请选择卡类型"
					>
						<el-option label="借记卡" value="1"></el-option>
						<el-option label="贷记卡" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.real_name"
						placeholder="请输入终端归属代理商名称"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.phone"
						placeholder="请输入终端归属代理商账号"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.transaction_number"
						placeholder="请输入订单号"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.agent_name"
						placeholder="请输入代理商名称"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.agent_phone"
						placeholder="请输入代理商账号"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-date-picker
						v-model="rangeDate"
						type="daterange"
						range-separator="至"
						start-placeholder="交易开始日期"
						end-placeholder="交易结束日期"
						value-format="yyyy-MM-dd"
						:picker-options="pickerOptions"
						@change="pickerChange"
					></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-select
						v-model="searchForm.transaction_order_type"
						clearable
						placeholder="请选择订单类型"
					>
						<el-option label="流量卡交易" value="1"></el-option>
						<el-option label="普通交易" value="2"></el-option>
					</el-select>
				</el-form-item>
				<div class="search-card-button">
					<el-button
						type="primary"
						icon="el-icon-search"
						style="font-size: 12px;"
						@click="handleSearch(searchForm)"
					>
						查询
					</el-button>
					<el-button
						type="info"
						icon="el-icon-refresh-right"
						style="font-size: 12px;"
						@click="searchForm = { ...searchReset }"
					>
						重置
					</el-button>
				</div>
			</el-form>
		</el-card>
		<!-- 表格部分 -->
		<el-card style="margin-top: 20px;" shadow="hover">
			<div style="margin-bottom: 20px;">
				<el-button
					size="small"
					type="primary"
					icon="el-icon-download"
					:loading="downLoading"
					@click="handleDownLoad"
					>导出数据</el-button
				>
			</div>
			<div style="margin-bottom: 10px;">
				<div class="amountSum">
					<span class="rm-label">交易总额：</span>
					<span class="rm-word-aux">{{ amountSum.money_count }}</span>
				</div>
				<div class="amountSum">
					<span class="rm-label">交易笔数：</span>
					<span class="rm-word-aux"
						>{{ amountSum.count }}&nbsp;笔</span
					>
				</div>
				<div class="amountSum">
					<span class="rm-label">流量卡总额：</span>
					<span class="rm-word-aux"
						>{{ amountSum.flow_card_fee_count }}&nbsp;元</span
					>
				</div>
			</div>
			<el-table
				:data="transctionTable"
				size="mini"
				border
				style="width: 100%"
				v-loading="loading"
			>
				<!-- id -->
				<el-table-column
					header-align="center"
					align="center"
					fixed
					prop="id"
					label="序号"
					min-width="80"
				></el-table-column>
				<!-- 订单号 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="transaction_number"
					label="订单号"
					min-width="200"
					show-overflow-tooltip
				></el-table-column>
				<!-- 终端类型 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="brand_name"
					label="终端类型"
					min-width="150"
				></el-table-column>
				<!-- SN码 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="terminal_sn"
					label="SN码"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 终端归属代理商名称 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="real_name"
					label="终端归属代理商名称"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 终端归属代理商手机号 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="phone"
					label="终端归属代理商手机号"
					min-width="150"
				></el-table-column>
				<!-- 终端号 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="terminal_number"
					label="终端号"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 商户号 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="merchant_number"
					label="商户号"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 交易类型 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="pay_type"
					label="交易类型"
					min-width="150"
				></el-table-column>
				<!-- 订单类型 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="transaction_order_type"
					label="订单类型"
					min-width="150"
				></el-table-column>
				<!-- 卡类型 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="card_type"
					label="卡类型"
					min-width="150"
					:formatter="formatCard"
				></el-table-column>
				<!-- 结算周期 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="settle_type"
					label="结算周期"
					min-width="150"
					:formatter="formatSettle"
				></el-table-column>
				<!-- 交易时间 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="transaction_time"
					label="交易时间"
					min-width="150"
				></el-table-column>
				<!-- 交易金额 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="transaction_money"
					label="交易金额"
					min-width="150"
				></el-table-column>
				<!-- 流量卡金额 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="flow_card_fee"
					label="流量卡金额"
					min-width="150"
				></el-table-column>
				<!-- 终端交易费率 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="real_profit_rate"
					label="终端交易费率"
					min-width="150"
				></el-table-column>
				<!-- 交易分润 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="real_rebate_money"
					label="交易分润"
					min-width="150"
				></el-table-column>
				<!-- 实际分润费率 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="terminal_rate"
					label="实际分润费率"
					min-width="150"
				></el-table-column>
				<!-- 实发分润 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="rebate_money"
					label="实发分润"
					min-width="150"
				></el-table-column>
				<!-- 操作 -->
				<el-table-column
					header-align="center"
					align="center"
					fixed="right"
					label="操作"
					min-width="150"
				>
					<template slot-scope="scope">
						<el-button
							@click="handleInfo(scope.row)"
							type="text"
							size="small"
							>查看</el-button
						>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页部分 -->
			<paginations
				:currentPage="page.currentPage"
				:pageSize="page.pageSize"
				:total="page.total"
				@watchCurrent="getCurrent"
				@watchPageSize="getPageSize"
			></paginations>
		</el-card>
		<transaction-info
			v-if="infoIsShow"
			:infoIsShow="infoIsShow"
			:detail="detail"
			@infoClose="infoClose"
		></transaction-info>
	</div>
</template>

<script>
import paginations from "../../../components/pagination/paginations";
import transactionInfo from "./DM_transctionInfo";
export default {
	name: "DM_transctionList",
	data() {
		return {
			amountSum: {
				count: 0,
				money_count: 0,
				flow_card_fee_count: 0,
			},
			currentDate: "",
			pickerOptions: {},
			infoIsShow: false, //弹窗
			detail: {}, //当前行
			brandList: [], //终端类型列表
			payTypeList: [], //支付类型
			rangeDate: [], //时间范围
			searchReset: {}, // 重置搜索条件
			searchForm: {
				sn: "", //sn码
				phone: "", //归属代理商账号
				pay_type: "", //交易方式
				brand_id: "", //终端类型id
				card_type: "", //卡类型
				real_name: "", //归属代理商名称
				end_time: "", //交易结束日期
				start_time: "", //交易开始日期
				terminal_number: "", //终端编号
				merchant_number: "", //商户编号
				transaction_number: "", //订单号
				agent_name: "", // 代理商名称
				agent_phone: "", // 代理商账号
				transaction_order_type: "", // 订单类型
				page: 1,
				limit: 10,
			},
			transctionTable: [],
			page: {
				currentPage: 1, //当前页码
				pageSize: 10, //条数
				total: 0, //总数
			},
			loading: false,
			downLoading: false, //导出loading
		};
	},
	created() {
		this.searchReset = { ...this.searchForm };
		this.pickerOptions.disabledDate = (time) => {
			//禁用日期
			if (this.currentDate !== "") {
				let tempTime = 3600 * 1000 * 24 * 30;
				let timer = new Date(this.currentDate).getTime();
				let minTime = timer - tempTime;
				let maxTime = timer + tempTime;
				return (
					time.getTime() < minTime ||
					time.getTime() > Date.now() ||
					time.getTime() > maxTime
				);
			}
			return time.getTime() > Date.now();
		};
		this.pickerOptions.onPick = ({ minDate, maxDate }) => {
			//当前选中的日期
			this.currentDate = minDate.getTime();
			if (maxDate) {
				this.currentDate = "";
			}
		};
	},
	mounted() {
		this.handleDefaultTime();
		this.$api.post("brand/brand_list", {}, (res) => {
			//获取终端类型
			if (res.code == 0) {
				this.brandList = res.data.list;
			} else {
				this.$message.success(res.msg);
			}
		});
		this.$api.post("type/pay_type", {}, (res) => {
			//获取支付类型
			if (res.code == 0) {
				this.payTypeList = res.data;
			} else {
				this.$message.success(res.msg);
			}
		});
		this.getData(this.searchForm); //初始化
	},
	components: {
		paginations,
		transactionInfo,
	},
	methods: {
		handleSearch(searchForm) {
			this.page.currentPage = 1; //重置分页
			this.searchForm.page = 1; //重置
			// 表单提交
			this.getData(searchForm);
		},
		handleInfo(row) {
			//查看
			this.infoIsShow = true;
			this.detail = row;
		},
		infoClose() {
			//关闭弹窗
			this.infoIsShow = false;
		},
		//分页
		getCurrent(page) {
			this.loading = true;
			// 获取当前页修改的值
			this.page.currentPage = page;
			this.searchForm.page = this.page.currentPage;
			this.searchForm.limit = this.page.pageSize;
			this.getData(this.searchForm);
		},
		getPageSize(limit) {
			this.page.currentPage = 1; //重置分页
			// 获取条数发生改变的值
			this.loading = true;
			this.page.pageSize = limit;
			this.searchForm.page = this.page.currentPage;
			this.searchForm.limit = limit;
			this.getData(this.searchForm);
		},
		getData(params) {
			this.loading = true;
			this.$api.post("transaction/getList", params, (res) => {
				if (res.code == 0) {
					let data = res.data;
					let {
						count,
						list,
						money_count,
						flow_card_fee_count,
					} = data;
					this.page.total = count; //总数
					this.transctionTable = list;
					this.amountSum = {
						count,
						money_count,
						flow_card_fee_count,
					};
				} else {
					this.$message.error(res.msg);
				}
				//渲染结束后取消加载
				this.loading = false;
			});
		},
		pickerChange(DateArr) {
			//时间选择器
			this.searchForm.start_time = this._.isEmpty(DateArr)
				? ""
				: DateArr[0];
			this.searchForm.end_time = this._.isEmpty(DateArr)
				? ""
				: DateArr[1];
		},
		formatSettle(row) {
			let settle = ""; //1.T+0 2.T+1
			switch (row.settle_type) {
				case 1:
					settle = "T+0";
					break;
				default:
					settle = "T+1";
					break;
			}
			return settle;
		},
		formatCard(row) {
			let card = ""; //1.借记卡 2.贷记卡
			switch (row.card_type) {
				case 1:
					card = "借记卡";
					break;
				default:
					card = "贷记卡";
					break;
			}
			return card;
		},
		handleDownLoad() {
			//导出
			let handleExportExcel = this.common.handleExportExcel;
			this.downLoading = true;
			let searchVal = this._.omit(this.searchForm, ["page", "limit"]);
			let value = {};
			this._.forEach(searchVal, (item, index) => {
				if (item != "") {
					value[index] = item;
				}
			});
			handleExportExcel({ type: 7, value: JSON.stringify(value) }).then(
				() => {
					this.downLoading = false;
				}
			);
		},
		handleDefaultTime() {
			//默认获取三十天
			let date = new Date().getTime();
			this.searchForm.end_time = this.$moment().format("YYYY-MM-DD");
			this.searchForm.start_time = this.$moment(
				date - 3600 * 1000 * 24 * 30
			).format("YYYY-MM-DD");
			this.rangeDate = [
				this.searchForm.start_time,
				this.searchForm.end_time,
			];
		},
	},
};
</script>

<style lang="scss" scoped>
.DM_transctionList {
	.el-input__inner {
		font-size: 12px;
	}
	.el-form-item {
		margin-bottom: 5px;
	}
	.amountSum {
		display: inline-block;
		margin-right: 20px;
	}
}
</style>
