<template>
    <div class="serviceApplyOrder">
        <el-dialog
            width="800px"
            :visible="info.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-card class="box-card" shadow="always">
                <div class="apply-title">{{info.type == 1? '坐席申请' : '续费申请'}}</div>
                <div class="payment-info">
                    <ul>
                        <li>
                            <div>
                                收费标准：
                                <span>¥ {{v.money}} 元 / 坐席 / 年</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                账户余额：
                                <span>¥ {{v.balance}} 元</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                应付金额：
                                <span>¥ {{v.money}} 元</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="notes">
                    <div class="note-title">注意事项：</div>
                    <ol>
                        <li>该功能为收费功能，提交申请后，将在平台账户冻结该部分费用，审核通过后扣除，同时PC后台和APP同步开启；</li>
                        <li>收费标准：每个坐席每年{{v.money}}元；</li>
                        <li>只能按年申请，中途不支持退款；</li>
                    </ol>
                </div>
                <el-button
                    size="medium"
                    type="primary"
                    icon="el-icon-check"
                    style="margin-left:16px"
                    @click="handleSubmit()"
                >提交订单</el-button>
            </el-card>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            v: {}
        };
    },
    mounted() {
        this.getBalance();
    },
    methods: {
        handleSubmit() { //提交
            let _this = this;
            let reqData = {
                type: this.info.type
            }
            if (this.info.type == 2) {
                reqData.renewal_child_id = this.info.id
            }
            this.$confirm('是否确认提交该笔订单？', '提示', {
                confirmButtonText: '确认提交',
                cancelButtonText: '取消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('online_service/application_open_order', reqData, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1500,
                                    onClose() {
                                        done();
                                        instance.confirmButtonLoading = false;
                                        _this.handleClose(1);
                                    }
                                })
                            } else {
                                this.$message.error(res.msg);
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleClose(ref) { //关闭
            this.$emit('close', ref)
        },
        getBalance() { //获取余额
            this.$api.post('online_service/online_service_application_show', {}, res => {
                if (res.code == 0) {
                    this.v = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
.apply-title {
    padding: 8px 16px;
    color: #606266;
    font-size: 13px;
    font-weight: 700;
    background-color: #f2f6fc;
    border-radius: 4px;
    border-left: 5px solid #dcdfe6;
    margin: 20px 0;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.payment-info {
    ul {
        list-style: none;
        padding: 0 16px;
        li {
            font-size: 13px;
            padding: 8px 0;
            color: #606266;
            div {
                font-weight: 700;
                span {
                    font-weight: 500;
                }
            }
        }
        li:last-child {
            div:last-child span {
                color: #e6a23c;
            }
        }
    }
}
.notes {
    .note-title {
        font-size: 12px;
        color: red;
        font-weight: 700;
        padding: 10px 0 0 16px;
    }
    ol > li {
        font-size: 12px;
    }
}
</style>