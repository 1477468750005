import layout from '../layout/index.vue'
import Login from '../views/login/index.vue' //登录
import editPassword from '../views/editPassword/editPassword' //修改密码

// 系统首页
import home from '../views/home/index';
import AgencyAgent from './modules/AgencyAgent'; // 机构代理商管理
import Base from './modules/Base'; // 基础设置
import Permission from './modules/Permission'; // 权限管理
import Agent from './modules/Agent'; // 代理商管理
import Rules from './modules/Rules'; // 规则管理
import Machine from './modules/Machine'; // 机具管理
import Data from './modules/Data'; // 数据管理
import Merchant from './modules/Merchant'; // 商户管理
import Finance from './modules/Finance'; // 财务管理
import Application from './modules/Application'; // APP管理
import Business from './modules/Business'; // 商学院
import Help from './modules/Help'; // 帮助中心
import Service from './modules/Service' // 客服管理
import Procurement from './modules/Procurement' // 采购管理

//路由文件
const routes = [
    {
        path: '/login',
        component: Login,
        hidden: true
    },
    {
        path: '/',
        name: 'home',
        meta: {
            title: '系统首页'
        },
        children: [
            {
                path: '/',
                component: home
            }
        ],
        component: layout,
    },
    {
        path: '/agentManagement',
        name: 'agentManagement',
        meta: {
            title: '机构代理商管理'
        },
        component: layout,
        children: AgencyAgent
    },
    {
        path: '/procurementManagement',
        name: 'procurementManagement',
        meta: {
            title: '采购管理'
        },
        component: layout,
        children: Procurement
    },
    {
        path: '/base',
        name: 'base',
        meta: {
            title: '基础设置'
        },
        component: layout,
        children: Base
    },
    {
        path: '/authority',
        name: 'authority',
        meta: {
            title: '权限管理'
        },
        component: layout,
        children: Permission
    },
    {
        path: '/user',
        name: 'user',
        meta: {
            title: '代理商管理'
        },
        component: layout,
        children: Agent
    },
    {
        path: '/machine',
        name: 'machine',
        meta: {
            title: '机具管理'
        },
        component: layout,
        children: Machine
    },
    {
        path: '/rules',
        name: 'rules',
        meta: {
            title: '规则管理'
        },
        component: layout,
        children: Rules
    },
    {
        path: '/data',
        name: 'data',
        meta: {
            title: '数据管理'
        },
        component: layout,
        children: Data
    },
    {
        path: '/merchats',
        name: 'merchats',
        meta: {
            title: '商户管理'
        },
        component: layout,
        children: Merchant
    },
    {
        path: '/financia',
        name: 'financia',
        meta: {
            title: '财务管理'
        },
        component: layout,
        children: Finance
    },
    {
        path: '/app',
        name: 'app',
        meta: {
            title: 'APP管理'
        },
        component: layout,
        children: Application
    },
    {
        path: '',
        name: 'businessCollege',
        meta: {
            title: '商学院'
        },
        component: layout,
        children: Business
    },
    {
        path: '',
        name: 'editPassword',
        meta: {
            title: '修改密码'
        },
        component: layout,
        children: [
            {
                path: '/editPassword',
                component: editPassword,
            }
        ]
    },
    {
        path: '/helpCenter',
        name: 'helpCenter',
        meta: {
            title: '帮助中心'
        },
        component: layout,
        children: Help
    },
    {
        path: '/serviceManagement',
        name: 'serviceManagement',
        meta: {
            title: '客服管理'
        },
        component: layout,
        children: Service
    },
]

export default routes;