/** 基础设置 */
import amortizationSetting from '../../views/basicSetting/amortizationSetting'; //分期设置
import platformSetting from '../../views/basicSetting/platormSet/BS_PlatformSetting'; //平台设置
import paramSetting from '../../views/basicSetting/paramSet/BS_ParamSetting'; //参数设置
import agreementSetting from '../../views/basicSetting/agreement/BS_AgreementSetting'; //协议设置
import editAgreement from '../../views/basicSetting/agreement/BS_settingInfo'; //编辑协议
import aboutUs from '../../views/basicSetting/aboutUs/BS_AboutUs'; //关于我们
import dataAccount from '../../views/basicSetting/dataAccount/BS_dataAccount'; //数据账号

export default [
    {
        path: '/amortizationSetting',
        name: 'amortizationSetting',
        meta: {
            title: '分期设置'
        },
        component: amortizationSetting
    },
    {
        path: '/setPlatform',
        name: 'setPlatform',
        meta: {
            title: '平台设置'
        },
        component: platformSetting
    },
    {
        path: '/paramSetting',
        name: 'paramSetting',
        meta: {
            title: '参数设置'
        },
        component: paramSetting
    },
    {
        path: '/setAgreements',
        name: 'setAgreements',
        meta: {
            title: '协议设置'
        },
        component: agreementSetting
    },
    {
        path: '/editAgreement',
        name: 'editAgreement',
        meta: {
            title: '编辑协议'
        },
        component: editAgreement
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        meta: {
            title: '关于我们'
        },
        component: aboutUs
    },
    {
        path: '/dataAccount',
        name: 'dataAccount',
        meta: {
            title: '数据账号'
        },
        component: dataAccount
    }
]