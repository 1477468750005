<template>
    <div class="MAM_standardReward">
        <el-dialog
            :title="`配置“${standardInfo.info.brand_name}”的交易量达标奖励`"
            width="800px"
            :visible="standardInfo.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <!-- 活动说明 -->
            <el-tooltip class="item" effect="dark" placement="right-start">
                <div slot="content">
                    <p>规则配置：</p>
                    <p>交易类型为支付宝不包含借记卡，按天，首笔交易当日开始，</p>
                    <p>50天内交易金额大于10000元，奖励200元；</p>
                    <p>举例：A代理商下SN码50天内支付宝交易10000元，则A获得200元奖励。</p>
                </div>
                <el-button type="text" style="color:#606266;font-weight:700">
                    交易量达标奖励
                    <i class="el-icon-question"></i>
                </el-button>
            </el-tooltip>
            <!-- 表单部分 -->
            <el-form label-position="top" label-width="80px" :model="standardData">
                <!-- 按交易金额 -->
                <div>
                    <el-form-item label="交易类型：">
                        <div v-for="(item,index) in checkList" :key="index">
                            <el-checkbox
                                v-model="item.is_selected"
                                :true-label="1"
                                :false-label="0"
                                :disabled="standardInfo.info.status == 2"
                            >{{item.title}}</el-checkbox>
                            <el-checkbox
                                v-model="item.is_selected_debit"
                                :true-label="1"
                                :false-label="0"
                                v-show="item.is_show_debit == 1"
                                :disabled="item.is_selected == 0 || standardInfo.info.status == 2"
                            >是否包含借记卡</el-checkbox>
                        </div>
                    </el-form-item>
                    <!-- 奖励规则 -->
                    <div style="margin-bottom:10px">奖励规则：</div>
                    <el-form :inline="true" v-for="(item,index) in setting" :key="index">
                        <!-- 根据不同的start_type展示不同的下拉 -->
                        <el-form-item v-if="item.start_type == 0">
                            <el-select
                                size="medium"
                                v-model="item.start_type"
                                style="width:155px"
                                :disabled="standardInfo.info.status == 1 || standardInfo.info.status == 2"
                                placeholder="请选择"
                            >
                                <el-option label="请选择" :value="0"></el-option>
                                <el-option label="激活时间开始" :value="3"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="item.start_type != 0">
                            <el-select
                                size="medium"
                                v-model="item.start_type"
                                style="width:155px"
                                :disabled="standardInfo.info.status == 1 || standardInfo.info.status == 2"
                                placeholder="请选择"
                            >
                                <el-option label="请选择" :value="0"></el-option>
                                <el-option label="激活时间开始" :value="3"></el-option>
                                <el-option label="首笔交易当天开始" :value="1"></el-option>
                                <el-option label="首笔交易次日开始" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                size="medium"
                                :style="{width:inputWidth+'px'}"
                                :disabled="standardInfo.info.status == 1 || standardInfo.info.status == 2"
                                v-model="item.days"
                                placeholder="天数"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <span class="line">天，交易≥</span>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                size="medium"
                                :style="{width:inputWidth+'px'}"
                                :disabled="standardInfo.info.status == 1 || standardInfo.info.status == 2"
                                v-model="item.target_money"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <span class="line">元，奖励</span>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                size="medium"
                                :style="{width:inputWidth+'px'}"
                                :disabled="standardInfo.info.status == 1 || standardInfo.info.status == 2"
                                v-model="item.reward_money"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <span class="line">元。</span>
                        </el-form-item>
                    </el-form>
                </div>
                <el-form-item v-if="setting.length < 6">
                    <el-button
                        type="text"
                        icon="el-icon-plus"
                        :disabled="standardInfo.info.status == 1 || standardInfo.info.status == 2"
                        @click="addRules"
                    >增加交易奖励规则</el-button>
                </el-form-item>
                <el-form-item label="活动截止时间：">
                    <el-date-picker
                        size="medium"
                        v-model="standardData.cut_off_time"
                        type="date"
                        placeholder="活动截止时间"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        :disabled="standardInfo.info.status == 1 || standardInfo.info.status == 2"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="APP提示语设置">
                    <el-input
                        v-model="standardData.tips"
                        size="medium"
                        :disabled="standardInfo.info.status != 0"
                    ></el-input>
                </el-form-item>
                <el-form-item style="margin-top:20px;">
                    <el-button
                        type="primary"
                        @click="handleSubmit"
                        :disabled="standardInfo.info.status == 2"
                    >提 交</el-button>
                    <div style="margin-top:10px">
                        <el-tag type="danger">温馨提示：活动配置后，只能修改交易类型，奖励条件无法修改。</el-tag>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "MAM_standardReward",
    props: {
        standardInfo: {
            type: Object
        },
        brandId: {
            type: [String, Number]
        }
    },
    data() {
        return {
            pickerOptions: { //禁止选之前的时间
                disabledDate(time) {
                    return time.getTime() < Date.now();
                }
            },
            inputWidth: 100,
            checkList: [],
            setting: [],
            standardData: {
            },
        };
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        handleSubmit() {
            let that = this;
            let arr = [];
            this._.forEach(this.checkList, (item) => {
                if (item.is_selected) {
                    // 拼接数据转json
                    arr.push(
                        {
                            pay_type_id: item.pay_type_id,
                            is_selected_debit: item.is_selected_debit
                        }
                    )
                }
            });
            //转JSON
            this.standardData.setting = JSON.stringify(this.setting);
            this.standardData.transaction_type = JSON.stringify(arr);
            // 存id
            this.standardData.brand_id = this.brandId;
            // 请求
            this.$confirm('活动奖励规则保存后，将不能进行修改，请确认是否保存？', '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('brand/brand_reward_standard_update', this.standardData, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose() {
                                        done();
                                        that.handleClose(1);
                                        instance.confirmButtonLoading = false;
                                    }
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg
                                });
                                done();
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        addRules() { //增加交易量奖励规则
            if (this.setting.length > 5) {
                this.$message.error('最多只能添加6条哦!')
            } else {
                let rule = {
                    brand_reward_conf_id: '',
                    start_type: 0,
                    days: '',
                    target_money: '',
                    reward_money: '',
                }
                this.setting.push(rule);
            }
        },
        handleClose(ref) { //关闭弹窗
            this.$emit('standardClose', ref)
        },
        getInfo() {
            this.$api.post('brand/brand_reward_standard', { brand_id: this.brandId }, res => {
                if (res.code === 0) {
                    let data = res.data;
                    this.standardData = data;
                    this.setting = data.setting; //规则
                    this.checkList = data.transaction_type; //类型
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
.MAM_standardReward * {
    font-size: 12px;
    .el-input__inner {
        font-size: 12px;
    }
    ::v-deep .el-dialog__body {
        height: 600px;
        overflow: auto;
    }
    ::v-deep .el-form-item {
        margin-bottom: 5px;
        .el-form-item__label,
        .el-radio__label {
            font-size: 12px;
        }
    }
    .el-form--label-top ::v-deep .el-form-item__label {
        padding: 0;
    }
    .el-checkbox ::v-deep .el-checkbox__label {
        font-size: 12px;
    }
    .line {
        min-width: 75px;
        text-align: center;
    }
    .headers {
        display: inline-block;
        width: 177px;
    }
}
</style>