<template>
    <div class="MAM_activeReward">
        <el-dialog
            :title="`配置“${activeInfo.info.brand_name}”的激活奖励`"
            width="70%"
            :visible="activeInfo.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <!-- 活动说明 -->
            <el-tooltip class="item" effect="dark" placement="right-start">
                <div slot="content">
                    <!-- <p>激活方式为：交易金额激活</p>
                    <p>（如设置按天，首笔交易当日开始，30天，交易金额大于5000元，激活奖励100元。)</p>-->
                    <p>激活方式为：激活状态</p>
                    <p>（举例：终端激活，奖励100元）</p>
                </div>
                <el-button type="text" style="color:#606266;font-weight:700">
                    激活奖励
                    <i class="el-icon-question"></i>
                </el-button>
            </el-tooltip>
            <!-- 表单部分 -->
            <el-form label-position="top" label-width="80px" :model="countMethod">
                <el-form-item label="激活方式：（点击文字可进行查看）">
                    <div class="active-methods">
                        <!-- <div class="active-checkbox">
                            <el-checkbox
                                :disabled="activeInfo.info.status != 0"
                                v-model="viewType[0]"
                                :true-label="'1'"
                                :false-label="''"
                                @change="handleCheckChange"
                            ></el-checkbox>
                            <span @click="handleCheckType(1)">按交易金额</span>
                        </div>-->
                        <div class="active-checkbox">
                            <el-checkbox
                                disabled
                                v-model="viewType"
                                :true-label="'2'"
                                :false-label="''"
                                @change="handleCheckChange"
                            ></el-checkbox>
                            <span @click="handleCheckType(2)">按激活状态</span>
                        </div>
                    </div>
                </el-form-item>
                <!-- <el-form-item label="激活方式：">
                    <el-radio
                        v-model.number="countMethod.active_method"
                        :label="1"
                        :disabled="activeInfo.info.status != 0"
                    >按交易金额</el-radio>
                    <el-radio
                        v-model.number="countMethod.active_method"
                        :label="2"
                        :disabled="activeInfo.info.status != 0"
                    >按激活状态</el-radio>
                </el-form-item>-->
                <!-- 按交易金额 -->
                <!-- <div v-if="selectType == 1">
                    <el-form-item label="交易类型：">
                        <div v-for="(item,index) in checkList" :key="index">
                            <el-checkbox
                                v-model="item.is_selected"
                                :true-label="1"
                                :false-label="0"
                                :disabled="activeInfo.info.status != 0"
                            >{{item.title}}</el-checkbox>
                            <el-checkbox
                                v-model="item.is_selected_debit"
                                :true-label="1"
                                v-show="item.is_show_debit == 1"
                                :false-label="0"
                                :disabled="item.is_selected == 0 || activeInfo.info.status != 0"
                            >是否包含借记卡</el-checkbox>
                        </div>
                    </el-form-item>
                    <el-form :inline="true">
                        <el-form-item>
                            <el-select
                                size="medium"
                                style="width:155px"
                                v-model="countMethod.start_type"
                                :disabled="activeInfo.info.status != 0"
                                placeholder="请选择"
                            >
                                <el-option label="请选择" :value="0"></el-option>
                                <el-option label="首笔交易当天开始" :value="1"></el-option>
                                <el-option label="首笔交易次日开始" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <span>-</span>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                size="medium"
                                style="width:100px"
                                v-model="countMethod.days"
                                placeholder="天数"
                                :disabled="activeInfo.info.status != 0"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <span class="rm-word-aux">天，交易≥</span>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                size="medium"
                                style="width:100px"
                                v-model="countMethod.target_money"
                                :disabled="activeInfo.info.status != 0"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <span class="rm-word-aux">元，激活奖励</span>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                size="medium"
                                style="width:100px"
                                v-model="countMethod.reward_money"
                                :disabled="activeInfo.info.status != 0"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <span class="rm-word-aux">元。</span>
                        </el-form-item>
                    </el-form>
                </div>-->
                <!-- 按激活奖励 -->
                <div v-if="selectType == 2">
                    <el-form-item label="激活奖励">
                        <el-input
                            v-model="countMethod.reward_money"
                            size="medium"
                            style="width:220px"
                        >
                            <span slot="suffix">元</span>
                        </el-input>
                    </el-form-item>
                </div>
                <el-form-item label="活动截止时间：">
                    <el-date-picker
                        size="medium"
                        v-model="countMethod.cut_off_time"
                        type="date"
                        placeholder="活动截止时间"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="APP提示语设置">
                    <el-input v-model="countMethod.tips" size="medium"></el-input>
                </el-form-item>
                <el-form-item style="margin-top:20px;">
                    <el-button type="primary" @click="handleSubmit(countMethod)">提 交</el-button>
                    <!-- <div style="margin-top:10px">
                        <el-tag type="danger">温馨提示：活动配置后，将不能进行修改。</el-tag>
                    </div>-->
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "MAM_activeReward",
    props: {
        activeInfo: {
            type: Object
        },
        brandId: {
            type: [String, Number]
        }
    },
    data() {
        return {
            viewType: 2, //1交易金额&&2激活状态 -- 视图使用
            selectType: 2, //默认展示 -- 视图使用
            pickerOptions: { //禁止选之前的时间
                disabledDate(time) {
                    return time.getTime() < Date.now();
                }
            },
            isAdd: true,
            checkList: [],
            countMethod: {}, //form内容
            rewardForm: {} //交易类型

        };
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        handleSubmit(countMethod) {
            let that = this;
            countMethod.brand_id = this.brandId; //品牌id
            countMethod.active_method = this._.filter(this.viewType, (v) => { //激活方式
                return v != '';
            }).join(',');
            let tempArr = []; //临时存储拼接的transaction_type
            this._.forEach(this.checkList, (item) => {
                if (item.is_selected) {
                    // 拼接数据
                    tempArr.push(
                        {
                            pay_type_id: item.pay_type_id,
                            is_selected_debit: item.is_selected_debit
                        }
                    )
                }
            });
            countMethod.transaction_type = JSON.stringify(tempArr); //交易类型

            this.$confirm('请确认是否保存修改？', '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('brand/brand_reward_active_update', countMethod, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose() {
                                        that.handleClose(1);
                                        instance.confirmButtonLoading = false;
                                        done();
                                    }
                                })
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg
                                })
                                done();
                                instance.confirmButtonLoading = false;
                            }
                        });
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleClose(ref) { //关闭弹窗
            this.$emit('activeClose', ref)
        },
        handleCheckChange(val) {
            if (val != '') {
                this.selectType = val;
            }
        },
        handleCheckType(v) { //查看类型
            this.selectType = v;
        },
        getInfo() { //获取详情
            this.$api.post('brand/brand_reward_active', { brand_id: this.brandId }, res => {
                if (res.code === 0) {
                    this.countMethod = res.data;
                    // let viewType = [];
                    // res.data.active_method.split(',').forEach((item) => { //转数组
                    //     switch (item) {
                    //         case '1':
                    //             viewType[0] = '1';
                    //             this.selectType = '1';
                    //             break;
                    //         case '2':
                    //             viewType[1] = '2';
                    //             this.selectType = '2';
                    //             break;
                    //         default:
                    //             break;
                    //     }
                    // })
                    // console.log(viewType)
                    this.viewType = res.data.active_method; //激活方式
                    this.checkList = res.data.transaction_type;
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
.active-methods {
    display: flex;
    .active-checkbox:last-child {
        margin-left: 20px;
    }
    .active-checkbox {
        span {
            font-size: 12px;
            color: #409eff;
            margin-left: 10px;
            cursor: pointer;
        }
    }
}
.el-input__inner {
    font-size: 12px;
}
::v-deep .el-dialog__body {
    height: 600px;
    overflow: auto;
}
::v-deep .el-form-item {
    margin-bottom: 5px;
    .el-form-item__label,
    .el-radio__label {
        font-size: 12px;
    }
}
.el-form--label-top ::v-deep .el-form-item__label {
    padding: 0;
}
.el-checkbox ::v-deep .el-checkbox__label {
    font-size: 12px;
}
// 滚动条
::v-deep .el-dialog__body::-webkit-scrollbar {
    width: 4px;
}
::v-deep .el-dialog__body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}
::v-deep .el-dialog__body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}
</style>