<template>
    <div class="FM_financial">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="financeRecordForm" class="demo-form-inline">
                <el-form-item>
                    <el-select
                        size="medium"
                        v-model="financeRecordForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="financeRecordForm.real_name"
                        placeholder="请输入代理商真实姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="financeRecordForm.phone"
                        placeholder="请输入代理商账号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        size
                        v-model="financeRecordForm.income_type"
                        clearable
                        placeholder="请选择入账类型"
                    >
                        <el-option label="收入" value="1"></el-option>
                        <el-option label="支出" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="financeRecordForm.type" clearable placeholder="请选择科目">
                        <el-option
                            v-for="(item,index) in typeList"
                            :label="item.name"
                            :value="item.type"
                            :key="index"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="入账开始日期"
                        end-placeholder="入账结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(financeRecordForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-s-promotion" @click>占位</el-button>
            </div>-->
            <el-table
                :data="financeRecordTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 所属终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="所属终端类型"
                    min-width="200"
                ></el-table-column>
                <!-- 所属一级代理名称 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_name"
                    label="所属一级代理名称"
                    min-width="120"
                ></el-table-column> -->
                <!-- 代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 代理商账户 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账户"
                    min-width="150"
                ></el-table-column>
                <!-- 账户类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop
                    label="账户类型"
                    min-width="120"
                >
                    <template slot-scope>钱包余额</template>
                </el-table-column>
                <!-- 入账类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="income_type"
                    label="入账类型"
                    min-width="100"
                    :formatter="formatIncomeType"
                ></el-table-column>
                <!-- 科目 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="type"
                    label="科目"
                    min-width="150"
                ></el-table-column>
                <!-- 来源 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="form"
                    label="来源"
                    min-width="200"
                ></el-table-column>
                <!-- 金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="money"
                    label="金额"
                    min-width="120"
                ></el-table-column>
                <!-- 当前余额 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="balance"
                    label="当前余额"
                    min-width="120"
                ></el-table-column>-->
                <!-- 入账时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="入账时间"
                    min-width="150"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
export default {
    name: "FM_financial",
    data() {
        return {
            rangeDate: '', //时间范围
            brandList: [],
            typeList: [], //科目列表
            financeRecordTable: [], //表格
            financeRecordForm: {
                brand_id: '', //终端类型id
                real_name: '', //代理商名称
                phone: '', //代理商账户
                income_type: '', //入账类型
                type: '', //科目
                start_create_time: '',    //申请开始时间
                end_create_time: '',    //申请结束时间
                page: 1,
                limit: 10
            },
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true,
        }
    },
    mounted() {
        this.getBrand();
        this.getType();
        //获取科目
        this.getData( //初始化
            {
                page: 1,
                limit: this.page.pageSize
            }
        );
    },
    components: {
        paginations
    },
    methods: {
        onSearch(financeRecordForm) {
            this.page.currentPage = 1;
            this.financeRecordForm.page = 1;
            // 表单提交
            this.getData(financeRecordForm);
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.financeRecordForm.page = this.page.currentPage;
            this.financeRecordForm.limit = this.page.pageSize;
            this.getData(this.financeRecordForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.financeRecordForm.page = this.page.currentPage;
            this.financeRecordForm.limit = limit;
            this.getData(this.financeRecordForm);
        },
        getData(params) {
            this.$api.post("user/balance_log_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.financeRecordTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        formatIncomeType(row) {
            let inComeType;
            switch (row.income_type) {
                case 1:
                    inComeType = '收入';
                    break;

                default:
                    inComeType = '支出';
                    break;
            }
            return inComeType;
        },
        getBrand() { //获取终端类型
            this.$api.post('brand/brand_list', {}, res => {
                if (res.code == 0) {
                    this.brandList = res.data.list;
                } else {
                    this.$$message.success(res.msg);
                }
            });
        },
        getType() { //获取科目
            this.$api.post('get/balance_type', {}, res => {
                if (res.code == 0) {
                    this.typeList = res.data;
                } else {
                    this.$$message.success(res.msg);
                }
            });
        },
        pickerChange(DateArr) { //时间选择器
            this.financeRecordForm.start_create_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.financeRecordForm.end_create_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
};
</script>

<style lang="scss" scope>
.FM_financial {
    .realTitle {
        :nth-child(1) {
            font-size: 12px;
            font-weight: 700;
            color: #1e1e1e;
        }
        .imgParent {
            margin: 10px 0;
            // width: 500px;
            overflow: hidden;
            img {
                width: 100%;
                height: 250px;
                border-radius: 10px;
            }
        }
    }
    .el-dialog__header {
        padding: 20px 20px 0px;
    }
    .el-dialog__body {
        padding: 0px 20px 30px 20px;
    }
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
