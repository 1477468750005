<template>
    <section class="app-main">
        <transition name="fade-transform" mode="out-in">
            <router-view></router-view>
        </transition>
    </section>
</template>

<script>
    export default {
        name: "app-main",
        data(){
            return{

            }
        },
        monthds:{

        }
    }
</script>

<style scoped lang="scss">
    .app-main {
        height: calc(100vh - 100px);
        width: 100%;
        position: absolute;
        overflow: auto;
        // margin-top: 100px;
        bottom: 0;
        left: 0;
        padding:10px 20px;
        box-sizing: border-box;
    }
    /* fade-transform */
    .fade-transform-leave-active,
    .fade-transform-enter-active {
        transition: all .5s;
    }

    .fade-transform-enter {
        opacity: 0;
        transform: translateX(-30px);
    }

    .fade-transform-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }
</style>