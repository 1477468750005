<template>
    <div class="offsetDetail">
        <el-dialog
            title="抵消明细"
            width="1000px"
            :visible="offsetInfo.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <!-- 表格部分 -->
            <el-card class="box-card" style="margin-top: 20px;" shadow="always">
                <el-table
                    :data="offsetDetailList"
                    size="mini"
                    border
                    style="width: 100%"
                    v-loading="loading"
                >
                    <!-- 抵消订单号 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="order_num"
                        label="抵消订单号"
                        min-width="250"
                    ></el-table-column>
                    <!-- 终端类型 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="brand_name"
                        label="终端类型"
                        min-width="120"
                    ></el-table-column>
                    <!-- 终端单价 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="terminal_price"
                        label="终端单价"
                        min-width="120"
                    ></el-table-column>
                    <!-- 数量 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="terminal_num"
                        label="数量"
                        min-width="120"
                    >
                        <template slot-scope="scope">
                            <el-button
                                @click="handleTerminalDetail(scope.row)"
                                type="text"
                                size="small"
                            >{{scope.row.terminal_num}}</el-button>
                        </template>
                    </el-table-column>
                    <!-- 抵消金额 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="offset_money"
                        label="抵消金额"
                        min-width="120"
                    ></el-table-column>
                    <!-- 剩余欠款处理方式 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="processing_method"
                        label="剩余欠款处理方式"
                        min-width="120"
                    ></el-table-column>
                    <!-- 抵消时间 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="create_time"
                        label="抵消时间"
                        min-width="150"
                    ></el-table-column>
                </el-table>
                <!-- 终端明细 -->
                <terminal-detail
                    v-if="terminalInfo.isShow"
                    :terminalInfo="terminalInfo"
                    @infoClose="handleTerminalClose"
                ></terminal-detail>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import terminalDetail from './terminalDetail';
export default {
    name: "offsetDetail",
    props: {
        offsetInfo: {
            type: Object
        }
    },
    data() {
        return {
            terminalInfo: {
                url: '',
                info: {
                    return_log_id: ''
                },
                isShow: false,
            },
            offsetDetailList: [], //列表
            loading: false, //列表loadding
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
        }
    },
    mounted() {
        this.getData({
            id: this.offsetInfo.info.id
        });
    },
    components: {
        terminalDetail
    },
    methods: {
        handleTerminalDetail(row) { //查看终端明细
            this.terminalInfo.isShow = true;
            this.terminalInfo.info.return_log_id = row.terminal_return_main_id;
            this.terminalInfo.url = 'terminal_return/get_log_terminal';
        },
        handleTerminalClose() { //关闭终端明细
            this.terminalInfo.isShow = false;
            this.terminalInfo.info.return_log_id = '';
            this.terminalInfo.url = '';
        },
        handleClose() { //关闭
            this.$emit('handleClose');
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("user_installment_loan/get_installment_offset_log", params, res => {
                if (res.code == 0) {
                    this.offsetDetailList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped lang="scss">
</style>