<template>
    <div class="BS_ParamSetting">
        <el-card class="box-card" shadow="hover">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="提现设置" name="withdraw">
                    <el-form :model="paramForm" label-position="right" label-width="100px">
                        <el-form-item label="起提金额：">
                            <div class="rm-input-number">
                                <el-input-number
                                    size="medium"
                                    style="width:100%"
                                    :precision="2"
                                    :min="0"
                                    :max="100000"
                                    placeholder="请输入起提金额"
                                    :controls="false"
                                    v-model="paramForm.withdraw_money"
                                ></el-input-number>
                                <span class="rm-number-aux">元</span>
                            </div>
                            <div style="color:red;font-size:12px;">注意：起提金额为：10元 - 100000元。</div>
                        </el-form-item>
                        <el-form-item label="预计到账时间：">
                            <div class="rm-input-number">
                                <el-input-number
                                    size="medium"
                                    style="width:100%"
                                    :min="1"
                                    placeholder="请输入预计到账时间"
                                    :controls="false"
                                    v-model="paramForm.withdraw_day"
                                ></el-input-number>
                                <span class="rm-number-aux">天</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="代收税点：">
                            <el-radio-group v-model="paramForm.withdraw_fee_switch">
                                <el-radio label="1">开启</el-radio>
                                <el-radio label="0">关闭</el-radio>
                            </el-radio-group>
                            <template v-if="paramForm.withdraw_fee_switch == 1">
                                <div>
                                    <el-radio-group v-model="paramForm.withdraw_feetype_switch">
                                        <el-radio label="1">按照固定金额</el-radio>
                                        <el-radio label="2">按照金额比例</el-radio>
                                    </el-radio-group>
                                </div>
                                <template v-if="paramForm.withdraw_feetype_switch == 2">
                                    <div class="rm-input-number">
                                        <el-input-number
                                            size="medium"
                                            style="width:100%"
                                            :min="0"
                                            :max="100"
                                            placeholder="0-100"
                                            :controls="false"
                                            v-model="paramForm.withdraw_rate"
                                        ></el-input-number>
                                        <span class="rm-number-aux">%</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="rm-input-number">
                                        <el-input-number
                                            size="medium"
                                            style="width:100%"
                                            :min="0"
                                            :max="100"
                                            placeholder="0-100"
                                            :controls="false"
                                            v-model="paramForm.withdraw_fee"
                                            key="fee"
                                        ></el-input-number>
                                        <span class="rm-number-aux">元</span>
                                    </div>
                                </template>
                            </template>
                        </el-form-item>
                        <el-form-item label="出款服务费：">
                            <el-radio-group v-model="paramForm.service_fee_status">
                                <el-radio label="1">开启</el-radio>
                                <el-radio label="2">关闭</el-radio>
                            </el-radio-group>
                            <template v-if="paramForm.service_fee_status == 1">
                                <div>
                                    <el-radio-group v-model="paramForm.service_fee_switch">
                                        <el-radio label="1">按照固定金额</el-radio>
                                        <el-radio label="2">按照金额比例</el-radio>
                                    </el-radio-group>
                                </div>
                                <template v-if="paramForm.service_fee_switch == 2">
                                    <div class="rm-input-number">
                                        <el-input-number
                                            size="medium"
                                            style="width:100%"
                                            :min="0"
                                            :max="100"
                                            placeholder="0-100"
                                            :controls="false"
                                            v-model="paramForm.service_fee_rate"
                                            key="rate"
                                        ></el-input-number>
                                        <span class="rm-number-aux">%</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="rm-input-number">
                                        <el-input-number
                                            size="medium"
                                            style="width:100%"
                                            :min="0"
                                            :max="100"
                                            placeholder="0-100"
                                            :controls="false"
                                            v-model="paramForm.service_fee_money"
                                        ></el-input-number>
                                        <span class="rm-number-aux">元</span>
                                    </div>
                                </template>
                            </template>
                        </el-form-item>
                        <el-form-item label="提现是否秒到：">
                            <el-radio-group v-model="paramForm.at_once_status">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="2">否</el-radio>
                            </el-radio-group>
                            <template v-if="paramForm.at_once_status == 1">
                                <el-form :inline="true">
                                    <el-form-item>
                                        <span style="font-size:12px">提现金额大于</span>
                                    </el-form-item>
                                    <el-form-item>
                                        <div class="rm-input-number">
                                            <el-input-number
                                                size="medium"
                                                style="width:100%"
                                                :min="0"
                                                :controls="false"
                                                v-model="paramForm.at_once_money"
                                            ></el-input-number>
                                            <span class="rm-number-aux">元</span>
                                        </div>
                                    </el-form-item>
                                    <el-form-item>
                                        <span style="font-size:12px">时需要审核</span>
                                    </el-form-item>
                                </el-form>
                            </template>
                        </el-form-item>
                    </el-form>
                    <div style="margin-top:20px">
                        <el-button
                            type="primary"
                            :loading="loading"
                            style="font-size: 12px;"
                            @click="withdrawSubmit(paramForm)"
                        >保存设置</el-button>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="接收设置" name="accept">
                    <el-form :model="acceptForm" label-position="right" label-width="120px">
                        <el-form-item label="是否默认接收：">
                            <el-radio-group v-model="acceptForm.is_open">
                                <el-radio label="1">开启</el-radio>
                                <el-radio label="0">关闭</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <template v-if="acceptForm.is_open != 0">
                            <el-form-item label="设置时间(分钟)：">
                                <el-input
                                    size="medium"
                                    v-model="acceptForm.time"
                                    placeholder="请输入时间"
                                ></el-input>
                            </el-form-item>
                            <div class="warmTips">
                                温馨提示：上级给下级划拨时，判断是否默认接收；例如：
                                开启，时间设置为10分钟，则上级给下级划拨机器后，10分钟内下级未接收时，则系统默认接收；
                                关闭，则不默认接收，需等待下级操作。
                            </div>
                        </template>
                    </el-form>
                    <div style="margin-top:20px">
                        <el-button
                            type="primary"
                            :loading="loading"
                            style="font-size: 12px;"
                            @click="acceptSubmit(acceptForm)"
                        >保存设置</el-button>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="在线客服" name="service">
                    <el-form :model="serviceForm" label-position="right" label-width="100px">
                        <el-form-item label="客服类型：">
                            <el-radio-group v-model="serviceForm.online_service_type">
                                <el-radio label="1">云客服</el-radio>
                                <el-radio label="2">自定义客服</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <template v-if="serviceForm.online_service_type == 2">
                            <el-form-item label="后台链接地址：">
                                <el-input
                                    size="medium"
                                    v-model="serviceForm.online_service_admin_url"
                                    placeholder="请输入后台链接地址"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="APP链接地址：">
                                <el-input
                                    size="medium"
                                    v-model="serviceForm.online_service_app_url"
                                    placeholder="请输入APP链接地址"
                                ></el-input>
                            </el-form-item>
                        </template>
                    </el-form>
                    <div style="margin-top:20px">
                        <el-button
                            type="primary"
                            :loading="loading"
                            style="font-size: 12px;"
                            @click="serviceSubmit(serviceForm)"
                        >保存设置</el-button>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="代付通道配置" name="channel">
                    <el-form :model="channelForm" label-position="right" label-width="120px">
                        <el-form-item
                            v-for="(item,key,index) in channelOptionList"
                            :label="`${item.name}：`"
                            :key="index"
                        >
                            <el-input
                                :disabled="item.isgrey == 1"
                                v-model="item.value"
                                :type="item.type == 1? 'input': 'textarea'"
                                clearable
                                autocomplete="off"
                                :placeholder="`请输入${item.name}`"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                    <div style="margin-top:20px">
                        <el-button
                            type="primary"
                            :loading="loading"
                            style="font-size: 12px;"
                            @click="handleChannelSubmit(channelForm)"
                        >保存设置</el-button>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeName: 'withdraw',
            loading: false,
            channelOptionList: [], // 代付配置项
            paramForm: {
                withdraw_fee_switch: 1,
                service_fee_status: 1,
            },
            acceptForm: {},
            serviceForm: {},
            subsidyForm: {},
            channelForm: {},  // 代付通道配置
        }
    },
    mounted() {
        this.getDetail({ k: 'withdraw' });//初始化
        this.getDetail({ k: 'email' });//初始化
        this.getDetail({ k: 'receive' });//初始化
        this.getDetail({ k: 'online_service' });//初始化
        // this.getDetail({ k: 'subsidy_fee' });//初始化
        this.getOption();
    },
    methods: {
        handleClick() {

        },
        withdrawSubmit(paramForm) { //提现提交
            paramForm.k = 'withdraw';
            this.sendParams(paramForm, 'withdraw');
        },
        acceptSubmit(acceptForm) { //接收提交
            acceptForm.k = "receive";
            this.sendParams(acceptForm, 'receive');
        },
        serviceSubmit(serviceForm) { //在线客服提交
            serviceForm.k = "online_service";
            this.sendParams(serviceForm, 'online_service');
        },
        subsidySubmit(subsidyForm) { //补贴手继续设置
            subsidyForm.k = "subsidy_fee";
            this.sendParams(subsidyForm, 'subsidy_fee');
        },
        getDetail(reqData) { //获取详情
            this.$api.post('setting/getSetting', reqData, res => {
                if (res.code == 0) {
                    if (reqData.k == "withdraw") {
                        this.paramForm = { ...this.paramForm, ...res.data };
                    }
                    if (reqData.k == "receive") {
                        this.acceptForm = { ...this.acceptForm, ...res.data };
                    }
                    if (reqData.k == "email") {
                        this.emailForm = { ...this.emailForm, ...res.data };
                    }
                    if (reqData.k == "online_service") {
                        this.serviceForm = { ...this.serviceForm, ...res.data };
                    }
                    if (reqData.k == "subsidy_fee") {
                        this.subsidyForm = { ...this.subsidyForm, ...res.data };
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        sendParams(reqData, k) { //请求
            this.loading = true;
            this.$api.post('setting/update_setting', reqData, res => {
                let that = this;
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.loading = false;
                            that.getDetail({ k });//初始化
                        }
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.loading = false;
                        }
                    });
                }
            })
        },
        getOption() { // 获取代付配置项
            this.$api.post('payment_account/get_payment_account_data', {}, res => {
                if (res.code == 0) {
                    this.channelOptionList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleChannelSubmit(channelForm) { // 代付提交
            this.loading = true;
            this.channelOptionList.forEach(item => {
                channelForm[item.key] = item.value
            });
            let reqData = this._.omit(channelForm, ['ip_whitelist', 'callback_url']);
            let _this = this;
            this.$api.post('payment_account/payment_account_save', { post_parameter: JSON.stringify(reqData) }, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '保存成功',
                        onClose() {
                            _this.loading = false;
                            _this.getOption();
                        }
                    })
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            });

        }
    }
}
</script>

<style scoped lang="scss">
.el-form {
    width: 500px;
}
.warmTips {
    margin-left: 120px;
    width: 500px;
    font-size: 12px;
    color: red;
}
</style>
