<template>
    <div class>
        <el-dialog
            ref="dependenceForm"
            :visible="info.isShow"
            @close="handleClose"
            :close-on-click-modal="false"
            width="800px"
            :title="`${agentTitle}的挂靠关系`"
        >
            <el-card class="box-card" shadow="hover" v-loading="initLoading">
                <el-form :model="dependenceForm" label-position="top" label-width="140px">
                    <el-form-item label="当前代理角色">
                        <el-input
                            size="medium"
                            suffix-icon="el-icon-aux"
                            disabled
                            v-model="dependenceForm.role_name"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="当前挂靠对象" v-if="dependenceForm.role_id != 1">
                        <!-- <el-form-item label="当前挂靠对象" v-if="true"> -->
                        <el-input
                            size="medium"
                            disabled
                            suffix-icon="el-icon-aux"
                            v-model="dependenceForm.parent_name"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="选择挂靠角色">
                        <el-select
                            size="medium"
                            style="width:100%"
                            v-model="dependenceForm.parent_role"
                            :disabled="dependenceForm.level == 2"
                            @change="handleRoleChange"
                            placeholder="请选择挂靠角色"
                        >
                            <el-option
                                v-for="item in roleLimit"
                                :label="item.name"
                                :value="item.id"
                                :key="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <template>
                        <el-form-item label="选择挂靠对象">
                            <el-select
                                size="medium"
                                style="width:100%"
                                v-model="dependenceForm.parent_id"
                                :disabled="dependenceForm.level == 2"
                                @change="handlePersonChange"
                                placeholder="请选择挂靠对象"
                            >
                                <el-option
                                    v-for="item in personList"
                                    :label="item.app_phone == ''? item.agent_name : `${item.agent_name}-${item.app_phone}` "
                                    :value="item.id"
                                    :key="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择终端类型">
                            <el-table
                                max-height="500px"
                                size="small"
                                :data="configList"
                                style="width: 100%"
                            >
                                <el-table-column header-align="center" align="center" label="终端类型">
                                    <template slot-scope="scope">
                                        <el-select
                                            size="medium"
                                            style="width:100%"
                                            v-model="scope.row.brand_id"
                                            @change="handleBrandChange($event,scope.$index)"
                                            placeholder="请选择终端类型"
                                        >
                                            <el-option
                                                v-for="item in brandList"
                                                :label="item.name"
                                                :value="item.id"
                                                :key="item.id"
                                            ></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column header-align="center" align="center" label="交易类型">
                                    <template slot-scope="scope">
                                        <el-checkbox-group v-model="scope.row.pay_types">
                                            <el-checkbox
                                                v-for="item in scope.row.pay_type_data"
                                                :label="`${item.id}`"
                                                :key="item.id"
                                            >{{item.name}}</el-checkbox>
                                        </el-checkbox-group>
                                    </template>
                                </el-table-column>
                                <el-table-column header-align="center" align="center" label="暗返比例">
                                    <template slot-scope="scope">
                                        <el-input
                                            size="medium"
                                            v-model="scope.row.rate"
                                            placeholder="请输入暗返比例"
                                        >
                                            <i slot="suffix" class="rate-icon">%</i>
                                        </el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    width="120"
                                    header-align="center"
                                    align="center"
                                    label="操作"
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                            v-if="scope.$index == configList.length-1"
                                            type="primary"
                                            size="small"
                                            icon="el-icon-plus"
                                            plain
                                            @click="handleTableAdd(scope.row,scope.$index)"
                                        ></el-button>
                                        <el-button
                                            v-if="scope.$index != 0"
                                            type="danger"
                                            size="small"
                                            icon="el-icon-minus"
                                            plain
                                            @click="handleTableDel(scope.row,scope.$index)"
                                        ></el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                        <el-form-item label="设置返现日期">
                            <el-col :span="11">
                                <el-date-picker
                                    type="date"
                                    placeholder="开始时间"
                                    v-model="dependenceForm.start_date"
                                    value-format="yyyy-MM-dd"
                                    style="width: 100%;"
                                    :picker-options="pickerStart"
                                ></el-date-picker>
                            </el-col>
                            <el-col class="line" :span="2">-</el-col>
                            <el-col :span="11">
                                <el-date-picker
                                    type="date"
                                    placeholder="结束时间"
                                    v-model="dependenceForm.end_date"
                                    value-format="yyyy-MM-dd"
                                    style="width: 100%;"
                                    :picker-options="pickerEnd"
                                ></el-date-picker>
                            </el-col>
                        </el-form-item>
                    </template>
                    <el-form-item>
                        <el-button
                            type="primary"
                            :loading="loading"
                            style="font-size: 12px;"
                            @click="handleSubmit(dependenceForm)"
                        >保存设置</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            pickerStart: { // 开始时间
                disabledDate(time) {
                    const date = new Date();
                    return time.getTime() < date.getTime() - 3600 * 1000 * 24;
                }
            },
            pickerEnd: { // 结束时间
                disabledDate(time) {
                    return time.getTime() < Date.now();
                }
            },
            configList: [], // 选择终端部分数据
            brandList: [], // 品牌列表
            agentTitle: this.info.row.agent_name, // 弹窗title
            initLoading: false, // 初始化loading
            loading: false, // 提交loading
            roleList: [], //挂靠角色列表
            personList: [], // 挂靠人列表
            baseInfo: {}, // 基础信息
            configInfo: [], // 配置信息
            dependenceForm: {
                agent_id: this.info.row.id,
            }
        }
    },
    created() {
        this.getData().then(result => { // 初始化
            this.getRole(result.role_id); // 获取角色列表
            this.getBrand();
            if (this.dependenceForm.parent_role) { // 存在parent_role取挂靠对象
                this.getPerson(result.parent_role);
            }
        })
    },
    mounted() {

    },
    methods: {
        handleClose(ref) {
            this.$emit('infoClose', ref);
        },
        handleSubmit(dependenceForm) { //后台提交
            this.loading = true; //防重复
            let rate_data = [];
            this.configList.forEach((item, index) => { // 移除pay_type_data
                rate_data.push(this._.omit(item, ['pay_type_data']));
                rate_data[index].pay_types = item.pay_types.filter(item => { // 处理空格的情况
                    return item != "";
                }).join();
            })
            dependenceForm.rate_data = JSON.stringify(rate_data); // 转JSON
            dependenceForm.role_id = dependenceForm.parent_role; // 后台取role_id 不取parent_id
            this.$api.post('agent_affiliation/save_affiliation', dependenceForm, res => {
                let that = this;
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.loading = false;
                            that.handleClose(1);
                        }
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.loading = false;
                        }
                    });
                }
            })
        },
        handleTableAdd() { // 添加
            let newData = {
                brand_id: '',
                pay_type_data: [],
                pay_types: [],
                rate: ""
            };
            this.configList.push(newData);
        },
        handleTableDel(row, index) { // 删除
            this.configList.splice(index, 1);
        },
        handleBrandChange(id, index) { // 监听终端类型切换
            this.getPayType(id, index);
        },
        handleRoleChange(val) { // 监听切换挂靠角色
            this.getPerson(val);
            this.dependenceForm.parent_id = '';
            this.dependenceForm.start_date = '';
            this.dependenceForm.end_date = '';
        },
        handlePersonChange() { // 监听切换挂靠对象
            this.dependenceForm.start_date = '';
            this.dependenceForm.end_date = '';
        },
        getRole(role_id) { // 获取代理商角色
            this.$api.post("agent/get_role_list", { role_id }, res => {
                if (res.code == 0) {
                    this.roleList = res.data;
                    this.roleList.unshift({
                        id: 0,
                        level: 0,
                        name: '无'
                    })
                }
            })
        },
        getPerson(params) { // 获取挂靠人
            let reqData = {
                role_id: params
            };
            this.$api.post("agent_affiliation/get_affiliation_person", reqData, res => {
                if (res.code == 0) {
                    this.personList = [];
                    this.personList = res.data;
                }
            })
        },
        getBrand() { // 获取品牌
            this.$api.post('brand/brand_list', {}, res => {
                if (res.code == 0) {
                    this.brandList = res.data.list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getPayType(id, itemIndex) { // 获取支付类型
            let params = {
                brand_id: id,
                return_selected: 1
            };
            this.$api.post('brand/brand_pay_type', params, res => {
                if (res.code == 0) {
                    this.configList[itemIndex].pay_types = [];
                    this.configList[itemIndex].pay_type_data = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getData() { // 获取结算信息
            this.initLoading = true;
            return new Promise(resolve => {
                this.$api.post('agent_affiliation/get_affiliation', { agent_id: this.info.row.id }, res => {
                    if (res.code == 0) {
                        let data = res.data;
                        this.dependenceForm = { ...this.dependenceForm, ...this._.omit(data, ['config']) };
                        if (data.config.length != 0) {
                            this.configList = data.config; // 配置列表
                        } else {
                            this.configList.push({
                                brand_id: '',
                                pay_type_data: [],
                                pay_types: [],
                                rate: ""
                            })
                        }
                        resolve(data);
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.initLoading = false;
                })
            })

        }
    },
    computed: {
        roleLimit() { // 返回小于当前等级的角色
            let roles = this.roleList.filter(item => {
                return item.level < this.dependenceForm.level;
            })
            return roles;
        }
    }
}
</script>

<style scoped lang="scss">
.m-title {
    color: #303133;
    font-size: 14px;
    font-weight: 700;
    padding-left: 10px;
    margin: 10px 0;
    border-left: 4px solid #409eff;
}
.line {
    text-align: center;
}
.rate-icon {
    line-height: 36px;
    font-style: normal;
}
// .el-form {
//     width: 500px;
// }
</style>
