<template>
    <div class="statisticList">
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="always">
            <div style="margin-bottom: 20px;">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    :loading="downLoading"
                    @click="handleDownLoad"
                >导出数据</el-button>
            </div>
            <el-table
                :data="statisticList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 日期 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="month"
                    label="日期"
                    min-width="150"
                ></el-table-column>
                <!-- 总支出 -->
                <el-table-column header-align="center" align="center" label="总支出" min-width="150">
                    <template slot-scope="scope">
                        <el-button
                            @click="handleCheckInfo(scope.row)"
                            type="text"
                            size="small"
                        >{{scope.row.total_money}}</el-button>
                    </template>
                </el-table-column>
                <!-- 交易分润支出 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="profit_money"
                    label="交易分润支出"
                    min-width="150"
                ></el-table-column>
                <!-- 奖励支出 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="award_money"
                    label="奖励支出"
                    min-width="150"
                ></el-table-column>
                <!-- 提现金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="withdrawal_money"
                    label="提现金额"
                    min-width="150"
                ></el-table-column>
                <!-- 沉淀资金 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="user_balance_money"
                    label="沉淀资金"
                    min-width="150"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <total-expenditure v-if="info.isShow" :info="info" @infoClose="handleInfoClose"></total-expenditure>
    </div>
</template>

<script>
import totalExpenditure from './components/moneyStatisticsDetail';
export default {
    data() {
        return {
            info: {
                isShow: false,
                row: {}
            },
            downLoading: false,
            statisticList: [], //角色列表
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        })
    },
    components: {
        totalExpenditure
    },
    methods: {
        handleCheckInfo(row) {
            this.info.row = row;
            this.info.isShow = true;
        },
        handleInfoClose() {
            this.info.isShow = false;
            this.info.row = {};
        },
        handleDownLoad() { //导出数据
            this.downLoading = true;
            let handleExportExcel = this.common.handleExportExcel;
            let value = {};
            handleExportExcel({ type: 2, value: JSON.stringify(value) }).then(() => {
                this.downLoading = false;
            });
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.getData({
                page: page,
                limit: this.pageSize
            })
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.getData({
                page: 1,
                limit: limit
            })
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("organization/money_statistics_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.statisticList = data.list;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
