<template>
    <div class="offsetDetail">
        <el-dialog
            title="分期明细"
            width="90%"
            :visible="stageInfo.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <!-- 表格部分 -->
            <el-card class="box-card" style="margin-top: 20px;" shadow="always">
                <el-table
                    :data="stageDetailList"
                    size="mini"
                    border
                    max-height="400px"
                    style="width: 100%"
                    v-loading="loading"
                >
                    <!-- 货款期数 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="period"
                        label="货款期数"
                        min-width="120"
                    ></el-table-column>
                    <!-- 是否出账单 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="is_out_bill"
                        label="是否出账单"
                        min-width="120"
                    ></el-table-column>
                    <!-- 应还金额 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="current_money"
                        label="应还金额"
                        min-width="120"
                    ></el-table-column>
                    <!-- 已还金额 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="completed_money"
                        label="已还金额"
                        min-width="120"
                    ></el-table-column>
                    <!-- 还款状态 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="status"
                        label="还款状态"
                        min-width="120"
                    ></el-table-column>
                    <!-- 操作 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        fixed="right"
                        label="操作"
                        min-width="120"
                    >
                        <template slot-scope="scope">
                            <el-button
                                v-show="scope.row.is_repayment_detail == 1"
                                @click="handleRepaymentDetail(scope.row)"
                                type="text"
                                size="small"
                            >还款明细</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <repayment-info
                    v-if="repaymentInfo.isShow"
                    :repaymentInfo="repaymentInfo"
                    @handleClose="handleRepaymentClose"
                ></repayment-info>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import repaymentInfo from './loanRepaymentDetail';
export default {
    name: "offsetDetail",
    props: {
        stageInfo: {
            type: Object
        }
    },
    data() {
        return {
            repaymentInfo: { //还款明细
                isShow: false,
                info: {}
            },
            stageDetailList: [], //列表
            loading: false, //列表loadding
        }
    },
    mounted() {
        this.getData({
            id: this.stageInfo.info.id
        });
    },
    components: {
        repaymentInfo
    },
    methods: {
        handleRepaymentDetail(row) { //还款明细
            this.repaymentInfo.isShow = true;
            this.repaymentInfo.info = row;
        },
        handleRepaymentClose() {
            this.repaymentInfo.isShow = false;
            this.repaymentInfo.info = {};
        },
        handleClose() { //关闭
            this.$emit('handleClose');
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("user_installment_loan/get_installment_detail", params, res => {
                if (res.code == 0) {
                    this.stageDetailList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped lang="scss">
</style>