<template>
    <div class="blocked">
        <el-dialog
            ref="blocked"
            :visible="blockedIsShow"
            @close="handleClose"
            :close-on-click-modal="false"
            title="冻结代理商"
        >
            <el-card class="box-card" shadow="hover">
                <el-form :model="blockedForm" label-position="top">
                    <el-form-item label="冻结方式">
                        <el-select size v-model="blockedForm.type" clearable placeholder="请选择冻结方式">
                            <el-option label="提现冻结" value="1"></el-option>
                            <el-option label="登陆冻结" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="冻结原因备注">
                        <el-input type="textarea" v-model="blockedForm.reason"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-top:20px">
                        <el-button
                            type="primary"
                            :loading="blockedLoading"
                            style="font-size: 12px;"
                            @click="handleBlocked(blockedForm)"
                        >确认冻结</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "UM_blockedUser",
    props: {
        blockedIsShow: {
            type: Boolean,
            default: false
        },
        userId: {
            type: [Number, String]
        }
    },
    data() {
        return {
            blockedLoading: false,
            blockedForm: {
                type: '2',
                reason: ''
            }
        }
    },
    mounted() {

    },
    methods: {
        handleBlocked(reqData) { //确认冻结
            let that = this;
            reqData.user_id = this.userId;
            this.blockedLoading = true; //防重复
            this.$api.post('user/freeze', reqData, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.handleClose(1); //刷新父页面
                        }
                    })
                } else {
                    this.$message.error(res.msg);
                    this.blockedLoading = false;
                }
            })
        },
        handleClose(ref) {
            this.$emit('blockedClose', ref)
        }
    }
}
</script>

<style scoped lang="scss">
.blocked {
    ::v-deep .el-form-item__label {
        font-size: 12px;
        font-weight: 700;
    }
    .el-textarea {
        height: 80px;
        ::v-deep .el-textarea__inner {
            font-size: 12px;
            min-height: 80px !important;
        }
    }
}
</style>
