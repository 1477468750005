import router from './router'

router.beforeEach((to, from, next)=>{
    const hasToken = sessionStorage.getItem('token')
    if(to.path == '/login'){
        next()
    }else{
        if(hasToken){
            next()
        }else{
            next({path:'/login'})
        }
    }

    if(to.matched.length == 0){
        next({path:'/'})
    }
})
