<template>
    <div class="installmentLoanList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="searchForm.borrow_user_name"
                        placeholder="请输入借款代理商名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="searchForm.borrow_user_phone"
                        placeholder="请输入借款代理商账号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="searchForm.loan_user_name"
                        placeholder="请输入出款代理商名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="searchForm.loan_user_phone"
                        placeholder="请输入出款代理商账号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-table
                :data="installmentList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 出款方代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="loan_user_name"
                    label="出款方代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 出款方代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="loan_user_phone"
                    label="出款方代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 借款代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="borrow_user_username"
                    label="借款代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 借款代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="borrow_user_phone"
                    label="借款代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 还款方式 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="repayment_status"
                    label="还款方式"
                    min-width="120"
                ></el-table-column>
                <!-- 延期入账金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="undone_wallet"
                    label="延期入账金额"
                    min-width="120"
                ></el-table-column>
                <!-- 当前欠款 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="arrears_money"
                    label="当前欠款"
                    min-width="120"
                ></el-table-column>
                <!-- 本期应还 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="current_wallet"
                    label="本期应还"
                    min-width="120"
                ></el-table-column>
                <!-- 逾期金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="overdue_wallet"
                    label="逾期金额"
                    min-width="120"
                ></el-table-column>
                <!-- 逾期费用 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="loan_name"
                    label="逾期费用"
                    min-width="120"
                ></el-table-column>-->
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">详情</el-button>
                        <el-button
                            v-show="scope.row.is_platform == 1 && scope.row.is_arrears_money == 1"
                            @click="handleOffset(scope.row)"
                            type="text"
                            size="small"
                        >核销</el-button>
                        <el-button
                            v-show="scope.row.is_write_off == 1"
                            @click="handleOffsetRecord(scope.row)"
                            type="text"
                            size="small"
                        >核销记录</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
            <!-- 核销 -->
            <offset-page
                v-if="offsetMoney.isShow"
                :offsetMoney="offsetMoney"
                @handleClose="handleoffsetMoneyClose"
            ></offset-page>
            <!-- 核销记录 -->
            <offset-record
                v-if="offsetInfo.isShow"
                :offsetInfo="offsetInfo"
                @handleClose="handleOffsetRecordClose"
            ></offset-record>
        </el-card>
    </div>
</template>

<script>
import offsetPage from './components/offsetPage'; //核销
import offsetRecord from './components/offsetRecord'; //核销记录
export default {
    name: "UM_loanList",
    data() {
        return {
            offsetMoney: { //核销
                isShow: false,
                info: {}
            },
            offsetInfo: { //核销记录
                isShow: false,
                info: {}
            },
            installmentList: [], //列表
            loading: false, //列表loadding
            searchForm: { //搜索表单
                loan_user_name: '', //出款方代理商名称
                loan_user_phone: '', //出款方代理商账号
                borrow_user_name: '', //借款方代理商名称
                borrow_user_phone: '', //借款方代理商账号
                page: 1,
                limit: 10
            },
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        });
    },
    components: {
        offsetPage,
        offsetRecord
    },
    methods: {
        handleSearch(searchForm) {
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleInfo(row) { //详情
            this.$router.push({
                path: '/installmentLoanInfo',
                query: { pay_id: row.pay_user_id, borrow_id: row.borrow_user_id }
            });
        },
        handleOffset(row) { //核销
            this.offsetMoney.isShow = true;
            this.offsetMoney.info = row;
        },
        handleoffsetMoneyClose(ref) { //关闭核销
            this.offsetMoney.isShow = false;
            this.offsetMoney.info = {};
            if (ref) {
                this.getData({
                    page: this.currentPage,
                    limit: this.pageSize
                });
            }
        },
        handleOffsetRecord(row) { //核销记录
            this.offsetInfo.isShow = true;
            this.offsetInfo.info = row;
        },
        handleOffsetRecordClose() { //关闭核销记录
            this.offsetInfo.isShow = false;
            this.offsetInfo.info = {};
        },
        //分页
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("user_installment_loan/get_installment_loan_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.installmentList = data.list;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
