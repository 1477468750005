<template>
    <div class="MAM_brandList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item>
                    <el-input clearable v-model="formInline.name" placeholder="请输入终端类型名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSubmit"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-plus"
                    @click="handleAddBrand"
                >添加终端类型</el-button>
                <!-- <el-button size="small" type="primary" icon="el-icon-download" @click>数据导出</el-button> -->
            </div>
            <el-table
                :data="brandTable.list"
                size="mini"
                border
                style="width: 100%"
                max-height="500px"
                v-loading="loading"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 终端类型名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="name"
                    label="终端类型名称"
                    min-width="120"
                ></el-table-column>
                <!-- 机具单价 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="price"
                    label="机具单价(元/台)"
                    min-width="120"
                ></el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="创建时间"
                    min-width="150"
                ></el-table-column>
                <!-- 费率设置 -->
                <el-table-column header-align="center" align="center" label="费率设置" min-width="120">
                    <template slot-scope="scope">
                        <el-button
                            @click="handleRate(scope.$index,scope.row)"
                            type="text"
                            size="small"
                        >设置</el-button>
                    </template>
                </el-table-column>
                <!-- 涨价后费率 -->
                <!-- <el-table-column header-align="center" align="center" label="涨价后费率" min-width="120">
                    <template slot-scope="scope">
                        <el-button
                            v-show="scope.row.is_rise_price == 1"
                            @click="handleRise(scope.row)"
                            type="text"
                            size="small"
                        >设置</el-button>
                        <span v-show="scope.row.is_rise_price != 1">--</span>
                    </template>
                </el-table-column>-->
                <!-- 营销活动 -->
                <el-table-column header-align="center" align="center" label="营销活动" min-width="120">
                    <template slot-scope="scope">
                        <el-button
                            @click="handleCampaigns(scope.$index,scope.row)"
                            type="text"
                            size="small"
                        >设置</el-button>
                    </template>
                </el-table-column>
                <!-- 商户费率 -->
                <!-- <el-table-column header-align="center" align="center" label="商户费率" min-width="120">
                    <template slot-scope="scope">
                        <el-button
                            @click="handleMerchantRate(scope.row)"
                            type="text"
                            size="small"
                        >设置</el-button>
                    </template>
                </el-table-column>-->
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button
                            @click="handleCustomPayment(scope.row)"
                            type="text"
                            size="small"
                        >交易方式名称定义</el-button>
                        <el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <!-- 添加、编辑终端类型 -->
        <brand-info
            v-if="brandDetail.isShow"
            :brand-detail="brandDetail"
            @brandClose="handleCloseBrandDetail"
        ></brand-info>
        <!-- 设置费率 -->
        <set-rate
            v-if="rateIsShow"
            :rateIsShow="rateIsShow"
            :brandId="brandId"
            @rateClose="rateClose"
        ></set-rate>
        <!-- 交易方式名称定义 -->
        <customPayment
            v-if="customInfo.isShow"
            :customInfo="customInfo"
            @handleClose="handleCustomClose"
        ></customPayment>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import brandInfo from './MAM_brandInfo';
import setRate from './MAM_setRate';
import customPayment from '../components/customPayment'; //交易方式名称定义
export default {
    name: "MAM_brandList",
    inject: ['reload'],
    data() {
        return {
            customInfo: {
                isShow: false,
                row: {}
            },
            brandDetail: {
                type: 1, // 1.添加 2.编辑
                isShow: false,
                row: {}
            },
            infoIsShow: false, //终端类型详情
            rateIsShow: false, //设置费率
            brandId: null,
            addBrand: false,
            editBrand: false,
            formInline: {
                name: '',
            },
            brandTable: {
                list: [],
            },
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true
        }
    },
    mounted() {
        this.getData({
            page: this.page.currentPage,
            limit: this.page.pageSize,
        })
    },
    components: {
        paginations,
        brandInfo,
        setRate,
        customPayment
    },
    methods: {
        onSubmit() {
            this.loading = true;
            // 表单提交
            this.getData({
                page: 1,
                limit: this.page.pageSize,
                name: this.formInline.name
            })
        },
        handleCustomPayment(row) { //交易方式名称定义
            this.customInfo.isShow = true;
            this.customInfo.row = row;
        },
        handleCustomClose(ref) { //关闭交易方式名称定义
            this.customInfo.isShow = false;
            this.customInfo.row = {};
            if (ref) { //情景刷新
                this.getData({
                    page: this.page.currentPage,
                    limit: this.page.pageSize,
                })
            }
        },
        handleRate(index, row) { //费率设置
            this.rateIsShow = true;
            this.brandId = row.id;
        },
        handleRise(row) { //涨价后费率
            this.$router.push({ path: "/risePrice", query: { id: row.id, } })
        },
        handleCampaigns(index, row) { //营销活动
            this.$router.push({ path: "/brandRewardList", query: { id: row.id, brandName: row.name } })
        },
        handleMerchantRate(row) { //商户费率
            this.$router.push({ path: "/merchantRate", query: { id: row.id, } })
        },
        handleAddBrand() { //添加终端类型
            this.brandDetail.isShow = true;
            this.brandDetail.type = 1;
        },
        handleEdit(row) { //编辑
            this.brandDetail.isShow = true;
            this.brandDetail.type = 2;
            this.brandDetail.row = row;
        },
        handleDelete(row) {
            // brand_id
            this.$confirm('确认删除该终端类型？', '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    let _this = this;
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('brand/del_brand', { brand_id: row.id }, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                        _this.getData({
                                            page: _this.currentPage,
                                            limit: _this.pageSize
                                        });
                                    }
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg
                                });
                                done();
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleCloseBrandDetail(ref) {
            this.brandDetail.isShow = false;
            this.brandDetail.type = 1;
            if (ref) { //情景刷新
                this.getData({
                    page: this.page.currentPage,
                    limit: this.page.pageSize,
                })
            }
        },
        rateClose(ref) { //关闭设置费率
            this.rateIsShow = false;
            if (ref) {
                this.getData({
                    page: this.page.currentPage,
                    limit: this.page.pageSize,
                })
            }
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.loading = true;
            this.getData({
                page: this.page.currentPage,
                limit: this.page.pageSize,
            })
        },
        getPageSize(limit) {
            // 获取条数发生改变的值
            this.page.pageSize = limit;
            this.loading = true;
            this.getData({
                page: this.page.currentPage,
                limit: limit,
            })
        },
        getData(params) {
            this.$api.post("brand/brand_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.brandTable.list = res.data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
