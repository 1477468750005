<template>
    <div class="MAM_brandRewardList">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-table :data="rewardTable" size="mini" border style="width: 100%">
                <el-table-column
                    header-align="center"
                    align="center"
                    min-width="150"
                    prop="brand_name"
                    label="终端类型"
                ></el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    min-width="120"
                    prop="name"
                    label="活动类型"
                ></el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    min-width="120"
                    prop="status"
                    label="状态"
                    :formatter="setStatus"
                ></el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    min-width="120"
                    prop="cut_off_time"
                    label="活动结束时间"
                >
                    <template
                        slot-scope="scope"
                    >{{scope.row.cut_off_time == ''? "--" : scope.row.cut_off_time}}</template>
                </el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    min-width="120"
                    fixed="right"
                    label="操作"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.status == 0 || scope.row.type == 1"
                            @click="handleSet(scope.row)"
                            type="text"
                            size="small"
                        >配置</el-button>
                        <el-button
                            v-else
                            @click="handleCheck(scope.row)"
                            type="text"
                            size="small"
                        >查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!-- 激活奖励 -->
        <active-reward
            v-if="activeInfo.isShow"
            :brandId="brandId"
            :activeInfo="activeInfo"
            @activeClose="activeClose"
        ></active-reward>
        <!-- 交易量阶梯奖励 -->
        <transtion-reward
            v-if="transtionInfo.isShow"
            :brandId="brandId"
            :transtionInfo="transtionInfo"
            @transtionClose="transtionClose"
        ></transtion-reward>
        <!-- 交易量达标奖励 -->
        <standard-reward
            v-if="standardInfo.isShow"
            :brandId="brandId"
            :standardInfo="standardInfo"
            @standardClose="standardClose"
        ></standard-reward>
        <!-- 服务费返现 -->
        <return-deposit
            v-if="returnDeposit.isShow"
            :brandId="brandId"
            :returnDeposit="returnDeposit"
            @depositClose="depositClose"
        ></return-deposit>
    </div>
</template>

<script>
import activeReward from './MAM_activeReward'; // 激活奖励 1.激活奖励 2.交易量达标奖励 3.交易量阶梯奖励 4.服务费返现
import standardReward from './MAM_standardReward'; // 交易量达标奖励
import transtionReward from './MAM_transtionReward'; // 交易量阶梯奖励
import returnDeposit from './MAM_returnDeposit'; // 服务费返现
export default {
    name: "MAM_brandRewardList",
    data() {
        return {
            standardInfo: { //交易量达标
                isShow: false,
                info: null, //0未配置 1进行中 2已结束
                type: null //1.配置 2.查看
            },
            returnDeposit: { //服务费返现
                isShow: false,
                info: null, //0未配置 1进行中 2已结束
                type: null //1.配置 2.查看
            },
            activeInfo: { //激活奖励
                isShow: false,
                info: null, //0未配置 1进行中 2已结束
                type: null //1.配置 2.查看
            },
            transtionInfo: { //激活奖励
                isShow: false,
                info: null, //0未配置 1进行中 2已结束
                type: null //1.配置 2.查看
            },
            brandId: null,
            rewardTable: []
        }
    },
    mounted() {
        this.brandId = this.$route.query.id;
        this.getData();
    },
    components: {
        activeReward,
        standardReward,
        transtionReward,
        returnDeposit
    },
    methods: {
        handleSet(row) { //配置
            switch (row.type) {
                case 1:
                    this.handlePublic(1, 'activeInfo', row);
                    break;
                case 2:
                    this.handlePublic(1, 'standardInfo', row);
                    break;
                case 3:
                    this.handlePublic(1, 'transtionInfo', row);
                    break;
                case 4:
                    this.handlePublic(1, 'returnDeposit', row);
                    break;
            }
        },
        handleCheck(row) { //查看
            switch (row.type) {
                case 1:
                    this.handlePublic(2, 'activeInfo', row);//激活奖励
                    break;
                case 2:
                    this.handlePublic(2, 'standardInfo', row);//交易量达标
                    break;
                case 3:
                    this.handlePublic(2, 'transtionInfo', row);//交易阶梯
                    break;
                case 4:
                    this.handlePublic(2, 'returnDeposit', row);//服务费返现
                    break;
            }
        },
        activeClose(ref) { //关闭激活奖励
            this.handlePublic(3, 'activeInfo');
            if (ref) {
                this.getData();
            }
        },
        transtionClose(ref) { //关闭交易阶梯奖励
            this.handlePublic(3, 'transtionInfo');
            if (ref) {
                this.getData();
            }
        },
        standardClose(ref) { //关闭交易量达标
            this.handlePublic(3, 'standardInfo');
            if (ref) {
                this.getData();
            }
        },
        depositClose(ref) { //关闭服务费返现
            this.handlePublic(3, 'returnDeposit');
            if (ref) {
                this.getData();
            }
        },
        setStatus(row) {
            let status;
            switch (row.status) {
                case 0:
                    status = "未配置";
                    break;
                case 1:
                    status = "进行中";
                    break;
                default:
                    status = "已结束";
                    break;
            }
            return status;
        },
        getData() { //初始化
            this.$api.post("brand/brand_reward_list", { brand_id: this.brandId }, res => {
                if (res.code == 0) {
                    res.data.forEach(item => {
                        item.brand_name = this.$route.query.brandName;
                    })
                    this.rewardTable = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handlePublic(type, name, row) {//1.配置 2.查看 3.关闭
            switch (type) {
                case 1:
                    this[name].isShow = true;
                    this[name].info = row;
                    this[name].type = 1;
                    break;
                case 2:
                    this[name].isShow = true;
                    this[name].info = row;
                    this[name].type = 2;
                    break;
                case 3:
                    this[name].isShow = false;
                    this[name].info = {};
                    this[name].type = null;
                    break;
            }
        }
    }
};
</script>

<style lang="scss" scope>
.MAM_brandRewardList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
