/** 数据管理 */
import turnoverAttainRecord from '../../views/dataManagement/turnoverAttainRecord/DM_attainRecord'; //交易量达标记录查询
import cashBackRecode from '../../views/dataManagement/cashBackRecode/DM_cashBackList'; //服务费返现记录查询
import activationRecord from '../../views/dataManagement/activationRecord/DM_activationList'; //激活记录查询
import awarding from '../../views/dataManagement/awarding/DM_awardingList'; //奖励发放查询
import transactionDetail from '../../views/dataManagement/transactionDetail/DM_transctionList'; //交易明细
import terminalStatistics from '../../views/dataManagement/terminalStatistics/DM_terminalList'; //终端统计
import upsideOrder from '../../views/dataManagement/upsideOrders/DM_upsideOrder'; //终端统计
import ladderLog from '../../views/dataManagement/ladderLog/DM_ladderLogList'; //交易阶梯奖励
import downloadList from '../../views/dataManagement/download/DM_downloadList'; //下载列表
import activityCenter from '../../views/dataManagement/activityCenterList'; //活动中心
import superiorSubsidyRecord from '../../views/dataManagement/superiorSubsidyRecord'; //上级补贴记录
import ResultList from '../../views/dataManagement/ResultList'; // 导入结果查询

export default [
    {
        path: '/turnoverAttainRecord',
        name: 'turnoverAttainRecord',
        meta: {
            title: '交易量达标记录查询'
        },
        component: turnoverAttainRecord
    },
    {
        path: '/activationRecord',
        name: 'activationRecord',
        meta: {
            title: '激活记录查询'
        },
        component: activationRecord
    },
    {
        path: '/awarding',
        name: 'awarding',
        meta: {
            title: '奖励发放查询'
        },
        component: awarding
    },
    {
        path: '/transactionDetail',
        name: 'transactionDetail',
        meta: {
            title: '交易明细'
        },
        component: transactionDetail
    },
    {
        path: '/terminalStatistics',
        name: 'terminalStatistics',
        meta: {
            title: '终端统计'
        },
        component: terminalStatistics
    },
    {
        path: '/upsideOrder',
        name: 'upsideOrder',
        meta: {
            title: '倒挂订单审核'
        },
        component: upsideOrder
    },
    {
        path: '/ladderLog',
        name: 'ladderLog',
        meta: {
            title: '阶梯记录查询'
        },
        component: ladderLog
    },
    {
        path: '/downloadList',
        name: 'downloadList',
        meta: {
            title: '下载列表'
        },
        component: downloadList
    },
    {
        path: '/cashBackRecode',
        name: 'cashBackRecode',
        meta: {
            title: '服务费返现记录查询'
        },
        component: cashBackRecode
    },
    {
        path: '/activityCenter',
        name: 'activityCenter',
        meta: {
            title: '活动中心'
        },
        component: activityCenter
    },
    {
        path: '/superiorSubsidyRecord',
        name: 'superiorSubsidyRecord',
        meta: {
            title: '上级补贴记录'
        },
        component: superiorSubsidyRecord
    },
    {
        path: '/importLog',
        name: 'importLog',
        meta: {
            title: '导入结果查询'
        },
        component: ResultList
    }
]