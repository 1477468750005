<template>
    <div class="FM_withdrawalInfo">
        <!-- 提现详情/审核 -->
        <el-dialog
            ref="terminalAudit"
            :visible="infoIsShow"
            @close="handleClose"
            :close-on-click-modal="false"
        >
            <el-card class="box-card" shadow="hover">
                <div class="title">银行信息</div>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <span class="labels">持卡人姓名：</span>
                        <span>{{dialogData.data.cardholder_name}}</span>
                    </el-col>
                    <el-col :span="24">
                        <span class="labels">银行名称：</span>
                        <span>{{resData.bank_name}}</span>
                    </el-col>
                    <el-col :span="24">
                        <span class="labels">银行卡号：</span>
                        <span>{{resData.bank_code}}</span>
                    </el-col>
                    <el-col :span="24">
                        <span class="labels">预留手机号：</span>
                        <span>{{resData.phone}}</span>
                    </el-col>
                    <!-- <el-col :span="24">
                        <span class="labels">开户所在地：</span>
                        <span>{{resData.bank_prov}}-{{resData.bank_city}}</span>
                    </el-col>
                    <el-col :span="24">
                        <span class="labels">开户支行：</span>
                        <span>{{resData.branch_bank}}</span>
                    </el-col>
                    <el-col :span="24">
                        <span class="labels">联行号：</span>
                        <span>{{resData.line_num}}</span>
                    </el-col>-->
                    <el-col :span="24">
                        <span class="labels">提现金额：</span>
                        <span>{{dialogData.data.money}}</span>
                    </el-col>
                    <el-col :span="24">
                        <span class="labels">代收税点：</span>
                        <span>{{dialogData.data.fee_money}}</span>
                    </el-col>
                    <el-col :span="24" v-show="dialogData.data.service_fee != 0">
                        <span class="labels">出款服务费：</span>
                        <span>{{dialogData.data.service_fee}}</span>
                    </el-col>
                    <!-- ----- -->
                    <el-col :span="24" v-if="dialogData.isInfo!=2">
                        <span class="labels">结算金额：</span>
                        <span>{{dialogData.data.true_money}}</span>
                    </el-col>
                    <el-col :span="24" v-if="dialogData.data.platform_fee != 0">
                        <span class="labels">平台手续费收益：</span>
                        <span>{{dialogData.data.platform_fee}}</span>
                    </el-col>
                    <el-col :span="24" v-if="dialogData.data.first_level_fee != 0">
                        <span class="labels">一级手续费收益：</span>
                        <span>{{dialogData.data.first_level_fee}}</span>
                    </el-col>
                    <el-col :span="24" v-if="dialogData.isInfo!=2">
                        <span class="labels">提现后余额：</span>
                        <span>{{dialogData.data.balance}}</span>
                    </el-col>
                    <el-col :span="24">
                        <span class="labels">申请时间：</span>
                        <span>{{dialogData.data.create_time}}</span>
                    </el-col>
                    <el-col :span="24" v-show="dialogData.data.status == 5">
                        <span class="labels">失败原因：</span>
                        <br />
                        <div v-html="dialogData.data.directions" class="desc"></div>
                    </el-col>
                    <el-col :span="24" v-if="dialogData.isInfo!=2">
                        <span class="labels">审核状态：</span>
                        <span>{{status}}</span>
                    </el-col>
                    <el-col :span="24" v-if="dialogData.isInfo!=2">
                        <span class="labels">结算方式：</span>
                        <span>{{payType}}</span>
                    </el-col>
                </el-row>
                <el-form
                    :model="auditForm"
                    label-position="top"
                    label-width="80px"
                    v-if="dialogData.isInfo != 1"
                >
                    <div class="audit">
                        <div class="title">审核</div>
                        <el-form-item>
                            <el-radio
                                v-if="dialogData.isInfo != 1 &&  dialogData.data.isShowPayMethods != 0"
                                v-model="auditForm.auditRadio"
                                :label="1"
                            >通过</el-radio>
                            <el-radio v-model="auditForm.auditRadio" :label="2">拒绝</el-radio>
                        </el-form-item>
                        <span style="color:red" v-if="auditForm.auditRadio == 2">注：拒绝后，提现金额将原路退回</span>
                    </div>
                    <div class="audit" v-if="auditForm.auditRadio != 2">
                        <div class="title">结算方式</div>
                        <el-form-item>
                            <el-radio v-model="auditForm.type" :label="1" @change="typeChange">平台代付</el-radio>
                            <!-- <el-radio
                                v-if="dialogData.data.level != 1"
                                v-model="auditForm.type"
                                :label="2"
                                @change="typeChange"
                            >线下付款</el-radio>-->
                            <!-- <el-radio
                                v-if="dialogData.data.level == 1"
                                v-model="auditForm.type"
                                :label="4"
                                @change="typeChange"
                            >海科出款</el-radio>-->
                        </el-form-item>
                    </div>
                </el-form>
                <div style="margin-top:20px" v-if="dialogData.isInfo != 1">
                    <el-button
                        @click="submit"
                        type="primary"
                        size="medium"
                        style="font-size:12px"
                        :loading="loading"
                    >确 定</el-button>
                </div>
                <el-alert
                    v-if="dialogData.isInfo != 1 &&  dialogData.data.isShowPayMethods != 1"
                    style="font-size:12px;"
                    title="提示 !"
                    type="error"
                    :closable="false"
                >
                    <span slot>未设置代付通道，无法审核。</span>
                </el-alert>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "FM_withdrawalInfo",
    props: {
        infoIsShow: {
            type: Boolean,
            default: false
        },
        dialogData: {
            type: Object
        }
    },
    data() {
        return {
            auditForm: {
                auditRadio: this.dialogData.data.isShowPayMethods == 0 ? 2 : 1,
                type: 1
            },
            resData: {},
            loading: false
        }
    },
    mounted() {
        let bankId = this.dialogData.data.bank_id;
        this.$api.post('user/bank_info', { bank_id: bankId }, res => { //初始化
            if (res.code == 0) {
                this.resData = res.data[0];
            } else {
                this.$message.error(res.msg);
            }
        })
    },
    methods: {
        submit() { //提交
            var payType = 0;
            let { id, user_id } = this.dialogData.data;
            if (this.auditForm.auditRadio == 2) {
                payType = 3;
            } else {
                payType = this.auditForm.type;
            }
            this.loading = true; //防重复
            this.$api.post('user/withdraw_audit', { type: payType, id, user_id }, res => {
                if (res.code == 0) {
                    let that = this;
                    this.$message.success({
                        type: 'success',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.handleClose(1);
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            });
        },
        typeChange(val) { //监听radio
            if (val == 2) {
                this.$confirm('线下付款将视为审核通过，是否将“结算方式”变更为线下付款？', '提示', {
                    confirmButtonText: '确 认',
                    cancelButtonText: '取 消',
                    type: 'warning'
                }).then(() => {

                }).catch(() => {
                    this.auditForm.type = 1;
                });
            }
        },
        handleClose(ref) {
            this.$emit('infoClose', ref);
        }
    },
    computed: {
        status() {
            var status = '';
            switch (this.dialogData.data.status) {
                case 0:
                    status = '未审核';
                    break;
                case 1:
                    status = '提现成功';
                    break;
                case 2:
                    status = '审核拒绝';
                    break;
                case 3:
                case 4:
                    status = '等待结果';
                    break;
                default:
                    status = '提现失败';
                    break;
            }
            return status;
        },
        payType() {
            var payType = '';
            switch (this.dialogData.data.type) {
                case 0:
                    payType = '- -';
                    break;
                case 1:
                    payType = '线下支付';
                    break;
                case 2:
                    payType = '微信支付';
                    break;
                case 3:
                    payType = '支付宝支付';
                    break;
                case 4:
                    payType = '平台代付';
                    break;
                case 5:
                    payType = '海科出款';
                    break;
            }
            return payType;
        }
    }
};
</script>

<style lang="scss" scope>
.FM_withdrawalInfo {
    * {
        font-size: 12px;
    }
    .labels {
        font-weight: 700;
    }
    .desc {
        border: 1px solid #d0d0d0;
        background-color: #ebeef5;
        padding: 3px;
        line-height: 20px;
        margin-top: 5px;
    }
    .title {
        margin: 10px 0;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #40b8ff;
        padding: 0 0 0 10px;
        .el-button--text {
            color: #303133;
            font-size: 13px;
            font-weight: 700;
        }
    }
    .el-input__inner,
    ::v-deep .el-radio__label {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
        ::v-deep .el-form-item__label {
            padding: 0;
            font-weight: 700;
            font-size: 12px;
        }
        ::v-deep .el-form-item__content {
            line-height: 0;
        }
    }
    ::v-deep .el-checkbox__label {
        font-size: 12px;
    }
    .el-row {
        margin: 10px 0;
        &:last-child {
            margin-bottom: 0;
        }
        .el-col {
            margin: 10px 0;
        }
    }
}
</style>
