<template>
    <div class="rateTableBox">
        <!-- 表格部分 -->
        <el-card class="box-card tableCard" style="margin-top: 20px;" shadow="hover">
            <el-form :model="setPrice" ref="rateForm" class="rateForm">
                <div class="tableDiv" ref="tableDiv">
                    <table class="rateTable">
                        <thead>
                            <tr>
                                <th>交易方式</th>
                                <th>结算周期</th>
                                <th>卡类型</th>
                                <th>终端/底价</th>
                                <th>标准费率%</th>
                                <th>是否设置封顶值</th>
                                <!-- <th>达到某一金额时</th> -->
                                <th style="min-width:180px">费率收取方式</th>
                                <th>费率/金额</th>
                                <th>是否添加秒到费</th>
                                <th>秒到费（单笔添加）</th>
                                <!-- <th style="border-right:none;min-width:50px;">操作</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <!-- 0-t0借记卡 -->
                            <tr>
                                <!-- 交易方式 -->
                                <td rowspan="8" style="border-bottom:none;">
                                    <div style="font-weight:700">{{TbList.title}}</div>
                                </td>
                                <!-- 结算周期 -->
                                <td rowspan="4">{{setPrice.sec.title}}</td>
                                <!-- 卡类型-借记卡 -->
                                <td rowspan="2">{{setPrice.secDebit.title}}</td>
                                <!-- 终端底价-费率 -->
                                <td>{{setPrice.secDebitRate.title}}</td>
                                <!-- 标准费率 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secDebitRate.is_edit == 0"
                                        v-model="setPrice.secDebitRate.base_rate"
                                        placeholder="终端费率"
                                    ></el-input>
                                </td>
                                <!-- 是否设置封顶值 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        v-model="setPrice.secDebit.is_overrun"
                                        active-text="是"
                                        inactive-text="否"
                                        :active-value="1"
                                        :inactive-value="0"
                                        disabled
                                        @change="handleSwitchChange(setPrice.secDebit.is_overrun,setPrice.secDebit)"
                                    ></el-switch>
                                </td>
                                <!-- 达到某一金额时 -->
                                <!-- <td rowspan="2">
                                    <el-input
                                        v-model="setPrice.secDebit.overrun_money"
                                        placeholder="达到某一金额时"
                                    ></el-input>
                                </td>-->
                                <!-- 费率收取方式 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        v-model="setPrice.secDebit.overrun_type"
                                        active-text="交易比例"
                                        :active-value="1"
                                        inactive-text="按固定"
                                        :inactive-value="2"
                                        disabled
                                    ></el-switch>
                                </td>
                                <!-- 费率/金额 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secDebitRate.is_edit == 0"
                                        v-model="setPrice.secDebitRate.overrun_value"
                                        placeholder="费率/金额"
                                    ></el-input>
                                </td>
                                <!-- 是否添加秒到费 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        disabled
                                        v-model="setPrice.secDebit.is_sec"
                                        active-text="是"
                                        :active-value="1"
                                        inactive-text="否"
                                        :inactive-value="0"
                                    ></el-switch>
                                </td>
                                <!-- 秒到费（单笔添加） -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secDebitRate.is_edit == 0"
                                        v-model="setPrice.secDebitRate.sec_fee"
                                        placeholder="秒到费"
                                    ></el-input>
                                </td>
                            </tr>
                            <!-- 1-t0借记卡 -->
                            <tr>
                                <!-- 终端底价-底价 -->
                                <td>{{setPrice.secDebitMy.title}}</td>
                                <!-- 标准费率 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secDebitMy.is_edit == 0"
                                        v-model="setPrice.secDebitMy.base_rate"
                                        placeholder="我的底价"
                                    ></el-input>
                                </td>
                                <!-- 费率/金额 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secDebitMy.is_edit == 0"
                                        v-model="setPrice.secDebitMy.overrun_value"
                                        placeholder="费率/金额"
                                    ></el-input>
                                </td>
                                <!-- 秒到费（单笔添加） -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secDebitMy.is_edit == 0"
                                        v-model="setPrice.secDebitMy.sec_fee"
                                        placeholder="秒到费"
                                    ></el-input>
                                </td>
                            </tr>
                            <!-- 2-t0贷记卡 -->
                            <tr>
                                <!-- 卡类型-贷记卡 -->
                                <td rowspan="2">{{setPrice.secCredit.title}}</td>
                                <!-- 终端底价-费率 -->
                                <td>{{setPrice.secCreditRate.title}}</td>
                                <!-- 标准费率 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secCreditRate.is_edit == 0"
                                        v-model="setPrice.secCreditRate.base_rate"
                                        placeholder="终端费率"
                                    ></el-input>
                                </td>
                                <!-- 是否设置封顶值 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        disabled
                                        v-model="setPrice.secCredit.is_overrun"
                                        active-text="是"
                                        inactive-text="否"
                                        :active-value="1"
                                        :inactive-value="0"
                                        @change="handleSwitchChange(setPrice.secCredit.is_overrun,setPrice.secCredit)"
                                    ></el-switch>
                                </td>
                                <!-- 达到某一金额时 -->
                                <!-- <td rowspan="2">
                                    <el-input
                                        v-model="setPrice.secCredit.overrun_money"
                                        placeholder="达到某一金额时"
                                    ></el-input>
                                </td>-->
                                <!-- 费率收取方式 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        v-model="setPrice.secCredit.overrun_type"
                                        disabled
                                        active-text="交易比例"
                                        :active-value="1"
                                        inactive-text="按固定"
                                        :inactive-value="2"
                                    ></el-switch>
                                </td>
                                <!-- 费率/金额 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secCreditRate.is_edit == 0"
                                        v-model="setPrice.secCreditRate.overrun_value"
                                        placeholder="费率/金额"
                                    ></el-input>
                                </td>
                                <!-- 是否添加秒到费 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        disabled
                                        v-model="setPrice.secCredit.is_sec"
                                        active-text="是"
                                        :active-value="1"
                                        inactive-text="否"
                                        :inactive-value="0"
                                    ></el-switch>
                                </td>
                                <!-- 秒到费（单笔添加） -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secCreditRate.is_edit == 0"
                                        v-model="setPrice.secCreditRate.sec_fee"
                                        placeholder="秒到费"
                                    ></el-input>
                                </td>
                            </tr>
                            <!-- 3-t0贷记卡 -->
                            <tr>
                                <!-- 终端底价 -->
                                <td>{{setPrice.secCreditMy.title}}</td>
                                <!-- 标准费率 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secCreditMy.is_edit == 0"
                                        v-model="setPrice.secCreditMy.base_rate"
                                        placeholder="我的底价"
                                    ></el-input>
                                </td>
                                <!-- 费率/金额 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secCreditMy.is_edit == 0"
                                        v-model="setPrice.secCreditMy.overrun_value"
                                        placeholder="费率/金额"
                                    ></el-input>
                                </td>
                                <!-- 秒到费（单笔添加） -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.secCreditMy.is_edit == 0"
                                        v-model="setPrice.secCreditMy.sec_fee"
                                        placeholder="秒到费"
                                    ></el-input>
                                </td>
                            </tr>
                            <!-- 4-t1借记卡 -->
                            <tr>
                                <!-- 结算周期 -->
                                <td rowspan="4" style="border-bottom:none;">{{setPrice.day.title}}</td>
                                <!-- 卡类型-t1借记卡 -->
                                <td rowspan="2">{{setPrice.dayDebit.title}}</td>
                                <!-- 终端底价 -->
                                <td>{{setPrice.dayDebitRate.title}}</td>
                                <!-- 标准费率 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayDebitRate.is_edit == 0"
                                        v-model="setPrice.dayDebitRate.base_rate"
                                        placeholder="终端费率"
                                    ></el-input>
                                </td>
                                <!-- 是否设置封顶值 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        disabled
                                        v-model="setPrice.dayDebit.is_overrun"
                                        active-text="是"
                                        inactive-text="否"
                                        :active-value="1"
                                        :inactive-value="0"
                                        @change="handleSwitchChange(setPrice.dayDebit.is_overrun,setPrice.dayDebit)"
                                    ></el-switch>
                                </td>
                                <!-- 达到某一金额时 -->
                                <!-- <td rowspan="2">
                                    <el-input
                                        v-model="setPrice.dayDebit.overrun_money"
                                        placeholder="达到某一金额时"
                                    ></el-input>
                                </td>-->
                                <!-- 费率收取方式 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        v-model="setPrice.dayDebit.overrun_type"
                                        disabled
                                        active-text="交易比例"
                                        :active-value="1"
                                        inactive-text="按固定"
                                        :inactive-value="2"
                                    ></el-switch>
                                </td>
                                <!-- 费率/金额 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayDebitRate.is_edit == 0"
                                        v-model="setPrice.dayDebitRate.overrun_value"
                                        placeholder="费率/金额"
                                    ></el-input>
                                </td>
                                <!-- 是否添加秒到费 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        disabled
                                        v-model="setPrice.dayDebit.is_sec"
                                        active-text="是"
                                        :active-value="1"
                                        inactive-text="否"
                                        :inactive-value="0"
                                    ></el-switch>
                                </td>
                                <!-- 秒到费（单笔添加） -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayDebitRate.is_edit == 0"
                                        v-model="setPrice.dayDebitRate.sec_fee"
                                        placeholder="秒到费"
                                    ></el-input>
                                </td>
                            </tr>
                            <!-- 5-t1借记卡 -->
                            <tr>
                                <!-- 终端底价 -->
                                <td>{{setPrice.dayDebitMy.title}}</td>
                                <!-- 标准费率 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayDebitMy.is_edit == 0"
                                        v-model="setPrice.dayDebitMy.base_rate"
                                        placeholder="我的底价"
                                    ></el-input>
                                </td>
                                <!-- 费率/金额 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayDebitMy.is_edit == 0"
                                        v-model="setPrice.dayDebitMy.overrun_value"
                                        placeholder="费率/金额"
                                    ></el-input>
                                </td>
                                <!-- 秒到费（单笔添加） -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayDebitMy.is_edit == 0"
                                        v-model="setPrice.dayDebitMy.sec_fee"
                                        placeholder="秒到费"
                                    ></el-input>
                                </td>
                            </tr>
                            <!-- 6-t1贷记卡 -->
                            <tr>
                                <!-- 卡类型 -->
                                <td
                                    rowspan="2"
                                    style="border-bottom:none;"
                                >{{setPrice.dayCredit.title}}</td>
                                <!-- 终端底价 -->
                                <td>{{setPrice.dayCreditRate.title}}</td>
                                <!-- 标准费率 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayCreditRate.is_edit == 0"
                                        v-model="setPrice.dayCreditRate.base_rate"
                                        placeholder="终端费率"
                                    ></el-input>
                                </td>
                                <!-- 是否设置封顶值 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        disabled
                                        v-model="setPrice.dayCredit.is_overrun"
                                        active-text="是"
                                        inactive-text="否"
                                        :active-value="1"
                                        :inactive-value="0"
                                        @change="handleSwitchChange(setPrice.dayCredit.is_overrun,setPrice.dayCredit)"
                                    ></el-switch>
                                </td>
                                <!-- 达到某一金额时 -->
                                <!-- <td rowspan="2">
                                    <el-input
                                        v-model="setPrice.dayCredit.overrun_money"
                                        placeholder="达到某一金额时"
                                    ></el-input>
                                </td>-->
                                <!-- 费率收取方式 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        v-model="setPrice.dayCredit.overrun_type"
                                        disabled
                                        active-text="交易比例"
                                        :active-value="1"
                                        inactive-text="按固定"
                                        :inactive-value="2"
                                    ></el-switch>
                                </td>
                                <!-- 费率/金额 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayCreditRate.is_edit == 0"
                                        v-model="setPrice.dayCreditRate.overrun_value"
                                        placeholder="费率/金额"
                                    ></el-input>
                                </td>
                                <!-- 是否添加秒到费 -->
                                <td rowspan="2">
                                    <el-switch
                                        style="display: block"
                                        disabled
                                        v-model="setPrice.dayCredit.is_sec"
                                        active-text="是"
                                        :active-value="1"
                                        inactive-text="否"
                                        :inactive-value="0"
                                    ></el-switch>
                                </td>
                                <!-- 秒到费（单笔添加） -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayCreditRate.is_edit == 0"
                                        v-model="setPrice.dayCreditRate.sec_fee"
                                        placeholder="秒到费"
                                    ></el-input>
                                </td>
                            </tr>
                            <!-- 7-t1贷记卡 -->
                            <tr>
                                <!-- 终端底价 -->
                                <td>{{setPrice.dayCreditMy.title}}</td>
                                <!-- 标准费率 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayCreditMy.is_edit == 0"
                                        v-model="setPrice.dayCreditMy.base_rate"
                                        placeholder="我的底价"
                                    ></el-input>
                                </td>
                                <!-- 费率/金额 -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayCreditMy.is_edit == 0"
                                        v-model="setPrice.dayCreditMy.overrun_value"
                                        placeholder="费率/金额"
                                    ></el-input>
                                </td>
                                <!-- 秒到费（单笔添加） -->
                                <td>
                                    <el-input
                                        :disabled="setPrice.dayCreditMy.is_edit == 0"
                                        v-model="setPrice.dayCreditMy.sec_fee"
                                        placeholder="秒到费"
                                    ></el-input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <el-button
                    :loading="btnLoading"
                    type="primary"
                    style="margin-top:20px"
                    @click="handleSubmit(TbList.list)"
                >保 存 配 置</el-button>
            </el-form>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "MAM_rateTable",
    props: {
        TbList: {
            type: [Array, Object]
        },
        brandId: {
            type: [String, Number]
        }
    },
    data() {
        return {
            btnLoading: false,
            oldArr: [], // 存储起来做对比用的
            setPrice: {
                sec: {}, //T+0
                day: {}, //T+1
                secDebit: {},           //T+0借记卡
                secCredit: {},          //T+0贷记卡
                dayDebit: {},           //T+1借记卡
                dayCredit: {},          //T+1贷记卡
                secDebitRate: {},       //T+0借记卡终端费率
                secDebitMy: {},         //T+0借记卡我的底价
                secCreditRate: {},       //T+0贷记卡终端费率
                secCreditMy: {},         //T+0贷记卡我的底价
                dayDebitRate: {},       //T+1借记卡终端费率
                dayDebitMy: {},         //T+1借记卡我的底价
                dayCreditRate: {},       //T+1贷记卡终端费率
                dayCreditMy: {},         //T+1贷记卡我的底价
            },
            formList: {
                brand_id: '',  //终端类型id
                pay_type: '', //支付类型
                settle_type: '', //结算类型
                card_type: '', // 卡类型
                is_overrun: '', //是否设置封顶 1是 0否
                overrun_type: '', //封顶类型 1按费率 2按固定
                overrun_money: '', //封顶值
                is_sec: '', //是否设置秒到费 1是 0否
                data: ''
            },
            newTempArr: [],
            oldTempArr: [],
        }
    },
    mounted() {
        this.oldArr = this._.cloneDeep(this.TbList.list);
        this._.forEach(this.TbList.list, item => { //结算周期
            if (item.id == 1) {
                this.setPrice.sec = item; //T0
                this._.forEach(item.list, value => { //卡类型
                    if (value.id == 1) {
                        this.setPrice.secDebit = value; //T+0借记卡
                        //终端/底价
                        this._.forEach(value.list, v => {
                            if (v.id == 1) {
                                this.setPrice.secDebitRate = v; //T+0借记卡终端费率
                            } else {
                                this.setPrice.secDebitMy = v; //T+0借记卡我的底价
                            }
                        });
                    } else {
                        this.setPrice.secCredit = value; //T+0贷记卡
                        //终端/底价
                        this._.forEach(value.list, v => {
                            if (v.id == 1) {
                                this.setPrice.secCreditRate = v; //T+0贷记卡终端费率
                            } else {
                                this.setPrice.secCreditMy = v; //T+0贷记卡我的底价
                            }
                        });
                    }
                });
            } else {
                this.setPrice.day = item //T1
                this._.forEach(item.list, value => { //卡类型
                    if (value.id == 1) {
                        this.setPrice.dayDebit = value; //T+1借记卡
                        //终端/底价
                        this._.forEach(value.list, v => {
                            if (v.id == 1) {
                                this.setPrice.dayDebitRate = v; //T+0借记卡终端费率
                            } else {
                                this.setPrice.dayDebitMy = v; //T+0借记卡我的底价
                            }
                        });
                    } else {
                        this.setPrice.dayCredit = value; //T+1贷记卡
                        //终端/底价
                        this._.forEach(value.list, v => {
                            if (v.id == 1) {
                                this.setPrice.dayCreditRate = v; //T+0贷记卡终端费率
                            } else {
                                this.setPrice.dayCreditMy = v; //T+0贷记卡我的底价
                            }
                        });
                    }
                });
            }
        })
        // let elementResizeDetectorMaker = require("element-resize-detector");//导入监听插件
        // 动态改变表格的宽
        // let that = this;
        // let listenResize = elementResizeDetectorMaker();
        // listenResize.listenTo($('.tableCard'), function (element) {
        //     $('.tableDiv').width(($('.tableCard').width() - 40) + 'px');
        // })
    },
    methods: {
        handleSubmit(field) {
            this.btnLoading = true;
            this.newTempArr = [];
            this.oldTempArr = [];
            this.formatRate(field, 'newTempArr')
            this.formatRate(this.oldArr, 'oldTempArr')
            let reqData = [];
            if (this.newTempArr.length != 0) {
                this._.forEach(this.newTempArr, (item, index) => {
                    let cardType; // 卡类型
                    let rateType; // 费率类型
                    let oldVal = this.oldTempArr;
                    if (!this._.isEqual(item, oldVal[index])) { // 被修改的我的底价
                        cardType = item.settle_pay_card;
                        rateType = item.type;
                        reqData.push(item);
                        this._.forEach(this.newTempArr, v => { // 终端费率
                            if (v.settle_pay_card == cardType && v.type != rateType) {
                                reqData.push(v);
                            }
                        })
                    }
                })
            }
            if (reqData.length != 0) {
                this.$api.post('brand/brand_rate_update_batch', { brand_id: this.brandId, data: JSON.stringify(reqData) }, res => {
                    let that = this;
                    if (res.code == 0) {
                        this.$message({
                            type: "success",
                            message: res.msg,
                            duration: 2000,
                            onClose() {
                                that.$emit('closeDia', 1)
                            }
                        });
                    } else {
                        this.btnLoading = false;
                        this.$message.error(res.msg);
                    }
                })
            } else {
                this.btnLoading = false;
                this.$message.error('未检测到修改');
            }
        },
        formatRate(arr, t) {
            arr.forEach(item => {
                if (item.list) {
                    this.formatRate(item.list, t);
                } else {
                    this[t].push(item);
                }
            });
        },
        handleSwitchChange(val, obj) { //收取方式同步封顶
            if (val == 1) {
                obj.overrun_type = 1;
            } else {
                obj.overrun_type = 0;
            }
        }
    },
    watch: {

    }
};
</script>

<style lang="scss" scope>
.rateTableBox * {
    .el-input__inner {
        // border: none;
        text-align: center;
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
    .el-switch__label *,
    .el-button--text {
        font-size: 12px;
    }
    .tableDiv {
        overflow-x: scroll;
    }
    .rateTable {
        margin-top: 20px;
        border: 1px solid #ebeef5;
        display: table;
        border-collapse: separate;
        border-spacing: 2px;
        // width: 2000px;
        thead {
            color: #909399;
            font-weight: 500;
            th {
                border-bottom: 1px solid #ebeef5;
                border-right: 1px solid #ebeef5;
                // overflow: auto;
                padding: 12px 0;
                min-width: 150px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                text-overflow: ellipsis;
                vertical-align: middle;
                position: relative;
                text-align: center;
                font-size: 12px;
            }
        }
        tbody {
            display: table-row-group;
            vertical-align: middle;
            border-color: inherit;
            :nth-last-child(1) td {
                border-bottom: none;
            }
            td {
                transition: background-color 0.25s ease;
                border-right: 1px solid #ebeef5;
                border-bottom: 1px solid #ebeef5;
                padding: 12px 12px;
                min-width: 150px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                text-overflow: ellipsis;
                vertical-align: middle;
                position: relative;
                text-align: center;
                font-size: 12px;
            }
            // tr {
            //     :nth-last-child(1) {
            //         border-right: none;
            //     }
            // }
        }
        td,
        th {
            display: table-cell;
            vertical-align: inherit;
        }
    }
}
</style>
