<template>
    <div class="installmentLoanInfo">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="searchForm.loan_order_num"
                        placeholder="请输入货款订单号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="searchForm.repayment_order_num"
                        placeholder="请输入还款订单号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="searchForm.offset_order_num"
                        placeholder="请输入抵消订单号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="searchForm.debt_write_offs_order_num"
                        placeholder="请输入核销订单号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div class="statistic">
                <div class="view-title">
                    <span>货款总额：</span>
                    <span>{{total_loan_money}}</span>
                </div>
                <div class="view-title">
                    <span>已还金额：</span>
                    <span>{{total_completed_money}}</span>
                </div>
                <div class="view-title">
                    <span>抵消金额：</span>
                    <span>{{total_offset_money}}</span>
                </div>
                <div class="view-title">
                    <span>延期入账：</span>
                    <span>{{total_postponed_money}}</span>
                </div>
            </div>
            <el-table :data="infoList" size="mini" border style="width: 100%" v-loading="loading">
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 贷款订单号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="order_num"
                    label="贷款订单号"
                    min-width="220"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="150"
                ></el-table-column>
                <!-- 划拨数量（台） -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="transfer_num"
                    label="划拨数量（台）"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleTerminalDetail(scope.row)"
                            type="text"
                            size="small"
                        >{{scope.row.transfer_num}}</el-button>
                    </template>
                </el-table-column>
                <!-- 订单总金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="total_money"
                    label="订单总金额"
                    min-width="120"
                ></el-table-column>
                <!-- 首付金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="down_money"
                    label="首付金额"
                    min-width="120"
                ></el-table-column>
                <!-- 抵消金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="offset_money"
                    label="抵消金额"
                    min-width="120"
                ></el-table-column>
                <!-- 贷款总额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="loan_money"
                    label="贷款总额"
                    min-width="120"
                ></el-table-column>
                <!-- 货款期数 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="loan_period"
                    label="货款期数"
                    min-width="120"
                ></el-table-column>
                <!-- 已还金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="completed_money"
                    label="已还金额"
                    min-width="120"
                ></el-table-column>
                <!-- 剩余期数 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="lave_period"
                    label="剩余期数"
                    min-width="120"
                ></el-table-column>
                <!-- 剩余欠款 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="lave_money"
                    label="剩余欠款"
                    min-width="120"
                ></el-table-column>
                <!-- 贷款日期 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="贷款日期"
                    min-width="150"
                ></el-table-column>
                <!-- 是否延期入账 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="is_postponed"
                    label="是否延期入账"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.is_postponed == 1? '是':'否'}}</span>
                    </template>
                </el-table-column>
                <!-- 入账日期 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="postponed_date"
                    label="入账日期"
                    min-width="120"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="状态"
                    min-width="120"
                    :formatter="handleStatus"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-show="true"
                            @click="handleStageDetail(scope.row)"
                            type="text"
                            size="small"
                        >分期明细</el-button>
                        <el-button
                            v-show="Number(scope.row.offset_money) > 0"
                            @click="handleOffsetDetail(scope.row)"
                            type="text"
                            size="small"
                        >抵消明细</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
            <!-- 抵消明细 -->
            <offset-detail
                v-if="offsetInfo.isShow"
                :offsetInfo="offsetInfo"
                @handleClose="handleCloseOffset"
            ></offset-detail>
            <!-- 分期明细 -->
            <stage-detail
                v-if="stageInfo.isShow"
                :stageInfo="stageInfo"
                @handleClose="handleCloseStage"
            ></stage-detail>
            <!-- 终端明细 -->
            <terminal-detail
                v-if="terminalInfo.isShow"
                :terminalInfo="terminalInfo"
                @infoClose="handleTerminalClose"
            ></terminal-detail>
        </el-card>
    </div>
</template>

<script>
import offsetDetail from './offsetDetail';
import stageDetail from './stageDetail';
import terminalDetail from './terminalDetail';

export default {
    name: "installmentLoanInfo",
    data() {
        return {
            offsetInfo: { //抵消明细
                isShow: false,
                info: {}
            },
            terminalInfo: { //终端明细
                url: '',
                isShow: false,
                info: {
                    transfer_log_id: ''
                }
            },
            stageInfo: { //分期明细
                isShow: false,
                info: {}
            },
            pay_id: '', //出款代理商ID
            borrow_id: '', //借款代理商ID
            searchForm: { //搜索表单
                page: 1,
                limit: 10
            },
            total: 0,  //总数
            pageSize: 10, //条数
            currentPage: 1, //当前页码
            infoList: [], //列表
            loading: false, //列表loadding
            total_loan_money: '', //货款总额
            total_completed_money: '', //已还金额
            total_offset_money: '', //抵消金额
            total_postponed_money: '', //延期入账
        }
    },
    mounted() {
        this.pay_id = this.$route.query.pay_id;
        this.borrow_id = this.$route.query.borrow_id;
        this.getInfo({
            page: 1,
            limit: 10
        });
    },
    components: {
        offsetDetail, // 抵消明细
        stageDetail, // 分期明细
        terminalDetail // 终端明细
    },
    methods: {
        handleSearch() { //搜索
            this.currentPage = 1;
            this.searchForm.page = 1;
            this.getInfo(this.searchForm);
        },
        handleStageDetail(row) { //分期明细
            this.stageInfo.isShow = true;
            this.stageInfo.info = row;
        },
        handleCloseStage() { //关闭分期明细
            this.stageInfo.isShow = false;
            this.stageInfo.info = {};
        },
        handleOffsetDetail(row) { //抵消明细
            this.offsetInfo.isShow = true;
            this.offsetInfo.info = row;
        },
        handleCloseOffset() { //关闭抵消明细
            this.offsetInfo.isShow = false;
            this.offsetInfo.info = {};
        },
        handleTerminalDetail(row) { //查看终端明细
            this.terminalInfo.isShow = true;
            this.terminalInfo.info.transfer_log_id = row.terminal_transfer_main_id;
            this.terminalInfo.url = 'terminal_transfer/get_log_terminal';

        },
        handleTerminalClose() { //关闭终端明细
            this.terminalInfo.isShow = false;
            this.terminalInfo.info.transfer_log_id = '';
            this.terminalInfo.url = '';
        },
        //分页
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getInfo(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getInfo(this.searchForm);
        },
        getInfo(params) { //获取详情
            this.loading = true;
            let temp = {
                pay_user_id: this.pay_id,
                borrow_user_id: this.borrow_id,
            };
            let reqData = this._.assign(temp, params);
            this.$api.post('user_installment_loan/get_installment_loan_info', reqData, res => {
                if (res.code == 0) {
                    this.loading = false;
                    let data = res.data;
                    this.infoList = data.list;
                    this.total = data.count;
                    this.total_completed_money = data.total_completed_money;
                    this.total_loan_money = data.total_loan_money;
                    this.total_offset_money = data.total_offset_money;
                    this.total_postponed_money = data.total_postponed_money;
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            });
        },
        handleStatus(row) { //格式化状态
            let status;
            switch (row.status) {
                case 0:
                    status = '未开始';
                    break;
                case 1:
                    status = '进行中';
                    break;
                case 2:
                    status = '已完成';
                    break;
                case 3:
                    status = '已变更';
                    break;
            }
            return status;
        }
    }
}
</script>

<style scoped lang="scss">
.installmentLoanInfo {
    .statistic {
        margin-bottom: 15px;
        .view-title {
            font-size: 12px;
            margin: 0px 10px;
            color: #606266;
            display: inline-block;
            :first-child {
                font-weight: 700;
            }
        }
    }
}
</style>
