/** 规则管理 */
import riskControl from '../../views/machineManagement/riskControl/MAM_riskControlList'; //风控规则配置
import riskControlLog from '../../views/machineManagement/riskControlLog/MAM_riskControlLogList'; //风控规则触发记录

export default [
    {
        path: '/riskControl',
        name: 'riskControl',
        meta: {
            title: '规则配置'
        },
        component: riskControl
    },
    {
        path: '/riskControlLog',
        name: 'riskControlLog',
        meta: {
            title: '伪激活触发记录'
        },
        component: riskControlLog
    }
]