<template>
    <div class>
        <!-- 表单搜索部分 -->
        <el-card shadow="hover">
            <el-form :inline="true" :model="searchForm">
                <el-form-item>
                    <el-input clearable v-model="searchForm.agent_code" placeholder="请输入代理商编号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.agent_name" placeholder="请输入代理商名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.agent_phone" placeholder="请输入代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="searchForm.is_default"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择地址类型"
                    >
                        <el-option label="默认收货地址" value="1"></el-option>
                        <el-option label="普通收货地址" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="bank_prov">
                    <el-cascader
                        separator="-"
                        filterable
                        clearable
                        v-model="searchForm.region"
                        :options="areaList"
                        :props="cascaderProps"
                        placeholder="请选择地址"
                        @change="handleAreaChange"
                    ></el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.name" placeholder="请输入收货人姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.phone" placeholder="请输入收货人联系电话"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查 询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <!-- <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-refresh"
                    :loading="downLoading"
                    @click="handleCallback"
                >导出</el-button>-->
            </div>
            <el-table
                :data="tableList"
                size="small"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <!-- 序号 -->
                <el-table-column
                    type="index"
                    header-align="center"
                    align="center"
                    fixed
                    label="序号"
                    width="100"
                    :index="handleTableIndex"
                ></el-table-column>
                <!-- 代理商编号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_code"
                    label="代理商编号"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_name"
                    label="代理商名称"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_phone"
                    label="代理商账号"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 收货人姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="name"
                    label="收货人姓名"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 联系电话 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="联系电话"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 所在地 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="所在地"
                    min-width="200"
                    show-overflow-tooltip
                >
                    <template
                        slot-scope="scope"
                    >{{scope.row.province_name}}-{{scope.row.city_name}}-{{scope.row.area_name}}</template>
                </el-table-column>
                <!-- 详细地址 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="address"
                    label="详细地址"
                    min-width="250"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 地址类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="is_default"
                    label="地址类型"
                    min-width="150"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_default == 1">默认地址</span>
                        <span v-else>普通地址</span>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
    </div>
</template>
<script>
import areaList from '../../utils/address';
export default {
    data() {
        return {
            areaList: areaList,
            cascaderProps: { // 省市区指定属性
                value: 'code',
                label: 'areaName'
            },
            searchForm: {
                page: 1,
                limit: 10,
                agent_code: '', // 代理商编号
                agent_phone: '', // 代理商账号
                agent_name: '', // 代理商名称
                is_default: '', // 选择地址：1=默认收货地址，2=普通收货地址
                region: [], // 省市区数组
                name: '', // 收货人姓名
                phone: '' // 收货人手机号
            },
            tableList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true
        }
    },
    created() {
        this.getData({
            page: 1,
            limit: 10
        })
    },
    mounted() {
    },
    methods: {
        handleSearch(searchForm) {
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { // 获取数据
            this.loading = true;
            this.$api.post("user_address/get_user_address_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.tableList = data.list;
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        handleAreaChange() { // 处理地区数据

        },
        handleTableIndex(index) { // 处理序号
            return (this.currentPage - 1) * this.pageSize + index + 1;
        }
    }
}
</script>

<style scoped lang="scss">
.el-form-item {
    margin-bottom: 5px;
}
</style>