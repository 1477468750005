<template>
    <div class="rateTemplateList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-select
                        v-model="searchForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.rate_code" placeholder="请输入费率模板ID"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.is_show" clearable placeholder="请选择状态">
                        <el-option label="显示" value="1"></el-option>
                        <el-option label="隐藏" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="handleAdd">添加模板</el-button>
            </div>
            <el-table
                :data="templateList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <!-- 模版ID -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="模版ID"
                    min-width="80"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="150"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="is_show"
                    label="状态"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.is_show"
                            active-text="显示"
                            inactive-text="隐藏"
                            :active-value="1"
                            :inactive-value="0"
                            @change="handleStatusChange(scope.row)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="创建时间"
                    min-width="150"
                ></el-table-column>
                <!-- 最后一次修改时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="update_time"
                    label="最后一次修改时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <rate-template-info
            v-if="templateInfo.isShow"
            :templateInfo="templateInfo"
            @infoClose="infoClose"
        ></rate-template-info>
    </div>
</template>

<script>
import publicReq from '../../utils/publicReq';
import rateTemplateInfo from './rateTemplateInfo';
export default {
    name: "rateTemplateList",
    data() {
        return {
            brandList: [],
            templateInfo: { //弹窗 
                info: {},
                type: 1, //1.添加 2.编辑
                isShow: false,
            },
            templateList: [],
            searchForm: {
                page: 1,
                limit: 10,
                brand_id: '',
                rate_code: '',
                is_show: ''
            },
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true
        }
    },
    mounted() {
        publicReq.getBrandList(res => {
            this.brandList = res.data.list;
        });
        this.getData({
            page: 1,
            limit: 10
        });
    },
    components: {
        rateTemplateInfo
    },
    methods: {
        handleSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleAdd() { //添加
            this.templateInfo.type = 1;
            this.templateInfo.info = {};
            this.templateInfo.isShow = true;
        },
        handleEdit(row) { //编辑
            this.templateInfo.type = 2;
            this.templateInfo.info = row;
            this.templateInfo.isShow = true;
        },
        handleStatusChange(row) { //状态改变
            console.log(row);
        },
        infoClose() { //关闭弹窗
            this.templateInfo.isShow = false;
            this.templateInfo.info = {};
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("rate_template/get_rate_template_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.templateList = res.data.list;
                    //渲染结束后取消加载
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                    //渲染结束后取消加载
                    this.loading = false;
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.rateTemplateList {
}
</style>
