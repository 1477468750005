<template>
    <div class="operatorList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm">
                <el-form-item>
                    <el-select v-model="searchForm.role_id" clearable placeholder="请选择角色">
                        <el-option
                            v-for="(item,index) in roleList"
                            :label="item.name"
                            :value="item.id"
                            :key="index"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.realname" placeholder="请输入操作员名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.phone" placeholder="请输入操作员联系电话"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-plus"
                    @click="handleAddOperator"
                >添加操作员</el-button>
            </div>
            <el-table
                :data="operatorList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 角色名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="role_name"
                    label="角色名称"
                    min-width="150"
                ></el-table-column>
                <!-- 操作员姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="realname"
                    label="操作员姓名"
                    min-width="150"
                ></el-table-column>
                <!-- 联系电话 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="联系电话"
                    min-width="150"
                ></el-table-column>
                <!-- 登录账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="username"
                    label="登录账号"
                    min-width="150"
                ></el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="创建时间"
                    min-width="150"
                ></el-table-column>
                <!-- 当前状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="当前状态"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">启用</span>
                        <span v-else>禁用</span>
                    </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    label="操作"
                    width="250"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            icon="el-icon-edit"
                            @click="handleEditOperator(scope.row)"
                        >编辑</el-button>
                        <el-button
                            style="color:#F56C6C"
                            type="text"
                            size="small"
                            icon="el-icon-delete"
                            @click="handleDelOperator(scope.row)"
                        >删除</el-button>
                        <el-button
                            type="text"
                            size="small"
                            icon="el-icon-refresh-right"
                            @click="handleResetPassword(scope.row)"
                        >重置密码</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <operatorInfo v-if="info.isShow" :info="info" @handleClose="handleCloseInfo"></operatorInfo>
    </div>
</template>

<script>
import operatorInfo from './components/operatorInfo';
export default {
    name: "operatorList",
    data() {
        return {
            info: {
                type: 1, //1.添加 2.编辑
                row: {},
                isShow: false
            },
            roleList: [],//角色列表
            operatorList: [], //操作员列表
            searchForm: {
                page: 1,
                limit: 10,
                role_id: '', //角色名称
                realname: '', //操作员名称
                phone: '', //操作员联系电话

            },
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false
        }
    },
    mounted() {
        this.getRoleList().then(() => {
            this.getData({
                page: 1,
                limit: 10
            })
        });
    },
    components: {
        operatorInfo
    },
    methods: {
        handleSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleAddOperator() { //添加操作员
            this.info.type = 1;
            this.info.isShow = true;
        },
        handleEditOperator(row) { //编辑操作员
            this.info.type = 2;
            this.info.isShow = true;
            this.info.row = row;
        },
        handleDelOperator(row) { //删除操作员
            this.tempHint({
                hint: '您确认删除该账号吗？',
                url: 'rule_menu/operator_deleted',
                id: row.id,
                type: 1
            });
        },
        handleResetPassword(row) { //重置密码
            this.tempHint({
                hint: '密码重置后，初始密码为hk123456，请确认是否重置该账户密码？',
                url: 'rule_menu/operator_reset_password',
                id: row.id,
                type: 2
            });
        },
        handleCloseInfo(ref) { //关闭详情
            this.info.type = null;
            this.info.isShow = false;
            this.info.row = {};
            if (ref) {
                this.getData({
                    page: this.currentPage,
                    limit: this.pageSize
                })
            }
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("rule_menu/get_operator_management_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.operatorList = data.list;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        getRoleList() { //获取角色列表
            return new Promise((resolve) => {
                this.$api.post("rule_menu/role_select", {}, res => {
                    if (res.code == 0) {
                        this.roleList = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                    resolve();
                })
            });

        },
        /**
         * tempHint 临时提示
         * hint 提示信息
         * url 请求地址
         * id 处理的数据id
         * type 类型 1.删除 2.重置密码
         */
        tempHint(obj) {
            let _this = this;
            this.$confirm(obj.hint, '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post(obj.url, { id: obj.id }, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1000,
                                    onClose() {
                                        done();
                                        if (obj.type == 1) {
                                            _this.getData({
                                                page: _this.currentPage,
                                                limit: _this.pageSize
                                            })
                                        }
                                        instance.confirmButtonLoading = false;
                                    }
                                })
                            } else {
                                this.$message.error(res.msg);
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
