<template>
	<div class="MAM_terminalList">
		<!-- 表单搜索部分 -->
		<el-card class="box-card" shadow="hover">
			<el-form
				:inline="true"
				:model="terminalForm"
				class="demo-form-inline"
			>
				<el-form-item>
					<el-select
						v-model="terminalForm.brand_id"
						filterable
						clearable
						style="width:100%"
						placeholder="请选择终端类型"
					>
						<el-option
							v-for="(item, index) in brandList"
							:key="index"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="terminalForm.terminal_sn"
						placeholder="请输入终端SN"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-select
						v-model="terminalForm.is_active"
						clearable
						placeholder="请选择激活状态"
					>
						<el-option label="已激活" value="1"></el-option>
						<el-option label="未激活" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select
						v-model="terminalForm.class_type"
						clearable
						placeholder="请选择商户分类"
					>
						<el-option label="优质商户" value="1"></el-option>
						<el-option label="活跃商户" value="2"></el-option>
						<el-option label="沉默商户" value="3"></el-option>
						<el-option label="新增商户" value="4"></el-option>
						<el-option label="未激活商户" value="5"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="terminalForm.merchant_number"
						placeholder="请输入商户编号"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="terminalForm.name"
						placeholder="请输入商户名称"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="terminalForm.phone"
						placeholder="请输入终端归属代理商账号"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-input
						clearable
						v-model="terminalForm.real_name"
						placeholder="请输入终端归属代理商姓名"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-date-picker
						v-model="terminalForm.join_time"
						type="datetimerange"
						range-separator="至"
						start-placeholder="入网开始时间"
						end-placeholder="入网结束时间"
						value-format="yyyy-MM-dd HH:mm:ss"
					></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-date-picker
						v-model="terminalForm.bind_time"
						type="datetimerange"
						range-separator="至"
						start-placeholder="绑定开始时间"
						end-placeholder="绑定结束时间"
						value-format="yyyy-MM-dd HH:mm:ss"
					></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-date-picker
						v-model="terminalForm.active_time"
						type="datetimerange"
						range-separator="至"
						start-placeholder="激活开始时间"
						end-placeholder="激活结束时间"
						value-format="yyyy-MM-dd HH:mm:ss"
					></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button
						type="primary"
						icon="el-icon-search"
						style="font-size: 12px;"
						@click="handleSearch(terminalForm)"
						>查询</el-button
					>
				</el-form-item>
			</el-form>
		</el-card>
		<!-- 表格部分 -->
		<el-card class="box-card" style="margin-top: 20px;" shadow="hover">
			<div style="margin-bottom: 20px;">
				<el-button
					type="primary"
					icon="el-icon-download"
					:loading="downLoading"
					@click="handleDownLoad"
					>导出数据</el-button
				>
				<el-button
					type="primary"
					icon="el-icon-folder-opened"
					@click="higher.isShow = true"
				>
					批量修改费率
				</el-button>
			</div>
			<el-table
				:data="terminalTable"
				size="mini"
				border
				style="width: 100%"
				v-loading="loading"
			>
				<!-- id -->
				<el-table-column
					header-align="center"
					align="center"
					fixed
					prop="id"
					label="序号"
					min-width="80"
				></el-table-column>
				<!-- 终端类型 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="brand_name"
					label="终端类型"
					min-width="150"
				></el-table-column>
				<!-- 终端SN -->
				<el-table-column
					header-align="center"
					align="center"
					prop="terminal_sn"
					label="终端SN"
					min-width="200"
				></el-table-column>
				<!-- 激活状态 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="is_active"
					label="激活状态"
					min-width="100"
				>
					<template slot-scope="scope">{{
						scope.row.is_active == 1 ? "已激活" : "未激活"
					}}</template>
				</el-table-column>
				<!-- 商户编号 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="merchant_number"
					label="商户编号"
					min-width="120"
				></el-table-column>
				<!-- 商户名称 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="name"
					label="商户名称"
					min-width="150"
				></el-table-column>
				<!-- 商户分类 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="type"
					label="商户分类"
					min-width="100"
					:formatter="handleMerchantType"
				></el-table-column>
				<!-- 商户备注手机号 -->
				<!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="tel"
                    label="商户备注手机号"
                    min-width="120"
                ></el-table-column>-->
				<!-- 终端归属代理商账号 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="phone"
					label="终端归属代理商账号"
					min-width="150"
				></el-table-column>
				<!-- 终端归属代理商姓名 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="real_name"
					label="终端归属代理商姓名"
					min-width="200"
				></el-table-column>
				<!-- 商户入网时间 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="join_time"
					label="商户入网时间"
					min-width="150"
				></el-table-column>
				<!-- 商户绑定时间 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="bind_time"
					label="商户绑定时间"
					min-width="150"
				></el-table-column>
				<!-- 商户激活时间 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="active_time"
					label="商户激活时间"
					min-width="150"
				></el-table-column>
				<!-- 操作 -->
				<el-table-column
					header-align="center"
					align="center"
					fixed="right"
					label="操作"
					min-width="150"
				>
					<template slot-scope="scope">
						<el-button
							@click="handleInfo(scope.row)"
							type="text"
							size="small"
							>详情</el-button
						>
						<el-button
							v-if="scope.row.is_show_unbind == 1"
							@click="handleCloseBind(scope.row)"
							type="text"
							size="small"
							>终端解绑</el-button
						>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页部分 -->
			<paginations
				:currentPage="page.currentPage"
				:pageSize="page.pageSize"
				:total="page.total"
				@watchCurrent="getCurrent"
				@watchPageSize="getPageSize"
			></paginations>
		</el-card>
		<terminal-info
			v-if="detail.isShow"
			:detail="detail"
			@infoClose="infoClose"
		></terminal-info>
		<TerminalMerchantListCloseBind
			v-if="closeBind.isShow"
			:close-bind="closeBind"
			@closeBindClose="handleCloseCloseBind"
		/>
		<MerchantRateModify
			v-if="higher.isShow"
			:higher="higher"
			@onClose="handleBatchClose"
		/>
	</div>
</template>

<script>
import paginations from "../../../components/pagination/paginations";
import terminalInfo from "./MAM_terminalInfo";
import TerminalMerchantListCloseBind from "../components/TerminalMerchantListCloseBind";
import MerchantRateModify from "../../merchantManagement/components/MerchantRateModify.vue";
export default {
	name: "MAM_terminalList",
	data() {
		return {
			higher: {
				isShow: false,
				data: {},
			},
			brandList: [],
			detail: {
				isShow: false,
			},
			closeBind: {
				// 解绑
				isShow: false,
				row: {},
			},
			terminalTable: [], //表格
			terminalForm: {
				bind_time: [], // 绑定时间
				join_time: [], // 入网时间
				active_time: [], // 激活时间
				brand_id: "", //终端类型id
				terminal_sn: "", //终端sn
				terminal_number: "", //终端编号
				is_active: "", //是否激活 1.已激活 0.未激活
				merchant_number: "", //商户编号
				name: "", //商户名称
				phone: "", //终端归属代理商账号
				real_name: "", //终端归属代理商名称
				class_type: "", //商户分类 1.优质 2.活跃 3.沉默 4.新增 5.未激活
				page: 1,
				limit: 10,
			},
			page: {
				currentPage: 1, //当前页码
				pageSize: 10, //条数
				total: 0, //总数
			},
			loading: true,
			downLoading: false, //导出loading
		};
	},
	mounted() {
		this.$api.post("brand/brand_list", {}, (res) => {
			//获取终端类型
			if (res.code == 0) {
				this.brandList = res.data.list;
			} else {
				this.$$message.success(res.msg);
			}
		});
		this.getData(
			//初始化
			{
				page: 1,
				limit: this.page.pageSize,
			}
		);
	},
	components: {
		paginations,
		terminalInfo,
		TerminalMerchantListCloseBind,
		MerchantRateModify,
	},
	methods: {
		handleBatchClose(ref) {
            this.higher.isShow = false;
			if (ref) {
				this.getData(this.terminalForm);
			}
		},
		handleSearch(terminalForm) {
			this.terminalForm.page = 1;
			this.page.currentPage = 1; //重置分页
			// 表单提交
			this.getData(terminalForm);
		},
		handleInfo(row) {
			//详情
			this.detail.isShow = true;
			this.detail.info = row;
		},
		infoClose() {
			//关闭弹窗
			this.detail.isShow = false;
		},
		handleCloseBind(row) {
			// 解除绑定
			this.closeBind.isShow = true;
			this.closeBind.row = row;
		},
		handleCloseCloseBind(ref) {
			// 关闭解绑弹窗
			this.closeBind.isShow = false;
			this.closeBind.row = {};
			if (ref) {
				this.getData(this.searchForm);
			}
		},
		//分页
		getCurrent(page) {
			this.loading = true;
			// 获取当前页修改的值
			this.page.currentPage = page;
			this.terminalForm.page = this.page.currentPage;
			this.terminalForm.limit = this.page.pageSize;
			this.getData(this.terminalForm);
		},
		getPageSize(limit) {
			this.page.currentPage = 1; //重置分页
			// 获取条数发生改变的值
			this.loading = true;
			this.page.pageSize = limit;
			this.terminalForm.page = this.page.currentPage;
			this.terminalForm.limit = limit;
			this.getData(this.terminalForm);
		},
		getData(params) {
			this.$api.post("merchant/getList", params, (res) => {
				let data = res.data;
				this.page.total = data.count; //总数
				this.terminalTable = data.list;
				//渲染结束后取消加载
				this.loading = false;
			});
		},
		handleDownLoad() {
			//导出
			let handleExportExcel = this.common.handleExportExcel;
			this.downLoading = true;
			let searchVal = this._.omit(this.terminalForm, ["page", "limit"]);
			let value = {};
			this._.forEach(searchVal, (item, index) => {
				if (item != "") {
					value[index] = item;
				}
			});
			handleExportExcel({ type: 8, value: JSON.stringify(value) }).then(
				() => {
					this.downLoading = false;
				}
			);
		},
		handleMerchantType(row) {
			let type;
			switch (row.type) {
				case 1:
					type = "优质商户";
					break;
				case 2:
					type = "活跃商户";
					break;
				case 3:
					type = "沉默商户";
					break;
				case 4:
					type = "新增商户";
					break;
				case 5:
					type = "未激活商户";
					break;
				default:
					type = row.type;
					break;
			}
			return type;
		},
	},
};
</script>

<style lang="scss" scoped></style>
