import { render, staticRenderFns } from "./MAM_merchantRate.vue?vue&type=template&id=280fae20&"
import script from "./MAM_merchantRate.vue?vue&type=script&lang=js&"
export * from "./MAM_merchantRate.vue?vue&type=script&lang=js&"
import style0 from "./MAM_merchantRate.vue?vue&type=style&index=0&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports