<template>
    <div>
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="formInline.order_num" placeholder="请输入订单号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="formInline.transfer_user_name"
                        placeholder="请输入发起人姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="formInline.receive_user_name"
                        placeholder="请输入被划拨人姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="formInline.terminal_sn" placeholder="请输入SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        size="medium"
                        v-model="formInline.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="formInline.pay_type" clearable placeholder="请选择支付方式">
                        <el-option label="全款" value="2"></el-option>
                        <el-option label="贷款" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="formInline.status" clearable placeholder="请选择划拨状态">
                        <el-option label="待处理" value="1"></el-option>
                        <el-option label="已完成" value="2"></el-option>
                        <el-option label="已拒绝" value="3"></el-option>
                        <el-option label="已撤回" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="formInline.rangeDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="订单创建开始日期"
                        end-placeholder="订单创建结束日期"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(formInline)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-s-promotion"
                    @click="transferTerminal"
                >终端划拨</el-button>
            </div>
            <el-table
                :data="transferList.list"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 划拨订单号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="order_num"
                    label="划拨订单号"
                    min-width="250"
                ></el-table-column>
                <!-- 货款订单号 -->
                <el-table-column
                    key="loan_order_num"
                    v-if="repayment_type == 2"
                    header-align="center"
                    align="center"
                    prop="loan_order_num"
                    label="货款订单号"
                    min-width="250"
                ></el-table-column>
                <!-- 发起人姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="transfer_user_name"
                    label="发起人姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 发起人账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="transfer_user_phone"
                    label="发起人账号"
                    min-width="120"
                ></el-table-column>
                <!-- 被划拨人姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="receive_user_name"
                    label="被划拨人姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 被划拨人账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="receive_user_phone"
                    label="被划拨人账号"
                    min-width="120"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="120"
                ></el-table-column>
                <!-- 终端数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_num"
                    label="终端数量"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleDetail(scope.row)"
                            type="text"
                            size="small"
                        >{{scope.row.terminal_num}}</el-button>
                    </template>
                </el-table-column>
                <!-- 终端单价 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_price"
                    label="终端单价"
                    min-width="120"
                ></el-table-column>
                <!-- 采购单价 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="procurement_price"
                    label="采购单价"
                    min-width="120"
                ></el-table-column>
                <!-- 采购订单号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="procurement_order_num"
                    label="采购订单号"
                    min-width="200"
                ></el-table-column>
                <!-- 支付方式 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="pay_type_name"
                    label="支付方式"
                    min-width="120"
                ></el-table-column>
                <!-- 应付金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="all_money"
                    label="应付金额"
                    min-width="120"
                ></el-table-column>
                <!-- 实付金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="down_money"
                    label="实付金额"
                    min-width="120"
                ></el-table-column>
                <!-- 贷款金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="loan_money"
                    label="贷款金额"
                    min-width="120"
                ></el-table-column>
                <template v-if="repayment_type == 2">
                    <!-- 还款期数 -->
                    <el-table-column
                        key="loan_period"
                        header-align="center"
                        align="center"
                        prop="loan_period"
                        label="还款期数"
                        min-width="120"
                    ></el-table-column>
                    <!-- 每月约还 -->
                    <el-table-column
                        key="every_period_money"
                        header-align="center"
                        align="center"
                        prop="every_period_money"
                        label="每月约还"
                        min-width="120"
                    ></el-table-column>
                </template>
                <!-- 是否延期入账 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="is_postponed"
                    label="是否延期入账"
                    min-width="120"
                ></el-table-column>
                <!-- 入账日期 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="postponed_date"
                    label="入账日期"
                    min-width="120"
                ></el-table-column>
                <!-- 订单状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status_name"
                    label="订单状态"
                    min-width="120"
                ></el-table-column>
                <!-- 接收方式 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="receive_method"
                    label="接收方式"
                    min-width="120"
                ></el-table-column>
                <!-- 操作时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="deal_time"
                    label="操作时间"
                    min-width="150"
                ></el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="transfer_time"
                    label="创建时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleRevocation(scope.row)"
                            type="text"
                            size="small"
                            v-show="scope.row.transfer_user_id == 0 && scope.row.status == 1"
                        >撤销</el-button>
                        <el-popover placement="left" width="400" trigger="click" title="拒绝原因">
                            <el-input
                                type="textarea"
                                v-model="reason"
                                :disabled="true"
                                style="font-size:12px"
                            ></el-input>
                            <el-button
                                @click="handleRefusalCause(scope.row)"
                                type="text"
                                size="small"
                                slot="reference"
                                v-show="scope.row.status == 3"
                            >查看拒绝原因</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <!-- 终端数量 -->
        <terminal-count
            v-if="infoIsShow"
            :infoIsShow="infoIsShow"
            :listId="listId"
            @infoClose="infoClose"
        ></terminal-count>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import terminalCount from './MAM_terminalCount';
export default {
    name: "MAM_transferList",
    data() {
        return {
            repayment_type: '',
            infoIsShow: false, //弹窗
            listId: null,
            brandList: [],
            reason: '', //拒绝原因
            formInline: {
                order_num: '', //订单号
                transfer_user_name: '', //发起人姓名
                receive_user_name: '', //被划拨人姓名
                brand_id: '', //终端类型id
                pay_type: '', //支付方式
                status: '', //划拨状态
                rangeDate: '', //订单创建时间
                terminal_sn: ''
            },
            transferList: {
                list: [],
            },
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            condition: {},//
            loading: true
        }
    },
    mounted() {
        this.repayment_type = sessionStorage.getItem('repayment_type');
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$$message.success(res.msg);
            }
        });
        this.getData({
            page: this.page.currentPage,
            limit: this.page.pageSize,
        })
    },
    components: {
        paginations,
        terminalCount
    },
    methods: {
        onSearch(formInline) { //搜索
            this.page.currentPage = 1;
            let rangeDate = formInline.rangeDate;
            formInline.start_time = rangeDate ? rangeDate[0] : '';
            formInline.end_time = rangeDate ? rangeDate[1] : '';
            this.condition = formInline;
            // 表单提交
            this.getData({
                condition: JSON.stringify(formInline),
                page: 1,
                limit: this.page.pageSize,
            })
        },
        //
        handleDetail(row) { //终端数量
            this.infoIsShow = true;
            this.listId = row.id;
        },
        handleRevocation(row) { //撤销
            let that = this;
            this.$confirm('撤销后终端将自动撤回，是否确认操作？', '提 示', {
                type: 'warning',
                distinguishCancelAndClose: true,
                confirmButtonText: '确 定',
                cancelButtonText: '取 消'
            }).then(() => {
                this.$api.post('terminal_transfer/revoke_transfer', { transfer_log_id: row.id }, res => {
                    if (res.code == 0) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 2000,
                            onClose() {
                                that.getData({
                                    condition: JSON.stringify(that.formInline),
                                    page: that.page.currentPage,
                                    limit: that.page.pageSize
                                })
                            }
                        })
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }).catch(() => {

            })
        },
        handleRefusalCause(row) { //查看拒绝原因
            this.reason = row.reason;
        },
        transferTerminal() { //终端划拨
            this.$router.push({ path: '/transferTerminal' })
        },
        infoClose() {
            this.infoIsShow = false;
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.loading = true;
            this.getData({
                condition: JSON.stringify(this.formInline),
                page: this.page.currentPage,
                limit: this.page.pageSize,
            });
        },
        getPageSize(limit) {
            this.page.currentPage = 1;
            // 获取条数发生改变的值
            this.page.pageSize = limit;
            this.loading = true;
            this.getData({
                condition: JSON.stringify(this.formInline),
                page: this.page.currentPage,
                limit: limit,
            })
        },
        getData(params) {
            this.$api.post("terminal_transfer/terminal_transfer_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.transferList.list = res.data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scope>
.el-input__inner {
    font-size: 12px;
}
.el-form-item {
    margin-bottom: 5px;
}
.el-date-editor .el-range-input,
.el-date-editor .el-range-separator {
    font-size: 12px;
}
.el-popover__title {
    font-size: 12px;
    font-weight: 700;
}
</style>
