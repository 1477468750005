<template>
    <div class="offsetDetail">
        <el-dialog
            title="还款明细"
            width="85%"
            append-to-body
            :visible="repaymentInfo.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <!-- 表格部分 -->
            <el-card class="box-card" style="margin-top: 20px;" shadow="always">
                <el-table
                    :data="repaymentList"
                    size="mini"
                    border
                    style="width: 100%"
                    v-loading="loading"
                >
                    <!-- 订单号 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="order_num"
                        label="订单号"
                        min-width="250"
                    ></el-table-column>
                    <!-- 还款方式 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="repayment_type"
                        label="还款方式"
                        min-width="120"
                    ></el-table-column>
                    <!-- 还款金额 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="current_repayment_money"
                        label="还款金额"
                        min-width="120"
                    ></el-table-column>
                    <!-- 还款时间 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="repayment_date_time"
                        label="还款时间"
                        min-width="120"
                    ></el-table-column>
                </el-table>
                <!-- 分页部分 -->
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,50,100]"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    :total="total"
                    layout="total, sizes, prev, pager, next, jumper"
                ></el-pagination>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "offsetDetail",
    props: {
        repaymentInfo: {
            type: Object
        }
    },
    data() {
        return {
            repaymentList: [], //列表
            loading: false, //列表loadding
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        });
    },
    methods: {
        handleClose() { //关闭
            this.$emit('handleClose');
        },
        //分页
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.getData({
                page: this.currentPage,
                limit: this.pageSize
            });
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.getData({
                page: this.currentPage,
                limit: this.pageSize
            });
        },
        getData(params) { //获取数据
            this.loading = true;
            let reqData = this._.assign({ id: this.repaymentInfo.info.id }, params);
            this.$api.post("user_installment_loan/get_repayment_detail", reqData, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.repaymentList = data.list;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped lang="scss">
</style>