<template>
    <div class="FM_platformRecord">
        <!-- 账户记录查询 -->
        <el-dialog
            :visible="platformRecordShow"
            @close="handleClose"
            width="800px"
            :close-on-click-modal="false"
            title="账户记录查询"
        >
            <el-card class="box-card" shadow="hover">
                <el-tabs v-model="activeName" type="card" @tab-click="handleTab">
                    <el-tab-pane label="账户记录查询" name="1">
                        <el-table
                            :data="walletTable"
                            size="mini"
                            border
                            height="415px"
                            style="width: 100%"
                            ref="walletTable"
                            v-loading="walletLoading"
                        >
                            <!-- id -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                fixed
                                prop="id"
                                label="序号"
                                min-width="80"
                            ></el-table-column>
                            <!-- 账户类型 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                label="账户类型"
                                min-width="200"
                            >
                                <template slot-scope="scope">
                                    <span>平台账户</span>
                                </template>
                            </el-table-column>
                            <!-- 入账类型 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="pay_type"
                                label="入账类型"
                                min-width="120"
                            ></el-table-column>
                            <!-- 科目 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="class_type"
                                label="科目"
                                min-width="150"
                            ></el-table-column>
                            <!-- 金额 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="money"
                                label="金额"
                                min-width="200"
                            ></el-table-column>
                            <!-- 账户余额 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="balance"
                                label="账户余额"
                                min-width="200"
                            ></el-table-column>
                            <!-- 创建时间 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="create_time"
                                label="创建时间"
                                min-width="200"
                            ></el-table-column>
                        </el-table>
                        <!-- 分页部分 -->
                        <paginations
                            :currentPage="wallet.page"
                            :pageSize="wallet.limit"
                            :total="wallet.total"
                            @watchCurrent="getWalletCurrent"
                            @watchPageSize="getWalletPageSize"
                        ></paginations>
                    </el-tab-pane>
                    <el-tab-pane label="充值记录查询" name="2">
                        <el-table
                            :data="rechargeTable"
                            size="mini"
                            border
                            height="415px"
                            style="width: 100%"
                            ref="rechargeTable"
                            v-loading="rechargeLoading"
                        >
                            <!-- id -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                fixed
                                prop="id"
                                label="序号"
                                min-width="80"
                            ></el-table-column>
                            <!-- 账户类型 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                label="账户类型"
                                min-width="200"
                            >
                                <template slot-scope="scope">
                                    <span>平台账户</span>
                                </template>
                            </el-table-column>
                            <!-- 充值金额 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="money"
                                label="充值金额"
                                min-width="120"
                            ></el-table-column>
                            <!-- 凭证 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="path"
                                label="凭证"
                                min-width="150"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        @click="previewPic(scope.row)"
                                        type="text"
                                        size="small"
                                    >点击查看凭证</el-button>
                                </template>
                            </el-table-column>
                            <!-- 提交时间 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="create_time"
                                label="提交时间"
                                min-width="200"
                            ></el-table-column>
                            <!-- 状态 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="status"
                                label="状态"
                                min-width="200"
                                :formatter="formatStatus"
                            ></el-table-column>
                            <!-- 原因 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="reason"
                                label="原因"
                                min-width="200"
                            >
                                <template
                                    slot-scope="scope"
                                >{{scope.row.reason == ''? '--':scope.row.reason}}</template>
                            </el-table-column>
                            <!-- 操作员 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="admin_name"
                                label="操作员"
                                min-width="200"
                            ></el-table-column>
                        </el-table>
                        <!-- 分页部分 -->
                        <paginations
                            :currentPage="recharge.page"
                            :pageSize="recharge.limit"
                            :total="recharge.total"
                            @watchCurrent="getRechargeCurrent"
                            @watchPageSize="getRechargePageSize"
                        ></paginations>
                    </el-tab-pane>
                </el-tabs>
            </el-card>
        </el-dialog>
        <el-dialog v-if="previewPicIsShow" :visible.sync="previewPicIsShow">
            <img width="100%" :src="defaultPic" alt />
        </el-dialog>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
export default {
    name: "FM_platformRecord",
    props: {
        platformRecordShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeName: '1',
            previewPicIsShow: false,
            defaultPic: '',
            recharge: {
                page: 1, //当前页码
                limit: 10, //条数
                total: 0  //总数
            },
            wallet: {
                page: 1, //当前页码
                limit: 10, //条数
                total: 0  //总数
            },
            walletTable: [], //账户记录
            rechargeTable: [], //充值记录
            walletLoading: false,
            rechargeLoading: false,
        }
    },
    mounted() {
        this.getWalletData({ //初始化
            page: this.wallet.page,
            limit: this.wallet.limit
        });
    },
    components: {
        paginations
    },
    methods: {
        handleTab(tab) {
            let index = tab.name; //tab的name值
            switch (index) {
                case '1': //账户记录查询
                    this.walletLoading = true;
                    this.getWalletData({
                        page: this.wallet.page,
                        limit: this.wallet.limit
                    });
                    this.$refs.walletTable.doLayout();
                    break;
                default: //充值记录查询
                    this.rechargeLoading = true;
                    this.getRechargeData({
                        page: this.recharge.page,
                        limit: this.recharge.limit
                    });
                    this.$refs.rechargeTable.doLayout();
                    break;
            }
        },
        handleClose() {
            this.$emit('platformClose');
        },
        //账户记录部分
        getWalletCurrent(page) {
            this.walletLoading = true;
            // 获取当前页修改的值
            this.wallet.page = page;
            this.getWalletData({
                page: this.wallet.page,
                limit: this.wallet.limit
            });
        },
        getWalletPageSize(limit) {
            this.wallet.page = 1; //重置分页
            // 获取条数发生改变的值
            this.walletLoading = true;
            this.wallet.limit = limit;
            this.getWalletData({ //初始化
                page: this.wallet.page,
                limit: this.wallet.limit
            });
        },
        getWalletData(params) {
            this.$api.post("organization/getBalanceLogList", params, res => {
                let data = res.data;
                this.wallet.total = data.count; //总数
                this.walletTable = data.list;
                //渲染结束后取消加载
                this.walletLoading = false;
            })
        },
        //充值记录查询
        getRechargeCurrent(page) {
            this.rechargeLoading = true;
            // 获取当前页修改的值
            this.recharge.page = page;
            this.getRechargeData({
                page: this.recharge.page,
                limit: this.recharge.limit
            });
        },
        getRechargePageSize(limit) {
            this.recharge.page = 1; //重置分页
            // 获取条数发生改变的值
            this.rechargeLoading = true;
            this.recharge.limit = limit;
            this.getRechargeData({ //初始化
                page: this.recharge.page,
                limit: this.recharge.limit
            });
        },
        getRechargeData(params) {
            this.$api.post("organization/getRechargeList", params, res => {
                let data = res.data;
                this.recharge.total = data.count; //总数
                this.rechargeTable = data.list;
                //渲染结束后取消加载
                this.rechargeLoading = false;
            })
        },
        formatStatus(row) { //格式化状态
            let status = '';
            switch (row.status) {
                case 0:
                    status = '审核中';
                    break;
                case 1:
                    status = '审核通过';
                    break;
                default:
                    status = '审核驳回';
                    break;
            }
            return status;
        },
        previewPic(row) { //预览图片
            this.previewPicIsShow = true;
            this.defaultPic = row.path;
        }
    }
};
</script>

<style lang="scss" scope>
.FM_platformRecord {
    .purseTitle {
        font-size: 12px;
        color: #909399;
        :nth-child(1) {
            font-weight: 700;
            color: #7f7f7f;
        }
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item {
        font-size: 12px;
    }
    // .el-dialog {
    //     min-width: 800px;
    // }
    // .el-dialog__header {
    //     padding: 20px 20px 0px;
    // }
    // .el-dialog__body {
    //     padding: 0px 20px 30px 20px;
    // }
    // .el-input__inner {
    //     font-size: 12px;
    // }
    // .el-form-item {
    //     margin-bottom: 5px;
    // }
}
</style>
