/** 帮助中心 */
import helpClass from '../../views/helpCenter/helpClass/HC_classList'; //帮助分类
import helpList from '../../views/helpCenter/helpList/HC_helpList'; //帮助列表
import helpInfo from '../../views/helpCenter/helpList/HC_helpInfo'; //帮助详情

export default [
    {
        path: '/helpClass',
        name: 'helpClass',
        meta: {
            title: '帮助分类'
        },
        component: helpClass
    },
    {
        path: '/helpList',
        name: 'helpList',
        meta: {
            title: '帮助列表'
        },
        component: helpList
    },
    {
        path: '/helpInfo',
        name: 'helpInfo',
        meta: {
            title: '帮助详情'
        },
        component: helpInfo
    }
]