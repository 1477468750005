<template>
	<!-- 考核扣费记录 - 详情 -->
	<div class="InspectionFeeListDetail">
		<el-dialog
			:visible="higher.isShow"
			@close="handleClose"
			:close-on-click-modal="false"
			width="1000px"
			title="详情"
		>
			<el-card style="margin-top: 20px;" shadow="always">
				<el-table
					:data="tableList"
					size="small"
					border
					style="width: 100%"
					v-loading="loading"
					max-height="500px"
				>
					<!-- 序号 -->
					<el-table-column
						type="index"
						header-align="center"
						align="center"
						fixed
						label="序号"
						width="100"
						:index="handleTableIndex"
					></el-table-column>
					<!-- 代理商编号 -->
					<el-table-column
						header-align="center"
						align="center"
						prop="user_code"
						label="代理商编号"
						min-width="150"
						show-overflow-tooltip
					></el-table-column>
					<!-- 代理商名称 -->
					<el-table-column
						header-align="center"
						align="center"
						prop="real_name"
						label="代理商名称"
						min-width="150"
						show-overflow-tooltip
					></el-table-column>
					<!-- 代理商级别 -->
					<el-table-column
						header-align="center"
						align="center"
						prop="user_level"
						label="代理商级别"
						min-width="150"
						show-overflow-tooltip
					></el-table-column>
					<!-- 实际扣款时间 -->
					<el-table-column
						header-align="center"
						align="center"
						prop="create_time"
						label="实际扣款时间"
						min-width="150"
						show-overflow-tooltip
					></el-table-column>
					<!-- 实际扣款金额 -->
					<el-table-column
						header-align="center"
						align="center"
						prop="dec_money"
						label="实际扣款金额"
						min-width="150"
						show-overflow-tooltip
					></el-table-column>
				</el-table>
				<!-- 分页部分 -->
				<el-pagination
					background
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:page-sizes="[10, 20, 50, 100]"
					:current-page="currentPage"
					:page-size="pageSize"
					:total="total"
					layout="total, sizes, prev, pager, next, jumper"
				></el-pagination>
			</el-card>
		</el-dialog>
	</div>
</template>
<script>
export default {
	name: "InspectionFeeListDetail",
	props: {
		higher: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			searchForm: {
				page: 1,
				limit: 10,
			},
			tableList: [],
			currentPage: 1, // 当前页码
			pageSize: 10, // 条数
			total: 0, // 总数
			loading: false,
		};
	},
	created() {
		this.getData(this.searchForm);
	},
	methods: {
		handleSearch(searchForm) {
			this.currentPage = 1;
			searchForm.page = 1;
			this.getData(searchForm);
		},
		handleCurrentChange(page) {
			//当前选中页
			this.currentPage = page;
			this.searchForm.page = page;
			this.searchForm.limit = this.pageSize;
			this.getData(this.searchForm);
		},
		handleSizeChange(limit) {
			//条数
			this.currentPage = 1;
			this.pageSize = limit;
			this.searchForm.page = 1;
			this.searchForm.limit = limit;
			this.getData(this.searchForm);
		},
		getData(params) {
			const { id } = this.higher.data;
			let req = {
				...params,
				...{ id },
			};
			this.loading = true;
			this.$api.post(
				"terminal/terminal_nactive_dec_money_info",
				req,
				(res) => {
					if (res.code == 0) {
						let { list, count } = res.data;
						this.total = count; //总数
						this.tableList = list;
					} else {
						this.$message({
							type: "error",
							duration: 1500,
							message: res.msg,
						});
					}
					//渲染结束后取消加载
					this.loading = false;
				}
			);
		},
		handleTableIndex(index) {
			// 处理序号
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		handleClose(ref) {
			this.$emit("onClose", ref);
		},
	},
};
</script>

<style scoped lang="scss"></style>
