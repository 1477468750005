export default [
    {
        "areaCode": 110000000000,
        "areaId": 1,
        "areaName": "北京市",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 110100000000,
                "areaId": 2,
                "areaName": "市辖区",
                "parent_id": 1,
                "level": 1,
                "children": [
                    {
                        "areaCode": 110101000000,
                        "areaId": 3,
                        "areaName": "东城区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110102000000,
                        "areaId": 203,
                        "areaName": "西城区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110105000000,
                        "areaId": 480,
                        "areaName": "朝阳区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110106000000,
                        "areaId": 1135,
                        "areaName": "丰台区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110107000000,
                        "areaId": 1546,
                        "areaName": "石景山区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110108000000,
                        "areaId": 1707,
                        "areaName": "海淀区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110109000000,
                        "areaId": 2398,
                        "areaName": "门头沟区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110111000000,
                        "areaId": 2720,
                        "areaName": "房山区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110112000000,
                        "areaId": 3364,
                        "areaName": "通州区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110113000000,
                        "areaId": 3968,
                        "areaName": "顺义区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110114000000,
                        "areaId": 4547,
                        "areaName": "昌平区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110115000000,
                        "areaId": 5106,
                        "areaName": "大兴区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110116000000,
                        "areaId": 5883,
                        "areaName": "怀柔区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110117000000,
                        "areaId": 6221,
                        "areaName": "平谷区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110118000000,
                        "areaId": 6552,
                        "areaName": "密云区",
                        "parent_id": 2,
                        "level": 2
                    },
                    {
                        "areaCode": 110119000000,
                        "areaId": 7007,
                        "areaName": "延庆区",
                        "parent_id": 2,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 120000000000,
        "areaId": 7448,
        "areaName": "天津市",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 120100000000,
                "areaId": 7449,
                "areaName": "市辖区",
                "parent_id": 7448,
                "level": 1,
                "children": [
                    {
                        "areaCode": 120101000000,
                        "areaId": 7450,
                        "areaName": "和平区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120102000000,
                        "areaId": 7520,
                        "areaName": "河东区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120103000000,
                        "areaId": 7697,
                        "areaName": "河西区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120104000000,
                        "areaId": 7853,
                        "areaName": "南开区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120105000000,
                        "areaId": 8045,
                        "areaName": "河北区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120106000000,
                        "areaId": 8170,
                        "areaName": "红桥区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120110000000,
                        "areaId": 8381,
                        "areaName": "东丽区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120111000000,
                        "areaId": 8651,
                        "areaName": "西青区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120112000000,
                        "areaId": 8932,
                        "areaName": "津南区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120113000000,
                        "areaId": 9210,
                        "areaName": "北辰区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120114000000,
                        "areaId": 9488,
                        "areaName": "武清区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120115000000,
                        "areaId": 10255,
                        "areaName": "宝坻区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120116000000,
                        "areaId": 11095,
                        "areaName": "滨海新区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120117000000,
                        "areaId": 11585,
                        "areaName": "宁河区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120118000000,
                        "areaId": 11928,
                        "areaName": "静海区",
                        "parent_id": 7449,
                        "level": 2
                    },
                    {
                        "areaCode": 120119000000,
                        "areaId": 12384,
                        "areaName": "蓟州区",
                        "parent_id": 7449,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 130000000000,
        "areaId": 13400,
        "areaName": "河北省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 130100000000,
                "areaId": 13401,
                "areaName": "石家庄市",
                "parent_id": 13400,
                "level": 1,
                "children": [
                    {
                        "areaCode": 130101000000,
                        "areaId": 13402,
                        "areaName": "市辖区",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130102000000,
                        "areaId": 13403,
                        "areaName": "长安区",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130104000000,
                        "areaId": 13577,
                        "areaName": "桥西区",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130105000000,
                        "areaId": 13736,
                        "areaName": "新华区",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130107000000,
                        "areaId": 13863,
                        "areaName": "井陉矿区",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130108000000,
                        "areaId": 13923,
                        "areaName": "裕华区",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130109000000,
                        "areaId": 14035,
                        "areaName": "藁城区",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130110000000,
                        "areaId": 14288,
                        "areaName": "鹿泉区",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130111000000,
                        "areaId": 14523,
                        "areaName": "栾城区",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130121000000,
                        "areaId": 14708,
                        "areaName": "井陉县",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130123000000,
                        "areaId": 15048,
                        "areaName": "正定县",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130125000000,
                        "areaId": 15246,
                        "areaName": "行唐县",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130126000000,
                        "areaId": 15593,
                        "areaName": "灵寿县",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130127000000,
                        "areaId": 15893,
                        "areaName": "高邑县",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130128000000,
                        "areaId": 16006,
                        "areaName": "深泽县",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130129000000,
                        "areaId": 16139,
                        "areaName": "赞皇县",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130130000000,
                        "areaId": 16364,
                        "areaName": "无极县",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130131000000,
                        "areaId": 16593,
                        "areaName": "平山县",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130132000000,
                        "areaId": 17334,
                        "areaName": "元氏县",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130133000000,
                        "areaId": 17563,
                        "areaName": "赵县",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130171000000,
                        "areaId": 17856,
                        "areaName": "石家庄高新技术产业开发区",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130172000000,
                        "areaId": 17901,
                        "areaName": "石家庄循环化工园区",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130181000000,
                        "areaId": 17916,
                        "areaName": "辛集市",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130183000000,
                        "areaId": 18292,
                        "areaName": "晋州市",
                        "parent_id": 13401,
                        "level": 2
                    },
                    {
                        "areaCode": 130184000000,
                        "areaId": 18527,
                        "areaName": "新乐市",
                        "parent_id": 13401,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 130200000000,
                "areaId": 18710,
                "areaName": "唐山市",
                "parent_id": 13400,
                "level": 1,
                "children": [
                    {
                        "areaCode": 130201000000,
                        "areaId": 18711,
                        "areaName": "市辖区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130202000000,
                        "areaId": 18712,
                        "areaName": "路南区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130203000000,
                        "areaId": 18882,
                        "areaName": "路北区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130204000000,
                        "areaId": 19135,
                        "areaName": "古冶区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130205000000,
                        "areaId": 19347,
                        "areaName": "开平区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130207000000,
                        "areaId": 19534,
                        "areaName": "丰南区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130208000000,
                        "areaId": 20035,
                        "areaName": "丰润区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130209000000,
                        "areaId": 20586,
                        "areaName": "曹妃甸区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130223000000,
                        "areaId": 20751,
                        "areaName": "滦县",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130224000000,
                        "areaId": 21295,
                        "areaName": "滦南县",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130225000000,
                        "areaId": 21920,
                        "areaName": "乐亭县",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130227000000,
                        "areaId": 22424,
                        "areaName": "迁西县",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130229000000,
                        "areaId": 22872,
                        "areaName": "玉田县",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130271000000,
                        "areaId": 23667,
                        "areaName": "唐山市芦台经济技术开发区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130272000000,
                        "areaId": 23701,
                        "areaName": "唐山市汉沽管理区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130273000000,
                        "areaId": 23741,
                        "areaName": "唐山高新技术产业开发区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130274000000,
                        "areaId": 23813,
                        "areaName": "河北唐山海港经济开发区",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130281000000,
                        "areaId": 23883,
                        "areaName": "遵化市",
                        "parent_id": 18710,
                        "level": 2
                    },
                    {
                        "areaCode": 130283000000,
                        "areaId": 24606,
                        "areaName": "迁安市",
                        "parent_id": 18710,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 130300000000,
                "areaId": 25194,
                "areaName": "秦皇岛市",
                "parent_id": 13400,
                "level": 1,
                "children": [
                    {
                        "areaCode": 130301000000,
                        "areaId": 25195,
                        "areaName": "市辖区",
                        "parent_id": 25194,
                        "level": 2
                    },
                    {
                        "areaCode": 130302000000,
                        "areaId": 25196,
                        "areaName": "海港区",
                        "parent_id": 25194,
                        "level": 2
                    },
                    {
                        "areaCode": 130303000000,
                        "areaId": 25594,
                        "areaName": "山海关区",
                        "parent_id": 25194,
                        "level": 2
                    },
                    {
                        "areaCode": 130304000000,
                        "areaId": 25725,
                        "areaName": "北戴河区",
                        "parent_id": 25194,
                        "level": 2
                    },
                    {
                        "areaCode": 130306000000,
                        "areaId": 25785,
                        "areaName": "抚宁区",
                        "parent_id": 25194,
                        "level": 2
                    },
                    {
                        "areaCode": 130321000000,
                        "areaId": 26166,
                        "areaName": "青龙满族自治县",
                        "parent_id": 25194,
                        "level": 2
                    },
                    {
                        "areaCode": 130322000000,
                        "areaId": 26593,
                        "areaName": "昌黎县",
                        "parent_id": 25194,
                        "level": 2
                    },
                    {
                        "areaCode": 130324000000,
                        "areaId": 27051,
                        "areaName": "卢龙县",
                        "parent_id": 25194,
                        "level": 2
                    },
                    {
                        "areaCode": 130371000000,
                        "areaId": 27614,
                        "areaName": "秦皇岛市经济技术开发区",
                        "parent_id": 25194,
                        "level": 2
                    },
                    {
                        "areaCode": 130372000000,
                        "areaId": 27725,
                        "areaName": "北戴河新区",
                        "parent_id": 25194,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 130400000000,
                "areaId": 27790,
                "areaName": "邯郸市",
                "parent_id": 13400,
                "level": 1,
                "children": [
                    {
                        "areaCode": 130401000000,
                        "areaId": 27791,
                        "areaName": "市辖区",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130402000000,
                        "areaId": 27792,
                        "areaName": "邯山区",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130403000000,
                        "areaId": 27994,
                        "areaName": "丛台区",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130404000000,
                        "areaId": 28179,
                        "areaName": "复兴区",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130406000000,
                        "areaId": 28296,
                        "areaName": "峰峰矿区",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130407000000,
                        "areaId": 28533,
                        "areaName": "肥乡区",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130408000000,
                        "areaId": 28808,
                        "areaName": "永年区",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130423000000,
                        "areaId": 29190,
                        "areaName": "临漳县",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130424000000,
                        "areaId": 29630,
                        "areaName": "成安县",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130425000000,
                        "areaId": 29874,
                        "areaName": "大名县",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130426000000,
                        "areaId": 30546,
                        "areaName": "涉县",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130427000000,
                        "areaId": 30878,
                        "areaName": "磁县",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130430000000,
                        "areaId": 31149,
                        "areaName": "邱县",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130431000000,
                        "areaId": 31382,
                        "areaName": "鸡泽县",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130432000000,
                        "areaId": 31561,
                        "areaName": "广平县",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130433000000,
                        "areaId": 31738,
                        "areaName": "馆陶县",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130434000000,
                        "areaId": 32024,
                        "areaName": "魏县",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130435000000,
                        "areaId": 32607,
                        "areaName": "曲周县",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130471000000,
                        "areaId": 32960,
                        "areaName": "邯郸经济技术开发区",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130473000000,
                        "areaId": 33077,
                        "areaName": "邯郸冀南新区",
                        "parent_id": 27790,
                        "level": 2
                    },
                    {
                        "areaCode": 130481000000,
                        "areaId": 33230,
                        "areaName": "武安市",
                        "parent_id": 27790,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 130500000000,
                "areaId": 33772,
                "areaName": "邢台市",
                "parent_id": 13400,
                "level": 1,
                "children": [
                    {
                        "areaCode": 130501000000,
                        "areaId": 33773,
                        "areaName": "市辖区",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130502000000,
                        "areaId": 33774,
                        "areaName": "桥东区",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130503000000,
                        "areaId": 33916,
                        "areaName": "桥西区",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130521000000,
                        "areaId": 34043,
                        "areaName": "邢台县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130522000000,
                        "areaId": 34580,
                        "areaName": "临城县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130523000000,
                        "areaId": 34809,
                        "areaName": "内丘县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130524000000,
                        "areaId": 35132,
                        "areaName": "柏乡县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130525000000,
                        "areaId": 35261,
                        "areaName": "隆尧县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130526000000,
                        "areaId": 35553,
                        "areaName": "任县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130527000000,
                        "areaId": 35757,
                        "areaName": "南和县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130528000000,
                        "areaId": 35993,
                        "areaName": "宁晋县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130529000000,
                        "areaId": 36389,
                        "areaName": "巨鹿县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130530000000,
                        "areaId": 36691,
                        "areaName": "新河县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130531000000,
                        "areaId": 36867,
                        "areaName": "广宗县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130532000000,
                        "areaId": 37089,
                        "areaName": "平乡县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130533000000,
                        "areaId": 37357,
                        "areaName": "威县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130534000000,
                        "areaId": 37903,
                        "areaName": "清河县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130535000000,
                        "areaId": 38241,
                        "areaName": "临西县",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130571000000,
                        "areaId": 38558,
                        "areaName": "河北邢台经济开发区",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130581000000,
                        "areaId": 38643,
                        "areaName": "南宫市",
                        "parent_id": 33772,
                        "level": 2
                    },
                    {
                        "areaCode": 130582000000,
                        "areaId": 39124,
                        "areaName": "沙河市",
                        "parent_id": 33772,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 130600000000,
                "areaId": 39401,
                "areaName": "保定市",
                "parent_id": 13400,
                "level": 1,
                "children": [
                    {
                        "areaCode": 130601000000,
                        "areaId": 39402,
                        "areaName": "市辖区",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130602000000,
                        "areaId": 39403,
                        "areaName": "竞秀区",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130606000000,
                        "areaId": 39573,
                        "areaName": "莲池区",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130607000000,
                        "areaId": 39840,
                        "areaName": "满城区",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130608000000,
                        "areaId": 40048,
                        "areaName": "清苑区",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130609000000,
                        "areaId": 40346,
                        "areaName": "徐水区",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130623000000,
                        "areaId": 40691,
                        "areaName": "涞水县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130624000000,
                        "areaId": 41001,
                        "areaName": "阜平县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130626000000,
                        "areaId": 41225,
                        "areaName": "定兴县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130627000000,
                        "areaId": 41524,
                        "areaName": "唐县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130628000000,
                        "areaId": 41913,
                        "areaName": "高阳县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130629000000,
                        "areaId": 42110,
                        "areaName": "容城县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130630000000,
                        "areaId": 42246,
                        "areaName": "涞源县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130631000000,
                        "areaId": 42557,
                        "areaName": "望都县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130632000000,
                        "areaId": 42720,
                        "areaName": "安新县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130633000000,
                        "areaId": 42945,
                        "areaName": "易县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130634000000,
                        "areaId": 43442,
                        "areaName": "曲阳县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130635000000,
                        "areaId": 43837,
                        "areaName": "蠡县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130636000000,
                        "areaId": 44089,
                        "areaName": "顺平县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130637000000,
                        "areaId": 44341,
                        "areaName": "博野县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130638000000,
                        "areaId": 44483,
                        "areaName": "雄县",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130671000000,
                        "areaId": 44728,
                        "areaName": "保定高新技术产业开发区",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130672000000,
                        "areaId": 44769,
                        "areaName": "保定白沟新城",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130681000000,
                        "areaId": 44804,
                        "areaName": "涿州市",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130682000000,
                        "areaId": 45269,
                        "areaName": "定州市",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130683000000,
                        "areaId": 45826,
                        "areaName": "安国市",
                        "parent_id": 39401,
                        "level": 2
                    },
                    {
                        "areaCode": 130684000000,
                        "areaId": 46045,
                        "areaName": "高碑店市",
                        "parent_id": 39401,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 130700000000,
                "areaId": 46497,
                "areaName": "张家口市",
                "parent_id": 13400,
                "level": 1,
                "children": [
                    {
                        "areaCode": 130701000000,
                        "areaId": 46498,
                        "areaName": "市辖区",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130702000000,
                        "areaId": 46499,
                        "areaName": "桥东区",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130703000000,
                        "areaId": 46601,
                        "areaName": "桥西区",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130705000000,
                        "areaId": 46671,
                        "areaName": "宣化区",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130706000000,
                        "areaId": 47062,
                        "areaName": "下花园区",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130708000000,
                        "areaId": 47127,
                        "areaName": "万全区",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130709000000,
                        "areaId": 47325,
                        "areaName": "崇礼区",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130722000000,
                        "areaId": 47553,
                        "areaName": "张北县",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130723000000,
                        "areaId": 47948,
                        "areaName": "康保县",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130724000000,
                        "areaId": 48301,
                        "areaName": "沽源县",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130725000000,
                        "areaId": 48549,
                        "areaName": "尚义县",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130726000000,
                        "areaId": 48742,
                        "areaName": "蔚县",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130727000000,
                        "areaId": 49326,
                        "areaName": "阳原县",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130728000000,
                        "areaId": 49648,
                        "areaName": "怀安县",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130730000000,
                        "areaId": 49943,
                        "areaName": "怀来县",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130731000000,
                        "areaId": 50261,
                        "areaName": "涿鹿县",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130732000000,
                        "areaId": 50661,
                        "areaName": "赤城县",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130771000000,
                        "areaId": 51120,
                        "areaName": "张家口市高新技术产业开发区",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130772000000,
                        "areaId": 51179,
                        "areaName": "张家口市察北管理区",
                        "parent_id": 46497,
                        "level": 2
                    },
                    {
                        "areaCode": 130773000000,
                        "areaId": 51210,
                        "areaName": "张家口市塞北管理区",
                        "parent_id": 46497,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 130800000000,
                "areaId": 51227,
                "areaName": "承德市",
                "parent_id": 13400,
                "level": 1,
                "children": [
                    {
                        "areaCode": 130801000000,
                        "areaId": 51228,
                        "areaName": "市辖区",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130802000000,
                        "areaId": 51229,
                        "areaName": "双桥区",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130803000000,
                        "areaId": 51367,
                        "areaName": "双滦区",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130804000000,
                        "areaId": 51465,
                        "areaName": "鹰手营子矿区",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130821000000,
                        "areaId": 51497,
                        "areaName": "承德县",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130822000000,
                        "areaId": 51907,
                        "areaName": "兴隆县",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130824000000,
                        "areaId": 52226,
                        "areaName": "滦平县",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130825000000,
                        "areaId": 52461,
                        "areaName": "隆化县",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130826000000,
                        "areaId": 52850,
                        "areaName": "丰宁满族自治县",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130827000000,
                        "areaId": 53197,
                        "areaName": "宽城满族自治县",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130828000000,
                        "areaId": 53426,
                        "areaName": "围场满族蒙古族自治县",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130871000000,
                        "areaId": 53787,
                        "areaName": "承德高新技术产业开发区",
                        "parent_id": 51227,
                        "level": 2
                    },
                    {
                        "areaCode": 130881000000,
                        "areaId": 53831,
                        "areaName": "平泉市",
                        "parent_id": 51227,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 130900000000,
                "areaId": 54101,
                "areaName": "沧州市",
                "parent_id": 13400,
                "level": 1,
                "children": [
                    {
                        "areaCode": 130901000000,
                        "areaId": 54102,
                        "areaName": "市辖区",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130902000000,
                        "areaId": 54103,
                        "areaName": "新华区",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130903000000,
                        "areaId": 54169,
                        "areaName": "运河区",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130921000000,
                        "areaId": 54299,
                        "areaName": "沧县",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130922000000,
                        "areaId": 54838,
                        "areaName": "青县",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130923000000,
                        "areaId": 55213,
                        "areaName": "东光县",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130924000000,
                        "areaId": 55680,
                        "areaName": "海兴县",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130925000000,
                        "areaId": 55895,
                        "areaName": "盐山县",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130926000000,
                        "areaId": 56362,
                        "areaName": "肃宁县",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130927000000,
                        "areaId": 56632,
                        "areaName": "南皮县",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130928000000,
                        "areaId": 56960,
                        "areaName": "吴桥县",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130929000000,
                        "areaId": 57454,
                        "areaName": "献县",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130930000000,
                        "areaId": 57987,
                        "areaName": "孟村回族自治县",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130971000000,
                        "areaId": 58122,
                        "areaName": "河北沧州经济开发区",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130972000000,
                        "areaId": 58132,
                        "areaName": "沧州高新技术产业开发区",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130973000000,
                        "areaId": 58137,
                        "areaName": "沧州渤海新区",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130981000000,
                        "areaId": 58222,
                        "areaName": "泊头市",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130982000000,
                        "areaId": 58949,
                        "areaName": "任丘市",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130983000000,
                        "areaId": 59423,
                        "areaName": "黄骅市",
                        "parent_id": 54101,
                        "level": 2
                    },
                    {
                        "areaCode": 130984000000,
                        "areaId": 59781,
                        "areaName": "河间市",
                        "parent_id": 54101,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 131000000000,
                "areaId": 60445,
                "areaName": "廊坊市",
                "parent_id": 13400,
                "level": 1,
                "children": [
                    {
                        "areaCode": 131001000000,
                        "areaId": 60446,
                        "areaName": "市辖区",
                        "parent_id": 60445,
                        "level": 2
                    },
                    {
                        "areaCode": 131002000000,
                        "areaId": 60447,
                        "areaName": "安次区",
                        "parent_id": 60445,
                        "level": 2
                    },
                    {
                        "areaCode": 131003000000,
                        "areaId": 60765,
                        "areaName": "广阳区",
                        "parent_id": 60445,
                        "level": 2
                    },
                    {
                        "areaCode": 131022000000,
                        "areaId": 60987,
                        "areaName": "固安县",
                        "parent_id": 60445,
                        "level": 2
                    },
                    {
                        "areaCode": 131023000000,
                        "areaId": 61426,
                        "areaName": "永清县",
                        "parent_id": 60445,
                        "level": 2
                    },
                    {
                        "areaCode": 131024000000,
                        "areaId": 61837,
                        "areaName": "香河县",
                        "parent_id": 60445,
                        "level": 2
                    },
                    {
                        "areaCode": 131025000000,
                        "areaId": 62161,
                        "areaName": "大城县",
                        "parent_id": 60445,
                        "level": 2
                    },
                    {
                        "areaCode": 131026000000,
                        "areaId": 62576,
                        "areaName": "文安县",
                        "parent_id": 60445,
                        "level": 2
                    },
                    {
                        "areaCode": 131028000000,
                        "areaId": 62982,
                        "areaName": "大厂回族自治县",
                        "parent_id": 60445,
                        "level": 2
                    },
                    {
                        "areaCode": 131071000000,
                        "areaId": 63102,
                        "areaName": "廊坊经济技术开发区",
                        "parent_id": 60445,
                        "level": 2
                    },
                    {
                        "areaCode": 131081000000,
                        "areaId": 63128,
                        "areaName": "霸州市",
                        "parent_id": 60445,
                        "level": 2
                    },
                    {
                        "areaCode": 131082000000,
                        "areaId": 63535,
                        "areaName": "三河市",
                        "parent_id": 60445,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 131100000000,
                "areaId": 64028,
                "areaName": "衡水市",
                "parent_id": 13400,
                "level": 1,
                "children": [
                    {
                        "areaCode": 131101000000,
                        "areaId": 64029,
                        "areaName": "市辖区",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131102000000,
                        "areaId": 64030,
                        "areaName": "桃城区",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131103000000,
                        "areaId": 64310,
                        "areaName": "冀州区",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131121000000,
                        "areaId": 64703,
                        "areaName": "枣强县",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131122000000,
                        "areaId": 65274,
                        "areaName": "武邑县",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131123000000,
                        "areaId": 65808,
                        "areaName": "武强县",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131124000000,
                        "areaId": 66056,
                        "areaName": "饶阳县",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131125000000,
                        "areaId": 66264,
                        "areaName": "安平县",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131126000000,
                        "areaId": 66505,
                        "areaName": "故城县",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131127000000,
                        "areaId": 67057,
                        "areaName": "景县",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131128000000,
                        "areaId": 67927,
                        "areaName": "阜城县",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131171000000,
                        "areaId": 68548,
                        "areaName": "河北衡水经济开发区",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131172000000,
                        "areaId": 68672,
                        "areaName": "衡水滨湖新区",
                        "parent_id": 64028,
                        "level": 2
                    },
                    {
                        "areaCode": 131182000000,
                        "areaId": 68750,
                        "areaName": "深州市",
                        "parent_id": 64028,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 140000000000,
        "areaId": 69240,
        "areaName": "山西省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 140100000000,
                "areaId": 69241,
                "areaName": "太原市",
                "parent_id": 69240,
                "level": 1,
                "children": [
                    {
                        "areaCode": 140101000000,
                        "areaId": 69242,
                        "areaName": "市辖区",
                        "parent_id": 69241,
                        "level": 2
                    },
                    {
                        "areaCode": 140105000000,
                        "areaId": 69243,
                        "areaName": "小店区",
                        "parent_id": 69241,
                        "level": 2
                    },
                    {
                        "areaCode": 140106000000,
                        "areaId": 69400,
                        "areaName": "迎泽区",
                        "parent_id": 69241,
                        "level": 2
                    },
                    {
                        "areaCode": 140107000000,
                        "areaId": 69522,
                        "areaName": "杏花岭区",
                        "parent_id": 69241,
                        "level": 2
                    },
                    {
                        "areaCode": 140108000000,
                        "areaId": 69682,
                        "areaName": "尖草坪区",
                        "parent_id": 69241,
                        "level": 2
                    },
                    {
                        "areaCode": 140109000000,
                        "areaId": 69847,
                        "areaName": "万柏林区",
                        "parent_id": 69241,
                        "level": 2
                    },
                    {
                        "areaCode": 140110000000,
                        "areaId": 70022,
                        "areaName": "晋源区",
                        "parent_id": 69241,
                        "level": 2
                    },
                    {
                        "areaCode": 140121000000,
                        "areaId": 70151,
                        "areaName": "清徐县",
                        "parent_id": 69241,
                        "level": 2
                    },
                    {
                        "areaCode": 140122000000,
                        "areaId": 70361,
                        "areaName": "阳曲县",
                        "parent_id": 69241,
                        "level": 2
                    },
                    {
                        "areaCode": 140123000000,
                        "areaId": 70500,
                        "areaName": "娄烦县",
                        "parent_id": 69241,
                        "level": 2
                    },
                    {
                        "areaCode": 140171000000,
                        "areaId": 70658,
                        "areaName": "山西转型综合改革示范区",
                        "parent_id": 69241,
                        "level": 2
                    },
                    {
                        "areaCode": 140181000000,
                        "areaId": 70712,
                        "areaName": "古交市",
                        "parent_id": 69241,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 140200000000,
                "areaId": 70912,
                "areaName": "大同市",
                "parent_id": 69240,
                "level": 1,
                "children": [
                    {
                        "areaCode": 140201000000,
                        "areaId": 70913,
                        "areaName": "市辖区",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140202000000,
                        "areaId": 70914,
                        "areaName": "城区",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140203000000,
                        "areaId": 71066,
                        "areaName": "矿区",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140211000000,
                        "areaId": 71211,
                        "areaName": "南郊区",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140212000000,
                        "areaId": 71411,
                        "areaName": "新荣区",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140221000000,
                        "areaId": 71565,
                        "areaName": "阳高县",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140222000000,
                        "areaId": 71856,
                        "areaName": "天镇县",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140223000000,
                        "areaId": 72102,
                        "areaName": "广灵县",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140224000000,
                        "areaId": 72298,
                        "areaName": "灵丘县",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140225000000,
                        "areaId": 72579,
                        "areaName": "浑源县",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140226000000,
                        "areaId": 72922,
                        "areaName": "左云县",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140227000000,
                        "areaId": 73169,
                        "areaName": "大同县",
                        "parent_id": 70912,
                        "level": 2
                    },
                    {
                        "areaCode": 140271000000,
                        "areaId": 73370,
                        "areaName": "山西大同经济开发区",
                        "parent_id": 70912,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 140300000000,
                "areaId": 73380,
                "areaName": "阳泉市",
                "parent_id": 69240,
                "level": 1,
                "children": [
                    {
                        "areaCode": 140301000000,
                        "areaId": 73381,
                        "areaName": "市辖区",
                        "parent_id": 73380,
                        "level": 2
                    },
                    {
                        "areaCode": 140302000000,
                        "areaId": 73382,
                        "areaName": "城区",
                        "parent_id": 73380,
                        "level": 2
                    },
                    {
                        "areaCode": 140303000000,
                        "areaId": 73454,
                        "areaName": "矿区",
                        "parent_id": 73380,
                        "level": 2
                    },
                    {
                        "areaCode": 140311000000,
                        "areaId": 73517,
                        "areaName": "郊区",
                        "parent_id": 73380,
                        "level": 2
                    },
                    {
                        "areaCode": 140321000000,
                        "areaId": 73674,
                        "areaName": "平定县",
                        "parent_id": 73380,
                        "level": 2
                    },
                    {
                        "areaCode": 140322000000,
                        "areaId": 74025,
                        "areaName": "盂县",
                        "parent_id": 73380,
                        "level": 2
                    },
                    {
                        "areaCode": 140371000000,
                        "areaId": 74503,
                        "areaName": "山西阳泉经济开发区",
                        "parent_id": 73380,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 140400000000,
                "areaId": 74528,
                "areaName": "长治市",
                "parent_id": 69240,
                "level": 1,
                "children": [
                    {
                        "areaCode": 140401000000,
                        "areaId": 74529,
                        "areaName": "市辖区",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140402000000,
                        "areaId": 74530,
                        "areaName": "城区",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140411000000,
                        "areaId": 74624,
                        "areaName": "郊区",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140421000000,
                        "areaId": 74778,
                        "areaName": "长治县",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140423000000,
                        "areaId": 75053,
                        "areaName": "襄垣县",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140424000000,
                        "areaId": 75400,
                        "areaName": "屯留县",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140425000000,
                        "areaId": 75713,
                        "areaName": "平顺县",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140426000000,
                        "areaId": 75993,
                        "areaName": "黎城县",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140427000000,
                        "areaId": 76260,
                        "areaName": "壶关县",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140428000000,
                        "areaId": 76667,
                        "areaName": "长子县",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140429000000,
                        "areaId": 77088,
                        "areaName": "武乡县",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140430000000,
                        "areaId": 77486,
                        "areaName": "沁县",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140431000000,
                        "areaId": 77812,
                        "areaName": "沁源县",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140471000000,
                        "areaId": 78089,
                        "areaName": "山西长治高新技术产业园区",
                        "parent_id": 74528,
                        "level": 2
                    },
                    {
                        "areaCode": 140481000000,
                        "areaId": 78100,
                        "areaName": "潞城市",
                        "parent_id": 74528,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 140500000000,
                "areaId": 78316,
                "areaName": "晋城市",
                "parent_id": 69240,
                "level": 1,
                "children": [
                    {
                        "areaCode": 140501000000,
                        "areaId": 78317,
                        "areaName": "市辖区",
                        "parent_id": 78316,
                        "level": 2
                    },
                    {
                        "areaCode": 140502000000,
                        "areaId": 78318,
                        "areaName": "城区",
                        "parent_id": 78316,
                        "level": 2
                    },
                    {
                        "areaCode": 140521000000,
                        "areaId": 78473,
                        "areaName": "沁水县",
                        "parent_id": 78316,
                        "level": 2
                    },
                    {
                        "areaCode": 140522000000,
                        "areaId": 78739,
                        "areaName": "阳城县",
                        "parent_id": 78316,
                        "level": 2
                    },
                    {
                        "areaCode": 140524000000,
                        "areaId": 79190,
                        "areaName": "陵川县",
                        "parent_id": 78316,
                        "level": 2
                    },
                    {
                        "areaCode": 140525000000,
                        "areaId": 79582,
                        "areaName": "泽州县",
                        "parent_id": 78316,
                        "level": 2
                    },
                    {
                        "areaCode": 140581000000,
                        "areaId": 80241,
                        "areaName": "高平市",
                        "parent_id": 78316,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 140600000000,
                "areaId": 80726,
                "areaName": "朔州市",
                "parent_id": 69240,
                "level": 1,
                "children": [
                    {
                        "areaCode": 140601000000,
                        "areaId": 80727,
                        "areaName": "市辖区",
                        "parent_id": 80726,
                        "level": 2
                    },
                    {
                        "areaCode": 140602000000,
                        "areaId": 80728,
                        "areaName": "朔城区",
                        "parent_id": 80726,
                        "level": 2
                    },
                    {
                        "areaCode": 140603000000,
                        "areaId": 81096,
                        "areaName": "平鲁区",
                        "parent_id": 80726,
                        "level": 2
                    },
                    {
                        "areaCode": 140621000000,
                        "areaId": 81406,
                        "areaName": "山阴县",
                        "parent_id": 80726,
                        "level": 2
                    },
                    {
                        "areaCode": 140622000000,
                        "areaId": 81697,
                        "areaName": "应县",
                        "parent_id": 80726,
                        "level": 2
                    },
                    {
                        "areaCode": 140623000000,
                        "areaId": 82017,
                        "areaName": "右玉县",
                        "parent_id": 80726,
                        "level": 2
                    },
                    {
                        "areaCode": 140624000000,
                        "areaId": 82329,
                        "areaName": "怀仁县",
                        "parent_id": 80726,
                        "level": 2
                    },
                    {
                        "areaCode": 140671000000,
                        "areaId": 82528,
                        "areaName": "山西朔州经济开发区",
                        "parent_id": 80726,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 140700000000,
                "areaId": 82533,
                "areaName": "晋中市",
                "parent_id": 69240,
                "level": 1,
                "children": [
                    {
                        "areaCode": 140701000000,
                        "areaId": 82534,
                        "areaName": "市辖区",
                        "parent_id": 82533,
                        "level": 2
                    },
                    {
                        "areaCode": 140702000000,
                        "areaId": 82535,
                        "areaName": "榆次区",
                        "parent_id": 82533,
                        "level": 2
                    },
                    {
                        "areaCode": 140721000000,
                        "areaId": 82918,
                        "areaName": "榆社县",
                        "parent_id": 82533,
                        "level": 2
                    },
                    {
                        "areaCode": 140722000000,
                        "areaId": 83206,
                        "areaName": "左权县",
                        "parent_id": 82533,
                        "level": 2
                    },
                    {
                        "areaCode": 140723000000,
                        "areaId": 83429,
                        "areaName": "和顺县",
                        "parent_id": 82533,
                        "level": 2
                    },
                    {
                        "areaCode": 140724000000,
                        "areaId": 83740,
                        "areaName": "昔阳县",
                        "parent_id": 82533,
                        "level": 2
                    },
                    {
                        "areaCode": 140725000000,
                        "areaId": 84092,
                        "areaName": "寿阳县",
                        "parent_id": 82533,
                        "level": 2
                    },
                    {
                        "areaCode": 140726000000,
                        "areaId": 84322,
                        "areaName": "太谷县",
                        "parent_id": 82533,
                        "level": 2
                    },
                    {
                        "areaCode": 140727000000,
                        "areaId": 84551,
                        "areaName": "祁县",
                        "parent_id": 82533,
                        "level": 2
                    },
                    {
                        "areaCode": 140728000000,
                        "areaId": 84731,
                        "areaName": "平遥县",
                        "parent_id": 82533,
                        "level": 2
                    },
                    {
                        "areaCode": 140729000000,
                        "areaId": 85038,
                        "areaName": "灵石县",
                        "parent_id": 82533,
                        "level": 2
                    },
                    {
                        "areaCode": 140781000000,
                        "areaId": 85376,
                        "areaName": "介休市",
                        "parent_id": 82533,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 140800000000,
                "areaId": 85649,
                "areaName": "运城市",
                "parent_id": 69240,
                "level": 1,
                "children": [
                    {
                        "areaCode": 140801000000,
                        "areaId": 85650,
                        "areaName": "市辖区",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140802000000,
                        "areaId": 85651,
                        "areaName": "盐湖区",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140821000000,
                        "areaId": 86079,
                        "areaName": "临猗县",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140822000000,
                        "areaId": 86489,
                        "areaName": "万荣县",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140823000000,
                        "areaId": 86790,
                        "areaName": "闻喜县",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140824000000,
                        "areaId": 87165,
                        "areaName": "稷山县",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140825000000,
                        "areaId": 87377,
                        "areaName": "新绛县",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140826000000,
                        "areaId": 87620,
                        "areaName": "绛县",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140827000000,
                        "areaId": 87846,
                        "areaName": "垣曲县",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140828000000,
                        "areaId": 88056,
                        "areaName": "夏县",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140829000000,
                        "areaId": 88329,
                        "areaName": "平陆县",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140830000000,
                        "areaId": 88572,
                        "areaName": "芮城县",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140881000000,
                        "areaId": 88766,
                        "areaName": "永济市",
                        "parent_id": 85649,
                        "level": 2
                    },
                    {
                        "areaCode": 140882000000,
                        "areaId": 89070,
                        "areaName": "河津市",
                        "parent_id": 85649,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 140900000000,
                "areaId": 89251,
                "areaName": "忻州市",
                "parent_id": 69240,
                "level": 1,
                "children": [
                    {
                        "areaCode": 140901000000,
                        "areaId": 89252,
                        "areaName": "市辖区",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140902000000,
                        "areaId": 89253,
                        "areaName": "忻府区",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140921000000,
                        "areaId": 89679,
                        "areaName": "定襄县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140922000000,
                        "areaId": 89845,
                        "areaName": "五台县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140923000000,
                        "areaId": 90372,
                        "areaName": "代县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140924000000,
                        "areaId": 90762,
                        "areaName": "繁峙县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140925000000,
                        "areaId": 91188,
                        "areaName": "宁武县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140926000000,
                        "areaId": 91684,
                        "areaName": "静乐县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140927000000,
                        "areaId": 92080,
                        "areaName": "神池县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140928000000,
                        "areaId": 92333,
                        "areaName": "五寨县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140929000000,
                        "areaId": 92596,
                        "areaName": "岢岚县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140930000000,
                        "areaId": 92811,
                        "areaName": "河曲县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140931000000,
                        "areaId": 93153,
                        "areaName": "保德县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140932000000,
                        "areaId": 93517,
                        "areaName": "偏关县",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140971000000,
                        "areaId": 93777,
                        "areaName": "五台山风景名胜区",
                        "parent_id": 89251,
                        "level": 2
                    },
                    {
                        "areaCode": 140981000000,
                        "areaId": 93844,
                        "areaName": "原平市",
                        "parent_id": 89251,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 141000000000,
                "areaId": 94410,
                "areaName": "临汾市",
                "parent_id": 69240,
                "level": 1,
                "children": [
                    {
                        "areaCode": 141001000000,
                        "areaId": 94411,
                        "areaName": "市辖区",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141002000000,
                        "areaId": 94412,
                        "areaName": "尧都区",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141021000000,
                        "areaId": 94895,
                        "areaName": "曲沃县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141022000000,
                        "areaId": 95062,
                        "areaName": "翼城县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141023000000,
                        "areaId": 95291,
                        "areaName": "襄汾县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141024000000,
                        "areaId": 95659,
                        "areaName": "洪洞县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141025000000,
                        "areaId": 96144,
                        "areaName": "古县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141026000000,
                        "areaId": 96267,
                        "areaName": "安泽县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141027000000,
                        "areaId": 96381,
                        "areaName": "浮山县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141028000000,
                        "areaId": 96578,
                        "areaName": "吉县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141029000000,
                        "areaId": 96666,
                        "areaName": "乡宁县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141030000000,
                        "areaId": 96866,
                        "areaName": "大宁县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141031000000,
                        "areaId": 96957,
                        "areaName": "隰县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141032000000,
                        "areaId": 97066,
                        "areaName": "永和县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141033000000,
                        "areaId": 97157,
                        "areaName": "蒲县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141034000000,
                        "areaId": 97264,
                        "areaName": "汾西县",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141081000000,
                        "areaId": 97400,
                        "areaName": "侯马市",
                        "parent_id": 94410,
                        "level": 2
                    },
                    {
                        "areaCode": 141082000000,
                        "areaId": 97513,
                        "areaName": "霍州市",
                        "parent_id": 94410,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 141100000000,
                "areaId": 97749,
                "areaName": "吕梁市",
                "parent_id": 69240,
                "level": 1,
                "children": [
                    {
                        "areaCode": 141101000000,
                        "areaId": 97750,
                        "areaName": "市辖区",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141102000000,
                        "areaId": 97751,
                        "areaName": "离石区",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141121000000,
                        "areaId": 98017,
                        "areaName": "文水县",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141122000000,
                        "areaId": 98233,
                        "areaName": "交城县",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141123000000,
                        "areaId": 98396,
                        "areaName": "兴县",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141124000000,
                        "areaId": 98798,
                        "areaName": "临县",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141125000000,
                        "areaId": 99470,
                        "areaName": "柳林县",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141126000000,
                        "areaId": 99747,
                        "areaName": "石楼县",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141127000000,
                        "areaId": 99892,
                        "areaName": "岚县",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141128000000,
                        "areaId": 100074,
                        "areaName": "方山县",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141129000000,
                        "areaId": 100253,
                        "areaName": "中阳县",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141130000000,
                        "areaId": 100361,
                        "areaName": "交口县",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141181000000,
                        "areaId": 100465,
                        "areaName": "孝义市",
                        "parent_id": 97749,
                        "level": 2
                    },
                    {
                        "areaCode": 141182000000,
                        "areaId": 100910,
                        "areaName": "汾阳市",
                        "parent_id": 97749,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 150000000000,
        "areaId": 101228,
        "areaName": "内蒙古自治区",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 150100000000,
                "areaId": 101229,
                "areaName": "呼和浩特市",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 150101000000,
                        "areaId": 101230,
                        "areaName": "市辖区",
                        "parent_id": 101229,
                        "level": 2
                    },
                    {
                        "areaCode": 150102000000,
                        "areaId": 101231,
                        "areaName": "新城区",
                        "parent_id": 101229,
                        "level": 2
                    },
                    {
                        "areaCode": 150103000000,
                        "areaId": 101320,
                        "areaName": "回民区",
                        "parent_id": 101229,
                        "level": 2
                    },
                    {
                        "areaCode": 150104000000,
                        "areaId": 101392,
                        "areaName": "玉泉区",
                        "parent_id": 101229,
                        "level": 2
                    },
                    {
                        "areaCode": 150105000000,
                        "areaId": 101512,
                        "areaName": "赛罕区",
                        "parent_id": 101229,
                        "level": 2
                    },
                    {
                        "areaCode": 150121000000,
                        "areaId": 101724,
                        "areaName": "土默特左旗",
                        "parent_id": 101229,
                        "level": 2
                    },
                    {
                        "areaCode": 150122000000,
                        "areaId": 102071,
                        "areaName": "托克托县",
                        "parent_id": 101229,
                        "level": 2
                    },
                    {
                        "areaCode": 150123000000,
                        "areaId": 102215,
                        "areaName": "和林格尔县",
                        "parent_id": 101229,
                        "level": 2
                    },
                    {
                        "areaCode": 150124000000,
                        "areaId": 102385,
                        "areaName": "清水河县",
                        "parent_id": 101229,
                        "level": 2
                    },
                    {
                        "areaCode": 150125000000,
                        "areaId": 102503,
                        "areaName": "武川县",
                        "parent_id": 101229,
                        "level": 2
                    },
                    {
                        "areaCode": 150171000000,
                        "areaId": 102620,
                        "areaName": "呼和浩特金海工业园区",
                        "parent_id": 101229,
                        "level": 2
                    },
                    {
                        "areaCode": 150172000000,
                        "areaId": 102623,
                        "areaName": "呼和浩特经济技术开发区",
                        "parent_id": 101229,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 150200000000,
                "areaId": 102636,
                "areaName": "包头市",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 150201000000,
                        "areaId": 102637,
                        "areaName": "市辖区",
                        "parent_id": 102636,
                        "level": 2
                    },
                    {
                        "areaCode": 150202000000,
                        "areaId": 102638,
                        "areaName": "东河区",
                        "parent_id": 102636,
                        "level": 2
                    },
                    {
                        "areaCode": 150203000000,
                        "areaId": 102765,
                        "areaName": "昆都仑区",
                        "parent_id": 102636,
                        "level": 2
                    },
                    {
                        "areaCode": 150204000000,
                        "areaId": 102886,
                        "areaName": "青山区",
                        "parent_id": 102636,
                        "level": 2
                    },
                    {
                        "areaCode": 150205000000,
                        "areaId": 102973,
                        "areaName": "石拐区",
                        "parent_id": 102636,
                        "level": 2
                    },
                    {
                        "areaCode": 150206000000,
                        "areaId": 103006,
                        "areaName": "白云鄂博矿区",
                        "parent_id": 102636,
                        "level": 2
                    },
                    {
                        "areaCode": 150207000000,
                        "areaId": 103013,
                        "areaName": "九原区",
                        "parent_id": 102636,
                        "level": 2
                    },
                    {
                        "areaCode": 150221000000,
                        "areaId": 103100,
                        "areaName": "土默特右旗",
                        "parent_id": 102636,
                        "level": 2
                    },
                    {
                        "areaCode": 150222000000,
                        "areaId": 103324,
                        "areaName": "固阳县",
                        "parent_id": 102636,
                        "level": 2
                    },
                    {
                        "areaCode": 150223000000,
                        "areaId": 103414,
                        "areaName": "达尔罕茂明安联合旗",
                        "parent_id": 102636,
                        "level": 2
                    },
                    {
                        "areaCode": 150271000000,
                        "areaId": 103513,
                        "areaName": "包头稀土高新技术产业开发区",
                        "parent_id": 102636,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 150300000000,
                "areaId": 103565,
                "areaName": "乌海市",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 150301000000,
                        "areaId": 103566,
                        "areaName": "市辖区",
                        "parent_id": 103565,
                        "level": 2
                    },
                    {
                        "areaCode": 150302000000,
                        "areaId": 103567,
                        "areaName": "海勃湾区",
                        "parent_id": 103565,
                        "level": 2
                    },
                    {
                        "areaCode": 150303000000,
                        "areaId": 103617,
                        "areaName": "海南区",
                        "parent_id": 103565,
                        "level": 2
                    },
                    {
                        "areaCode": 150304000000,
                        "areaId": 103643,
                        "areaName": "乌达区",
                        "parent_id": 103565,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 150400000000,
                "areaId": 103677,
                "areaName": "赤峰市",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 150401000000,
                        "areaId": 103678,
                        "areaName": "市辖区",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150402000000,
                        "areaId": 103679,
                        "areaName": "红山区",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150403000000,
                        "areaId": 103779,
                        "areaName": "元宝山区",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150404000000,
                        "areaId": 103905,
                        "areaName": "松山区",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150421000000,
                        "areaId": 104230,
                        "areaName": "阿鲁科尔沁旗",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150422000000,
                        "areaId": 104510,
                        "areaName": "巴林左旗",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150423000000,
                        "areaId": 104716,
                        "areaName": "巴林右旗",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150424000000,
                        "areaId": 104900,
                        "areaName": "林西县",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150425000000,
                        "areaId": 105028,
                        "areaName": "克什克腾旗",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150426000000,
                        "areaId": 105191,
                        "areaName": "翁牛特旗",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150428000000,
                        "areaId": 105453,
                        "areaName": "喀喇沁旗",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150429000000,
                        "areaId": 105641,
                        "areaName": "宁城县",
                        "parent_id": 103677,
                        "level": 2
                    },
                    {
                        "areaCode": 150430000000,
                        "areaId": 105997,
                        "areaName": "敖汉旗",
                        "parent_id": 103677,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 150500000000,
                "areaId": 106265,
                "areaName": "通辽市",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 150501000000,
                        "areaId": 106266,
                        "areaName": "市辖区",
                        "parent_id": 106265,
                        "level": 2
                    },
                    {
                        "areaCode": 150502000000,
                        "areaId": 106267,
                        "areaName": "科尔沁区",
                        "parent_id": 106265,
                        "level": 2
                    },
                    {
                        "areaCode": 150521000000,
                        "areaId": 106762,
                        "areaName": "科尔沁左翼中旗",
                        "parent_id": 106265,
                        "level": 2
                    },
                    {
                        "areaCode": 150522000000,
                        "areaId": 107339,
                        "areaName": "科尔沁左翼后旗",
                        "parent_id": 106265,
                        "level": 2
                    },
                    {
                        "areaCode": 150523000000,
                        "areaId": 107674,
                        "areaName": "开鲁县",
                        "parent_id": 106265,
                        "level": 2
                    },
                    {
                        "areaCode": 150524000000,
                        "areaId": 107961,
                        "areaName": "库伦旗",
                        "parent_id": 106265,
                        "level": 2
                    },
                    {
                        "areaCode": 150525000000,
                        "areaId": 108167,
                        "areaName": "奈曼旗",
                        "parent_id": 106265,
                        "level": 2
                    },
                    {
                        "areaCode": 150526000000,
                        "areaId": 108557,
                        "areaName": "扎鲁特旗",
                        "parent_id": 106265,
                        "level": 2
                    },
                    {
                        "areaCode": 150571000000,
                        "areaId": 108839,
                        "areaName": "通辽经济技术开发区",
                        "parent_id": 106265,
                        "level": 2
                    },
                    {
                        "areaCode": 150581000000,
                        "areaId": 108924,
                        "areaName": "霍林郭勒市",
                        "parent_id": 106265,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 150600000000,
                "areaId": 108961,
                "areaName": "鄂尔多斯市",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 150601000000,
                        "areaId": 108962,
                        "areaName": "市辖区",
                        "parent_id": 108961,
                        "level": 2
                    },
                    {
                        "areaCode": 150602000000,
                        "areaId": 108963,
                        "areaName": "东胜区",
                        "parent_id": 108961,
                        "level": 2
                    },
                    {
                        "areaCode": 150603000000,
                        "areaId": 109089,
                        "areaName": "康巴什区",
                        "parent_id": 108961,
                        "level": 2
                    },
                    {
                        "areaCode": 150621000000,
                        "areaId": 109109,
                        "areaName": "达拉特旗",
                        "parent_id": 108961,
                        "level": 2
                    },
                    {
                        "areaCode": 150622000000,
                        "areaId": 109285,
                        "areaName": "准格尔旗",
                        "parent_id": 108961,
                        "level": 2
                    },
                    {
                        "areaCode": 150623000000,
                        "areaId": 109493,
                        "areaName": "鄂托克前旗",
                        "parent_id": 108961,
                        "level": 2
                    },
                    {
                        "areaCode": 150624000000,
                        "areaId": 109584,
                        "areaName": "鄂托克旗",
                        "parent_id": 108961,
                        "level": 2
                    },
                    {
                        "areaCode": 150625000000,
                        "areaId": 109692,
                        "areaName": "杭锦旗",
                        "parent_id": 108961,
                        "level": 2
                    },
                    {
                        "areaCode": 150626000000,
                        "areaId": 109792,
                        "areaName": "乌审旗",
                        "parent_id": 108961,
                        "level": 2
                    },
                    {
                        "areaCode": 150627000000,
                        "areaId": 109874,
                        "areaName": "伊金霍洛旗",
                        "parent_id": 108961,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 150700000000,
                "areaId": 110058,
                "areaName": "呼伦贝尔市",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 150701000000,
                        "areaId": 110059,
                        "areaName": "市辖区",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150702000000,
                        "areaId": 110060,
                        "areaName": "海拉尔区",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150703000000,
                        "areaId": 110124,
                        "areaName": "扎赉诺尔区",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150721000000,
                        "areaId": 110152,
                        "areaName": "阿荣旗",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150722000000,
                        "areaId": 110376,
                        "areaName": "莫力达瓦达斡尔族自治旗",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150723000000,
                        "areaId": 110680,
                        "areaName": "鄂伦春自治旗",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150724000000,
                        "areaId": 110925,
                        "areaName": "鄂温克族自治旗",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150725000000,
                        "areaId": 111008,
                        "areaName": "陈巴尔虎旗",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150726000000,
                        "areaId": 111096,
                        "areaName": "新巴尔虎左旗",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150727000000,
                        "areaId": 111174,
                        "areaName": "新巴尔虎右旗",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150781000000,
                        "areaId": 111248,
                        "areaName": "满洲里市",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150782000000,
                        "areaId": 111289,
                        "areaName": "牙克石市",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150783000000,
                        "areaId": 111386,
                        "areaName": "扎兰屯市",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150784000000,
                        "areaId": 111636,
                        "areaName": "额尔古纳市",
                        "parent_id": 110058,
                        "level": 2
                    },
                    {
                        "areaCode": 150785000000,
                        "areaId": 111712,
                        "areaName": "根河市",
                        "parent_id": 110058,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 150800000000,
                "areaId": 111747,
                "areaName": "巴彦淖尔市",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 150801000000,
                        "areaId": 111748,
                        "areaName": "市辖区",
                        "parent_id": 111747,
                        "level": 2
                    },
                    {
                        "areaCode": 150802000000,
                        "areaId": 111749,
                        "areaName": "临河区",
                        "parent_id": 111747,
                        "level": 2
                    },
                    {
                        "areaCode": 150821000000,
                        "areaId": 111995,
                        "areaName": "五原县",
                        "parent_id": 111747,
                        "level": 2
                    },
                    {
                        "areaCode": 150822000000,
                        "areaId": 112155,
                        "areaName": "磴口县",
                        "parent_id": 111747,
                        "level": 2
                    },
                    {
                        "areaCode": 150823000000,
                        "areaId": 112272,
                        "areaName": "乌拉特前旗",
                        "parent_id": 111747,
                        "level": 2
                    },
                    {
                        "areaCode": 150824000000,
                        "areaId": 112457,
                        "areaName": "乌拉特中旗",
                        "parent_id": 111747,
                        "level": 2
                    },
                    {
                        "areaCode": 150825000000,
                        "areaId": 112576,
                        "areaName": "乌拉特后旗",
                        "parent_id": 111747,
                        "level": 2
                    },
                    {
                        "areaCode": 150826000000,
                        "areaId": 112643,
                        "areaName": "杭锦后旗",
                        "parent_id": 111747,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 150900000000,
                "areaId": 112789,
                "areaName": "乌兰察布市",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 150901000000,
                        "areaId": 112790,
                        "areaName": "市辖区",
                        "parent_id": 112789,
                        "level": 2
                    },
                    {
                        "areaCode": 150902000000,
                        "areaId": 112791,
                        "areaName": "集宁区",
                        "parent_id": 112789,
                        "level": 2
                    },
                    {
                        "areaCode": 150921000000,
                        "areaId": 112905,
                        "areaName": "卓资县",
                        "parent_id": 112789,
                        "level": 2
                    },
                    {
                        "areaCode": 150922000000,
                        "areaId": 113034,
                        "areaName": "化德县",
                        "parent_id": 112789,
                        "level": 2
                    },
                    {
                        "areaCode": 150923000000,
                        "areaId": 113144,
                        "areaName": "商都县",
                        "parent_id": 112789,
                        "level": 2
                    },
                    {
                        "areaCode": 150924000000,
                        "areaId": 113388,
                        "areaName": "兴和县",
                        "parent_id": 112789,
                        "level": 2
                    },
                    {
                        "areaCode": 150925000000,
                        "areaId": 113573,
                        "areaName": "凉城县",
                        "parent_id": 112789,
                        "level": 2
                    },
                    {
                        "areaCode": 150926000000,
                        "areaId": 113727,
                        "areaName": "察哈尔右翼前旗",
                        "parent_id": 112789,
                        "level": 2
                    },
                    {
                        "areaCode": 150927000000,
                        "areaId": 113873,
                        "areaName": "察哈尔右翼中旗",
                        "parent_id": 112789,
                        "level": 2
                    },
                    {
                        "areaCode": 150928000000,
                        "areaId": 114068,
                        "areaName": "察哈尔右翼后旗",
                        "parent_id": 112789,
                        "level": 2
                    },
                    {
                        "areaCode": 150929000000,
                        "areaId": 114181,
                        "areaName": "四子王旗",
                        "parent_id": 112789,
                        "level": 2
                    },
                    {
                        "areaCode": 150981000000,
                        "areaId": 114332,
                        "areaName": "丰镇市",
                        "parent_id": 112789,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 152200000000,
                "areaId": 114457,
                "areaName": "兴安盟",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 152201000000,
                        "areaId": 114458,
                        "areaName": "乌兰浩特市",
                        "parent_id": 114457,
                        "level": 2
                    },
                    {
                        "areaCode": 152202000000,
                        "areaId": 114599,
                        "areaName": "阿尔山市",
                        "parent_id": 114457,
                        "level": 2
                    },
                    {
                        "areaCode": 152221000000,
                        "areaId": 114639,
                        "areaName": "科尔沁右翼前旗",
                        "parent_id": 114457,
                        "level": 2
                    },
                    {
                        "areaCode": 152222000000,
                        "areaId": 114958,
                        "areaName": "科尔沁右翼中旗",
                        "parent_id": 114457,
                        "level": 2
                    },
                    {
                        "areaCode": 152223000000,
                        "areaId": 115201,
                        "areaName": "扎赉特旗",
                        "parent_id": 114457,
                        "level": 2
                    },
                    {
                        "areaCode": 152224000000,
                        "areaId": 115461,
                        "areaName": "突泉县",
                        "parent_id": 114457,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 152500000000,
                "areaId": 115684,
                "areaName": "锡林郭勒盟",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 152501000000,
                        "areaId": 115685,
                        "areaName": "二连浩特市",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152502000000,
                        "areaId": 115703,
                        "areaName": "锡林浩特市",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152522000000,
                        "areaId": 115823,
                        "areaName": "阿巴嘎旗",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152523000000,
                        "areaId": 115910,
                        "areaName": "苏尼特左旗",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152524000000,
                        "areaId": 115976,
                        "areaName": "苏尼特右旗",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152525000000,
                        "areaId": 116063,
                        "areaName": "东乌珠穆沁旗",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152526000000,
                        "areaId": 116150,
                        "areaName": "西乌珠穆沁旗",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152527000000,
                        "areaId": 116269,
                        "areaName": "太仆寺旗",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152528000000,
                        "areaId": 116468,
                        "areaName": "镶黄旗",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152529000000,
                        "areaId": 116539,
                        "areaName": "正镶白旗",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152530000000,
                        "areaId": 116633,
                        "areaName": "正蓝旗",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152531000000,
                        "areaId": 116770,
                        "areaName": "多伦县",
                        "parent_id": 115684,
                        "level": 2
                    },
                    {
                        "areaCode": 152571000000,
                        "areaId": 116851,
                        "areaName": "乌拉盖管委会",
                        "parent_id": 115684,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 152900000000,
                "areaId": 116879,
                "areaName": "阿拉善盟",
                "parent_id": 101228,
                "level": 1,
                "children": [
                    {
                        "areaCode": 152921000000,
                        "areaId": 116880,
                        "areaName": "阿拉善左旗",
                        "parent_id": 116879,
                        "level": 2
                    },
                    {
                        "areaCode": 152922000000,
                        "areaId": 117072,
                        "areaName": "阿拉善右旗",
                        "parent_id": 116879,
                        "level": 2
                    },
                    {
                        "areaCode": 152923000000,
                        "areaId": 117129,
                        "areaName": "额济纳旗",
                        "parent_id": 116879,
                        "level": 2
                    },
                    {
                        "areaCode": 152971000000,
                        "areaId": 117164,
                        "areaName": "内蒙古阿拉善经济开发区",
                        "parent_id": 116879,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 210000000000,
        "areaId": 117170,
        "areaName": "辽宁省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 210100000000,
                "areaId": 117171,
                "areaName": "沈阳市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 210101000000,
                        "areaId": 117172,
                        "areaName": "市辖区",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210102000000,
                        "areaId": 117173,
                        "areaName": "和平区",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210103000000,
                        "areaId": 117293,
                        "areaName": "沈河区",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210104000000,
                        "areaId": 117420,
                        "areaName": "大东区",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210105000000,
                        "areaId": 117559,
                        "areaName": "皇姑区",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210106000000,
                        "areaId": 117699,
                        "areaName": "铁西区",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210111000000,
                        "areaId": 117911,
                        "areaName": "苏家屯区",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210112000000,
                        "areaId": 118106,
                        "areaName": "浑南区",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210113000000,
                        "areaId": 118346,
                        "areaName": "沈北新区",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210114000000,
                        "areaId": 118558,
                        "areaName": "于洪区",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210115000000,
                        "areaId": 118767,
                        "areaName": "辽中区",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210123000000,
                        "areaId": 119022,
                        "areaName": "康平县",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210124000000,
                        "areaId": 119216,
                        "areaName": "法库县",
                        "parent_id": 117171,
                        "level": 2
                    },
                    {
                        "areaCode": 210181000000,
                        "areaId": 119478,
                        "areaName": "新民市",
                        "parent_id": 117171,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 210200000000,
                "areaId": 119880,
                "areaName": "大连市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 210201000000,
                        "areaId": 119881,
                        "areaName": "市辖区",
                        "parent_id": 119880,
                        "level": 2
                    },
                    {
                        "areaCode": 210202000000,
                        "areaId": 119882,
                        "areaName": "中山区",
                        "parent_id": 119880,
                        "level": 2
                    },
                    {
                        "areaCode": 210203000000,
                        "areaId": 119945,
                        "areaName": "西岗区",
                        "parent_id": 119880,
                        "level": 2
                    },
                    {
                        "areaCode": 210204000000,
                        "areaId": 119998,
                        "areaName": "沙河口区",
                        "parent_id": 119880,
                        "level": 2
                    },
                    {
                        "areaCode": 210211000000,
                        "areaId": 120098,
                        "areaName": "甘井子区",
                        "parent_id": 119880,
                        "level": 2
                    },
                    {
                        "areaCode": 210212000000,
                        "areaId": 120359,
                        "areaName": "旅顺口区",
                        "parent_id": 119880,
                        "level": 2
                    },
                    {
                        "areaCode": 210213000000,
                        "areaId": 120479,
                        "areaName": "金州区",
                        "parent_id": 119880,
                        "level": 2
                    },
                    {
                        "areaCode": 210214000000,
                        "areaId": 120843,
                        "areaName": "普兰店区",
                        "parent_id": 119880,
                        "level": 2
                    },
                    {
                        "areaCode": 210224000000,
                        "areaId": 121049,
                        "areaName": "长海县",
                        "parent_id": 119880,
                        "level": 2
                    },
                    {
                        "areaCode": 210281000000,
                        "areaId": 121085,
                        "areaName": "瓦房店市",
                        "parent_id": 119880,
                        "level": 2
                    },
                    {
                        "areaCode": 210283000000,
                        "areaId": 121441,
                        "areaName": "庄河市",
                        "parent_id": 119880,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 210300000000,
                "areaId": 121749,
                "areaName": "鞍山市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 210301000000,
                        "areaId": 121750,
                        "areaName": "市辖区",
                        "parent_id": 121749,
                        "level": 2
                    },
                    {
                        "areaCode": 210302000000,
                        "areaId": 121751,
                        "areaName": "铁东区",
                        "parent_id": 121749,
                        "level": 2
                    },
                    {
                        "areaCode": 210303000000,
                        "areaId": 121876,
                        "areaName": "铁西区",
                        "parent_id": 121749,
                        "level": 2
                    },
                    {
                        "areaCode": 210304000000,
                        "areaId": 121982,
                        "areaName": "立山区",
                        "parent_id": 121749,
                        "level": 2
                    },
                    {
                        "areaCode": 210311000000,
                        "areaId": 122113,
                        "areaName": "千山区",
                        "parent_id": 121749,
                        "level": 2
                    },
                    {
                        "areaCode": 210321000000,
                        "areaId": 122183,
                        "areaName": "台安县",
                        "parent_id": 121749,
                        "level": 2
                    },
                    {
                        "areaCode": 210323000000,
                        "areaId": 122363,
                        "areaName": "岫岩满族自治县",
                        "parent_id": 121749,
                        "level": 2
                    },
                    {
                        "areaCode": 210381000000,
                        "areaId": 122595,
                        "areaName": "海城市",
                        "parent_id": 121749,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 210400000000,
                "areaId": 123042,
                "areaName": "抚顺市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 210401000000,
                        "areaId": 123043,
                        "areaName": "市辖区",
                        "parent_id": 123042,
                        "level": 2
                    },
                    {
                        "areaCode": 210402000000,
                        "areaId": 123044,
                        "areaName": "新抚区",
                        "parent_id": 123042,
                        "level": 2
                    },
                    {
                        "areaCode": 210403000000,
                        "areaId": 123129,
                        "areaName": "东洲区",
                        "parent_id": 123042,
                        "level": 2
                    },
                    {
                        "areaCode": 210404000000,
                        "areaId": 123252,
                        "areaName": "望花区",
                        "parent_id": 123042,
                        "level": 2
                    },
                    {
                        "areaCode": 210411000000,
                        "areaId": 123400,
                        "areaName": "顺城区",
                        "parent_id": 123042,
                        "level": 2
                    },
                    {
                        "areaCode": 210421000000,
                        "areaId": 123523,
                        "areaName": "抚顺县",
                        "parent_id": 123042,
                        "level": 2
                    },
                    {
                        "areaCode": 210422000000,
                        "areaId": 123629,
                        "areaName": "新宾满族自治县",
                        "parent_id": 123042,
                        "level": 2
                    },
                    {
                        "areaCode": 210423000000,
                        "areaId": 123839,
                        "areaName": "清原满族自治县",
                        "parent_id": 123042,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 210500000000,
                "areaId": 124062,
                "areaName": "本溪市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 210501000000,
                        "areaId": 124063,
                        "areaName": "市辖区",
                        "parent_id": 124062,
                        "level": 2
                    },
                    {
                        "areaCode": 210502000000,
                        "areaId": 124064,
                        "areaName": "平山区",
                        "parent_id": 124062,
                        "level": 2
                    },
                    {
                        "areaCode": 210503000000,
                        "areaId": 124136,
                        "areaName": "溪湖区",
                        "parent_id": 124062,
                        "level": 2
                    },
                    {
                        "areaCode": 210504000000,
                        "areaId": 124231,
                        "areaName": "明山区",
                        "parent_id": 124062,
                        "level": 2
                    },
                    {
                        "areaCode": 210505000000,
                        "areaId": 124323,
                        "areaName": "南芬区",
                        "parent_id": 124062,
                        "level": 2
                    },
                    {
                        "areaCode": 210521000000,
                        "areaId": 124367,
                        "areaName": "本溪满族自治县",
                        "parent_id": 124062,
                        "level": 2
                    },
                    {
                        "areaCode": 210522000000,
                        "areaId": 124514,
                        "areaName": "桓仁满族自治县",
                        "parent_id": 124062,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 210600000000,
                "areaId": 124653,
                "areaName": "丹东市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 210601000000,
                        "areaId": 124654,
                        "areaName": "市辖区",
                        "parent_id": 124653,
                        "level": 2
                    },
                    {
                        "areaCode": 210602000000,
                        "areaId": 124655,
                        "areaName": "元宝区",
                        "parent_id": 124653,
                        "level": 2
                    },
                    {
                        "areaCode": 210603000000,
                        "areaId": 124708,
                        "areaName": "振兴区",
                        "parent_id": 124653,
                        "level": 2
                    },
                    {
                        "areaCode": 210604000000,
                        "areaId": 124809,
                        "areaName": "振安区",
                        "parent_id": 124653,
                        "level": 2
                    },
                    {
                        "areaCode": 210624000000,
                        "areaId": 124881,
                        "areaName": "宽甸满族自治县",
                        "parent_id": 124653,
                        "level": 2
                    },
                    {
                        "areaCode": 210681000000,
                        "areaId": 125100,
                        "areaName": "东港市",
                        "parent_id": 124653,
                        "level": 2
                    },
                    {
                        "areaCode": 210682000000,
                        "areaId": 125361,
                        "areaName": "凤城市",
                        "parent_id": 124653,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 210700000000,
                "areaId": 125621,
                "areaName": "锦州市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 210701000000,
                        "areaId": 125622,
                        "areaName": "市辖区",
                        "parent_id": 125621,
                        "level": 2
                    },
                    {
                        "areaCode": 210702000000,
                        "areaId": 125623,
                        "areaName": "古塔区",
                        "parent_id": 125621,
                        "level": 2
                    },
                    {
                        "areaCode": 210703000000,
                        "areaId": 125701,
                        "areaName": "凌河区",
                        "parent_id": 125621,
                        "level": 2
                    },
                    {
                        "areaCode": 210711000000,
                        "areaId": 125792,
                        "areaName": "太和区",
                        "parent_id": 125621,
                        "level": 2
                    },
                    {
                        "areaCode": 210726000000,
                        "areaId": 125969,
                        "areaName": "黑山县",
                        "parent_id": 125621,
                        "level": 2
                    },
                    {
                        "areaCode": 210727000000,
                        "areaId": 126323,
                        "areaName": "义县",
                        "parent_id": 125621,
                        "level": 2
                    },
                    {
                        "areaCode": 210781000000,
                        "areaId": 126595,
                        "areaName": "凌海市",
                        "parent_id": 125621,
                        "level": 2
                    },
                    {
                        "areaCode": 210782000000,
                        "areaId": 126905,
                        "areaName": "北镇市",
                        "parent_id": 125621,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 210800000000,
                "areaId": 127173,
                "areaName": "营口市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 210801000000,
                        "areaId": 127174,
                        "areaName": "市辖区",
                        "parent_id": 127173,
                        "level": 2
                    },
                    {
                        "areaCode": 210802000000,
                        "areaId": 127175,
                        "areaName": "站前区",
                        "parent_id": 127173,
                        "level": 2
                    },
                    {
                        "areaCode": 210803000000,
                        "areaId": 127229,
                        "areaName": "西市区",
                        "parent_id": 127173,
                        "level": 2
                    },
                    {
                        "areaCode": 210804000000,
                        "areaId": 127278,
                        "areaName": "鲅鱼圈区",
                        "parent_id": 127173,
                        "level": 2
                    },
                    {
                        "areaCode": 210811000000,
                        "areaId": 127379,
                        "areaName": "老边区",
                        "parent_id": 127173,
                        "level": 2
                    },
                    {
                        "areaCode": 210881000000,
                        "areaId": 127445,
                        "areaName": "盖州市",
                        "parent_id": 127173,
                        "level": 2
                    },
                    {
                        "areaCode": 210882000000,
                        "areaId": 127774,
                        "areaName": "大石桥市",
                        "parent_id": 127173,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 210900000000,
                "areaId": 128081,
                "areaName": "阜新市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 210901000000,
                        "areaId": 128082,
                        "areaName": "市辖区",
                        "parent_id": 128081,
                        "level": 2
                    },
                    {
                        "areaCode": 210902000000,
                        "areaId": 128083,
                        "areaName": "海州区",
                        "parent_id": 128081,
                        "level": 2
                    },
                    {
                        "areaCode": 210903000000,
                        "areaId": 128161,
                        "areaName": "新邱区",
                        "parent_id": 128081,
                        "level": 2
                    },
                    {
                        "areaCode": 210904000000,
                        "areaId": 128189,
                        "areaName": "太平区",
                        "parent_id": 128081,
                        "level": 2
                    },
                    {
                        "areaCode": 210905000000,
                        "areaId": 128239,
                        "areaName": "清河门区",
                        "parent_id": 128081,
                        "level": 2
                    },
                    {
                        "areaCode": 210911000000,
                        "areaId": 128274,
                        "areaName": "细河区",
                        "parent_id": 128081,
                        "level": 2
                    },
                    {
                        "areaCode": 210921000000,
                        "areaId": 128342,
                        "areaName": "阜新蒙古族自治县",
                        "parent_id": 128081,
                        "level": 2
                    },
                    {
                        "areaCode": 210922000000,
                        "areaId": 128770,
                        "areaName": "彰武县",
                        "parent_id": 128081,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 211000000000,
                "areaId": 128995,
                "areaName": "辽阳市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 211001000000,
                        "areaId": 128996,
                        "areaName": "市辖区",
                        "parent_id": 128995,
                        "level": 2
                    },
                    {
                        "areaCode": 211002000000,
                        "areaId": 128997,
                        "areaName": "白塔区",
                        "parent_id": 128995,
                        "level": 2
                    },
                    {
                        "areaCode": 211003000000,
                        "areaId": 129081,
                        "areaName": "文圣区",
                        "parent_id": 128995,
                        "level": 2
                    },
                    {
                        "areaCode": 211004000000,
                        "areaId": 129147,
                        "areaName": "宏伟区",
                        "parent_id": 128995,
                        "level": 2
                    },
                    {
                        "areaCode": 211005000000,
                        "areaId": 129196,
                        "areaName": "弓长岭区",
                        "parent_id": 128995,
                        "level": 2
                    },
                    {
                        "areaCode": 211011000000,
                        "areaId": 129235,
                        "areaName": "太子河区",
                        "parent_id": 128995,
                        "level": 2
                    },
                    {
                        "areaCode": 211021000000,
                        "areaId": 129316,
                        "areaName": "辽阳县",
                        "parent_id": 128995,
                        "level": 2
                    },
                    {
                        "areaCode": 211081000000,
                        "areaId": 129542,
                        "areaName": "灯塔市",
                        "parent_id": 128995,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 211100000000,
                "areaId": 129768,
                "areaName": "盘锦市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 211101000000,
                        "areaId": 129769,
                        "areaName": "市辖区",
                        "parent_id": 129768,
                        "level": 2
                    },
                    {
                        "areaCode": 211102000000,
                        "areaId": 129770,
                        "areaName": "双台子区",
                        "parent_id": 129768,
                        "level": 2
                    },
                    {
                        "areaCode": 211103000000,
                        "areaId": 129830,
                        "areaName": "兴隆台区",
                        "parent_id": 129768,
                        "level": 2
                    },
                    {
                        "areaCode": 211104000000,
                        "areaId": 129965,
                        "areaName": "大洼区",
                        "parent_id": 129768,
                        "level": 2
                    },
                    {
                        "areaCode": 211122000000,
                        "areaId": 130174,
                        "areaName": "盘山县",
                        "parent_id": 129768,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 211200000000,
                "areaId": 130364,
                "areaName": "铁岭市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 211201000000,
                        "areaId": 130365,
                        "areaName": "市辖区",
                        "parent_id": 130364,
                        "level": 2
                    },
                    {
                        "areaCode": 211202000000,
                        "areaId": 130366,
                        "areaName": "银州区",
                        "parent_id": 130364,
                        "level": 2
                    },
                    {
                        "areaCode": 211204000000,
                        "areaId": 130475,
                        "areaName": "清河区",
                        "parent_id": 130364,
                        "level": 2
                    },
                    {
                        "areaCode": 211221000000,
                        "areaId": 130537,
                        "areaName": "铁岭县",
                        "parent_id": 130364,
                        "level": 2
                    },
                    {
                        "areaCode": 211223000000,
                        "areaId": 130786,
                        "areaName": "西丰县",
                        "parent_id": 130364,
                        "level": 2
                    },
                    {
                        "areaCode": 211224000000,
                        "areaId": 130992,
                        "areaName": "昌图县",
                        "parent_id": 130364,
                        "level": 2
                    },
                    {
                        "areaCode": 211281000000,
                        "areaId": 131547,
                        "areaName": "调兵山市",
                        "parent_id": 130364,
                        "level": 2
                    },
                    {
                        "areaCode": 211282000000,
                        "areaId": 131616,
                        "areaName": "开原市",
                        "parent_id": 130364,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 211300000000,
                "areaId": 131931,
                "areaName": "朝阳市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 211301000000,
                        "areaId": 131932,
                        "areaName": "市辖区",
                        "parent_id": 131931,
                        "level": 2
                    },
                    {
                        "areaCode": 211302000000,
                        "areaId": 131933,
                        "areaName": "双塔区",
                        "parent_id": 131931,
                        "level": 2
                    },
                    {
                        "areaCode": 211303000000,
                        "areaId": 132056,
                        "areaName": "龙城区",
                        "parent_id": 131931,
                        "level": 2
                    },
                    {
                        "areaCode": 211321000000,
                        "areaId": 132159,
                        "areaName": "朝阳县",
                        "parent_id": 131931,
                        "level": 2
                    },
                    {
                        "areaCode": 211322000000,
                        "areaId": 132488,
                        "areaName": "建平县",
                        "parent_id": 131931,
                        "level": 2
                    },
                    {
                        "areaCode": 211324000000,
                        "areaId": 132814,
                        "areaName": "喀喇沁左翼蒙古族自治县",
                        "parent_id": 131931,
                        "level": 2
                    },
                    {
                        "areaCode": 211381000000,
                        "areaId": 133043,
                        "areaName": "北票市",
                        "parent_id": 131931,
                        "level": 2
                    },
                    {
                        "areaCode": 211382000000,
                        "areaId": 133379,
                        "areaName": "凌源市",
                        "parent_id": 131931,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 211400000000,
                "areaId": 133692,
                "areaName": "葫芦岛市",
                "parent_id": 117170,
                "level": 1,
                "children": [
                    {
                        "areaCode": 211401000000,
                        "areaId": 133693,
                        "areaName": "市辖区",
                        "parent_id": 133692,
                        "level": 2
                    },
                    {
                        "areaCode": 211402000000,
                        "areaId": 133694,
                        "areaName": "连山区",
                        "parent_id": 133692,
                        "level": 2
                    },
                    {
                        "areaCode": 211403000000,
                        "areaId": 133931,
                        "areaName": "龙港区",
                        "parent_id": 133692,
                        "level": 2
                    },
                    {
                        "areaCode": 211404000000,
                        "areaId": 134028,
                        "areaName": "南票区",
                        "parent_id": 133692,
                        "level": 2
                    },
                    {
                        "areaCode": 211421000000,
                        "areaId": 134213,
                        "areaName": "绥中县",
                        "parent_id": 133692,
                        "level": 2
                    },
                    {
                        "areaCode": 211422000000,
                        "areaId": 134545,
                        "areaName": "建昌县",
                        "parent_id": 133692,
                        "level": 2
                    },
                    {
                        "areaCode": 211481000000,
                        "areaId": 134867,
                        "areaName": "兴城市",
                        "parent_id": 133692,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 220000000000,
        "areaId": 135164,
        "areaName": "吉林省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 220100000000,
                "areaId": 135165,
                "areaName": "长春市",
                "parent_id": 135164,
                "level": 1,
                "children": [
                    {
                        "areaCode": 220101000000,
                        "areaId": 135166,
                        "areaName": "市辖区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220102000000,
                        "areaId": 135167,
                        "areaName": "南关区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220103000000,
                        "areaId": 135246,
                        "areaName": "宽城区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220104000000,
                        "areaId": 135410,
                        "areaName": "朝阳区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220105000000,
                        "areaId": 135500,
                        "areaName": "二道区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220106000000,
                        "areaId": 135588,
                        "areaName": "绿园区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220112000000,
                        "areaId": 135676,
                        "areaName": "双阳区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220113000000,
                        "areaId": 135838,
                        "areaName": "九台区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220122000000,
                        "areaId": 136197,
                        "areaName": "农安县",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220171000000,
                        "areaId": 136608,
                        "areaName": "长春经济技术开发区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220172000000,
                        "areaId": 136650,
                        "areaName": "长春净月高新技术产业开发区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220173000000,
                        "areaId": 136704,
                        "areaName": "长春高新技术产业开发区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220174000000,
                        "areaId": 136737,
                        "areaName": "长春汽车经济技术开发区",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220182000000,
                        "areaId": 136768,
                        "areaName": "榆树市",
                        "parent_id": 135165,
                        "level": 2
                    },
                    {
                        "areaCode": 220183000000,
                        "areaId": 137201,
                        "areaName": "德惠市",
                        "parent_id": 135165,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 220200000000,
                "areaId": 137532,
                "areaName": "吉林市",
                "parent_id": 135164,
                "level": 1,
                "children": [
                    {
                        "areaCode": 220201000000,
                        "areaId": 137533,
                        "areaName": "市辖区",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220202000000,
                        "areaId": 137534,
                        "areaName": "昌邑区",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220203000000,
                        "areaId": 137709,
                        "areaName": "龙潭区",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220204000000,
                        "areaId": 137901,
                        "areaName": "船营区",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220211000000,
                        "areaId": 138031,
                        "areaName": "丰满区",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220221000000,
                        "areaId": 138133,
                        "areaName": "永吉县",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220271000000,
                        "areaId": 138283,
                        "areaName": "吉林经济开发区",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220272000000,
                        "areaId": 138325,
                        "areaName": "吉林高新技术产业开发区",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220273000000,
                        "areaId": 138358,
                        "areaName": "吉林中国新加坡食品区",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220281000000,
                        "areaId": 138377,
                        "areaName": "蛟河市",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220282000000,
                        "areaId": 138680,
                        "areaName": "桦甸市",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220283000000,
                        "areaId": 138870,
                        "areaName": "舒兰市",
                        "parent_id": 137532,
                        "level": 2
                    },
                    {
                        "areaCode": 220284000000,
                        "areaId": 139134,
                        "areaName": "磐石市",
                        "parent_id": 137532,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 220300000000,
                "areaId": 139451,
                "areaName": "四平市",
                "parent_id": 135164,
                "level": 1,
                "children": [
                    {
                        "areaCode": 220301000000,
                        "areaId": 139452,
                        "areaName": "市辖区",
                        "parent_id": 139451,
                        "level": 2
                    },
                    {
                        "areaCode": 220302000000,
                        "areaId": 139453,
                        "areaName": "铁西区",
                        "parent_id": 139451,
                        "level": 2
                    },
                    {
                        "areaCode": 220303000000,
                        "areaId": 139520,
                        "areaName": "铁东区",
                        "parent_id": 139451,
                        "level": 2
                    },
                    {
                        "areaCode": 220322000000,
                        "areaId": 139631,
                        "areaName": "梨树县",
                        "parent_id": 139451,
                        "level": 2
                    },
                    {
                        "areaCode": 220323000000,
                        "areaId": 140001,
                        "areaName": "伊通满族自治县",
                        "parent_id": 139451,
                        "level": 2
                    },
                    {
                        "areaCode": 220381000000,
                        "areaId": 140223,
                        "areaName": "公主岭市",
                        "parent_id": 139451,
                        "level": 2
                    },
                    {
                        "areaCode": 220382000000,
                        "areaId": 140727,
                        "areaName": "双辽市",
                        "parent_id": 139451,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 220400000000,
                "areaId": 140971,
                "areaName": "辽源市",
                "parent_id": 135164,
                "level": 1,
                "children": [
                    {
                        "areaCode": 220401000000,
                        "areaId": 140972,
                        "areaName": "市辖区",
                        "parent_id": 140971,
                        "level": 2
                    },
                    {
                        "areaCode": 220402000000,
                        "areaId": 140973,
                        "areaName": "龙山区",
                        "parent_id": 140971,
                        "level": 2
                    },
                    {
                        "areaCode": 220403000000,
                        "areaId": 141030,
                        "areaName": "西安区",
                        "parent_id": 140971,
                        "level": 2
                    },
                    {
                        "areaCode": 220421000000,
                        "areaId": 141068,
                        "areaName": "东丰县",
                        "parent_id": 140971,
                        "level": 2
                    },
                    {
                        "areaCode": 220422000000,
                        "areaId": 141322,
                        "areaName": "东辽县",
                        "parent_id": 140971,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 220500000000,
                "areaId": 141590,
                "areaName": "通化市",
                "parent_id": 135164,
                "level": 1,
                "children": [
                    {
                        "areaCode": 220501000000,
                        "areaId": 141591,
                        "areaName": "市辖区",
                        "parent_id": 141590,
                        "level": 2
                    },
                    {
                        "areaCode": 220502000000,
                        "areaId": 141592,
                        "areaName": "东昌区",
                        "parent_id": 141590,
                        "level": 2
                    },
                    {
                        "areaCode": 220503000000,
                        "areaId": 141655,
                        "areaName": "二道江区",
                        "parent_id": 141590,
                        "level": 2
                    },
                    {
                        "areaCode": 220521000000,
                        "areaId": 141703,
                        "areaName": "通化县",
                        "parent_id": 141590,
                        "level": 2
                    },
                    {
                        "areaCode": 220523000000,
                        "areaId": 141904,
                        "areaName": "辉南县",
                        "parent_id": 141590,
                        "level": 2
                    },
                    {
                        "areaCode": 220524000000,
                        "areaId": 142074,
                        "areaName": "柳河县",
                        "parent_id": 141590,
                        "level": 2
                    },
                    {
                        "areaCode": 220581000000,
                        "areaId": 142327,
                        "areaName": "梅河口市",
                        "parent_id": 141590,
                        "level": 2
                    },
                    {
                        "areaCode": 220582000000,
                        "areaId": 142682,
                        "areaName": "集安市",
                        "parent_id": 141590,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 220600000000,
                "areaId": 142853,
                "areaName": "白山市",
                "parent_id": 135164,
                "level": 1,
                "children": [
                    {
                        "areaCode": 220601000000,
                        "areaId": 142854,
                        "areaName": "市辖区",
                        "parent_id": 142853,
                        "level": 2
                    },
                    {
                        "areaCode": 220602000000,
                        "areaId": 142855,
                        "areaName": "浑江区",
                        "parent_id": 142853,
                        "level": 2
                    },
                    {
                        "areaCode": 220605000000,
                        "areaId": 142966,
                        "areaName": "江源区",
                        "parent_id": 142853,
                        "level": 2
                    },
                    {
                        "areaCode": 220621000000,
                        "areaId": 143068,
                        "areaName": "抚松县",
                        "parent_id": 142853,
                        "level": 2
                    },
                    {
                        "areaCode": 220622000000,
                        "areaId": 143256,
                        "areaName": "靖宇县",
                        "parent_id": 142853,
                        "level": 2
                    },
                    {
                        "areaCode": 220623000000,
                        "areaId": 143385,
                        "areaName": "长白朝鲜族自治县",
                        "parent_id": 142853,
                        "level": 2
                    },
                    {
                        "areaCode": 220681000000,
                        "areaId": 143482,
                        "areaName": "临江市",
                        "parent_id": 142853,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 220700000000,
                "areaId": 143606,
                "areaName": "松原市",
                "parent_id": 135164,
                "level": 1,
                "children": [
                    {
                        "areaCode": 220701000000,
                        "areaId": 143607,
                        "areaName": "市辖区",
                        "parent_id": 143606,
                        "level": 2
                    },
                    {
                        "areaCode": 220702000000,
                        "areaId": 143608,
                        "areaName": "宁江区",
                        "parent_id": 143606,
                        "level": 2
                    },
                    {
                        "areaCode": 220721000000,
                        "areaId": 143805,
                        "areaName": "前郭尔罗斯蒙古族自治县",
                        "parent_id": 143606,
                        "level": 2
                    },
                    {
                        "areaCode": 220722000000,
                        "areaId": 144115,
                        "areaName": "长岭县",
                        "parent_id": 143606,
                        "level": 2
                    },
                    {
                        "areaCode": 220723000000,
                        "areaId": 144400,
                        "areaName": "乾安县",
                        "parent_id": 143606,
                        "level": 2
                    },
                    {
                        "areaCode": 220771000000,
                        "areaId": 144596,
                        "areaName": "吉林松原经济开发区",
                        "parent_id": 143606,
                        "level": 2
                    },
                    {
                        "areaCode": 220781000000,
                        "areaId": 144614,
                        "areaName": "扶余市",
                        "parent_id": 143606,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 220800000000,
                "areaId": 145047,
                "areaName": "白城市",
                "parent_id": 135164,
                "level": 1,
                "children": [
                    {
                        "areaCode": 220801000000,
                        "areaId": 145048,
                        "areaName": "市辖区",
                        "parent_id": 145047,
                        "level": 2
                    },
                    {
                        "areaCode": 220802000000,
                        "areaId": 145049,
                        "areaName": "洮北区",
                        "parent_id": 145047,
                        "level": 2
                    },
                    {
                        "areaCode": 220821000000,
                        "areaId": 145284,
                        "areaName": "镇赉县",
                        "parent_id": 145047,
                        "level": 2
                    },
                    {
                        "areaCode": 220822000000,
                        "areaId": 145472,
                        "areaName": "通榆县",
                        "parent_id": 145047,
                        "level": 2
                    },
                    {
                        "areaCode": 220871000000,
                        "areaId": 145691,
                        "areaName": "吉林白城经济开发区",
                        "parent_id": 145047,
                        "level": 2
                    },
                    {
                        "areaCode": 220881000000,
                        "areaId": 145718,
                        "areaName": "洮南市",
                        "parent_id": 145047,
                        "level": 2
                    },
                    {
                        "areaCode": 220882000000,
                        "areaId": 145992,
                        "areaName": "大安市",
                        "parent_id": 145047,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 222400000000,
                "areaId": 146280,
                "areaName": "延边朝鲜族自治州",
                "parent_id": 135164,
                "level": 1,
                "children": [
                    {
                        "areaCode": 222401000000,
                        "areaId": 146281,
                        "areaName": "延吉市",
                        "parent_id": 146280,
                        "level": 2
                    },
                    {
                        "areaCode": 222402000000,
                        "areaId": 146437,
                        "areaName": "图们市",
                        "parent_id": 146280,
                        "level": 2
                    },
                    {
                        "areaCode": 222403000000,
                        "areaId": 146516,
                        "areaName": "敦化市",
                        "parent_id": 146280,
                        "level": 2
                    },
                    {
                        "areaCode": 222404000000,
                        "areaId": 146955,
                        "areaName": "珲春市",
                        "parent_id": 146280,
                        "level": 2
                    },
                    {
                        "areaCode": 222405000000,
                        "areaId": 147134,
                        "areaName": "龙井市",
                        "parent_id": 146280,
                        "level": 2
                    },
                    {
                        "areaCode": 222406000000,
                        "areaId": 147243,
                        "areaName": "和龙市",
                        "parent_id": 146280,
                        "level": 2
                    },
                    {
                        "areaCode": 222424000000,
                        "areaId": 147386,
                        "areaName": "汪清县",
                        "parent_id": 146280,
                        "level": 2
                    },
                    {
                        "areaCode": 222426000000,
                        "areaId": 147663,
                        "areaName": "安图县",
                        "parent_id": 146280,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 230000000000,
        "areaId": 147907,
        "areaName": "黑龙江省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 230100000000,
                "areaId": 147908,
                "areaName": "哈尔滨市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 230101000000,
                        "areaId": 147909,
                        "areaName": "市辖区",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230102000000,
                        "areaId": 147910,
                        "areaName": "道里区",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230103000000,
                        "areaId": 148110,
                        "areaName": "南岗区",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230104000000,
                        "areaId": 148328,
                        "areaName": "道外区",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230108000000,
                        "areaId": 148523,
                        "areaName": "平房区",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230109000000,
                        "areaId": 148580,
                        "areaName": "松北区",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230110000000,
                        "areaId": 148682,
                        "areaName": "香坊区",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230111000000,
                        "areaId": 148876,
                        "areaName": "呼兰区",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230112000000,
                        "areaId": 149128,
                        "areaName": "阿城区",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230113000000,
                        "areaId": 149336,
                        "areaName": "双城区",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230123000000,
                        "areaId": 149647,
                        "areaName": "依兰县",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230124000000,
                        "areaId": 149827,
                        "areaName": "方正县",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230125000000,
                        "areaId": 149927,
                        "areaName": "宾县",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230126000000,
                        "areaId": 150135,
                        "areaName": "巴彦县",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230127000000,
                        "areaId": 150291,
                        "areaName": "木兰县",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230128000000,
                        "areaId": 150409,
                        "areaName": "通河县",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230129000000,
                        "areaId": 150582,
                        "areaName": "延寿县",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230183000000,
                        "areaId": 150733,
                        "areaName": "尚志市",
                        "parent_id": 147908,
                        "level": 2
                    },
                    {
                        "areaCode": 230184000000,
                        "areaId": 150983,
                        "areaName": "五常市",
                        "parent_id": 147908,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 230200000000,
                "areaId": 151309,
                "areaName": "齐齐哈尔市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 230201000000,
                        "areaId": 151310,
                        "areaName": "市辖区",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230202000000,
                        "areaId": 151311,
                        "areaName": "龙沙区",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230203000000,
                        "areaId": 151359,
                        "areaName": "建华区",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230204000000,
                        "areaId": 151404,
                        "areaName": "铁锋区",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230205000000,
                        "areaId": 151462,
                        "areaName": "昂昂溪区",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230206000000,
                        "areaId": 151497,
                        "areaName": "富拉尔基区",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230207000000,
                        "areaId": 151548,
                        "areaName": "碾子山区",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230208000000,
                        "areaId": 151569,
                        "areaName": "梅里斯达斡尔族区",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230221000000,
                        "areaId": 151637,
                        "areaName": "龙江县",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230223000000,
                        "areaId": 151821,
                        "areaName": "依安县",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230224000000,
                        "areaId": 152000,
                        "areaName": "泰来县",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230225000000,
                        "areaId": 152129,
                        "areaName": "甘南县",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230227000000,
                        "areaId": 152279,
                        "areaName": "富裕县",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230229000000,
                        "areaId": 152401,
                        "areaName": "克山县",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230230000000,
                        "areaId": 152570,
                        "areaName": "克东县",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230231000000,
                        "areaId": 152704,
                        "areaName": "拜泉县",
                        "parent_id": 151309,
                        "level": 2
                    },
                    {
                        "areaCode": 230281000000,
                        "areaId": 152913,
                        "areaName": "讷河市",
                        "parent_id": 151309,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 230300000000,
                "areaId": 153148,
                "areaName": "鸡西市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 230301000000,
                        "areaId": 153149,
                        "areaName": "市辖区",
                        "parent_id": 153148,
                        "level": 2
                    },
                    {
                        "areaCode": 230302000000,
                        "areaId": 153150,
                        "areaName": "鸡冠区",
                        "parent_id": 153148,
                        "level": 2
                    },
                    {
                        "areaCode": 230303000000,
                        "areaId": 153217,
                        "areaName": "恒山区",
                        "parent_id": 153148,
                        "level": 2
                    },
                    {
                        "areaCode": 230304000000,
                        "areaId": 153272,
                        "areaName": "滴道区",
                        "parent_id": 153148,
                        "level": 2
                    },
                    {
                        "areaCode": 230305000000,
                        "areaId": 153317,
                        "areaName": "梨树区",
                        "parent_id": 153148,
                        "level": 2
                    },
                    {
                        "areaCode": 230306000000,
                        "areaId": 153346,
                        "areaName": "城子河区",
                        "parent_id": 153148,
                        "level": 2
                    },
                    {
                        "areaCode": 230307000000,
                        "areaId": 153387,
                        "areaName": "麻山区",
                        "parent_id": 153148,
                        "level": 2
                    },
                    {
                        "areaCode": 230321000000,
                        "areaId": 153410,
                        "areaName": "鸡东县",
                        "parent_id": 153148,
                        "level": 2
                    },
                    {
                        "areaCode": 230381000000,
                        "areaId": 153577,
                        "areaName": "虎林市",
                        "parent_id": 153148,
                        "level": 2
                    },
                    {
                        "areaCode": 230382000000,
                        "areaId": 153789,
                        "areaName": "密山市",
                        "parent_id": 153148,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 230400000000,
                "areaId": 154039,
                "areaName": "鹤岗市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 230401000000,
                        "areaId": 154040,
                        "areaName": "市辖区",
                        "parent_id": 154039,
                        "level": 2
                    },
                    {
                        "areaCode": 230402000000,
                        "areaId": 154041,
                        "areaName": "向阳区",
                        "parent_id": 154039,
                        "level": 2
                    },
                    {
                        "areaCode": 230403000000,
                        "areaId": 154064,
                        "areaName": "工农区",
                        "parent_id": 154039,
                        "level": 2
                    },
                    {
                        "areaCode": 230404000000,
                        "areaId": 154089,
                        "areaName": "南山区",
                        "parent_id": 154039,
                        "level": 2
                    },
                    {
                        "areaCode": 230405000000,
                        "areaId": 154114,
                        "areaName": "兴安区",
                        "parent_id": 154039,
                        "level": 2
                    },
                    {
                        "areaCode": 230406000000,
                        "areaId": 154149,
                        "areaName": "东山区",
                        "parent_id": 154039,
                        "level": 2
                    },
                    {
                        "areaCode": 230407000000,
                        "areaId": 154211,
                        "areaName": "兴山区",
                        "parent_id": 154039,
                        "level": 2
                    },
                    {
                        "areaCode": 230421000000,
                        "areaId": 154224,
                        "areaName": "萝北县",
                        "parent_id": 154039,
                        "level": 2
                    },
                    {
                        "areaCode": 230422000000,
                        "areaId": 154414,
                        "areaName": "绥滨县",
                        "parent_id": 154039,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 230500000000,
                "areaId": 154590,
                "areaName": "双鸭山市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 230501000000,
                        "areaId": 154591,
                        "areaName": "市辖区",
                        "parent_id": 154590,
                        "level": 2
                    },
                    {
                        "areaCode": 230502000000,
                        "areaId": 154592,
                        "areaName": "尖山区",
                        "parent_id": 154590,
                        "level": 2
                    },
                    {
                        "areaCode": 230503000000,
                        "areaId": 154646,
                        "areaName": "岭东区",
                        "parent_id": 154590,
                        "level": 2
                    },
                    {
                        "areaCode": 230505000000,
                        "areaId": 154685,
                        "areaName": "四方台区",
                        "parent_id": 154590,
                        "level": 2
                    },
                    {
                        "areaCode": 230506000000,
                        "areaId": 154721,
                        "areaName": "宝山区",
                        "parent_id": 154590,
                        "level": 2
                    },
                    {
                        "areaCode": 230521000000,
                        "areaId": 154767,
                        "areaName": "集贤县",
                        "parent_id": 154590,
                        "level": 2
                    },
                    {
                        "areaCode": 230522000000,
                        "areaId": 154987,
                        "areaName": "友谊县",
                        "parent_id": 154590,
                        "level": 2
                    },
                    {
                        "areaCode": 230523000000,
                        "areaId": 155087,
                        "areaName": "宝清县",
                        "parent_id": 154590,
                        "level": 2
                    },
                    {
                        "areaCode": 230524000000,
                        "areaId": 155319,
                        "areaName": "饶河县",
                        "parent_id": 154590,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 230600000000,
                "areaId": 155490,
                "areaName": "大庆市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 230601000000,
                        "areaId": 155491,
                        "areaName": "市辖区",
                        "parent_id": 155490,
                        "level": 2
                    },
                    {
                        "areaCode": 230602000000,
                        "areaId": 155492,
                        "areaName": "萨尔图区",
                        "parent_id": 155490,
                        "level": 2
                    },
                    {
                        "areaCode": 230603000000,
                        "areaId": 155560,
                        "areaName": "龙凤区",
                        "parent_id": 155490,
                        "level": 2
                    },
                    {
                        "areaCode": 230604000000,
                        "areaId": 155612,
                        "areaName": "让胡路区",
                        "parent_id": 155490,
                        "level": 2
                    },
                    {
                        "areaCode": 230605000000,
                        "areaId": 155702,
                        "areaName": "红岗区",
                        "parent_id": 155490,
                        "level": 2
                    },
                    {
                        "areaCode": 230606000000,
                        "areaId": 155750,
                        "areaName": "大同区",
                        "parent_id": 155490,
                        "level": 2
                    },
                    {
                        "areaCode": 230621000000,
                        "areaId": 155848,
                        "areaName": "肇州县",
                        "parent_id": 155490,
                        "level": 2
                    },
                    {
                        "areaCode": 230622000000,
                        "areaId": 155980,
                        "areaName": "肇源县",
                        "parent_id": 155490,
                        "level": 2
                    },
                    {
                        "areaCode": 230623000000,
                        "areaId": 156163,
                        "areaName": "林甸县",
                        "parent_id": 155490,
                        "level": 2
                    },
                    {
                        "areaCode": 230624000000,
                        "areaId": 156271,
                        "areaName": "杜尔伯特蒙古族自治县",
                        "parent_id": 155490,
                        "level": 2
                    },
                    {
                        "areaCode": 230671000000,
                        "areaId": 156390,
                        "areaName": "大庆高新技术产业开发区",
                        "parent_id": 155490,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 230700000000,
                "areaId": 156400,
                "areaName": "伊春市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 230701000000,
                        "areaId": 156401,
                        "areaName": "市辖区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230702000000,
                        "areaId": 156402,
                        "areaName": "伊春区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230703000000,
                        "areaId": 156433,
                        "areaName": "南岔区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230704000000,
                        "areaId": 156522,
                        "areaName": "友好区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230705000000,
                        "areaId": 156571,
                        "areaName": "西林区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230706000000,
                        "areaId": 156594,
                        "areaName": "翠峦区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230707000000,
                        "areaId": 156629,
                        "areaName": "新青区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230708000000,
                        "areaId": 156670,
                        "areaName": "美溪区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230709000000,
                        "areaId": 156712,
                        "areaName": "金山屯区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230710000000,
                        "areaId": 156745,
                        "areaName": "五营区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230711000000,
                        "areaId": 156775,
                        "areaName": "乌马河区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230712000000,
                        "areaId": 156817,
                        "areaName": "汤旺河区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230713000000,
                        "areaId": 156856,
                        "areaName": "带岭区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230714000000,
                        "areaId": 156896,
                        "areaName": "乌伊岭区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230715000000,
                        "areaId": 156933,
                        "areaName": "红星区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230716000000,
                        "areaId": 156962,
                        "areaName": "上甘岭区",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230722000000,
                        "areaId": 156998,
                        "areaName": "嘉荫县",
                        "parent_id": 156400,
                        "level": 2
                    },
                    {
                        "areaCode": 230781000000,
                        "areaId": 157104,
                        "areaName": "铁力市",
                        "parent_id": 156400,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 230800000000,
                "areaId": 157281,
                "areaName": "佳木斯市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 230801000000,
                        "areaId": 157282,
                        "areaName": "市辖区",
                        "parent_id": 157281,
                        "level": 2
                    },
                    {
                        "areaCode": 230803000000,
                        "areaId": 157283,
                        "areaName": "向阳区",
                        "parent_id": 157281,
                        "level": 2
                    },
                    {
                        "areaCode": 230804000000,
                        "areaId": 157371,
                        "areaName": "前进区",
                        "parent_id": 157281,
                        "level": 2
                    },
                    {
                        "areaCode": 230805000000,
                        "areaId": 157396,
                        "areaName": "东风区",
                        "parent_id": 157281,
                        "level": 2
                    },
                    {
                        "areaCode": 230811000000,
                        "areaId": 157459,
                        "areaName": "郊区",
                        "parent_id": 157281,
                        "level": 2
                    },
                    {
                        "areaCode": 230822000000,
                        "areaId": 157640,
                        "areaName": "桦南县",
                        "parent_id": 157281,
                        "level": 2
                    },
                    {
                        "areaCode": 230826000000,
                        "areaId": 157907,
                        "areaName": "桦川县",
                        "parent_id": 157281,
                        "level": 2
                    },
                    {
                        "areaCode": 230828000000,
                        "areaId": 158043,
                        "areaName": "汤原县",
                        "parent_id": 157281,
                        "level": 2
                    },
                    {
                        "areaCode": 230881000000,
                        "areaId": 158239,
                        "areaName": "同江市",
                        "parent_id": 157281,
                        "level": 2
                    },
                    {
                        "areaCode": 230882000000,
                        "areaId": 158418,
                        "areaName": "富锦市",
                        "parent_id": 157281,
                        "level": 2
                    },
                    {
                        "areaCode": 230883000000,
                        "areaId": 158820,
                        "areaName": "抚远市",
                        "parent_id": 157281,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 230900000000,
                "areaId": 158940,
                "areaName": "七台河市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 230901000000,
                        "areaId": 158941,
                        "areaName": "市辖区",
                        "parent_id": 158940,
                        "level": 2
                    },
                    {
                        "areaCode": 230902000000,
                        "areaId": 158942,
                        "areaName": "新兴区",
                        "parent_id": 158940,
                        "level": 2
                    },
                    {
                        "areaCode": 230903000000,
                        "areaId": 159037,
                        "areaName": "桃山区",
                        "parent_id": 158940,
                        "level": 2
                    },
                    {
                        "areaCode": 230904000000,
                        "areaId": 159087,
                        "areaName": "茄子河区",
                        "parent_id": 158940,
                        "level": 2
                    },
                    {
                        "areaCode": 230921000000,
                        "areaId": 159178,
                        "areaName": "勃利县",
                        "parent_id": 158940,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 231000000000,
                "areaId": 159352,
                "areaName": "牡丹江市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 231001000000,
                        "areaId": 159353,
                        "areaName": "市辖区",
                        "parent_id": 159352,
                        "level": 2
                    },
                    {
                        "areaCode": 231002000000,
                        "areaId": 159354,
                        "areaName": "东安区",
                        "parent_id": 159352,
                        "level": 2
                    },
                    {
                        "areaCode": 231003000000,
                        "areaId": 159392,
                        "areaName": "阳明区",
                        "parent_id": 159352,
                        "level": 2
                    },
                    {
                        "areaCode": 231004000000,
                        "areaId": 159495,
                        "areaName": "爱民区",
                        "parent_id": 159352,
                        "level": 2
                    },
                    {
                        "areaCode": 231005000000,
                        "areaId": 159543,
                        "areaName": "西安区",
                        "parent_id": 159352,
                        "level": 2
                    },
                    {
                        "areaCode": 231025000000,
                        "areaId": 159628,
                        "areaName": "林口县",
                        "parent_id": 159352,
                        "level": 2
                    },
                    {
                        "areaCode": 231071000000,
                        "areaId": 159868,
                        "areaName": "牡丹江经济技术开发区",
                        "parent_id": 159352,
                        "level": 2
                    },
                    {
                        "areaCode": 231081000000,
                        "areaId": 159883,
                        "areaName": "绥芬河市",
                        "parent_id": 159352,
                        "level": 2
                    },
                    {
                        "areaCode": 231083000000,
                        "areaId": 159915,
                        "areaName": "海林市",
                        "parent_id": 159352,
                        "level": 2
                    },
                    {
                        "areaCode": 231084000000,
                        "areaId": 160146,
                        "areaName": "宁安市",
                        "parent_id": 159352,
                        "level": 2
                    },
                    {
                        "areaCode": 231085000000,
                        "areaId": 160467,
                        "areaName": "穆棱市",
                        "parent_id": 159352,
                        "level": 2
                    },
                    {
                        "areaCode": 231086000000,
                        "areaId": 160704,
                        "areaName": "东宁市",
                        "parent_id": 159352,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 231100000000,
                "areaId": 160857,
                "areaName": "黑河市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 231101000000,
                        "areaId": 160858,
                        "areaName": "市辖区",
                        "parent_id": 160857,
                        "level": 2
                    },
                    {
                        "areaCode": 231102000000,
                        "areaId": 160859,
                        "areaName": "爱辉区",
                        "parent_id": 160857,
                        "level": 2
                    },
                    {
                        "areaCode": 231121000000,
                        "areaId": 161031,
                        "areaName": "嫩江县",
                        "parent_id": 160857,
                        "level": 2
                    },
                    {
                        "areaCode": 231123000000,
                        "areaId": 161320,
                        "areaName": "逊克县",
                        "parent_id": 160857,
                        "level": 2
                    },
                    {
                        "areaCode": 231124000000,
                        "areaId": 161435,
                        "areaName": "孙吴县",
                        "parent_id": 160857,
                        "level": 2
                    },
                    {
                        "areaCode": 231181000000,
                        "areaId": 161573,
                        "areaName": "北安市",
                        "parent_id": 160857,
                        "level": 2
                    },
                    {
                        "areaCode": 231182000000,
                        "areaId": 161740,
                        "areaName": "五大连池市",
                        "parent_id": 160857,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 231200000000,
                "areaId": 161983,
                "areaName": "绥化市",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 231201000000,
                        "areaId": 161984,
                        "areaName": "市辖区",
                        "parent_id": 161983,
                        "level": 2
                    },
                    {
                        "areaCode": 231202000000,
                        "areaId": 161985,
                        "areaName": "北林区",
                        "parent_id": 161983,
                        "level": 2
                    },
                    {
                        "areaCode": 231221000000,
                        "areaId": 162215,
                        "areaName": "望奎县",
                        "parent_id": 161983,
                        "level": 2
                    },
                    {
                        "areaCode": 231222000000,
                        "areaId": 162368,
                        "areaName": "兰西县",
                        "parent_id": 161983,
                        "level": 2
                    },
                    {
                        "areaCode": 231223000000,
                        "areaId": 162511,
                        "areaName": "青冈县",
                        "parent_id": 161983,
                        "level": 2
                    },
                    {
                        "areaCode": 231224000000,
                        "areaId": 162718,
                        "areaName": "庆安县",
                        "parent_id": 161983,
                        "level": 2
                    },
                    {
                        "areaCode": 231225000000,
                        "areaId": 162849,
                        "areaName": "明水县",
                        "parent_id": 161983,
                        "level": 2
                    },
                    {
                        "areaCode": 231226000000,
                        "areaId": 162991,
                        "areaName": "绥棱县",
                        "parent_id": 161983,
                        "level": 2
                    },
                    {
                        "areaCode": 231281000000,
                        "areaId": 163157,
                        "areaName": "安达市",
                        "parent_id": 161983,
                        "level": 2
                    },
                    {
                        "areaCode": 231282000000,
                        "areaId": 163327,
                        "areaName": "肇东市",
                        "parent_id": 161983,
                        "level": 2
                    },
                    {
                        "areaCode": 231283000000,
                        "areaId": 163579,
                        "areaName": "海伦市",
                        "parent_id": 161983,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 232700000000,
                "areaId": 163904,
                "areaName": "大兴安岭地区",
                "parent_id": 147907,
                "level": 1,
                "children": [
                    {
                        "areaCode": 232701000000,
                        "areaId": 163905,
                        "areaName": "加格达奇区",
                        "parent_id": 163904,
                        "level": 2
                    },
                    {
                        "areaCode": 232702000000,
                        "areaId": 163955,
                        "areaName": "松岭区",
                        "parent_id": 163904,
                        "level": 2
                    },
                    {
                        "areaCode": 232703000000,
                        "areaId": 163965,
                        "areaName": "新林区",
                        "parent_id": 163904,
                        "level": 2
                    },
                    {
                        "areaCode": 232704000000,
                        "areaId": 163981,
                        "areaName": "呼中区",
                        "parent_id": 163904,
                        "level": 2
                    },
                    {
                        "areaCode": 232721000000,
                        "areaId": 163991,
                        "areaName": "呼玛县",
                        "parent_id": 163904,
                        "level": 2
                    },
                    {
                        "areaCode": 232722000000,
                        "areaId": 164059,
                        "areaName": "塔河县",
                        "parent_id": 163904,
                        "level": 2
                    },
                    {
                        "areaCode": 232723000000,
                        "areaId": 164093,
                        "areaName": "漠河县",
                        "parent_id": 163904,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 310000000000,
        "areaId": 164131,
        "areaName": "上海市",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 310100000000,
                "areaId": 164132,
                "areaName": "市辖区",
                "parent_id": 164131,
                "level": 1,
                "children": [
                    {
                        "areaCode": 310101000000,
                        "areaId": 164133,
                        "areaName": "黄浦区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310104000000,
                        "areaId": 164327,
                        "areaName": "徐汇区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310105000000,
                        "areaId": 164648,
                        "areaName": "长宁区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310106000000,
                        "areaId": 164844,
                        "areaName": "静安区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310107000000,
                        "areaId": 165135,
                        "areaName": "普陀区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310109000000,
                        "areaId": 165410,
                        "areaName": "虹口区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310110000000,
                        "areaId": 165632,
                        "areaName": "杨浦区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310112000000,
                        "areaId": 165958,
                        "areaName": "闵行区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310113000000,
                        "areaId": 166536,
                        "areaName": "宝山区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310114000000,
                        "areaId": 167026,
                        "areaName": "嘉定区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310115000000,
                        "areaId": 167380,
                        "areaName": "浦东新区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310116000000,
                        "areaId": 168722,
                        "areaName": "金山区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310117000000,
                        "areaId": 168957,
                        "areaName": "松江区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310118000000,
                        "areaId": 169315,
                        "areaName": "青浦区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310120000000,
                        "areaId": 169630,
                        "areaName": "奉贤区",
                        "parent_id": 164132,
                        "level": 2
                    },
                    {
                        "areaCode": 310151000000,
                        "areaId": 169937,
                        "areaName": "崇明区",
                        "parent_id": 164132,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 320000000000,
        "areaId": 170308,
        "areaName": "江苏省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 320100000000,
                "areaId": 170309,
                "areaName": "南京市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 320101000000,
                        "areaId": 170310,
                        "areaName": "市辖区",
                        "parent_id": 170309,
                        "level": 2
                    },
                    {
                        "areaCode": 320102000000,
                        "areaId": 170311,
                        "areaName": "玄武区",
                        "parent_id": 170309,
                        "level": 2
                    },
                    {
                        "areaCode": 320104000000,
                        "areaId": 170378,
                        "areaName": "秦淮区",
                        "parent_id": 170309,
                        "level": 2
                    },
                    {
                        "areaCode": 320105000000,
                        "areaId": 170502,
                        "areaName": "建邺区",
                        "parent_id": 170309,
                        "level": 2
                    },
                    {
                        "areaCode": 320106000000,
                        "areaId": 170574,
                        "areaName": "鼓楼区",
                        "parent_id": 170309,
                        "level": 2
                    },
                    {
                        "areaCode": 320111000000,
                        "areaId": 170711,
                        "areaName": "浦口区",
                        "parent_id": 170309,
                        "level": 2
                    },
                    {
                        "areaCode": 320113000000,
                        "areaId": 170849,
                        "areaName": "栖霞区",
                        "parent_id": 170309,
                        "level": 2
                    },
                    {
                        "areaCode": 320114000000,
                        "areaId": 171005,
                        "areaName": "雨花台区",
                        "parent_id": 170309,
                        "level": 2
                    },
                    {
                        "areaCode": 320115000000,
                        "areaId": 171105,
                        "areaName": "江宁区",
                        "parent_id": 170309,
                        "level": 2
                    },
                    {
                        "areaCode": 320116000000,
                        "areaId": 171326,
                        "areaName": "六合区",
                        "parent_id": 170309,
                        "level": 2
                    },
                    {
                        "areaCode": 320117000000,
                        "areaId": 171497,
                        "areaName": "溧水区",
                        "parent_id": 170309,
                        "level": 2
                    },
                    {
                        "areaCode": 320118000000,
                        "areaId": 171619,
                        "areaName": "高淳区",
                        "parent_id": 170309,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 320200000000,
                "areaId": 171778,
                "areaName": "无锡市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 320201000000,
                        "areaId": 171779,
                        "areaName": "市辖区",
                        "parent_id": 171778,
                        "level": 2
                    },
                    {
                        "areaCode": 320205000000,
                        "areaId": 171780,
                        "areaName": "锡山区",
                        "parent_id": 171778,
                        "level": 2
                    },
                    {
                        "areaCode": 320206000000,
                        "areaId": 171912,
                        "areaName": "惠山区",
                        "parent_id": 171778,
                        "level": 2
                    },
                    {
                        "areaCode": 320211000000,
                        "areaId": 172037,
                        "areaName": "滨湖区",
                        "parent_id": 171778,
                        "level": 2
                    },
                    {
                        "areaCode": 320213000000,
                        "areaId": 172161,
                        "areaName": "梁溪区",
                        "parent_id": 171778,
                        "level": 2
                    },
                    {
                        "areaCode": 320214000000,
                        "areaId": 172338,
                        "areaName": "新吴区",
                        "parent_id": 171778,
                        "level": 2
                    },
                    {
                        "areaCode": 320281000000,
                        "areaId": 172482,
                        "areaName": "江阴市",
                        "parent_id": 171778,
                        "level": 2
                    },
                    {
                        "areaCode": 320282000000,
                        "areaId": 172811,
                        "areaName": "宜兴市",
                        "parent_id": 171778,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 320300000000,
                "areaId": 173144,
                "areaName": "徐州市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 320301000000,
                        "areaId": 173145,
                        "areaName": "市辖区",
                        "parent_id": 173144,
                        "level": 2
                    },
                    {
                        "areaCode": 320302000000,
                        "areaId": 173146,
                        "areaName": "鼓楼区",
                        "parent_id": 173144,
                        "level": 2
                    },
                    {
                        "areaCode": 320303000000,
                        "areaId": 173221,
                        "areaName": "云龙区",
                        "parent_id": 173144,
                        "level": 2
                    },
                    {
                        "areaCode": 320305000000,
                        "areaId": 173310,
                        "areaName": "贾汪区",
                        "parent_id": 173144,
                        "level": 2
                    },
                    {
                        "areaCode": 320311000000,
                        "areaId": 173473,
                        "areaName": "泉山区",
                        "parent_id": 173144,
                        "level": 2
                    },
                    {
                        "areaCode": 320312000000,
                        "areaId": 173610,
                        "areaName": "铜山区",
                        "parent_id": 173144,
                        "level": 2
                    },
                    {
                        "areaCode": 320321000000,
                        "areaId": 173965,
                        "areaName": "丰县",
                        "parent_id": 173144,
                        "level": 2
                    },
                    {
                        "areaCode": 320322000000,
                        "areaId": 174395,
                        "areaName": "沛县",
                        "parent_id": 173144,
                        "level": 2
                    },
                    {
                        "areaCode": 320324000000,
                        "areaId": 174810,
                        "areaName": "睢宁县",
                        "parent_id": 173144,
                        "level": 2
                    },
                    {
                        "areaCode": 320371000000,
                        "areaId": 175233,
                        "areaName": "徐州经济技术开发区",
                        "parent_id": 173144,
                        "level": 2
                    },
                    {
                        "areaCode": 320381000000,
                        "areaId": 175317,
                        "areaName": "新沂市",
                        "parent_id": 173144,
                        "level": 2
                    },
                    {
                        "areaCode": 320382000000,
                        "areaId": 175624,
                        "areaName": "邳州市",
                        "parent_id": 173144,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 320400000000,
                "areaId": 176154,
                "areaName": "常州市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 320401000000,
                        "areaId": 176155,
                        "areaName": "市辖区",
                        "parent_id": 176154,
                        "level": 2
                    },
                    {
                        "areaCode": 320402000000,
                        "areaId": 176156,
                        "areaName": "天宁区",
                        "parent_id": 176154,
                        "level": 2
                    },
                    {
                        "areaCode": 320404000000,
                        "areaId": 176294,
                        "areaName": "钟楼区",
                        "parent_id": 176154,
                        "level": 2
                    },
                    {
                        "areaCode": 320411000000,
                        "areaId": 176408,
                        "areaName": "新北区",
                        "parent_id": 176154,
                        "level": 2
                    },
                    {
                        "areaCode": 320412000000,
                        "areaId": 176556,
                        "areaName": "武进区",
                        "parent_id": 176154,
                        "level": 2
                    },
                    {
                        "areaCode": 320413000000,
                        "areaId": 176869,
                        "areaName": "金坛区",
                        "parent_id": 176154,
                        "level": 2
                    },
                    {
                        "areaCode": 320481000000,
                        "areaId": 177030,
                        "areaName": "溧阳市",
                        "parent_id": 176154,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 320500000000,
                "areaId": 177286,
                "areaName": "苏州市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 320501000000,
                        "areaId": 177287,
                        "areaName": "市辖区",
                        "parent_id": 177286,
                        "level": 2
                    },
                    {
                        "areaCode": 320505000000,
                        "areaId": 177288,
                        "areaName": "虎丘区",
                        "parent_id": 177286,
                        "level": 2
                    },
                    {
                        "areaCode": 320506000000,
                        "areaId": 177395,
                        "areaName": "吴中区",
                        "parent_id": 177286,
                        "level": 2
                    },
                    {
                        "areaCode": 320507000000,
                        "areaId": 177614,
                        "areaName": "相城区",
                        "parent_id": 177286,
                        "level": 2
                    },
                    {
                        "areaCode": 320508000000,
                        "areaId": 177772,
                        "areaName": "姑苏区",
                        "parent_id": 177286,
                        "level": 2
                    },
                    {
                        "areaCode": 320509000000,
                        "areaId": 177955,
                        "areaName": "吴江区",
                        "parent_id": 177286,
                        "level": 2
                    },
                    {
                        "areaCode": 320571000000,
                        "areaId": 178286,
                        "areaName": "苏州工业园区",
                        "parent_id": 177286,
                        "level": 2
                    },
                    {
                        "areaCode": 320581000000,
                        "areaId": 178438,
                        "areaName": "常熟市",
                        "parent_id": 177286,
                        "level": 2
                    },
                    {
                        "areaCode": 320582000000,
                        "areaId": 178799,
                        "areaName": "张家港市",
                        "parent_id": 177286,
                        "level": 2
                    },
                    {
                        "areaCode": 320583000000,
                        "areaId": 179115,
                        "areaName": "昆山市",
                        "parent_id": 177286,
                        "level": 2
                    },
                    {
                        "areaCode": 320585000000,
                        "areaId": 179474,
                        "areaName": "太仓市",
                        "parent_id": 177286,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 320600000000,
                "areaId": 179635,
                "areaName": "南通市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 320601000000,
                        "areaId": 179636,
                        "areaName": "市辖区",
                        "parent_id": 179635,
                        "level": 2
                    },
                    {
                        "areaCode": 320602000000,
                        "areaId": 179637,
                        "areaName": "崇川区",
                        "parent_id": 179635,
                        "level": 2
                    },
                    {
                        "areaCode": 320611000000,
                        "areaId": 179755,
                        "areaName": "港闸区",
                        "parent_id": 179635,
                        "level": 2
                    },
                    {
                        "areaCode": 320612000000,
                        "areaId": 179836,
                        "areaName": "通州区",
                        "parent_id": 179635,
                        "level": 2
                    },
                    {
                        "areaCode": 320621000000,
                        "areaId": 180132,
                        "areaName": "海安县",
                        "parent_id": 179635,
                        "level": 2
                    },
                    {
                        "areaCode": 320623000000,
                        "areaId": 180382,
                        "areaName": "如东县",
                        "parent_id": 179635,
                        "level": 2
                    },
                    {
                        "areaCode": 320671000000,
                        "areaId": 180663,
                        "areaName": "南通经济技术开发区",
                        "parent_id": 179635,
                        "level": 2
                    },
                    {
                        "areaCode": 320681000000,
                        "areaId": 180713,
                        "areaName": "启东市",
                        "parent_id": 179635,
                        "level": 2
                    },
                    {
                        "areaCode": 320682000000,
                        "areaId": 181058,
                        "areaName": "如皋市",
                        "parent_id": 179635,
                        "level": 2
                    },
                    {
                        "areaCode": 320684000000,
                        "areaId": 181436,
                        "areaName": "海门市",
                        "parent_id": 179635,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 320700000000,
                "areaId": 181766,
                "areaName": "连云港市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 320701000000,
                        "areaId": 181767,
                        "areaName": "市辖区",
                        "parent_id": 181766,
                        "level": 2
                    },
                    {
                        "areaCode": 320703000000,
                        "areaId": 181768,
                        "areaName": "连云区",
                        "parent_id": 181766,
                        "level": 2
                    },
                    {
                        "areaCode": 320706000000,
                        "areaId": 181852,
                        "areaName": "海州区",
                        "parent_id": 181766,
                        "level": 2
                    },
                    {
                        "areaCode": 320707000000,
                        "areaId": 182070,
                        "areaName": "赣榆区",
                        "parent_id": 181766,
                        "level": 2
                    },
                    {
                        "areaCode": 320722000000,
                        "areaId": 182560,
                        "areaName": "东海县",
                        "parent_id": 181766,
                        "level": 2
                    },
                    {
                        "areaCode": 320723000000,
                        "areaId": 182910,
                        "areaName": "灌云县",
                        "parent_id": 181766,
                        "level": 2
                    },
                    {
                        "areaCode": 320724000000,
                        "areaId": 183267,
                        "areaName": "灌南县",
                        "parent_id": 181766,
                        "level": 2
                    },
                    {
                        "areaCode": 320771000000,
                        "areaId": 183517,
                        "areaName": "连云港经济技术开发区",
                        "parent_id": 181766,
                        "level": 2
                    },
                    {
                        "areaCode": 320772000000,
                        "areaId": 183558,
                        "areaName": "连云港高新技术产业开发区",
                        "parent_id": 181766,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 320800000000,
                "areaId": 183589,
                "areaName": "淮安市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 320801000000,
                        "areaId": 183590,
                        "areaName": "市辖区",
                        "parent_id": 183589,
                        "level": 2
                    },
                    {
                        "areaCode": 320803000000,
                        "areaId": 183591,
                        "areaName": "淮安区",
                        "parent_id": 183589,
                        "level": 2
                    },
                    {
                        "areaCode": 320804000000,
                        "areaId": 183963,
                        "areaName": "淮阴区",
                        "parent_id": 183589,
                        "level": 2
                    },
                    {
                        "areaCode": 320812000000,
                        "areaId": 184247,
                        "areaName": "清江浦区",
                        "parent_id": 183589,
                        "level": 2
                    },
                    {
                        "areaCode": 320813000000,
                        "areaId": 184393,
                        "areaName": "洪泽区",
                        "parent_id": 183589,
                        "level": 2
                    },
                    {
                        "areaCode": 320826000000,
                        "areaId": 184527,
                        "areaName": "涟水县",
                        "parent_id": 183589,
                        "level": 2
                    },
                    {
                        "areaCode": 320830000000,
                        "areaId": 184938,
                        "areaName": "盱眙县",
                        "parent_id": 183589,
                        "level": 2
                    },
                    {
                        "areaCode": 320831000000,
                        "areaId": 185218,
                        "areaName": "金湖县",
                        "parent_id": 183589,
                        "level": 2
                    },
                    {
                        "areaCode": 320871000000,
                        "areaId": 185384,
                        "areaName": "淮安经济技术开发区",
                        "parent_id": 183589,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 320900000000,
                "areaId": 185480,
                "areaName": "盐城市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 320901000000,
                        "areaId": 185481,
                        "areaName": "市辖区",
                        "parent_id": 185480,
                        "level": 2
                    },
                    {
                        "areaCode": 320902000000,
                        "areaId": 185482,
                        "areaName": "亭湖区",
                        "parent_id": 185480,
                        "level": 2
                    },
                    {
                        "areaCode": 320903000000,
                        "areaId": 185704,
                        "areaName": "盐都区",
                        "parent_id": 185480,
                        "level": 2
                    },
                    {
                        "areaCode": 320904000000,
                        "areaId": 185990,
                        "areaName": "大丰区",
                        "parent_id": 185480,
                        "level": 2
                    },
                    {
                        "areaCode": 320921000000,
                        "areaId": 186276,
                        "areaName": "响水县",
                        "parent_id": 185480,
                        "level": 2
                    },
                    {
                        "areaCode": 320922000000,
                        "areaId": 186452,
                        "areaName": "滨海县",
                        "parent_id": 185480,
                        "level": 2
                    },
                    {
                        "areaCode": 320923000000,
                        "areaId": 186772,
                        "areaName": "阜宁县",
                        "parent_id": 185480,
                        "level": 2
                    },
                    {
                        "areaCode": 320924000000,
                        "areaId": 187131,
                        "areaName": "射阳县",
                        "parent_id": 185480,
                        "level": 2
                    },
                    {
                        "areaCode": 320925000000,
                        "areaId": 187425,
                        "areaName": "建湖县",
                        "parent_id": 185480,
                        "level": 2
                    },
                    {
                        "areaCode": 320971000000,
                        "areaId": 187687,
                        "areaName": "盐城经济技术开发区",
                        "parent_id": 185480,
                        "level": 2
                    },
                    {
                        "areaCode": 320981000000,
                        "areaId": 187724,
                        "areaName": "东台市",
                        "parent_id": 185480,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 321000000000,
                "areaId": 188177,
                "areaName": "扬州市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 321001000000,
                        "areaId": 188178,
                        "areaName": "市辖区",
                        "parent_id": 188177,
                        "level": 2
                    },
                    {
                        "areaCode": 321002000000,
                        "areaId": 188179,
                        "areaName": "广陵区",
                        "parent_id": 188177,
                        "level": 2
                    },
                    {
                        "areaCode": 321003000000,
                        "areaId": 188337,
                        "areaName": "邗江区",
                        "parent_id": 188177,
                        "level": 2
                    },
                    {
                        "areaCode": 321012000000,
                        "areaId": 188533,
                        "areaName": "江都区",
                        "parent_id": 188177,
                        "level": 2
                    },
                    {
                        "areaCode": 321023000000,
                        "areaId": 188883,
                        "areaName": "宝应县",
                        "parent_id": 188177,
                        "level": 2
                    },
                    {
                        "areaCode": 321071000000,
                        "areaId": 189181,
                        "areaName": "扬州经济技术开发区",
                        "parent_id": 188177,
                        "level": 2
                    },
                    {
                        "areaCode": 321081000000,
                        "areaId": 189244,
                        "areaName": "仪征市",
                        "parent_id": 188177,
                        "level": 2
                    },
                    {
                        "areaCode": 321084000000,
                        "areaId": 189458,
                        "areaName": "高邮市",
                        "parent_id": 188177,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 321100000000,
                "areaId": 189700,
                "areaName": "镇江市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 321101000000,
                        "areaId": 189701,
                        "areaName": "市辖区",
                        "parent_id": 189700,
                        "level": 2
                    },
                    {
                        "areaCode": 321102000000,
                        "areaId": 189702,
                        "areaName": "京口区",
                        "parent_id": 189700,
                        "level": 2
                    },
                    {
                        "areaCode": 321111000000,
                        "areaId": 189777,
                        "areaName": "润州区",
                        "parent_id": 189700,
                        "level": 2
                    },
                    {
                        "areaCode": 321112000000,
                        "areaId": 189859,
                        "areaName": "丹徒区",
                        "parent_id": 189700,
                        "level": 2
                    },
                    {
                        "areaCode": 321171000000,
                        "areaId": 189961,
                        "areaName": "镇江新区",
                        "parent_id": 189700,
                        "level": 2
                    },
                    {
                        "areaCode": 321181000000,
                        "areaId": 190026,
                        "areaName": "丹阳市",
                        "parent_id": 189700,
                        "level": 2
                    },
                    {
                        "areaCode": 321182000000,
                        "areaId": 190257,
                        "areaName": "扬中市",
                        "parent_id": 189700,
                        "level": 2
                    },
                    {
                        "areaCode": 321183000000,
                        "areaId": 190343,
                        "areaName": "句容市",
                        "parent_id": 189700,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 321200000000,
                "areaId": 190549,
                "areaName": "泰州市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 321201000000,
                        "areaId": 190550,
                        "areaName": "市辖区",
                        "parent_id": 190549,
                        "level": 2
                    },
                    {
                        "areaCode": 321202000000,
                        "areaId": 190551,
                        "areaName": "海陵区",
                        "parent_id": 190549,
                        "level": 2
                    },
                    {
                        "areaCode": 321203000000,
                        "areaId": 190706,
                        "areaName": "高港区",
                        "parent_id": 190549,
                        "level": 2
                    },
                    {
                        "areaCode": 321204000000,
                        "areaId": 190807,
                        "areaName": "姜堰区",
                        "parent_id": 190549,
                        "level": 2
                    },
                    {
                        "areaCode": 321271000000,
                        "areaId": 191143,
                        "areaName": "泰州医药高新技术产业开发区",
                        "parent_id": 190549,
                        "level": 2
                    },
                    {
                        "areaCode": 321281000000,
                        "areaId": 191215,
                        "areaName": "兴化市",
                        "parent_id": 190549,
                        "level": 2
                    },
                    {
                        "areaCode": 321282000000,
                        "areaId": 191932,
                        "areaName": "靖江市",
                        "parent_id": 190549,
                        "level": 2
                    },
                    {
                        "areaCode": 321283000000,
                        "areaId": 192208,
                        "areaName": "泰兴市",
                        "parent_id": 190549,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 321300000000,
                "areaId": 192582,
                "areaName": "宿迁市",
                "parent_id": 170308,
                "level": 1,
                "children": [
                    {
                        "areaCode": 321301000000,
                        "areaId": 192583,
                        "areaName": "市辖区",
                        "parent_id": 192582,
                        "level": 2
                    },
                    {
                        "areaCode": 321302000000,
                        "areaId": 192584,
                        "areaName": "宿城区",
                        "parent_id": 192582,
                        "level": 2
                    },
                    {
                        "areaCode": 321311000000,
                        "areaId": 192817,
                        "areaName": "宿豫区",
                        "parent_id": 192582,
                        "level": 2
                    },
                    {
                        "areaCode": 321322000000,
                        "areaId": 193030,
                        "areaName": "沭阳县",
                        "parent_id": 192582,
                        "level": 2
                    },
                    {
                        "areaCode": 321323000000,
                        "areaId": 193557,
                        "areaName": "泗阳县",
                        "parent_id": 192582,
                        "level": 2
                    },
                    {
                        "areaCode": 321324000000,
                        "areaId": 193822,
                        "areaName": "泗洪县",
                        "parent_id": 192582,
                        "level": 2
                    },
                    {
                        "areaCode": 321371000000,
                        "areaId": 194199,
                        "areaName": "宿迁经济技术开发区",
                        "parent_id": 192582,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 330000000000,
        "areaId": 194236,
        "areaName": "浙江省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 330100000000,
                "areaId": 194237,
                "areaName": "杭州市",
                "parent_id": 194236,
                "level": 1,
                "children": [
                    {
                        "areaCode": 330101000000,
                        "areaId": 194238,
                        "areaName": "市辖区",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330102000000,
                        "areaId": 194239,
                        "areaName": "上城区",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330103000000,
                        "areaId": 194300,
                        "areaName": "下城区",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330104000000,
                        "areaId": 194383,
                        "areaName": "江干区",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330105000000,
                        "areaId": 194579,
                        "areaName": "拱墅区",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330106000000,
                        "areaId": 194689,
                        "areaName": "西湖区",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330108000000,
                        "areaId": 194908,
                        "areaName": "滨江区",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330109000000,
                        "areaId": 194972,
                        "areaName": "萧山区",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330110000000,
                        "areaId": 195609,
                        "areaName": "余杭区",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330111000000,
                        "areaId": 195978,
                        "areaName": "富阳区",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330112000000,
                        "areaId": 196310,
                        "areaName": "临安区",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330122000000,
                        "areaId": 196645,
                        "areaName": "桐庐县",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330127000000,
                        "areaId": 196864,
                        "areaName": "淳安县",
                        "parent_id": 194237,
                        "level": 2
                    },
                    {
                        "areaCode": 330182000000,
                        "areaId": 197327,
                        "areaName": "建德市",
                        "parent_id": 194237,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 330200000000,
                "areaId": 197621,
                "areaName": "宁波市",
                "parent_id": 194236,
                "level": 1,
                "children": [
                    {
                        "areaCode": 330201000000,
                        "areaId": 197622,
                        "areaName": "市辖区",
                        "parent_id": 197621,
                        "level": 2
                    },
                    {
                        "areaCode": 330203000000,
                        "areaId": 197623,
                        "areaName": "海曙区",
                        "parent_id": 197621,
                        "level": 2
                    },
                    {
                        "areaCode": 330205000000,
                        "areaId": 197912,
                        "areaName": "江北区",
                        "parent_id": 197621,
                        "level": 2
                    },
                    {
                        "areaCode": 330206000000,
                        "areaId": 198090,
                        "areaName": "北仑区",
                        "parent_id": 197621,
                        "level": 2
                    },
                    {
                        "areaCode": 330211000000,
                        "areaId": 198364,
                        "areaName": "镇海区",
                        "parent_id": 197621,
                        "level": 2
                    },
                    {
                        "areaCode": 330212000000,
                        "areaId": 198468,
                        "areaName": "鄞州区",
                        "parent_id": 197621,
                        "level": 2
                    },
                    {
                        "areaCode": 330213000000,
                        "areaId": 198916,
                        "areaName": "奉化区",
                        "parent_id": 197621,
                        "level": 2
                    },
                    {
                        "areaCode": 330225000000,
                        "areaId": 199326,
                        "areaName": "象山县",
                        "parent_id": 197621,
                        "level": 2
                    },
                    {
                        "areaCode": 330226000000,
                        "areaId": 199885,
                        "areaName": "宁海县",
                        "parent_id": 197621,
                        "level": 2
                    },
                    {
                        "areaCode": 330281000000,
                        "areaId": 200314,
                        "areaName": "余姚市",
                        "parent_id": 197621,
                        "level": 2
                    },
                    {
                        "areaCode": 330282000000,
                        "areaId": 200661,
                        "areaName": "慈溪市",
                        "parent_id": 197621,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 330300000000,
                "areaId": 201072,
                "areaName": "温州市",
                "parent_id": 194236,
                "level": 1,
                "children": [
                    {
                        "areaCode": 330301000000,
                        "areaId": 201073,
                        "areaName": "市辖区",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330302000000,
                        "areaId": 201074,
                        "areaName": "鹿城区",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330303000000,
                        "areaId": 201313,
                        "areaName": "龙湾区",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330304000000,
                        "areaId": 201432,
                        "areaName": "瓯海区",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330305000000,
                        "areaId": 201730,
                        "areaName": "洞头区",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330324000000,
                        "areaId": 201841,
                        "areaName": "永嘉县",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330326000000,
                        "areaId": 202804,
                        "areaName": "平阳县",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330327000000,
                        "areaId": 203462,
                        "areaName": "苍南县",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330328000000,
                        "areaId": 204363,
                        "areaName": "文成县",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330329000000,
                        "areaId": 204780,
                        "areaName": "泰顺县",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330371000000,
                        "areaId": 205115,
                        "areaName": "温州经济技术开发区",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330381000000,
                        "areaId": 205169,
                        "areaName": "瑞安市",
                        "parent_id": 201072,
                        "level": 2
                    },
                    {
                        "areaCode": 330382000000,
                        "areaId": 206151,
                        "areaName": "乐清市",
                        "parent_id": 201072,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 330400000000,
                "areaId": 207139,
                "areaName": "嘉兴市",
                "parent_id": 194236,
                "level": 1,
                "children": [
                    {
                        "areaCode": 330401000000,
                        "areaId": 207140,
                        "areaName": "市辖区",
                        "parent_id": 207139,
                        "level": 2
                    },
                    {
                        "areaCode": 330402000000,
                        "areaId": 207141,
                        "areaName": "南湖区",
                        "parent_id": 207139,
                        "level": 2
                    },
                    {
                        "areaCode": 330411000000,
                        "areaId": 207296,
                        "areaName": "秀洲区",
                        "parent_id": 207139,
                        "level": 2
                    },
                    {
                        "areaCode": 330421000000,
                        "areaId": 207466,
                        "areaName": "嘉善县",
                        "parent_id": 207139,
                        "level": 2
                    },
                    {
                        "areaCode": 330424000000,
                        "areaId": 207630,
                        "areaName": "海盐县",
                        "parent_id": 207139,
                        "level": 2
                    },
                    {
                        "areaCode": 330481000000,
                        "areaId": 207773,
                        "areaName": "海宁市",
                        "parent_id": 207139,
                        "level": 2
                    },
                    {
                        "areaCode": 330482000000,
                        "areaId": 208009,
                        "areaName": "平湖市",
                        "parent_id": 207139,
                        "level": 2
                    },
                    {
                        "areaCode": 330483000000,
                        "areaId": 208154,
                        "areaName": "桐乡市",
                        "parent_id": 207139,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 330500000000,
                "areaId": 208377,
                "areaName": "湖州市",
                "parent_id": 194236,
                "level": 1,
                "children": [
                    {
                        "areaCode": 330501000000,
                        "areaId": 208378,
                        "areaName": "市辖区",
                        "parent_id": 208377,
                        "level": 2
                    },
                    {
                        "areaCode": 330502000000,
                        "areaId": 208379,
                        "areaName": "吴兴区",
                        "parent_id": 208377,
                        "level": 2
                    },
                    {
                        "areaCode": 330503000000,
                        "areaId": 208733,
                        "areaName": "南浔区",
                        "parent_id": 208377,
                        "level": 2
                    },
                    {
                        "areaCode": 330521000000,
                        "areaId": 209001,
                        "areaName": "德清县",
                        "parent_id": 208377,
                        "level": 2
                    },
                    {
                        "areaCode": 330522000000,
                        "areaId": 209195,
                        "areaName": "长兴县",
                        "parent_id": 208377,
                        "level": 2
                    },
                    {
                        "areaCode": 330523000000,
                        "areaId": 209493,
                        "areaName": "安吉县",
                        "parent_id": 208377,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 330600000000,
                "areaId": 209718,
                "areaName": "绍兴市",
                "parent_id": 194236,
                "level": 1,
                "children": [
                    {
                        "areaCode": 330601000000,
                        "areaId": 209719,
                        "areaName": "市辖区",
                        "parent_id": 209718,
                        "level": 2
                    },
                    {
                        "areaCode": 330602000000,
                        "areaId": 209720,
                        "areaName": "越城区",
                        "parent_id": 209718,
                        "level": 2
                    },
                    {
                        "areaCode": 330603000000,
                        "areaId": 210144,
                        "areaName": "柯桥区",
                        "parent_id": 209718,
                        "level": 2
                    },
                    {
                        "areaCode": 330604000000,
                        "areaId": 210517,
                        "areaName": "上虞区",
                        "parent_id": 209718,
                        "level": 2
                    },
                    {
                        "areaCode": 330624000000,
                        "areaId": 210984,
                        "areaName": "新昌县",
                        "parent_id": 209718,
                        "level": 2
                    },
                    {
                        "areaCode": 330681000000,
                        "areaId": 211441,
                        "areaName": "诸暨市",
                        "parent_id": 209718,
                        "level": 2
                    },
                    {
                        "areaCode": 330683000000,
                        "areaId": 212014,
                        "areaName": "嵊州市",
                        "parent_id": 209718,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 330700000000,
                "areaId": 212531,
                "areaName": "金华市",
                "parent_id": 194236,
                "level": 1,
                "children": [
                    {
                        "areaCode": 330701000000,
                        "areaId": 212532,
                        "areaName": "市辖区",
                        "parent_id": 212531,
                        "level": 2
                    },
                    {
                        "areaCode": 330702000000,
                        "areaId": 212533,
                        "areaName": "婺城区",
                        "parent_id": 212531,
                        "level": 2
                    },
                    {
                        "areaCode": 330703000000,
                        "areaId": 213229,
                        "areaName": "金东区",
                        "parent_id": 212531,
                        "level": 2
                    },
                    {
                        "areaCode": 330723000000,
                        "areaId": 213750,
                        "areaName": "武义县",
                        "parent_id": 212531,
                        "level": 2
                    },
                    {
                        "areaCode": 330726000000,
                        "areaId": 214323,
                        "areaName": "浦江县",
                        "parent_id": 212531,
                        "level": 2
                    },
                    {
                        "areaCode": 330727000000,
                        "areaId": 214768,
                        "areaName": "磐安县",
                        "parent_id": 212531,
                        "level": 2
                    },
                    {
                        "areaCode": 330781000000,
                        "areaId": 215159,
                        "areaName": "兰溪市",
                        "parent_id": 212531,
                        "level": 2
                    },
                    {
                        "areaCode": 330782000000,
                        "areaId": 215533,
                        "areaName": "义乌市",
                        "parent_id": 212531,
                        "level": 2
                    },
                    {
                        "areaCode": 330783000000,
                        "areaId": 216334,
                        "areaName": "东阳市",
                        "parent_id": 212531,
                        "level": 2
                    },
                    {
                        "areaCode": 330784000000,
                        "areaId": 216741,
                        "areaName": "永康市",
                        "parent_id": 212531,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 330800000000,
                "areaId": 217516,
                "areaName": "衢州市",
                "parent_id": 194236,
                "level": 1,
                "children": [
                    {
                        "areaCode": 330801000000,
                        "areaId": 217517,
                        "areaName": "市辖区",
                        "parent_id": 217516,
                        "level": 2
                    },
                    {
                        "areaCode": 330802000000,
                        "areaId": 217518,
                        "areaName": "柯城区",
                        "parent_id": 217516,
                        "level": 2
                    },
                    {
                        "areaCode": 330803000000,
                        "areaId": 217794,
                        "areaName": "衢江区",
                        "parent_id": 217516,
                        "level": 2
                    },
                    {
                        "areaCode": 330822000000,
                        "areaId": 218100,
                        "areaName": "常山县",
                        "parent_id": 217516,
                        "level": 2
                    },
                    {
                        "areaCode": 330824000000,
                        "areaId": 218308,
                        "areaName": "开化县",
                        "parent_id": 217516,
                        "level": 2
                    },
                    {
                        "areaCode": 330825000000,
                        "areaId": 218589,
                        "areaName": "龙游县",
                        "parent_id": 217516,
                        "level": 2
                    },
                    {
                        "areaCode": 330881000000,
                        "areaId": 218881,
                        "areaName": "江山市",
                        "parent_id": 217516,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 330900000000,
                "areaId": 219212,
                "areaName": "舟山市",
                "parent_id": 194236,
                "level": 1,
                "children": [
                    {
                        "areaCode": 330901000000,
                        "areaId": 219213,
                        "areaName": "市辖区",
                        "parent_id": 219212,
                        "level": 2
                    },
                    {
                        "areaCode": 330902000000,
                        "areaId": 219214,
                        "areaName": "定海区",
                        "parent_id": 219212,
                        "level": 2
                    },
                    {
                        "areaCode": 330903000000,
                        "areaId": 219399,
                        "areaName": "普陀区",
                        "parent_id": 219212,
                        "level": 2
                    },
                    {
                        "areaCode": 330921000000,
                        "areaId": 219529,
                        "areaName": "岱山县",
                        "parent_id": 219212,
                        "level": 2
                    },
                    {
                        "areaCode": 330922000000,
                        "areaId": 219622,
                        "areaName": "嵊泗县",
                        "parent_id": 219212,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 331000000000,
                "areaId": 219668,
                "areaName": "台州市",
                "parent_id": 194236,
                "level": 1,
                "children": [
                    {
                        "areaCode": 331001000000,
                        "areaId": 219669,
                        "areaName": "市辖区",
                        "parent_id": 219668,
                        "level": 2
                    },
                    {
                        "areaCode": 331002000000,
                        "areaId": 219670,
                        "areaName": "椒江区",
                        "parent_id": 219668,
                        "level": 2
                    },
                    {
                        "areaCode": 331003000000,
                        "areaId": 220010,
                        "areaName": "黄岩区",
                        "parent_id": 219668,
                        "level": 2
                    },
                    {
                        "areaCode": 331004000000,
                        "areaId": 220548,
                        "areaName": "路桥区",
                        "parent_id": 219668,
                        "level": 2
                    },
                    {
                        "areaCode": 331022000000,
                        "areaId": 220892,
                        "areaName": "三门县",
                        "parent_id": 219668,
                        "level": 2
                    },
                    {
                        "areaCode": 331023000000,
                        "areaId": 221428,
                        "areaName": "天台县",
                        "parent_id": 219668,
                        "level": 2
                    },
                    {
                        "areaCode": 331024000000,
                        "areaId": 222056,
                        "areaName": "仙居县",
                        "parent_id": 219668,
                        "level": 2
                    },
                    {
                        "areaCode": 331081000000,
                        "areaId": 222503,
                        "areaName": "温岭市",
                        "parent_id": 219668,
                        "level": 2
                    },
                    {
                        "areaCode": 331082000000,
                        "areaId": 223443,
                        "areaName": "临海市",
                        "parent_id": 219668,
                        "level": 2
                    },
                    {
                        "areaCode": 331083000000,
                        "areaId": 224498,
                        "areaName": "玉环市",
                        "parent_id": 219668,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 331100000000,
                "areaId": 224830,
                "areaName": "丽水市",
                "parent_id": 194236,
                "level": 1,
                "children": [
                    {
                        "areaCode": 331101000000,
                        "areaId": 224831,
                        "areaName": "市辖区",
                        "parent_id": 224830,
                        "level": 2
                    },
                    {
                        "areaCode": 331102000000,
                        "areaId": 224832,
                        "areaName": "莲都区",
                        "parent_id": 224830,
                        "level": 2
                    },
                    {
                        "areaCode": 331121000000,
                        "areaId": 225123,
                        "areaName": "青田县",
                        "parent_id": 224830,
                        "level": 2
                    },
                    {
                        "areaCode": 331122000000,
                        "areaId": 225601,
                        "areaName": "缙云县",
                        "parent_id": 224830,
                        "level": 2
                    },
                    {
                        "areaCode": 331123000000,
                        "areaId": 225879,
                        "areaName": "遂昌县",
                        "parent_id": 224830,
                        "level": 2
                    },
                    {
                        "areaCode": 331124000000,
                        "areaId": 226111,
                        "areaName": "松阳县",
                        "parent_id": 224830,
                        "level": 2
                    },
                    {
                        "areaCode": 331125000000,
                        "areaId": 226545,
                        "areaName": "云和县",
                        "parent_id": 224830,
                        "level": 2
                    },
                    {
                        "areaCode": 331126000000,
                        "areaId": 226733,
                        "areaName": "庆元县",
                        "parent_id": 224830,
                        "level": 2
                    },
                    {
                        "areaCode": 331127000000,
                        "areaId": 227106,
                        "areaName": "景宁畲族自治县",
                        "parent_id": 224830,
                        "level": 2
                    },
                    {
                        "areaCode": 331181000000,
                        "areaId": 227393,
                        "areaName": "龙泉市",
                        "parent_id": 224830,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 340000000000,
        "areaId": 227870,
        "areaName": "安徽省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 340100000000,
                "areaId": 227871,
                "areaName": "合肥市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 340101000000,
                        "areaId": 227872,
                        "areaName": "市辖区",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340102000000,
                        "areaId": 227873,
                        "areaName": "瑶海区",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340103000000,
                        "areaId": 227976,
                        "areaName": "庐阳区",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340104000000,
                        "areaId": 228060,
                        "areaName": "蜀山区",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340111000000,
                        "areaId": 228168,
                        "areaName": "包河区",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340121000000,
                        "areaId": 228293,
                        "areaName": "长丰县",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340122000000,
                        "areaId": 228583,
                        "areaName": "肥东县",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340123000000,
                        "areaId": 228935,
                        "areaName": "肥西县",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340124000000,
                        "areaId": 229228,
                        "areaName": "庐江县",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340171000000,
                        "areaId": 229478,
                        "areaName": "合肥高新技术产业开发区",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340172000000,
                        "areaId": 229507,
                        "areaName": "合肥经济技术开发区",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340173000000,
                        "areaId": 229567,
                        "areaName": "合肥新站高新技术产业开发区",
                        "parent_id": 227871,
                        "level": 2
                    },
                    {
                        "areaCode": 340181000000,
                        "areaId": 229624,
                        "areaName": "巢湖市",
                        "parent_id": 227871,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 340200000000,
                "areaId": 229844,
                "areaName": "芜湖市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 340201000000,
                        "areaId": 229845,
                        "areaName": "市辖区",
                        "parent_id": 229844,
                        "level": 2
                    },
                    {
                        "areaCode": 340202000000,
                        "areaId": 229846,
                        "areaName": "镜湖区",
                        "parent_id": 229844,
                        "level": 2
                    },
                    {
                        "areaCode": 340203000000,
                        "areaId": 229925,
                        "areaName": "弋江区",
                        "parent_id": 229844,
                        "level": 2
                    },
                    {
                        "areaCode": 340207000000,
                        "areaId": 229985,
                        "areaName": "鸠江区",
                        "parent_id": 229844,
                        "level": 2
                    },
                    {
                        "areaCode": 340208000000,
                        "areaId": 230110,
                        "areaName": "三山区",
                        "parent_id": 229844,
                        "level": 2
                    },
                    {
                        "areaCode": 340221000000,
                        "areaId": 230161,
                        "areaName": "芜湖县",
                        "parent_id": 229844,
                        "level": 2
                    },
                    {
                        "areaCode": 340222000000,
                        "areaId": 230259,
                        "areaName": "繁昌县",
                        "parent_id": 229844,
                        "level": 2
                    },
                    {
                        "areaCode": 340223000000,
                        "areaId": 230363,
                        "areaName": "南陵县",
                        "parent_id": 229844,
                        "level": 2
                    },
                    {
                        "areaCode": 340225000000,
                        "areaId": 230551,
                        "areaName": "无为县",
                        "parent_id": 229844,
                        "level": 2
                    },
                    {
                        "areaCode": 340271000000,
                        "areaId": 230850,
                        "areaName": "芜湖经济技术开发区",
                        "parent_id": 229844,
                        "level": 2
                    },
                    {
                        "areaCode": 340272000000,
                        "areaId": 230876,
                        "areaName": "安徽芜湖长江大桥经济开发区",
                        "parent_id": 229844,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 340300000000,
                "areaId": 230885,
                "areaName": "蚌埠市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 340301000000,
                        "areaId": 230886,
                        "areaName": "市辖区",
                        "parent_id": 230885,
                        "level": 2
                    },
                    {
                        "areaCode": 340302000000,
                        "areaId": 230887,
                        "areaName": "龙子湖区",
                        "parent_id": 230885,
                        "level": 2
                    },
                    {
                        "areaCode": 340303000000,
                        "areaId": 230954,
                        "areaName": "蚌山区",
                        "parent_id": 230885,
                        "level": 2
                    },
                    {
                        "areaCode": 340304000000,
                        "areaId": 231002,
                        "areaName": "禹会区",
                        "parent_id": 230885,
                        "level": 2
                    },
                    {
                        "areaCode": 340311000000,
                        "areaId": 231078,
                        "areaName": "淮上区",
                        "parent_id": 230885,
                        "level": 2
                    },
                    {
                        "areaCode": 340321000000,
                        "areaId": 231170,
                        "areaName": "怀远县",
                        "parent_id": 230885,
                        "level": 2
                    },
                    {
                        "areaCode": 340322000000,
                        "areaId": 231556,
                        "areaName": "五河县",
                        "parent_id": 230885,
                        "level": 2
                    },
                    {
                        "areaCode": 340323000000,
                        "areaId": 231789,
                        "areaName": "固镇县",
                        "parent_id": 230885,
                        "level": 2
                    },
                    {
                        "areaCode": 340371000000,
                        "areaId": 232031,
                        "areaName": "蚌埠市高新技术开发区",
                        "parent_id": 230885,
                        "level": 2
                    },
                    {
                        "areaCode": 340372000000,
                        "areaId": 232039,
                        "areaName": "蚌埠市经济开发区",
                        "parent_id": 230885,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 340400000000,
                "areaId": 232085,
                "areaName": "淮南市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 340401000000,
                        "areaId": 232086,
                        "areaName": "市辖区",
                        "parent_id": 232085,
                        "level": 2
                    },
                    {
                        "areaCode": 340402000000,
                        "areaId": 232087,
                        "areaName": "大通区",
                        "parent_id": 232085,
                        "level": 2
                    },
                    {
                        "areaCode": 340403000000,
                        "areaId": 232165,
                        "areaName": "田家庵区",
                        "parent_id": 232085,
                        "level": 2
                    },
                    {
                        "areaCode": 340404000000,
                        "areaId": 232311,
                        "areaName": "谢家集区",
                        "parent_id": 232085,
                        "level": 2
                    },
                    {
                        "areaCode": 340405000000,
                        "areaId": 232418,
                        "areaName": "八公山区",
                        "parent_id": 232085,
                        "level": 2
                    },
                    {
                        "areaCode": 340406000000,
                        "areaId": 232469,
                        "areaName": "潘集区",
                        "parent_id": 232085,
                        "level": 2
                    },
                    {
                        "areaCode": 340421000000,
                        "areaId": 232661,
                        "areaName": "凤台县",
                        "parent_id": 232085,
                        "level": 2
                    },
                    {
                        "areaCode": 340422000000,
                        "areaId": 232984,
                        "areaName": "寿县",
                        "parent_id": 232085,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 340500000000,
                "areaId": 233301,
                "areaName": "马鞍山市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 340501000000,
                        "areaId": 233302,
                        "areaName": "市辖区",
                        "parent_id": 233301,
                        "level": 2
                    },
                    {
                        "areaCode": 340503000000,
                        "areaId": 233303,
                        "areaName": "花山区",
                        "parent_id": 233301,
                        "level": 2
                    },
                    {
                        "areaCode": 340504000000,
                        "areaId": 233381,
                        "areaName": "雨山区",
                        "parent_id": 233301,
                        "level": 2
                    },
                    {
                        "areaCode": 340506000000,
                        "areaId": 233451,
                        "areaName": "博望区",
                        "parent_id": 233301,
                        "level": 2
                    },
                    {
                        "areaCode": 340521000000,
                        "areaId": 233500,
                        "areaName": "当涂县",
                        "parent_id": 233301,
                        "level": 2
                    },
                    {
                        "areaCode": 340522000000,
                        "areaId": 233672,
                        "areaName": "含山县",
                        "parent_id": 233301,
                        "level": 2
                    },
                    {
                        "areaCode": 340523000000,
                        "areaId": 233804,
                        "areaName": "和县",
                        "parent_id": 233301,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 340600000000,
                "areaId": 233935,
                "areaName": "淮北市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 340601000000,
                        "areaId": 233936,
                        "areaName": "市辖区",
                        "parent_id": 233935,
                        "level": 2
                    },
                    {
                        "areaCode": 340602000000,
                        "areaId": 233937,
                        "areaName": "杜集区",
                        "parent_id": 233935,
                        "level": 2
                    },
                    {
                        "areaCode": 340603000000,
                        "areaId": 233998,
                        "areaName": "相山区",
                        "parent_id": 233935,
                        "level": 2
                    },
                    {
                        "areaCode": 340604000000,
                        "areaId": 234095,
                        "areaName": "烈山区",
                        "parent_id": 233935,
                        "level": 2
                    },
                    {
                        "areaCode": 340621000000,
                        "areaId": 234167,
                        "areaName": "濉溪县",
                        "parent_id": 233935,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 340700000000,
                "areaId": 234428,
                "areaName": "铜陵市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 340701000000,
                        "areaId": 234429,
                        "areaName": "市辖区",
                        "parent_id": 234428,
                        "level": 2
                    },
                    {
                        "areaCode": 340705000000,
                        "areaId": 234430,
                        "areaName": "铜官区",
                        "parent_id": 234428,
                        "level": 2
                    },
                    {
                        "areaCode": 340706000000,
                        "areaId": 234490,
                        "areaName": "义安区",
                        "parent_id": 234428,
                        "level": 2
                    },
                    {
                        "areaCode": 340711000000,
                        "areaId": 234619,
                        "areaName": "郊区",
                        "parent_id": 234428,
                        "level": 2
                    },
                    {
                        "areaCode": 340722000000,
                        "areaId": 234664,
                        "areaName": "枞阳县",
                        "parent_id": 234428,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 340800000000,
                "areaId": 234947,
                "areaName": "安庆市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 340801000000,
                        "areaId": 234948,
                        "areaName": "市辖区",
                        "parent_id": 234947,
                        "level": 2
                    },
                    {
                        "areaCode": 340802000000,
                        "areaId": 234949,
                        "areaName": "迎江区",
                        "parent_id": 234947,
                        "level": 2
                    },
                    {
                        "areaCode": 340803000000,
                        "areaId": 235006,
                        "areaName": "大观区",
                        "parent_id": 234947,
                        "level": 2
                    },
                    {
                        "areaCode": 340811000000,
                        "areaId": 235094,
                        "areaName": "宜秀区",
                        "parent_id": 234947,
                        "level": 2
                    },
                    {
                        "areaCode": 340822000000,
                        "areaId": 235160,
                        "areaName": "怀宁县",
                        "parent_id": 234947,
                        "level": 2
                    },
                    {
                        "areaCode": 340824000000,
                        "areaId": 235424,
                        "areaName": "潜山县",
                        "parent_id": 234947,
                        "level": 2
                    },
                    {
                        "areaCode": 340825000000,
                        "areaId": 235630,
                        "areaName": "太湖县",
                        "parent_id": 234947,
                        "level": 2
                    },
                    {
                        "areaCode": 340826000000,
                        "areaId": 235836,
                        "areaName": "宿松县",
                        "parent_id": 234947,
                        "level": 2
                    },
                    {
                        "areaCode": 340827000000,
                        "areaId": 236081,
                        "areaName": "望江县",
                        "parent_id": 234947,
                        "level": 2
                    },
                    {
                        "areaCode": 340828000000,
                        "areaId": 236231,
                        "areaName": "岳西县",
                        "parent_id": 234947,
                        "level": 2
                    },
                    {
                        "areaCode": 340871000000,
                        "areaId": 236446,
                        "areaName": "安徽安庆经济开发区",
                        "parent_id": 234947,
                        "level": 2
                    },
                    {
                        "areaCode": 340881000000,
                        "areaId": 236475,
                        "areaName": "桐城市",
                        "parent_id": 234947,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 341000000000,
                "areaId": 236716,
                "areaName": "黄山市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 341001000000,
                        "areaId": 236717,
                        "areaName": "市辖区",
                        "parent_id": 236716,
                        "level": 2
                    },
                    {
                        "areaCode": 341002000000,
                        "areaId": 236718,
                        "areaName": "屯溪区",
                        "parent_id": 236716,
                        "level": 2
                    },
                    {
                        "areaCode": 341003000000,
                        "areaId": 236801,
                        "areaName": "黄山区",
                        "parent_id": 236716,
                        "level": 2
                    },
                    {
                        "areaCode": 341004000000,
                        "areaId": 236903,
                        "areaName": "徽州区",
                        "parent_id": 236716,
                        "level": 2
                    },
                    {
                        "areaCode": 341021000000,
                        "areaId": 236967,
                        "areaName": "歙县",
                        "parent_id": 236716,
                        "level": 2
                    },
                    {
                        "areaCode": 341022000000,
                        "areaId": 237194,
                        "areaName": "休宁县",
                        "parent_id": 236716,
                        "level": 2
                    },
                    {
                        "areaCode": 341023000000,
                        "areaId": 237374,
                        "areaName": "黟县",
                        "parent_id": 236716,
                        "level": 2
                    },
                    {
                        "areaCode": 341024000000,
                        "areaId": 237453,
                        "areaName": "祁门县",
                        "parent_id": 236716,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 341100000000,
                "areaId": 237589,
                "areaName": "滁州市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 341101000000,
                        "areaId": 237590,
                        "areaName": "市辖区",
                        "parent_id": 237589,
                        "level": 2
                    },
                    {
                        "areaCode": 341102000000,
                        "areaId": 237591,
                        "areaName": "琅琊区",
                        "parent_id": 237589,
                        "level": 2
                    },
                    {
                        "areaCode": 341103000000,
                        "areaId": 237654,
                        "areaName": "南谯区",
                        "parent_id": 237589,
                        "level": 2
                    },
                    {
                        "areaCode": 341122000000,
                        "areaId": 237754,
                        "areaName": "来安县",
                        "parent_id": 237589,
                        "level": 2
                    },
                    {
                        "areaCode": 341124000000,
                        "areaId": 237915,
                        "areaName": "全椒县",
                        "parent_id": 237589,
                        "level": 2
                    },
                    {
                        "areaCode": 341125000000,
                        "areaId": 238041,
                        "areaName": "定远县",
                        "parent_id": 237589,
                        "level": 2
                    },
                    {
                        "areaCode": 341126000000,
                        "areaId": 238328,
                        "areaName": "凤阳县",
                        "parent_id": 237589,
                        "level": 2
                    },
                    {
                        "areaCode": 341171000000,
                        "areaId": 238593,
                        "areaName": "苏滁现代产业园",
                        "parent_id": 237589,
                        "level": 2
                    },
                    {
                        "areaCode": 341172000000,
                        "areaId": 238599,
                        "areaName": "滁州经济技术开发区",
                        "parent_id": 237589,
                        "level": 2
                    },
                    {
                        "areaCode": 341181000000,
                        "areaId": 238619,
                        "areaName": "天长市",
                        "parent_id": 237589,
                        "level": 2
                    },
                    {
                        "areaCode": 341182000000,
                        "areaId": 238812,
                        "areaName": "明光市",
                        "parent_id": 237589,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 341200000000,
                "areaId": 238990,
                "areaName": "阜阳市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 341201000000,
                        "areaId": 238991,
                        "areaName": "市辖区",
                        "parent_id": 238990,
                        "level": 2
                    },
                    {
                        "areaCode": 341202000000,
                        "areaId": 238992,
                        "areaName": "颍州区",
                        "parent_id": 238990,
                        "level": 2
                    },
                    {
                        "areaCode": 341203000000,
                        "areaId": 239153,
                        "areaName": "颍东区",
                        "parent_id": 238990,
                        "level": 2
                    },
                    {
                        "areaCode": 341204000000,
                        "areaId": 239285,
                        "areaName": "颍泉区",
                        "parent_id": 238990,
                        "level": 2
                    },
                    {
                        "areaCode": 341221000000,
                        "areaId": 239416,
                        "areaName": "临泉县",
                        "parent_id": 238990,
                        "level": 2
                    },
                    {
                        "areaCode": 341222000000,
                        "areaId": 239840,
                        "areaName": "太和县",
                        "parent_id": 238990,
                        "level": 2
                    },
                    {
                        "areaCode": 341225000000,
                        "areaId": 240187,
                        "areaName": "阜南县",
                        "parent_id": 238990,
                        "level": 2
                    },
                    {
                        "areaCode": 341226000000,
                        "areaId": 240548,
                        "areaName": "颍上县",
                        "parent_id": 238990,
                        "level": 2
                    },
                    {
                        "areaCode": 341271000000,
                        "areaId": 240930,
                        "areaName": "阜阳合肥现代产业园区",
                        "parent_id": 238990,
                        "level": 2
                    },
                    {
                        "areaCode": 341272000000,
                        "areaId": 240941,
                        "areaName": "阜阳经济技术开发区",
                        "parent_id": 238990,
                        "level": 2
                    },
                    {
                        "areaCode": 341282000000,
                        "areaId": 240959,
                        "areaName": "界首市",
                        "parent_id": 238990,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 341300000000,
                "areaId": 241138,
                "areaName": "宿州市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 341301000000,
                        "areaId": 241139,
                        "areaName": "市辖区",
                        "parent_id": 241138,
                        "level": 2
                    },
                    {
                        "areaCode": 341302000000,
                        "areaId": 241140,
                        "areaName": "埇桥区",
                        "parent_id": 241138,
                        "level": 2
                    },
                    {
                        "areaCode": 341321000000,
                        "areaId": 241572,
                        "areaName": "砀山县",
                        "parent_id": 241138,
                        "level": 2
                    },
                    {
                        "areaCode": 341322000000,
                        "areaId": 241756,
                        "areaName": "萧县",
                        "parent_id": 241138,
                        "level": 2
                    },
                    {
                        "areaCode": 341323000000,
                        "areaId": 242082,
                        "areaName": "灵璧县",
                        "parent_id": 241138,
                        "level": 2
                    },
                    {
                        "areaCode": 341324000000,
                        "areaId": 242413,
                        "areaName": "泗县",
                        "parent_id": 241138,
                        "level": 2
                    },
                    {
                        "areaCode": 341371000000,
                        "areaId": 242625,
                        "areaName": "宿州马鞍山现代产业园区",
                        "parent_id": 241138,
                        "level": 2
                    },
                    {
                        "areaCode": 341372000000,
                        "areaId": 242638,
                        "areaName": "宿州经济技术开发区",
                        "parent_id": 241138,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 341500000000,
                "areaId": 242654,
                "areaName": "六安市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 341501000000,
                        "areaId": 242655,
                        "areaName": "市辖区",
                        "parent_id": 242654,
                        "level": 2
                    },
                    {
                        "areaCode": 341502000000,
                        "areaId": 242656,
                        "areaName": "金安区",
                        "parent_id": 242654,
                        "level": 2
                    },
                    {
                        "areaCode": 341503000000,
                        "areaId": 243020,
                        "areaName": "裕安区",
                        "parent_id": 242654,
                        "level": 2
                    },
                    {
                        "areaCode": 341504000000,
                        "areaId": 243356,
                        "areaName": "叶集区",
                        "parent_id": 242654,
                        "level": 2
                    },
                    {
                        "areaCode": 341522000000,
                        "areaId": 243440,
                        "areaName": "霍邱县",
                        "parent_id": 242654,
                        "level": 2
                    },
                    {
                        "areaCode": 341523000000,
                        "areaId": 243886,
                        "areaName": "舒城县",
                        "parent_id": 242654,
                        "level": 2
                    },
                    {
                        "areaCode": 341524000000,
                        "areaId": 244335,
                        "areaName": "金寨县",
                        "parent_id": 242654,
                        "level": 2
                    },
                    {
                        "areaCode": 341525000000,
                        "areaId": 244591,
                        "areaName": "霍山县",
                        "parent_id": 242654,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 341600000000,
                "areaId": 244755,
                "areaName": "亳州市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 341601000000,
                        "areaId": 244756,
                        "areaName": "市辖区",
                        "parent_id": 244755,
                        "level": 2
                    },
                    {
                        "areaCode": 341602000000,
                        "areaId": 244757,
                        "areaName": "谯城区",
                        "parent_id": 244755,
                        "level": 2
                    },
                    {
                        "areaCode": 341621000000,
                        "areaId": 245087,
                        "areaName": "涡阳县",
                        "parent_id": 244755,
                        "level": 2
                    },
                    {
                        "areaCode": 341622000000,
                        "areaId": 245496,
                        "areaName": "蒙城县",
                        "parent_id": 244755,
                        "level": 2
                    },
                    {
                        "areaCode": 341623000000,
                        "areaId": 245811,
                        "areaName": "利辛县",
                        "parent_id": 244755,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 341700000000,
                "areaId": 246200,
                "areaName": "池州市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 341701000000,
                        "areaId": 246201,
                        "areaName": "市辖区",
                        "parent_id": 246200,
                        "level": 2
                    },
                    {
                        "areaCode": 341702000000,
                        "areaId": 246202,
                        "areaName": "贵池区",
                        "parent_id": 246200,
                        "level": 2
                    },
                    {
                        "areaCode": 341721000000,
                        "areaId": 246454,
                        "areaName": "东至县",
                        "parent_id": 246200,
                        "level": 2
                    },
                    {
                        "areaCode": 341722000000,
                        "areaId": 246725,
                        "areaName": "石台县",
                        "parent_id": 246200,
                        "level": 2
                    },
                    {
                        "areaCode": 341723000000,
                        "areaId": 246820,
                        "areaName": "青阳县",
                        "parent_id": 246200,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 341800000000,
                "areaId": 246965,
                "areaName": "宣城市",
                "parent_id": 227870,
                "level": 1,
                "children": [
                    {
                        "areaCode": 341801000000,
                        "areaId": 246966,
                        "areaName": "市辖区",
                        "parent_id": 246965,
                        "level": 2
                    },
                    {
                        "areaCode": 341802000000,
                        "areaId": 246967,
                        "areaName": "宣州区",
                        "parent_id": 246965,
                        "level": 2
                    },
                    {
                        "areaCode": 341821000000,
                        "areaId": 247202,
                        "areaName": "郎溪县",
                        "parent_id": 246965,
                        "level": 2
                    },
                    {
                        "areaCode": 341822000000,
                        "areaId": 247320,
                        "areaName": "广德县",
                        "parent_id": 246965,
                        "level": 2
                    },
                    {
                        "areaCode": 341823000000,
                        "areaId": 247469,
                        "areaName": "泾县",
                        "parent_id": 246965,
                        "level": 2
                    },
                    {
                        "areaCode": 341824000000,
                        "areaId": 247625,
                        "areaName": "绩溪县",
                        "parent_id": 246965,
                        "level": 2
                    },
                    {
                        "areaCode": 341825000000,
                        "areaId": 247720,
                        "areaName": "旌德县",
                        "parent_id": 246965,
                        "level": 2
                    },
                    {
                        "areaCode": 341871000000,
                        "areaId": 247799,
                        "areaName": "宣城市经济开发区",
                        "parent_id": 246965,
                        "level": 2
                    },
                    {
                        "areaCode": 341881000000,
                        "areaId": 247824,
                        "areaName": "宁国市",
                        "parent_id": 246965,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 350000000000,
        "areaId": 247968,
        "areaName": "福建省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 350100000000,
                "areaId": 247969,
                "areaName": "福州市",
                "parent_id": 247968,
                "level": 1,
                "children": [
                    {
                        "areaCode": 350101000000,
                        "areaId": 247970,
                        "areaName": "市辖区",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350102000000,
                        "areaId": 247971,
                        "areaName": "鼓楼区",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350103000000,
                        "areaId": 248051,
                        "areaName": "台江区",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350104000000,
                        "areaId": 248114,
                        "areaName": "仓山区",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350105000000,
                        "areaId": 248304,
                        "areaName": "马尾区",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350111000000,
                        "areaId": 248384,
                        "areaName": "晋安区",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350121000000,
                        "areaId": 248583,
                        "areaName": "闽侯县",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350122000000,
                        "areaId": 248926,
                        "areaName": "连江县",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350123000000,
                        "areaId": 249230,
                        "areaName": "罗源县",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350124000000,
                        "areaId": 249444,
                        "areaName": "闽清县",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350125000000,
                        "areaId": 249753,
                        "areaName": "永泰县",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350128000000,
                        "areaId": 250042,
                        "areaName": "平潭县",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350181000000,
                        "areaId": 250272,
                        "areaName": "福清市",
                        "parent_id": 247969,
                        "level": 2
                    },
                    {
                        "areaCode": 350182000000,
                        "areaId": 250808,
                        "areaName": "长乐市",
                        "parent_id": 247969,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 350200000000,
                "areaId": 251087,
                "areaName": "厦门市",
                "parent_id": 247968,
                "level": 1,
                "children": [
                    {
                        "areaCode": 350201000000,
                        "areaId": 251088,
                        "areaName": "市辖区",
                        "parent_id": 251087,
                        "level": 2
                    },
                    {
                        "areaCode": 350203000000,
                        "areaId": 251089,
                        "areaName": "思明区",
                        "parent_id": 251087,
                        "level": 2
                    },
                    {
                        "areaCode": 350205000000,
                        "areaId": 251199,
                        "areaName": "海沧区",
                        "parent_id": 251087,
                        "level": 2
                    },
                    {
                        "areaCode": 350206000000,
                        "areaId": 251250,
                        "areaName": "湖里区",
                        "parent_id": 251087,
                        "level": 2
                    },
                    {
                        "areaCode": 350211000000,
                        "areaId": 251314,
                        "areaName": "集美区",
                        "parent_id": 251087,
                        "level": 2
                    },
                    {
                        "areaCode": 350212000000,
                        "areaId": 251394,
                        "areaName": "同安区",
                        "parent_id": 251087,
                        "level": 2
                    },
                    {
                        "areaCode": 350213000000,
                        "areaId": 251545,
                        "areaName": "翔安区",
                        "parent_id": 251087,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 350300000000,
                "areaId": 251672,
                "areaName": "莆田市",
                "parent_id": 247968,
                "level": 1,
                "children": [
                    {
                        "areaCode": 350301000000,
                        "areaId": 251673,
                        "areaName": "市辖区",
                        "parent_id": 251672,
                        "level": 2
                    },
                    {
                        "areaCode": 350302000000,
                        "areaId": 251674,
                        "areaName": "城厢区",
                        "parent_id": 251672,
                        "level": 2
                    },
                    {
                        "areaCode": 350303000000,
                        "areaId": 251802,
                        "areaName": "涵江区",
                        "parent_id": 251672,
                        "level": 2
                    },
                    {
                        "areaCode": 350304000000,
                        "areaId": 252016,
                        "areaName": "荔城区",
                        "parent_id": 251672,
                        "level": 2
                    },
                    {
                        "areaCode": 350305000000,
                        "areaId": 252153,
                        "areaName": "秀屿区",
                        "parent_id": 251672,
                        "level": 2
                    },
                    {
                        "areaCode": 350322000000,
                        "areaId": 252366,
                        "areaName": "仙游县",
                        "parent_id": 251672,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 350400000000,
                "areaId": 252711,
                "areaName": "三明市",
                "parent_id": 247968,
                "level": 1,
                "children": [
                    {
                        "areaCode": 350401000000,
                        "areaId": 252712,
                        "areaName": "市辖区",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350402000000,
                        "areaId": 252713,
                        "areaName": "梅列区",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350403000000,
                        "areaId": 252772,
                        "areaName": "三元区",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350421000000,
                        "areaId": 252861,
                        "areaName": "明溪县",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350423000000,
                        "areaId": 252967,
                        "areaName": "清流县",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350424000000,
                        "areaId": 253100,
                        "areaName": "宁化县",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350425000000,
                        "areaId": 253345,
                        "areaName": "大田县",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350426000000,
                        "areaId": 253644,
                        "areaName": "尤溪县",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350427000000,
                        "areaId": 253926,
                        "areaName": "沙县",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350428000000,
                        "areaId": 254131,
                        "areaName": "将乐县",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350429000000,
                        "areaId": 254299,
                        "areaName": "泰宁县",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350430000000,
                        "areaId": 254428,
                        "areaName": "建宁县",
                        "parent_id": 252711,
                        "level": 2
                    },
                    {
                        "areaCode": 350481000000,
                        "areaId": 254539,
                        "areaName": "永安市",
                        "parent_id": 252711,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 350500000000,
                "areaId": 254816,
                "areaName": "泉州市",
                "parent_id": 247968,
                "level": 1,
                "children": [
                    {
                        "areaCode": 350501000000,
                        "areaId": 254817,
                        "areaName": "市辖区",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350502000000,
                        "areaId": 254818,
                        "areaName": "鲤城区",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350503000000,
                        "areaId": 254909,
                        "areaName": "丰泽区",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350504000000,
                        "areaId": 254996,
                        "areaName": "洛江区",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350505000000,
                        "areaId": 255090,
                        "areaName": "泉港区",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350521000000,
                        "areaId": 255200,
                        "areaName": "惠安县",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350524000000,
                        "areaId": 255517,
                        "areaName": "安溪县",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350525000000,
                        "areaId": 256026,
                        "areaName": "永春县",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350526000000,
                        "areaId": 256285,
                        "areaName": "德化县",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350527000000,
                        "areaId": 256518,
                        "areaName": "金门县",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350581000000,
                        "areaId": 256519,
                        "areaName": "石狮市",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350582000000,
                        "areaId": 256656,
                        "areaName": "晋江市",
                        "parent_id": 254816,
                        "level": 2
                    },
                    {
                        "areaCode": 350583000000,
                        "areaId": 257078,
                        "areaName": "南安市",
                        "parent_id": 254816,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 350600000000,
                "areaId": 257531,
                "areaName": "漳州市",
                "parent_id": 247968,
                "level": 1,
                "children": [
                    {
                        "areaCode": 350601000000,
                        "areaId": 257532,
                        "areaName": "市辖区",
                        "parent_id": 257531,
                        "level": 2
                    },
                    {
                        "areaCode": 350602000000,
                        "areaId": 257533,
                        "areaName": "芗城区",
                        "parent_id": 257531,
                        "level": 2
                    },
                    {
                        "areaCode": 350603000000,
                        "areaId": 257719,
                        "areaName": "龙文区",
                        "parent_id": 257531,
                        "level": 2
                    },
                    {
                        "areaCode": 350622000000,
                        "areaId": 257796,
                        "areaName": "云霄县",
                        "parent_id": 257531,
                        "level": 2
                    },
                    {
                        "areaCode": 350623000000,
                        "areaId": 258020,
                        "areaName": "漳浦县",
                        "parent_id": 257531,
                        "level": 2
                    },
                    {
                        "areaCode": 350624000000,
                        "areaId": 258376,
                        "areaName": "诏安县",
                        "parent_id": 257531,
                        "level": 2
                    },
                    {
                        "areaCode": 350625000000,
                        "areaId": 258640,
                        "areaName": "长泰县",
                        "parent_id": 257531,
                        "level": 2
                    },
                    {
                        "areaCode": 350626000000,
                        "areaId": 258734,
                        "areaName": "东山县",
                        "parent_id": 257531,
                        "level": 2
                    },
                    {
                        "areaCode": 350627000000,
                        "areaId": 258819,
                        "areaName": "南靖县",
                        "parent_id": 257531,
                        "level": 2
                    },
                    {
                        "areaCode": 350628000000,
                        "areaId": 259034,
                        "areaName": "平和县",
                        "parent_id": 257531,
                        "level": 2
                    },
                    {
                        "areaCode": 350629000000,
                        "areaId": 259309,
                        "areaName": "华安县",
                        "parent_id": 257531,
                        "level": 2
                    },
                    {
                        "areaCode": 350681000000,
                        "areaId": 259418,
                        "areaName": "龙海市",
                        "parent_id": 257531,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 350700000000,
                "areaId": 259741,
                "areaName": "南平市",
                "parent_id": 247968,
                "level": 1,
                "children": [
                    {
                        "areaCode": 350701000000,
                        "areaId": 259742,
                        "areaName": "市辖区",
                        "parent_id": 259741,
                        "level": 2
                    },
                    {
                        "areaCode": 350702000000,
                        "areaId": 259743,
                        "areaName": "延平区",
                        "parent_id": 259741,
                        "level": 2
                    },
                    {
                        "areaCode": 350703000000,
                        "areaId": 260082,
                        "areaName": "建阳区",
                        "parent_id": 259741,
                        "level": 2
                    },
                    {
                        "areaCode": 350721000000,
                        "areaId": 260317,
                        "areaName": "顺昌县",
                        "parent_id": 259741,
                        "level": 2
                    },
                    {
                        "areaCode": 350722000000,
                        "areaId": 260479,
                        "areaName": "浦城县",
                        "parent_id": 259741,
                        "level": 2
                    },
                    {
                        "areaCode": 350723000000,
                        "areaId": 260803,
                        "areaName": "光泽县",
                        "parent_id": 259741,
                        "level": 2
                    },
                    {
                        "areaCode": 350724000000,
                        "areaId": 260907,
                        "areaName": "松溪县",
                        "parent_id": 259741,
                        "level": 2
                    },
                    {
                        "areaCode": 350725000000,
                        "areaId": 261026,
                        "areaName": "政和县",
                        "parent_id": 259741,
                        "level": 2
                    },
                    {
                        "areaCode": 350781000000,
                        "areaId": 261170,
                        "areaName": "邵武市",
                        "parent_id": 259741,
                        "level": 2
                    },
                    {
                        "areaCode": 350782000000,
                        "areaId": 261366,
                        "areaName": "武夷山市",
                        "parent_id": 259741,
                        "level": 2
                    },
                    {
                        "areaCode": 350783000000,
                        "areaId": 261519,
                        "areaName": "建瓯市",
                        "parent_id": 259741,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 350800000000,
                "areaId": 261793,
                "areaName": "龙岩市",
                "parent_id": 247968,
                "level": 1,
                "children": [
                    {
                        "areaCode": 350801000000,
                        "areaId": 261794,
                        "areaName": "市辖区",
                        "parent_id": 261793,
                        "level": 2
                    },
                    {
                        "areaCode": 350802000000,
                        "areaId": 261795,
                        "areaName": "新罗区",
                        "parent_id": 261793,
                        "level": 2
                    },
                    {
                        "areaCode": 350803000000,
                        "areaId": 262153,
                        "areaName": "永定区",
                        "parent_id": 261793,
                        "level": 2
                    },
                    {
                        "areaCode": 350821000000,
                        "areaId": 262458,
                        "areaName": "长汀县",
                        "parent_id": 261793,
                        "level": 2
                    },
                    {
                        "areaCode": 350823000000,
                        "areaId": 262777,
                        "areaName": "上杭县",
                        "parent_id": 261793,
                        "level": 2
                    },
                    {
                        "areaCode": 350824000000,
                        "areaId": 263145,
                        "areaName": "武平县",
                        "parent_id": 261793,
                        "level": 2
                    },
                    {
                        "areaCode": 350825000000,
                        "areaId": 263382,
                        "areaName": "连城县",
                        "parent_id": 261793,
                        "level": 2
                    },
                    {
                        "areaCode": 350881000000,
                        "areaId": 263647,
                        "areaName": "漳平市",
                        "parent_id": 261793,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 350900000000,
                "areaId": 263863,
                "areaName": "宁德市",
                "parent_id": 247968,
                "level": 1,
                "children": [
                    {
                        "areaCode": 350901000000,
                        "areaId": 263864,
                        "areaName": "市辖区",
                        "parent_id": 263863,
                        "level": 2
                    },
                    {
                        "areaCode": 350902000000,
                        "areaId": 263865,
                        "areaName": "蕉城区",
                        "parent_id": 263863,
                        "level": 2
                    },
                    {
                        "areaCode": 350921000000,
                        "areaId": 264211,
                        "areaName": "霞浦县",
                        "parent_id": 263863,
                        "level": 2
                    },
                    {
                        "areaCode": 350922000000,
                        "areaId": 264544,
                        "areaName": "古田县",
                        "parent_id": 263863,
                        "level": 2
                    },
                    {
                        "areaCode": 350923000000,
                        "areaId": 264850,
                        "areaName": "屏南县",
                        "parent_id": 263863,
                        "level": 2
                    },
                    {
                        "areaCode": 350924000000,
                        "areaId": 265021,
                        "areaName": "寿宁县",
                        "parent_id": 263863,
                        "level": 2
                    },
                    {
                        "areaCode": 350925000000,
                        "areaId": 265241,
                        "areaName": "周宁县",
                        "parent_id": 263863,
                        "level": 2
                    },
                    {
                        "areaCode": 350926000000,
                        "areaId": 265398,
                        "areaName": "柘荣县",
                        "parent_id": 263863,
                        "level": 2
                    },
                    {
                        "areaCode": 350981000000,
                        "areaId": 265524,
                        "areaName": "福安市",
                        "parent_id": 263863,
                        "level": 2
                    },
                    {
                        "areaCode": 350982000000,
                        "areaId": 266034,
                        "areaName": "福鼎市",
                        "parent_id": 263863,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 360000000000,
        "areaId": 266337,
        "areaName": "江西省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 360100000000,
                "areaId": 266338,
                "areaName": "南昌市",
                "parent_id": 266337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 360101000000,
                        "areaId": 266339,
                        "areaName": "市辖区",
                        "parent_id": 266338,
                        "level": 2
                    },
                    {
                        "areaCode": 360102000000,
                        "areaId": 266340,
                        "areaName": "东湖区",
                        "parent_id": 266338,
                        "level": 2
                    },
                    {
                        "areaCode": 360103000000,
                        "areaId": 266528,
                        "areaName": "西湖区",
                        "parent_id": 266338,
                        "level": 2
                    },
                    {
                        "areaCode": 360104000000,
                        "areaId": 266699,
                        "areaName": "青云谱区",
                        "parent_id": 266338,
                        "level": 2
                    },
                    {
                        "areaCode": 360105000000,
                        "areaId": 266795,
                        "areaName": "湾里区",
                        "parent_id": 266338,
                        "level": 2
                    },
                    {
                        "areaCode": 360111000000,
                        "areaId": 266861,
                        "areaName": "青山湖区",
                        "parent_id": 266338,
                        "level": 2
                    },
                    {
                        "areaCode": 360112000000,
                        "areaId": 267156,
                        "areaName": "新建区",
                        "parent_id": 266338,
                        "level": 2
                    },
                    {
                        "areaCode": 360121000000,
                        "areaId": 267599,
                        "areaName": "南昌县",
                        "parent_id": 266338,
                        "level": 2
                    },
                    {
                        "areaCode": 360123000000,
                        "areaId": 268040,
                        "areaName": "安义县",
                        "parent_id": 266338,
                        "level": 2
                    },
                    {
                        "areaCode": 360124000000,
                        "areaId": 268186,
                        "areaName": "进贤县",
                        "parent_id": 266338,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 360200000000,
                "areaId": 268541,
                "areaName": "景德镇市",
                "parent_id": 266337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 360201000000,
                        "areaId": 268542,
                        "areaName": "市辖区",
                        "parent_id": 268541,
                        "level": 2
                    },
                    {
                        "areaCode": 360202000000,
                        "areaId": 268543,
                        "areaName": "昌江区",
                        "parent_id": 268541,
                        "level": 2
                    },
                    {
                        "areaCode": 360203000000,
                        "areaId": 268641,
                        "areaName": "珠山区",
                        "parent_id": 268541,
                        "level": 2
                    },
                    {
                        "areaCode": 360222000000,
                        "areaId": 268762,
                        "areaName": "浮梁县",
                        "parent_id": 268541,
                        "level": 2
                    },
                    {
                        "areaCode": 360281000000,
                        "areaId": 268976,
                        "areaName": "乐平市",
                        "parent_id": 268541,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 360300000000,
                "areaId": 269358,
                "areaName": "萍乡市",
                "parent_id": 266337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 360301000000,
                        "areaId": 269359,
                        "areaName": "市辖区",
                        "parent_id": 269358,
                        "level": 2
                    },
                    {
                        "areaCode": 360302000000,
                        "areaId": 269360,
                        "areaName": "安源区",
                        "parent_id": 269358,
                        "level": 2
                    },
                    {
                        "areaCode": 360313000000,
                        "areaId": 269508,
                        "areaName": "湘东区",
                        "parent_id": 269358,
                        "level": 2
                    },
                    {
                        "areaCode": 360321000000,
                        "areaId": 269676,
                        "areaName": "莲花县",
                        "parent_id": 269358,
                        "level": 2
                    },
                    {
                        "areaCode": 360322000000,
                        "areaId": 269853,
                        "areaName": "上栗县",
                        "parent_id": 269358,
                        "level": 2
                    },
                    {
                        "areaCode": 360323000000,
                        "areaId": 270038,
                        "areaName": "芦溪县",
                        "parent_id": 269358,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 360400000000,
                "areaId": 270203,
                "areaName": "九江市",
                "parent_id": 266337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 360401000000,
                        "areaId": 270204,
                        "areaName": "市辖区",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360402000000,
                        "areaId": 270205,
                        "areaName": "濂溪区",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360403000000,
                        "areaId": 270347,
                        "areaName": "浔阳区",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360404000000,
                        "areaId": 270463,
                        "areaName": "柴桑区",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360423000000,
                        "areaId": 270628,
                        "areaName": "武宁县",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360424000000,
                        "areaId": 270850,
                        "areaName": "修水县",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360425000000,
                        "areaId": 271296,
                        "areaName": "永修县",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360426000000,
                        "areaId": 271551,
                        "areaName": "德安县",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360428000000,
                        "areaId": 271665,
                        "areaName": "都昌县",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360429000000,
                        "areaId": 272001,
                        "areaName": "湖口县",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360430000000,
                        "areaId": 272167,
                        "areaName": "彭泽县",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360481000000,
                        "areaId": 272376,
                        "areaName": "瑞昌市",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360482000000,
                        "areaId": 272613,
                        "areaName": "共青城市",
                        "parent_id": 270203,
                        "level": 2
                    },
                    {
                        "areaCode": 360483000000,
                        "areaId": 272682,
                        "areaName": "庐山市",
                        "parent_id": 270203,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 360500000000,
                "areaId": 272794,
                "areaName": "新余市",
                "parent_id": 266337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 360501000000,
                        "areaId": 272795,
                        "areaName": "市辖区",
                        "parent_id": 272794,
                        "level": 2
                    },
                    {
                        "areaCode": 360502000000,
                        "areaId": 272796,
                        "areaName": "渝水区",
                        "parent_id": 272794,
                        "level": 2
                    },
                    {
                        "areaCode": 360521000000,
                        "areaId": 273155,
                        "areaName": "分宜县",
                        "parent_id": 272794,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 360600000000,
                "areaId": 273344,
                "areaName": "鹰潭市",
                "parent_id": 266337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 360601000000,
                        "areaId": 273345,
                        "areaName": "市辖区",
                        "parent_id": 273344,
                        "level": 2
                    },
                    {
                        "areaCode": 360602000000,
                        "areaId": 273346,
                        "areaName": "月湖区",
                        "parent_id": 273344,
                        "level": 2
                    },
                    {
                        "areaCode": 360622000000,
                        "areaId": 273440,
                        "areaName": "余江县",
                        "parent_id": 273344,
                        "level": 2
                    },
                    {
                        "areaCode": 360681000000,
                        "areaId": 273636,
                        "areaName": "贵溪市",
                        "parent_id": 273344,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 360700000000,
                "areaId": 273918,
                "areaName": "赣州市",
                "parent_id": 266337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 360701000000,
                        "areaId": 273919,
                        "areaName": "市辖区",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360702000000,
                        "areaId": 273920,
                        "areaName": "章贡区",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360703000000,
                        "areaId": 274138,
                        "areaName": "南康区",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360704000000,
                        "areaId": 274479,
                        "areaName": "赣县区",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360722000000,
                        "areaId": 274794,
                        "areaName": "信丰县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360723000000,
                        "areaId": 275109,
                        "areaName": "大余县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360724000000,
                        "areaId": 275242,
                        "areaName": "上犹县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360725000000,
                        "areaId": 275400,
                        "areaName": "崇义县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360726000000,
                        "areaId": 275549,
                        "areaName": "安远县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360727000000,
                        "areaId": 275734,
                        "areaName": "龙南县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360728000000,
                        "areaId": 275857,
                        "areaName": "定南县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360729000000,
                        "areaId": 276003,
                        "areaName": "全南县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360730000000,
                        "areaId": 276110,
                        "areaName": "宁都县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360731000000,
                        "areaId": 276469,
                        "areaName": "于都县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360732000000,
                        "areaId": 276886,
                        "areaName": "兴国县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360733000000,
                        "areaId": 277228,
                        "areaName": "会昌县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360734000000,
                        "areaId": 277524,
                        "areaName": "寻乌县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360735000000,
                        "areaId": 277724,
                        "areaName": "石城县",
                        "parent_id": 273918,
                        "level": 2
                    },
                    {
                        "areaCode": 360781000000,
                        "areaId": 277888,
                        "areaName": "瑞金市",
                        "parent_id": 273918,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 360800000000,
                "areaId": 278148,
                "areaName": "吉安市",
                "parent_id": 266337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 360801000000,
                        "areaId": 278149,
                        "areaName": "市辖区",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360802000000,
                        "areaId": 278150,
                        "areaName": "吉州区",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360803000000,
                        "areaId": 278299,
                        "areaName": "青原区",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360821000000,
                        "areaId": 278443,
                        "areaName": "吉安县",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360822000000,
                        "areaId": 278812,
                        "areaName": "吉水县",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360823000000,
                        "areaId": 279115,
                        "areaName": "峡江县",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360824000000,
                        "areaId": 279221,
                        "areaName": "新干县",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360825000000,
                        "areaId": 279404,
                        "areaName": "永丰县",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360826000000,
                        "areaId": 279671,
                        "areaName": "泰和县",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360827000000,
                        "areaId": 280046,
                        "areaName": "遂川县",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360828000000,
                        "areaId": 280417,
                        "areaName": "万安县",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360829000000,
                        "areaId": 280592,
                        "areaName": "安福县",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360830000000,
                        "areaId": 280888,
                        "areaName": "永新县",
                        "parent_id": 278148,
                        "level": 2
                    },
                    {
                        "areaCode": 360881000000,
                        "areaId": 281178,
                        "areaName": "井冈山市",
                        "parent_id": 278148,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 360900000000,
                "areaId": 281334,
                "areaName": "宜春市",
                "parent_id": 266337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 360901000000,
                        "areaId": 281335,
                        "areaName": "市辖区",
                        "parent_id": 281334,
                        "level": 2
                    },
                    {
                        "areaCode": 360902000000,
                        "areaId": 281336,
                        "areaName": "袁州区",
                        "parent_id": 281334,
                        "level": 2
                    },
                    {
                        "areaCode": 360921000000,
                        "areaId": 281757,
                        "areaName": "奉新县",
                        "parent_id": 281334,
                        "level": 2
                    },
                    {
                        "areaCode": 360922000000,
                        "areaId": 281975,
                        "areaName": "万载县",
                        "parent_id": 281334,
                        "level": 2
                    },
                    {
                        "areaCode": 360923000000,
                        "areaId": 282197,
                        "areaName": "上高县",
                        "parent_id": 281334,
                        "level": 2
                    },
                    {
                        "areaCode": 360924000000,
                        "areaId": 282436,
                        "areaName": "宜丰县",
                        "parent_id": 281334,
                        "level": 2
                    },
                    {
                        "areaCode": 360925000000,
                        "areaId": 282701,
                        "areaName": "靖安县",
                        "parent_id": 281334,
                        "level": 2
                    },
                    {
                        "areaCode": 360926000000,
                        "areaId": 282822,
                        "areaName": "铜鼓县",
                        "parent_id": 281334,
                        "level": 2
                    },
                    {
                        "areaCode": 360981000000,
                        "areaId": 282947,
                        "areaName": "丰城市",
                        "parent_id": 281334,
                        "level": 2
                    },
                    {
                        "areaCode": 360982000000,
                        "areaId": 283573,
                        "areaName": "樟树市",
                        "parent_id": 281334,
                        "level": 2
                    },
                    {
                        "areaCode": 360983000000,
                        "areaId": 283895,
                        "areaName": "高安市",
                        "parent_id": 281334,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 361000000000,
                "areaId": 284291,
                "areaName": "抚州市",
                "parent_id": 266337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 361001000000,
                        "areaId": 284292,
                        "areaName": "市辖区",
                        "parent_id": 284291,
                        "level": 2
                    },
                    {
                        "areaCode": 361002000000,
                        "areaId": 284293,
                        "areaName": "临川区",
                        "parent_id": 284291,
                        "level": 2
                    },
                    {
                        "areaCode": 361003000000,
                        "areaId": 284827,
                        "areaName": "东乡区",
                        "parent_id": 284291,
                        "level": 2
                    },
                    {
                        "areaCode": 361021000000,
                        "areaId": 285029,
                        "areaName": "南城县",
                        "parent_id": 284291,
                        "level": 2
                    },
                    {
                        "areaCode": 361022000000,
                        "areaId": 285216,
                        "areaName": "黎川县",
                        "parent_id": 284291,
                        "level": 2
                    },
                    {
                        "areaCode": 361023000000,
                        "areaId": 285374,
                        "areaName": "南丰县",
                        "parent_id": 284291,
                        "level": 2
                    },
                    {
                        "areaCode": 361024000000,
                        "areaId": 285576,
                        "areaName": "崇仁县",
                        "parent_id": 284291,
                        "level": 2
                    },
                    {
                        "areaCode": 361025000000,
                        "areaId": 285762,
                        "areaName": "乐安县",
                        "parent_id": 284291,
                        "level": 2
                    },
                    {
                        "areaCode": 361026000000,
                        "areaId": 285971,
                        "areaName": "宜黄县",
                        "parent_id": 284291,
                        "level": 2
                    },
                    {
                        "areaCode": 361027000000,
                        "areaId": 286142,
                        "areaName": "金溪县",
                        "parent_id": 284291,
                        "level": 2
                    },
                    {
                        "areaCode": 361028000000,
                        "areaId": 286329,
                        "areaName": "资溪县",
                        "parent_id": 284291,
                        "level": 2
                    },
                    {
                        "areaCode": 361030000000,
                        "areaId": 286420,
                        "areaName": "广昌县",
                        "parent_id": 284291,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 361100000000,
                "areaId": 286579,
                "areaName": "上饶市",
                "parent_id": 266337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 361101000000,
                        "areaId": 286580,
                        "areaName": "市辖区",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361102000000,
                        "areaId": 286581,
                        "areaName": "信州区",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361103000000,
                        "areaId": 286718,
                        "areaName": "广丰区",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361121000000,
                        "areaId": 286982,
                        "areaName": "上饶县",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361123000000,
                        "areaId": 287253,
                        "areaName": "玉山县",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361124000000,
                        "areaId": 287511,
                        "areaName": "铅山县",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361125000000,
                        "areaId": 287712,
                        "areaName": "横峰县",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361126000000,
                        "areaId": 287808,
                        "areaName": "弋阳县",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361127000000,
                        "areaId": 287983,
                        "areaName": "余干县",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361128000000,
                        "areaId": 288450,
                        "areaName": "鄱阳县",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361129000000,
                        "areaId": 289063,
                        "areaName": "万年县",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361130000000,
                        "areaId": 289235,
                        "areaName": "婺源县",
                        "parent_id": 286579,
                        "level": 2
                    },
                    {
                        "areaCode": 361181000000,
                        "areaId": 289457,
                        "areaName": "德兴市",
                        "parent_id": 286579,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 370000000000,
        "areaId": 289611,
        "areaName": "山东省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 370100000000,
                "areaId": 289612,
                "areaName": "济南市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 370101000000,
                        "areaId": 289613,
                        "areaName": "市辖区",
                        "parent_id": 289612,
                        "level": 2
                    },
                    {
                        "areaCode": 370102000000,
                        "areaId": 289614,
                        "areaName": "历下区",
                        "parent_id": 289612,
                        "level": 2
                    },
                    {
                        "areaCode": 370103000000,
                        "areaId": 289737,
                        "areaName": "市中区",
                        "parent_id": 289612,
                        "level": 2
                    },
                    {
                        "areaCode": 370104000000,
                        "areaId": 289962,
                        "areaName": "槐荫区",
                        "parent_id": 289612,
                        "level": 2
                    },
                    {
                        "areaCode": 370105000000,
                        "areaId": 290159,
                        "areaName": "天桥区",
                        "parent_id": 289612,
                        "level": 2
                    },
                    {
                        "areaCode": 370112000000,
                        "areaId": 290438,
                        "areaName": "历城区",
                        "parent_id": 289612,
                        "level": 2
                    },
                    {
                        "areaCode": 370113000000,
                        "areaId": 291044,
                        "areaName": "长清区",
                        "parent_id": 289612,
                        "level": 2
                    },
                    {
                        "areaCode": 370114000000,
                        "areaId": 291696,
                        "areaName": "章丘区",
                        "parent_id": 289612,
                        "level": 2
                    },
                    {
                        "areaCode": 370124000000,
                        "areaId": 292626,
                        "areaName": "平阴县",
                        "parent_id": 289612,
                        "level": 2
                    },
                    {
                        "areaCode": 370125000000,
                        "areaId": 292990,
                        "areaName": "济阳县",
                        "parent_id": 289612,
                        "level": 2
                    },
                    {
                        "areaCode": 370126000000,
                        "areaId": 293862,
                        "areaName": "商河县",
                        "parent_id": 289612,
                        "level": 2
                    },
                    {
                        "areaCode": 370171000000,
                        "areaId": 294839,
                        "areaName": "济南高新技术产业开发区",
                        "parent_id": 289612,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 370200000000,
                "areaId": 295028,
                "areaName": "青岛市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 370201000000,
                        "areaId": 295029,
                        "areaName": "市辖区",
                        "parent_id": 295028,
                        "level": 2
                    },
                    {
                        "areaCode": 370202000000,
                        "areaId": 295030,
                        "areaName": "市南区",
                        "parent_id": 295028,
                        "level": 2
                    },
                    {
                        "areaCode": 370203000000,
                        "areaId": 295106,
                        "areaName": "市北区",
                        "parent_id": 295028,
                        "level": 2
                    },
                    {
                        "areaCode": 370211000000,
                        "areaId": 295261,
                        "areaName": "黄岛区",
                        "parent_id": 295028,
                        "level": 2
                    },
                    {
                        "areaCode": 370212000000,
                        "areaId": 296512,
                        "areaName": "崂山区",
                        "parent_id": 295028,
                        "level": 2
                    },
                    {
                        "areaCode": 370213000000,
                        "areaId": 296679,
                        "areaName": "李沧区",
                        "parent_id": 295028,
                        "level": 2
                    },
                    {
                        "areaCode": 370214000000,
                        "areaId": 296808,
                        "areaName": "城阳区",
                        "parent_id": 295028,
                        "level": 2
                    },
                    {
                        "areaCode": 370215000000,
                        "areaId": 297041,
                        "areaName": "即墨区",
                        "parent_id": 295028,
                        "level": 2
                    },
                    {
                        "areaCode": 370271000000,
                        "areaId": 298158,
                        "areaName": "青岛高新技术产业开发区",
                        "parent_id": 295028,
                        "level": 2
                    },
                    {
                        "areaCode": 370281000000,
                        "areaId": 298198,
                        "areaName": "胶州市",
                        "parent_id": 295028,
                        "level": 2
                    },
                    {
                        "areaCode": 370283000000,
                        "areaId": 299105,
                        "areaName": "平度市",
                        "parent_id": 295028,
                        "level": 2
                    },
                    {
                        "areaCode": 370285000000,
                        "areaId": 300977,
                        "areaName": "莱西市",
                        "parent_id": 295028,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 370300000000,
                "areaId": 301882,
                "areaName": "淄博市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 370301000000,
                        "areaId": 301883,
                        "areaName": "市辖区",
                        "parent_id": 301882,
                        "level": 2
                    },
                    {
                        "areaCode": 370302000000,
                        "areaId": 301884,
                        "areaName": "淄川区",
                        "parent_id": 301882,
                        "level": 2
                    },
                    {
                        "areaCode": 370303000000,
                        "areaId": 302364,
                        "areaName": "张店区",
                        "parent_id": 301882,
                        "level": 2
                    },
                    {
                        "areaCode": 370304000000,
                        "areaId": 302681,
                        "areaName": "博山区",
                        "parent_id": 301882,
                        "level": 2
                    },
                    {
                        "areaCode": 370305000000,
                        "areaId": 302989,
                        "areaName": "临淄区",
                        "parent_id": 301882,
                        "level": 2
                    },
                    {
                        "areaCode": 370306000000,
                        "areaId": 303482,
                        "areaName": "周村区",
                        "parent_id": 301882,
                        "level": 2
                    },
                    {
                        "areaCode": 370321000000,
                        "areaId": 303749,
                        "areaName": "桓台县",
                        "parent_id": 301882,
                        "level": 2
                    },
                    {
                        "areaCode": 370322000000,
                        "areaId": 304109,
                        "areaName": "高青县",
                        "parent_id": 301882,
                        "level": 2
                    },
                    {
                        "areaCode": 370323000000,
                        "areaId": 304889,
                        "areaName": "沂源县",
                        "parent_id": 301882,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 370400000000,
                "areaId": 305549,
                "areaName": "枣庄市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 370401000000,
                        "areaId": 305550,
                        "areaName": "市辖区",
                        "parent_id": 305549,
                        "level": 2
                    },
                    {
                        "areaCode": 370402000000,
                        "areaId": 305551,
                        "areaName": "市中区",
                        "parent_id": 305549,
                        "level": 2
                    },
                    {
                        "areaCode": 370403000000,
                        "areaId": 305732,
                        "areaName": "薛城区",
                        "parent_id": 305549,
                        "level": 2
                    },
                    {
                        "areaCode": 370404000000,
                        "areaId": 306023,
                        "areaName": "峄城区",
                        "parent_id": 305549,
                        "level": 2
                    },
                    {
                        "areaCode": 370405000000,
                        "areaId": 306376,
                        "areaName": "台儿庄区",
                        "parent_id": 305549,
                        "level": 2
                    },
                    {
                        "areaCode": 370406000000,
                        "areaId": 306594,
                        "areaName": "山亭区",
                        "parent_id": 305549,
                        "level": 2
                    },
                    {
                        "areaCode": 370481000000,
                        "areaId": 306882,
                        "areaName": "滕州市",
                        "parent_id": 305549,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 370500000000,
                "areaId": 308154,
                "areaName": "东营市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 370501000000,
                        "areaId": 308155,
                        "areaName": "市辖区",
                        "parent_id": 308154,
                        "level": 2
                    },
                    {
                        "areaCode": 370502000000,
                        "areaId": 308156,
                        "areaName": "东营区",
                        "parent_id": 308154,
                        "level": 2
                    },
                    {
                        "areaCode": 370503000000,
                        "areaId": 308505,
                        "areaName": "河口区",
                        "parent_id": 308154,
                        "level": 2
                    },
                    {
                        "areaCode": 370505000000,
                        "areaId": 308703,
                        "areaName": "垦利区",
                        "parent_id": 308154,
                        "level": 2
                    },
                    {
                        "areaCode": 370522000000,
                        "areaId": 309071,
                        "areaName": "利津县",
                        "parent_id": 308154,
                        "level": 2
                    },
                    {
                        "areaCode": 370523000000,
                        "areaId": 309610,
                        "areaName": "广饶县",
                        "parent_id": 308154,
                        "level": 2
                    },
                    {
                        "areaCode": 370571000000,
                        "areaId": 310202,
                        "areaName": "东营经济技术开发区",
                        "parent_id": 308154,
                        "level": 2
                    },
                    {
                        "areaCode": 370572000000,
                        "areaId": 310205,
                        "areaName": "东营港经济开发区",
                        "parent_id": 308154,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 370600000000,
                "areaId": 310208,
                "areaName": "烟台市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 370601000000,
                        "areaId": 310209,
                        "areaName": "市辖区",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370602000000,
                        "areaId": 310210,
                        "areaName": "芝罘区",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370611000000,
                        "areaId": 310365,
                        "areaName": "福山区",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370612000000,
                        "areaId": 310612,
                        "areaName": "牟平区",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370613000000,
                        "areaId": 311217,
                        "areaName": "莱山区",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370634000000,
                        "areaId": 311350,
                        "areaName": "长岛县",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370671000000,
                        "areaId": 311399,
                        "areaName": "烟台高新技术产业开发区",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370672000000,
                        "areaId": 311422,
                        "areaName": "烟台经济技术开发区",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370681000000,
                        "areaId": 311559,
                        "areaName": "龙口市",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370682000000,
                        "areaId": 312169,
                        "areaName": "莱阳市",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370683000000,
                        "areaId": 312982,
                        "areaName": "莱州市",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370684000000,
                        "areaId": 314015,
                        "areaName": "蓬莱市",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370685000000,
                        "areaId": 314616,
                        "areaName": "招远市",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370686000000,
                        "areaId": 315377,
                        "areaName": "栖霞市",
                        "parent_id": 310208,
                        "level": 2
                    },
                    {
                        "areaCode": 370687000000,
                        "areaId": 316347,
                        "areaName": "海阳市",
                        "parent_id": 310208,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 370700000000,
                "areaId": 317098,
                "areaName": "潍坊市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 370701000000,
                        "areaId": 317099,
                        "areaName": "市辖区",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370702000000,
                        "areaId": 317100,
                        "areaName": "潍城区",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370703000000,
                        "areaId": 317362,
                        "areaName": "寒亭区",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370704000000,
                        "areaId": 317740,
                        "areaName": "坊子区",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370705000000,
                        "areaId": 318327,
                        "areaName": "奎文区",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370724000000,
                        "areaId": 318570,
                        "areaName": "临朐县",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370725000000,
                        "areaId": 318931,
                        "areaName": "昌乐县",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370772000000,
                        "areaId": 319310,
                        "areaName": "潍坊滨海经济技术开发区",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370781000000,
                        "areaId": 319364,
                        "areaName": "青州市",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370782000000,
                        "areaId": 320456,
                        "areaName": "诸城市",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370783000000,
                        "areaId": 320737,
                        "areaName": "寿光市",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370784000000,
                        "areaId": 321728,
                        "areaName": "安丘市",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370785000000,
                        "areaId": 322607,
                        "areaName": "高密市",
                        "parent_id": 317098,
                        "level": 2
                    },
                    {
                        "areaCode": 370786000000,
                        "areaId": 323565,
                        "areaName": "昌邑市",
                        "parent_id": 317098,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 370800000000,
                "areaId": 324266,
                "areaName": "济宁市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 370801000000,
                        "areaId": 324267,
                        "areaName": "市辖区",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370811000000,
                        "areaId": 324268,
                        "areaName": "任城区",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370812000000,
                        "areaId": 324882,
                        "areaName": "兖州区",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370826000000,
                        "areaId": 325336,
                        "areaName": "微山县",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370827000000,
                        "areaId": 325894,
                        "areaName": "鱼台县",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370828000000,
                        "areaId": 326299,
                        "areaName": "金乡县",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370829000000,
                        "areaId": 326972,
                        "areaName": "嘉祥县",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370830000000,
                        "areaId": 327698,
                        "areaName": "汶上县",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370831000000,
                        "areaId": 328209,
                        "areaName": "泗水县",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370832000000,
                        "areaId": 328820,
                        "areaName": "梁山县",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370871000000,
                        "areaId": 329471,
                        "areaName": "济宁高新技术产业开发区",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370881000000,
                        "areaId": 329668,
                        "areaName": "曲阜市",
                        "parent_id": 324266,
                        "level": 2
                    },
                    {
                        "areaCode": 370883000000,
                        "areaId": 330087,
                        "areaName": "邹城市",
                        "parent_id": 324266,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 370900000000,
                "areaId": 331003,
                "areaName": "泰安市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 370901000000,
                        "areaId": 331004,
                        "areaName": "市辖区",
                        "parent_id": 331003,
                        "level": 2
                    },
                    {
                        "areaCode": 370902000000,
                        "areaId": 331005,
                        "areaName": "泰山区",
                        "parent_id": 331003,
                        "level": 2
                    },
                    {
                        "areaCode": 370911000000,
                        "areaId": 331223,
                        "areaName": "岱岳区",
                        "parent_id": 331003,
                        "level": 2
                    },
                    {
                        "areaCode": 370921000000,
                        "areaId": 331952,
                        "areaName": "宁阳县",
                        "parent_id": 331003,
                        "level": 2
                    },
                    {
                        "areaCode": 370923000000,
                        "areaId": 332528,
                        "areaName": "东平县",
                        "parent_id": 331003,
                        "level": 2
                    },
                    {
                        "areaCode": 370982000000,
                        "areaId": 333259,
                        "areaName": "新泰市",
                        "parent_id": 331003,
                        "level": 2
                    },
                    {
                        "areaCode": 370983000000,
                        "areaId": 334194,
                        "areaName": "肥城市",
                        "parent_id": 331003,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 371000000000,
                "areaId": 334842,
                "areaName": "威海市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 371001000000,
                        "areaId": 334843,
                        "areaName": "市辖区",
                        "parent_id": 334842,
                        "level": 2
                    },
                    {
                        "areaCode": 371002000000,
                        "areaId": 334844,
                        "areaName": "环翠区",
                        "parent_id": 334842,
                        "level": 2
                    },
                    {
                        "areaCode": 371003000000,
                        "areaId": 335037,
                        "areaName": "文登区",
                        "parent_id": 334842,
                        "level": 2
                    },
                    {
                        "areaCode": 371071000000,
                        "areaId": 335816,
                        "areaName": "威海火炬高技术产业开发区",
                        "parent_id": 334842,
                        "level": 2
                    },
                    {
                        "areaCode": 371072000000,
                        "areaId": 335907,
                        "areaName": "威海经济技术开发区",
                        "parent_id": 334842,
                        "level": 2
                    },
                    {
                        "areaCode": 371073000000,
                        "areaId": 336058,
                        "areaName": "威海临港经济技术开发区",
                        "parent_id": 334842,
                        "level": 2
                    },
                    {
                        "areaCode": 371082000000,
                        "areaId": 336237,
                        "areaName": "荣成市",
                        "parent_id": 334842,
                        "level": 2
                    },
                    {
                        "areaCode": 371083000000,
                        "areaId": 337204,
                        "areaName": "乳山市",
                        "parent_id": 334842,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 371100000000,
                "areaId": 337848,
                "areaName": "日照市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 371101000000,
                        "areaId": 337849,
                        "areaName": "市辖区",
                        "parent_id": 337848,
                        "level": 2
                    },
                    {
                        "areaCode": 371102000000,
                        "areaId": 337850,
                        "areaName": "东港区",
                        "parent_id": 337848,
                        "level": 2
                    },
                    {
                        "areaCode": 371103000000,
                        "areaId": 338463,
                        "areaName": "岚山区",
                        "parent_id": 337848,
                        "level": 2
                    },
                    {
                        "areaCode": 371121000000,
                        "areaId": 338896,
                        "areaName": "五莲县",
                        "parent_id": 337848,
                        "level": 2
                    },
                    {
                        "areaCode": 371122000000,
                        "areaId": 339541,
                        "areaName": "莒县",
                        "parent_id": 337848,
                        "level": 2
                    },
                    {
                        "areaCode": 371171000000,
                        "areaId": 340758,
                        "areaName": "日照经济技术开发区",
                        "parent_id": 337848,
                        "level": 2
                    },
                    {
                        "areaCode": 371172000000,
                        "areaId": 340850,
                        "areaName": "日照国际海洋城",
                        "parent_id": 337848,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 371200000000,
                "areaId": 340927,
                "areaName": "莱芜市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 371201000000,
                        "areaId": 340928,
                        "areaName": "市辖区",
                        "parent_id": 340927,
                        "level": 2
                    },
                    {
                        "areaCode": 371202000000,
                        "areaId": 340929,
                        "areaName": "莱城区",
                        "parent_id": 340927,
                        "level": 2
                    },
                    {
                        "areaCode": 371203000000,
                        "areaId": 341785,
                        "areaName": "钢城区",
                        "parent_id": 340927,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 371300000000,
                "areaId": 342021,
                "areaName": "临沂市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 371301000000,
                        "areaId": 342022,
                        "areaName": "市辖区",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371302000000,
                        "areaId": 342023,
                        "areaName": "兰山区",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371311000000,
                        "areaId": 342498,
                        "areaName": "罗庄区",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371312000000,
                        "areaId": 342721,
                        "areaName": "河东区",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371321000000,
                        "areaId": 343065,
                        "areaName": "沂南县",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371322000000,
                        "areaId": 343655,
                        "areaName": "郯城县",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371323000000,
                        "areaId": 344285,
                        "areaName": "沂水县",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371324000000,
                        "areaId": 345367,
                        "areaName": "兰陵县",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371325000000,
                        "areaId": 346409,
                        "areaName": "费县",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371326000000,
                        "areaId": 346897,
                        "areaName": "平邑县",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371327000000,
                        "areaId": 347643,
                        "areaName": "莒南县",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371328000000,
                        "areaId": 348238,
                        "areaName": "蒙阴县",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371329000000,
                        "areaId": 348713,
                        "areaName": "临沭县",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371371000000,
                        "areaId": 349010,
                        "areaName": "临沂高新技术产业开发区",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371372000000,
                        "areaId": 349064,
                        "areaName": "临沂经济技术开发区",
                        "parent_id": 342021,
                        "level": 2
                    },
                    {
                        "areaCode": 371373000000,
                        "areaId": 349184,
                        "areaName": "临沂临港经济开发区",
                        "parent_id": 342021,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 371400000000,
                "areaId": 349339,
                "areaName": "德州市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 371401000000,
                        "areaId": 349340,
                        "areaName": "市辖区",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371402000000,
                        "areaId": 349341,
                        "areaName": "德城区",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371403000000,
                        "areaId": 349450,
                        "areaName": "陵城区",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371422000000,
                        "areaId": 350454,
                        "areaName": "宁津县",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371423000000,
                        "areaId": 351323,
                        "areaName": "庆云县",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371424000000,
                        "areaId": 351724,
                        "areaName": "临邑县",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371425000000,
                        "areaId": 351921,
                        "areaName": "齐河县",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371426000000,
                        "areaId": 352955,
                        "areaName": "平原县",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371427000000,
                        "areaId": 353149,
                        "areaName": "夏津县",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371428000000,
                        "areaId": 353474,
                        "areaName": "武城县",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371471000000,
                        "areaId": 353678,
                        "areaName": "德州经济技术开发区",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371472000000,
                        "areaId": 353754,
                        "areaName": "德州运河经济开发区",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371481000000,
                        "areaId": 353781,
                        "areaName": "乐陵市",
                        "parent_id": 349339,
                        "level": 2
                    },
                    {
                        "areaCode": 371482000000,
                        "areaId": 354289,
                        "areaName": "禹城市",
                        "parent_id": 349339,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 371500000000,
                "areaId": 354691,
                "areaName": "聊城市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 371501000000,
                        "areaId": 354692,
                        "areaName": "市辖区",
                        "parent_id": 354691,
                        "level": 2
                    },
                    {
                        "areaCode": 371502000000,
                        "areaId": 354693,
                        "areaName": "东昌府区",
                        "parent_id": 354691,
                        "level": 2
                    },
                    {
                        "areaCode": 371521000000,
                        "areaId": 355852,
                        "areaName": "阳谷县",
                        "parent_id": 354691,
                        "level": 2
                    },
                    {
                        "areaCode": 371522000000,
                        "areaId": 356745,
                        "areaName": "莘县",
                        "parent_id": 354691,
                        "level": 2
                    },
                    {
                        "areaCode": 371523000000,
                        "areaId": 357896,
                        "areaName": "茌平县",
                        "parent_id": 354691,
                        "level": 2
                    },
                    {
                        "areaCode": 371524000000,
                        "areaId": 358643,
                        "areaName": "东阿县",
                        "parent_id": 354691,
                        "level": 2
                    },
                    {
                        "areaCode": 371525000000,
                        "areaId": 359172,
                        "areaName": "冠县",
                        "parent_id": 354691,
                        "level": 2
                    },
                    {
                        "areaCode": 371526000000,
                        "areaId": 359952,
                        "areaName": "高唐县",
                        "parent_id": 354691,
                        "level": 2
                    },
                    {
                        "areaCode": 371581000000,
                        "areaId": 360610,
                        "areaName": "临清市",
                        "parent_id": 354691,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 371600000000,
                "areaId": 361281,
                "areaName": "滨州市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 371601000000,
                        "areaId": 361282,
                        "areaName": "市辖区",
                        "parent_id": 361281,
                        "level": 2
                    },
                    {
                        "areaCode": 371602000000,
                        "areaId": 361283,
                        "areaName": "滨城区",
                        "parent_id": 361281,
                        "level": 2
                    },
                    {
                        "areaCode": 371603000000,
                        "areaId": 362269,
                        "areaName": "沾化区",
                        "parent_id": 361281,
                        "level": 2
                    },
                    {
                        "areaCode": 371621000000,
                        "areaId": 362729,
                        "areaName": "惠民县",
                        "parent_id": 361281,
                        "level": 2
                    },
                    {
                        "areaCode": 371622000000,
                        "areaId": 363865,
                        "areaName": "阳信县",
                        "parent_id": 361281,
                        "level": 2
                    },
                    {
                        "areaCode": 371623000000,
                        "areaId": 364733,
                        "areaName": "无棣县",
                        "parent_id": 361281,
                        "level": 2
                    },
                    {
                        "areaCode": 371625000000,
                        "areaId": 365347,
                        "areaName": "博兴县",
                        "parent_id": 361281,
                        "level": 2
                    },
                    {
                        "areaCode": 371626000000,
                        "areaId": 365816,
                        "areaName": "邹平县",
                        "parent_id": 361281,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 371700000000,
                "areaId": 366684,
                "areaName": "菏泽市",
                "parent_id": 289611,
                "level": 1,
                "children": [
                    {
                        "areaCode": 371701000000,
                        "areaId": 366685,
                        "areaName": "市辖区",
                        "parent_id": 366684,
                        "level": 2
                    },
                    {
                        "areaCode": 371702000000,
                        "areaId": 366686,
                        "areaName": "牡丹区",
                        "parent_id": 366684,
                        "level": 2
                    },
                    {
                        "areaCode": 371703000000,
                        "areaId": 367297,
                        "areaName": "定陶区",
                        "parent_id": 366684,
                        "level": 2
                    },
                    {
                        "areaCode": 371721000000,
                        "areaId": 367677,
                        "areaName": "曹县",
                        "parent_id": 366684,
                        "level": 2
                    },
                    {
                        "areaCode": 371722000000,
                        "areaId": 368885,
                        "areaName": "单县",
                        "parent_id": 366684,
                        "level": 2
                    },
                    {
                        "areaCode": 371723000000,
                        "areaId": 369416,
                        "areaName": "成武县",
                        "parent_id": 366684,
                        "level": 2
                    },
                    {
                        "areaCode": 371724000000,
                        "areaId": 369915,
                        "areaName": "巨野县",
                        "parent_id": 366684,
                        "level": 2
                    },
                    {
                        "areaCode": 371725000000,
                        "areaId": 370805,
                        "areaName": "郓城县",
                        "parent_id": 366684,
                        "level": 2
                    },
                    {
                        "areaCode": 371726000000,
                        "areaId": 371869,
                        "areaName": "鄄城县",
                        "parent_id": 366684,
                        "level": 2
                    },
                    {
                        "areaCode": 371728000000,
                        "areaId": 372278,
                        "areaName": "东明县",
                        "parent_id": 366684,
                        "level": 2
                    },
                    {
                        "areaCode": 371771000000,
                        "areaId": 372702,
                        "areaName": "菏泽经济技术开发区",
                        "parent_id": 366684,
                        "level": 2
                    },
                    {
                        "areaCode": 371772000000,
                        "areaId": 372815,
                        "areaName": "菏泽高新技术开发区",
                        "parent_id": 366684,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 410000000000,
        "areaId": 372884,
        "areaName": "河南省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 410100000000,
                "areaId": 372885,
                "areaName": "郑州市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 410101000000,
                        "areaId": 372886,
                        "areaName": "市辖区",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410102000000,
                        "areaId": 372887,
                        "areaName": "中原区",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410103000000,
                        "areaId": 373053,
                        "areaName": "二七区",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410104000000,
                        "areaId": 373237,
                        "areaName": "管城回族区",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410105000000,
                        "areaId": 373385,
                        "areaName": "金水区",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410106000000,
                        "areaId": 373667,
                        "areaName": "上街区",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410108000000,
                        "areaId": 373733,
                        "areaName": "惠济区",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410122000000,
                        "areaId": 373817,
                        "areaName": "中牟县",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410171000000,
                        "areaId": 374161,
                        "areaName": "郑州经济技术开发区",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410172000000,
                        "areaId": 374229,
                        "areaName": "郑州高新技术产业开发区",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410173000000,
                        "areaId": 374293,
                        "areaName": "郑州航空港经济综合实验区",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410181000000,
                        "areaId": 374479,
                        "areaName": "巩义市",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410182000000,
                        "areaId": 374820,
                        "areaName": "荥阳市",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410183000000,
                        "areaId": 375138,
                        "areaName": "新密市",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410184000000,
                        "areaId": 375508,
                        "areaName": "新郑市",
                        "parent_id": 372885,
                        "level": 2
                    },
                    {
                        "areaCode": 410185000000,
                        "areaId": 375808,
                        "areaName": "登封市",
                        "parent_id": 372885,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 410200000000,
                "areaId": 376149,
                "areaName": "开封市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 410201000000,
                        "areaId": 376150,
                        "areaName": "市辖区",
                        "parent_id": 376149,
                        "level": 2
                    },
                    {
                        "areaCode": 410202000000,
                        "areaId": 376151,
                        "areaName": "龙亭区",
                        "parent_id": 376149,
                        "level": 2
                    },
                    {
                        "areaCode": 410203000000,
                        "areaId": 376314,
                        "areaName": "顺河回族区",
                        "parent_id": 376149,
                        "level": 2
                    },
                    {
                        "areaCode": 410204000000,
                        "areaId": 376391,
                        "areaName": "鼓楼区",
                        "parent_id": 376149,
                        "level": 2
                    },
                    {
                        "areaCode": 410205000000,
                        "areaId": 376442,
                        "areaName": "禹王台区",
                        "parent_id": 376149,
                        "level": 2
                    },
                    {
                        "areaCode": 410212000000,
                        "areaId": 376498,
                        "areaName": "祥符区",
                        "parent_id": 376149,
                        "level": 2
                    },
                    {
                        "areaCode": 410221000000,
                        "areaId": 376859,
                        "areaName": "杞县",
                        "parent_id": 376149,
                        "level": 2
                    },
                    {
                        "areaCode": 410222000000,
                        "areaId": 377482,
                        "areaName": "通许县",
                        "parent_id": 376149,
                        "level": 2
                    },
                    {
                        "areaCode": 410223000000,
                        "areaId": 377806,
                        "areaName": "尉氏县",
                        "parent_id": 376149,
                        "level": 2
                    },
                    {
                        "areaCode": 410225000000,
                        "areaId": 378345,
                        "areaName": "兰考县",
                        "parent_id": 376149,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 410300000000,
                "areaId": 378835,
                "areaName": "洛阳市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 410301000000,
                        "areaId": 378836,
                        "areaName": "市辖区",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410302000000,
                        "areaId": 378837,
                        "areaName": "老城区",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410303000000,
                        "areaId": 378894,
                        "areaName": "西工区",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410304000000,
                        "areaId": 378969,
                        "areaName": "瀍河回族区",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410305000000,
                        "areaId": 379008,
                        "areaName": "涧西区",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410306000000,
                        "areaId": 379092,
                        "areaName": "吉利区",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410311000000,
                        "areaId": 379135,
                        "areaName": "洛龙区",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410322000000,
                        "areaId": 379424,
                        "areaName": "孟津县",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410323000000,
                        "areaId": 379669,
                        "areaName": "新安县",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410324000000,
                        "areaId": 379992,
                        "areaName": "栾川县",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410325000000,
                        "areaId": 380220,
                        "areaName": "嵩县",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410326000000,
                        "areaId": 380565,
                        "areaName": "汝阳县",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410327000000,
                        "areaId": 380800,
                        "areaName": "宜阳县",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410328000000,
                        "areaId": 381179,
                        "areaName": "洛宁县",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410329000000,
                        "areaId": 381588,
                        "areaName": "伊川县",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410371000000,
                        "areaId": 381977,
                        "areaName": "洛阳高新技术产业开发区",
                        "parent_id": 378835,
                        "level": 2
                    },
                    {
                        "areaCode": 410381000000,
                        "areaId": 382018,
                        "areaName": "偃师市",
                        "parent_id": 378835,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 410400000000,
                "areaId": 382278,
                "areaName": "平顶山市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 410401000000,
                        "areaId": 382279,
                        "areaName": "市辖区",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410402000000,
                        "areaId": 382280,
                        "areaName": "新华区",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410403000000,
                        "areaId": 382357,
                        "areaName": "卫东区",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410404000000,
                        "areaId": 382428,
                        "areaName": "石龙区",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410411000000,
                        "areaId": 382447,
                        "areaName": "湛河区",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410421000000,
                        "areaId": 382558,
                        "areaName": "宝丰县",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410422000000,
                        "areaId": 382902,
                        "areaName": "叶县",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410423000000,
                        "areaId": 383473,
                        "areaName": "鲁山县",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410425000000,
                        "areaId": 384057,
                        "areaName": "郏县",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410471000000,
                        "areaId": 384450,
                        "areaName": "平顶山高新技术产业开发区",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410472000000,
                        "areaId": 384483,
                        "areaName": "平顶山市新城区",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410481000000,
                        "areaId": 384531,
                        "areaName": "舞钢市",
                        "parent_id": 382278,
                        "level": 2
                    },
                    {
                        "areaCode": 410482000000,
                        "areaId": 384763,
                        "areaName": "汝州市",
                        "parent_id": 382278,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 410500000000,
                "areaId": 385246,
                "areaName": "安阳市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 410501000000,
                        "areaId": 385247,
                        "areaName": "市辖区",
                        "parent_id": 385246,
                        "level": 2
                    },
                    {
                        "areaCode": 410502000000,
                        "areaId": 385248,
                        "areaName": "文峰区",
                        "parent_id": 385246,
                        "level": 2
                    },
                    {
                        "areaCode": 410503000000,
                        "areaId": 385375,
                        "areaName": "北关区",
                        "parent_id": 385246,
                        "level": 2
                    },
                    {
                        "areaCode": 410505000000,
                        "areaId": 385502,
                        "areaName": "殷都区",
                        "parent_id": 385246,
                        "level": 2
                    },
                    {
                        "areaCode": 410506000000,
                        "areaId": 385598,
                        "areaName": "龙安区",
                        "parent_id": 385246,
                        "level": 2
                    },
                    {
                        "areaCode": 410522000000,
                        "areaId": 385758,
                        "areaName": "安阳县",
                        "parent_id": 385246,
                        "level": 2
                    },
                    {
                        "areaCode": 410523000000,
                        "areaId": 386378,
                        "areaName": "汤阴县",
                        "parent_id": 385246,
                        "level": 2
                    },
                    {
                        "areaCode": 410526000000,
                        "areaId": 386695,
                        "areaName": "滑县",
                        "parent_id": 385246,
                        "level": 2
                    },
                    {
                        "areaCode": 410527000000,
                        "areaId": 387738,
                        "areaName": "内黄县",
                        "parent_id": 385246,
                        "level": 2
                    },
                    {
                        "areaCode": 410571000000,
                        "areaId": 388288,
                        "areaName": "安阳高新技术产业开发区",
                        "parent_id": 385246,
                        "level": 2
                    },
                    {
                        "areaCode": 410581000000,
                        "areaId": 388325,
                        "areaName": "林州市",
                        "parent_id": 385246,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 410600000000,
                "areaId": 388929,
                "areaName": "鹤壁市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 410601000000,
                        "areaId": 388930,
                        "areaName": "市辖区",
                        "parent_id": 388929,
                        "level": 2
                    },
                    {
                        "areaCode": 410602000000,
                        "areaId": 388931,
                        "areaName": "鹤山区",
                        "parent_id": 388929,
                        "level": 2
                    },
                    {
                        "areaCode": 410603000000,
                        "areaId": 389020,
                        "areaName": "山城区",
                        "parent_id": 388929,
                        "level": 2
                    },
                    {
                        "areaCode": 410611000000,
                        "areaId": 389130,
                        "areaName": "淇滨区",
                        "parent_id": 388929,
                        "level": 2
                    },
                    {
                        "areaCode": 410621000000,
                        "areaId": 389279,
                        "areaName": "浚县",
                        "parent_id": 388929,
                        "level": 2
                    },
                    {
                        "areaCode": 410622000000,
                        "areaId": 389755,
                        "areaName": "淇县",
                        "parent_id": 388929,
                        "level": 2
                    },
                    {
                        "areaCode": 410671000000,
                        "areaId": 389941,
                        "areaName": "鹤壁经济技术开发区",
                        "parent_id": 388929,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 410700000000,
                "areaId": 389957,
                "areaName": "新乡市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 410701000000,
                        "areaId": 389958,
                        "areaName": "市辖区",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410702000000,
                        "areaId": 389959,
                        "areaName": "红旗区",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410703000000,
                        "areaId": 390045,
                        "areaName": "卫滨区",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410704000000,
                        "areaId": 390108,
                        "areaName": "凤泉区",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410711000000,
                        "areaId": 390160,
                        "areaName": "牧野区",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410721000000,
                        "areaId": 390263,
                        "areaName": "新乡县",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410724000000,
                        "areaId": 390450,
                        "areaName": "获嘉县",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410725000000,
                        "areaId": 390692,
                        "areaName": "原阳县",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410726000000,
                        "areaId": 391165,
                        "areaName": "延津县",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410727000000,
                        "areaId": 391528,
                        "areaName": "封丘县",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410728000000,
                        "areaId": 392156,
                        "areaName": "长垣县",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410771000000,
                        "areaId": 392787,
                        "areaName": "新乡高新技术产业开发区",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410772000000,
                        "areaId": 392815,
                        "areaName": "新乡经济技术开发区",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410773000000,
                        "areaId": 392831,
                        "areaName": "新乡市平原城乡一体化示范区",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410781000000,
                        "areaId": 392970,
                        "areaName": "卫辉市",
                        "parent_id": 389957,
                        "level": 2
                    },
                    {
                        "areaCode": 410782000000,
                        "areaId": 393351,
                        "areaName": "辉县市",
                        "parent_id": 389957,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 410800000000,
                "areaId": 393933,
                "areaName": "焦作市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 410801000000,
                        "areaId": 393934,
                        "areaName": "市辖区",
                        "parent_id": 393933,
                        "level": 2
                    },
                    {
                        "areaCode": 410802000000,
                        "areaId": 393935,
                        "areaName": "解放区",
                        "parent_id": 393933,
                        "level": 2
                    },
                    {
                        "areaCode": 410803000000,
                        "areaId": 394002,
                        "areaName": "中站区",
                        "parent_id": 393933,
                        "level": 2
                    },
                    {
                        "areaCode": 410804000000,
                        "areaId": 394062,
                        "areaName": "马村区",
                        "parent_id": 393933,
                        "level": 2
                    },
                    {
                        "areaCode": 410811000000,
                        "areaId": 394148,
                        "areaName": "山阳区",
                        "parent_id": 393933,
                        "level": 2
                    },
                    {
                        "areaCode": 410821000000,
                        "areaId": 394212,
                        "areaName": "修武县",
                        "parent_id": 393933,
                        "level": 2
                    },
                    {
                        "areaCode": 410822000000,
                        "areaId": 394415,
                        "areaName": "博爱县",
                        "parent_id": 393933,
                        "level": 2
                    },
                    {
                        "areaCode": 410823000000,
                        "areaId": 394643,
                        "areaName": "武陟县",
                        "parent_id": 393933,
                        "level": 2
                    },
                    {
                        "areaCode": 410825000000,
                        "areaId": 395011,
                        "areaName": "温县",
                        "parent_id": 393933,
                        "level": 2
                    },
                    {
                        "areaCode": 410871000000,
                        "areaId": 395292,
                        "areaName": "焦作城乡一体化示范区",
                        "parent_id": 393933,
                        "level": 2
                    },
                    {
                        "areaCode": 410882000000,
                        "areaId": 395403,
                        "areaName": "沁阳市",
                        "parent_id": 393933,
                        "level": 2
                    },
                    {
                        "areaCode": 410883000000,
                        "areaId": 395757,
                        "areaName": "孟州市",
                        "parent_id": 393933,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 410900000000,
                "areaId": 396058,
                "areaName": "濮阳市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 410901000000,
                        "areaId": 396059,
                        "areaName": "市辖区",
                        "parent_id": 396058,
                        "level": 2
                    },
                    {
                        "areaCode": 410902000000,
                        "areaId": 396060,
                        "areaName": "华龙区",
                        "parent_id": 396058,
                        "level": 2
                    },
                    {
                        "areaCode": 410922000000,
                        "areaId": 396223,
                        "areaName": "清丰县",
                        "parent_id": 396058,
                        "level": 2
                    },
                    {
                        "areaCode": 410923000000,
                        "areaId": 396750,
                        "areaName": "南乐县",
                        "parent_id": 396058,
                        "level": 2
                    },
                    {
                        "areaCode": 410926000000,
                        "areaId": 397090,
                        "areaName": "范县",
                        "parent_id": 396058,
                        "level": 2
                    },
                    {
                        "areaCode": 410927000000,
                        "areaId": 397684,
                        "areaName": "台前县",
                        "parent_id": 396058,
                        "level": 2
                    },
                    {
                        "areaCode": 410928000000,
                        "areaId": 398069,
                        "areaName": "濮阳县",
                        "parent_id": 396058,
                        "level": 2
                    },
                    {
                        "areaCode": 410971000000,
                        "areaId": 399076,
                        "areaName": "河南濮阳工业园区",
                        "parent_id": 396058,
                        "level": 2
                    },
                    {
                        "areaCode": 410972000000,
                        "areaId": 399101,
                        "areaName": "濮阳经济技术开发区",
                        "parent_id": 396058,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 411000000000,
                "areaId": 399267,
                "areaName": "许昌市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 411001000000,
                        "areaId": 399268,
                        "areaName": "市辖区",
                        "parent_id": 399267,
                        "level": 2
                    },
                    {
                        "areaCode": 411002000000,
                        "areaId": 399269,
                        "areaName": "魏都区",
                        "parent_id": 399267,
                        "level": 2
                    },
                    {
                        "areaCode": 411003000000,
                        "areaId": 399380,
                        "areaName": "建安区",
                        "parent_id": 399267,
                        "level": 2
                    },
                    {
                        "areaCode": 411024000000,
                        "areaId": 399836,
                        "areaName": "鄢陵县",
                        "parent_id": 399267,
                        "level": 2
                    },
                    {
                        "areaCode": 411025000000,
                        "areaId": 400235,
                        "areaName": "襄城县",
                        "parent_id": 399267,
                        "level": 2
                    },
                    {
                        "areaCode": 411071000000,
                        "areaId": 400700,
                        "areaName": "许昌经济技术开发区",
                        "parent_id": 399267,
                        "level": 2
                    },
                    {
                        "areaCode": 411081000000,
                        "areaId": 400728,
                        "areaName": "禹州市",
                        "parent_id": 399267,
                        "level": 2
                    },
                    {
                        "areaCode": 411082000000,
                        "areaId": 401430,
                        "areaName": "长葛市",
                        "parent_id": 399267,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 411100000000,
                "areaId": 401821,
                "areaName": "漯河市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 411101000000,
                        "areaId": 401822,
                        "areaName": "市辖区",
                        "parent_id": 401821,
                        "level": 2
                    },
                    {
                        "areaCode": 411102000000,
                        "areaId": 401823,
                        "areaName": "源汇区",
                        "parent_id": 401821,
                        "level": 2
                    },
                    {
                        "areaCode": 411103000000,
                        "areaId": 401959,
                        "areaName": "郾城区",
                        "parent_id": 401821,
                        "level": 2
                    },
                    {
                        "areaCode": 411104000000,
                        "areaId": 402168,
                        "areaName": "召陵区",
                        "parent_id": 401821,
                        "level": 2
                    },
                    {
                        "areaCode": 411121000000,
                        "areaId": 402383,
                        "areaName": "舞阳县",
                        "parent_id": 401821,
                        "level": 2
                    },
                    {
                        "areaCode": 411122000000,
                        "areaId": 402795,
                        "areaName": "临颍县",
                        "parent_id": 401821,
                        "level": 2
                    },
                    {
                        "areaCode": 411171000000,
                        "areaId": 403178,
                        "areaName": "漯河经济技术开发区",
                        "parent_id": 401821,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 411200000000,
                "areaId": 403210,
                "areaName": "三门峡市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 411201000000,
                        "areaId": 403211,
                        "areaName": "市辖区",
                        "parent_id": 403210,
                        "level": 2
                    },
                    {
                        "areaCode": 411202000000,
                        "areaId": 403212,
                        "areaName": "湖滨区",
                        "parent_id": 403210,
                        "level": 2
                    },
                    {
                        "areaCode": 411203000000,
                        "areaId": 403309,
                        "areaName": "陕州区",
                        "parent_id": 403210,
                        "level": 2
                    },
                    {
                        "areaCode": 411221000000,
                        "areaId": 403595,
                        "areaName": "渑池县",
                        "parent_id": 403210,
                        "level": 2
                    },
                    {
                        "areaCode": 411224000000,
                        "areaId": 403857,
                        "areaName": "卢氏县",
                        "parent_id": 403210,
                        "level": 2
                    },
                    {
                        "areaCode": 411271000000,
                        "areaId": 404244,
                        "areaName": "河南三门峡经济开发区",
                        "parent_id": 403210,
                        "level": 2
                    },
                    {
                        "areaCode": 411281000000,
                        "areaId": 404252,
                        "areaName": "义马市",
                        "parent_id": 403210,
                        "level": 2
                    },
                    {
                        "areaCode": 411282000000,
                        "areaId": 404295,
                        "areaName": "灵宝市",
                        "parent_id": 403210,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 411300000000,
                "areaId": 404779,
                "areaName": "南阳市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 411301000000,
                        "areaId": 404780,
                        "areaName": "市辖区",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411302000000,
                        "areaId": 404781,
                        "areaName": "宛城区",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411303000000,
                        "areaId": 405047,
                        "areaName": "卧龙区",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411321000000,
                        "areaId": 405359,
                        "areaName": "南召县",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411322000000,
                        "areaId": 405729,
                        "areaName": "方城县",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411323000000,
                        "areaId": 406314,
                        "areaName": "西峡县",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411324000000,
                        "areaId": 406633,
                        "areaName": "镇平县",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411325000000,
                        "areaId": 407086,
                        "areaName": "内乡县",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411326000000,
                        "areaId": 407399,
                        "areaName": "淅川县",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411327000000,
                        "areaId": 407917,
                        "areaName": "社旗县",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411328000000,
                        "areaId": 408191,
                        "areaName": "唐河县",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411329000000,
                        "areaId": 408753,
                        "areaName": "新野县",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411330000000,
                        "areaId": 409041,
                        "areaName": "桐柏县",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411371000000,
                        "areaId": 409277,
                        "areaName": "南阳高新技术产业开发区",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411372000000,
                        "areaId": 409300,
                        "areaName": "南阳市城乡一体化示范区",
                        "parent_id": 404779,
                        "level": 2
                    },
                    {
                        "areaCode": 411381000000,
                        "areaId": 409361,
                        "areaName": "邓州市",
                        "parent_id": 404779,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 411400000000,
                "areaId": 410023,
                "areaName": "商丘市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 411401000000,
                        "areaId": 410024,
                        "areaName": "市辖区",
                        "parent_id": 410023,
                        "level": 2
                    },
                    {
                        "areaCode": 411402000000,
                        "areaId": 410025,
                        "areaName": "梁园区",
                        "parent_id": 410023,
                        "level": 2
                    },
                    {
                        "areaCode": 411403000000,
                        "areaId": 410289,
                        "areaName": "睢阳区",
                        "parent_id": 410023,
                        "level": 2
                    },
                    {
                        "areaCode": 411421000000,
                        "areaId": 410692,
                        "areaName": "民权县",
                        "parent_id": 410023,
                        "level": 2
                    },
                    {
                        "areaCode": 411422000000,
                        "areaId": 411272,
                        "areaName": "睢县",
                        "parent_id": 410023,
                        "level": 2
                    },
                    {
                        "areaCode": 411423000000,
                        "areaId": 411850,
                        "areaName": "宁陵县",
                        "parent_id": 410023,
                        "level": 2
                    },
                    {
                        "areaCode": 411424000000,
                        "areaId": 412230,
                        "areaName": "柘城县",
                        "parent_id": 410023,
                        "level": 2
                    },
                    {
                        "areaCode": 411425000000,
                        "areaId": 412770,
                        "areaName": "虞城县",
                        "parent_id": 410023,
                        "level": 2
                    },
                    {
                        "areaCode": 411426000000,
                        "areaId": 413417,
                        "areaName": "夏邑县",
                        "parent_id": 410023,
                        "level": 2
                    },
                    {
                        "areaCode": 411471000000,
                        "areaId": 414187,
                        "areaName": "豫东综合物流产业聚集区",
                        "parent_id": 410023,
                        "level": 2
                    },
                    {
                        "areaCode": 411472000000,
                        "areaId": 414260,
                        "areaName": "河南商丘经济开发区",
                        "parent_id": 410023,
                        "level": 2
                    },
                    {
                        "areaCode": 411481000000,
                        "areaId": 414304,
                        "areaName": "永城市",
                        "parent_id": 410023,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 411500000000,
                "areaId": 415104,
                "areaName": "信阳市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 411501000000,
                        "areaId": 415105,
                        "areaName": "市辖区",
                        "parent_id": 415104,
                        "level": 2
                    },
                    {
                        "areaCode": 411502000000,
                        "areaId": 415106,
                        "areaName": "浉河区",
                        "parent_id": 415104,
                        "level": 2
                    },
                    {
                        "areaCode": 411503000000,
                        "areaId": 415383,
                        "areaName": "平桥区",
                        "parent_id": 415104,
                        "level": 2
                    },
                    {
                        "areaCode": 411521000000,
                        "areaId": 415732,
                        "areaName": "罗山县",
                        "parent_id": 415104,
                        "level": 2
                    },
                    {
                        "areaCode": 411522000000,
                        "areaId": 416059,
                        "areaName": "光山县",
                        "parent_id": 415104,
                        "level": 2
                    },
                    {
                        "areaCode": 411523000000,
                        "areaId": 416439,
                        "areaName": "新县",
                        "parent_id": 415104,
                        "level": 2
                    },
                    {
                        "areaCode": 411524000000,
                        "areaId": 416662,
                        "areaName": "商城县",
                        "parent_id": 415104,
                        "level": 2
                    },
                    {
                        "areaCode": 411525000000,
                        "areaId": 417053,
                        "areaName": "固始县",
                        "parent_id": 415104,
                        "level": 2
                    },
                    {
                        "areaCode": 411526000000,
                        "areaId": 417736,
                        "areaName": "潢川县",
                        "parent_id": 415104,
                        "level": 2
                    },
                    {
                        "areaCode": 411527000000,
                        "areaId": 418046,
                        "areaName": "淮滨县",
                        "parent_id": 415104,
                        "level": 2
                    },
                    {
                        "areaCode": 411528000000,
                        "areaId": 418361,
                        "areaName": "息县",
                        "parent_id": 415104,
                        "level": 2
                    },
                    {
                        "areaCode": 411571000000,
                        "areaId": 418745,
                        "areaName": "信阳高新技术产业开发区",
                        "parent_id": 415104,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 411600000000,
                "areaId": 418757,
                "areaName": "周口市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 411601000000,
                        "areaId": 418758,
                        "areaName": "市辖区",
                        "parent_id": 418757,
                        "level": 2
                    },
                    {
                        "areaCode": 411602000000,
                        "areaId": 418759,
                        "areaName": "川汇区",
                        "parent_id": 418757,
                        "level": 2
                    },
                    {
                        "areaCode": 411621000000,
                        "areaId": 418930,
                        "areaName": "扶沟县",
                        "parent_id": 418757,
                        "level": 2
                    },
                    {
                        "areaCode": 411622000000,
                        "areaId": 419359,
                        "areaName": "西华县",
                        "parent_id": 418757,
                        "level": 2
                    },
                    {
                        "areaCode": 411623000000,
                        "areaId": 419863,
                        "areaName": "商水县",
                        "parent_id": 418757,
                        "level": 2
                    },
                    {
                        "areaCode": 411624000000,
                        "areaId": 420478,
                        "areaName": "沈丘县",
                        "parent_id": 418757,
                        "level": 2
                    },
                    {
                        "areaCode": 411625000000,
                        "areaId": 421073,
                        "areaName": "郸城县",
                        "parent_id": 418757,
                        "level": 2
                    },
                    {
                        "areaCode": 411626000000,
                        "areaId": 421613,
                        "areaName": "淮阳县",
                        "parent_id": 418757,
                        "level": 2
                    },
                    {
                        "areaCode": 411627000000,
                        "areaId": 422107,
                        "areaName": "太康县",
                        "parent_id": 418757,
                        "level": 2
                    },
                    {
                        "areaCode": 411628000000,
                        "areaId": 422906,
                        "areaName": "鹿邑县",
                        "parent_id": 418757,
                        "level": 2
                    },
                    {
                        "areaCode": 411671000000,
                        "areaId": 423487,
                        "areaName": "河南周口经济开发区",
                        "parent_id": 418757,
                        "level": 2
                    },
                    {
                        "areaCode": 411681000000,
                        "areaId": 423511,
                        "areaName": "项城市",
                        "parent_id": 418757,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 411700000000,
                "areaId": 424034,
                "areaName": "驻马店市",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 411701000000,
                        "areaId": 424035,
                        "areaName": "市辖区",
                        "parent_id": 424034,
                        "level": 2
                    },
                    {
                        "areaCode": 411702000000,
                        "areaId": 424036,
                        "areaName": "驿城区",
                        "parent_id": 424034,
                        "level": 2
                    },
                    {
                        "areaCode": 411721000000,
                        "areaId": 424263,
                        "areaName": "西平县",
                        "parent_id": 424034,
                        "level": 2
                    },
                    {
                        "areaCode": 411722000000,
                        "areaId": 424570,
                        "areaName": "上蔡县",
                        "parent_id": 424034,
                        "level": 2
                    },
                    {
                        "areaCode": 411723000000,
                        "areaId": 425057,
                        "areaName": "平舆县",
                        "parent_id": 424034,
                        "level": 2
                    },
                    {
                        "areaCode": 411724000000,
                        "areaId": 425301,
                        "areaName": "正阳县",
                        "parent_id": 424034,
                        "level": 2
                    },
                    {
                        "areaCode": 411725000000,
                        "areaId": 425617,
                        "areaName": "确山县",
                        "parent_id": 424034,
                        "level": 2
                    },
                    {
                        "areaCode": 411726000000,
                        "areaId": 425833,
                        "areaName": "泌阳县",
                        "parent_id": 424034,
                        "level": 2
                    },
                    {
                        "areaCode": 411727000000,
                        "areaId": 426214,
                        "areaName": "汝南县",
                        "parent_id": 424034,
                        "level": 2
                    },
                    {
                        "areaCode": 411728000000,
                        "areaId": 426514,
                        "areaName": "遂平县",
                        "parent_id": 424034,
                        "level": 2
                    },
                    {
                        "areaCode": 411729000000,
                        "areaId": 426734,
                        "areaName": "新蔡县",
                        "parent_id": 424034,
                        "level": 2
                    },
                    {
                        "areaCode": 411771000000,
                        "areaId": 427118,
                        "areaName": "河南驻马店经济开发区",
                        "parent_id": 424034,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 419000000000,
                "areaId": 427149,
                "areaName": "直辖县",
                "parent_id": 372884,
                "level": 1,
                "children": [
                    {
                        "areaCode": 419001000000,
                        "areaId": 427150,
                        "areaName": "济源市",
                        "parent_id": 427149,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 420000000000,
        "areaId": 427715,
        "areaName": "湖北省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 420100000000,
                "areaId": 427716,
                "areaName": "武汉市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 420101000000,
                        "areaId": 427717,
                        "areaName": "市辖区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420102000000,
                        "areaId": 427718,
                        "areaName": "江岸区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420103000000,
                        "areaId": 427891,
                        "areaName": "江汉区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420104000000,
                        "areaId": 428017,
                        "areaName": "硚口区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420105000000,
                        "areaId": 428165,
                        "areaName": "汉阳区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420106000000,
                        "areaId": 428295,
                        "areaName": "武昌区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420107000000,
                        "areaId": 428454,
                        "areaName": "青山区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420111000000,
                        "areaId": 428572,
                        "areaName": "洪山区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420112000000,
                        "areaId": 428881,
                        "areaName": "东西湖区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420113000000,
                        "areaId": 429048,
                        "areaName": "汉南区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420114000000,
                        "areaId": 429119,
                        "areaName": "蔡甸区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420115000000,
                        "areaId": 429535,
                        "areaName": "江夏区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420116000000,
                        "areaId": 429980,
                        "areaName": "黄陂区",
                        "parent_id": 427716,
                        "level": 2
                    },
                    {
                        "areaCode": 420117000000,
                        "areaId": 430680,
                        "areaName": "新洲区",
                        "parent_id": 427716,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 420200000000,
                "areaId": 431354,
                "areaName": "黄石市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 420201000000,
                        "areaId": 431355,
                        "areaName": "市辖区",
                        "parent_id": 431354,
                        "level": 2
                    },
                    {
                        "areaCode": 420202000000,
                        "areaId": 431356,
                        "areaName": "黄石港区",
                        "parent_id": 431354,
                        "level": 2
                    },
                    {
                        "areaCode": 420203000000,
                        "areaId": 431394,
                        "areaName": "西塞山区",
                        "parent_id": 431354,
                        "level": 2
                    },
                    {
                        "areaCode": 420204000000,
                        "areaId": 431436,
                        "areaName": "下陆区",
                        "parent_id": 431354,
                        "level": 2
                    },
                    {
                        "areaCode": 420205000000,
                        "areaId": 431479,
                        "areaName": "铁山区",
                        "parent_id": 431354,
                        "level": 2
                    },
                    {
                        "areaCode": 420222000000,
                        "areaId": 431495,
                        "areaName": "阳新县",
                        "parent_id": 431354,
                        "level": 2
                    },
                    {
                        "areaCode": 420281000000,
                        "areaId": 431976,
                        "areaName": "大冶市",
                        "parent_id": 431354,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 420300000000,
                "areaId": 432416,
                "areaName": "十堰市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 420301000000,
                        "areaId": 432417,
                        "areaName": "市辖区",
                        "parent_id": 432416,
                        "level": 2
                    },
                    {
                        "areaCode": 420302000000,
                        "areaId": 432418,
                        "areaName": "茅箭区",
                        "parent_id": 432416,
                        "level": 2
                    },
                    {
                        "areaCode": 420303000000,
                        "areaId": 432500,
                        "areaName": "张湾区",
                        "parent_id": 432416,
                        "level": 2
                    },
                    {
                        "areaCode": 420304000000,
                        "areaId": 432617,
                        "areaName": "郧阳区",
                        "parent_id": 432416,
                        "level": 2
                    },
                    {
                        "areaCode": 420322000000,
                        "areaId": 432986,
                        "areaName": "郧西县",
                        "parent_id": 432416,
                        "level": 2
                    },
                    {
                        "areaCode": 420323000000,
                        "areaId": 433348,
                        "areaName": "竹山县",
                        "parent_id": 432416,
                        "level": 2
                    },
                    {
                        "areaCode": 420324000000,
                        "areaId": 433616,
                        "areaName": "竹溪县",
                        "parent_id": 432416,
                        "level": 2
                    },
                    {
                        "areaCode": 420325000000,
                        "areaId": 433993,
                        "areaName": "房县",
                        "parent_id": 432416,
                        "level": 2
                    },
                    {
                        "areaCode": 420381000000,
                        "areaId": 434319,
                        "areaName": "丹江口市",
                        "parent_id": 432416,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 420500000000,
                "areaId": 434610,
                "areaName": "宜昌市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 420501000000,
                        "areaId": 434611,
                        "areaName": "市辖区",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420502000000,
                        "areaId": 434612,
                        "areaName": "西陵区",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420503000000,
                        "areaId": 434728,
                        "areaName": "伍家岗区",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420504000000,
                        "areaId": 434795,
                        "areaName": "点军区",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420505000000,
                        "areaId": 434854,
                        "areaName": "猇亭区",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420506000000,
                        "areaId": 434884,
                        "areaName": "夷陵区",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420525000000,
                        "areaId": 435096,
                        "areaName": "远安县",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420526000000,
                        "areaId": 435221,
                        "areaName": "兴山县",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420527000000,
                        "areaId": 435326,
                        "areaName": "秭归县",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420528000000,
                        "areaId": 435533,
                        "areaName": "长阳土家族自治县",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420529000000,
                        "areaId": 435710,
                        "areaName": "五峰土家族自治县",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420581000000,
                        "areaId": 435827,
                        "areaName": "宜都市",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420582000000,
                        "areaId": 435994,
                        "areaName": "当阳市",
                        "parent_id": 434610,
                        "level": 2
                    },
                    {
                        "areaCode": 420583000000,
                        "areaId": 436178,
                        "areaName": "枝江市",
                        "parent_id": 434610,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 420600000000,
                "areaId": 436406,
                "areaName": "襄阳市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 420601000000,
                        "areaId": 436407,
                        "areaName": "市辖区",
                        "parent_id": 436406,
                        "level": 2
                    },
                    {
                        "areaCode": 420602000000,
                        "areaId": 436408,
                        "areaName": "襄城区",
                        "parent_id": 436406,
                        "level": 2
                    },
                    {
                        "areaCode": 420606000000,
                        "areaId": 436592,
                        "areaName": "樊城区",
                        "parent_id": 436406,
                        "level": 2
                    },
                    {
                        "areaCode": 420607000000,
                        "areaId": 436816,
                        "areaName": "襄州区",
                        "parent_id": 436406,
                        "level": 2
                    },
                    {
                        "areaCode": 420624000000,
                        "areaId": 437311,
                        "areaName": "南漳县",
                        "parent_id": 436406,
                        "level": 2
                    },
                    {
                        "areaCode": 420625000000,
                        "areaId": 437634,
                        "areaName": "谷城县",
                        "parent_id": 436406,
                        "level": 2
                    },
                    {
                        "areaCode": 420626000000,
                        "areaId": 437936,
                        "areaName": "保康县",
                        "parent_id": 436406,
                        "level": 2
                    },
                    {
                        "areaCode": 420682000000,
                        "areaId": 438212,
                        "areaName": "老河口市",
                        "parent_id": 436406,
                        "level": 2
                    },
                    {
                        "areaCode": 420683000000,
                        "areaId": 438486,
                        "areaName": "枣阳市",
                        "parent_id": 436406,
                        "level": 2
                    },
                    {
                        "areaCode": 420684000000,
                        "areaId": 439077,
                        "areaName": "宜城市",
                        "parent_id": 436406,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 420700000000,
                "areaId": 439326,
                "areaName": "鄂州市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 420701000000,
                        "areaId": 439327,
                        "areaName": "市辖区",
                        "parent_id": 439326,
                        "level": 2
                    },
                    {
                        "areaCode": 420702000000,
                        "areaId": 439328,
                        "areaName": "梁子湖区",
                        "parent_id": 439326,
                        "level": 2
                    },
                    {
                        "areaCode": 420703000000,
                        "areaId": 439421,
                        "areaName": "华容区",
                        "parent_id": 439326,
                        "level": 2
                    },
                    {
                        "areaCode": 420704000000,
                        "areaId": 439544,
                        "areaName": "鄂城区",
                        "parent_id": 439326,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 420800000000,
                "areaId": 439740,
                "areaName": "荆门市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 420801000000,
                        "areaId": 439741,
                        "areaName": "市辖区",
                        "parent_id": 439740,
                        "level": 2
                    },
                    {
                        "areaCode": 420802000000,
                        "areaId": 439742,
                        "areaName": "东宝区",
                        "parent_id": 439740,
                        "level": 2
                    },
                    {
                        "areaCode": 420804000000,
                        "areaId": 439963,
                        "areaName": "掇刀区",
                        "parent_id": 439740,
                        "level": 2
                    },
                    {
                        "areaCode": 420821000000,
                        "areaId": 440093,
                        "areaName": "京山县",
                        "parent_id": 439740,
                        "level": 2
                    },
                    {
                        "areaCode": 420822000000,
                        "areaId": 440545,
                        "areaName": "沙洋县",
                        "parent_id": 439740,
                        "level": 2
                    },
                    {
                        "areaCode": 420881000000,
                        "areaId": 440855,
                        "areaName": "钟祥市",
                        "parent_id": 439740,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 420900000000,
                "areaId": 441459,
                "areaName": "孝感市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 420901000000,
                        "areaId": 441460,
                        "areaName": "市辖区",
                        "parent_id": 441459,
                        "level": 2
                    },
                    {
                        "areaCode": 420902000000,
                        "areaId": 441461,
                        "areaName": "孝南区",
                        "parent_id": 441459,
                        "level": 2
                    },
                    {
                        "areaCode": 420921000000,
                        "areaId": 442002,
                        "areaName": "孝昌县",
                        "parent_id": 441459,
                        "level": 2
                    },
                    {
                        "areaCode": 420922000000,
                        "areaId": 442474,
                        "areaName": "大悟县",
                        "parent_id": 441459,
                        "level": 2
                    },
                    {
                        "areaCode": 420923000000,
                        "areaId": 442867,
                        "areaName": "云梦县",
                        "parent_id": 441459,
                        "level": 2
                    },
                    {
                        "areaCode": 420981000000,
                        "areaId": 443178,
                        "areaName": "应城市",
                        "parent_id": 441459,
                        "level": 2
                    },
                    {
                        "areaCode": 420982000000,
                        "areaId": 443617,
                        "areaName": "安陆市",
                        "parent_id": 441459,
                        "level": 2
                    },
                    {
                        "areaCode": 420984000000,
                        "areaId": 444030,
                        "areaName": "汉川市",
                        "parent_id": 441459,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 421000000000,
                "areaId": 444691,
                "areaName": "荆州市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 421001000000,
                        "areaId": 444692,
                        "areaName": "市辖区",
                        "parent_id": 444691,
                        "level": 2
                    },
                    {
                        "areaCode": 421002000000,
                        "areaId": 444693,
                        "areaName": "沙市区",
                        "parent_id": 444691,
                        "level": 2
                    },
                    {
                        "areaCode": 421003000000,
                        "areaId": 444821,
                        "areaName": "荆州区",
                        "parent_id": 444691,
                        "level": 2
                    },
                    {
                        "areaCode": 421022000000,
                        "areaId": 445015,
                        "areaName": "公安县",
                        "parent_id": 444691,
                        "level": 2
                    },
                    {
                        "areaCode": 421023000000,
                        "areaId": 445424,
                        "areaName": "监利县",
                        "parent_id": 444691,
                        "level": 2
                    },
                    {
                        "areaCode": 421024000000,
                        "areaId": 446175,
                        "areaName": "江陵县",
                        "parent_id": 444691,
                        "level": 2
                    },
                    {
                        "areaCode": 421071000000,
                        "areaId": 446403,
                        "areaName": "荆州经济技术开发区",
                        "parent_id": 444691,
                        "level": 2
                    },
                    {
                        "areaCode": 421081000000,
                        "areaId": 446469,
                        "areaName": "石首市",
                        "parent_id": 444691,
                        "level": 2
                    },
                    {
                        "areaCode": 421083000000,
                        "areaId": 446689,
                        "areaName": "洪湖市",
                        "parent_id": 444691,
                        "level": 2
                    },
                    {
                        "areaCode": 421087000000,
                        "areaId": 447188,
                        "areaName": "松滋市",
                        "parent_id": 444691,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 421100000000,
                "areaId": 447483,
                "areaName": "黄冈市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 421101000000,
                        "areaId": 447484,
                        "areaName": "市辖区",
                        "parent_id": 447483,
                        "level": 2
                    },
                    {
                        "areaCode": 421102000000,
                        "areaId": 447485,
                        "areaName": "黄州区",
                        "parent_id": 447483,
                        "level": 2
                    },
                    {
                        "areaCode": 421121000000,
                        "areaId": 447641,
                        "areaName": "团风县",
                        "parent_id": 447483,
                        "level": 2
                    },
                    {
                        "areaCode": 421122000000,
                        "areaId": 447954,
                        "areaName": "红安县",
                        "parent_id": 447483,
                        "level": 2
                    },
                    {
                        "areaCode": 421123000000,
                        "areaId": 448394,
                        "areaName": "罗田县",
                        "parent_id": 447483,
                        "level": 2
                    },
                    {
                        "areaCode": 421124000000,
                        "areaId": 448865,
                        "areaName": "英山县",
                        "parent_id": 447483,
                        "level": 2
                    },
                    {
                        "areaCode": 421125000000,
                        "areaId": 449199,
                        "areaName": "浠水县",
                        "parent_id": 447483,
                        "level": 2
                    },
                    {
                        "areaCode": 421126000000,
                        "areaId": 449888,
                        "areaName": "蕲春县",
                        "parent_id": 447483,
                        "level": 2
                    },
                    {
                        "areaCode": 421127000000,
                        "areaId": 450489,
                        "areaName": "黄梅县",
                        "parent_id": 447483,
                        "level": 2
                    },
                    {
                        "areaCode": 421171000000,
                        "areaId": 451034,
                        "areaName": "龙感湖管理区",
                        "parent_id": 447483,
                        "level": 2
                    },
                    {
                        "areaCode": 421181000000,
                        "areaId": 451105,
                        "areaName": "麻城市",
                        "parent_id": 447483,
                        "level": 2
                    },
                    {
                        "areaCode": 421182000000,
                        "areaId": 451603,
                        "areaName": "武穴市",
                        "parent_id": 447483,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 421200000000,
                "areaId": 451959,
                "areaName": "咸宁市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 421201000000,
                        "areaId": 451960,
                        "areaName": "市辖区",
                        "parent_id": 451959,
                        "level": 2
                    },
                    {
                        "areaCode": 421202000000,
                        "areaId": 451961,
                        "areaName": "咸安区",
                        "parent_id": 451959,
                        "level": 2
                    },
                    {
                        "areaCode": 421221000000,
                        "areaId": 452173,
                        "areaName": "嘉鱼县",
                        "parent_id": 451959,
                        "level": 2
                    },
                    {
                        "areaCode": 421222000000,
                        "areaId": 452288,
                        "areaName": "通城县",
                        "parent_id": 451959,
                        "level": 2
                    },
                    {
                        "areaCode": 421223000000,
                        "areaId": 452494,
                        "areaName": "崇阳县",
                        "parent_id": 451959,
                        "level": 2
                    },
                    {
                        "areaCode": 421224000000,
                        "areaId": 452711,
                        "areaName": "通山县",
                        "parent_id": 451959,
                        "level": 2
                    },
                    {
                        "areaCode": 421281000000,
                        "areaId": 452932,
                        "areaName": "赤壁市",
                        "parent_id": 451959,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 421300000000,
                "areaId": 453137,
                "areaName": "随州市",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 421301000000,
                        "areaId": 453138,
                        "areaName": "市辖区",
                        "parent_id": 453137,
                        "level": 2
                    },
                    {
                        "areaCode": 421303000000,
                        "areaId": 453139,
                        "areaName": "曾都区",
                        "parent_id": 453137,
                        "level": 2
                    },
                    {
                        "areaCode": 421321000000,
                        "areaId": 453358,
                        "areaName": "随县",
                        "parent_id": 453137,
                        "level": 2
                    },
                    {
                        "areaCode": 421381000000,
                        "areaId": 453772,
                        "areaName": "广水市",
                        "parent_id": 453137,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 422800000000,
                "areaId": 454193,
                "areaName": "恩施土家族苗族自治州",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 422801000000,
                        "areaId": 454194,
                        "areaName": "恩施市",
                        "parent_id": 454193,
                        "level": 2
                    },
                    {
                        "areaCode": 422802000000,
                        "areaId": 454420,
                        "areaName": "利川市",
                        "parent_id": 454193,
                        "level": 2
                    },
                    {
                        "areaCode": 422822000000,
                        "areaId": 455024,
                        "areaName": "建始县",
                        "parent_id": 454193,
                        "level": 2
                    },
                    {
                        "areaCode": 422823000000,
                        "areaId": 455445,
                        "areaName": "巴东县",
                        "parent_id": 454193,
                        "level": 2
                    },
                    {
                        "areaCode": 422825000000,
                        "areaId": 455780,
                        "areaName": "宣恩县",
                        "parent_id": 454193,
                        "level": 2
                    },
                    {
                        "areaCode": 422826000000,
                        "areaId": 456074,
                        "areaName": "咸丰县",
                        "parent_id": 454193,
                        "level": 2
                    },
                    {
                        "areaCode": 422827000000,
                        "areaId": 456355,
                        "areaName": "来凤县",
                        "parent_id": 454193,
                        "level": 2
                    },
                    {
                        "areaCode": 422828000000,
                        "areaId": 456560,
                        "areaName": "鹤峰县",
                        "parent_id": 454193,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 429000000000,
                "areaId": 456789,
                "areaName": "直辖县",
                "parent_id": 427715,
                "level": 1,
                "children": [
                    {
                        "areaCode": 429004000000,
                        "areaId": 456790,
                        "areaName": "仙桃市",
                        "parent_id": 456789,
                        "level": 2
                    },
                    {
                        "areaCode": 429005000000,
                        "areaId": 457577,
                        "areaName": "潜江市",
                        "parent_id": 456789,
                        "level": 2
                    },
                    {
                        "areaCode": 429006000000,
                        "areaId": 458076,
                        "areaName": "天门市",
                        "parent_id": 456789,
                        "level": 2
                    },
                    {
                        "areaCode": 429021000000,
                        "areaId": 458960,
                        "areaName": "神农架林区",
                        "parent_id": 456789,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 430000000000,
        "areaId": 459049,
        "areaName": "湖南省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 430100000000,
                "areaId": 459050,
                "areaName": "长沙市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 430101000000,
                        "areaId": 459051,
                        "areaName": "市辖区",
                        "parent_id": 459050,
                        "level": 2
                    },
                    {
                        "areaCode": 430102000000,
                        "areaId": 459052,
                        "areaName": "芙蓉区",
                        "parent_id": 459050,
                        "level": 2
                    },
                    {
                        "areaCode": 430103000000,
                        "areaId": 459139,
                        "areaName": "天心区",
                        "parent_id": 459050,
                        "level": 2
                    },
                    {
                        "areaCode": 430104000000,
                        "areaId": 459247,
                        "areaName": "岳麓区",
                        "parent_id": 459050,
                        "level": 2
                    },
                    {
                        "areaCode": 430105000000,
                        "areaId": 459436,
                        "areaName": "开福区",
                        "parent_id": 459050,
                        "level": 2
                    },
                    {
                        "areaCode": 430111000000,
                        "areaId": 459560,
                        "areaName": "雨花区",
                        "parent_id": 459050,
                        "level": 2
                    },
                    {
                        "areaCode": 430112000000,
                        "areaId": 459726,
                        "areaName": "望城区",
                        "parent_id": 459050,
                        "level": 2
                    },
                    {
                        "areaCode": 430121000000,
                        "areaId": 459879,
                        "areaName": "长沙县",
                        "parent_id": 459050,
                        "level": 2
                    },
                    {
                        "areaCode": 430181000000,
                        "areaId": 460095,
                        "areaName": "浏阳市",
                        "parent_id": 459050,
                        "level": 2
                    },
                    {
                        "areaCode": 430182000000,
                        "areaId": 460450,
                        "areaName": "宁乡市",
                        "parent_id": 459050,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 430200000000,
                "areaId": 460758,
                "areaName": "株洲市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 430201000000,
                        "areaId": 460759,
                        "areaName": "市辖区",
                        "parent_id": 460758,
                        "level": 2
                    },
                    {
                        "areaCode": 430202000000,
                        "areaId": 460760,
                        "areaName": "荷塘区",
                        "parent_id": 460758,
                        "level": 2
                    },
                    {
                        "areaCode": 430203000000,
                        "areaId": 460825,
                        "areaName": "芦淞区",
                        "parent_id": 460758,
                        "level": 2
                    },
                    {
                        "areaCode": 430204000000,
                        "areaId": 460915,
                        "areaName": "石峰区",
                        "parent_id": 460758,
                        "level": 2
                    },
                    {
                        "areaCode": 430211000000,
                        "areaId": 460966,
                        "areaName": "天元区",
                        "parent_id": 460758,
                        "level": 2
                    },
                    {
                        "areaCode": 430221000000,
                        "areaId": 461073,
                        "areaName": "株洲县",
                        "parent_id": 460758,
                        "level": 2
                    },
                    {
                        "areaCode": 430223000000,
                        "areaId": 461221,
                        "areaName": "攸县",
                        "parent_id": 460758,
                        "level": 2
                    },
                    {
                        "areaCode": 430224000000,
                        "areaId": 461540,
                        "areaName": "茶陵县",
                        "parent_id": 460758,
                        "level": 2
                    },
                    {
                        "areaCode": 430225000000,
                        "areaId": 461805,
                        "areaName": "炎陵县",
                        "parent_id": 460758,
                        "level": 2
                    },
                    {
                        "areaCode": 430271000000,
                        "areaId": 461949,
                        "areaName": "云龙示范区",
                        "parent_id": 460758,
                        "level": 2
                    },
                    {
                        "areaCode": 430281000000,
                        "areaId": 461975,
                        "areaName": "醴陵市",
                        "parent_id": 460758,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 430300000000,
                "areaId": 462269,
                "areaName": "湘潭市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 430301000000,
                        "areaId": 462270,
                        "areaName": "市辖区",
                        "parent_id": 462269,
                        "level": 2
                    },
                    {
                        "areaCode": 430302000000,
                        "areaId": 462271,
                        "areaName": "雨湖区",
                        "parent_id": 462269,
                        "level": 2
                    },
                    {
                        "areaCode": 430304000000,
                        "areaId": 462396,
                        "areaName": "岳塘区",
                        "parent_id": 462269,
                        "level": 2
                    },
                    {
                        "areaCode": 430321000000,
                        "areaId": 462467,
                        "areaName": "湘潭县",
                        "parent_id": 462269,
                        "level": 2
                    },
                    {
                        "areaCode": 430371000000,
                        "areaId": 462840,
                        "areaName": "湖南湘潭高新技术产业园区",
                        "parent_id": 462269,
                        "level": 2
                    },
                    {
                        "areaCode": 430372000000,
                        "areaId": 462861,
                        "areaName": "湘潭昭山示范区",
                        "parent_id": 462269,
                        "level": 2
                    },
                    {
                        "areaCode": 430373000000,
                        "areaId": 462878,
                        "areaName": "湘潭九华示范区",
                        "parent_id": 462269,
                        "level": 2
                    },
                    {
                        "areaCode": 430381000000,
                        "areaId": 462906,
                        "areaName": "湘乡市",
                        "parent_id": 462269,
                        "level": 2
                    },
                    {
                        "areaCode": 430382000000,
                        "areaId": 463277,
                        "areaName": "韶山市",
                        "parent_id": 462269,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 430400000000,
                "areaId": 463320,
                "areaName": "衡阳市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 430401000000,
                        "areaId": 463321,
                        "areaName": "市辖区",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430405000000,
                        "areaId": 463322,
                        "areaName": "珠晖区",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430406000000,
                        "areaId": 463408,
                        "areaName": "雁峰区",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430407000000,
                        "areaId": 463466,
                        "areaName": "石鼓区",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430408000000,
                        "areaId": 463519,
                        "areaName": "蒸湘区",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430412000000,
                        "areaId": 463575,
                        "areaName": "南岳区",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430421000000,
                        "areaId": 463604,
                        "areaName": "衡阳县",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430422000000,
                        "areaId": 464121,
                        "areaName": "衡南县",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430423000000,
                        "areaId": 464593,
                        "areaName": "衡山县",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430424000000,
                        "areaId": 464759,
                        "areaName": "衡东县",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430426000000,
                        "areaId": 465035,
                        "areaName": "祁东县",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430471000000,
                        "areaId": 465427,
                        "areaName": "衡阳综合保税区",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430472000000,
                        "areaId": 465432,
                        "areaName": "湖南衡阳高新技术产业园区",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430473000000,
                        "areaId": 465450,
                        "areaName": "湖南衡阳松木经济开发区",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430481000000,
                        "areaId": 465459,
                        "areaName": "耒阳市",
                        "parent_id": 463320,
                        "level": 2
                    },
                    {
                        "areaCode": 430482000000,
                        "areaId": 465868,
                        "areaName": "常宁市",
                        "parent_id": 463320,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 430500000000,
                "areaId": 466299,
                "areaName": "邵阳市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 430501000000,
                        "areaId": 466300,
                        "areaName": "市辖区",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430502000000,
                        "areaId": 466301,
                        "areaName": "双清区",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430503000000,
                        "areaId": 466405,
                        "areaName": "大祥区",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430511000000,
                        "areaId": 466518,
                        "areaName": "北塔区",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430521000000,
                        "areaId": 466565,
                        "areaName": "邵东县",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430522000000,
                        "areaId": 467174,
                        "areaName": "新邵县",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430523000000,
                        "areaId": 467607,
                        "areaName": "邵阳县",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430524000000,
                        "areaId": 468064,
                        "areaName": "隆回县",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430525000000,
                        "areaId": 468661,
                        "areaName": "洞口县",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430527000000,
                        "areaId": 469048,
                        "areaName": "绥宁县",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430528000000,
                        "areaId": 469308,
                        "areaName": "新宁县",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430529000000,
                        "areaId": 469651,
                        "areaName": "城步苗族自治县",
                        "parent_id": 466299,
                        "level": 2
                    },
                    {
                        "areaCode": 430581000000,
                        "areaId": 469858,
                        "areaName": "武冈市",
                        "parent_id": 466299,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 430600000000,
                "areaId": 470192,
                "areaName": "岳阳市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 430601000000,
                        "areaId": 470193,
                        "areaName": "市辖区",
                        "parent_id": 470192,
                        "level": 2
                    },
                    {
                        "areaCode": 430602000000,
                        "areaId": 470194,
                        "areaName": "岳阳楼区",
                        "parent_id": 470192,
                        "level": 2
                    },
                    {
                        "areaCode": 430603000000,
                        "areaId": 470392,
                        "areaName": "云溪区",
                        "parent_id": 470192,
                        "level": 2
                    },
                    {
                        "areaCode": 430611000000,
                        "areaId": 470456,
                        "areaName": "君山区",
                        "parent_id": 470192,
                        "level": 2
                    },
                    {
                        "areaCode": 430621000000,
                        "areaId": 470549,
                        "areaName": "岳阳县",
                        "parent_id": 470192,
                        "level": 2
                    },
                    {
                        "areaCode": 430623000000,
                        "areaId": 470782,
                        "areaName": "华容县",
                        "parent_id": 470192,
                        "level": 2
                    },
                    {
                        "areaCode": 430624000000,
                        "areaId": 471021,
                        "areaName": "湘阴县",
                        "parent_id": 470192,
                        "level": 2
                    },
                    {
                        "areaCode": 430626000000,
                        "areaId": 471255,
                        "areaName": "平江县",
                        "parent_id": 470192,
                        "level": 2
                    },
                    {
                        "areaCode": 430671000000,
                        "areaId": 471822,
                        "areaName": "岳阳市屈原管理区",
                        "parent_id": 470192,
                        "level": 2
                    },
                    {
                        "areaCode": 430681000000,
                        "areaId": 471859,
                        "areaName": "汨罗市",
                        "parent_id": 470192,
                        "level": 2
                    },
                    {
                        "areaCode": 430682000000,
                        "areaId": 472071,
                        "areaName": "临湘市",
                        "parent_id": 470192,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 430700000000,
                "areaId": 472246,
                "areaName": "常德市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 430701000000,
                        "areaId": 472247,
                        "areaName": "市辖区",
                        "parent_id": 472246,
                        "level": 2
                    },
                    {
                        "areaCode": 430702000000,
                        "areaId": 472248,
                        "areaName": "武陵区",
                        "parent_id": 472246,
                        "level": 2
                    },
                    {
                        "areaCode": 430703000000,
                        "areaId": 472436,
                        "areaName": "鼎城区",
                        "parent_id": 472246,
                        "level": 2
                    },
                    {
                        "areaCode": 430721000000,
                        "areaId": 472795,
                        "areaName": "安乡县",
                        "parent_id": 472246,
                        "level": 2
                    },
                    {
                        "areaCode": 430722000000,
                        "areaId": 472979,
                        "areaName": "汉寿县",
                        "parent_id": 472246,
                        "level": 2
                    },
                    {
                        "areaCode": 430723000000,
                        "areaId": 473308,
                        "areaName": "澧县",
                        "parent_id": 472246,
                        "level": 2
                    },
                    {
                        "areaCode": 430724000000,
                        "areaId": 473619,
                        "areaName": "临澧县",
                        "parent_id": 472246,
                        "level": 2
                    },
                    {
                        "areaCode": 430725000000,
                        "areaId": 473790,
                        "areaName": "桃源县",
                        "parent_id": 472246,
                        "level": 2
                    },
                    {
                        "areaCode": 430726000000,
                        "areaId": 474248,
                        "areaName": "石门县",
                        "parent_id": 472246,
                        "level": 2
                    },
                    {
                        "areaCode": 430771000000,
                        "areaId": 474607,
                        "areaName": "常德市西洞庭管理区",
                        "parent_id": 472246,
                        "level": 2
                    },
                    {
                        "areaCode": 430781000000,
                        "areaId": 474629,
                        "areaName": "津市市",
                        "parent_id": 472246,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 430800000000,
                "areaId": 474718,
                "areaName": "张家界市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 430801000000,
                        "areaId": 474719,
                        "areaName": "市辖区",
                        "parent_id": 474718,
                        "level": 2
                    },
                    {
                        "areaCode": 430802000000,
                        "areaId": 474720,
                        "areaName": "永定区",
                        "parent_id": 474718,
                        "level": 2
                    },
                    {
                        "areaCode": 430811000000,
                        "areaId": 474997,
                        "areaName": "武陵源区",
                        "parent_id": 474718,
                        "level": 2
                    },
                    {
                        "areaCode": 430821000000,
                        "areaId": 475037,
                        "areaName": "慈利县",
                        "parent_id": 474718,
                        "level": 2
                    },
                    {
                        "areaCode": 430822000000,
                        "areaId": 475493,
                        "areaName": "桑植县",
                        "parent_id": 474718,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 430900000000,
                "areaId": 475815,
                "areaName": "益阳市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 430901000000,
                        "areaId": 475816,
                        "areaName": "市辖区",
                        "parent_id": 475815,
                        "level": 2
                    },
                    {
                        "areaCode": 430902000000,
                        "areaId": 475817,
                        "areaName": "资阳区",
                        "parent_id": 475815,
                        "level": 2
                    },
                    {
                        "areaCode": 430903000000,
                        "areaId": 475945,
                        "areaName": "赫山区",
                        "parent_id": 475815,
                        "level": 2
                    },
                    {
                        "areaCode": 430921000000,
                        "areaId": 476171,
                        "areaName": "南县",
                        "parent_id": 475815,
                        "level": 2
                    },
                    {
                        "areaCode": 430922000000,
                        "areaId": 476348,
                        "areaName": "桃江县",
                        "parent_id": 475815,
                        "level": 2
                    },
                    {
                        "areaCode": 430923000000,
                        "areaId": 476610,
                        "areaName": "安化县",
                        "parent_id": 475815,
                        "level": 2
                    },
                    {
                        "areaCode": 430971000000,
                        "areaId": 477067,
                        "areaName": "益阳市大通湖管理区",
                        "parent_id": 475815,
                        "level": 2
                    },
                    {
                        "areaCode": 430972000000,
                        "areaId": 477112,
                        "areaName": "湖南益阳高新技术产业园区",
                        "parent_id": 475815,
                        "level": 2
                    },
                    {
                        "areaCode": 430981000000,
                        "areaId": 477132,
                        "areaName": "沅江市",
                        "parent_id": 475815,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 431000000000,
                "areaId": 477346,
                "areaName": "郴州市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 431001000000,
                        "areaId": 477347,
                        "areaName": "市辖区",
                        "parent_id": 477346,
                        "level": 2
                    },
                    {
                        "areaCode": 431002000000,
                        "areaId": 477348,
                        "areaName": "北湖区",
                        "parent_id": 477346,
                        "level": 2
                    },
                    {
                        "areaCode": 431003000000,
                        "areaId": 477526,
                        "areaName": "苏仙区",
                        "parent_id": 477346,
                        "level": 2
                    },
                    {
                        "areaCode": 431021000000,
                        "areaId": 477726,
                        "areaName": "桂阳县",
                        "parent_id": 477346,
                        "level": 2
                    },
                    {
                        "areaCode": 431022000000,
                        "areaId": 478120,
                        "areaName": "宜章县",
                        "parent_id": 477346,
                        "level": 2
                    },
                    {
                        "areaCode": 431023000000,
                        "areaId": 478404,
                        "areaName": "永兴县",
                        "parent_id": 477346,
                        "level": 2
                    },
                    {
                        "areaCode": 431024000000,
                        "areaId": 478699,
                        "areaName": "嘉禾县",
                        "parent_id": 477346,
                        "level": 2
                    },
                    {
                        "areaCode": 431025000000,
                        "areaId": 478911,
                        "areaName": "临武县",
                        "parent_id": 477346,
                        "level": 2
                    },
                    {
                        "areaCode": 431026000000,
                        "areaId": 479134,
                        "areaName": "汝城县",
                        "parent_id": 477346,
                        "level": 2
                    },
                    {
                        "areaCode": 431027000000,
                        "areaId": 479400,
                        "areaName": "桂东县",
                        "parent_id": 477346,
                        "level": 2
                    },
                    {
                        "areaCode": 431028000000,
                        "areaId": 479527,
                        "areaName": "安仁县",
                        "parent_id": 477346,
                        "level": 2
                    },
                    {
                        "areaCode": 431081000000,
                        "areaId": 479703,
                        "areaName": "资兴市",
                        "parent_id": 477346,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 431100000000,
                "areaId": 479933,
                "areaName": "永州市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 431101000000,
                        "areaId": 479934,
                        "areaName": "市辖区",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431102000000,
                        "areaId": 479935,
                        "areaName": "零陵区",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431103000000,
                        "areaId": 480286,
                        "areaName": "冷水滩区",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431121000000,
                        "areaId": 480508,
                        "areaName": "祁阳县",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431122000000,
                        "areaId": 481095,
                        "areaName": "东安县",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431123000000,
                        "areaId": 481457,
                        "areaName": "双牌县",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431124000000,
                        "areaId": 481591,
                        "areaName": "道县",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431125000000,
                        "areaId": 481985,
                        "areaName": "江永县",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431126000000,
                        "areaId": 482107,
                        "areaName": "宁远县",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431127000000,
                        "areaId": 482550,
                        "areaName": "蓝山县",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431128000000,
                        "areaId": 482798,
                        "areaName": "新田县",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431129000000,
                        "areaId": 483043,
                        "areaName": "江华瑶族自治县",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431171000000,
                        "areaId": 483372,
                        "areaName": "永州经济技术开发区",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431172000000,
                        "areaId": 483391,
                        "areaName": "永州市金洞管理区",
                        "parent_id": 479933,
                        "level": 2
                    },
                    {
                        "areaCode": 431173000000,
                        "areaId": 483441,
                        "areaName": "永州市回龙圩管理区",
                        "parent_id": 479933,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 431200000000,
                "areaId": 483454,
                "areaName": "怀化市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 431201000000,
                        "areaId": 483455,
                        "areaName": "市辖区",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431202000000,
                        "areaId": 483456,
                        "areaName": "鹤城区",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431221000000,
                        "areaId": 483594,
                        "areaName": "中方县",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431222000000,
                        "areaId": 483756,
                        "areaName": "沅陵县",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431223000000,
                        "areaId": 484181,
                        "areaName": "辰溪县",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431224000000,
                        "areaId": 484498,
                        "areaName": "溆浦县",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431225000000,
                        "areaId": 484940,
                        "areaName": "会同县",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431226000000,
                        "areaId": 485202,
                        "areaName": "麻阳苗族自治县",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431227000000,
                        "areaId": 485442,
                        "areaName": "新晃侗族自治县",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431228000000,
                        "areaId": 485605,
                        "areaName": "芷江侗族自治县",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431229000000,
                        "areaId": 485842,
                        "areaName": "靖州苗族侗族自治县",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431230000000,
                        "areaId": 485992,
                        "areaName": "通道侗族自治县",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431271000000,
                        "areaId": 486170,
                        "areaName": "怀化市洪江管理区",
                        "parent_id": 483454,
                        "level": 2
                    },
                    {
                        "areaCode": 431281000000,
                        "areaId": 486211,
                        "areaName": "洪江市",
                        "parent_id": 483454,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 431300000000,
                "areaId": 486450,
                "areaName": "娄底市",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 431301000000,
                        "areaId": 486451,
                        "areaName": "市辖区",
                        "parent_id": 486450,
                        "level": 2
                    },
                    {
                        "areaCode": 431302000000,
                        "areaId": 486452,
                        "areaName": "娄星区",
                        "parent_id": 486450,
                        "level": 2
                    },
                    {
                        "areaCode": 431321000000,
                        "areaId": 486697,
                        "areaName": "双峰县",
                        "parent_id": 486450,
                        "level": 2
                    },
                    {
                        "areaCode": 431322000000,
                        "areaId": 487237,
                        "areaName": "新化县",
                        "parent_id": 486450,
                        "level": 2
                    },
                    {
                        "areaCode": 431381000000,
                        "areaId": 487954,
                        "areaName": "冷水江市",
                        "parent_id": 486450,
                        "level": 2
                    },
                    {
                        "areaCode": 431382000000,
                        "areaId": 488115,
                        "areaName": "涟源市",
                        "parent_id": 486450,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 433100000000,
                "areaId": 488639,
                "areaName": "湘西土家族苗族自治州",
                "parent_id": 459049,
                "level": 1,
                "children": [
                    {
                        "areaCode": 433101000000,
                        "areaId": 488640,
                        "areaName": "吉首市",
                        "parent_id": 488639,
                        "level": 2
                    },
                    {
                        "areaCode": 433122000000,
                        "areaId": 488792,
                        "areaName": "泸溪县",
                        "parent_id": 488639,
                        "level": 2
                    },
                    {
                        "areaCode": 433123000000,
                        "areaId": 488953,
                        "areaName": "凤凰县",
                        "parent_id": 488639,
                        "level": 2
                    },
                    {
                        "areaCode": 433124000000,
                        "areaId": 489252,
                        "areaName": "花垣县",
                        "parent_id": 488639,
                        "level": 2
                    },
                    {
                        "areaCode": 433125000000,
                        "areaId": 489506,
                        "areaName": "保靖县",
                        "parent_id": 488639,
                        "level": 2
                    },
                    {
                        "areaCode": 433126000000,
                        "areaId": 489697,
                        "areaName": "古丈县",
                        "parent_id": 488639,
                        "level": 2
                    },
                    {
                        "areaCode": 433127000000,
                        "areaId": 489826,
                        "areaName": "永顺县",
                        "parent_id": 488639,
                        "level": 2
                    },
                    {
                        "areaCode": 433130000000,
                        "areaId": 490151,
                        "areaName": "龙山县",
                        "parent_id": 488639,
                        "level": 2
                    },
                    {
                        "areaCode": 433172000000,
                        "areaId": 490570,
                        "areaName": "湖南吉首经济开发区",
                        "parent_id": 488639,
                        "level": 2
                    },
                    {
                        "areaCode": 433173000000,
                        "areaId": 490583,
                        "areaName": "湖南永顺经济开发区",
                        "parent_id": 488639,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 440000000000,
        "areaId": 490587,
        "areaName": "广东省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 440100000000,
                "areaId": 490588,
                "areaName": "广州市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 440101000000,
                        "areaId": 490589,
                        "areaName": "市辖区",
                        "parent_id": 490588,
                        "level": 2
                    },
                    {
                        "areaCode": 440103000000,
                        "areaId": 490590,
                        "areaName": "荔湾区",
                        "parent_id": 490588,
                        "level": 2
                    },
                    {
                        "areaCode": 440104000000,
                        "areaId": 490799,
                        "areaName": "越秀区",
                        "parent_id": 490588,
                        "level": 2
                    },
                    {
                        "areaCode": 440105000000,
                        "areaId": 491040,
                        "areaName": "海珠区",
                        "parent_id": 490588,
                        "level": 2
                    },
                    {
                        "areaCode": 440106000000,
                        "areaId": 491324,
                        "areaName": "天河区",
                        "parent_id": 490588,
                        "level": 2
                    },
                    {
                        "areaCode": 440111000000,
                        "areaId": 491565,
                        "areaName": "白云区",
                        "parent_id": 490588,
                        "level": 2
                    },
                    {
                        "areaCode": 440112000000,
                        "areaId": 491984,
                        "areaName": "黄埔区",
                        "parent_id": 490588,
                        "level": 2
                    },
                    {
                        "areaCode": 440113000000,
                        "areaId": 492127,
                        "areaName": "番禺区",
                        "parent_id": 490588,
                        "level": 2
                    },
                    {
                        "areaCode": 440114000000,
                        "areaId": 492418,
                        "areaName": "花都区",
                        "parent_id": 490588,
                        "level": 2
                    },
                    {
                        "areaCode": 440115000000,
                        "areaId": 492681,
                        "areaName": "南沙区",
                        "parent_id": 490588,
                        "level": 2
                    },
                    {
                        "areaCode": 440117000000,
                        "areaId": 492858,
                        "areaName": "从化区",
                        "parent_id": 490588,
                        "level": 2
                    },
                    {
                        "areaCode": 440118000000,
                        "areaId": 493145,
                        "areaName": "增城区",
                        "parent_id": 490588,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 440200000000,
                "areaId": 493498,
                "areaName": "韶关市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 440201000000,
                        "areaId": 493499,
                        "areaName": "市辖区",
                        "parent_id": 493498,
                        "level": 2
                    },
                    {
                        "areaCode": 440203000000,
                        "areaId": 493500,
                        "areaName": "武江区",
                        "parent_id": 493498,
                        "level": 2
                    },
                    {
                        "areaCode": 440204000000,
                        "areaId": 493592,
                        "areaName": "浈江区",
                        "parent_id": 493498,
                        "level": 2
                    },
                    {
                        "areaCode": 440205000000,
                        "areaId": 493716,
                        "areaName": "曲江区",
                        "parent_id": 493498,
                        "level": 2
                    },
                    {
                        "areaCode": 440222000000,
                        "areaId": 493838,
                        "areaName": "始兴县",
                        "parent_id": 493498,
                        "level": 2
                    },
                    {
                        "areaCode": 440224000000,
                        "areaId": 493978,
                        "areaName": "仁化县",
                        "parent_id": 493498,
                        "level": 2
                    },
                    {
                        "areaCode": 440229000000,
                        "areaId": 494115,
                        "areaName": "翁源县",
                        "parent_id": 493498,
                        "level": 2
                    },
                    {
                        "areaCode": 440232000000,
                        "areaId": 494298,
                        "areaName": "乳源瑶族自治县",
                        "parent_id": 493498,
                        "level": 2
                    },
                    {
                        "areaCode": 440233000000,
                        "areaId": 494427,
                        "areaName": "新丰县",
                        "parent_id": 493498,
                        "level": 2
                    },
                    {
                        "areaCode": 440281000000,
                        "areaId": 494592,
                        "areaName": "乐昌市",
                        "parent_id": 493498,
                        "level": 2
                    },
                    {
                        "areaCode": 440282000000,
                        "areaId": 494837,
                        "areaName": "南雄市",
                        "parent_id": 493498,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 440300000000,
                "areaId": 495090,
                "areaName": "深圳市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 440301000000,
                        "areaId": 495091,
                        "areaName": "市辖区",
                        "parent_id": 495090,
                        "level": 2
                    },
                    {
                        "areaCode": 440303000000,
                        "areaId": 495092,
                        "areaName": "罗湖区",
                        "parent_id": 495090,
                        "level": 2
                    },
                    {
                        "areaCode": 440304000000,
                        "areaId": 495215,
                        "areaName": "福田区",
                        "parent_id": 495090,
                        "level": 2
                    },
                    {
                        "areaCode": 440305000000,
                        "areaId": 495343,
                        "areaName": "南山区",
                        "parent_id": 495090,
                        "level": 2
                    },
                    {
                        "areaCode": 440306000000,
                        "areaId": 495456,
                        "areaName": "宝安区",
                        "parent_id": 495090,
                        "level": 2
                    },
                    {
                        "areaCode": 440307000000,
                        "areaId": 495646,
                        "areaName": "龙岗区",
                        "parent_id": 495090,
                        "level": 2
                    },
                    {
                        "areaCode": 440308000000,
                        "areaId": 495805,
                        "areaName": "盐田区",
                        "parent_id": 495090,
                        "level": 2
                    },
                    {
                        "areaCode": 440309000000,
                        "areaId": 495837,
                        "areaName": "龙华区",
                        "parent_id": 495090,
                        "level": 2
                    },
                    {
                        "areaCode": 440310000000,
                        "areaId": 495952,
                        "areaName": "坪山区",
                        "parent_id": 495090,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 440400000000,
                "areaId": 495984,
                "areaName": "珠海市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 440401000000,
                        "areaId": 495985,
                        "areaName": "市辖区",
                        "parent_id": 495984,
                        "level": 2
                    },
                    {
                        "areaCode": 440402000000,
                        "areaId": 495986,
                        "areaName": "香洲区",
                        "parent_id": 495984,
                        "level": 2
                    },
                    {
                        "areaCode": 440403000000,
                        "areaId": 496163,
                        "areaName": "斗门区",
                        "parent_id": 495984,
                        "level": 2
                    },
                    {
                        "areaCode": 440404000000,
                        "areaId": 496301,
                        "areaName": "金湾区",
                        "parent_id": 495984,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 440500000000,
                "areaId": 496350,
                "areaName": "汕头市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 440501000000,
                        "areaId": 496351,
                        "areaName": "市辖区",
                        "parent_id": 496350,
                        "level": 2
                    },
                    {
                        "areaCode": 440507000000,
                        "areaId": 496352,
                        "areaName": "龙湖区",
                        "parent_id": 496350,
                        "level": 2
                    },
                    {
                        "areaCode": 440511000000,
                        "areaId": 496481,
                        "areaName": "金平区",
                        "parent_id": 496350,
                        "level": 2
                    },
                    {
                        "areaCode": 440512000000,
                        "areaId": 496670,
                        "areaName": "濠江区",
                        "parent_id": 496350,
                        "level": 2
                    },
                    {
                        "areaCode": 440513000000,
                        "areaId": 496738,
                        "areaName": "潮阳区",
                        "parent_id": 496350,
                        "level": 2
                    },
                    {
                        "areaCode": 440514000000,
                        "areaId": 497024,
                        "areaName": "潮南区",
                        "parent_id": 496350,
                        "level": 2
                    },
                    {
                        "areaCode": 440515000000,
                        "areaId": 497268,
                        "areaName": "澄海区",
                        "parent_id": 496350,
                        "level": 2
                    },
                    {
                        "areaCode": 440523000000,
                        "areaId": 497464,
                        "areaName": "南澳县",
                        "parent_id": 496350,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 440600000000,
                "areaId": 497514,
                "areaName": "佛山市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 440601000000,
                        "areaId": 497515,
                        "areaName": "市辖区",
                        "parent_id": 497514,
                        "level": 2
                    },
                    {
                        "areaCode": 440604000000,
                        "areaId": 497516,
                        "areaName": "禅城区",
                        "parent_id": 497514,
                        "level": 2
                    },
                    {
                        "areaCode": 440605000000,
                        "areaId": 497664,
                        "areaName": "南海区",
                        "parent_id": 497514,
                        "level": 2
                    },
                    {
                        "areaCode": 440606000000,
                        "areaId": 497963,
                        "areaName": "顺德区",
                        "parent_id": 497514,
                        "level": 2
                    },
                    {
                        "areaCode": 440607000000,
                        "areaId": 498178,
                        "areaName": "三水区",
                        "parent_id": 497514,
                        "level": 2
                    },
                    {
                        "areaCode": 440608000000,
                        "areaId": 498266,
                        "areaName": "高明区",
                        "parent_id": 497514,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 440700000000,
                "areaId": 498351,
                "areaName": "江门市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 440701000000,
                        "areaId": 498352,
                        "areaName": "市辖区",
                        "parent_id": 498351,
                        "level": 2
                    },
                    {
                        "areaCode": 440703000000,
                        "areaId": 498353,
                        "areaName": "蓬江区",
                        "parent_id": 498351,
                        "level": 2
                    },
                    {
                        "areaCode": 440704000000,
                        "areaId": 498503,
                        "areaName": "江海区",
                        "parent_id": 498351,
                        "level": 2
                    },
                    {
                        "areaCode": 440705000000,
                        "areaId": 498569,
                        "areaName": "新会区",
                        "parent_id": 498351,
                        "level": 2
                    },
                    {
                        "areaCode": 440781000000,
                        "areaId": 498815,
                        "areaName": "台山市",
                        "parent_id": 498351,
                        "level": 2
                    },
                    {
                        "areaCode": 440783000000,
                        "areaId": 499146,
                        "areaName": "开平市",
                        "parent_id": 498351,
                        "level": 2
                    },
                    {
                        "areaCode": 440784000000,
                        "areaId": 499434,
                        "areaName": "鹤山市",
                        "parent_id": 498351,
                        "level": 2
                    },
                    {
                        "areaCode": 440785000000,
                        "areaId": 499587,
                        "areaName": "恩平市",
                        "parent_id": 498351,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 440800000000,
                "areaId": 499775,
                "areaName": "湛江市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 440801000000,
                        "areaId": 499776,
                        "areaName": "市辖区",
                        "parent_id": 499775,
                        "level": 2
                    },
                    {
                        "areaCode": 440802000000,
                        "areaId": 499777,
                        "areaName": "赤坎区",
                        "parent_id": 499775,
                        "level": 2
                    },
                    {
                        "areaCode": 440803000000,
                        "areaId": 499833,
                        "areaName": "霞山区",
                        "parent_id": 499775,
                        "level": 2
                    },
                    {
                        "areaCode": 440804000000,
                        "areaId": 499924,
                        "areaName": "坡头区",
                        "parent_id": 499775,
                        "level": 2
                    },
                    {
                        "areaCode": 440811000000,
                        "areaId": 500003,
                        "areaName": "麻章区",
                        "parent_id": 499775,
                        "level": 2
                    },
                    {
                        "areaCode": 440823000000,
                        "areaId": 500156,
                        "areaName": "遂溪县",
                        "parent_id": 499775,
                        "level": 2
                    },
                    {
                        "areaCode": 440825000000,
                        "areaId": 500522,
                        "areaName": "徐闻县",
                        "parent_id": 499775,
                        "level": 2
                    },
                    {
                        "areaCode": 440881000000,
                        "areaId": 500750,
                        "areaName": "廉江市",
                        "parent_id": 499775,
                        "level": 2
                    },
                    {
                        "areaCode": 440882000000,
                        "areaId": 501173,
                        "areaName": "雷州市",
                        "parent_id": 499775,
                        "level": 2
                    },
                    {
                        "areaCode": 440883000000,
                        "areaId": 501693,
                        "areaName": "吴川市",
                        "parent_id": 499775,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 440900000000,
                "areaId": 501905,
                "areaName": "茂名市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 440901000000,
                        "areaId": 501906,
                        "areaName": "市辖区",
                        "parent_id": 501905,
                        "level": 2
                    },
                    {
                        "areaCode": 440902000000,
                        "areaId": 501907,
                        "areaName": "茂南区",
                        "parent_id": 501905,
                        "level": 2
                    },
                    {
                        "areaCode": 440904000000,
                        "areaId": 502164,
                        "areaName": "电白区",
                        "parent_id": 501905,
                        "level": 2
                    },
                    {
                        "areaCode": 440981000000,
                        "areaId": 502600,
                        "areaName": "高州市",
                        "parent_id": 501905,
                        "level": 2
                    },
                    {
                        "areaCode": 440982000000,
                        "areaId": 503123,
                        "areaName": "化州市",
                        "parent_id": 501905,
                        "level": 2
                    },
                    {
                        "areaCode": 440983000000,
                        "areaId": 503528,
                        "areaName": "信宜市",
                        "parent_id": 501905,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 441200000000,
                "areaId": 503950,
                "areaName": "肇庆市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 441201000000,
                        "areaId": 503951,
                        "areaName": "市辖区",
                        "parent_id": 503950,
                        "level": 2
                    },
                    {
                        "areaCode": 441202000000,
                        "areaId": 503952,
                        "areaName": "端州区",
                        "parent_id": 503950,
                        "level": 2
                    },
                    {
                        "areaCode": 441203000000,
                        "areaId": 504017,
                        "areaName": "鼎湖区",
                        "parent_id": 503950,
                        "level": 2
                    },
                    {
                        "areaCode": 441204000000,
                        "areaId": 504106,
                        "areaName": "高要区",
                        "parent_id": 503950,
                        "level": 2
                    },
                    {
                        "areaCode": 441223000000,
                        "areaId": 504476,
                        "areaName": "广宁县",
                        "parent_id": 503950,
                        "level": 2
                    },
                    {
                        "areaCode": 441224000000,
                        "areaId": 504670,
                        "areaName": "怀集县",
                        "parent_id": 503950,
                        "level": 2
                    },
                    {
                        "areaCode": 441225000000,
                        "areaId": 505018,
                        "areaName": "封开县",
                        "parent_id": 503950,
                        "level": 2
                    },
                    {
                        "areaCode": 441226000000,
                        "areaId": 505234,
                        "areaName": "德庆县",
                        "parent_id": 503950,
                        "level": 2
                    },
                    {
                        "areaCode": 441284000000,
                        "areaId": 505441,
                        "areaName": "四会市",
                        "parent_id": 503950,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 441300000000,
                "areaId": 505620,
                "areaName": "惠州市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 441301000000,
                        "areaId": 505621,
                        "areaName": "市辖区",
                        "parent_id": 505620,
                        "level": 2
                    },
                    {
                        "areaCode": 441302000000,
                        "areaId": 505622,
                        "areaName": "惠城区",
                        "parent_id": 505620,
                        "level": 2
                    },
                    {
                        "areaCode": 441303000000,
                        "areaId": 505913,
                        "areaName": "惠阳区",
                        "parent_id": 505620,
                        "level": 2
                    },
                    {
                        "areaCode": 441322000000,
                        "areaId": 506096,
                        "areaName": "博罗县",
                        "parent_id": 505620,
                        "level": 2
                    },
                    {
                        "areaCode": 441323000000,
                        "areaId": 506496,
                        "areaName": "惠东县",
                        "parent_id": 505620,
                        "level": 2
                    },
                    {
                        "areaCode": 441324000000,
                        "areaId": 506813,
                        "areaName": "龙门县",
                        "parent_id": 505620,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 441400000000,
                "areaId": 507010,
                "areaName": "梅州市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 441401000000,
                        "areaId": 507011,
                        "areaName": "市辖区",
                        "parent_id": 507010,
                        "level": 2
                    },
                    {
                        "areaCode": 441402000000,
                        "areaId": 507012,
                        "areaName": "梅江区",
                        "parent_id": 507010,
                        "level": 2
                    },
                    {
                        "areaCode": 441403000000,
                        "areaId": 507146,
                        "areaName": "梅县区",
                        "parent_id": 507010,
                        "level": 2
                    },
                    {
                        "areaCode": 441422000000,
                        "areaId": 507557,
                        "areaName": "大埔县",
                        "parent_id": 507010,
                        "level": 2
                    },
                    {
                        "areaCode": 441423000000,
                        "areaId": 507833,
                        "areaName": "丰顺县",
                        "parent_id": 507010,
                        "level": 2
                    },
                    {
                        "areaCode": 441424000000,
                        "areaId": 508133,
                        "areaName": "五华县",
                        "parent_id": 507010,
                        "level": 2
                    },
                    {
                        "areaCode": 441426000000,
                        "areaId": 508596,
                        "areaName": "平远县",
                        "parent_id": 507010,
                        "level": 2
                    },
                    {
                        "areaCode": 441427000000,
                        "areaId": 508752,
                        "areaName": "蕉岭县",
                        "parent_id": 507010,
                        "level": 2
                    },
                    {
                        "areaCode": 441481000000,
                        "areaId": 508873,
                        "areaName": "兴宁市",
                        "parent_id": 507010,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 441500000000,
                "areaId": 509389,
                "areaName": "汕尾市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 441501000000,
                        "areaId": 509390,
                        "areaName": "市辖区",
                        "parent_id": 509389,
                        "level": 2
                    },
                    {
                        "areaCode": 441502000000,
                        "areaId": 509391,
                        "areaName": "城区",
                        "parent_id": 509389,
                        "level": 2
                    },
                    {
                        "areaCode": 441521000000,
                        "areaId": 509525,
                        "areaName": "海丰县",
                        "parent_id": 509389,
                        "level": 2
                    },
                    {
                        "areaCode": 441523000000,
                        "areaId": 509826,
                        "areaName": "陆河县",
                        "parent_id": 509389,
                        "level": 2
                    },
                    {
                        "areaCode": 441581000000,
                        "areaId": 509962,
                        "areaName": "陆丰市",
                        "parent_id": 509389,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 441600000000,
                "areaId": 510352,
                "areaName": "河源市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 441601000000,
                        "areaId": 510353,
                        "areaName": "市辖区",
                        "parent_id": 510352,
                        "level": 2
                    },
                    {
                        "areaCode": 441602000000,
                        "areaId": 510354,
                        "areaName": "源城区",
                        "parent_id": 510352,
                        "level": 2
                    },
                    {
                        "areaCode": 441621000000,
                        "areaId": 510434,
                        "areaName": "紫金县",
                        "parent_id": 510352,
                        "level": 2
                    },
                    {
                        "areaCode": 441622000000,
                        "areaId": 510753,
                        "areaName": "龙川县",
                        "parent_id": 510352,
                        "level": 2
                    },
                    {
                        "areaCode": 441623000000,
                        "areaId": 511135,
                        "areaName": "连平县",
                        "parent_id": 510352,
                        "level": 2
                    },
                    {
                        "areaCode": 441624000000,
                        "areaId": 511324,
                        "areaName": "和平县",
                        "parent_id": 510352,
                        "level": 2
                    },
                    {
                        "areaCode": 441625000000,
                        "areaId": 511587,
                        "areaName": "东源县",
                        "parent_id": 510352,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 441700000000,
                "areaId": 511893,
                "areaName": "阳江市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 441701000000,
                        "areaId": 511894,
                        "areaName": "市辖区",
                        "parent_id": 511893,
                        "level": 2
                    },
                    {
                        "areaCode": 441702000000,
                        "areaId": 511895,
                        "areaName": "江城区",
                        "parent_id": 511893,
                        "level": 2
                    },
                    {
                        "areaCode": 441704000000,
                        "areaId": 512076,
                        "areaName": "阳东区",
                        "parent_id": 511893,
                        "level": 2
                    },
                    {
                        "areaCode": 441721000000,
                        "areaId": 512276,
                        "areaName": "阳西县",
                        "parent_id": 511893,
                        "level": 2
                    },
                    {
                        "areaCode": 441781000000,
                        "areaId": 512438,
                        "areaName": "阳春市",
                        "parent_id": 511893,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 441800000000,
                "areaId": 512825,
                "areaName": "清远市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 441801000000,
                        "areaId": 512826,
                        "areaName": "市辖区",
                        "parent_id": 512825,
                        "level": 2
                    },
                    {
                        "areaCode": 441802000000,
                        "areaId": 512827,
                        "areaName": "清城区",
                        "parent_id": 512825,
                        "level": 2
                    },
                    {
                        "areaCode": 441803000000,
                        "areaId": 512995,
                        "areaName": "清新区",
                        "parent_id": 512825,
                        "level": 2
                    },
                    {
                        "areaCode": 441821000000,
                        "areaId": 513214,
                        "areaName": "佛冈县",
                        "parent_id": 512825,
                        "level": 2
                    },
                    {
                        "areaCode": 441823000000,
                        "areaId": 513311,
                        "areaName": "阳山县",
                        "parent_id": 512825,
                        "level": 2
                    },
                    {
                        "areaCode": 441825000000,
                        "areaId": 513492,
                        "areaName": "连山壮族瑶族自治县",
                        "parent_id": 512825,
                        "level": 2
                    },
                    {
                        "areaCode": 441826000000,
                        "areaId": 513556,
                        "areaName": "连南瑶族自治县",
                        "parent_id": 512825,
                        "level": 2
                    },
                    {
                        "areaCode": 441881000000,
                        "areaId": 513635,
                        "areaName": "英德市",
                        "parent_id": 512825,
                        "level": 2
                    },
                    {
                        "areaCode": 441882000000,
                        "areaId": 513959,
                        "areaName": "连州市",
                        "parent_id": 512825,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 441900000000,
                "areaId": 514145,
                "areaName": "东莞市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 441900003000,
                        "areaId": 514146,
                        "areaName": "东城街道办事处",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900004000,
                        "areaId": 514172,
                        "areaName": "南城街道办事处",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900005000,
                        "areaId": 514191,
                        "areaName": "万江街道办事处",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900006000,
                        "areaId": 514220,
                        "areaName": "莞城街道办事处",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900101000,
                        "areaId": 514229,
                        "areaName": "石碣镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900102000,
                        "areaId": 514245,
                        "areaName": "石龙镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900103000,
                        "areaId": 514256,
                        "areaName": "茶山镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900104000,
                        "areaId": 514275,
                        "areaName": "石排镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900105000,
                        "areaId": 514295,
                        "areaName": "企石镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900106000,
                        "areaId": 514316,
                        "areaName": "横沥镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900107000,
                        "areaId": 514334,
                        "areaName": "桥头镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900108000,
                        "areaId": 514352,
                        "areaName": "谢岗镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900109000,
                        "areaId": 514365,
                        "areaName": "东坑镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900110000,
                        "areaId": 514382,
                        "areaName": "常平镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900111000,
                        "areaId": 514416,
                        "areaName": "寮步镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900112000,
                        "areaId": 514447,
                        "areaName": "樟木头镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900113000,
                        "areaId": 514459,
                        "areaName": "大朗镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900114000,
                        "areaId": 514488,
                        "areaName": "黄江镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900115000,
                        "areaId": 514496,
                        "areaName": "清溪镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900116000,
                        "areaId": 514519,
                        "areaName": "塘厦镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900117000,
                        "areaId": 514544,
                        "areaName": "凤岗镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900118000,
                        "areaId": 514557,
                        "areaName": "大岭山镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900119000,
                        "areaId": 514581,
                        "areaName": "长安镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900121000,
                        "areaId": 514595,
                        "areaName": "虎门镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900122000,
                        "areaId": 514628,
                        "areaName": "厚街镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900123000,
                        "areaId": 514653,
                        "areaName": "沙田镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900124000,
                        "areaId": 514672,
                        "areaName": "道滘镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900125000,
                        "areaId": 514687,
                        "areaName": "洪梅镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900126000,
                        "areaId": 514698,
                        "areaName": "麻涌镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900127000,
                        "areaId": 514714,
                        "areaName": "望牛墩镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900128000,
                        "areaId": 514737,
                        "areaName": "中堂镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900129000,
                        "areaId": 514758,
                        "areaName": "高埗镇",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900401000,
                        "areaId": 514778,
                        "areaName": "松山湖管委会",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900402000,
                        "areaId": 514780,
                        "areaName": "东莞港",
                        "parent_id": 514145,
                        "level": 2
                    },
                    {
                        "areaCode": 441900403000,
                        "areaId": 514782,
                        "areaName": "东莞生态园",
                        "parent_id": 514145,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 442000000000,
                "areaId": 514784,
                "areaName": "中山市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 442000001000,
                        "areaId": 514785,
                        "areaName": "石岐区街道办事处",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000002000,
                        "areaId": 514805,
                        "areaName": "东区街道办事处",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000003000,
                        "areaId": 514816,
                        "areaName": "火炬开发区街道办事处",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000004000,
                        "areaId": 514824,
                        "areaName": "西区街道办事处",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000005000,
                        "areaId": 514834,
                        "areaName": "南区街道办事处",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000006000,
                        "areaId": 514839,
                        "areaName": "五桂山街道办事处",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000100000,
                        "areaId": 514845,
                        "areaName": "小榄镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000101000,
                        "areaId": 514861,
                        "areaName": "黄圃镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000102000,
                        "areaId": 514878,
                        "areaName": "民众镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000103000,
                        "areaId": 514898,
                        "areaName": "东凤镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000104000,
                        "areaId": 514913,
                        "areaName": "东升镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000105000,
                        "areaId": 514928,
                        "areaName": "古镇镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000106000,
                        "areaId": 514942,
                        "areaName": "沙溪镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000107000,
                        "areaId": 514959,
                        "areaName": "坦洲镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000108000,
                        "areaId": 514974,
                        "areaName": "港口镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000109000,
                        "areaId": 514984,
                        "areaName": "三角镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000110000,
                        "areaId": 514993,
                        "areaName": "横栏镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000111000,
                        "areaId": 515005,
                        "areaName": "南头镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000112000,
                        "areaId": 515012,
                        "areaName": "阜沙镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000113000,
                        "areaId": 515022,
                        "areaName": "南朗镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000114000,
                        "areaId": 515038,
                        "areaName": "三乡镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000115000,
                        "areaId": 515055,
                        "areaName": "板芙镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000116000,
                        "areaId": 515067,
                        "areaName": "大涌镇",
                        "parent_id": 514784,
                        "level": 2
                    },
                    {
                        "areaCode": 442000117000,
                        "areaId": 515079,
                        "areaName": "神湾镇",
                        "parent_id": 514784,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 445100000000,
                "areaId": 515086,
                "areaName": "潮州市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 445101000000,
                        "areaId": 515087,
                        "areaName": "市辖区",
                        "parent_id": 515086,
                        "level": 2
                    },
                    {
                        "areaCode": 445102000000,
                        "areaId": 515088,
                        "areaName": "湘桥区",
                        "parent_id": 515086,
                        "level": 2
                    },
                    {
                        "areaCode": 445103000000,
                        "areaId": 515280,
                        "areaName": "潮安区",
                        "parent_id": 515086,
                        "level": 2
                    },
                    {
                        "areaCode": 445122000000,
                        "areaId": 515755,
                        "areaName": "饶平县",
                        "parent_id": 515086,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 445200000000,
                "areaId": 516180,
                "areaName": "揭阳市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 445201000000,
                        "areaId": 516181,
                        "areaName": "市辖区",
                        "parent_id": 516180,
                        "level": 2
                    },
                    {
                        "areaCode": 445202000000,
                        "areaId": 516182,
                        "areaName": "榕城区",
                        "parent_id": 516180,
                        "level": 2
                    },
                    {
                        "areaCode": 445203000000,
                        "areaId": 516395,
                        "areaName": "揭东区",
                        "parent_id": 516180,
                        "level": 2
                    },
                    {
                        "areaCode": 445222000000,
                        "areaId": 516633,
                        "areaName": "揭西县",
                        "parent_id": 516180,
                        "level": 2
                    },
                    {
                        "areaCode": 445224000000,
                        "areaId": 516981,
                        "areaName": "惠来县",
                        "parent_id": 516180,
                        "level": 2
                    },
                    {
                        "areaCode": 445281000000,
                        "areaId": 517328,
                        "areaName": "普宁市",
                        "parent_id": 516180,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 445300000000,
                "areaId": 517940,
                "areaName": "云浮市",
                "parent_id": 490587,
                "level": 1,
                "children": [
                    {
                        "areaCode": 445301000000,
                        "areaId": 517941,
                        "areaName": "市辖区",
                        "parent_id": 517940,
                        "level": 2
                    },
                    {
                        "areaCode": 445302000000,
                        "areaId": 517942,
                        "areaName": "云城区",
                        "parent_id": 517940,
                        "level": 2
                    },
                    {
                        "areaCode": 445303000000,
                        "areaId": 518068,
                        "areaName": "云安区",
                        "parent_id": 517940,
                        "level": 2
                    },
                    {
                        "areaCode": 445321000000,
                        "areaId": 518192,
                        "areaName": "新兴县",
                        "parent_id": 517940,
                        "level": 2
                    },
                    {
                        "areaCode": 445322000000,
                        "areaId": 518410,
                        "areaName": "郁南县",
                        "parent_id": 517940,
                        "level": 2
                    },
                    {
                        "areaCode": 445381000000,
                        "areaId": 518634,
                        "areaName": "罗定市",
                        "parent_id": 517940,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 450000000000,
        "areaId": 518994,
        "areaName": "广西壮族自治区",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 450100000000,
                "areaId": 518995,
                "areaName": "南宁市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 450101000000,
                        "areaId": 518996,
                        "areaName": "市辖区",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450102000000,
                        "areaId": 518997,
                        "areaName": "兴宁区",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450103000000,
                        "areaId": 519080,
                        "areaName": "青秀区",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450105000000,
                        "areaId": 519197,
                        "areaName": "江南区",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450107000000,
                        "areaId": 519326,
                        "areaName": "西乡塘区",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450108000000,
                        "areaId": 519496,
                        "areaName": "良庆区",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450109000000,
                        "areaId": 519584,
                        "areaName": "邕宁区",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450110000000,
                        "areaId": 519664,
                        "areaName": "武鸣区",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450123000000,
                        "areaId": 519920,
                        "areaName": "隆安县",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450124000000,
                        "areaId": 520069,
                        "areaName": "马山县",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450125000000,
                        "areaId": 520234,
                        "areaName": "上林县",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450126000000,
                        "areaId": 520377,
                        "areaName": "宾阳县",
                        "parent_id": 518995,
                        "level": 2
                    },
                    {
                        "areaCode": 450127000000,
                        "areaId": 520630,
                        "areaName": "横县",
                        "parent_id": 518995,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 450200000000,
                "areaId": 520973,
                "areaName": "柳州市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 450201000000,
                        "areaId": 520974,
                        "areaName": "市辖区",
                        "parent_id": 520973,
                        "level": 2
                    },
                    {
                        "areaCode": 450202000000,
                        "areaId": 520975,
                        "areaName": "城中区",
                        "parent_id": 520973,
                        "level": 2
                    },
                    {
                        "areaCode": 450203000000,
                        "areaId": 521017,
                        "areaName": "鱼峰区",
                        "parent_id": 520973,
                        "level": 2
                    },
                    {
                        "areaCode": 450204000000,
                        "areaId": 521104,
                        "areaName": "柳南区",
                        "parent_id": 520973,
                        "level": 2
                    },
                    {
                        "areaCode": 450205000000,
                        "areaId": 521191,
                        "areaName": "柳北区",
                        "parent_id": 520973,
                        "level": 2
                    },
                    {
                        "areaCode": 450206000000,
                        "areaId": 521300,
                        "areaName": "柳江区",
                        "parent_id": 520973,
                        "level": 2
                    },
                    {
                        "areaCode": 450222000000,
                        "areaId": 521466,
                        "areaName": "柳城县",
                        "parent_id": 520973,
                        "level": 2
                    },
                    {
                        "areaCode": 450223000000,
                        "areaId": 521619,
                        "areaName": "鹿寨县",
                        "parent_id": 520973,
                        "level": 2
                    },
                    {
                        "areaCode": 450224000000,
                        "areaId": 521747,
                        "areaName": "融安县",
                        "parent_id": 520973,
                        "level": 2
                    },
                    {
                        "areaCode": 450225000000,
                        "areaId": 521908,
                        "areaName": "融水苗族自治县",
                        "parent_id": 520973,
                        "level": 2
                    },
                    {
                        "areaCode": 450226000000,
                        "areaId": 522135,
                        "areaName": "三江侗族自治县",
                        "parent_id": 520973,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 450300000000,
                "areaId": 522320,
                "areaName": "桂林市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 450301000000,
                        "areaId": 522321,
                        "areaName": "市辖区",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450302000000,
                        "areaId": 522322,
                        "areaName": "秀峰区",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450303000000,
                        "areaId": 522354,
                        "areaName": "叠彩区",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450304000000,
                        "areaId": 522394,
                        "areaName": "象山区",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450305000000,
                        "areaId": 522441,
                        "areaName": "七星区",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450311000000,
                        "areaId": 522494,
                        "areaName": "雁山区",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450312000000,
                        "areaId": 522542,
                        "areaName": "临桂区",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450321000000,
                        "areaId": 522725,
                        "areaName": "阳朔县",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450323000000,
                        "areaId": 522849,
                        "areaName": "灵川县",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450324000000,
                        "areaId": 523011,
                        "areaName": "全州县",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450325000000,
                        "areaId": 523314,
                        "areaName": "兴安县",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450326000000,
                        "areaId": 523450,
                        "areaName": "永福县",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450327000000,
                        "areaId": 523559,
                        "areaName": "灌阳县",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450328000000,
                        "areaId": 523710,
                        "areaName": "龙胜各族自治县",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450329000000,
                        "areaId": 523848,
                        "areaName": "资源县",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450330000000,
                        "areaId": 523930,
                        "areaName": "平乐县",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450331000000,
                        "areaId": 524094,
                        "areaName": "荔浦县",
                        "parent_id": 522320,
                        "level": 2
                    },
                    {
                        "areaCode": 450332000000,
                        "areaId": 524252,
                        "areaName": "恭城瑶族自治县",
                        "parent_id": 522320,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 450400000000,
                "areaId": 524389,
                "areaName": "梧州市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 450401000000,
                        "areaId": 524390,
                        "areaName": "市辖区",
                        "parent_id": 524389,
                        "level": 2
                    },
                    {
                        "areaCode": 450403000000,
                        "areaId": 524391,
                        "areaName": "万秀区",
                        "parent_id": 524389,
                        "level": 2
                    },
                    {
                        "areaCode": 450405000000,
                        "areaId": 524476,
                        "areaName": "长洲区",
                        "parent_id": 524389,
                        "level": 2
                    },
                    {
                        "areaCode": 450406000000,
                        "areaId": 524522,
                        "areaName": "龙圩区",
                        "parent_id": 524389,
                        "level": 2
                    },
                    {
                        "areaCode": 450421000000,
                        "areaId": 524606,
                        "areaName": "苍梧县",
                        "parent_id": 524389,
                        "level": 2
                    },
                    {
                        "areaCode": 450422000000,
                        "areaId": 524761,
                        "areaName": "藤县",
                        "parent_id": 524389,
                        "level": 2
                    },
                    {
                        "areaCode": 450423000000,
                        "areaId": 525077,
                        "areaName": "蒙山县",
                        "parent_id": 524389,
                        "level": 2
                    },
                    {
                        "areaCode": 450481000000,
                        "areaId": 525171,
                        "areaName": "岑溪市",
                        "parent_id": 524389,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 450500000000,
                "areaId": 525468,
                "areaName": "北海市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 450501000000,
                        "areaId": 525469,
                        "areaName": "市辖区",
                        "parent_id": 525468,
                        "level": 2
                    },
                    {
                        "areaCode": 450502000000,
                        "areaId": 525470,
                        "areaName": "海城区",
                        "parent_id": 525468,
                        "level": 2
                    },
                    {
                        "areaCode": 450503000000,
                        "areaId": 525545,
                        "areaName": "银海区",
                        "parent_id": 525468,
                        "level": 2
                    },
                    {
                        "areaCode": 450512000000,
                        "areaId": 525603,
                        "areaName": "铁山港区",
                        "parent_id": 525468,
                        "level": 2
                    },
                    {
                        "areaCode": 450521000000,
                        "areaId": 525650,
                        "areaName": "合浦县",
                        "parent_id": 525468,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 450600000000,
                "areaId": 525942,
                "areaName": "防城港市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 450601000000,
                        "areaId": 525943,
                        "areaName": "市辖区",
                        "parent_id": 525942,
                        "level": 2
                    },
                    {
                        "areaCode": 450602000000,
                        "areaId": 525944,
                        "areaName": "港口区",
                        "parent_id": 525942,
                        "level": 2
                    },
                    {
                        "areaCode": 450603000000,
                        "areaId": 525992,
                        "areaName": "防城区",
                        "parent_id": 525942,
                        "level": 2
                    },
                    {
                        "areaCode": 450621000000,
                        "areaId": 526178,
                        "areaName": "上思县",
                        "parent_id": 525942,
                        "level": 2
                    },
                    {
                        "areaCode": 450681000000,
                        "areaId": 526299,
                        "areaName": "东兴市",
                        "parent_id": 525942,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 450700000000,
                "areaId": 526345,
                "areaName": "钦州市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 450701000000,
                        "areaId": 526346,
                        "areaName": "市辖区",
                        "parent_id": 526345,
                        "level": 2
                    },
                    {
                        "areaCode": 450702000000,
                        "areaId": 526347,
                        "areaName": "钦南区",
                        "parent_id": 526345,
                        "level": 2
                    },
                    {
                        "areaCode": 450703000000,
                        "areaId": 526540,
                        "areaName": "钦北区",
                        "parent_id": 526345,
                        "level": 2
                    },
                    {
                        "areaCode": 450721000000,
                        "areaId": 526738,
                        "areaName": "灵山县",
                        "parent_id": 526345,
                        "level": 2
                    },
                    {
                        "areaCode": 450722000000,
                        "areaId": 527174,
                        "areaName": "浦北县",
                        "parent_id": 526345,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 450800000000,
                "areaId": 527466,
                "areaName": "贵港市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 450801000000,
                        "areaId": 527467,
                        "areaName": "市辖区",
                        "parent_id": 527466,
                        "level": 2
                    },
                    {
                        "areaCode": 450802000000,
                        "areaId": 527468,
                        "areaName": "港北区",
                        "parent_id": 527466,
                        "level": 2
                    },
                    {
                        "areaCode": 450803000000,
                        "areaId": 527600,
                        "areaName": "港南区",
                        "parent_id": 527466,
                        "level": 2
                    },
                    {
                        "areaCode": 450804000000,
                        "areaId": 527777,
                        "areaName": "覃塘区",
                        "parent_id": 527466,
                        "level": 2
                    },
                    {
                        "areaCode": 450821000000,
                        "areaId": 527934,
                        "areaName": "平南县",
                        "parent_id": 527466,
                        "level": 2
                    },
                    {
                        "areaCode": 450881000000,
                        "areaId": 528244,
                        "areaName": "桂平市",
                        "parent_id": 527466,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 450900000000,
                "areaId": 528703,
                "areaName": "玉林市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 450901000000,
                        "areaId": 528704,
                        "areaName": "市辖区",
                        "parent_id": 528703,
                        "level": 2
                    },
                    {
                        "areaCode": 450902000000,
                        "areaId": 528705,
                        "areaName": "玉州区",
                        "parent_id": 528703,
                        "level": 2
                    },
                    {
                        "areaCode": 450903000000,
                        "areaId": 528823,
                        "areaName": "福绵区",
                        "parent_id": 528703,
                        "level": 2
                    },
                    {
                        "areaCode": 450921000000,
                        "areaId": 528946,
                        "areaName": "容县",
                        "parent_id": 528703,
                        "level": 2
                    },
                    {
                        "areaCode": 450922000000,
                        "areaId": 529189,
                        "areaName": "陆川县",
                        "parent_id": 528703,
                        "level": 2
                    },
                    {
                        "areaCode": 450923000000,
                        "areaId": 529369,
                        "areaName": "博白县",
                        "parent_id": 528703,
                        "level": 2
                    },
                    {
                        "areaCode": 450924000000,
                        "areaId": 529757,
                        "areaName": "兴业县",
                        "parent_id": 528703,
                        "level": 2
                    },
                    {
                        "areaCode": 450981000000,
                        "areaId": 529985,
                        "areaName": "北流市",
                        "parent_id": 528703,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 451000000000,
                "areaId": 530323,
                "areaName": "百色市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 451001000000,
                        "areaId": 530324,
                        "areaName": "市辖区",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451002000000,
                        "areaId": 530325,
                        "areaName": "右江区",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451021000000,
                        "areaId": 530455,
                        "areaName": "田阳县",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451022000000,
                        "areaId": 530623,
                        "areaName": "田东县",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451023000000,
                        "areaId": 530802,
                        "areaName": "平果县",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451024000000,
                        "areaId": 530998,
                        "areaName": "德保县",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451026000000,
                        "areaId": 531198,
                        "areaName": "那坡县",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451027000000,
                        "areaId": 531338,
                        "areaName": "凌云县",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451028000000,
                        "areaId": 531457,
                        "areaName": "乐业县",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451029000000,
                        "areaId": 531554,
                        "areaName": "田林县",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451030000000,
                        "areaId": 531737,
                        "areaName": "西林县",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451031000000,
                        "areaId": 531843,
                        "areaName": "隆林各族自治县",
                        "parent_id": 530323,
                        "level": 2
                    },
                    {
                        "areaCode": 451081000000,
                        "areaId": 532039,
                        "areaName": "靖西市",
                        "parent_id": 530323,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 451100000000,
                "areaId": 532351,
                "areaName": "贺州市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 451101000000,
                        "areaId": 532352,
                        "areaName": "市辖区",
                        "parent_id": 532351,
                        "level": 2
                    },
                    {
                        "areaCode": 451102000000,
                        "areaId": 532353,
                        "areaName": "八步区",
                        "parent_id": 532351,
                        "level": 2
                    },
                    {
                        "areaCode": 451103000000,
                        "areaId": 532572,
                        "areaName": "平桂区",
                        "parent_id": 532351,
                        "level": 2
                    },
                    {
                        "areaCode": 451121000000,
                        "areaId": 532706,
                        "areaName": "昭平县",
                        "parent_id": 532351,
                        "level": 2
                    },
                    {
                        "areaCode": 451122000000,
                        "areaId": 532878,
                        "areaName": "钟山县",
                        "parent_id": 532351,
                        "level": 2
                    },
                    {
                        "areaCode": 451123000000,
                        "areaId": 533007,
                        "areaName": "富川瑶族自治县",
                        "parent_id": 532351,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 451200000000,
                "areaId": 533175,
                "areaName": "河池市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 451201000000,
                        "areaId": 533176,
                        "areaName": "市辖区",
                        "parent_id": 533175,
                        "level": 2
                    },
                    {
                        "areaCode": 451202000000,
                        "areaId": 533177,
                        "areaName": "金城江区",
                        "parent_id": 533175,
                        "level": 2
                    },
                    {
                        "areaCode": 451203000000,
                        "areaId": 533335,
                        "areaName": "宜州区",
                        "parent_id": 533175,
                        "level": 2
                    },
                    {
                        "areaCode": 451221000000,
                        "areaId": 533562,
                        "areaName": "南丹县",
                        "parent_id": 533175,
                        "level": 2
                    },
                    {
                        "areaCode": 451222000000,
                        "areaId": 533725,
                        "areaName": "天峨县",
                        "parent_id": 533175,
                        "level": 2
                    },
                    {
                        "areaCode": 451223000000,
                        "areaId": 533830,
                        "areaName": "凤山县",
                        "parent_id": 533175,
                        "level": 2
                    },
                    {
                        "areaCode": 451224000000,
                        "areaId": 533938,
                        "areaName": "东兰县",
                        "parent_id": 533175,
                        "level": 2
                    },
                    {
                        "areaCode": 451225000000,
                        "areaId": 534102,
                        "areaName": "罗城仫佬族自治县",
                        "parent_id": 533175,
                        "level": 2
                    },
                    {
                        "areaCode": 451226000000,
                        "areaId": 534257,
                        "areaName": "环江毛南族自治县",
                        "parent_id": 533175,
                        "level": 2
                    },
                    {
                        "areaCode": 451227000000,
                        "areaId": 534418,
                        "areaName": "巴马瑶族自治县",
                        "parent_id": 533175,
                        "level": 2
                    },
                    {
                        "areaCode": 451228000000,
                        "areaId": 534536,
                        "areaName": "都安瑶族自治县",
                        "parent_id": 533175,
                        "level": 2
                    },
                    {
                        "areaCode": 451229000000,
                        "areaId": 534805,
                        "areaName": "大化瑶族自治县",
                        "parent_id": 533175,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 451300000000,
                "areaId": 534980,
                "areaName": "来宾市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 451301000000,
                        "areaId": 534981,
                        "areaName": "市辖区",
                        "parent_id": 534980,
                        "level": 2
                    },
                    {
                        "areaCode": 451302000000,
                        "areaId": 534982,
                        "areaName": "兴宾区",
                        "parent_id": 534980,
                        "level": 2
                    },
                    {
                        "areaCode": 451321000000,
                        "areaId": 535304,
                        "areaName": "忻城县",
                        "parent_id": 534980,
                        "level": 2
                    },
                    {
                        "areaCode": 451322000000,
                        "areaId": 535447,
                        "areaName": "象州县",
                        "parent_id": 534980,
                        "level": 2
                    },
                    {
                        "areaCode": 451323000000,
                        "areaId": 535584,
                        "areaName": "武宣县",
                        "parent_id": 534980,
                        "level": 2
                    },
                    {
                        "areaCode": 451324000000,
                        "areaId": 535747,
                        "areaName": "金秀瑶族自治县",
                        "parent_id": 534980,
                        "level": 2
                    },
                    {
                        "areaCode": 451381000000,
                        "areaId": 535841,
                        "areaName": "合山市",
                        "parent_id": 534980,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 451400000000,
                "areaId": 535881,
                "areaName": "崇左市",
                "parent_id": 518994,
                "level": 1,
                "children": [
                    {
                        "areaCode": 451401000000,
                        "areaId": 535882,
                        "areaName": "市辖区",
                        "parent_id": 535881,
                        "level": 2
                    },
                    {
                        "areaCode": 451402000000,
                        "areaId": 535883,
                        "areaName": "江州区",
                        "parent_id": 535881,
                        "level": 2
                    },
                    {
                        "areaCode": 451421000000,
                        "areaId": 536036,
                        "areaName": "扶绥县",
                        "parent_id": 535881,
                        "level": 2
                    },
                    {
                        "areaCode": 451422000000,
                        "areaId": 536190,
                        "areaName": "宁明县",
                        "parent_id": 535881,
                        "level": 2
                    },
                    {
                        "areaCode": 451423000000,
                        "areaId": 536388,
                        "areaName": "龙州县",
                        "parent_id": 535881,
                        "level": 2
                    },
                    {
                        "areaCode": 451424000000,
                        "areaId": 536529,
                        "areaName": "大新县",
                        "parent_id": 535881,
                        "level": 2
                    },
                    {
                        "areaCode": 451425000000,
                        "areaId": 536702,
                        "areaName": "天等县",
                        "parent_id": 535881,
                        "level": 2
                    },
                    {
                        "areaCode": 451481000000,
                        "areaId": 536840,
                        "areaName": "凭祥市",
                        "parent_id": 535881,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 460000000000,
        "areaId": 536887,
        "areaName": "海南省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 460100000000,
                "areaId": 536888,
                "areaName": "海口市",
                "parent_id": 536887,
                "level": 1,
                "children": [
                    {
                        "areaCode": 460101000000,
                        "areaId": 536889,
                        "areaName": "市辖区",
                        "parent_id": 536888,
                        "level": 2
                    },
                    {
                        "areaCode": 460105000000,
                        "areaId": 536890,
                        "areaName": "秀英区",
                        "parent_id": 536888,
                        "level": 2
                    },
                    {
                        "areaCode": 460106000000,
                        "areaId": 536994,
                        "areaName": "龙华区",
                        "parent_id": 536888,
                        "level": 2
                    },
                    {
                        "areaCode": 460107000000,
                        "areaId": 537135,
                        "areaName": "琼山区",
                        "parent_id": 536888,
                        "level": 2
                    },
                    {
                        "areaCode": 460108000000,
                        "areaId": 537267,
                        "areaName": "美兰区",
                        "parent_id": 536888,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 460200000000,
                "areaId": 537407,
                "areaName": "三亚市",
                "parent_id": 536887,
                "level": 1,
                "children": [
                    {
                        "areaCode": 460201000000,
                        "areaId": 537408,
                        "areaName": "市辖区",
                        "parent_id": 537407,
                        "level": 2
                    },
                    {
                        "areaCode": 460202000000,
                        "areaId": 537409,
                        "areaName": "海棠区",
                        "parent_id": 537407,
                        "level": 2
                    },
                    {
                        "areaCode": 460203000000,
                        "areaId": 537434,
                        "areaName": "吉阳区",
                        "parent_id": 537407,
                        "level": 2
                    },
                    {
                        "areaCode": 460204000000,
                        "areaId": 537474,
                        "areaName": "天涯区",
                        "parent_id": 537407,
                        "level": 2
                    },
                    {
                        "areaCode": 460205000000,
                        "areaId": 537531,
                        "areaName": "崖州区",
                        "parent_id": 537407,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 460300000000,
                "areaId": 537565,
                "areaName": "三沙市",
                "parent_id": 536887,
                "level": 1,
                "children": [
                    {
                        "areaCode": 460321000000,
                        "areaId": 537566,
                        "areaName": "西沙群岛",
                        "parent_id": 537565,
                        "level": 2
                    },
                    {
                        "areaCode": 460322000000,
                        "areaId": 537569,
                        "areaName": "南沙群岛",
                        "parent_id": 537565,
                        "level": 2
                    },
                    {
                        "areaCode": 460323000000,
                        "areaId": 537572,
                        "areaName": "中沙群岛的岛礁及其海域",
                        "parent_id": 537565,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 460400000000,
                "areaId": 537575,
                "areaName": "儋州市",
                "parent_id": 536887,
                "level": 1,
                "children": [
                    {
                        "areaCode": 460400100000,
                        "areaId": 537576,
                        "areaName": "那大镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400101000,
                        "areaId": 537618,
                        "areaName": "和庆镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400102000,
                        "areaId": 537633,
                        "areaName": "南丰镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400103000,
                        "areaId": 537646,
                        "areaName": "大成镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400104000,
                        "areaId": 537672,
                        "areaName": "雅星镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400105000,
                        "areaId": 537706,
                        "areaName": "兰洋镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400106000,
                        "areaId": 537726,
                        "areaName": "光村镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400107000,
                        "areaId": 537740,
                        "areaName": "木棠镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400108000,
                        "areaId": 537766,
                        "areaName": "海头镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400109000,
                        "areaId": 537781,
                        "areaName": "峨蔓镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400111000,
                        "areaId": 537795,
                        "areaName": "王五镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400112000,
                        "areaId": 537805,
                        "areaName": "白马井镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400113000,
                        "areaId": 537828,
                        "areaName": "中和镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400114000,
                        "areaId": 537841,
                        "areaName": "排浦镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400115000,
                        "areaId": 537851,
                        "areaName": "东成镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400116000,
                        "areaId": 537872,
                        "areaName": "新州镇",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400499000,
                        "areaId": 537904,
                        "areaName": "洋浦经济开发区",
                        "parent_id": 537575,
                        "level": 2
                    },
                    {
                        "areaCode": 460400500000,
                        "areaId": 537932,
                        "areaName": "华南热作学院",
                        "parent_id": 537575,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 469000000000,
                "areaId": 537934,
                "areaName": "直辖县",
                "parent_id": 536887,
                "level": 1,
                "children": [
                    {
                        "areaCode": 469001000000,
                        "areaId": 537935,
                        "areaName": "五指山市",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469002000000,
                        "areaId": 538018,
                        "areaName": "琼海市",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469005000000,
                        "areaId": 538247,
                        "areaName": "文昌市",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469006000000,
                        "areaId": 538570,
                        "areaName": "万宁市",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469007000000,
                        "areaId": 538821,
                        "areaName": "东方市",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469021000000,
                        "areaId": 539038,
                        "areaName": "定安县",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469022000000,
                        "areaId": 539179,
                        "areaName": "屯昌县",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469023000000,
                        "areaId": 539312,
                        "areaName": "澄迈县",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469024000000,
                        "areaId": 539522,
                        "areaName": "临高县",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469025000000,
                        "areaId": 539725,
                        "areaName": "白沙黎族自治县",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469026000000,
                        "areaId": 539829,
                        "areaName": "昌江黎族自治县",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469027000000,
                        "areaId": 539937,
                        "areaName": "乐东黎族自治县",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469028000000,
                        "areaId": 540149,
                        "areaName": "陵水黎族自治县",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469029000000,
                        "areaId": 540282,
                        "areaName": "保亭黎族苗族自治县",
                        "parent_id": 537934,
                        "level": 2
                    },
                    {
                        "areaCode": 469030000000,
                        "areaId": 540368,
                        "areaName": "琼中黎族苗族自治县",
                        "parent_id": 537934,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 500000000000,
        "areaId": 540508,
        "areaName": "重庆市",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 500100000000,
                "areaId": 540509,
                "areaName": "市辖区",
                "parent_id": 540508,
                "level": 1,
                "children": [
                    {
                        "areaCode": 500101000000,
                        "areaId": 540510,
                        "areaName": "万州区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500102000000,
                        "areaId": 541198,
                        "areaName": "涪陵区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500103000000,
                        "areaId": 541648,
                        "areaName": "渝中区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500104000000,
                        "areaId": 541738,
                        "areaName": "大渡口区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500105000000,
                        "areaId": 541839,
                        "areaName": "江北区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500106000000,
                        "areaId": 541983,
                        "areaName": "沙坪坝区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500107000000,
                        "areaId": 542205,
                        "areaName": "九龙坡区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500108000000,
                        "areaId": 542443,
                        "areaName": "南岸区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500109000000,
                        "areaId": 542612,
                        "areaName": "北碚区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500110000000,
                        "areaId": 542821,
                        "areaName": "綦江区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500111000000,
                        "areaId": 543332,
                        "areaName": "大足区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500112000000,
                        "areaId": 543669,
                        "areaName": "渝北区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500113000000,
                        "areaId": 544101,
                        "areaName": "巴南区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500114000000,
                        "areaId": 544424,
                        "areaName": "黔江区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500115000000,
                        "areaId": 544674,
                        "areaName": "长寿区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500116000000,
                        "areaId": 544958,
                        "areaName": "江津区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500117000000,
                        "areaId": 545266,
                        "areaName": "合川区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500118000000,
                        "areaId": 545712,
                        "areaName": "永川区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500119000000,
                        "areaId": 545997,
                        "areaName": "南川区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500120000000,
                        "areaId": 546276,
                        "areaName": "璧山区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500151000000,
                        "areaId": 546479,
                        "areaName": "铜梁区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500152000000,
                        "areaId": 546841,
                        "areaName": "潼南区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500153000000,
                        "areaId": 547168,
                        "areaName": "荣昌区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500154000000,
                        "areaId": 547357,
                        "areaName": "开州区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500155000000,
                        "areaId": 547932,
                        "areaName": "梁平区",
                        "parent_id": 540509,
                        "level": 2
                    },
                    {
                        "areaCode": 500156000000,
                        "areaId": 548313,
                        "areaName": "武隆区",
                        "parent_id": 540509,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 500200000000,
                "areaId": 548550,
                "areaName": "直辖县",
                "parent_id": 540508,
                "level": 1,
                "children": [
                    {
                        "areaCode": 500229000000,
                        "areaId": 548551,
                        "areaName": "城口县",
                        "parent_id": 548550,
                        "level": 2
                    },
                    {
                        "areaCode": 500230000000,
                        "areaId": 548781,
                        "areaName": "丰都县",
                        "parent_id": 548550,
                        "level": 2
                    },
                    {
                        "areaCode": 500231000000,
                        "areaId": 549142,
                        "areaName": "垫江县",
                        "parent_id": 548550,
                        "level": 2
                    },
                    {
                        "areaCode": 500233000000,
                        "areaId": 549470,
                        "areaName": "忠县",
                        "parent_id": 548550,
                        "level": 2
                    },
                    {
                        "areaCode": 500235000000,
                        "areaId": 549868,
                        "areaName": "云阳县",
                        "parent_id": 548550,
                        "level": 2
                    },
                    {
                        "areaCode": 500236000000,
                        "areaId": 550389,
                        "areaName": "奉节县",
                        "parent_id": 548550,
                        "level": 2
                    },
                    {
                        "areaCode": 500237000000,
                        "areaId": 550812,
                        "areaName": "巫山县",
                        "parent_id": 548550,
                        "level": 2
                    },
                    {
                        "areaCode": 500238000000,
                        "areaId": 551180,
                        "areaName": "巫溪县",
                        "parent_id": 548550,
                        "level": 2
                    },
                    {
                        "areaCode": 500240000000,
                        "areaId": 551544,
                        "areaName": "石柱土家族自治县",
                        "parent_id": 548550,
                        "level": 2
                    },
                    {
                        "areaCode": 500241000000,
                        "areaId": 551820,
                        "areaName": "秀山土家族苗族自治县",
                        "parent_id": 548550,
                        "level": 2
                    },
                    {
                        "areaCode": 500242000000,
                        "areaId": 552116,
                        "areaName": "酉阳土家族苗族自治县",
                        "parent_id": 548550,
                        "level": 2
                    },
                    {
                        "areaCode": 500243000000,
                        "areaId": 552434,
                        "areaName": "彭水苗族土家族自治县",
                        "parent_id": 548550,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 510000000000,
        "areaId": 552770,
        "areaName": "四川省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 510100000000,
                "areaId": 552771,
                "areaName": "成都市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 510101000000,
                        "areaId": 552772,
                        "areaName": "市辖区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510104000000,
                        "areaId": 552773,
                        "areaName": "锦江区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510105000000,
                        "areaId": 552879,
                        "areaName": "青羊区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510106000000,
                        "areaId": 552973,
                        "areaName": "金牛区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510107000000,
                        "areaId": 553099,
                        "areaName": "武侯区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510108000000,
                        "areaId": 553248,
                        "areaName": "成华区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510112000000,
                        "areaId": 553366,
                        "areaName": "龙泉驿区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510113000000,
                        "areaId": 553523,
                        "areaName": "青白江区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510114000000,
                        "areaId": 553659,
                        "areaName": "新都区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510115000000,
                        "areaId": 553928,
                        "areaName": "温江区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510116000000,
                        "areaId": 554055,
                        "areaName": "双流区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510117000000,
                        "areaId": 554374,
                        "areaName": "郫都区",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510121000000,
                        "areaId": 554611,
                        "areaName": "金堂县",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510129000000,
                        "areaId": 554865,
                        "areaName": "大邑县",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510131000000,
                        "areaId": 555104,
                        "areaName": "蒲江县",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510132000000,
                        "areaId": 555251,
                        "areaName": "新津县",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510181000000,
                        "areaId": 555370,
                        "areaName": "都江堰市",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510182000000,
                        "areaId": 555646,
                        "areaName": "彭州市",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510183000000,
                        "areaId": 556022,
                        "areaName": "邛崃市",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510184000000,
                        "areaId": 556318,
                        "areaName": "崇州市",
                        "parent_id": 552771,
                        "level": 2
                    },
                    {
                        "areaCode": 510185000000,
                        "areaId": 556597,
                        "areaName": "简阳市",
                        "parent_id": 552771,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 510300000000,
                "areaId": 557510,
                "areaName": "自贡市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 510301000000,
                        "areaId": 557511,
                        "areaName": "市辖区",
                        "parent_id": 557510,
                        "level": 2
                    },
                    {
                        "areaCode": 510302000000,
                        "areaId": 557512,
                        "areaName": "自流井区",
                        "parent_id": 557510,
                        "level": 2
                    },
                    {
                        "areaCode": 510303000000,
                        "areaId": 557648,
                        "areaName": "贡井区",
                        "parent_id": 557510,
                        "level": 2
                    },
                    {
                        "areaCode": 510304000000,
                        "areaId": 557844,
                        "areaName": "大安区",
                        "parent_id": 557510,
                        "level": 2
                    },
                    {
                        "areaCode": 510311000000,
                        "areaId": 558051,
                        "areaName": "沿滩区",
                        "parent_id": 557510,
                        "level": 2
                    },
                    {
                        "areaCode": 510321000000,
                        "areaId": 558258,
                        "areaName": "荣县",
                        "parent_id": 557510,
                        "level": 2
                    },
                    {
                        "areaCode": 510322000000,
                        "areaId": 558633,
                        "areaName": "富顺县",
                        "parent_id": 557510,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 510400000000,
                "areaId": 559048,
                "areaName": "攀枝花市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 510401000000,
                        "areaId": 559049,
                        "areaName": "市辖区",
                        "parent_id": 559048,
                        "level": 2
                    },
                    {
                        "areaCode": 510402000000,
                        "areaId": 559050,
                        "areaName": "东区",
                        "parent_id": 559048,
                        "level": 2
                    },
                    {
                        "areaCode": 510403000000,
                        "areaId": 559130,
                        "areaName": "西区",
                        "parent_id": 559048,
                        "level": 2
                    },
                    {
                        "areaCode": 510411000000,
                        "areaId": 559180,
                        "areaName": "仁和区",
                        "parent_id": 559048,
                        "level": 2
                    },
                    {
                        "areaCode": 510421000000,
                        "areaId": 559296,
                        "areaName": "米易县",
                        "parent_id": 559048,
                        "level": 2
                    },
                    {
                        "areaCode": 510422000000,
                        "areaId": 559408,
                        "areaName": "盐边县",
                        "parent_id": 559048,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 510500000000,
                "areaId": 559597,
                "areaName": "泸州市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 510501000000,
                        "areaId": 559598,
                        "areaName": "市辖区",
                        "parent_id": 559597,
                        "level": 2
                    },
                    {
                        "areaCode": 510502000000,
                        "areaId": 559599,
                        "areaName": "江阳区",
                        "parent_id": 559597,
                        "level": 2
                    },
                    {
                        "areaCode": 510503000000,
                        "areaId": 559776,
                        "areaName": "纳溪区",
                        "parent_id": 559597,
                        "level": 2
                    },
                    {
                        "areaCode": 510504000000,
                        "areaId": 559995,
                        "areaName": "龙马潭区",
                        "parent_id": 559597,
                        "level": 2
                    },
                    {
                        "areaCode": 510521000000,
                        "areaId": 560098,
                        "areaName": "泸县",
                        "parent_id": 559597,
                        "level": 2
                    },
                    {
                        "areaCode": 510522000000,
                        "areaId": 560424,
                        "areaName": "合江县",
                        "parent_id": 559597,
                        "level": 2
                    },
                    {
                        "areaCode": 510524000000,
                        "areaId": 560779,
                        "areaName": "叙永县",
                        "parent_id": 559597,
                        "level": 2
                    },
                    {
                        "areaCode": 510525000000,
                        "areaId": 561069,
                        "areaName": "古蔺县",
                        "parent_id": 559597,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 510600000000,
                "areaId": 561389,
                "areaName": "德阳市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 510601000000,
                        "areaId": 561390,
                        "areaName": "市辖区",
                        "parent_id": 561389,
                        "level": 2
                    },
                    {
                        "areaCode": 510603000000,
                        "areaId": 561391,
                        "areaName": "旌阳区",
                        "parent_id": 561389,
                        "level": 2
                    },
                    {
                        "areaCode": 510604000000,
                        "areaId": 561639,
                        "areaName": "罗江区",
                        "parent_id": 561389,
                        "level": 2
                    },
                    {
                        "areaCode": 510623000000,
                        "areaId": 561777,
                        "areaName": "中江县",
                        "parent_id": 561389,
                        "level": 2
                    },
                    {
                        "areaCode": 510681000000,
                        "areaId": 562660,
                        "areaName": "广汉市",
                        "parent_id": 561389,
                        "level": 2
                    },
                    {
                        "areaCode": 510682000000,
                        "areaId": 562902,
                        "areaName": "什邡市",
                        "parent_id": 561389,
                        "level": 2
                    },
                    {
                        "areaCode": 510683000000,
                        "areaId": 563090,
                        "areaName": "绵竹市",
                        "parent_id": 561389,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 510700000000,
                "areaId": 563319,
                "areaName": "绵阳市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 510701000000,
                        "areaId": 563320,
                        "areaName": "市辖区",
                        "parent_id": 563319,
                        "level": 2
                    },
                    {
                        "areaCode": 510703000000,
                        "areaId": 563321,
                        "areaName": "涪城区",
                        "parent_id": 563319,
                        "level": 2
                    },
                    {
                        "areaCode": 510704000000,
                        "areaId": 563599,
                        "areaName": "游仙区",
                        "parent_id": 563319,
                        "level": 2
                    },
                    {
                        "areaCode": 510705000000,
                        "areaId": 563947,
                        "areaName": "安州区",
                        "parent_id": 563319,
                        "level": 2
                    },
                    {
                        "areaCode": 510722000000,
                        "areaId": 564227,
                        "areaName": "三台县",
                        "parent_id": 563319,
                        "level": 2
                    },
                    {
                        "areaCode": 510723000000,
                        "areaId": 565333,
                        "areaName": "盐亭县",
                        "parent_id": 563319,
                        "level": 2
                    },
                    {
                        "areaCode": 510725000000,
                        "areaId": 565889,
                        "areaName": "梓潼县",
                        "parent_id": 563319,
                        "level": 2
                    },
                    {
                        "areaCode": 510726000000,
                        "areaId": 566271,
                        "areaName": "北川羌族自治县",
                        "parent_id": 563319,
                        "level": 2
                    },
                    {
                        "areaCode": 510727000000,
                        "areaId": 566638,
                        "areaName": "平武县",
                        "parent_id": 563319,
                        "level": 2
                    },
                    {
                        "areaCode": 510781000000,
                        "areaId": 566928,
                        "areaName": "江油市",
                        "parent_id": 563319,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 510800000000,
                "areaId": 567432,
                "areaName": "广元市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 510801000000,
                        "areaId": 567433,
                        "areaName": "市辖区",
                        "parent_id": 567432,
                        "level": 2
                    },
                    {
                        "areaCode": 510802000000,
                        "areaId": 567434,
                        "areaName": "利州区",
                        "parent_id": 567432,
                        "level": 2
                    },
                    {
                        "areaCode": 510811000000,
                        "areaId": 567710,
                        "areaName": "昭化区",
                        "parent_id": 567432,
                        "level": 2
                    },
                    {
                        "areaCode": 510812000000,
                        "areaId": 567976,
                        "areaName": "朝天区",
                        "parent_id": 567432,
                        "level": 2
                    },
                    {
                        "areaCode": 510821000000,
                        "areaId": 568223,
                        "areaName": "旺苍县",
                        "parent_id": 567432,
                        "level": 2
                    },
                    {
                        "areaCode": 510822000000,
                        "areaId": 568657,
                        "areaName": "青川县",
                        "parent_id": 567432,
                        "level": 2
                    },
                    {
                        "areaCode": 510823000000,
                        "areaId": 569005,
                        "areaName": "剑阁县",
                        "parent_id": 567432,
                        "level": 2
                    },
                    {
                        "areaCode": 510824000000,
                        "areaId": 569642,
                        "areaName": "苍溪县",
                        "parent_id": 567432,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 510900000000,
                "areaId": 570493,
                "areaName": "遂宁市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 510901000000,
                        "areaId": 570494,
                        "areaName": "市辖区",
                        "parent_id": 570493,
                        "level": 2
                    },
                    {
                        "areaCode": 510903000000,
                        "areaId": 570495,
                        "areaName": "船山区",
                        "parent_id": 570493,
                        "level": 2
                    },
                    {
                        "areaCode": 510904000000,
                        "areaId": 570822,
                        "areaName": "安居区",
                        "parent_id": 570493,
                        "level": 2
                    },
                    {
                        "areaCode": 510921000000,
                        "areaId": 571370,
                        "areaName": "蓬溪县",
                        "parent_id": 570493,
                        "level": 2
                    },
                    {
                        "areaCode": 510922000000,
                        "areaId": 571954,
                        "areaName": "射洪县",
                        "parent_id": 570493,
                        "level": 2
                    },
                    {
                        "areaCode": 510923000000,
                        "areaId": 572643,
                        "areaName": "大英县",
                        "parent_id": 570493,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 511000000000,
                "areaId": 572991,
                "areaName": "内江市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 511001000000,
                        "areaId": 572992,
                        "areaName": "市辖区",
                        "parent_id": 572991,
                        "level": 2
                    },
                    {
                        "areaCode": 511002000000,
                        "areaId": 572993,
                        "areaName": "市中区",
                        "parent_id": 572991,
                        "level": 2
                    },
                    {
                        "areaCode": 511011000000,
                        "areaId": 573212,
                        "areaName": "东兴区",
                        "parent_id": 572991,
                        "level": 2
                    },
                    {
                        "areaCode": 511024000000,
                        "areaId": 573732,
                        "areaName": "威远县",
                        "parent_id": 572991,
                        "level": 2
                    },
                    {
                        "areaCode": 511025000000,
                        "areaId": 574127,
                        "areaName": "资中县",
                        "parent_id": 572991,
                        "level": 2
                    },
                    {
                        "areaCode": 511071000000,
                        "areaId": 575001,
                        "areaName": "内江经济开发区",
                        "parent_id": 572991,
                        "level": 2
                    },
                    {
                        "areaCode": 511083000000,
                        "areaId": 575032,
                        "areaName": "隆昌市",
                        "parent_id": 572991,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 511100000000,
                "areaId": 575465,
                "areaName": "乐山市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 511101000000,
                        "areaId": 575466,
                        "areaName": "市辖区",
                        "parent_id": 575465,
                        "level": 2
                    },
                    {
                        "areaCode": 511102000000,
                        "areaId": 575467,
                        "areaName": "市中区",
                        "parent_id": 575465,
                        "level": 2
                    },
                    {
                        "areaCode": 511111000000,
                        "areaId": 575790,
                        "areaName": "沙湾区",
                        "parent_id": 575465,
                        "level": 2
                    },
                    {
                        "areaCode": 511112000000,
                        "areaId": 575953,
                        "areaName": "五通桥区",
                        "parent_id": 575465,
                        "level": 2
                    },
                    {
                        "areaCode": 511113000000,
                        "areaId": 576138,
                        "areaName": "金口河区",
                        "parent_id": 575465,
                        "level": 2
                    },
                    {
                        "areaCode": 511123000000,
                        "areaId": 576190,
                        "areaName": "犍为县",
                        "parent_id": 575465,
                        "level": 2
                    },
                    {
                        "areaCode": 511124000000,
                        "areaId": 576610,
                        "areaName": "井研县",
                        "parent_id": 575465,
                        "level": 2
                    },
                    {
                        "areaCode": 511126000000,
                        "areaId": 576869,
                        "areaName": "夹江县",
                        "parent_id": 575465,
                        "level": 2
                    },
                    {
                        "areaCode": 511129000000,
                        "areaId": 577139,
                        "areaName": "沐川县",
                        "parent_id": 575465,
                        "level": 2
                    },
                    {
                        "areaCode": 511132000000,
                        "areaId": 577372,
                        "areaName": "峨边彝族自治县",
                        "parent_id": 575465,
                        "level": 2
                    },
                    {
                        "areaCode": 511133000000,
                        "areaId": 577530,
                        "areaName": "马边彝族自治县",
                        "parent_id": 575465,
                        "level": 2
                    },
                    {
                        "areaCode": 511181000000,
                        "areaId": 577674,
                        "areaName": "峨眉山市",
                        "parent_id": 575465,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 511300000000,
                "areaId": 577967,
                "areaName": "南充市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 511301000000,
                        "areaId": 577968,
                        "areaName": "市辖区",
                        "parent_id": 577967,
                        "level": 2
                    },
                    {
                        "areaCode": 511302000000,
                        "areaId": 577969,
                        "areaName": "顺庆区",
                        "parent_id": 577967,
                        "level": 2
                    },
                    {
                        "areaCode": 511303000000,
                        "areaId": 578316,
                        "areaName": "高坪区",
                        "parent_id": 577967,
                        "level": 2
                    },
                    {
                        "areaCode": 511304000000,
                        "areaId": 578748,
                        "areaName": "嘉陵区",
                        "parent_id": 577967,
                        "level": 2
                    },
                    {
                        "areaCode": 511321000000,
                        "areaId": 579386,
                        "areaName": "南部县",
                        "parent_id": 577967,
                        "level": 2
                    },
                    {
                        "areaCode": 511322000000,
                        "areaId": 580579,
                        "areaName": "营山县",
                        "parent_id": 577967,
                        "level": 2
                    },
                    {
                        "areaCode": 511323000000,
                        "areaId": 581318,
                        "areaName": "蓬安县",
                        "parent_id": 577967,
                        "level": 2
                    },
                    {
                        "areaCode": 511324000000,
                        "areaId": 582007,
                        "areaName": "仪陇县",
                        "parent_id": 577967,
                        "level": 2
                    },
                    {
                        "areaCode": 511325000000,
                        "areaId": 583001,
                        "areaName": "西充县",
                        "parent_id": 577967,
                        "level": 2
                    },
                    {
                        "areaCode": 511381000000,
                        "areaId": 583667,
                        "areaName": "阆中市",
                        "parent_id": 577967,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 511400000000,
                "areaId": 584232,
                "areaName": "眉山市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 511401000000,
                        "areaId": 584233,
                        "areaName": "市辖区",
                        "parent_id": 584232,
                        "level": 2
                    },
                    {
                        "areaCode": 511402000000,
                        "areaId": 584234,
                        "areaName": "东坡区",
                        "parent_id": 584232,
                        "level": 2
                    },
                    {
                        "areaCode": 511403000000,
                        "areaId": 584572,
                        "areaName": "彭山区",
                        "parent_id": 584232,
                        "level": 2
                    },
                    {
                        "areaCode": 511421000000,
                        "areaId": 584694,
                        "areaName": "仁寿县",
                        "parent_id": 584232,
                        "level": 2
                    },
                    {
                        "areaCode": 511423000000,
                        "areaId": 585373,
                        "areaName": "洪雅县",
                        "parent_id": 584232,
                        "level": 2
                    },
                    {
                        "areaCode": 511424000000,
                        "areaId": 585548,
                        "areaName": "丹棱县",
                        "parent_id": 584232,
                        "level": 2
                    },
                    {
                        "areaCode": 511425000000,
                        "areaId": 585634,
                        "areaName": "青神县",
                        "parent_id": 584232,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 511500000000,
                "areaId": 585734,
                "areaName": "宜宾市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 511501000000,
                        "areaId": 585735,
                        "areaName": "市辖区",
                        "parent_id": 585734,
                        "level": 2
                    },
                    {
                        "areaCode": 511502000000,
                        "areaId": 585736,
                        "areaName": "翠屏区",
                        "parent_id": 585734,
                        "level": 2
                    },
                    {
                        "areaCode": 511503000000,
                        "areaId": 586107,
                        "areaName": "南溪区",
                        "parent_id": 585734,
                        "level": 2
                    },
                    {
                        "areaCode": 511521000000,
                        "areaId": 586364,
                        "areaName": "宜宾县",
                        "parent_id": 585734,
                        "level": 2
                    },
                    {
                        "areaCode": 511523000000,
                        "areaId": 586981,
                        "areaName": "江安县",
                        "parent_id": 585734,
                        "level": 2
                    },
                    {
                        "areaCode": 511524000000,
                        "areaId": 587324,
                        "areaName": "长宁县",
                        "parent_id": 585734,
                        "level": 2
                    },
                    {
                        "areaCode": 511525000000,
                        "areaId": 587642,
                        "areaName": "高县",
                        "parent_id": 585734,
                        "level": 2
                    },
                    {
                        "areaCode": 511526000000,
                        "areaId": 587979,
                        "areaName": "珙县",
                        "parent_id": 585734,
                        "level": 2
                    },
                    {
                        "areaCode": 511527000000,
                        "areaId": 588260,
                        "areaName": "筠连县",
                        "parent_id": 585734,
                        "level": 2
                    },
                    {
                        "areaCode": 511528000000,
                        "areaId": 588539,
                        "areaName": "兴文县",
                        "parent_id": 585734,
                        "level": 2
                    },
                    {
                        "areaCode": 511529000000,
                        "areaId": 588814,
                        "areaName": "屏山县",
                        "parent_id": 585734,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 511600000000,
                "areaId": 589105,
                "areaName": "广安市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 511601000000,
                        "areaId": 589106,
                        "areaName": "市辖区",
                        "parent_id": 589105,
                        "level": 2
                    },
                    {
                        "areaCode": 511602000000,
                        "areaId": 589107,
                        "areaName": "广安区",
                        "parent_id": 589105,
                        "level": 2
                    },
                    {
                        "areaCode": 511603000000,
                        "areaId": 589760,
                        "areaName": "前锋区",
                        "parent_id": 589105,
                        "level": 2
                    },
                    {
                        "areaCode": 511621000000,
                        "areaId": 590061,
                        "areaName": "岳池县",
                        "parent_id": 589105,
                        "level": 2
                    },
                    {
                        "areaCode": 511622000000,
                        "areaId": 590985,
                        "areaName": "武胜县",
                        "parent_id": 589105,
                        "level": 2
                    },
                    {
                        "areaCode": 511623000000,
                        "areaId": 591576,
                        "areaName": "邻水县",
                        "parent_id": 589105,
                        "level": 2
                    },
                    {
                        "areaCode": 511681000000,
                        "areaId": 592147,
                        "areaName": "华蓥市",
                        "parent_id": 589105,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 511700000000,
                "areaId": 592296,
                "areaName": "达州市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 511701000000,
                        "areaId": 592297,
                        "areaName": "市辖区",
                        "parent_id": 592296,
                        "level": 2
                    },
                    {
                        "areaCode": 511702000000,
                        "areaId": 592298,
                        "areaName": "通川区",
                        "parent_id": 592296,
                        "level": 2
                    },
                    {
                        "areaCode": 511703000000,
                        "areaId": 592594,
                        "areaName": "达川区",
                        "parent_id": 592296,
                        "level": 2
                    },
                    {
                        "areaCode": 511722000000,
                        "areaId": 593332,
                        "areaName": "宣汉县",
                        "parent_id": 592296,
                        "level": 2
                    },
                    {
                        "areaCode": 511723000000,
                        "areaId": 593956,
                        "areaName": "开江县",
                        "parent_id": 592296,
                        "level": 2
                    },
                    {
                        "areaCode": 511724000000,
                        "areaId": 594204,
                        "areaName": "大竹县",
                        "parent_id": 592296,
                        "level": 2
                    },
                    {
                        "areaCode": 511725000000,
                        "areaId": 594699,
                        "areaName": "渠县",
                        "parent_id": 592296,
                        "level": 2
                    },
                    {
                        "areaCode": 511771000000,
                        "areaId": 595326,
                        "areaName": "达州经济开发区",
                        "parent_id": 592296,
                        "level": 2
                    },
                    {
                        "areaCode": 511781000000,
                        "areaId": 595375,
                        "areaName": "万源市",
                        "parent_id": 592296,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 511800000000,
                "areaId": 595842,
                "areaName": "雅安市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 511801000000,
                        "areaId": 595843,
                        "areaName": "市辖区",
                        "parent_id": 595842,
                        "level": 2
                    },
                    {
                        "areaCode": 511802000000,
                        "areaId": 595844,
                        "areaName": "雨城区",
                        "parent_id": 595842,
                        "level": 2
                    },
                    {
                        "areaCode": 511803000000,
                        "areaId": 596074,
                        "areaName": "名山区",
                        "parent_id": 595842,
                        "level": 2
                    },
                    {
                        "areaCode": 511822000000,
                        "areaId": 596304,
                        "areaName": "荥经县",
                        "parent_id": 595842,
                        "level": 2
                    },
                    {
                        "areaCode": 511823000000,
                        "areaId": 596438,
                        "areaName": "汉源县",
                        "parent_id": 595842,
                        "level": 2
                    },
                    {
                        "areaCode": 511824000000,
                        "areaId": 596679,
                        "areaName": "石棉县",
                        "parent_id": 595842,
                        "level": 2
                    },
                    {
                        "areaCode": 511825000000,
                        "areaId": 596797,
                        "areaName": "天全县",
                        "parent_id": 595842,
                        "level": 2
                    },
                    {
                        "areaCode": 511826000000,
                        "areaId": 596957,
                        "areaName": "芦山县",
                        "parent_id": 595842,
                        "level": 2
                    },
                    {
                        "areaCode": 511827000000,
                        "areaId": 597014,
                        "areaName": "宝兴县",
                        "parent_id": 595842,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 511900000000,
                "areaId": 597082,
                "areaName": "巴中市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 511901000000,
                        "areaId": 597083,
                        "areaName": "市辖区",
                        "parent_id": 597082,
                        "level": 2
                    },
                    {
                        "areaCode": 511902000000,
                        "areaId": 597084,
                        "areaName": "巴州区",
                        "parent_id": 597082,
                        "level": 2
                    },
                    {
                        "areaCode": 511903000000,
                        "areaId": 597550,
                        "areaName": "恩阳区",
                        "parent_id": 597082,
                        "level": 2
                    },
                    {
                        "areaCode": 511921000000,
                        "areaId": 598017,
                        "areaName": "通江县",
                        "parent_id": 597082,
                        "level": 2
                    },
                    {
                        "areaCode": 511922000000,
                        "areaId": 598635,
                        "areaName": "南江县",
                        "parent_id": 597082,
                        "level": 2
                    },
                    {
                        "areaCode": 511923000000,
                        "areaId": 599311,
                        "areaName": "平昌县",
                        "parent_id": 597082,
                        "level": 2
                    },
                    {
                        "areaCode": 511971000000,
                        "areaId": 599906,
                        "areaName": "巴中经济开发区",
                        "parent_id": 597082,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 512000000000,
                "areaId": 599951,
                "areaName": "资阳市",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 512001000000,
                        "areaId": 599952,
                        "areaName": "市辖区",
                        "parent_id": 599951,
                        "level": 2
                    },
                    {
                        "areaCode": 512002000000,
                        "areaId": 599953,
                        "areaName": "雁江区",
                        "parent_id": 599951,
                        "level": 2
                    },
                    {
                        "areaCode": 512021000000,
                        "areaId": 600510,
                        "areaName": "安岳县",
                        "parent_id": 599951,
                        "level": 2
                    },
                    {
                        "areaCode": 512022000000,
                        "areaId": 601558,
                        "areaName": "乐至县",
                        "parent_id": 599951,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 513200000000,
                "areaId": 602217,
                "areaName": "阿坝藏族羌族自治州",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 513201000000,
                        "areaId": 602218,
                        "areaName": "马尔康市",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513221000000,
                        "areaId": 602341,
                        "areaName": "汶川县",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513222000000,
                        "areaId": 602481,
                        "areaName": "理县",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513223000000,
                        "areaId": 602584,
                        "areaName": "茂县",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513224000000,
                        "areaId": 602758,
                        "areaName": "松潘县",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513225000000,
                        "areaId": 602933,
                        "areaName": "九寨沟县",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513226000000,
                        "areaId": 603085,
                        "areaName": "金川县",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513227000000,
                        "areaId": 603220,
                        "areaName": "小金县",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513228000000,
                        "areaId": 603378,
                        "areaName": "黑水县",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513230000000,
                        "areaId": 603524,
                        "areaName": "壤塘县",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513231000000,
                        "areaId": 603600,
                        "areaName": "阿坝县",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513232000000,
                        "areaId": 603711,
                        "areaName": "若尔盖县",
                        "parent_id": 602217,
                        "level": 2
                    },
                    {
                        "areaCode": 513233000000,
                        "areaId": 603832,
                        "areaName": "红原县",
                        "parent_id": 602217,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 513300000000,
                "areaId": 603882,
                "areaName": "甘孜藏族自治州",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 513301000000,
                        "areaId": 603883,
                        "areaName": "康定市",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513322000000,
                        "areaId": 604149,
                        "areaName": "泸定县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513323000000,
                        "areaId": 604314,
                        "areaName": "丹巴县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513324000000,
                        "areaId": 604515,
                        "areaName": "九龙县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513325000000,
                        "areaId": 604599,
                        "areaName": "雅江县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513326000000,
                        "areaId": 604731,
                        "areaName": "道孚县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513327000000,
                        "areaId": 604914,
                        "areaName": "炉霍县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513328000000,
                        "areaId": 605106,
                        "areaName": "甘孜县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513329000000,
                        "areaId": 605352,
                        "areaName": "新龙县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513330000000,
                        "areaId": 605522,
                        "areaName": "德格县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513331000000,
                        "areaId": 605723,
                        "areaName": "白玉县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513332000000,
                        "areaId": 605900,
                        "areaName": "石渠县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513333000000,
                        "areaId": 606093,
                        "areaName": "色达县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513334000000,
                        "areaId": 606249,
                        "areaName": "理塘县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513335000000,
                        "areaId": 606492,
                        "areaName": "巴塘县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513336000000,
                        "areaId": 606635,
                        "areaName": "乡城县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513337000000,
                        "areaId": 606740,
                        "areaName": "稻城县",
                        "parent_id": 603882,
                        "level": 2
                    },
                    {
                        "areaCode": 513338000000,
                        "areaId": 606879,
                        "areaName": "得荣县",
                        "parent_id": 603882,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 513400000000,
                "areaId": 607022,
                "areaName": "凉山彝族自治州",
                "parent_id": 552770,
                "level": 1,
                "children": [
                    {
                        "areaCode": 513401000000,
                        "areaId": 607023,
                        "areaName": "西昌市",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513422000000,
                        "areaId": 607337,
                        "areaName": "木里藏族自治县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513423000000,
                        "areaId": 607490,
                        "areaName": "盐源县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513424000000,
                        "areaId": 607778,
                        "areaName": "德昌县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513425000000,
                        "areaId": 607940,
                        "areaName": "会理县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513426000000,
                        "areaId": 608281,
                        "areaName": "会东县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513427000000,
                        "areaId": 608627,
                        "areaName": "宁南县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513428000000,
                        "areaId": 608787,
                        "areaName": "普格县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513429000000,
                        "areaId": 608983,
                        "areaName": "布拖县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513430000000,
                        "areaId": 609206,
                        "areaName": "金阳县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513431000000,
                        "areaId": 609419,
                        "areaName": "昭觉县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513432000000,
                        "areaId": 609739,
                        "areaName": "喜德县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513433000000,
                        "areaId": 609937,
                        "areaName": "冕宁县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513434000000,
                        "areaId": 610208,
                        "areaName": "越西县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513435000000,
                        "areaId": 610541,
                        "areaName": "甘洛县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513436000000,
                        "areaId": 610800,
                        "areaName": "美姑县",
                        "parent_id": 607022,
                        "level": 2
                    },
                    {
                        "areaCode": 513437000000,
                        "areaId": 611132,
                        "areaName": "雷波县",
                        "parent_id": 607022,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 520000000000,
        "areaId": 611471,
        "areaName": "贵州省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 520100000000,
                "areaId": 611472,
                "areaName": "贵阳市",
                "parent_id": 611471,
                "level": 1,
                "children": [
                    {
                        "areaCode": 520101000000,
                        "areaId": 611473,
                        "areaName": "市辖区",
                        "parent_id": 611472,
                        "level": 2
                    },
                    {
                        "areaCode": 520102000000,
                        "areaId": 611474,
                        "areaName": "南明区",
                        "parent_id": 611472,
                        "level": 2
                    },
                    {
                        "areaCode": 520103000000,
                        "areaId": 611690,
                        "areaName": "云岩区",
                        "parent_id": 611472,
                        "level": 2
                    },
                    {
                        "areaCode": 520111000000,
                        "areaId": 611893,
                        "areaName": "花溪区",
                        "parent_id": 611472,
                        "level": 2
                    },
                    {
                        "areaCode": 520112000000,
                        "areaId": 612133,
                        "areaName": "乌当区",
                        "parent_id": 611472,
                        "level": 2
                    },
                    {
                        "areaCode": 520113000000,
                        "areaId": 612248,
                        "areaName": "白云区",
                        "parent_id": 611472,
                        "level": 2
                    },
                    {
                        "areaCode": 520115000000,
                        "areaId": 612354,
                        "areaName": "观山湖区",
                        "parent_id": 611472,
                        "level": 2
                    },
                    {
                        "areaCode": 520121000000,
                        "areaId": 612486,
                        "areaName": "开阳县",
                        "parent_id": 611472,
                        "level": 2
                    },
                    {
                        "areaCode": 520122000000,
                        "areaId": 612639,
                        "areaName": "息烽县",
                        "parent_id": 611472,
                        "level": 2
                    },
                    {
                        "areaCode": 520123000000,
                        "areaId": 612828,
                        "areaName": "修文县",
                        "parent_id": 611472,
                        "level": 2
                    },
                    {
                        "areaCode": 520181000000,
                        "areaId": 612968,
                        "areaName": "清镇市",
                        "parent_id": 611472,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 520200000000,
                "areaId": 613210,
                "areaName": "六盘水市",
                "parent_id": 611471,
                "level": 1,
                "children": [
                    {
                        "areaCode": 520201000000,
                        "areaId": 613211,
                        "areaName": "钟山区",
                        "parent_id": 613210,
                        "level": 2
                    },
                    {
                        "areaCode": 520203000000,
                        "areaId": 613370,
                        "areaName": "六枝特区",
                        "parent_id": 613210,
                        "level": 2
                    },
                    {
                        "areaCode": 520221000000,
                        "areaId": 613632,
                        "areaName": "水城县",
                        "parent_id": 613210,
                        "level": 2
                    },
                    {
                        "areaCode": 520281000000,
                        "areaId": 613868,
                        "areaName": "盘州市",
                        "parent_id": 613210,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 520300000000,
                "areaId": 614412,
                "areaName": "遵义市",
                "parent_id": 611471,
                "level": 1,
                "children": [
                    {
                        "areaCode": 520301000000,
                        "areaId": 614413,
                        "areaName": "市辖区",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520302000000,
                        "areaId": 614414,
                        "areaName": "红花岗区",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520303000000,
                        "areaId": 614619,
                        "areaName": "汇川区",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520304000000,
                        "areaId": 614769,
                        "areaName": "播州区",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520322000000,
                        "areaId": 614970,
                        "areaName": "桐梓县",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520323000000,
                        "areaId": 615221,
                        "areaName": "绥阳县",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520324000000,
                        "areaId": 615356,
                        "areaName": "正安县",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520325000000,
                        "areaId": 615528,
                        "areaName": "道真仡佬族苗族自治县",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520326000000,
                        "areaId": 615626,
                        "areaName": "务川仡佬族苗族自治县",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520327000000,
                        "areaId": 615764,
                        "areaName": "凤冈县",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520328000000,
                        "areaId": 615865,
                        "areaName": "湄潭县",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520329000000,
                        "areaId": 616014,
                        "areaName": "余庆县",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520330000000,
                        "areaId": 616095,
                        "areaName": "习水县",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520381000000,
                        "areaId": 616370,
                        "areaName": "赤水市",
                        "parent_id": 614412,
                        "level": 2
                    },
                    {
                        "areaCode": 520382000000,
                        "areaId": 616512,
                        "areaName": "仁怀市",
                        "parent_id": 614412,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 520400000000,
                "areaId": 616707,
                "areaName": "安顺市",
                "parent_id": 611471,
                "level": 1,
                "children": [
                    {
                        "areaCode": 520401000000,
                        "areaId": 616708,
                        "areaName": "市辖区",
                        "parent_id": 616707,
                        "level": 2
                    },
                    {
                        "areaCode": 520402000000,
                        "areaId": 616709,
                        "areaName": "西秀区",
                        "parent_id": 616707,
                        "level": 2
                    },
                    {
                        "areaCode": 520403000000,
                        "areaId": 617087,
                        "areaName": "平坝区",
                        "parent_id": 616707,
                        "level": 2
                    },
                    {
                        "areaCode": 520422000000,
                        "areaId": 617246,
                        "areaName": "普定县",
                        "parent_id": 616707,
                        "level": 2
                    },
                    {
                        "areaCode": 520423000000,
                        "areaId": 617431,
                        "areaName": "镇宁布依族苗族自治县",
                        "parent_id": 616707,
                        "level": 2
                    },
                    {
                        "areaCode": 520424000000,
                        "areaId": 617657,
                        "areaName": "关岭布依族苗族自治县",
                        "parent_id": 616707,
                        "level": 2
                    },
                    {
                        "areaCode": 520425000000,
                        "areaId": 617828,
                        "areaName": "紫云苗族布依族自治县",
                        "parent_id": 616707,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 520500000000,
                "areaId": 618009,
                "areaName": "毕节市",
                "parent_id": 611471,
                "level": 1,
                "children": [
                    {
                        "areaCode": 520501000000,
                        "areaId": 618010,
                        "areaName": "市辖区",
                        "parent_id": 618009,
                        "level": 2
                    },
                    {
                        "areaCode": 520502000000,
                        "areaId": 618011,
                        "areaName": "七星关区",
                        "parent_id": 618009,
                        "level": 2
                    },
                    {
                        "areaCode": 520521000000,
                        "areaId": 618615,
                        "areaName": "大方县",
                        "parent_id": 618009,
                        "level": 2
                    },
                    {
                        "areaCode": 520522000000,
                        "areaId": 619034,
                        "areaName": "黔西县",
                        "parent_id": 618009,
                        "level": 2
                    },
                    {
                        "areaCode": 520523000000,
                        "areaId": 619450,
                        "areaName": "金沙县",
                        "parent_id": 618009,
                        "level": 2
                    },
                    {
                        "areaCode": 520524000000,
                        "areaId": 619715,
                        "areaName": "织金县",
                        "parent_id": 618009,
                        "level": 2
                    },
                    {
                        "areaCode": 520525000000,
                        "areaId": 620322,
                        "areaName": "纳雍县",
                        "parent_id": 618009,
                        "level": 2
                    },
                    {
                        "areaCode": 520526000000,
                        "areaId": 620776,
                        "areaName": "威宁彝族回族苗族自治县",
                        "parent_id": 618009,
                        "level": 2
                    },
                    {
                        "areaCode": 520527000000,
                        "areaId": 621445,
                        "areaName": "赫章县",
                        "parent_id": 618009,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 520600000000,
                "areaId": 621940,
                "areaName": "铜仁市",
                "parent_id": 611471,
                "level": 1,
                "children": [
                    {
                        "areaCode": 520601000000,
                        "areaId": 621941,
                        "areaName": "市辖区",
                        "parent_id": 621940,
                        "level": 2
                    },
                    {
                        "areaCode": 520602000000,
                        "areaId": 621942,
                        "areaName": "碧江区",
                        "parent_id": 621940,
                        "level": 2
                    },
                    {
                        "areaCode": 520603000000,
                        "areaId": 622046,
                        "areaName": "万山区",
                        "parent_id": 621940,
                        "level": 2
                    },
                    {
                        "areaCode": 520621000000,
                        "areaId": 622152,
                        "areaName": "江口县",
                        "parent_id": 621940,
                        "level": 2
                    },
                    {
                        "areaCode": 520622000000,
                        "areaId": 622319,
                        "areaName": "玉屏侗族自治县",
                        "parent_id": 621940,
                        "level": 2
                    },
                    {
                        "areaCode": 520623000000,
                        "areaId": 622420,
                        "areaName": "石阡县",
                        "parent_id": 621940,
                        "level": 2
                    },
                    {
                        "areaCode": 520624000000,
                        "areaId": 622750,
                        "areaName": "思南县",
                        "parent_id": 621940,
                        "level": 2
                    },
                    {
                        "areaCode": 520625000000,
                        "areaId": 623305,
                        "areaName": "印江土家族苗族自治县",
                        "parent_id": 621940,
                        "level": 2
                    },
                    {
                        "areaCode": 520626000000,
                        "areaId": 623697,
                        "areaName": "德江县",
                        "parent_id": 621940,
                        "level": 2
                    },
                    {
                        "areaCode": 520627000000,
                        "areaId": 624063,
                        "areaName": "沿河土家族自治县",
                        "parent_id": 621940,
                        "level": 2
                    },
                    {
                        "areaCode": 520628000000,
                        "areaId": 624535,
                        "areaName": "松桃苗族自治县",
                        "parent_id": 621940,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 522300000000,
                "areaId": 625088,
                "areaName": "黔西南布依族苗族自治州",
                "parent_id": 611471,
                "level": 1,
                "children": [
                    {
                        "areaCode": 522301000000,
                        "areaId": 625089,
                        "areaName": "兴义市",
                        "parent_id": 625088,
                        "level": 2
                    },
                    {
                        "areaCode": 522322000000,
                        "areaId": 625343,
                        "areaName": "兴仁县",
                        "parent_id": 625088,
                        "level": 2
                    },
                    {
                        "areaCode": 522323000000,
                        "areaId": 625525,
                        "areaName": "普安县",
                        "parent_id": 625088,
                        "level": 2
                    },
                    {
                        "areaCode": 522324000000,
                        "areaId": 625628,
                        "areaName": "晴隆县",
                        "parent_id": 625088,
                        "level": 2
                    },
                    {
                        "areaCode": 522325000000,
                        "areaId": 625739,
                        "areaName": "贞丰县",
                        "parent_id": 625088,
                        "level": 2
                    },
                    {
                        "areaCode": 522326000000,
                        "areaId": 625913,
                        "areaName": "望谟县",
                        "parent_id": 625088,
                        "level": 2
                    },
                    {
                        "areaCode": 522327000000,
                        "areaId": 626095,
                        "areaName": "册亨县",
                        "parent_id": 625088,
                        "level": 2
                    },
                    {
                        "areaCode": 522328000000,
                        "areaId": 626240,
                        "areaName": "安龙县",
                        "parent_id": 625088,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 522600000000,
                "areaId": 626442,
                "areaName": "黔东南苗族侗族自治州",
                "parent_id": 611471,
                "level": 1,
                "children": [
                    {
                        "areaCode": 522601000000,
                        "areaId": 626443,
                        "areaName": "凯里市",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522622000000,
                        "areaId": 626733,
                        "areaName": "黄平县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522623000000,
                        "areaId": 626999,
                        "areaName": "施秉县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522624000000,
                        "areaId": 627082,
                        "areaName": "三穗县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522625000000,
                        "areaId": 627256,
                        "areaName": "镇远县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522626000000,
                        "areaId": 627395,
                        "areaName": "岑巩县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522627000000,
                        "areaId": 627547,
                        "areaName": "天柱县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522628000000,
                        "areaId": 627890,
                        "areaName": "锦屏县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522629000000,
                        "areaId": 628098,
                        "areaName": "剑河县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522630000000,
                        "areaId": 628421,
                        "areaName": "台江县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522631000000,
                        "areaId": 628591,
                        "areaName": "黎平县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522632000000,
                        "areaId": 629040,
                        "areaName": "榕江县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522633000000,
                        "areaId": 629330,
                        "areaName": "从江县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522634000000,
                        "areaId": 629736,
                        "areaName": "雷山县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522635000000,
                        "areaId": 629908,
                        "areaName": "麻江县",
                        "parent_id": 626442,
                        "level": 2
                    },
                    {
                        "areaCode": 522636000000,
                        "areaId": 629985,
                        "areaName": "丹寨县",
                        "parent_id": 626442,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 522700000000,
                "areaId": 630158,
                "areaName": "黔南布依族苗族自治州",
                "parent_id": 611471,
                "level": 1,
                "children": [
                    {
                        "areaCode": 522701000000,
                        "areaId": 630159,
                        "areaName": "都匀市",
                        "parent_id": 630158,
                        "level": 2
                    },
                    {
                        "areaCode": 522702000000,
                        "areaId": 630312,
                        "areaName": "福泉市",
                        "parent_id": 630158,
                        "level": 2
                    },
                    {
                        "areaCode": 522722000000,
                        "areaId": 630397,
                        "areaName": "荔波县",
                        "parent_id": 630158,
                        "level": 2
                    },
                    {
                        "areaCode": 522723000000,
                        "areaId": 630506,
                        "areaName": "贵定县",
                        "parent_id": 630158,
                        "level": 2
                    },
                    {
                        "areaCode": 522725000000,
                        "areaId": 630626,
                        "areaName": "瓮安县",
                        "parent_id": 630158,
                        "level": 2
                    },
                    {
                        "areaCode": 522726000000,
                        "areaId": 630738,
                        "areaName": "独山县",
                        "parent_id": 630158,
                        "level": 2
                    },
                    {
                        "areaCode": 522727000000,
                        "areaId": 630811,
                        "areaName": "平塘县",
                        "parent_id": 630158,
                        "level": 2
                    },
                    {
                        "areaCode": 522728000000,
                        "areaId": 630950,
                        "areaName": "罗甸县",
                        "parent_id": 630158,
                        "level": 2
                    },
                    {
                        "areaCode": 522729000000,
                        "areaId": 631143,
                        "areaName": "长顺县",
                        "parent_id": 630158,
                        "level": 2
                    },
                    {
                        "areaCode": 522730000000,
                        "areaId": 631233,
                        "areaName": "龙里县",
                        "parent_id": 630158,
                        "level": 2
                    },
                    {
                        "areaCode": 522731000000,
                        "areaId": 631318,
                        "areaName": "惠水县",
                        "parent_id": 630158,
                        "level": 2
                    },
                    {
                        "areaCode": 522732000000,
                        "areaId": 631543,
                        "areaName": "三都水族自治县",
                        "parent_id": 630158,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 530000000000,
        "areaId": 631793,
        "areaName": "云南省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 530100000000,
                "areaId": 631794,
                "areaName": "昆明市",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 530101000000,
                        "areaId": 631795,
                        "areaName": "市辖区",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530102000000,
                        "areaId": 631796,
                        "areaName": "五华区",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530103000000,
                        "areaId": 631907,
                        "areaName": "盘龙区",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530111000000,
                        "areaId": 632023,
                        "areaName": "官渡区",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530112000000,
                        "areaId": 632154,
                        "areaName": "西山区",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530113000000,
                        "areaId": 632284,
                        "areaName": "东川区",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530114000000,
                        "areaId": 632458,
                        "areaName": "呈贡区",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530115000000,
                        "areaId": 632538,
                        "areaName": "晋宁区",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530124000000,
                        "areaId": 632684,
                        "areaName": "富民县",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530125000000,
                        "areaId": 632767,
                        "areaName": "宜良县",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530126000000,
                        "areaId": 632916,
                        "areaName": "石林彝族自治县",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530127000000,
                        "areaId": 633017,
                        "areaName": "嵩明县",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530128000000,
                        "areaId": 633101,
                        "areaName": "禄劝彝族苗族自治县",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530129000000,
                        "areaId": 633319,
                        "areaName": "寻甸回族彝族自治县",
                        "parent_id": 631794,
                        "level": 2
                    },
                    {
                        "areaCode": 530181000000,
                        "areaId": 633512,
                        "areaName": "安宁市",
                        "parent_id": 631794,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 530300000000,
                "areaId": 633619,
                "areaName": "曲靖市",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 530301000000,
                        "areaId": 633620,
                        "areaName": "市辖区",
                        "parent_id": 633619,
                        "level": 2
                    },
                    {
                        "areaCode": 530302000000,
                        "areaId": 633621,
                        "areaName": "麒麟区",
                        "parent_id": 633619,
                        "level": 2
                    },
                    {
                        "areaCode": 530303000000,
                        "areaId": 633781,
                        "areaName": "沾益区",
                        "parent_id": 633619,
                        "level": 2
                    },
                    {
                        "areaCode": 530321000000,
                        "areaId": 633923,
                        "areaName": "马龙县",
                        "parent_id": 633619,
                        "level": 2
                    },
                    {
                        "areaCode": 530322000000,
                        "areaId": 634009,
                        "areaName": "陆良县",
                        "parent_id": 633619,
                        "level": 2
                    },
                    {
                        "areaCode": 530323000000,
                        "areaId": 634171,
                        "areaName": "师宗县",
                        "parent_id": 633619,
                        "level": 2
                    },
                    {
                        "areaCode": 530324000000,
                        "areaId": 634292,
                        "areaName": "罗平县",
                        "parent_id": 633619,
                        "level": 2
                    },
                    {
                        "areaCode": 530325000000,
                        "areaId": 634460,
                        "areaName": "富源县",
                        "parent_id": 633619,
                        "level": 2
                    },
                    {
                        "areaCode": 530326000000,
                        "areaId": 634634,
                        "areaName": "会泽县",
                        "parent_id": 633619,
                        "level": 2
                    },
                    {
                        "areaCode": 530381000000,
                        "areaId": 635036,
                        "areaName": "宣威市",
                        "parent_id": 633619,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 530400000000,
                "areaId": 635421,
                "areaName": "玉溪市",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 530401000000,
                        "areaId": 635422,
                        "areaName": "市辖区",
                        "parent_id": 635421,
                        "level": 2
                    },
                    {
                        "areaCode": 530402000000,
                        "areaId": 635423,
                        "areaName": "红塔区",
                        "parent_id": 635421,
                        "level": 2
                    },
                    {
                        "areaCode": 530403000000,
                        "areaId": 635539,
                        "areaName": "江川区",
                        "parent_id": 635421,
                        "level": 2
                    },
                    {
                        "areaCode": 530422000000,
                        "areaId": 635621,
                        "areaName": "澄江县",
                        "parent_id": 635421,
                        "level": 2
                    },
                    {
                        "areaCode": 530423000000,
                        "areaId": 635668,
                        "areaName": "通海县",
                        "parent_id": 635421,
                        "level": 2
                    },
                    {
                        "areaCode": 530424000000,
                        "areaId": 635754,
                        "areaName": "华宁县",
                        "parent_id": 635421,
                        "level": 2
                    },
                    {
                        "areaCode": 530425000000,
                        "areaId": 635837,
                        "areaName": "易门县",
                        "parent_id": 635421,
                        "level": 2
                    },
                    {
                        "areaCode": 530426000000,
                        "areaId": 635903,
                        "areaName": "峨山彝族自治县",
                        "parent_id": 635421,
                        "level": 2
                    },
                    {
                        "areaCode": 530427000000,
                        "areaId": 635988,
                        "areaName": "新平彝族傣族自治县",
                        "parent_id": 635421,
                        "level": 2
                    },
                    {
                        "areaCode": 530428000000,
                        "areaId": 636124,
                        "areaName": "元江哈尼族彝族傣族自治县",
                        "parent_id": 635421,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 530500000000,
                "areaId": 636216,
                "areaName": "保山市",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 530501000000,
                        "areaId": 636217,
                        "areaName": "市辖区",
                        "parent_id": 636216,
                        "level": 2
                    },
                    {
                        "areaCode": 530502000000,
                        "areaId": 636218,
                        "areaName": "隆阳区",
                        "parent_id": 636216,
                        "level": 2
                    },
                    {
                        "areaCode": 530521000000,
                        "areaId": 636558,
                        "areaName": "施甸县",
                        "parent_id": 636216,
                        "level": 2
                    },
                    {
                        "areaCode": 530523000000,
                        "areaId": 636712,
                        "areaName": "龙陵县",
                        "parent_id": 636216,
                        "level": 2
                    },
                    {
                        "areaCode": 530524000000,
                        "areaId": 636844,
                        "areaName": "昌宁县",
                        "parent_id": 636216,
                        "level": 2
                    },
                    {
                        "areaCode": 530581000000,
                        "areaId": 636982,
                        "areaName": "腾冲市",
                        "parent_id": 636216,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 530600000000,
                "areaId": 637222,
                "areaName": "昭通市",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 530601000000,
                        "areaId": 637223,
                        "areaName": "市辖区",
                        "parent_id": 637222,
                        "level": 2
                    },
                    {
                        "areaCode": 530602000000,
                        "areaId": 637224,
                        "areaName": "昭阳区",
                        "parent_id": 637222,
                        "level": 2
                    },
                    {
                        "areaCode": 530621000000,
                        "areaId": 637429,
                        "areaName": "鲁甸县",
                        "parent_id": 637222,
                        "level": 2
                    },
                    {
                        "areaCode": 530622000000,
                        "areaId": 637539,
                        "areaName": "巧家县",
                        "parent_id": 637222,
                        "level": 2
                    },
                    {
                        "areaCode": 530623000000,
                        "areaId": 637740,
                        "areaName": "盐津县",
                        "parent_id": 637222,
                        "level": 2
                    },
                    {
                        "areaCode": 530624000000,
                        "areaId": 637845,
                        "areaName": "大关县",
                        "parent_id": 637222,
                        "level": 2
                    },
                    {
                        "areaCode": 530625000000,
                        "areaId": 637939,
                        "areaName": "永善县",
                        "parent_id": 637222,
                        "level": 2
                    },
                    {
                        "areaCode": 530626000000,
                        "areaId": 638097,
                        "areaName": "绥江县",
                        "parent_id": 637222,
                        "level": 2
                    },
                    {
                        "areaCode": 530627000000,
                        "areaId": 638145,
                        "areaName": "镇雄县",
                        "parent_id": 637222,
                        "level": 2
                    },
                    {
                        "areaCode": 530628000000,
                        "areaId": 638430,
                        "areaName": "彝良县",
                        "parent_id": 637222,
                        "level": 2
                    },
                    {
                        "areaCode": 530629000000,
                        "areaId": 638585,
                        "areaName": "威信县",
                        "parent_id": 637222,
                        "level": 2
                    },
                    {
                        "areaCode": 530630000000,
                        "areaId": 638683,
                        "areaName": "水富县",
                        "parent_id": 637222,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 530700000000,
                "areaId": 638717,
                "areaName": "丽江市",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 530701000000,
                        "areaId": 638718,
                        "areaName": "市辖区",
                        "parent_id": 638717,
                        "level": 2
                    },
                    {
                        "areaCode": 530702000000,
                        "areaId": 638719,
                        "areaName": "古城区",
                        "parent_id": 638717,
                        "level": 2
                    },
                    {
                        "areaCode": 530721000000,
                        "areaId": 638790,
                        "areaName": "玉龙纳西族自治县",
                        "parent_id": 638717,
                        "level": 2
                    },
                    {
                        "areaCode": 530722000000,
                        "areaId": 638911,
                        "areaName": "永胜县",
                        "parent_id": 638717,
                        "level": 2
                    },
                    {
                        "areaCode": 530723000000,
                        "areaId": 639077,
                        "areaName": "华坪县",
                        "parent_id": 638717,
                        "level": 2
                    },
                    {
                        "areaCode": 530724000000,
                        "areaId": 639147,
                        "areaName": "宁蒗彝族自治县",
                        "parent_id": 638717,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 530800000000,
                "areaId": 639254,
                "areaName": "普洱市",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 530801000000,
                        "areaId": 639255,
                        "areaName": "市辖区",
                        "parent_id": 639254,
                        "level": 2
                    },
                    {
                        "areaCode": 530802000000,
                        "areaId": 639256,
                        "areaName": "思茅区",
                        "parent_id": 639254,
                        "level": 2
                    },
                    {
                        "areaCode": 530821000000,
                        "areaId": 639337,
                        "areaName": "宁洱哈尼族彝族自治县",
                        "parent_id": 639254,
                        "level": 2
                    },
                    {
                        "areaCode": 530822000000,
                        "areaId": 639436,
                        "areaName": "墨江哈尼族自治县",
                        "parent_id": 639254,
                        "level": 2
                    },
                    {
                        "areaCode": 530823000000,
                        "areaId": 639620,
                        "areaName": "景东彝族自治县",
                        "parent_id": 639254,
                        "level": 2
                    },
                    {
                        "areaCode": 530824000000,
                        "areaId": 639804,
                        "areaName": "景谷傣族彝族自治县",
                        "parent_id": 639254,
                        "level": 2
                    },
                    {
                        "areaCode": 530825000000,
                        "areaId": 639956,
                        "areaName": "镇沅彝族哈尼族拉祜族自治县",
                        "parent_id": 639254,
                        "level": 2
                    },
                    {
                        "areaCode": 530826000000,
                        "areaId": 640077,
                        "areaName": "江城哈尼族彝族自治县",
                        "parent_id": 639254,
                        "level": 2
                    },
                    {
                        "areaCode": 530827000000,
                        "areaId": 640136,
                        "areaName": "孟连傣族拉祜族佤族自治县",
                        "parent_id": 639254,
                        "level": 2
                    },
                    {
                        "areaCode": 530828000000,
                        "areaId": 640185,
                        "areaName": "澜沧拉祜族自治县",
                        "parent_id": 639254,
                        "level": 2
                    },
                    {
                        "areaCode": 530829000000,
                        "areaId": 640367,
                        "areaName": "西盟佤族自治县",
                        "parent_id": 639254,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 530900000000,
                "areaId": 640414,
                "areaName": "临沧市",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 530901000000,
                        "areaId": 640415,
                        "areaName": "市辖区",
                        "parent_id": 640414,
                        "level": 2
                    },
                    {
                        "areaCode": 530902000000,
                        "areaId": 640416,
                        "areaName": "临翔区",
                        "parent_id": 640414,
                        "level": 2
                    },
                    {
                        "areaCode": 530921000000,
                        "areaId": 640529,
                        "areaName": "凤庆县",
                        "parent_id": 640414,
                        "level": 2
                    },
                    {
                        "areaCode": 530922000000,
                        "areaId": 640730,
                        "areaName": "云县",
                        "parent_id": 640414,
                        "level": 2
                    },
                    {
                        "areaCode": 530923000000,
                        "areaId": 640941,
                        "areaName": "永德县",
                        "parent_id": 640414,
                        "level": 2
                    },
                    {
                        "areaCode": 530924000000,
                        "areaId": 641072,
                        "areaName": "镇康县",
                        "parent_id": 640414,
                        "level": 2
                    },
                    {
                        "areaCode": 530925000000,
                        "areaId": 641154,
                        "areaName": "双江拉祜族佤族布朗族傣族自治县",
                        "parent_id": 640414,
                        "level": 2
                    },
                    {
                        "areaCode": 530926000000,
                        "areaId": 641236,
                        "areaName": "耿马傣族佤族自治县",
                        "parent_id": 640414,
                        "level": 2
                    },
                    {
                        "areaCode": 530927000000,
                        "areaId": 641341,
                        "areaName": "沧源佤族自治县",
                        "parent_id": 640414,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 532300000000,
                "areaId": 641449,
                "areaName": "楚雄彝族自治州",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 532301000000,
                        "areaId": 641450,
                        "areaName": "楚雄市",
                        "parent_id": 641449,
                        "level": 2
                    },
                    {
                        "areaCode": 532322000000,
                        "areaId": 641620,
                        "areaName": "双柏县",
                        "parent_id": 641449,
                        "level": 2
                    },
                    {
                        "areaCode": 532323000000,
                        "areaId": 641713,
                        "areaName": "牟定县",
                        "parent_id": 641449,
                        "level": 2
                    },
                    {
                        "areaCode": 532324000000,
                        "areaId": 641810,
                        "areaName": "南华县",
                        "parent_id": 641449,
                        "level": 2
                    },
                    {
                        "areaCode": 532325000000,
                        "areaId": 641949,
                        "areaName": "姚安县",
                        "parent_id": 641449,
                        "level": 2
                    },
                    {
                        "areaCode": 532326000000,
                        "areaId": 642036,
                        "areaName": "大姚县",
                        "parent_id": 641449,
                        "level": 2
                    },
                    {
                        "areaCode": 532327000000,
                        "areaId": 642178,
                        "areaName": "永仁县",
                        "parent_id": 641449,
                        "level": 2
                    },
                    {
                        "areaCode": 532328000000,
                        "areaId": 642249,
                        "areaName": "元谋县",
                        "parent_id": 641449,
                        "level": 2
                    },
                    {
                        "areaCode": 532329000000,
                        "areaId": 642338,
                        "areaName": "武定县",
                        "parent_id": 641449,
                        "level": 2
                    },
                    {
                        "areaCode": 532331000000,
                        "areaId": 642483,
                        "areaName": "禄丰县",
                        "parent_id": 641449,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 532500000000,
                "areaId": 642663,
                "areaName": "红河哈尼族彝族自治州",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 532501000000,
                        "areaId": 642664,
                        "areaName": "个旧市",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532502000000,
                        "areaId": 642791,
                        "areaName": "开远市",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532503000000,
                        "areaId": 642877,
                        "areaName": "蒙自市",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532504000000,
                        "areaId": 642990,
                        "areaName": "弥勒市",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532523000000,
                        "areaId": 643140,
                        "areaName": "屏边苗族自治县",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532524000000,
                        "areaId": 643228,
                        "areaName": "建水县",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532525000000,
                        "areaId": 643397,
                        "areaName": "石屏县",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532527000000,
                        "areaId": 643522,
                        "areaName": "泸西县",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532528000000,
                        "areaId": 643618,
                        "areaName": "元阳县",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532529000000,
                        "areaId": 643771,
                        "areaName": "红河县",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532530000000,
                        "areaId": 643876,
                        "areaName": "金平苗族瑶族傣族自治县",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532531000000,
                        "areaId": 643988,
                        "areaName": "绿春县",
                        "parent_id": 642663,
                        "level": 2
                    },
                    {
                        "areaCode": 532532000000,
                        "areaId": 644090,
                        "areaName": "河口瑶族自治县",
                        "parent_id": 642663,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 532600000000,
                "areaId": 644131,
                "areaName": "文山壮族苗族自治州",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 532601000000,
                        "areaId": 644132,
                        "areaName": "文山市",
                        "parent_id": 644131,
                        "level": 2
                    },
                    {
                        "areaCode": 532622000000,
                        "areaId": 644293,
                        "areaName": "砚山县",
                        "parent_id": 644131,
                        "level": 2
                    },
                    {
                        "areaCode": 532623000000,
                        "areaId": 644413,
                        "areaName": "西畴县",
                        "parent_id": 644131,
                        "level": 2
                    },
                    {
                        "areaCode": 532624000000,
                        "areaId": 644495,
                        "areaName": "麻栗坡县",
                        "parent_id": 644131,
                        "level": 2
                    },
                    {
                        "areaCode": 532625000000,
                        "areaId": 644609,
                        "areaName": "马关县",
                        "parent_id": 644131,
                        "level": 2
                    },
                    {
                        "areaCode": 532626000000,
                        "areaId": 644755,
                        "areaName": "丘北县",
                        "parent_id": 644131,
                        "level": 2
                    },
                    {
                        "areaCode": 532627000000,
                        "areaId": 644869,
                        "areaName": "广南县",
                        "parent_id": 644131,
                        "level": 2
                    },
                    {
                        "areaCode": 532628000000,
                        "areaId": 645062,
                        "areaName": "富宁县",
                        "parent_id": 644131,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 532800000000,
                "areaId": 645221,
                "areaName": "西双版纳傣族自治州",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 532801000000,
                        "areaId": 645222,
                        "areaName": "景洪市",
                        "parent_id": 645221,
                        "level": 2
                    },
                    {
                        "areaCode": 532822000000,
                        "areaId": 645353,
                        "areaName": "勐海县",
                        "parent_id": 645221,
                        "level": 2
                    },
                    {
                        "areaCode": 532823000000,
                        "areaId": 645458,
                        "areaName": "勐腊县",
                        "parent_id": 645221,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 532900000000,
                "areaId": 645536,
                "areaName": "大理白族自治州",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 532901000000,
                        "areaId": 645537,
                        "areaName": "大理市",
                        "parent_id": 645536,
                        "level": 2
                    },
                    {
                        "areaCode": 532922000000,
                        "areaId": 645693,
                        "areaName": "漾濞彝族自治县",
                        "parent_id": 645536,
                        "level": 2
                    },
                    {
                        "areaCode": 532923000000,
                        "areaId": 645769,
                        "areaName": "祥云县",
                        "parent_id": 645536,
                        "level": 2
                    },
                    {
                        "areaCode": 532924000000,
                        "areaId": 645919,
                        "areaName": "宾川县",
                        "parent_id": 645536,
                        "level": 2
                    },
                    {
                        "areaCode": 532925000000,
                        "areaId": 646020,
                        "areaName": "弥渡县",
                        "parent_id": 645536,
                        "level": 2
                    },
                    {
                        "areaCode": 532926000000,
                        "areaId": 646118,
                        "areaName": "南涧彝族自治县",
                        "parent_id": 645536,
                        "level": 2
                    },
                    {
                        "areaCode": 532927000000,
                        "areaId": 646208,
                        "areaName": "巍山彝族回族自治县",
                        "parent_id": 645536,
                        "level": 2
                    },
                    {
                        "areaCode": 532928000000,
                        "areaId": 646302,
                        "areaName": "永平县",
                        "parent_id": 645536,
                        "level": 2
                    },
                    {
                        "areaCode": 532929000000,
                        "areaId": 646385,
                        "areaName": "云龙县",
                        "parent_id": 645536,
                        "level": 2
                    },
                    {
                        "areaCode": 532930000000,
                        "areaId": 646483,
                        "areaName": "洱源县",
                        "parent_id": 645536,
                        "level": 2
                    },
                    {
                        "areaCode": 532931000000,
                        "areaId": 646583,
                        "areaName": "剑川县",
                        "parent_id": 645536,
                        "level": 2
                    },
                    {
                        "areaCode": 532932000000,
                        "areaId": 646685,
                        "areaName": "鹤庆县",
                        "parent_id": 645536,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 533100000000,
                "areaId": 646812,
                "areaName": "德宏傣族景颇族自治州",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 533102000000,
                        "areaId": 646813,
                        "areaName": "瑞丽市",
                        "parent_id": 646812,
                        "level": 2
                    },
                    {
                        "areaCode": 533103000000,
                        "areaId": 646860,
                        "areaName": "芒市",
                        "parent_id": 646812,
                        "level": 2
                    },
                    {
                        "areaCode": 533122000000,
                        "areaId": 646971,
                        "areaName": "梁河县",
                        "parent_id": 646812,
                        "level": 2
                    },
                    {
                        "areaCode": 533123000000,
                        "areaId": 647047,
                        "areaName": "盈江县",
                        "parent_id": 646812,
                        "level": 2
                    },
                    {
                        "areaCode": 533124000000,
                        "areaId": 647166,
                        "areaName": "陇川县",
                        "parent_id": 646812,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 533300000000,
                "areaId": 647249,
                "areaName": "怒江傈僳族自治州",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 533301000000,
                        "areaId": 647250,
                        "areaName": "泸水市",
                        "parent_id": 647249,
                        "level": 2
                    },
                    {
                        "areaCode": 533323000000,
                        "areaId": 647337,
                        "areaName": "福贡县",
                        "parent_id": 647249,
                        "level": 2
                    },
                    {
                        "areaCode": 533324000000,
                        "areaId": 647404,
                        "areaName": "贡山独龙族怒族自治县",
                        "parent_id": 647249,
                        "level": 2
                    },
                    {
                        "areaCode": 533325000000,
                        "areaId": 647438,
                        "areaName": "兰坪白族普米族自治县",
                        "parent_id": 647249,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 533400000000,
                "areaId": 647555,
                "areaName": "迪庆藏族自治州",
                "parent_id": 631793,
                "level": 1,
                "children": [
                    {
                        "areaCode": 533401000000,
                        "areaId": 647556,
                        "areaName": "香格里拉市",
                        "parent_id": 647555,
                        "level": 2
                    },
                    {
                        "areaCode": 533422000000,
                        "areaId": 647632,
                        "areaName": "德钦县",
                        "parent_id": 647555,
                        "level": 2
                    },
                    {
                        "areaCode": 533423000000,
                        "areaId": 647687,
                        "areaName": "维西傈僳族自治县",
                        "parent_id": 647555,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 540000000000,
        "areaId": 647780,
        "areaName": "西藏自治区",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 540100000000,
                "areaId": 647781,
                "areaName": "拉萨市",
                "parent_id": 647780,
                "level": 1,
                "children": [
                    {
                        "areaCode": 540101000000,
                        "areaId": 647782,
                        "areaName": "市辖区",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540102000000,
                        "areaId": 647783,
                        "areaName": "城关区",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540103000000,
                        "areaId": 647847,
                        "areaName": "堆龙德庆区",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540121000000,
                        "areaId": 647889,
                        "areaName": "林周县",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540122000000,
                        "areaId": 647945,
                        "areaName": "当雄县",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540123000000,
                        "areaId": 647982,
                        "areaName": "尼木县",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540124000000,
                        "areaId": 648023,
                        "areaName": "曲水县",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540126000000,
                        "areaId": 648049,
                        "areaName": "达孜县",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540127000000,
                        "areaId": 648076,
                        "areaName": "墨竹工卡县",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540171000000,
                        "areaId": 648125,
                        "areaName": "格尔木藏青工业园区",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540172000000,
                        "areaId": 648128,
                        "areaName": "拉萨经济技术开发区",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540173000000,
                        "areaId": 648131,
                        "areaName": "西藏文化旅游创意园区",
                        "parent_id": 647781,
                        "level": 2
                    },
                    {
                        "areaCode": 540174000000,
                        "areaId": 648134,
                        "areaName": "达孜工业园区",
                        "parent_id": 647781,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 540200000000,
                "areaId": 648137,
                "areaName": "日喀则市",
                "parent_id": 647780,
                "level": 1,
                "children": [
                    {
                        "areaCode": 540202000000,
                        "areaId": 648138,
                        "areaName": "桑珠孜区",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540221000000,
                        "areaId": 648327,
                        "areaName": "南木林县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540222000000,
                        "areaId": 648491,
                        "areaName": "江孜县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540223000000,
                        "areaId": 648666,
                        "areaName": "定日县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540224000000,
                        "areaId": 648855,
                        "areaName": "萨迦县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540225000000,
                        "areaId": 648974,
                        "areaName": "拉孜县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540226000000,
                        "areaId": 649084,
                        "areaName": "昂仁县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540227000000,
                        "areaId": 649287,
                        "areaName": "谢通门县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540228000000,
                        "areaId": 649402,
                        "areaName": "白朗县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540229000000,
                        "areaId": 649525,
                        "areaName": "仁布县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540230000000,
                        "areaId": 649608,
                        "areaName": "康马县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540231000000,
                        "areaId": 649665,
                        "areaName": "定结县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540232000000,
                        "areaId": 649746,
                        "areaName": "仲巴县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540233000000,
                        "areaId": 649818,
                        "areaName": "亚东县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540234000000,
                        "areaId": 649851,
                        "areaName": "吉隆县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540235000000,
                        "areaId": 649899,
                        "areaName": "聂拉木县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540236000000,
                        "areaId": 649951,
                        "areaName": "萨嘎县",
                        "parent_id": 648137,
                        "level": 2
                    },
                    {
                        "areaCode": 540237000000,
                        "areaId": 649998,
                        "areaName": "岗巴县",
                        "parent_id": 648137,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 540300000000,
                "areaId": 650033,
                "areaName": "昌都市",
                "parent_id": 647780,
                "level": 1,
                "children": [
                    {
                        "areaCode": 540302000000,
                        "areaId": 650034,
                        "areaName": "卡若区",
                        "parent_id": 650033,
                        "level": 2
                    },
                    {
                        "areaCode": 540321000000,
                        "areaId": 650217,
                        "areaName": "江达县",
                        "parent_id": 650033,
                        "level": 2
                    },
                    {
                        "areaCode": 540322000000,
                        "areaId": 650326,
                        "areaName": "贡觉县",
                        "parent_id": 650033,
                        "level": 2
                    },
                    {
                        "areaCode": 540323000000,
                        "areaId": 650488,
                        "areaName": "类乌齐县",
                        "parent_id": 650033,
                        "level": 2
                    },
                    {
                        "areaCode": 540324000000,
                        "areaId": 650581,
                        "areaName": "丁青县",
                        "parent_id": 650033,
                        "level": 2
                    },
                    {
                        "areaCode": 540325000000,
                        "areaId": 650659,
                        "areaName": "察雅县",
                        "parent_id": 650033,
                        "level": 2
                    },
                    {
                        "areaCode": 540326000000,
                        "areaId": 650811,
                        "areaName": "八宿县",
                        "parent_id": 650033,
                        "level": 2
                    },
                    {
                        "areaCode": 540327000000,
                        "areaId": 650936,
                        "areaName": "左贡县",
                        "parent_id": 650033,
                        "level": 2
                    },
                    {
                        "areaCode": 540328000000,
                        "areaId": 651075,
                        "areaName": "芒康县",
                        "parent_id": 650033,
                        "level": 2
                    },
                    {
                        "areaCode": 540329000000,
                        "areaId": 651153,
                        "areaName": "洛隆县",
                        "parent_id": 650033,
                        "level": 2
                    },
                    {
                        "areaCode": 540330000000,
                        "areaId": 651231,
                        "areaName": "边坝县",
                        "parent_id": 650033,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 540400000000,
                "areaId": 651325,
                "areaName": "林芝市",
                "parent_id": 647780,
                "level": 1,
                "children": [
                    {
                        "areaCode": 540402000000,
                        "areaId": 651326,
                        "areaName": "巴宜区",
                        "parent_id": 651325,
                        "level": 2
                    },
                    {
                        "areaCode": 540421000000,
                        "areaId": 651407,
                        "areaName": "工布江达县",
                        "parent_id": 651325,
                        "level": 2
                    },
                    {
                        "areaCode": 540422000000,
                        "areaId": 651497,
                        "areaName": "米林县",
                        "parent_id": 651325,
                        "level": 2
                    },
                    {
                        "areaCode": 540423000000,
                        "areaId": 651573,
                        "areaName": "墨脱县",
                        "parent_id": 651325,
                        "level": 2
                    },
                    {
                        "areaCode": 540424000000,
                        "areaId": 651628,
                        "areaName": "波密县",
                        "parent_id": 651325,
                        "level": 2
                    },
                    {
                        "areaCode": 540425000000,
                        "areaId": 651724,
                        "areaName": "察隅县",
                        "parent_id": 651325,
                        "level": 2
                    },
                    {
                        "areaCode": 540426000000,
                        "areaId": 651828,
                        "areaName": "朗县",
                        "parent_id": 651325,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 540500000000,
                "areaId": 651887,
                "areaName": "山南市",
                "parent_id": 647780,
                "level": 1,
                "children": [
                    {
                        "areaCode": 540501000000,
                        "areaId": 651888,
                        "areaName": "市辖区",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540502000000,
                        "areaId": 651889,
                        "areaName": "乃东区",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540521000000,
                        "areaId": 651944,
                        "areaName": "扎囊县",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540522000000,
                        "areaId": 652012,
                        "areaName": "贡嘎县",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540523000000,
                        "areaId": 652064,
                        "areaName": "桑日县",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540524000000,
                        "areaId": 652111,
                        "areaName": "琼结县",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540525000000,
                        "areaId": 652136,
                        "areaName": "曲松县",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540526000000,
                        "areaId": 652163,
                        "areaName": "措美县",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540527000000,
                        "areaId": 652184,
                        "areaName": "洛扎县",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540528000000,
                        "areaId": 652219,
                        "areaName": "加查县",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540529000000,
                        "areaId": 652304,
                        "areaName": "隆子县",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540530000000,
                        "areaId": 652396,
                        "areaName": "错那县",
                        "parent_id": 651887,
                        "level": 2
                    },
                    {
                        "areaCode": 540531000000,
                        "areaId": 652431,
                        "areaName": "浪卡子县",
                        "parent_id": 651887,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 542400000000,
                "areaId": 652540,
                "areaName": "那曲地区",
                "parent_id": 647780,
                "level": 1,
                "children": [
                    {
                        "areaCode": 542421000000,
                        "areaId": 652541,
                        "areaName": "那曲县",
                        "parent_id": 652540,
                        "level": 2
                    },
                    {
                        "areaCode": 542422000000,
                        "areaId": 652695,
                        "areaName": "嘉黎县",
                        "parent_id": 652540,
                        "level": 2
                    },
                    {
                        "areaCode": 542423000000,
                        "areaId": 652828,
                        "areaName": "比如县",
                        "parent_id": 652540,
                        "level": 2
                    },
                    {
                        "areaCode": 542424000000,
                        "areaId": 653014,
                        "areaName": "聂荣县",
                        "parent_id": 652540,
                        "level": 2
                    },
                    {
                        "areaCode": 542425000000,
                        "areaId": 653167,
                        "areaName": "安多县",
                        "parent_id": 652540,
                        "level": 2
                    },
                    {
                        "areaCode": 542426000000,
                        "areaId": 653255,
                        "areaName": "申扎县",
                        "parent_id": 652540,
                        "level": 2
                    },
                    {
                        "areaCode": 542427000000,
                        "areaId": 653326,
                        "areaName": "索县",
                        "parent_id": 652540,
                        "level": 2
                    },
                    {
                        "areaCode": 542428000000,
                        "areaId": 653461,
                        "areaName": "班戈县",
                        "parent_id": 652540,
                        "level": 2
                    },
                    {
                        "areaCode": 542429000000,
                        "areaId": 653558,
                        "areaName": "巴青县",
                        "parent_id": 652540,
                        "level": 2
                    },
                    {
                        "areaCode": 542430000000,
                        "areaId": 653725,
                        "areaName": "尼玛县",
                        "parent_id": 652540,
                        "level": 2
                    },
                    {
                        "areaCode": 542431000000,
                        "areaId": 653817,
                        "areaName": "双湖县",
                        "parent_id": 652540,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 542500000000,
                "areaId": 653856,
                "areaName": "阿里地区",
                "parent_id": 647780,
                "level": 1,
                "children": [
                    {
                        "areaCode": 542521000000,
                        "areaId": 653857,
                        "areaName": "普兰县",
                        "parent_id": 653856,
                        "level": 2
                    },
                    {
                        "areaCode": 542522000000,
                        "areaId": 653871,
                        "areaName": "札达县",
                        "parent_id": 653856,
                        "level": 2
                    },
                    {
                        "areaCode": 542523000000,
                        "areaId": 653895,
                        "areaName": "噶尔县",
                        "parent_id": 653856,
                        "level": 2
                    },
                    {
                        "areaCode": 542524000000,
                        "areaId": 653915,
                        "areaName": "日土县",
                        "parent_id": 653856,
                        "level": 2
                    },
                    {
                        "areaCode": 542525000000,
                        "areaId": 653934,
                        "areaName": "革吉县",
                        "parent_id": 653856,
                        "level": 2
                    },
                    {
                        "areaCode": 542526000000,
                        "areaId": 653959,
                        "areaName": "改则县",
                        "parent_id": 653856,
                        "level": 2
                    },
                    {
                        "areaCode": 542527000000,
                        "areaId": 654015,
                        "areaName": "措勤县",
                        "parent_id": 653856,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 610000000000,
        "areaId": 654042,
        "areaName": "陕西省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 610100000000,
                "areaId": 654043,
                "areaName": "西安市",
                "parent_id": 654042,
                "level": 1,
                "children": [
                    {
                        "areaCode": 610101000000,
                        "areaId": 654044,
                        "areaName": "市辖区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610102000000,
                        "areaId": 654045,
                        "areaName": "新城区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610103000000,
                        "areaId": 654156,
                        "areaName": "碑林区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610104000000,
                        "areaId": 654263,
                        "areaName": "莲湖区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610111000000,
                        "areaId": 654404,
                        "areaName": "灞桥区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610112000000,
                        "areaId": 654674,
                        "areaName": "未央区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610113000000,
                        "areaId": 654949,
                        "areaName": "雁塔区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610114000000,
                        "areaId": 655176,
                        "areaName": "阎良区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610115000000,
                        "areaId": 655283,
                        "areaName": "临潼区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610116000000,
                        "areaId": 655575,
                        "areaName": "长安区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610117000000,
                        "areaId": 656107,
                        "areaName": "高陵区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610118000000,
                        "areaId": 656218,
                        "areaName": "鄠邑区",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610122000000,
                        "areaId": 656514,
                        "areaName": "蓝田县",
                        "parent_id": 654043,
                        "level": 2
                    },
                    {
                        "areaCode": 610124000000,
                        "areaId": 656881,
                        "areaName": "周至县",
                        "parent_id": 654043,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 610200000000,
                "areaId": 657182,
                "areaName": "铜川市",
                "parent_id": 654042,
                "level": 1,
                "children": [
                    {
                        "areaCode": 610201000000,
                        "areaId": 657183,
                        "areaName": "市辖区",
                        "parent_id": 657182,
                        "level": 2
                    },
                    {
                        "areaCode": 610202000000,
                        "areaId": 657184,
                        "areaName": "王益区",
                        "parent_id": 657182,
                        "level": 2
                    },
                    {
                        "areaCode": 610203000000,
                        "areaId": 657238,
                        "areaName": "印台区",
                        "parent_id": 657182,
                        "level": 2
                    },
                    {
                        "areaCode": 610204000000,
                        "areaId": 657344,
                        "areaName": "耀州区",
                        "parent_id": 657182,
                        "level": 2
                    },
                    {
                        "areaCode": 610222000000,
                        "areaId": 657526,
                        "areaName": "宜君县",
                        "parent_id": 657182,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 610300000000,
                "areaId": 657654,
                "areaName": "宝鸡市",
                "parent_id": 654042,
                "level": 1,
                "children": [
                    {
                        "areaCode": 610301000000,
                        "areaId": 657655,
                        "areaName": "市辖区",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610302000000,
                        "areaId": 657656,
                        "areaName": "渭滨区",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610303000000,
                        "areaId": 657832,
                        "areaName": "金台区",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610304000000,
                        "areaId": 657998,
                        "areaName": "陈仓区",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610322000000,
                        "areaId": 658363,
                        "areaName": "凤翔县",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610323000000,
                        "areaId": 658613,
                        "areaName": "岐山县",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610324000000,
                        "areaId": 658782,
                        "areaName": "扶风县",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610326000000,
                        "areaId": 658957,
                        "areaName": "眉县",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610327000000,
                        "areaId": 659100,
                        "areaName": "陇县",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610328000000,
                        "areaId": 659272,
                        "areaName": "千阳县",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610329000000,
                        "areaId": 659379,
                        "areaName": "麟游县",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610330000000,
                        "areaId": 659456,
                        "areaName": "凤县",
                        "parent_id": 657654,
                        "level": 2
                    },
                    {
                        "areaCode": 610331000000,
                        "areaId": 659540,
                        "areaName": "太白县",
                        "parent_id": 657654,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 610400000000,
                "areaId": 659616,
                "areaName": "咸阳市",
                "parent_id": 654042,
                "level": 1,
                "children": [
                    {
                        "areaCode": 610401000000,
                        "areaId": 659617,
                        "areaName": "市辖区",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610402000000,
                        "areaId": 659618,
                        "areaName": "秦都区",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610403000000,
                        "areaId": 659815,
                        "areaName": "杨陵区",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610404000000,
                        "areaId": 659899,
                        "areaName": "渭城区",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610422000000,
                        "areaId": 660078,
                        "areaName": "三原县",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610423000000,
                        "areaId": 660270,
                        "areaName": "泾阳县",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610424000000,
                        "areaId": 660507,
                        "areaName": "乾县",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610425000000,
                        "areaId": 660705,
                        "areaName": "礼泉县",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610426000000,
                        "areaId": 660949,
                        "areaName": "永寿县",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610427000000,
                        "areaId": 661121,
                        "areaName": "彬县",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610428000000,
                        "areaId": 661298,
                        "areaName": "长武县",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610429000000,
                        "areaId": 661440,
                        "areaName": "旬邑县",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610430000000,
                        "areaId": 661576,
                        "areaName": "淳化县",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610431000000,
                        "areaId": 661791,
                        "areaName": "武功县",
                        "parent_id": 659616,
                        "level": 2
                    },
                    {
                        "areaCode": 610481000000,
                        "areaId": 661996,
                        "areaName": "兴平市",
                        "parent_id": 659616,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 610500000000,
                "areaId": 662210,
                "areaName": "渭南市",
                "parent_id": 654042,
                "level": 1,
                "children": [
                    {
                        "areaCode": 610501000000,
                        "areaId": 662211,
                        "areaName": "市辖区",
                        "parent_id": 662210,
                        "level": 2
                    },
                    {
                        "areaCode": 610502000000,
                        "areaId": 662212,
                        "areaName": "临渭区",
                        "parent_id": 662210,
                        "level": 2
                    },
                    {
                        "areaCode": 610503000000,
                        "areaId": 662634,
                        "areaName": "华州区",
                        "parent_id": 662210,
                        "level": 2
                    },
                    {
                        "areaCode": 610522000000,
                        "areaId": 662795,
                        "areaName": "潼关县",
                        "parent_id": 662210,
                        "level": 2
                    },
                    {
                        "areaCode": 610523000000,
                        "areaId": 662829,
                        "areaName": "大荔县",
                        "parent_id": 662210,
                        "level": 2
                    },
                    {
                        "areaCode": 610524000000,
                        "areaId": 663170,
                        "areaName": "合阳县",
                        "parent_id": 662210,
                        "level": 2
                    },
                    {
                        "areaCode": 610525000000,
                        "areaId": 663404,
                        "areaName": "澄城县",
                        "parent_id": 662210,
                        "level": 2
                    },
                    {
                        "areaCode": 610526000000,
                        "areaId": 663590,
                        "areaName": "蒲城县",
                        "parent_id": 662210,
                        "level": 2
                    },
                    {
                        "areaCode": 610527000000,
                        "areaId": 663897,
                        "areaName": "白水县",
                        "parent_id": 662210,
                        "level": 2
                    },
                    {
                        "areaCode": 610528000000,
                        "areaId": 664041,
                        "areaName": "富平县",
                        "parent_id": 662210,
                        "level": 2
                    },
                    {
                        "areaCode": 610581000000,
                        "areaId": 664338,
                        "areaName": "韩城市",
                        "parent_id": 662210,
                        "level": 2
                    },
                    {
                        "areaCode": 610582000000,
                        "areaId": 664552,
                        "areaName": "华阴市",
                        "parent_id": 662210,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 610600000000,
                "areaId": 664694,
                "areaName": "延安市",
                "parent_id": 654042,
                "level": 1,
                "children": [
                    {
                        "areaCode": 610601000000,
                        "areaId": 664695,
                        "areaName": "市辖区",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610602000000,
                        "areaId": 664696,
                        "areaName": "宝塔区",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610603000000,
                        "areaId": 665082,
                        "areaName": "安塞区",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610621000000,
                        "areaId": 665228,
                        "areaName": "延长县",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610622000000,
                        "areaId": 665402,
                        "areaName": "延川县",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610623000000,
                        "areaId": 665588,
                        "areaName": "子长县",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610625000000,
                        "areaId": 665794,
                        "areaName": "志丹县",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610626000000,
                        "areaId": 665917,
                        "areaName": "吴起县",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610627000000,
                        "areaId": 666021,
                        "areaName": "甘泉县",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610628000000,
                        "areaId": 666099,
                        "areaName": "富县",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610629000000,
                        "areaId": 666244,
                        "areaName": "洛川县",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610630000000,
                        "areaId": 666627,
                        "areaName": "宜川县",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610631000000,
                        "areaId": 666752,
                        "areaName": "黄龙县",
                        "parent_id": 664694,
                        "level": 2
                    },
                    {
                        "areaCode": 610632000000,
                        "areaId": 666810,
                        "areaName": "黄陵县",
                        "parent_id": 664694,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 610700000000,
                "areaId": 667020,
                "areaName": "汉中市",
                "parent_id": 654042,
                "level": 1,
                "children": [
                    {
                        "areaCode": 610701000000,
                        "areaId": 667021,
                        "areaName": "市辖区",
                        "parent_id": 667020,
                        "level": 2
                    },
                    {
                        "areaCode": 610702000000,
                        "areaId": 667022,
                        "areaName": "汉台区",
                        "parent_id": 667020,
                        "level": 2
                    },
                    {
                        "areaCode": 610703000000,
                        "areaId": 667287,
                        "areaName": "南郑区",
                        "parent_id": 667020,
                        "level": 2
                    },
                    {
                        "areaCode": 610722000000,
                        "areaId": 667622,
                        "areaName": "城固县",
                        "parent_id": 667020,
                        "level": 2
                    },
                    {
                        "areaCode": 610723000000,
                        "areaId": 667913,
                        "areaName": "洋县",
                        "parent_id": 667020,
                        "level": 2
                    },
                    {
                        "areaCode": 610724000000,
                        "areaId": 668217,
                        "areaName": "西乡县",
                        "parent_id": 667020,
                        "level": 2
                    },
                    {
                        "areaCode": 610725000000,
                        "areaId": 668450,
                        "areaName": "勉县",
                        "parent_id": 667020,
                        "level": 2
                    },
                    {
                        "areaCode": 610726000000,
                        "areaId": 668667,
                        "areaName": "宁强县",
                        "parent_id": 667020,
                        "level": 2
                    },
                    {
                        "areaCode": 610727000000,
                        "areaId": 668899,
                        "areaName": "略阳县",
                        "parent_id": 667020,
                        "level": 2
                    },
                    {
                        "areaCode": 610728000000,
                        "areaId": 669082,
                        "areaName": "镇巴县",
                        "parent_id": 667020,
                        "level": 2
                    },
                    {
                        "areaCode": 610729000000,
                        "areaId": 669286,
                        "areaName": "留坝县",
                        "parent_id": 667020,
                        "level": 2
                    },
                    {
                        "areaCode": 610730000000,
                        "areaId": 669371,
                        "areaName": "佛坪县",
                        "parent_id": 667020,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 610800000000,
                "areaId": 669424,
                "areaName": "榆林市",
                "parent_id": 654042,
                "level": 1,
                "children": [
                    {
                        "areaCode": 610801000000,
                        "areaId": 669425,
                        "areaName": "市辖区",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610802000000,
                        "areaId": 669426,
                        "areaName": "榆阳区",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610803000000,
                        "areaId": 669854,
                        "areaName": "横山区",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610822000000,
                        "areaId": 670236,
                        "areaName": "府谷县",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610824000000,
                        "areaId": 670468,
                        "areaName": "靖边县",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610825000000,
                        "areaId": 670713,
                        "areaName": "定边县",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610826000000,
                        "areaId": 670966,
                        "areaName": "绥德县",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610827000000,
                        "areaId": 671329,
                        "areaName": "米脂县",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610828000000,
                        "areaId": 671550,
                        "areaName": "佳县",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610829000000,
                        "areaId": 671902,
                        "areaName": "吴堡县",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610830000000,
                        "areaId": 672016,
                        "areaName": "清涧县",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610831000000,
                        "areaId": 672381,
                        "areaName": "子洲县",
                        "parent_id": 669424,
                        "level": 2
                    },
                    {
                        "areaCode": 610881000000,
                        "areaId": 672956,
                        "areaName": "神木市",
                        "parent_id": 669424,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 610900000000,
                "areaId": 673336,
                "areaName": "安康市",
                "parent_id": 654042,
                "level": 1,
                "children": [
                    {
                        "areaCode": 610901000000,
                        "areaId": 673337,
                        "areaName": "市辖区",
                        "parent_id": 673336,
                        "level": 2
                    },
                    {
                        "areaCode": 610902000000,
                        "areaId": 673338,
                        "areaName": "汉滨区",
                        "parent_id": 673336,
                        "level": 2
                    },
                    {
                        "areaCode": 610921000000,
                        "areaId": 673891,
                        "areaName": "汉阴县",
                        "parent_id": 673336,
                        "level": 2
                    },
                    {
                        "areaCode": 610922000000,
                        "areaId": 674051,
                        "areaName": "石泉县",
                        "parent_id": 673336,
                        "level": 2
                    },
                    {
                        "areaCode": 610923000000,
                        "areaId": 674224,
                        "areaName": "宁陕县",
                        "parent_id": 673336,
                        "level": 2
                    },
                    {
                        "areaCode": 610924000000,
                        "areaId": 674316,
                        "areaName": "紫阳县",
                        "parent_id": 673336,
                        "level": 2
                    },
                    {
                        "areaCode": 610925000000,
                        "areaId": 674531,
                        "areaName": "岚皋县",
                        "parent_id": 673336,
                        "level": 2
                    },
                    {
                        "areaCode": 610926000000,
                        "areaId": 674680,
                        "areaName": "平利县",
                        "parent_id": 673336,
                        "level": 2
                    },
                    {
                        "areaCode": 610927000000,
                        "areaId": 674835,
                        "areaName": "镇坪县",
                        "parent_id": 673336,
                        "level": 2
                    },
                    {
                        "areaCode": 610928000000,
                        "areaId": 674905,
                        "areaName": "旬阳县",
                        "parent_id": 673336,
                        "level": 2
                    },
                    {
                        "areaCode": 610929000000,
                        "areaId": 675232,
                        "areaName": "白河县",
                        "parent_id": 673336,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 611000000000,
                "areaId": 675365,
                "areaName": "商洛市",
                "parent_id": 654042,
                "level": 1,
                "children": [
                    {
                        "areaCode": 611001000000,
                        "areaId": 675366,
                        "areaName": "市辖区",
                        "parent_id": 675365,
                        "level": 2
                    },
                    {
                        "areaCode": 611002000000,
                        "areaId": 675367,
                        "areaName": "商州区",
                        "parent_id": 675365,
                        "level": 2
                    },
                    {
                        "areaCode": 611021000000,
                        "areaId": 675688,
                        "areaName": "洛南县",
                        "parent_id": 675365,
                        "level": 2
                    },
                    {
                        "areaCode": 611022000000,
                        "areaId": 675949,
                        "areaName": "丹凤县",
                        "parent_id": 675365,
                        "level": 2
                    },
                    {
                        "areaCode": 611023000000,
                        "areaId": 676117,
                        "areaName": "商南县",
                        "parent_id": 675365,
                        "level": 2
                    },
                    {
                        "areaCode": 611024000000,
                        "areaId": 676252,
                        "areaName": "山阳县",
                        "parent_id": 675365,
                        "level": 2
                    },
                    {
                        "areaCode": 611025000000,
                        "areaId": 676510,
                        "areaName": "镇安县",
                        "parent_id": 675365,
                        "level": 2
                    },
                    {
                        "areaCode": 611026000000,
                        "areaId": 676686,
                        "areaName": "柞水县",
                        "parent_id": 675365,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 620000000000,
        "areaId": 676777,
        "areaName": "甘肃省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 620100000000,
                "areaId": 676778,
                "areaName": "兰州市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 620101000000,
                        "areaId": 676779,
                        "areaName": "市辖区",
                        "parent_id": 676778,
                        "level": 2
                    },
                    {
                        "areaCode": 620102000000,
                        "areaId": 676780,
                        "areaName": "城关区",
                        "parent_id": 676778,
                        "level": 2
                    },
                    {
                        "areaCode": 620103000000,
                        "areaId": 676982,
                        "areaName": "七里河区",
                        "parent_id": 676778,
                        "level": 2
                    },
                    {
                        "areaCode": 620104000000,
                        "areaId": 677135,
                        "areaName": "西固区",
                        "parent_id": 676778,
                        "level": 2
                    },
                    {
                        "areaCode": 620105000000,
                        "areaId": 677260,
                        "areaName": "安宁区",
                        "parent_id": 676778,
                        "level": 2
                    },
                    {
                        "areaCode": 620111000000,
                        "areaId": 677329,
                        "areaName": "红古区",
                        "parent_id": 676778,
                        "level": 2
                    },
                    {
                        "areaCode": 620121000000,
                        "areaId": 677394,
                        "areaName": "永登县",
                        "parent_id": 676778,
                        "level": 2
                    },
                    {
                        "areaCode": 620122000000,
                        "areaId": 677621,
                        "areaName": "皋兰县",
                        "parent_id": 676778,
                        "level": 2
                    },
                    {
                        "areaCode": 620123000000,
                        "areaId": 677690,
                        "areaName": "榆中县",
                        "parent_id": 676778,
                        "level": 2
                    },
                    {
                        "areaCode": 620171000000,
                        "areaId": 677986,
                        "areaName": "兰州新区",
                        "parent_id": 676778,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 620200000000,
                "areaId": 678046,
                "areaName": "嘉峪关市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 620201000000,
                        "areaId": 678047,
                        "areaName": "市辖区",
                        "parent_id": 678046,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 620300000000,
                "areaId": 678101,
                "areaName": "金昌市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 620301000000,
                        "areaId": 678102,
                        "areaName": "市辖区",
                        "parent_id": 678101,
                        "level": 2
                    },
                    {
                        "areaCode": 620302000000,
                        "areaId": 678103,
                        "areaName": "金川区",
                        "parent_id": 678101,
                        "level": 2
                    },
                    {
                        "areaCode": 620321000000,
                        "areaId": 678155,
                        "areaName": "永昌县",
                        "parent_id": 678101,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 620400000000,
                "areaId": 678293,
                "areaName": "白银市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 620401000000,
                        "areaId": 678294,
                        "areaName": "市辖区",
                        "parent_id": 678293,
                        "level": 2
                    },
                    {
                        "areaCode": 620402000000,
                        "areaId": 678295,
                        "areaName": "白银区",
                        "parent_id": 678293,
                        "level": 2
                    },
                    {
                        "areaCode": 620403000000,
                        "areaId": 678390,
                        "areaName": "平川区",
                        "parent_id": 678293,
                        "level": 2
                    },
                    {
                        "areaCode": 620421000000,
                        "areaId": 678493,
                        "areaName": "靖远县",
                        "parent_id": 678293,
                        "level": 2
                    },
                    {
                        "areaCode": 620422000000,
                        "areaId": 678698,
                        "areaName": "会宁县",
                        "parent_id": 678293,
                        "level": 2
                    },
                    {
                        "areaCode": 620423000000,
                        "areaId": 679031,
                        "areaName": "景泰县",
                        "parent_id": 678293,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 620500000000,
                "areaId": 679187,
                "areaName": "天水市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 620501000000,
                        "areaId": 679188,
                        "areaName": "市辖区",
                        "parent_id": 679187,
                        "level": 2
                    },
                    {
                        "areaCode": 620502000000,
                        "areaId": 679189,
                        "areaName": "秦州区",
                        "parent_id": 679187,
                        "level": 2
                    },
                    {
                        "areaCode": 620503000000,
                        "areaId": 679680,
                        "areaName": "麦积区",
                        "parent_id": 679187,
                        "level": 2
                    },
                    {
                        "areaCode": 620521000000,
                        "areaId": 680127,
                        "areaName": "清水县",
                        "parent_id": 679187,
                        "level": 2
                    },
                    {
                        "areaCode": 620522000000,
                        "areaId": 680412,
                        "areaName": "秦安县",
                        "parent_id": 679187,
                        "level": 2
                    },
                    {
                        "areaCode": 620523000000,
                        "areaId": 680866,
                        "areaName": "甘谷县",
                        "parent_id": 679187,
                        "level": 2
                    },
                    {
                        "areaCode": 620524000000,
                        "areaId": 681297,
                        "areaName": "武山县",
                        "parent_id": 679187,
                        "level": 2
                    },
                    {
                        "areaCode": 620525000000,
                        "areaId": 681667,
                        "areaName": "张家川回族自治县",
                        "parent_id": 679187,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 620600000000,
                "areaId": 681944,
                "areaName": "武威市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 620601000000,
                        "areaId": 681945,
                        "areaName": "市辖区",
                        "parent_id": 681944,
                        "level": 2
                    },
                    {
                        "areaCode": 620602000000,
                        "areaId": 681946,
                        "areaName": "凉州区",
                        "parent_id": 681944,
                        "level": 2
                    },
                    {
                        "areaCode": 620621000000,
                        "areaId": 682536,
                        "areaName": "民勤县",
                        "parent_id": 681944,
                        "level": 2
                    },
                    {
                        "areaCode": 620622000000,
                        "areaId": 682848,
                        "areaName": "古浪县",
                        "parent_id": 681944,
                        "level": 2
                    },
                    {
                        "areaCode": 620623000000,
                        "areaId": 683140,
                        "areaName": "天祝藏族自治县",
                        "parent_id": 681944,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 620700000000,
                "areaId": 683364,
                "areaName": "张掖市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 620701000000,
                        "areaId": 683365,
                        "areaName": "市辖区",
                        "parent_id": 683364,
                        "level": 2
                    },
                    {
                        "areaCode": 620702000000,
                        "areaId": 683366,
                        "areaName": "甘州区",
                        "parent_id": 683364,
                        "level": 2
                    },
                    {
                        "areaCode": 620721000000,
                        "areaId": 683661,
                        "areaName": "肃南裕固族自治县",
                        "parent_id": 683364,
                        "level": 2
                    },
                    {
                        "areaCode": 620722000000,
                        "areaId": 683779,
                        "areaName": "民乐县",
                        "parent_id": 683364,
                        "level": 2
                    },
                    {
                        "areaCode": 620723000000,
                        "areaId": 683970,
                        "areaName": "临泽县",
                        "parent_id": 683364,
                        "level": 2
                    },
                    {
                        "areaCode": 620724000000,
                        "areaId": 684066,
                        "areaName": "高台县",
                        "parent_id": 683364,
                        "level": 2
                    },
                    {
                        "areaCode": 620725000000,
                        "areaId": 684223,
                        "areaName": "山丹县",
                        "parent_id": 683364,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 620800000000,
                "areaId": 684356,
                "areaName": "平凉市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 620801000000,
                        "areaId": 684357,
                        "areaName": "市辖区",
                        "parent_id": 684356,
                        "level": 2
                    },
                    {
                        "areaCode": 620802000000,
                        "areaId": 684358,
                        "areaName": "崆峒区",
                        "parent_id": 684356,
                        "level": 2
                    },
                    {
                        "areaCode": 620821000000,
                        "areaId": 684649,
                        "areaName": "泾川县",
                        "parent_id": 684356,
                        "level": 2
                    },
                    {
                        "areaCode": 620822000000,
                        "areaId": 684890,
                        "areaName": "灵台县",
                        "parent_id": 684356,
                        "level": 2
                    },
                    {
                        "areaCode": 620823000000,
                        "areaId": 685096,
                        "areaName": "崇信县",
                        "parent_id": 684356,
                        "level": 2
                    },
                    {
                        "areaCode": 620824000000,
                        "areaId": 685193,
                        "areaName": "华亭县",
                        "parent_id": 684356,
                        "level": 2
                    },
                    {
                        "areaCode": 620825000000,
                        "areaId": 685333,
                        "areaName": "庄浪县",
                        "parent_id": 684356,
                        "level": 2
                    },
                    {
                        "areaCode": 620826000000,
                        "areaId": 685651,
                        "areaName": "静宁县",
                        "parent_id": 684356,
                        "level": 2
                    },
                    {
                        "areaCode": 620871000000,
                        "areaId": 686017,
                        "areaName": "平凉工业园区",
                        "parent_id": 684356,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 620900000000,
                "areaId": 686020,
                "areaName": "酒泉市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 620901000000,
                        "areaId": 686021,
                        "areaName": "市辖区",
                        "parent_id": 686020,
                        "level": 2
                    },
                    {
                        "areaCode": 620902000000,
                        "areaId": 686022,
                        "areaName": "肃州区",
                        "parent_id": 686020,
                        "level": 2
                    },
                    {
                        "areaCode": 620921000000,
                        "areaId": 686194,
                        "areaName": "金塔县",
                        "parent_id": 686020,
                        "level": 2
                    },
                    {
                        "areaCode": 620922000000,
                        "areaId": 686307,
                        "areaName": "瓜州县",
                        "parent_id": 686020,
                        "level": 2
                    },
                    {
                        "areaCode": 620923000000,
                        "areaId": 686412,
                        "areaName": "肃北蒙古族自治县",
                        "parent_id": 686020,
                        "level": 2
                    },
                    {
                        "areaCode": 620924000000,
                        "areaId": 686445,
                        "areaName": "阿克塞哈萨克族自治县",
                        "parent_id": 686020,
                        "level": 2
                    },
                    {
                        "areaCode": 620981000000,
                        "areaId": 686464,
                        "areaName": "玉门市",
                        "parent_id": 686020,
                        "level": 2
                    },
                    {
                        "areaCode": 620982000000,
                        "areaId": 686566,
                        "areaName": "敦煌市",
                        "parent_id": 686020,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 621000000000,
                "areaId": 686645,
                "areaName": "庆阳市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 621001000000,
                        "areaId": 686646,
                        "areaName": "市辖区",
                        "parent_id": 686645,
                        "level": 2
                    },
                    {
                        "areaCode": 621002000000,
                        "areaId": 686647,
                        "areaName": "西峰区",
                        "parent_id": 686645,
                        "level": 2
                    },
                    {
                        "areaCode": 621021000000,
                        "areaId": 686773,
                        "areaName": "庆城县",
                        "parent_id": 686645,
                        "level": 2
                    },
                    {
                        "areaCode": 621022000000,
                        "areaId": 686962,
                        "areaName": "环县",
                        "parent_id": 686645,
                        "level": 2
                    },
                    {
                        "areaCode": 621023000000,
                        "areaId": 687245,
                        "areaName": "华池县",
                        "parent_id": 686645,
                        "level": 2
                    },
                    {
                        "areaCode": 621024000000,
                        "areaId": 687390,
                        "areaName": "合水县",
                        "parent_id": 686645,
                        "level": 2
                    },
                    {
                        "areaCode": 621025000000,
                        "areaId": 687496,
                        "areaName": "正宁县",
                        "parent_id": 686645,
                        "level": 2
                    },
                    {
                        "areaCode": 621026000000,
                        "areaId": 687615,
                        "areaName": "宁县",
                        "parent_id": 686645,
                        "level": 2
                    },
                    {
                        "areaCode": 621027000000,
                        "areaId": 687912,
                        "areaName": "镇原县",
                        "parent_id": 686645,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 621100000000,
                "areaId": 688153,
                "areaName": "定西市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 621101000000,
                        "areaId": 688154,
                        "areaName": "市辖区",
                        "parent_id": 688153,
                        "level": 2
                    },
                    {
                        "areaCode": 621102000000,
                        "areaId": 688155,
                        "areaName": "安定区",
                        "parent_id": 688153,
                        "level": 2
                    },
                    {
                        "areaCode": 621121000000,
                        "areaId": 688505,
                        "areaName": "通渭县",
                        "parent_id": 688153,
                        "level": 2
                    },
                    {
                        "areaCode": 621122000000,
                        "areaId": 688866,
                        "areaName": "陇西县",
                        "parent_id": 688153,
                        "level": 2
                    },
                    {
                        "areaCode": 621123000000,
                        "areaId": 689110,
                        "areaName": "渭源县",
                        "parent_id": 688153,
                        "level": 2
                    },
                    {
                        "areaCode": 621124000000,
                        "areaId": 689347,
                        "areaName": "临洮县",
                        "parent_id": 688153,
                        "level": 2
                    },
                    {
                        "areaCode": 621125000000,
                        "areaId": 689701,
                        "areaName": "漳县",
                        "parent_id": 688153,
                        "level": 2
                    },
                    {
                        "areaCode": 621126000000,
                        "areaId": 689856,
                        "areaName": "岷县",
                        "parent_id": 688153,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 621200000000,
                "areaId": 690247,
                "areaName": "陇南市",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 621201000000,
                        "areaId": 690248,
                        "areaName": "市辖区",
                        "parent_id": 690247,
                        "level": 2
                    },
                    {
                        "areaCode": 621202000000,
                        "areaId": 690249,
                        "areaName": "武都区",
                        "parent_id": 690247,
                        "level": 2
                    },
                    {
                        "areaCode": 621221000000,
                        "areaId": 690999,
                        "areaName": "成县",
                        "parent_id": 690247,
                        "level": 2
                    },
                    {
                        "areaCode": 621222000000,
                        "areaId": 691276,
                        "areaName": "文县",
                        "parent_id": 690247,
                        "level": 2
                    },
                    {
                        "areaCode": 621223000000,
                        "areaId": 691609,
                        "areaName": "宕昌县",
                        "parent_id": 690247,
                        "level": 2
                    },
                    {
                        "areaCode": 621224000000,
                        "areaId": 691975,
                        "areaName": "康县",
                        "parent_id": 690247,
                        "level": 2
                    },
                    {
                        "areaCode": 621225000000,
                        "areaId": 692356,
                        "areaName": "西和县",
                        "parent_id": 690247,
                        "level": 2
                    },
                    {
                        "areaCode": 621226000000,
                        "areaId": 692771,
                        "areaName": "礼县",
                        "parent_id": 690247,
                        "level": 2
                    },
                    {
                        "areaCode": 621227000000,
                        "areaId": 693380,
                        "areaName": "徽县",
                        "parent_id": 690247,
                        "level": 2
                    },
                    {
                        "areaCode": 621228000000,
                        "areaId": 693631,
                        "areaName": "两当县",
                        "parent_id": 690247,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 622900000000,
                "areaId": 693766,
                "areaName": "临夏回族自治州",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 622901000000,
                        "areaId": 693767,
                        "areaName": "临夏市",
                        "parent_id": 693766,
                        "level": 2
                    },
                    {
                        "areaCode": 622921000000,
                        "areaId": 693852,
                        "areaName": "临夏县",
                        "parent_id": 693766,
                        "level": 2
                    },
                    {
                        "areaCode": 622922000000,
                        "areaId": 694101,
                        "areaName": "康乐县",
                        "parent_id": 693766,
                        "level": 2
                    },
                    {
                        "areaCode": 622923000000,
                        "areaId": 694270,
                        "areaName": "永靖县",
                        "parent_id": 693766,
                        "level": 2
                    },
                    {
                        "areaCode": 622924000000,
                        "areaId": 694439,
                        "areaName": "广河县",
                        "parent_id": 693766,
                        "level": 2
                    },
                    {
                        "areaCode": 622925000000,
                        "areaId": 694552,
                        "areaName": "和政县",
                        "parent_id": 693766,
                        "level": 2
                    },
                    {
                        "areaCode": 622926000000,
                        "areaId": 694692,
                        "areaName": "东乡族自治县",
                        "parent_id": 693766,
                        "level": 2
                    },
                    {
                        "areaCode": 622927000000,
                        "areaId": 694947,
                        "areaName": "积石山保安族东乡族撒拉族自治县",
                        "parent_id": 693766,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 623000000000,
                "areaId": 695116,
                "areaName": "甘南藏族自治州",
                "parent_id": 676777,
                "level": 1,
                "children": [
                    {
                        "areaCode": 623001000000,
                        "areaId": 695117,
                        "areaName": "合作市",
                        "parent_id": 695116,
                        "level": 2
                    },
                    {
                        "areaCode": 623021000000,
                        "areaId": 695178,
                        "areaName": "临潭县",
                        "parent_id": 695116,
                        "level": 2
                    },
                    {
                        "areaCode": 623022000000,
                        "areaId": 695339,
                        "areaName": "卓尼县",
                        "parent_id": 695116,
                        "level": 2
                    },
                    {
                        "areaCode": 623023000000,
                        "areaId": 695461,
                        "areaName": "舟曲县",
                        "parent_id": 695116,
                        "level": 2
                    },
                    {
                        "areaCode": 623024000000,
                        "areaId": 695696,
                        "areaName": "迭部县",
                        "parent_id": 695116,
                        "level": 2
                    },
                    {
                        "areaCode": 623025000000,
                        "areaId": 695764,
                        "areaName": "玛曲县",
                        "parent_id": 695116,
                        "level": 2
                    },
                    {
                        "areaCode": 623026000000,
                        "areaId": 695817,
                        "areaName": "碌曲县",
                        "parent_id": 695116,
                        "level": 2
                    },
                    {
                        "areaCode": 623027000000,
                        "areaId": 695856,
                        "areaName": "夏河县",
                        "parent_id": 695116,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 630000000000,
        "areaId": 695945,
        "areaName": "青海省",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 630100000000,
                "areaId": 695946,
                "areaName": "西宁市",
                "parent_id": 695945,
                "level": 1,
                "children": [
                    {
                        "areaCode": 630101000000,
                        "areaId": 695947,
                        "areaName": "市辖区",
                        "parent_id": 695946,
                        "level": 2
                    },
                    {
                        "areaCode": 630102000000,
                        "areaId": 695948,
                        "areaName": "城东区",
                        "parent_id": 695946,
                        "level": 2
                    },
                    {
                        "areaCode": 630103000000,
                        "areaId": 696015,
                        "areaName": "城中区",
                        "parent_id": 695946,
                        "level": 2
                    },
                    {
                        "areaCode": 630104000000,
                        "areaId": 696086,
                        "areaName": "城西区",
                        "parent_id": 695946,
                        "level": 2
                    },
                    {
                        "areaCode": 630105000000,
                        "areaId": 696134,
                        "areaName": "城北区",
                        "parent_id": 695946,
                        "level": 2
                    },
                    {
                        "areaCode": 630121000000,
                        "areaId": 696208,
                        "areaName": "大通回族土族自治县",
                        "parent_id": 695946,
                        "level": 2
                    },
                    {
                        "areaCode": 630122000000,
                        "areaId": 696540,
                        "areaName": "湟中县",
                        "parent_id": 695946,
                        "level": 2
                    },
                    {
                        "areaCode": 630123000000,
                        "areaId": 696965,
                        "areaName": "湟源县",
                        "parent_id": 695946,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 630200000000,
                "areaId": 697129,
                "areaName": "海东市",
                "parent_id": 695945,
                "level": 1,
                "children": [
                    {
                        "areaCode": 630202000000,
                        "areaId": 697130,
                        "areaName": "乐都区",
                        "parent_id": 697129,
                        "level": 2
                    },
                    {
                        "areaCode": 630203000000,
                        "areaId": 697518,
                        "areaName": "平安区",
                        "parent_id": 697129,
                        "level": 2
                    },
                    {
                        "areaCode": 630222000000,
                        "areaId": 697647,
                        "areaName": "民和回族土族自治县",
                        "parent_id": 697129,
                        "level": 2
                    },
                    {
                        "areaCode": 630223000000,
                        "areaId": 697999,
                        "areaName": "互助土族自治县",
                        "parent_id": 697129,
                        "level": 2
                    },
                    {
                        "areaCode": 630224000000,
                        "areaId": 698330,
                        "areaName": "化隆回族自治县",
                        "parent_id": 697129,
                        "level": 2
                    },
                    {
                        "areaCode": 630225000000,
                        "areaId": 698721,
                        "areaName": "循化撒拉族自治县",
                        "parent_id": 697129,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 632200000000,
                "areaId": 698891,
                "areaName": "海北藏族自治州",
                "parent_id": 695945,
                "level": 1,
                "children": [
                    {
                        "areaCode": 632221000000,
                        "areaId": 698892,
                        "areaName": "门源回族自治县",
                        "parent_id": 698891,
                        "level": 2
                    },
                    {
                        "areaCode": 632222000000,
                        "areaId": 699037,
                        "areaName": "祁连县",
                        "parent_id": 698891,
                        "level": 2
                    },
                    {
                        "areaCode": 632223000000,
                        "areaId": 699095,
                        "areaName": "海晏县",
                        "parent_id": 698891,
                        "level": 2
                    },
                    {
                        "areaCode": 632224000000,
                        "areaId": 699138,
                        "areaName": "刚察县",
                        "parent_id": 698891,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 632300000000,
                "areaId": 699194,
                "areaName": "黄南藏族自治州",
                "parent_id": 695945,
                "level": 1,
                "children": [
                    {
                        "areaCode": 632321000000,
                        "areaId": 699195,
                        "areaName": "同仁县",
                        "parent_id": 699194,
                        "level": 2
                    },
                    {
                        "areaCode": 632322000000,
                        "areaId": 699289,
                        "areaName": "尖扎县",
                        "parent_id": 699194,
                        "level": 2
                    },
                    {
                        "areaCode": 632323000000,
                        "areaId": 699393,
                        "areaName": "泽库县",
                        "parent_id": 699194,
                        "level": 2
                    },
                    {
                        "areaCode": 632324000000,
                        "areaId": 699476,
                        "areaName": "河南蒙古族自治县",
                        "parent_id": 699194,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 632500000000,
                "areaId": 699527,
                "areaName": "海南藏族自治州",
                "parent_id": 695945,
                "level": 1,
                "children": [
                    {
                        "areaCode": 632521000000,
                        "areaId": 699528,
                        "areaName": "共和县",
                        "parent_id": 699527,
                        "level": 2
                    },
                    {
                        "areaCode": 632522000000,
                        "areaId": 699664,
                        "areaName": "同德县",
                        "parent_id": 699527,
                        "level": 2
                    },
                    {
                        "areaCode": 632523000000,
                        "areaId": 699762,
                        "areaName": "贵德县",
                        "parent_id": 699527,
                        "level": 2
                    },
                    {
                        "areaCode": 632524000000,
                        "areaId": 699902,
                        "areaName": "兴海县",
                        "parent_id": 699527,
                        "level": 2
                    },
                    {
                        "areaCode": 632525000000,
                        "areaId": 699977,
                        "areaName": "贵南县",
                        "parent_id": 699527,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 632600000000,
                "areaId": 700076,
                "areaName": "果洛藏族自治州",
                "parent_id": 695945,
                "level": 1,
                "children": [
                    {
                        "areaCode": 632621000000,
                        "areaId": 700077,
                        "areaName": "玛沁县",
                        "parent_id": 700076,
                        "level": 2
                    },
                    {
                        "areaCode": 632622000000,
                        "areaId": 700128,
                        "areaName": "班玛县",
                        "parent_id": 700076,
                        "level": 2
                    },
                    {
                        "areaCode": 632623000000,
                        "areaId": 700171,
                        "areaName": "甘德县",
                        "parent_id": 700076,
                        "level": 2
                    },
                    {
                        "areaCode": 632624000000,
                        "areaId": 700217,
                        "areaName": "达日县",
                        "parent_id": 700076,
                        "level": 2
                    },
                    {
                        "areaCode": 632625000000,
                        "areaId": 700263,
                        "areaName": "久治县",
                        "parent_id": 700076,
                        "level": 2
                    },
                    {
                        "areaCode": 632626000000,
                        "areaId": 700296,
                        "areaName": "玛多县",
                        "parent_id": 700076,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 632700000000,
                "areaId": 700334,
                "areaName": "玉树藏族自治州",
                "parent_id": 695945,
                "level": 1,
                "children": [
                    {
                        "areaCode": 632701000000,
                        "areaId": 700335,
                        "areaName": "玉树市",
                        "parent_id": 700334,
                        "level": 2
                    },
                    {
                        "areaCode": 632722000000,
                        "areaId": 700428,
                        "areaName": "杂多县",
                        "parent_id": 700334,
                        "level": 2
                    },
                    {
                        "areaCode": 632723000000,
                        "areaId": 700478,
                        "areaName": "称多县",
                        "parent_id": 700334,
                        "level": 2
                    },
                    {
                        "areaCode": 632724000000,
                        "areaId": 700551,
                        "areaName": "治多县",
                        "parent_id": 700334,
                        "level": 2
                    },
                    {
                        "areaCode": 632725000000,
                        "areaId": 700584,
                        "areaName": "囊谦县",
                        "parent_id": 700334,
                        "level": 2
                    },
                    {
                        "areaCode": 632726000000,
                        "areaId": 700672,
                        "areaName": "曲麻莱县",
                        "parent_id": 700334,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 632800000000,
                "areaId": 700702,
                "areaName": "海西蒙古族藏族自治州",
                "parent_id": 695945,
                "level": 1,
                "children": [
                    {
                        "areaCode": 632801000000,
                        "areaId": 700703,
                        "areaName": "格尔木市",
                        "parent_id": 700702,
                        "level": 2
                    },
                    {
                        "areaCode": 632802000000,
                        "areaId": 700789,
                        "areaName": "德令哈市",
                        "parent_id": 700702,
                        "level": 2
                    },
                    {
                        "areaCode": 632821000000,
                        "areaId": 700866,
                        "areaName": "乌兰县",
                        "parent_id": 700702,
                        "level": 2
                    },
                    {
                        "areaCode": 632822000000,
                        "areaId": 700917,
                        "areaName": "都兰县",
                        "parent_id": 700702,
                        "level": 2
                    },
                    {
                        "areaCode": 632823000000,
                        "areaId": 701042,
                        "areaName": "天峻县",
                        "parent_id": 700702,
                        "level": 2
                    },
                    {
                        "areaCode": 632857000000,
                        "areaId": 701117,
                        "areaName": "大柴旦行政委员会",
                        "parent_id": 700702,
                        "level": 2
                    },
                    {
                        "areaCode": 632858000000,
                        "areaId": 701125,
                        "areaName": "冷湖行政委员会",
                        "parent_id": 700702,
                        "level": 2
                    },
                    {
                        "areaCode": 632859000000,
                        "areaId": 701128,
                        "areaName": "茫崖行政委员会",
                        "parent_id": 700702,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 640000000000,
        "areaId": 701142,
        "areaName": "宁夏回族自治区",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 640100000000,
                "areaId": 701143,
                "areaName": "银川市",
                "parent_id": 701142,
                "level": 1,
                "children": [
                    {
                        "areaCode": 640101000000,
                        "areaId": 701144,
                        "areaName": "市辖区",
                        "parent_id": 701143,
                        "level": 2
                    },
                    {
                        "areaCode": 640104000000,
                        "areaId": 701145,
                        "areaName": "兴庆区",
                        "parent_id": 701143,
                        "level": 2
                    },
                    {
                        "areaCode": 640105000000,
                        "areaId": 701295,
                        "areaName": "西夏区",
                        "parent_id": 701143,
                        "level": 2
                    },
                    {
                        "areaCode": 640106000000,
                        "areaId": 701383,
                        "areaName": "金凤区",
                        "parent_id": 701143,
                        "level": 2
                    },
                    {
                        "areaCode": 640121000000,
                        "areaId": 701458,
                        "areaName": "永宁县",
                        "parent_id": 701143,
                        "level": 2
                    },
                    {
                        "areaCode": 640122000000,
                        "areaId": 701558,
                        "areaName": "贺兰县",
                        "parent_id": 701143,
                        "level": 2
                    },
                    {
                        "areaCode": 640181000000,
                        "areaId": 701650,
                        "areaName": "灵武市",
                        "parent_id": 701143,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 640200000000,
                "areaId": 701766,
                "areaName": "石嘴山市",
                "parent_id": 701142,
                "level": 1,
                "children": [
                    {
                        "areaCode": 640201000000,
                        "areaId": 701767,
                        "areaName": "市辖区",
                        "parent_id": 701766,
                        "level": 2
                    },
                    {
                        "areaCode": 640202000000,
                        "areaId": 701768,
                        "areaName": "大武口区",
                        "parent_id": 701766,
                        "level": 2
                    },
                    {
                        "areaCode": 640205000000,
                        "areaId": 701842,
                        "areaName": "惠农区",
                        "parent_id": 701766,
                        "level": 2
                    },
                    {
                        "areaCode": 640221000000,
                        "areaId": 701941,
                        "areaName": "平罗县",
                        "parent_id": 701766,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 640300000000,
                "areaId": 702135,
                "areaName": "吴忠市",
                "parent_id": 701142,
                "level": 1,
                "children": [
                    {
                        "areaCode": 640301000000,
                        "areaId": 702136,
                        "areaName": "市辖区",
                        "parent_id": 702135,
                        "level": 2
                    },
                    {
                        "areaCode": 640302000000,
                        "areaId": 702137,
                        "areaName": "利通区",
                        "parent_id": 702135,
                        "level": 2
                    },
                    {
                        "areaCode": 640303000000,
                        "areaId": 702310,
                        "areaName": "红寺堡区",
                        "parent_id": 702135,
                        "level": 2
                    },
                    {
                        "areaCode": 640323000000,
                        "areaId": 702389,
                        "areaName": "盐池县",
                        "parent_id": 702135,
                        "level": 2
                    },
                    {
                        "areaCode": 640324000000,
                        "areaId": 702517,
                        "areaName": "同心县",
                        "parent_id": 702135,
                        "level": 2
                    },
                    {
                        "areaCode": 640381000000,
                        "areaId": 702687,
                        "areaName": "青铜峡市",
                        "parent_id": 702135,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 640400000000,
                "areaId": 702813,
                "areaName": "固原市",
                "parent_id": 701142,
                "level": 1,
                "children": [
                    {
                        "areaCode": 640401000000,
                        "areaId": 702814,
                        "areaName": "市辖区",
                        "parent_id": 702813,
                        "level": 2
                    },
                    {
                        "areaCode": 640402000000,
                        "areaId": 702815,
                        "areaName": "原州区",
                        "parent_id": 702813,
                        "level": 2
                    },
                    {
                        "areaCode": 640422000000,
                        "areaId": 703021,
                        "areaName": "西吉县",
                        "parent_id": 702813,
                        "level": 2
                    },
                    {
                        "areaCode": 640423000000,
                        "areaId": 703345,
                        "areaName": "隆德县",
                        "parent_id": 702813,
                        "level": 2
                    },
                    {
                        "areaCode": 640424000000,
                        "areaId": 703482,
                        "areaName": "泾源县",
                        "parent_id": 702813,
                        "level": 2
                    },
                    {
                        "areaCode": 640425000000,
                        "areaId": 703602,
                        "areaName": "彭阳县",
                        "parent_id": 702813,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 640500000000,
                "areaId": 703775,
                "areaName": "中卫市",
                "parent_id": 701142,
                "level": 1,
                "children": [
                    {
                        "areaCode": 640501000000,
                        "areaId": 703776,
                        "areaName": "市辖区",
                        "parent_id": 703775,
                        "level": 2
                    },
                    {
                        "areaCode": 640502000000,
                        "areaId": 703777,
                        "areaName": "沙坡头区",
                        "parent_id": 703775,
                        "level": 2
                    },
                    {
                        "areaCode": 640521000000,
                        "areaId": 703975,
                        "areaName": "中宁县",
                        "parent_id": 703775,
                        "level": 2
                    },
                    {
                        "areaCode": 640522000000,
                        "areaId": 704136,
                        "areaName": "海原县",
                        "parent_id": 703775,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 650000000000,
        "areaId": 704337,
        "areaName": "新疆维吾尔自治区",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 650100000000,
                "areaId": 704338,
                "areaName": "乌鲁木齐市",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 650101000000,
                        "areaId": 704339,
                        "areaName": "市辖区",
                        "parent_id": 704338,
                        "level": 2
                    },
                    {
                        "areaCode": 650102000000,
                        "areaId": 704340,
                        "areaName": "天山区",
                        "parent_id": 704338,
                        "level": 2
                    },
                    {
                        "areaCode": 650103000000,
                        "areaId": 704557,
                        "areaName": "沙依巴克区",
                        "parent_id": 704338,
                        "level": 2
                    },
                    {
                        "areaCode": 650104000000,
                        "areaId": 704787,
                        "areaName": "新市区",
                        "parent_id": 704338,
                        "level": 2
                    },
                    {
                        "areaCode": 650105000000,
                        "areaId": 704957,
                        "areaName": "水磨沟区",
                        "parent_id": 704338,
                        "level": 2
                    },
                    {
                        "areaCode": 650106000000,
                        "areaId": 705132,
                        "areaName": "头屯河区",
                        "parent_id": 704338,
                        "level": 2
                    },
                    {
                        "areaCode": 650107000000,
                        "areaId": 705216,
                        "areaName": "达坂城区",
                        "parent_id": 704338,
                        "level": 2
                    },
                    {
                        "areaCode": 650109000000,
                        "areaId": 705266,
                        "areaName": "米东区",
                        "parent_id": 704338,
                        "level": 2
                    },
                    {
                        "areaCode": 650121000000,
                        "areaId": 705438,
                        "areaName": "乌鲁木齐县",
                        "parent_id": 704338,
                        "level": 2
                    },
                    {
                        "areaCode": 650171000000,
                        "areaId": 705486,
                        "areaName": "乌鲁木齐经济技术开发区",
                        "parent_id": 704338,
                        "level": 2
                    },
                    {
                        "areaCode": 650172000000,
                        "areaId": 705537,
                        "areaName": "乌鲁木齐高新技术产业开发区",
                        "parent_id": 704338,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 650200000000,
                "areaId": 705562,
                "areaName": "克拉玛依市",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 650201000000,
                        "areaId": 705563,
                        "areaName": "市辖区",
                        "parent_id": 705562,
                        "level": 2
                    },
                    {
                        "areaCode": 650202000000,
                        "areaId": 705564,
                        "areaName": "独山子区",
                        "parent_id": 705562,
                        "level": 2
                    },
                    {
                        "areaCode": 650203000000,
                        "areaId": 705586,
                        "areaName": "克拉玛依区",
                        "parent_id": 705562,
                        "level": 2
                    },
                    {
                        "areaCode": 650204000000,
                        "areaId": 705708,
                        "areaName": "白碱滩区",
                        "parent_id": 705562,
                        "level": 2
                    },
                    {
                        "areaCode": 650205000000,
                        "areaId": 705730,
                        "areaName": "乌尔禾区",
                        "parent_id": 705562,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 650400000000,
                "areaId": 705749,
                "areaName": "吐鲁番市",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 650402000000,
                        "areaId": 705750,
                        "areaName": "高昌区",
                        "parent_id": 705749,
                        "level": 2
                    },
                    {
                        "areaCode": 650421000000,
                        "areaId": 705874,
                        "areaName": "鄯善县",
                        "parent_id": 705749,
                        "level": 2
                    },
                    {
                        "areaCode": 650422000000,
                        "areaId": 705991,
                        "areaName": "托克逊县",
                        "parent_id": 705749,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 650500000000,
                "areaId": 706061,
                "areaName": "哈密市",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 650502000000,
                        "areaId": 706062,
                        "areaName": "伊州区",
                        "parent_id": 706061,
                        "level": 2
                    },
                    {
                        "areaCode": 650521000000,
                        "areaId": 706326,
                        "areaName": "巴里坤哈萨克自治县",
                        "parent_id": 706061,
                        "level": 2
                    },
                    {
                        "areaCode": 650522000000,
                        "areaId": 706423,
                        "areaName": "伊吾县",
                        "parent_id": 706061,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 652300000000,
                "areaId": 706476,
                "areaName": "昌吉回族自治州",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 652301000000,
                        "areaId": 706477,
                        "areaName": "昌吉市",
                        "parent_id": 706476,
                        "level": 2
                    },
                    {
                        "areaCode": 652302000000,
                        "areaId": 706672,
                        "areaName": "阜康市",
                        "parent_id": 706476,
                        "level": 2
                    },
                    {
                        "areaCode": 652323000000,
                        "areaId": 706795,
                        "areaName": "呼图壁县",
                        "parent_id": 706476,
                        "level": 2
                    },
                    {
                        "areaCode": 652324000000,
                        "areaId": 706948,
                        "areaName": "玛纳斯县",
                        "parent_id": 706476,
                        "level": 2
                    },
                    {
                        "areaCode": 652325000000,
                        "areaId": 707224,
                        "areaName": "奇台县",
                        "parent_id": 706476,
                        "level": 2
                    },
                    {
                        "areaCode": 652327000000,
                        "areaId": 707372,
                        "areaName": "吉木萨尔县",
                        "parent_id": 706476,
                        "level": 2
                    },
                    {
                        "areaCode": 652328000000,
                        "areaId": 707471,
                        "areaName": "木垒哈萨克自治县",
                        "parent_id": 706476,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 652700000000,
                "areaId": 707556,
                "areaName": "博尔塔拉蒙古自治州",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 652701000000,
                        "areaId": 707557,
                        "areaName": "博乐市",
                        "parent_id": 707556,
                        "level": 2
                    },
                    {
                        "areaCode": 652702000000,
                        "areaId": 707852,
                        "areaName": "阿拉山口市",
                        "parent_id": 707556,
                        "level": 2
                    },
                    {
                        "areaCode": 652722000000,
                        "areaId": 707857,
                        "areaName": "精河县",
                        "parent_id": 707556,
                        "level": 2
                    },
                    {
                        "areaCode": 652723000000,
                        "areaId": 708004,
                        "areaName": "温泉县",
                        "parent_id": 707556,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 652800000000,
                "areaId": 708131,
                "areaName": "巴音郭楞蒙古自治州",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 652801000000,
                        "areaId": 708132,
                        "areaName": "库尔勒市",
                        "parent_id": 708131,
                        "level": 2
                    },
                    {
                        "areaCode": 652822000000,
                        "areaId": 708384,
                        "areaName": "轮台县",
                        "parent_id": 708131,
                        "level": 2
                    },
                    {
                        "areaCode": 652823000000,
                        "areaId": 708474,
                        "areaName": "尉犁县",
                        "parent_id": 708131,
                        "level": 2
                    },
                    {
                        "areaCode": 652824000000,
                        "areaId": 708600,
                        "areaName": "若羌县",
                        "parent_id": 708131,
                        "level": 2
                    },
                    {
                        "areaCode": 652825000000,
                        "areaId": 708662,
                        "areaName": "且末县",
                        "parent_id": 708131,
                        "level": 2
                    },
                    {
                        "areaCode": 652826000000,
                        "areaId": 708755,
                        "areaName": "焉耆回族自治县",
                        "parent_id": 708131,
                        "level": 2
                    },
                    {
                        "areaCode": 652827000000,
                        "areaId": 708854,
                        "areaName": "和静县",
                        "parent_id": 708131,
                        "level": 2
                    },
                    {
                        "areaCode": 652828000000,
                        "areaId": 709034,
                        "areaName": "和硕县",
                        "parent_id": 708131,
                        "level": 2
                    },
                    {
                        "areaCode": 652829000000,
                        "areaId": 709102,
                        "areaName": "博湖县",
                        "parent_id": 708131,
                        "level": 2
                    },
                    {
                        "areaCode": 652871000000,
                        "areaId": 709158,
                        "areaName": "库尔勒经济技术开发区",
                        "parent_id": 708131,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 652900000000,
                "areaId": 709162,
                "areaName": "阿克苏地区",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 652901000000,
                        "areaId": 709163,
                        "areaName": "阿克苏市",
                        "parent_id": 709162,
                        "level": 2
                    },
                    {
                        "areaCode": 652922000000,
                        "areaId": 709401,
                        "areaName": "温宿县",
                        "parent_id": 709162,
                        "level": 2
                    },
                    {
                        "areaCode": 652923000000,
                        "areaId": 709628,
                        "areaName": "库车县",
                        "parent_id": 709162,
                        "level": 2
                    },
                    {
                        "areaCode": 652924000000,
                        "areaId": 709917,
                        "areaName": "沙雅县",
                        "parent_id": 709162,
                        "level": 2
                    },
                    {
                        "areaCode": 652925000000,
                        "areaId": 710122,
                        "areaName": "新和县",
                        "parent_id": 709162,
                        "level": 2
                    },
                    {
                        "areaCode": 652926000000,
                        "areaId": 710274,
                        "areaName": "拜城县",
                        "parent_id": 709162,
                        "level": 2
                    },
                    {
                        "areaCode": 652927000000,
                        "areaId": 710487,
                        "areaName": "乌什县",
                        "parent_id": 709162,
                        "level": 2
                    },
                    {
                        "areaCode": 652928000000,
                        "areaId": 710632,
                        "areaName": "阿瓦提县",
                        "parent_id": 709162,
                        "level": 2
                    },
                    {
                        "areaCode": 652929000000,
                        "areaId": 710818,
                        "areaName": "柯坪县",
                        "parent_id": 709162,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 653000000000,
                "areaId": 710867,
                "areaName": "克孜勒苏柯尔克孜自治州",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 653001000000,
                        "areaId": 710868,
                        "areaName": "阿图什市",
                        "parent_id": 710867,
                        "level": 2
                    },
                    {
                        "areaCode": 653022000000,
                        "areaId": 711001,
                        "areaName": "阿克陶县",
                        "parent_id": 710867,
                        "level": 2
                    },
                    {
                        "areaCode": 653023000000,
                        "areaId": 711175,
                        "areaName": "阿合奇县",
                        "parent_id": 710867,
                        "level": 2
                    },
                    {
                        "areaCode": 653024000000,
                        "areaId": 711213,
                        "areaName": "乌恰县",
                        "parent_id": 710867,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 653100000000,
                "areaId": 711274,
                "areaName": "喀什地区",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 653101000000,
                        "areaId": 711275,
                        "areaName": "喀什市",
                        "parent_id": 711274,
                        "level": 2
                    },
                    {
                        "areaCode": 653121000000,
                        "areaId": 711530,
                        "areaName": "疏附县",
                        "parent_id": 711274,
                        "level": 2
                    },
                    {
                        "areaCode": 653122000000,
                        "areaId": 711696,
                        "areaName": "疏勒县",
                        "parent_id": 711274,
                        "level": 2
                    },
                    {
                        "areaCode": 653123000000,
                        "areaId": 711957,
                        "areaName": "英吉沙县",
                        "parent_id": 711274,
                        "level": 2
                    },
                    {
                        "areaCode": 653124000000,
                        "areaId": 712154,
                        "areaName": "泽普县",
                        "parent_id": 711274,
                        "level": 2
                    },
                    {
                        "areaCode": 653125000000,
                        "areaId": 712330,
                        "areaName": "莎车县",
                        "parent_id": 711274,
                        "level": 2
                    },
                    {
                        "areaCode": 653126000000,
                        "areaId": 712944,
                        "areaName": "叶城县",
                        "parent_id": 711274,
                        "level": 2
                    },
                    {
                        "areaCode": 653127000000,
                        "areaId": 713361,
                        "areaName": "麦盖提县",
                        "parent_id": 711274,
                        "level": 2
                    },
                    {
                        "areaCode": 653128000000,
                        "areaId": 713582,
                        "areaName": "岳普湖县",
                        "parent_id": 711274,
                        "level": 2
                    },
                    {
                        "areaCode": 653129000000,
                        "areaId": 713710,
                        "areaName": "伽师县",
                        "parent_id": 711274,
                        "level": 2
                    },
                    {
                        "areaCode": 653130000000,
                        "areaId": 714069,
                        "areaName": "巴楚县",
                        "parent_id": 711274,
                        "level": 2
                    },
                    {
                        "areaCode": 653131000000,
                        "areaId": 714331,
                        "areaName": "塔什库尔干塔吉克自治县",
                        "parent_id": 711274,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 653200000000,
                "areaId": 714394,
                "areaName": "和田地区",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 653201000000,
                        "areaId": 714395,
                        "areaName": "和田市",
                        "parent_id": 714394,
                        "level": 2
                    },
                    {
                        "areaCode": 653221000000,
                        "areaId": 714656,
                        "areaName": "和田县",
                        "parent_id": 714394,
                        "level": 2
                    },
                    {
                        "areaCode": 653222000000,
                        "areaId": 714928,
                        "areaName": "墨玉县",
                        "parent_id": 714394,
                        "level": 2
                    },
                    {
                        "areaCode": 653223000000,
                        "areaId": 715392,
                        "areaName": "皮山县",
                        "parent_id": 714394,
                        "level": 2
                    },
                    {
                        "areaCode": 653224000000,
                        "areaId": 715652,
                        "areaName": "洛浦县",
                        "parent_id": 714394,
                        "level": 2
                    },
                    {
                        "areaCode": 653225000000,
                        "areaId": 715912,
                        "areaName": "策勒县",
                        "parent_id": 714394,
                        "level": 2
                    },
                    {
                        "areaCode": 653226000000,
                        "areaId": 716065,
                        "areaName": "于田县",
                        "parent_id": 714394,
                        "level": 2
                    },
                    {
                        "areaCode": 653227000000,
                        "areaId": 716315,
                        "areaName": "民丰县",
                        "parent_id": 714394,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 654000000000,
                "areaId": 716367,
                "areaName": "伊犁哈萨克自治州",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 654002000000,
                        "areaId": 716368,
                        "areaName": "伊宁市",
                        "parent_id": 716367,
                        "level": 2
                    },
                    {
                        "areaCode": 654003000000,
                        "areaId": 716557,
                        "areaName": "奎屯市",
                        "parent_id": 716367,
                        "level": 2
                    },
                    {
                        "areaCode": 654004000000,
                        "areaId": 716636,
                        "areaName": "霍尔果斯市",
                        "parent_id": 716367,
                        "level": 2
                    },
                    {
                        "areaCode": 654021000000,
                        "areaId": 716699,
                        "areaName": "伊宁县",
                        "parent_id": 716367,
                        "level": 2
                    },
                    {
                        "areaCode": 654022000000,
                        "areaId": 716889,
                        "areaName": "察布查尔锡伯自治县",
                        "parent_id": 716367,
                        "level": 2
                    },
                    {
                        "areaCode": 654023000000,
                        "areaId": 717024,
                        "areaName": "霍城县",
                        "parent_id": 716367,
                        "level": 2
                    },
                    {
                        "areaCode": 654024000000,
                        "areaId": 717193,
                        "areaName": "巩留县",
                        "parent_id": 716367,
                        "level": 2
                    },
                    {
                        "areaCode": 654025000000,
                        "areaId": 717315,
                        "areaName": "新源县",
                        "parent_id": 716367,
                        "level": 2
                    },
                    {
                        "areaCode": 654026000000,
                        "areaId": 717453,
                        "areaName": "昭苏县",
                        "parent_id": 716367,
                        "level": 2
                    },
                    {
                        "areaCode": 654027000000,
                        "areaId": 717598,
                        "areaName": "特克斯县",
                        "parent_id": 716367,
                        "level": 2
                    },
                    {
                        "areaCode": 654028000000,
                        "areaId": 717690,
                        "areaName": "尼勒克县",
                        "parent_id": 716367,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 654200000000,
                "areaId": 717803,
                "areaName": "塔城地区",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 654201000000,
                        "areaId": 717804,
                        "areaName": "塔城市",
                        "parent_id": 717803,
                        "level": 2
                    },
                    {
                        "areaCode": 654202000000,
                        "areaId": 718021,
                        "areaName": "乌苏市",
                        "parent_id": 717803,
                        "level": 2
                    },
                    {
                        "areaCode": 654221000000,
                        "areaId": 718417,
                        "areaName": "额敏县",
                        "parent_id": 717803,
                        "level": 2
                    },
                    {
                        "areaCode": 654223000000,
                        "areaId": 718704,
                        "areaName": "沙湾县",
                        "parent_id": 717803,
                        "level": 2
                    },
                    {
                        "areaCode": 654224000000,
                        "areaId": 719202,
                        "areaName": "托里县",
                        "parent_id": 717803,
                        "level": 2
                    },
                    {
                        "areaCode": 654225000000,
                        "areaId": 719301,
                        "areaName": "裕民县",
                        "parent_id": 717803,
                        "level": 2
                    },
                    {
                        "areaCode": 654226000000,
                        "areaId": 719378,
                        "areaName": "和布克赛尔蒙古自治县",
                        "parent_id": 717803,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 654300000000,
                "areaId": 719494,
                "areaName": "阿勒泰地区",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 654301000000,
                        "areaId": 719495,
                        "areaName": "阿勒泰市",
                        "parent_id": 719494,
                        "level": 2
                    },
                    {
                        "areaCode": 654321000000,
                        "areaId": 719679,
                        "areaName": "布尔津县",
                        "parent_id": 719494,
                        "level": 2
                    },
                    {
                        "areaCode": 654322000000,
                        "areaId": 719768,
                        "areaName": "富蕴县",
                        "parent_id": 719494,
                        "level": 2
                    },
                    {
                        "areaCode": 654323000000,
                        "areaId": 719868,
                        "areaName": "福海县",
                        "parent_id": 719494,
                        "level": 2
                    },
                    {
                        "areaCode": 654324000000,
                        "areaId": 720036,
                        "areaName": "哈巴河县",
                        "parent_id": 719494,
                        "level": 2
                    },
                    {
                        "areaCode": 654325000000,
                        "areaId": 720178,
                        "areaName": "青河县",
                        "parent_id": 719494,
                        "level": 2
                    },
                    {
                        "areaCode": 654326000000,
                        "areaId": 720245,
                        "areaName": "吉木乃县",
                        "parent_id": 719494,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 659000000000,
                "areaId": 720307,
                "areaName": "直辖县",
                "parent_id": 704337,
                "level": 1,
                "children": [
                    {
                        "areaCode": 659001000000,
                        "areaId": 720308,
                        "areaName": "石河子市",
                        "parent_id": 720307,
                        "level": 2
                    },
                    {
                        "areaCode": 659002000000,
                        "areaId": 720452,
                        "areaName": "阿拉尔市",
                        "parent_id": 720307,
                        "level": 2
                    },
                    {
                        "areaCode": 659003000000,
                        "areaId": 720782,
                        "areaName": "图木舒克市",
                        "parent_id": 720307,
                        "level": 2
                    },
                    {
                        "areaCode": 659004000000,
                        "areaId": 720933,
                        "areaName": "五家渠市",
                        "parent_id": 720307,
                        "level": 2
                    },
                    {
                        "areaCode": 659006000000,
                        "areaId": 720984,
                        "areaName": "铁门关市",
                        "parent_id": 720307,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 0,
        "areaId": 720989,
        "areaName": "香港特别行政区",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 0,
                "areaId": 720990,
                "areaName": "香港岛",
                "parent_id": 720989,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 720991,
                        "areaName": "中西区",
                        "parent_id": 720990,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 720992,
                        "areaName": "湾仔区",
                        "parent_id": 720990,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 720993,
                        "areaName": "东区",
                        "parent_id": 720990,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 720994,
                        "areaName": "南区",
                        "parent_id": 720990,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 720995,
                "areaName": "九龙",
                "parent_id": 720989,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 720996,
                        "areaName": "油尖旺区",
                        "parent_id": 720995,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 720997,
                        "areaName": "深水埗区",
                        "parent_id": 720995,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 720998,
                        "areaName": "九龙城区",
                        "parent_id": 720995,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 720999,
                        "areaName": "黄大仙区",
                        "parent_id": 720995,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721000,
                        "areaName": "观塘区",
                        "parent_id": 720995,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 721001,
                "areaName": "新界",
                "parent_id": 720989,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 721002,
                        "areaName": "荃湾区",
                        "parent_id": 721001,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721003,
                        "areaName": "屯门区",
                        "parent_id": 721001,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721004,
                        "areaName": "元朗区",
                        "parent_id": 721001,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721005,
                        "areaName": "北区",
                        "parent_id": 721001,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721006,
                        "areaName": "大埔区",
                        "parent_id": 721001,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721007,
                        "areaName": "西贡区",
                        "parent_id": 721001,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721008,
                        "areaName": "沙田区",
                        "parent_id": 721001,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721009,
                        "areaName": "葵青区",
                        "parent_id": 721001,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721010,
                        "areaName": "离岛区",
                        "parent_id": 721001,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 0,
        "areaId": 721011,
        "areaName": "澳门特别行政区",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 0,
                "areaId": 721012,
                "areaName": "澳门半岛",
                "parent_id": 721011,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 721013,
                        "areaName": "花地玛堂区",
                        "parent_id": 721012,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721014,
                        "areaName": "圣安多尼堂区",
                        "parent_id": 721012,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721015,
                        "areaName": "大堂区",
                        "parent_id": 721012,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721016,
                        "areaName": "望德堂区",
                        "parent_id": 721012,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721017,
                        "areaName": "风顺堂区",
                        "parent_id": 721012,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 721018,
                "areaName": "氹仔岛",
                "parent_id": 721011,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 721019,
                        "areaName": "嘉模堂区",
                        "parent_id": 721018,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 721020,
                "areaName": "路环岛",
                "parent_id": 721011,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 721021,
                        "areaName": "圣方济各堂区",
                        "parent_id": 721020,
                        "level": 2
                    }
                ]
            }
        ]
    },
    {
        "areaCode": 0,
        "areaId": 721022,
        "areaName": "台湾",
        "parent_id": 0,
        "level": 0,
        "children": [
            {
                "areaCode": 0,
                "areaId": 721023,
                "areaName": "彰化县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 721024,
                        "areaName": "芳苑乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721253,
                        "areaName": "芬园乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721322,
                        "areaName": "福兴乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721398,
                        "areaName": "和美镇",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721676,
                        "areaName": "花坛乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 721793,
                        "areaName": "鹿港镇",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 722111,
                        "areaName": "埤头乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 722223,
                        "areaName": "埔心乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 722366,
                        "areaName": "埔盐乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 722416,
                        "areaName": "伸港乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 722581,
                        "areaName": "社头乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 722726,
                        "areaName": "田尾乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 722823,
                        "areaName": "田中镇",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 722962,
                        "areaName": "线西乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 723042,
                        "areaName": "溪湖镇",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 723371,
                        "areaName": "秀水乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 723495,
                        "areaName": "溪州乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 723728,
                        "areaName": "永靖乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 723859,
                        "areaName": "员林市",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 724068,
                        "areaName": "竹塘乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 724152,
                        "areaName": "北斗镇",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 724269,
                        "areaName": "彰化市",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 724648,
                        "areaName": "大城乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 724755,
                        "areaName": "大村乡",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 724830,
                        "areaName": "二林镇",
                        "parent_id": 721023,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 725095,
                        "areaName": "二水乡",
                        "parent_id": 721023,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 725178,
                "areaName": "嘉义市",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 725179,
                        "areaName": "东区",
                        "parent_id": 725178,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 726119,
                        "areaName": "西区",
                        "parent_id": 725178,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 726816,
                "areaName": "嘉义县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 726817,
                        "areaName": "阿里山乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 726842,
                        "areaName": "布袋镇",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 726931,
                        "areaName": "大林镇",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727052,
                        "areaName": "大埔乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727098,
                        "areaName": "东石乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727151,
                        "areaName": "番路乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727249,
                        "areaName": "六脚乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727281,
                        "areaName": "鹿草乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727316,
                        "areaName": "梅山乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727443,
                        "areaName": "民雄乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727618,
                        "areaName": "朴子市",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727757,
                        "areaName": "水上乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727856,
                        "areaName": "太保市",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727952,
                        "areaName": "溪口乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 727994,
                        "areaName": "新港乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 728056,
                        "areaName": "义竹乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 728091,
                        "areaName": "中埔乡",
                        "parent_id": 726816,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 728242,
                        "areaName": "竹崎乡",
                        "parent_id": 726816,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 728432,
                "areaName": "新竹市",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 728433,
                        "areaName": "东区",
                        "parent_id": 728432,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 729373,
                        "areaName": "北区",
                        "parent_id": 728432,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 730018,
                "areaName": "新竹县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 730019,
                        "areaName": "峨眉乡",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 730056,
                        "areaName": "关西镇",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 730179,
                        "areaName": "横山乡",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 730234,
                        "areaName": "湖口乡",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 730583,
                        "areaName": "尖石乡",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 730628,
                        "areaName": "芎林乡",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 730680,
                        "areaName": "五峰乡",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 730701,
                        "areaName": "新丰乡",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 730796,
                        "areaName": "新埔镇",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 730909,
                        "areaName": "竹北市",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 731330,
                        "areaName": "竹东镇",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 731490,
                        "areaName": "宝山乡",
                        "parent_id": 730018,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 731653,
                        "areaName": "北埔乡",
                        "parent_id": 730018,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 731703,
                "areaName": "花莲县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 731704,
                        "areaName": "卓溪乡",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 731721,
                        "areaName": "丰滨乡",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 731758,
                        "areaName": "凤林镇",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 731893,
                        "areaName": "富里乡",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 731950,
                        "areaName": "光复乡",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 732011,
                        "areaName": "花莲市",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 732390,
                        "areaName": "吉安乡",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 732815,
                        "areaName": "瑞穗乡",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 732961,
                        "areaName": "寿丰乡",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 733119,
                        "areaName": "万荣乡",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 733128,
                        "areaName": "新城乡",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 733251,
                        "areaName": "秀林乡",
                        "parent_id": 731703,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 733297,
                        "areaName": "玉里镇",
                        "parent_id": 731703,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 733439,
                "areaName": "高雄市",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 733440,
                        "areaName": "阿莲区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 733480,
                        "areaName": "大寮区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 733848,
                        "areaName": "大社区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 733964,
                        "areaName": "大树区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 734059,
                        "areaName": "凤山区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 734739,
                        "areaName": "冈山区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 735021,
                        "areaName": "鼓山区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 735168,
                        "areaName": "湖内区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 735223,
                        "areaName": "甲仙区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 735265,
                        "areaName": "苓雅区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 735457,
                        "areaName": "林园区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 735596,
                        "areaName": "六龟区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 735651,
                        "areaName": "路竹区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 735758,
                        "areaName": "茂林区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 735762,
                        "areaName": "美浓区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 735879,
                        "areaName": "弥陀区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 736009,
                        "areaName": "那玛夏区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 736017,
                        "areaName": "楠梓区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 736373,
                        "areaName": "内门区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 736459,
                        "areaName": "鸟松区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 736668,
                        "areaName": "前金区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 736732,
                        "areaName": "前镇区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 737336,
                        "areaName": "桥头区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 737687,
                        "areaName": "茄萣区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 737769,
                        "areaName": "旗津区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 737821,
                        "areaName": "旗山区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 738030,
                        "areaName": "仁武区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 738415,
                        "areaName": "三民区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 738852,
                        "areaName": "杉林区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 738887,
                        "areaName": "桃源区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 738911,
                        "areaName": "田寮区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 738977,
                        "areaName": "小港区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 739398,
                        "areaName": "新兴区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 739503,
                        "areaName": "燕巢区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 739632,
                        "areaName": "盐埕区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 739678,
                        "areaName": "永安区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 739718,
                        "areaName": "梓官区",
                        "parent_id": 733439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 739810,
                        "areaName": "左营区",
                        "parent_id": 733439,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 740105,
                "areaName": "基隆市",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 740106,
                        "areaName": "安乐区",
                        "parent_id": 740105,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 740133,
                        "areaName": "暖暖区",
                        "parent_id": 740105,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 740151,
                        "areaName": "七堵区",
                        "parent_id": 740105,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 740222,
                        "areaName": "仁爱区",
                        "parent_id": 740105,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 740268,
                        "areaName": "信义区",
                        "parent_id": 740105,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 740472,
                        "areaName": "中山区",
                        "parent_id": 740105,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 740778,
                        "areaName": "中正区",
                        "parent_id": 740105,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 741123,
                "areaName": "金门县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 741124,
                        "areaName": "金城镇",
                        "parent_id": 741123,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741199,
                        "areaName": "金湖镇",
                        "parent_id": 741123,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741304,
                        "areaName": "金宁乡",
                        "parent_id": 741123,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741362,
                        "areaName": "金沙镇",
                        "parent_id": 741123,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741446,
                        "areaName": "烈屿乡",
                        "parent_id": 741123,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741480,
                        "areaName": "乌坵乡",
                        "parent_id": 741123,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 741482,
                "areaName": "连江县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 741483,
                        "areaName": "北竿乡",
                        "parent_id": 741482,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741490,
                        "areaName": "东引乡",
                        "parent_id": 741482,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741493,
                        "areaName": "莒光乡",
                        "parent_id": 741482,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741499,
                        "areaName": "南竿乡",
                        "parent_id": 741482,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 741509,
                "areaName": "苗栗县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 741510,
                        "areaName": "头屋乡",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741542,
                        "areaName": "西湖乡",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741596,
                        "areaName": "苑里镇",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741698,
                        "areaName": "造桥乡",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 741806,
                        "areaName": "竹南镇",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 742102,
                        "areaName": "卓兰镇",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 742176,
                        "areaName": "大湖乡",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 742267,
                        "areaName": "公馆乡",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 742343,
                        "areaName": "后龙镇",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 742530,
                        "areaName": "苗栗市",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 742898,
                        "areaName": "南庄乡",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 742950,
                        "areaName": "三湾乡",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 743017,
                        "areaName": "三义乡",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 743067,
                        "areaName": "狮潭乡",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 743113,
                        "areaName": "泰安乡",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 743135,
                        "areaName": "铜锣乡",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 743212,
                        "areaName": "通霄镇",
                        "parent_id": 741509,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 743290,
                        "areaName": "头份市",
                        "parent_id": 741509,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 743605,
                "areaName": "南海岛",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 743606,
                        "areaName": "东沙群岛",
                        "parent_id": 743605,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 743608,
                        "areaName": "南沙群岛",
                        "parent_id": 743605,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 743610,
                "areaName": "南投县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 743611,
                        "areaName": "草屯镇",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 743864,
                        "areaName": "国姓乡",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 743915,
                        "areaName": "集集镇",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 744008,
                        "areaName": "鹿谷乡",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 744081,
                        "areaName": "名间乡",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 744181,
                        "areaName": "南投市",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 744446,
                        "areaName": "埔里镇",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 744825,
                        "areaName": "仁爱乡",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 744896,
                        "areaName": "水里乡",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 745055,
                        "areaName": "信义乡",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 745092,
                        "areaName": "鱼池乡",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 745150,
                        "areaName": "中寮乡",
                        "parent_id": 743610,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 745184,
                        "areaName": "竹山镇",
                        "parent_id": 743610,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 745415,
                "areaName": "新北市",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 745416,
                        "areaName": "八里区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 745529,
                        "areaName": "板桥区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 745786,
                        "areaName": "贡寮区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 745839,
                        "areaName": "金山区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 745920,
                        "areaName": "林口区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 746079,
                        "areaName": "芦洲区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 746150,
                        "areaName": "坪林区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 746211,
                        "areaName": "平溪区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 746259,
                        "areaName": "瑞芳区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 746374,
                        "areaName": "三重区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 746670,
                        "areaName": "三峡区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 746763,
                        "areaName": "三芝区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 746864,
                        "areaName": "深坑区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 746907,
                        "areaName": "石碇区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 746999,
                        "areaName": "石门区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 747039,
                        "areaName": "双溪区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 747127,
                        "areaName": "树林区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 747266,
                        "areaName": "泰山区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 747382,
                        "areaName": "淡水区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 747615,
                        "areaName": "土城区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 747752,
                        "areaName": "万里区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 747831,
                        "areaName": "五股区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 747928,
                        "areaName": "乌来区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 747954,
                        "areaName": "新店区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 748210,
                        "areaName": "新庄区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 748478,
                        "areaName": "汐止区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 748653,
                        "areaName": "莺歌区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 748819,
                        "areaName": "永和区",
                        "parent_id": 745415,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 748933,
                        "areaName": "中和区",
                        "parent_id": 745415,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 749189,
                "areaName": "澎湖县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 749190,
                        "areaName": "白沙乡",
                        "parent_id": 749189,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749211,
                        "areaName": "湖西乡",
                        "parent_id": 749189,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749237,
                        "areaName": "马公市",
                        "parent_id": 749189,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749386,
                        "areaName": "七美乡",
                        "parent_id": 749189,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749411,
                        "areaName": "望安乡",
                        "parent_id": 749189,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749423,
                        "areaName": "西屿乡",
                        "parent_id": 749189,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 749439,
                "areaName": "屏东县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 749440,
                        "areaName": "三地门乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749463,
                        "areaName": "狮子乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749484,
                        "areaName": "泰武乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749507,
                        "areaName": "万丹乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749736,
                        "areaName": "万峦乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749801,
                        "areaName": "雾台乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749823,
                        "areaName": "新埤乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749874,
                        "areaName": "新园乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 749970,
                        "areaName": "盐埔乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 750069,
                        "areaName": "竹田乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 750156,
                        "areaName": "长治乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 750296,
                        "areaName": "潮州镇",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 750614,
                        "areaName": "车城乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 750656,
                        "areaName": "春日乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 750665,
                        "areaName": "东港镇",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 750811,
                        "areaName": "枋寮乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 750938,
                        "areaName": "枋山乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 750959,
                        "areaName": "高树乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751126,
                        "areaName": "恆春镇",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751251,
                        "areaName": "佳冬乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751345,
                        "areaName": "九如乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751429,
                        "areaName": "崁顶乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751477,
                        "areaName": "来义乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751494,
                        "areaName": "里港乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751561,
                        "areaName": "林边乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751613,
                        "areaName": "麟洛乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751682,
                        "areaName": "琉球乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751709,
                        "areaName": "玛家乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751722,
                        "areaName": "满州乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751774,
                        "areaName": "牡丹乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751788,
                        "areaName": "南州乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 751829,
                        "areaName": "内埔乡",
                        "parent_id": 749439,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 752152,
                        "areaName": "屏东市",
                        "parent_id": 749439,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 752851,
                "areaName": "台中市",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 752852,
                        "areaName": "梧栖区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 753039,
                        "areaName": "乌日区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 753229,
                        "areaName": "新社区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 753301,
                        "areaName": "西屯区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 753895,
                        "areaName": "北屯区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 754425,
                        "areaName": "中区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 754469,
                        "areaName": "大肚区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 754567,
                        "areaName": "大甲区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 754757,
                        "areaName": "大里区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 755138,
                        "areaName": "大雅区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 755302,
                        "areaName": "大安区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 755630,
                        "areaName": "东势区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 755794,
                        "areaName": "东区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 756734,
                        "areaName": "丰原区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 757010,
                        "areaName": "和平区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 757090,
                        "areaName": "后里区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 757242,
                        "areaName": "龙井区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 757383,
                        "areaName": "南屯区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 757790,
                        "areaName": "北区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 758435,
                        "areaName": "清水区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 758724,
                        "areaName": "沙鹿区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 759020,
                        "areaName": "神冈区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 759169,
                        "areaName": "石冈区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 759235,
                        "areaName": "南区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 759585,
                        "areaName": "太平区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 759962,
                        "areaName": "潭子区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 760138,
                        "areaName": "外埔区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 760220,
                        "areaName": "西区",
                        "parent_id": 752851,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 760917,
                        "areaName": "雾峰区",
                        "parent_id": 752851,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 761068,
                "areaName": "台南市",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 761069,
                        "areaName": "佳里区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 761168,
                        "areaName": "将军区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 761200,
                        "areaName": "六甲区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 761259,
                        "areaName": "柳营区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 761375,
                        "areaName": "龙崎区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 761421,
                        "areaName": "麻豆区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 761507,
                        "areaName": "南化区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 761518,
                        "areaName": "楠西区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 761553,
                        "areaName": "北区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 762198,
                        "areaName": "七股区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 762236,
                        "areaName": "仁德区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 762437,
                        "areaName": "善化区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 762573,
                        "areaName": "山上区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 762589,
                        "areaName": "南区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 762939,
                        "areaName": "中西区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 763149,
                        "areaName": "下营区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 763237,
                        "areaName": "西港区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 763273,
                        "areaName": "新化区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 763323,
                        "areaName": "新市区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 763427,
                        "areaName": "新营区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 763628,
                        "areaName": "学甲区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 763710,
                        "areaName": "盐水区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 763793,
                        "areaName": "永康区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 764145,
                        "areaName": "玉井区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 764201,
                        "areaName": "左镇区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 764212,
                        "areaName": "安定区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 764238,
                        "areaName": "安南区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 764579,
                        "areaName": "安平区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 764707,
                        "areaName": "白河区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 764858,
                        "areaName": "北门区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 764876,
                        "areaName": "大内区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 764897,
                        "areaName": "东山区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 764981,
                        "areaName": "东区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 765921,
                        "areaName": "关庙区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 766056,
                        "areaName": "官田区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 766125,
                        "areaName": "归仁区",
                        "parent_id": 761068,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 766413,
                        "areaName": "后壁区",
                        "parent_id": 761068,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 766445,
                "areaName": "台北市",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 766446,
                        "areaName": "北投区",
                        "parent_id": 766445,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 766612,
                        "areaName": "大同区",
                        "parent_id": 766445,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 766785,
                        "areaName": "大安区",
                        "parent_id": 766445,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 767113,
                        "areaName": "南港区",
                        "parent_id": 766445,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 767217,
                        "areaName": "内湖区",
                        "parent_id": 766445,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 767337,
                        "areaName": "士林区",
                        "parent_id": 766445,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 767547,
                        "areaName": "松山区",
                        "parent_id": 766445,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 767710,
                        "areaName": "万华区",
                        "parent_id": 766445,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 767917,
                        "areaName": "文山区",
                        "parent_id": 766445,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 768070,
                        "areaName": "信义区",
                        "parent_id": 766445,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 768274,
                        "areaName": "中山区",
                        "parent_id": 766445,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 768580,
                        "areaName": "中正区",
                        "parent_id": 766445,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 768925,
                "areaName": "台东县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 768926,
                        "areaName": "卑南乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 768991,
                        "areaName": "长滨乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769030,
                        "areaName": "成功镇",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769104,
                        "areaName": "池上乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769193,
                        "areaName": "达仁乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769216,
                        "areaName": "大武乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769265,
                        "areaName": "东河乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769305,
                        "areaName": "关山镇",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769381,
                        "areaName": "海端乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769406,
                        "areaName": "金峰乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769413,
                        "areaName": "兰屿乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769420,
                        "areaName": "绿岛乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769435,
                        "areaName": "鹿野乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769507,
                        "areaName": "太麻里乡",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769562,
                        "areaName": "台东市",
                        "parent_id": 768925,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 769829,
                        "areaName": "延平乡",
                        "parent_id": 768925,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 769840,
                "areaName": "桃园市",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 769841,
                        "areaName": "八德区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 770209,
                        "areaName": "大溪区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 770468,
                        "areaName": "大园区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 770764,
                        "areaName": "复兴区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 770832,
                        "areaName": "观音区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 771076,
                        "areaName": "龟山区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 771466,
                        "areaName": "龙潭区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 771797,
                        "areaName": "芦竹区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 772141,
                        "areaName": "平镇区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 772546,
                        "areaName": "桃园区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 773173,
                        "areaName": "新屋区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 773320,
                        "areaName": "杨梅区",
                        "parent_id": 769840,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 773659,
                        "areaName": "中坜区",
                        "parent_id": 769840,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 774788,
                "areaName": "宜兰县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 774789,
                        "areaName": "大同乡",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 774855,
                        "areaName": "钓鱼台",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 774857,
                        "areaName": "冬山乡",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 775188,
                        "areaName": "礁溪乡",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 775290,
                        "areaName": "罗东镇",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 775451,
                        "areaName": "南澳乡",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 775474,
                        "areaName": "三星乡",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 775713,
                        "areaName": "苏澳镇",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 775928,
                        "areaName": "头城镇",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 776028,
                        "areaName": "五结乡",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 776229,
                        "areaName": "宜兰市",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 776460,
                        "areaName": "员山乡",
                        "parent_id": 774788,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 776579,
                        "areaName": "壮围乡",
                        "parent_id": 774788,
                        "level": 2
                    }
                ]
            },
            {
                "areaCode": 0,
                "areaId": 776660,
                "areaName": "云林县",
                "parent_id": 721022,
                "level": 1,
                "children": [
                    {
                        "areaCode": 0,
                        "areaId": 776661,
                        "areaName": "褒忠乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 776694,
                        "areaName": "北港镇",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 776873,
                        "areaName": "莿桐乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 776921,
                        "areaName": "大埤乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 776994,
                        "areaName": "东势乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 777066,
                        "areaName": "斗六市",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 777498,
                        "areaName": "斗南镇",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 777712,
                        "areaName": "二崙乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 777774,
                        "areaName": "古坑乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 777850,
                        "areaName": "虎尾镇",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 778104,
                        "areaName": "口湖乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 778197,
                        "areaName": "林内乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 778259,
                        "areaName": "崙背乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 778378,
                        "areaName": "麦寮乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 778468,
                        "areaName": "水林乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 778536,
                        "areaName": "四湖乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 778611,
                        "areaName": "台西乡",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 778660,
                        "areaName": "土库镇",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 778738,
                        "areaName": "西螺镇",
                        "parent_id": 776660,
                        "level": 2
                    },
                    {
                        "areaCode": 0,
                        "areaId": 778853,
                        "areaName": "元长乡",
                        "parent_id": 776660,
                        "level": 2
                    }
                ]
            }
        ]
    }
]
