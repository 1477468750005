<template>
    <div class="DM_upsideOrder">
        <!-- 倒挂订单审核 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="upsideOrderForm" class="demo-form-inline">
                <el-form-item>
                    <el-select size v-model="upsideOrderForm.type" clearable placeholder="请选择挂账类型">
                        <el-option label="交易分润" value="1"></el-option>
                        <el-option label="激活奖励" value="2"></el-option>
                        <el-option label="交易阶梯" value="3"></el-option>
                        <el-option label="交易达标" value="4"></el-option>
                        <el-option label="服务费返现" value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="挂账开始日期"
                        end-placeholder="挂账结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="upsideOrderForm.terminal_sn" placeholder="请输入SN码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(upsideOrderForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click>导出数据</el-button>
            </div>-->
            <el-table
                :data="ladderLogTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 代理商姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="user_name"
                    label="代理商姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 挂账类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="type_name"
                    label="挂账类型"
                    min-width="120"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="150"
                ></el-table-column>
                <!-- SN码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_sn"
                    label="SN码"
                    min-width="120"
                ></el-table-column>
                <!-- 挂账时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="挂账时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleAudit(scope.row)" type="text" size="small">审核</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <upside-info
            v-if="infoIsShow"
            :infoIsShow="infoIsShow"
            :detail="detail"
            @infoClose="infoClose"
        ></upside-info>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import upsideInfo from './DM_upsideInfo';
export default {
    name: "DM_upsideOrder",
    data() {
        return {
            infoIsShow: false, //弹窗
            detail: {}, //当前行
            rangeDate: '', //时间范围
            upsideOrderForm: {
                type: '',  //终端类型id
                terminal_sn: '', //终端SN
                start_time: '', //挂账开始日期
                end_time: '', //挂账结束日期
                page: 1,
                limit: 10,

            },
            ladderLogTable: [],
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true
        }
    },
    mounted() {
        this.getData( //初始化
            {
                page: 1,
                limit: this.page.pageSize
            }
        );
    },
    components: {
        paginations,
        upsideInfo
    },
    methods: {
        onSearch(upsideOrderForm) {
            this.upsideOrderForm.page = 1; //重置
            // 表单提交
            this.getData(upsideOrderForm);
        },
        handleAudit(row) { //审核
            this.infoIsShow = true;
            this.detail = row;
        },
        infoClose(ref) { //关闭弹窗
            this.infoIsShow = false;
            if (ref) {
                this.getData(this.upsideOrderForm);
            }
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.upsideOrderForm.page = this.page.currentPage;
            this.upsideOrderForm.limit = this.page.pageSize;
            this.getData(this.upsideOrderForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.upsideOrderForm.page = this.page.currentPage;
            this.upsideOrderForm.limit = limit;
            this.getData(this.upsideOrderForm);
        },
        getData(params) {
            this.$api.post("upside_down/upside_down_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.ladderLogTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        pickerChange(DateArr) { //时间选择器
            this.upsideOrderForm.start_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.upsideOrderForm.end_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
};
</script>

<style lang="scss" scope>
.DM_upsideOrder {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
