<template>
    <div class="HC_classInfo">
        <el-dialog
            :title="info.type == 1? '添加分类':'编辑分类'"
            :visible="info.isShow"
            width="500px"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <!-- 表单搜索部分 -->
            <el-card class="box-card" shadow="always">
                <el-form
                    :model="infoForm"
                    class="demo-form-inline"
                    label-width="80px"
                    label-position="left"
                >
                    <el-form-item label="分类名称：">
                        <el-input size clearable v-model="infoForm.name" placeholder="请输入分类名称" maxlength="8"></el-input>
                    </el-form-item>
                    <el-form-item label="排序：">
                        <el-input size clearable v-model="infoForm.sort" placeholder="请输入排序"></el-input>
                    </el-form-item>
                    <el-form-item label="状态：">
                        <el-radio v-model="infoForm.status" :label="1">显示</el-radio>
                        <el-radio v-model="infoForm.status" :label="0">隐藏</el-radio>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            style="font-size: 12px;"
                            :loading="loading"
                            @click="onSubmit(infoForm)"
                        >提 交</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "HC_classInfo",
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            infoForm: {
                name: '',
                sort: '',
                status: 1
            },
            loading: false
        }
    },
    mounted() {
        if (this.info.type == 2) {
            this.infoForm = this._.omit(this.info.detail, ['count']);
        }
    },
    methods: {
        onSubmit(infoForm) { //提交
            if (this.info.type == 1) {
                this.sendParams('help/category_add', infoForm); //添加
            } else {
                this.sendParams('help/category_edit', infoForm);//编辑
            }
        },
        handleClose(ref) { //关闭
            this.$emit('infoClose', ref)
        },
        sendParams(url, params) { //请求
            let that = this;
            this.loading = true;
            this.$api.post(url, params, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.handleClose(1);
                        }
                    })
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            })
        }
    }
};
</script>

<style lang="scss" scope>
.HC_classInfo {
    .el-form-item__label {
        font-size: 12px;
        font-weight: 700;
    }
    .el-input__inner {
        font-size: 12px;
    }
    .el-radio__label {
        font-size: 12px;
    }
    .el-switch__label *,
    .el-button--text {
        font-size: 12px;
    }
}
</style>
