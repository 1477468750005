import http from '../api/index';
// import { resolve } from '_any-promise@1.3.0@any-promise';

const getBrandList = (callback) => { //获取终端类型
    http.post('brand/brand_list', {}, res => {
        if (res.code == 0) {
            callback(res);
        }
    });
}

export default {
    getBrandList
}