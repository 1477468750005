<template>
    <div class="BS_accountInfo">
        <el-dialog
            ref="accountForm"
            :visible="infoIsShow"
            @close="handleClose"
            width="600px"
            :close-on-click-modal="false"
            title="密码修改"
        >
            <el-card class="box-card" shadow="hover">
                <el-form :model="accountForm" label-position="right" label-width="80px">
                    <el-form-item label="支付平台：">
                        <el-input
                            size="medium"
                            v-model="accountForm.account_organization_name"
                            disabled
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="账号：">
                        <el-input size="medium" clearable v-model="accountForm.account" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="密码：">
                        <el-input
                            size="medium"
                            clearable
                            v-model="accountForm.password"
                            placeholder="请输入新密码"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="账号状态：">
                        <el-input size="medium" v-model="accountForm.account_status_name" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="推送状态：">
                        <el-input size="medium" v-model="accountForm.status_name" disabled></el-input>
                    </el-form-item>
                </el-form>
                <div style="margin-top:20px">
                    <el-button
                        type="primary"
                        :loading="loading"
                        style="font-size: 12px;"
                        @click="editPassword(accountForm)"
                    >提交修改</el-button>
                </div>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "BS_accountInfo",
    props: {
        infoIsShow: {
            type: Boolean,
            default: false
        },
        detail: {
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            accountForm: {},
        }
    },
    mounted() {
        this.getInfo();//初始化
    },
    methods: {
        editPassword(accountForm) { //添加代理
            let that = this;
            this.loading = true; //防重复
            let reqData = this._.pick(accountForm, ['account_id', 'password']);
            this.$api.post('setting/pay_account_update', reqData, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.handleClose(1); //刷新父页面
                        }
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.loading = false; //防重复
                        }
                    })
                }
            })
        },
        handleClose(ref) {
            this.$emit('infoClose', ref);
        },
        getInfo() {
            this.$api.post('setting/pay_account_info', { account_id: this.detail.account_id }, res => {
                if (res.code == 0) {
                    this.accountForm = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.BS_accountInfo {
}
</style>
