<template>
    <div class="FM_purseList">
        <!-- 代理商钱包 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="purseForm" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="purseForm.phone" placeholder="请输入代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="purseForm.real_name" placeholder="请输入代理商真实姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(purseForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    :loading="downLoading"
                    @click="handleDownLoad"
                >导出数据</el-button>
            </div>
            <el-table :data="purseTable" size="mini" border style="width: 100%" v-loading="loading">
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="代理商名称"
                    min-width="200"
                ></el-table-column>
                <!-- 代理商账户 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账户"
                    min-width="120"
                ></el-table-column>
                <!-- 累计提现金额 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="累计提现金额"
                    min-width="120"
                ></el-table-column>-->
                <!-- 累计收益 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="累计收益"
                    min-width="150"
                ></el-table-column>-->
                <!-- 钱包当前余额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="balance_total"
                    label="钱包余额"
                    min-width="120"
                ></el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="balance"
                    label="可提现金额"
                    min-width="120"
                ></el-table-column>
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="freeze_wallet"
                    label="冻结金额"
                    min-width="120"
                ></el-table-column> -->
                <!-- 个人奖励钱包 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="reward_wallet"
                    label="个人奖励钱包"
                    min-width="100"
                ></el-table-column>-->
                <!-- 欠上级钱包 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="arrears_money"
                    label="欠上级钱包"
                    min-width="150"
                ></el-table-column>
                <!-- 下级欠我钱包 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="loan_wallet"
                    label="下级欠我钱包"
                    min-width="200"
                ></el-table-column>
                <!-- 延期入账钱包 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="undone_wallet"
                    label="延期入账钱包"
                    min-width="200"
                ></el-table-column>
                <!-- 剩余扣款钱包 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="debt_wallet"
                    label="剩余扣款钱包"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleRemaining(scope.row)"
                            type="text"
                            size="small"
                        >{{scope.row.debt_wallet}}</el-button>
                    </template>
                </el-table-column>
                <!-- 冻结钱包 -->
                <el-table-column
                    v-if="repayment_type == 1"
                    header-align="center"
                    align="center"
                    prop="freeze_wallet"
                    label="冻结钱包"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleFreezeWallet(scope.row)"
                            type="text"
                            size="small"
                        >{{scope.row.freeze_wallet}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <purse-info
            v-if="infoIsShow"
            :infoIsShow="infoIsShow"
            :detail="detail"
            @infoClose="infoClose"
        ></purse-info>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import purseInfo from './FM_purseInfo';
export default {
    name: "FM_purseList",
    data() {
        return {
            repayment_type: null,
            infoIsShow: false,
            detail: {
                infoType: null, //详情类型 1.冻结 2.剩余扣款
                data: {}
            },
            purseTable: [], //表格
            purseForm: {
                phone: '', //代理商账号
                real_name: '',   //代理商姓名
                page: 1,
                limit: 10
            },
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true,
            downLoading: false //导出loading
        }
    },
    mounted() {
        this.repayment_type = sessionStorage.getItem('repayment_type');
        this.getData( //初始化
            {
                page: 1,
                limit: this.page.pageSize
            }
        );
    },
    components: {
        paginations,
        purseInfo
    },
    methods: {
        onSearch(purseForm) {
            this.purseForm.page = 1;
            // 表单提交
            this.getData(purseForm);
        },
        handleRemaining(row) { //剩余钱包
            this.infoIsShow = true;
            this.detail.infoType = 2;
            this.detail.data = row;
            this.detail.data.repayment_type = this.repayment_type;
        },
        handleFreezeWallet(row) { //冻结钱包
            this.infoIsShow = true;
            this.detail.infoType = 1;
            this.detail.data = row;
        },
        infoClose() { //关闭弹窗
            this.infoIsShow = false;
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.purseForm.page = this.page.currentPage;
            this.purseForm.limit = this.page.pageSize;
            this.getData(this.purseForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.purseForm.page = this.page.currentPage;
            this.purseForm.limit = limit;
            this.getData(this.purseForm);
        },
        getData(params) {
            this.$api.post("user/balance_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.purseTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        handleDownLoad() { //导出
            let handleExportExcel = this.common.handleExportExcel;
            this.downLoading = true;
            let searchVal = this._.omit(this.purseForm, ['page', 'limit']);
            let value = {};
            this._.forEach(searchVal, (item, index) => {
                if (item != '') {
                    value[index] = item;
                }
            });
            handleExportExcel({ type: 9, value: JSON.stringify(value) }).then(() => {
                this.downLoading = false;
            });
        }
    }
};
</script>

<style lang="scss" scope>
.FM_purseList {
    .realTitle {
        :nth-child(1) {
            font-size: 12px;
            font-weight: 700;
            color: #1e1e1e;
        }
        .imgParent {
            margin: 10px 0;
            // width: 500px;
            overflow: hidden;
            img {
                width: 100%;
                height: 250px;
                border-radius: 10px;
            }
        }
    }
    // .el-dialog__header {
    //     padding: 20px 20px 0px;
    // }
    // .el-dialog__body {
    //     padding: 0px 20px 30px 20px;
    // }
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
    .el-dialog {
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /*height:600px;*/
        max-height: calc(100% - 30px);
        max-width: calc(100% - 30px);
    }
    .el-dialog .el-dialog__body {
        flex: 1;
        overflow: auto;
    }
}
</style>
