<template>
    <div class="DM_attainRecord">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-select
                        v-model="searchForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.terminal_sn" placeholder="请输入终端SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.phone" placeholder="请输入归属代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.name" placeholder="请输入归属代理商名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="达标开始日期"
                        end-placeholder="达标结束日期"
                        value-format="yyyy-MM-dd"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    :loading="downLoading"
                    @click="handleDownLoad"
                >导出数据</el-button>
            </div>
            <el-table :data="recordList" size="mini" border style="width: 100%" v-loading="loading">
                <!-- ID -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="120"
                ></el-table-column>
                <!-- 终端SN -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_sn"
                    label="终端SN"
                    min-width="120"
                ></el-table-column>
                <!-- 终端归属代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="name"
                    label="终端归属代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 终端归属代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="终端归属代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 达标规则 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rule"
                    label="达标规则"
                    min-width="120"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 达标时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="award_time"
                    label="达标时间"
                    min-width="150"
                ></el-table-column>
                <!-- 达标奖励金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="reward_money"
                    label="达标奖励金额"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <record-info
            v-if="infoIsShow"
            :infoIsShow="infoIsShow"
            :detail="detail"
            @infoClose="handleInfoClose"
        ></record-info>
    </div>
</template>
<script>
import recordInfo from './DM_attainRecordInfo';
export default {
    name: "DM_attainRecord",
    data() {
        return {
            infoIsShow: false,
            detail: {},
            searchForm: {
                page: 1,
                limit: 10,
                brand_id: '',
                terminal_sn: '',
                name: '',
                phone: '',
                start_time: '',
                end_time: ''
            },
            rangeDate: '',
            brandList: [],
            recordList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true,
            downLoading: false //导出loading
        }
    },
    components: {
        recordInfo
    },
    mounted() {
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$$message.success(res.msg);
            }
        });
        this.getData({
            page: 1,
            limit: 10
        })
    },
    methods: {
        onSearch(searchForm) {
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("transaction/standard_award", params, res => {
                let data = res.data;
                this.total = data.count; //总数
                this.recordList = res.data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        handleInfo(row) { //详情
            this.infoIsShow = true;
            this.detail = row;
        },
        handleInfoClose() { //关闭详情
            this.infoIsShow = false;
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        pickerChange(DateArr) { //时间选择器
            this.searchForm.start_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.searchForm.end_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        },
        handleDownLoad() { //导出
            let handleExportExcel = this.common.handleExportExcel;
            this.downLoading = true;
            let searchVal = this._.omit(this.searchForm, ['page', 'limit']);
            let value = {};
            this._.forEach(searchVal, (item, index) => {
                if (item != '') {
                    value[index] = item;
                }
            });
            handleExportExcel({ type: 4, value: JSON.stringify(value) }).then(() => {
                this.downLoading = false;
            });
        },
    }
}
</script>

<style scoped lang="scss">
.DM_attainRecord {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>