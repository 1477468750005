var validBusLicense = (rule, value, callback) => {
    if (value) {
        callback();
    } else {
        return callback(new Error('请上传营业执照'));
    }
};
var validOpeningPermit = (rule, value, callback) => {
    if (value) {
        callback();
    } else {
        return callback(new Error('请上传开户许可证'));
    }
};
var validCardPositive = (rule, value, callback) => {
    if (value) {
        callback();
    } else {
        return callback(new Error('请上传身份证正面照'));
    }
};
var validCardNegative = (rule, value, callback) => {
    if (value) {
        callback();
    } else {
        return callback(new Error('请上传身份证反面照'));
    }
};
export default { // 验证
    role_id: [
        { required: true, message: '请选择代理商角色', trigger: 'change' },
    ],
    agent_name: [
        { required: true, message: '请填写代理商名称', trigger: 'blur' },
    ],
    credit_code: [
        { required: true, message: '请填写统一社会信用代码', trigger: 'blur' },
    ],
    // bus_license_valid_sdate: [
    //     { required: true, message: '请选择起始日期', trigger: 'change' },
    // ],
    // bus_license_valid_edate: [
    //     { required: true, message: '请选择结束日期', trigger: 'change' },
    // ],
    bus_license_valid_sdate: [
        { required: true, message: '请选择营业执照有效期', trigger: 'blur' },
    ],
    legal_person: [
        { required: true, message: '请填写法人姓名', trigger: 'blur' },
    ],
    legal_tel: [
        { required: true, message: '请填写法人手机号', trigger: 'blur' },
    ],
    card_type: [
        { required: true, message: '请选择法人证件类型', trigger: 'change' },
    ],
    id_card: [
        { required: true, message: '请填写证件号', trigger: 'blur' },
    ],
    // card_valid_sdate: [
    //     { required: true, message: '请选择起始日期', trigger: 'change' },
    // ],
    // card_valid_edate: [
    //     { required: true, message: '请选择结束日期', trigger: 'change' },
    // ],
    card_valid_sdate: [
        { required: true, message: '请选择身份证有效期', trigger: 'blur' },
    ],
    contact_name: [
        { required: true, message: '请填写联系人', trigger: 'blur' },
    ],
    contact_tel: [
        { required: true, message: '请填写联系电话', trigger: 'blur' },
    ],
    contact_email: [
        { required: true, message: '请填写联系邮箱', trigger: 'blur' },
    ],
    contact_addr: [
        { required: true, message: '请填写联系地址', trigger: 'blur' },
    ],
    account_name: [
        { required: true, message: '请填写银行账户名称', trigger: 'change' },
    ],
    account_type: [
        { required: true, message: '请选择账户类型', trigger: 'change' },
    ],
    bank_id: [
        { required: true, message: '请选择银行名称', trigger: 'change' },
    ],
    bank_prov: [
        { required: true, message: '请选择银行归属地', trigger: 'change' },
    ],
    branch_bank_id: [
        { required: true, message: '请填写支行名称', trigger: 'change' },
    ],
    line_num: [
        { required: true, message: '请填写联行号', trigger: 'change' },
    ],
    bank_code: [
        { required: true, message: '请填写银行账号', trigger: 'blur' },
    ],
    bus_license_img: [
        { required: true, validator: validBusLicense, trigger: 'change' },
    ],
    opening_permit_img: [
        { required: true, validator: validOpeningPermit, trigger: 'change' },
    ],
    card_positive_img: [
        { required: true, validator: validCardPositive, trigger: 'change' },
    ],
    card_negative_img: [
        { required: true, validator: validCardNegative, trigger: 'change' },
    ],
    username: [
        { required: true, message: '请填写后台登录账号', trigger: 'blur' },
    ],
    app_phone: [
        { required: true, message: '请填写APP登录账号', trigger: 'blur' },
    ],
    check_code: [
        { required: true, message: '请填写验证码', trigger: 'blur' },
    ]
}