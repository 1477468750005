/** 财务管理 */
import userWithdrawal from '../../views/financialManagement/userWithdrawal/FM_withdrawalList'; //代理商提现
import userPurse from '../../views/financialManagement/usersPurse/FM_purseList'; //代理商钱包
import userFinancial from '../../views/financialManagement/usersOfFinancial/FM_financial'; //代理商财务记录
import userAlipayRecord from '../../views/financialManagement/UserAlipay/FM_alipayList'; //代理商支付宝支付记录
import platformAccount from '../../views/financialManagement/platformAccount/FM_accountList'; //平台账户
import moneyStatistics from '../../views/financialManagement/moneyStatistics'; //平台分润支出统计

export default [
    {
        path: '/userWithdrawal',
        name: 'userWithdrawal',
        meta: {
            title: '代理商提现'
        },
        component: userWithdrawal
    },
    {
        path: '/userPurse',
        name: 'userPurse',
        meta: {
            title: '代理商钱包'
        },
        component: userPurse
    },
    {
        path: '/userFinancial',
        name: 'userFinancial',
        meta: {
            title: '代理商财务记录'
        },
        component: userFinancial
    },
    {
        path: '/userAlipayRecord',
        name: 'userAlipayRecord',
        meta: {
            title: '代理商支付宝支付记录'
        },
        component: userAlipayRecord
    },
    {
        path: '/platformAccount',
        name: 'platformAccount',
        meta: {
            title: '平台账户'
        },
        component: platformAccount
    },
    {
        path: '/moneyStatistics',
        name: 'moneyStatistics',
        meta: {
            title: '平台支出统计'
        },
        component: moneyStatistics
    }
]