/** 机构代理商管理 */
import agentList from '../../views/agentManagement/agentList'; // 机构代理商列表
import addAgent from '../../views/agentManagement/components/addAgent'; // 添加机构代理商
import agentDetail from '../../views/agentManagement/components/agentDetail'; // 机构代理商详情
import agentPolicyConfig from '../../views/agentManagement/components/agentPolicyConfig'; // 机构代理商政策配置
import agentCashbackList from '../../views/agentManagement/agentCashbackList'; // 机构代理商返现记录

export default [
    {
        path: '/agentList',
        name: 'agentList',
        meta: {
            title: '机构代理商列表'
        },
        component: agentList
    },
    {
        path: '/addAgent',
        name: 'addAgent',
        meta: {
            title: '添加签约代理'
        },
        component: addAgent
    },
    {
        path: '/agentDetail/:id',
        name: 'agentDetail',
        meta: {
            title: '机构代理商详情'
        },
        component: agentDetail
    },
    {
        path: '/agentPolicyConfig/:id',
        name: 'agentPolicyConfig',
        meta: {
            title: '机构代理商政策配置'
        },
        component: agentPolicyConfig
    },
    {
        path: '/agentCashbackList',
        name: 'agentCashbackList',
        meta: {
            title: '机构代理商返现记录'
        },
        component: agentCashbackList
    }
]