<template>
    <div class="DM_activationList">
        <!-- 激活记录查询 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="activationForm" class="demo-form-inline">
                <el-form-item>
                    <el-select
                        size="medium"
                        v-model="activationForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="activationForm.terminal_sn" placeholder="请输入SN码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="activationForm.user_num" placeholder="请输入归属代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="activationForm.user_name" placeholder="请输入归属代理商名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="activationForm.merchant_num" placeholder="请输入商户编号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        size
                        v-model="activationForm.active_type"
                        clearable
                        placeholder="请选择激活方式"
                    >
                        <el-option label="交易量激活" value="1"></el-option>
                        <el-option label="正常激活" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="激活奖励开始日期"
                        end-placeholder="激活奖励结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(activationForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    :loading="downLoading"
                    @click="handleDownLoad"
                >导出数据</el-button>
            </div>
            <el-table
                :data="activationTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="120"
                ></el-table-column>
                <!-- SN码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_sn"
                    label="终端SN"
                    min-width="120"
                ></el-table-column>
                <!-- 激活方式 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="active_method"
                    label="激活方式"
                    :formatter="formatActiveMethod"
                    min-width="120"
                ></el-table-column>
                <!-- 商户编号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="merchant_number"
                    label="商户编号"
                    min-width="150"
                ></el-table-column>
                <!-- 商户名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="merchant_nickname"
                    label="商户名称"
                    min-width="120"
                ></el-table-column>
                <!-- 终端归属代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="user_name"
                    label="终端归属代理商名称"
                    min-width="150"
                ></el-table-column>
                <!-- 终端归属代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="user_phone"
                    label="终端归属代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 激活奖励时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="激活奖励时间"
                    min-width="150"
                ></el-table-column>
                <!-- 激活奖励总金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="reward_money"
                    label="激活奖励总金额"
                    min-width="120"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <activation-info
            v-if="infoIsShow"
            :infoIsShow="infoIsShow"
            :detail="detail"
            @infoClose="infoClose"
        ></activation-info>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import activationInfo from './DM_activationInfo';
export default {
    name: "DM_activationList",
    data() {
        return {
            infoIsShow: false, //弹窗
            detail: {}, //当前行
            brandList: [], //终端类型列表
            rangeDate: '', //时间范围
            activationForm: {
                brand_id: '',  //终端类型名称
                terminal_sn: '', //终端SN
                user_name: '', //归属代理商名称
                user_num: '', //归属代理商编号
                merchant_num: '', //商户编号
                active_type: '', //激活方式
                end_time: '', //达标结束日期
                start_time: '', //达标开始日期
                page: 1,
                limit: 10,

            },
            activationTable: [], //列表
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true,
            downLoading: false //导出loading
        }
    },
    mounted() {
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$message.success(res.msg);
            }
        });
        this.getData( //初始化
            {
                page: 1,
                limit: this.page.pageSize
            }
        );
    },
    components: {
        paginations,
        activationInfo
    },
    methods: {
        onSearch(activationForm) {
            this.activationForm.page = 1; //重置
            // 表单提交
            this.getData(activationForm);
        },
        handleInfo(row) { //查看
            this.infoIsShow = true;
            this.detail = row;
        },
        infoClose() { //关闭弹窗
            this.infoIsShow = false;
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.activationForm.page = this.page.currentPage;
            this.activationForm.limit = this.page.pageSize;
            this.getData(this.activationForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.activationForm.page = this.page.currentPage;
            this.activationForm.limit = limit;
            this.getData(this.activationForm);
        },
        getData(params) {
            this.$api.post("dataManagement/terminalActiveList", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.activationTable = data.active_list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        pickerChange(DateArr) { //时间选择器
            this.activationForm.start_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.activationForm.end_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        },
        formatActiveMethod(row) { //格式化激活方式
            let activeMethod = '';
            switch (row.active_method) {
                case 1:
                    activeMethod = '交易量激活'
                    break;
                default:
                    activeMethod = '正常激活'
                    break;
            }
            return activeMethod;
        },
        handleDownLoad() { //导出
            let handleExportExcel = this.common.handleExportExcel;
            this.downLoading = true;
            let searchVal = this._.omit(this.activationForm, ['page', 'limit']);
            let value = {};
            this._.forEach(searchVal, (item, index) => {
                if (item != '') {
                    value[index] = item;
                }
            });
            handleExportExcel({ type: 3, value: JSON.stringify(value) }).then(() => {
                this.downLoading = false;
            });
        },
    }
};
</script>

<style lang="scss" scope>
.DM_activationList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
