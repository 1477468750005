<template>
    <div class="UM_terminalCount">
        <el-dialog
            :title="detail.recordType == 1? '抵消终端SN' : '终端明细'"
            width="550px"
            :visible="terminalIsShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-table
                :data="terminalList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="id"
                    label="序号"
                    min-width="120"
                ></el-table-column>
                <!-- sn -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="sn"
                    label="SN"
                    min-width="120"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                small
                layout="prev, pager, next"
                @current-change="getCurrent"
                :total="page.total"
            ></el-pagination>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "UM_terminalCount",
    props: {
        terminalIsShow: {
            type: Boolean,
            default: false
        },
        detail: {
            type: Object
        }
    },
    data() {
        return {
            terminalList: [],
            loading: false,
            url: this.detail.recordType == 1 ? 'user_offset_log/offset_log_terminal' : 'terminal_transfer/get_log_terminal',
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: Number(this.detail.count)  //总数
            },
        };
    },
    mounted() {
        if (this.detail.recordType == 1) { //抵消记录数量
            this.getData(this.url, {
                page: 1,
                limit: this.page.pageSize,
                id: this.detail.id
            });
        } else { //贷款记录数量
            this.getData(this.url, {
                page: 1,
                limit: this.page.pageSize,
                transfer_log_id: this.detail.id
            });
        }
    },
    methods: {
        handleClose() { //关闭弹窗
            this.$emit('terminalClose');
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.loading = true;
            if (this.detail.recordType == 1) {
                this.getData(this.url, {
                    page: this.page.currentPage,
                    limit: this.page.pageSize,
                    id: this.detail.id,
                });
            } else {
                this.getData(this.url, {
                    page: this.page.currentPage,
                    limit: this.page.pageSize,
                    transfer_log_id: this.detail.id,
                });
            }
        },
        getData(url, params) {
            this.$api.post(url, params, res => {
                let data = res.data;
                if (this.detail.recordType == 1) {
                    this.terminalList = data; //列表
                } else {
                    this.terminalList = data.list;
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style scoped lang="scss">
.UM_terminalCount {
    font-size: 12px;
    .el-input__inner,
    .tips {
        font-size: 12px;
    }

    .el-form-item {
        margin-bottom: 5px;
    }

    ::v-deep .el-form-item__label {
        padding: 0;
        font-size: 12px;
    }
    .titleTip {
        padding: 10px 10px;
        background-color: #ecf8ff;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #50bfff;
        margin: 10px 0;
    }
    .title p {
        font-size: 12px;
        :first-child {
            font-weight: 700;
        }
    }
}
</style>