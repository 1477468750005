<template>
    <div class="MAM_riskControlInfo">
        <el-dialog
            :title="info.type==1? '添加规则':'编辑规则'"
            width="1000px"
            :visible="info.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-card class="box-card" shadow="always">
                <!-- 表单部分 -->
                <el-form label-position="top" label-width="80px" :model="infoForm">
                    <el-form-item label="终端类型：">
                        <el-select
                            :disabled="info.type == 2"
                            v-model="infoForm.brand_id"
                            filterable
                            clearable
                            style="width:100%"
                            placeholder="请选择终端类型"
                            @change="handleBrandChange"
                        >
                            <el-option
                                v-for="(item,index) in brandList"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="规则类型：">
                        <el-select
                            :disabled="info.type == 2"
                            v-model="infoForm.type"
                            style="width:100%"
                            clearable
                            placeholder="请选择规则类型"
                            @change="handleRuleChange"
                        >
                            <el-option
                                v-for="(item,index) in ruleList"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 交易量未达标 -->
                    <template v-if="infoForm.type == 1">
                        <!-- 奖励规则 -->
                        <el-form :inline="true">
                            <div style="margin-bottom:10px">规则配置：</div>
                            <el-form-item>
                                <el-select
                                    :disabled="info.type == 2"
                                    filterable
                                    clearable
                                    size="medium"
                                    v-model="infoForm.start_type"
                                    style="width:155px"
                                    placeholder="请选择"
                                >
                                    <el-option label="激活时间开始" :value="1"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-input
                                    size="medium"
                                    @blur="watchDays"
                                    :style="{width:inputWidth+'px'}"
                                    v-model="infoForm.days"
                                    placeholder="天数"
                                    :disabled="info.detail.status == 2 || info.type == 2"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <span class="line">个自然日内，交易额未达到</span>
                            </el-form-item>
                            <el-form-item>
                                <el-input
                                    size="medium"
                                    @blur="watchMoney('target_money')"
                                    :style="{width:inputWidth+'px'}"
                                    v-model="infoForm.target_money"
                                    :disabled="info.detail.status == 2 || info.type == 2"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <span class="line">元，扣除代理</span>
                            </el-form-item>
                            <el-form-item>
                                <el-input
                                    size="medium"
                                    @blur="watchMoney('deduction_money')"
                                    :style="{width:inputWidth+'px'}"
                                    v-model="infoForm.deduction_money"
                                    :disabled="info.detail.status == 2 || info.type == 2"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <span class="line">元。</span>
                            </el-form-item>
                        </el-form>
                        <el-form-item
                            label="交易类型："
                            v-loading="checkLoading"
                            element-loading-text="拼命加载中"
                        >
                            <div v-for="(item,index) in checkList" :key="index">
                                <el-checkbox
                                    v-model="item.is_selected"
                                    :true-label="1"
                                    :false-label="0"
                                    :disabled="info.detail.status == 2 || info.type == 2"
                                >{{item.title}}</el-checkbox>
                                <el-checkbox
                                    v-model="item.is_selected_debit"
                                    :true-label="1"
                                    :false-label="0"
                                    :disabled="item.is_selected != 1 || info.detail.status == 2 || info.type == 2"
                                    v-show="item.is_show_debit == 1"
                                >是否包含借记卡</el-checkbox>
                            </div>
                        </el-form-item>
                    </template>
                    <!-- 涨价规则配置 -->
                    <template v-if="infoForm.type == 2">
                        <el-form :inline="true">
                            <div style="margin-bottom:10px">规则配置：</div>
                            <el-form-item>
                                <el-select
                                    :disabled="info.type == 2"
                                    filterable
                                    clearable
                                    size="medium"
                                    v-model="infoForm.start_type"
                                    style="width:155px"
                                    placeholder="请选择"
                                >
                                    <el-option label="激活时间开始" :value="1"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-input
                                    size="medium"
                                    @blur="watchDays"
                                    :style="{width:inputWidth+'px'}"
                                    v-model="infoForm.days"
                                    placeholder="天数"
                                    :disabled="info.detail.status == 2"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <span class="line">个自然日后按下方费率计算。</span>
                            </el-form-item>
                        </el-form>
                        <el-table
                            :data="riseList"
                            size="mini"
                            border
                            style="width: 100%"
                            max-height="455px"
                        >
                            <!-- 交易方式 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                fixed
                                label="交易方式"
                                min-width="120"
                            >
                                <template slot-scope="scope">{{scope.row.title}}</template>
                            </el-table-column>
                            <!-- 结算周期 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                fixed
                                label="结算周期"
                                min-width="120"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope">
                                        <div
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >{{item.title}}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 卡类型 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                fixed
                                label="卡类型"
                                min-width="120"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="four-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >{{v.title}}</div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 费率上调% -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                label="费率上调%"
                                min-width="120"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="four-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >
                                                <template v-if="v.is_empty == 1">--</template>
                                                <template v-else>
                                                    <el-input
                                                        size="sm"
                                                        v-model="v.base_rate"
                                                        :disabled="info.detail.status == 2"
                                                    ></el-input>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 是否设置封顶值 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                label="是否设置封顶值"
                                min-width="120"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="four-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >
                                                <template v-if="v.is_empty == 1">--</template>
                                                <template v-if="item.list[i].is_overrun == 1">是</template>
                                                <template v-if="item.list[i].is_overrun == 0">否</template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 达到某一金额时-->
                            <el-table-column
                                header-align="center"
                                align="center"
                                label="达到某一金额时"
                                min-width="120"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="four-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >
                                                <template
                                                    v-if="v.is_empty == 1 || v.is_overrun == 0"
                                                >--</template>
                                                <template v-else>{{item.list[i].overrun_money}}</template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 费率收取方式 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                label="费率收取方式"
                                min-width="120"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="four-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >
                                                <template
                                                    v-if="v.is_empty == 1 || v.is_overrun == 0"
                                                >--</template>
                                                <template
                                                    v-if="item.list[i].overrun_type == 1"
                                                >按交易比例</template>
                                                <template
                                                    v-if="item.list[i].overrun_type == 2"
                                                >按固定金额</template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 费率/金额上调-->
                            <el-table-column
                                header-align="center"
                                align="center"
                                label="费率/金额上调"
                                min-width="120"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="four-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >
                                                <template
                                                    v-if="v.is_empty == 1 || v.is_overrun == 0"
                                                >--</template>
                                                <!-- 费率 -->
                                                <template v-if="v.overrun_type == 1">
                                                    <el-input
                                                        size="sm"
                                                        v-model="v.overrun_rate"
                                                        :disabled="info.detail.status == 2"
                                                    ></el-input>
                                                </template>
                                                <!-- 金额 -->
                                                <template v-if="v.overrun_type == 2">
                                                    <el-input
                                                        size="sm"
                                                        v-model="v.overrun_cap"
                                                        :disabled="info.detail.status == 2"
                                                    ></el-input>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 是否添加秒到费 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                label="是否添加秒到费"
                                min-width="120"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="four-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >
                                                <template v-if="v.is_empty == 1">--</template>
                                                <template v-if="item.list[i].is_sec == 0">否</template>
                                                <template v-if="item.list[i].is_sec == 1">是</template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 秒到费上调（单笔添加）-->
                            <el-table-column
                                header-align="center"
                                align="center"
                                label="秒到费上调（单笔添加）"
                                min-width="150"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="four-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >
                                                <template v-if="v.is_empty == 1 || v.is_sec == 0">--</template>
                                                <template v-else>
                                                    <el-input
                                                        size="sm"
                                                        v-model="v.sec_fee"
                                                        :disabled="info.detail.status == 2"
                                                    ></el-input>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                    <el-form-item label="活动截止时间：">
                        <el-date-picker
                            size="medium"
                            v-model="infoForm.cut_off_time"
                            type="datetime"
                            :disabled="info.detail.status == 2 || (info.type == 2 && info.detail.type == 1)"
                            placeholder="活动截止时间"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item style="margin-top:20px;">
                        <el-button
                            type="primary"
                            v-show="info.detail.status == 1 || info.type == 1"
                            :disabled="info.detail.status == 2 || (info.type == 2 && info.detail.type == 1)"
                            @click="handleSubmit(infoForm)"
                        >提 交</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "MAM_riskControlInfo",
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            brandList: [], //终端类型
            ruleList: [],//规则类型
            checkList: [], //交易类型
            riseList: [], //表格
            pickerOptions: { //禁止选之前的时间
                disabledDate(time) {
                    return time.getTime() < Date.now();
                }
            },
            infoForm: {
                brand_id: '', //终端类型
                type: '',//规则类型
                start_type: 1,//开始类型
                days: '', //达标天数
                target_money: '', //达标金额
                deduction_money: '', //扣除金额
                cut_off_time: '' //失效日期 格式为年-月-日
            },
            checkLoading: false,
            inputWidth: 100
        };
    },
    mounted() {
        this.getBrand();//获取终端类型
        this.getRule(); //获取规则类型
        if (this.info.type == 2) {
            this.getInfo({ id: this.info.detail.id });
        }
    },
    methods: {
        handleSubmit(field) { //提交
            if (this.infoForm.type == 1) {
                field.rate_list = '';
                let transactionType = this.handleTransactionType();//处理交易类型
                field.transaction_type = transactionType;
            } else
                if (this.infoForm.type == 2) {
                    field.transaction_type = '';
                    let rate_list = this.handleRiseRate();
                    field.rate_list = rate_list;
                }
            if (this.info.type == 2) { //编辑的时候传id
                field.id = this.info.detail.id;
            }
            let that = this;
            this.$confirm('活动奖励规则保存后，将不能进行修改，请确认是否保存？', '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('brand_risk_control/add_update_brand_risk_control', field, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    duration: 1500,
                                    message: res.msg,
                                    onClose() {
                                        that.handleClose(1);
                                        instance.confirmButtonLoading = false;
                                        done();
                                    }
                                });
                            } else {
                                this.$message.error(res.msg);
                                instance.confirmButtonLoading = false;
                                done();
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleBrandChange(brand_id) { //监听终端类型发生改变
            if (this.infoForm.type != '') {
                let reqData = {
                    brand_id,
                    type: this.infoForm.type
                };
                this.getTransactionType(reqData);
            }
        },
        handleRuleChange(type) { //监听规则类型发生改变
            if (this.infoForm.brand_id != '') {
                let reqData = {
                    type,
                    brand_id: this.infoForm.brand_id,
                };
                this.getTransactionType(reqData);
            }
        },
        getInfo(params) { //获取详情
            this.$api.post('brand_risk_control/get_brand_risk_control_detail', params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.infoForm = res.data;
                    switch (data.type) {
                        case 1:
                            this.checkList = data.transaction_type;
                            break;
                        case 2:
                            this.riseList = data.rate_list;
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getBrand() { //获取终端类型
            this.$api.post('brand/brand_list', {}, res => {
                if (res.code == 0) {
                    this.brandList = res.data.list;
                } else {
                    this.$message.success(res.msg);
                }
            });
        },
        getRule() { //获取规则类型
            this.$api.post('get_risk_control', {}, res => {
                if (res.code == 0) {
                    this.ruleList = res.data;
                } else {
                    this.$message.success(res.msg);
                }
            });
        },
        handleTransactionType() { //处理交易类型
            let temp = [];
            this._.forEach(this.checkList, item => {
                if (item.is_selected) {
                    // 拼接数据转json
                    temp.push(
                        {
                            pay_type: item.pay_type_id,
                            is_include_debit: item.is_selected_debit
                        }
                    )
                }
            });
            return JSON.stringify(temp);
        },
        handleRiseRate() { //涨价费率数据
            let temp = [];
            this._.forEach(this.riseList, (item) => {
                this._.forEach(item.list, (v) => {
                    this._.forEach(v.list, (j) => {
                        if (j.is_empty != 1) {
                            temp.push(this._.pick(j, ['brand_id', 'settle_type', 'pay_type', 'card_type', 'base_rate', 'overrun_rate', 'overrun_cap', 'sec_fee', 'id']));
                        }
                    })
                })
            });
            return JSON.stringify(temp);
        },
        getTransactionType(params) { //获取交易类型
            this.checkLoading = true;
            this.$api.post('brand_risk_control/get_brand_risk_control_tran_type', params, res => {
                if (res.code == 0) {
                    if (this.infoForm.type == 1) {
                        this.checkList = res.data;
                        this.checkLoading = false;
                    } else {
                        this.riseList = res.data
                    }
                } else {
                    this.$message.error(res.msg);
                    this.riseList = [];
                    this.infoForm.brand_id = '';
                    this.checkLoading = false;
                }
            });
        },
        watchDays() { //监听自然日 暂时校验待优化
            let reg = /^[0-9]*$/;
            if (!reg.test(this.infoForm.days)) {
                this.infoForm.days = ''
            }
        },
        watchMoney(val) { //监听金额 暂时校验待优化
            let reg = /^[0-9]+(.[0-9]{1,4})?$/;
            if (!reg.test(this.infoForm[val])) {
                this.infoForm[val] = ''
            }
        },
        handleClose(ref) { //关闭弹窗
            this.$emit('infoClose', ref)
        },
    }
};
</script>

<style scoped lang="scss">
.MAM_riskControlInfo {
    * {
        font-size: 12px;
    }
    .el-input__inner {
        font-size: 12px;
    }
    ::v-deep .el-dialog__body {
        height: 600px;
        overflow: auto;
    }
    ::v-deep .el-form-item {
        margin-bottom: 5px;
        .el-form-item__label,
        .el-radio__label {
            font-size: 12px;
        }
    }
    .el-form--label-top ::v-deep .el-form-item__label {
        padding: 0;
    }
    .el-checkbox ::v-deep .el-checkbox__label {
        font-size: 12px;
    }
    .line {
        min-width: 75px;
        text-align: center;
    }
    .headers {
        display: inline-block;
        width: 177px;
    }
    // 滚动条
    ::v-deep .el-dialog__body::-webkit-scrollbar {
        width: 4px;
    }
    ::v-deep .el-dialog__body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }
    ::v-deep .el-dialog__body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }
    ::v-deep .el-table .cell {
        padding: 0;
    }
    ::v-deep .el-table--mini td,
    .el-table--mini th {
        padding: 0;
    }
    .tableScope {
        display: flex;
        flex-direction: column;
        div {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
        }
        :not(:last-child) {
            border-bottom: 1px solid #ebeef5;
        }
        .isNull {
            background-color: #f5f7fa;
        }
        .notNull {
            background-color: none;
        }
    }
    .tableScope-fours {
        .tableScope-four {
            display: flex;
            flex-direction: column;
            .four-mbox {
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-bottom: 1px solid #ebeef5;
            }
            .isNull {
                background-color: #f5f7fa;
            }
            .notNull {
                background-color: none;
            }
        }
        .el-input {
            width: 100px;
            ::v-deep .el-input__inner {
                // border: none;
                // height: 100%;
                text-align: center;
                font-size: 12px;
            }
        }
        :last-child {
            .four-mbox:last-child {
                border-bottom: none;
            }
        }
    }
}
</style>