<template>
    <div class="terminalDetail">
        <el-dialog
            title="终端明细"
            width="550px"
            append-to-body
            :visible="terminalInfo.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <!-- <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="terminal_sn" placeholder="请输入SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(terminal_sn)"
                    >查询</el-button>
                </el-form-item>
            </el-form>-->
            <el-table
                :data="terminalList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="id"
                    label="序号"
                    min-width="120"
                ></el-table-column>
                <!-- Sn -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="sn"
                    label="SN码"
                    min-width="120"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                small
                layout="prev, pager, next"
                @current-change="getCurrent"
                :total="page.total"
            ></el-pagination>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "terminalDetail",
    props: {
        terminalInfo: {
            type: Object
        }
    },
    data() {
        return {
            terminalList: [],
            terminal_sn: '',
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: false
        };
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10,
        });
    },
    methods: {
        // handleSearch(terminal_sn) {
        //     this.getData({
        //         page: 1,
        //         limit: 10,
        //         terminal_sn
        //     });
        // },
        handleClose(ref) { //关闭弹窗
            this.$emit('infoClose', ref);
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.getData({
                page: this.page.currentPage,
                limit: this.page.pageSize,
                // terminal_sn: this.terminal_sn
            });
        },
        getData(params) { //获取数据
            this.loading = true;
            let reqData = this._.assign(this.terminalInfo.info, params);
            this.$api.post(this.terminalInfo.url, reqData, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.page.total = data.count; //总数
                    this.terminalList = data.list; //列表
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style scoped lang="scss">
.terminalDetail {
    font-size: 12px;
    .el-input__inner,
    .tips {
        font-size: 12px;
    }

    .el-form-item {
        margin-bottom: 5px;
    }

    ::v-deep .el-form-item__label {
        padding: 0;
        font-size: 12px;
    }
    .titleTip {
        padding: 10px 10px;
        background-color: #ecf8ff;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #50bfff;
        margin: 10px 0;
    }
    .title p {
        font-size: 12px;
        :first-child {
            font-weight: 700;
        }
    }
}
</style>