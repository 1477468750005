<template>
    <div class="rateTemplateInfo">
        <el-dialog
            :title="templateInfo.type == 1? '添加模板': '编辑模板'"
            :visible="templateInfo.isShow"
            @close="handleClose"
            :close-on-click-modal="false"
            width="600px"
        >
            <el-card class="box-card" shadow="hover">
                <el-form
                    :model="submitForm"
                    label-position="left"
                    label-width="auto"
                    class="demo-form-inline"
                >
                    <el-form-item label="终端类型">
                        <el-select
                            v-model="submitForm.brand_id"
                            filterable
                            clearable
                            style="width:100%"
                            placeholder="请选择终端类型"
                        >
                            <el-option
                                v-for="(item,index) in brandList"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="模板ID">
                        <el-input clearable v-model="submitForm.templateId" placeholder="请输入模板ID"></el-input>
                    </el-form-item>
                </el-form>
                <el-tabs v-model="activeName" type="card" style="margin-top:20px">
                    <el-tab-pane
                        v-for="(item,index) in tempTab"
                        :label="item.title"
                        :name="item.title"
                        :key="index"
                    >
                        <el-table
                            :data="item.list"
                            align="center"
                            size="mini"
                            border
                            max-height="400px"
                            style="width: 100%; margin-top: 20px"
                        >
                            <el-table-column align="center" prop="title" label="交易方式" width="180">
                                <template slot-scope="scope">{{scope.row.title}}</template>
                            </el-table-column>
                            <el-table-column align="center" label="说明" min-width="150">
                                <template slot-scope="scope">
                                    <div class="tableScoped">
                                        <div
                                            v-if="scope.row.base_rate_name"
                                        >{{scope.row.base_rate_name}}</div>
                                        <div>{{scope.row.overrun_name}}</div>
                                        <!-- <div
                                            class="tableTitle second"
                                            v-if="scope.row.before_overrun_rate != 0 && scope.row.after_overrun_rate != 0"
                                        >交易封顶（%）</div>-->
                                        <div v-if="scope.row.sec_name">{{scope.row.sec_name}}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="费率" min-width="150">
                                <template slot-scope="scope">
                                    <div class="tableScoped">
                                        <div v-if="scope.row.base_rate">
                                            <el-input
                                                size="sm"
                                                v-model="scope.row.base_rate"
                                                :placeholder="scope.row.base_rate"
                                            ></el-input>
                                        </div>
                                        <div v-if="scope.row.overrun_cap">
                                            <el-input
                                                size="sm"
                                                v-model="scope.row.base_rate"
                                                :placeholder="scope.row.base_rate"
                                            ></el-input>
                                        </div>
                                        <div v-if="scope.row.overrun_rate">
                                            <el-input
                                                size="sm"
                                                v-model="scope.row.base_rate"
                                                :placeholder="scope.row.base_rate"
                                            ></el-input>
                                        </div>
                                        <div v-if="scope.row.sec_cap">
                                            <el-input
                                                size="sm"
                                                v-model="scope.row.base_rate"
                                                :placeholder="scope.row.base_rate"
                                            ></el-input>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
                <el-button
                    size="medium"
                    type="primary"
                    style="font-size: 12px;margin-top:20px;"
                    @click="handleSubmit()"
                >提 交</el-button>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import publicReq from '../../utils/publicReq';
export default {
    name: "rateTemplateInfo",
    props: {
        templateInfo: {
            type: Object
        }
    },
    data() {
        return {
            brandList: [],
            submitForm: {
                brand_id: ''
            },
            activeName: "T+0",
            tempTab: [{ title: "T+0", list: [{ title: "刷卡（借记卡）", base_rate_name: "基础费率(%)", overrun_name: "交易封顶1000元", sec_name: "秒到费用(元/笔)", base_rate: "0.52", overrun_cap: "10", sec_cap: "2", pay_type: "101", card_type: "1", settle_type: "1" }, { title: "刷卡（贷记卡）", base_rate_name: "基础费率(%)", overrun_name: "交易封顶1000元", base_rate: "0.52", overrun_rate: "10", pay_type: "101", card_type: "2", settle_type: "1" }] }, { title: "T+1", list: [{ title: "刷卡（借记卡）", overrun_name: "交易封顶1000元", sec_name: "秒到费用(元/笔)", overrun_cap: "10", sec_cap: "2", pay_type: "101", card_type: "1", settle_type: "2" }, { title: "刷卡（贷记卡）", base_rate_name: "基础费率(%)", overrun_name: "交易封顶1000元", base_rate: "0.52", overrun_rate: "10", pay_type: "101", card_type: "2", settle_type: "2" }] }]
        }
    },
    mounted() {
        publicReq.getBrandList(res => {
            this.brandList = res.data.list;
        });
    },
    methods: {
        handleSubmit() {

        },
        handleClose(ref) {
            this.$emit('infoClose', ref);
        }
    }
};
</script>

<style lang="scss" scoped>
.rateTemplateInfo {
    ::v-deep .el-table--mini td,
    ::v-deep .el-table--mini th {
        padding: 0;
    }
    ::v-deep .el-table .el-table__header td,
    ::v-deep .el-table .el-table__header th {
        padding: 12px 0;
    }
    ::v-deep .el-table .cell {
        padding: 0;
    }
    .tableScoped {
        div {
            height: 51px;
            line-height: 51px;
            ::v-deep .el-input {
                width: 130px;
                .el-input__inner {
                    text-align: center;
                }
            }
        }
        :not(:last-child) {
            border-bottom: 1px solid #ebeef5;
        }
    }
}
</style>
