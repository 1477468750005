<template>
    <div class="rateInfo">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <el-card class="box-card" shadow="hover">
            <div class="title">基础信息</div>
            <el-row :gutter="20">
                <el-col :sm="12" :md="12" :lg="8">
                    <span class="labels">代理商账号：</span>
                    <span>{{resData.phone}}</span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8">
                    <span class="labels">代理商姓名：</span>
                    <span>{{resData.realname}}</span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8">
                    <span class="labels">终端类型：</span>
                    <span>{{resData.brand_name}}</span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8">
                    <span class="labels">终端SN：</span>
                    <span>{{resData.terminal_sn}}</span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8">
                    <span class="labels">商户编号：</span>
                    <span>{{resData.merchant_number}}</span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8">
                    <span class="labels">商户名称：</span>
                    <span>{{resData.merchant_name}}</span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8">
                    <span class="labels">激活状态：</span>
                    <span>{{resData.is_active == 0? '未激活': '已激活'}}</span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8">
                    <span class="labels">审核状态：</span>
                    <span>{{auditStatus}}</span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8">
                    <span class="labels">创建时间：</span>
                    <span>{{resData.create_time}}</span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8">
                    <span class="labels">审核时间：</span>
                    <span>{{resData.check_time}}</span>
                </el-col>
            </el-row>
            <div class="title">终端费率修改信息</div>
            <div class="rate-title">
                <el-alert :title="resData.rate_code_name" type="info" :closable="false"></el-alert>
            </div>
            <el-tabs v-model="activeName" type="border-card">
                <el-tab-pane
                    v-for="(item,index) in resData.data"
                    :label="item.title"
                    :name="item.title"
                    :key="index"
                >
                    <el-table
                        :data="item.list"
                        align="center"
                        size="small"
                        border
                        style="width: 100%; margin-top: 20px"
                    >
                        <el-table-column align="center" prop="title" label="交易方式" width="180"></el-table-column>
                        <el-table-column align="center" label="说明" min-width="150">
                            <template slot-scope="scope">
                                <div class="tableScope">
                                    <div
                                        class="tableTitle first"
                                        v-if="scope.row.before_base_rate != 0 && scope.row.after_base_rate != 0"
                                    >基础费率（%）</div>
                                    <div
                                        class="tableTitle second"
                                        v-if="scope.row.before_overrun_cap != 0 && scope.row.after_overrun_cap != 0"
                                    >交易封顶（元）</div>
                                    <div
                                        class="tableTitle second"
                                        v-if="scope.row.before_overrun_rate != 0 && scope.row.after_overrun_rate != 0"
                                    >交易封顶（%）</div>
                                    <div
                                        class="tableTitle third"
                                        v-if="scope.row.before_sec_fee != 0 || scope.row.after_sec_fee != 0"
                                    >秒到费用（元/笔）</div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="当前费率" min-width="150">
                            <template slot-scope="scope">
                                <div class="tableScope">
                                    <!-- 基础费率 -->
                                    <div
                                        class="first"
                                        v-if="scope.row.before_base_rate != 0 && scope.row.after_base_rate != 0"
                                    >{{scope.row.before_base_rate}}</div>
                                    <!-- 交易封顶-费率 -->
                                    <div
                                        class="second"
                                        v-if="scope.row.before_overrun_rate != 0 && scope.row.after_overrun_rate != 0"
                                    >{{scope.row.before_overrun_rate}}</div>
                                    <!-- 交易封顶-元 -->
                                    <div
                                        class="second"
                                        v-if="scope.row.before_overrun_cap != 0 && scope.row.after_overrun_cap != 0"
                                    >{{scope.row.before_overrun_cap}}</div>
                                    <!-- 秒到费用 -->
                                    <div
                                        class="third"
                                        v-if="scope.row.before_sec_fee != 0 || scope.row.after_sec_fee != 0"
                                    >{{scope.row.before_sec_fee}}</div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="修改后费率" min-width="150">
                            <template slot-scope="scope">
                                <div class="tableScope">
                                    <!-- 基础费率 -->
                                    <div
                                        class="first"
                                        v-if="scope.row.before_base_rate != 0 && scope.row.after_base_rate != 0"
                                    >{{scope.row.after_base_rate}}</div>
                                    <!-- 交易封顶-费率 -->
                                    <div
                                        class="second"
                                        v-if="scope.row.before_overrun_rate != 0 && scope.row.after_overrun_rate != 0"
                                    >{{scope.row.after_overrun_rate}}</div>
                                    <!-- 交易封顶-元 -->
                                    <div
                                        class="second"
                                        v-if="scope.row.before_overrun_cap != 0 && scope.row.after_overrun_cap != 0"
                                    >{{scope.row.after_overrun_cap}}</div>
                                    <!-- 秒到费用 -->
                                    <div
                                        class="third"
                                        v-if="scope.row.before_sec_fee != 0 || scope.row.after_sec_fee != 0"
                                    >{{scope.row.after_sec_fee}}</div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            <div style="margin-top:20px">
                <el-button
                    v-if="resData.status == 0"
                    @click="audit"
                    type="primary"
                    size="medium"
                    style="font-size:12px"
                >审 核</el-button>
            </div>
        </el-card>
        <rate-audit v-if="auditIsShow" :auditIsShow="auditIsShow" @auditClose="auditClose" :id="id"></rate-audit>
    </div>
</template>

<script>
import rateAudit from './MAM_terminalAudit';
export default {
    name: "MAM_rateInfo",
    data() {
        return {
            auditIsShow: false,
            id: null,
            resData: {},
            rateTable: [],
            activeName: 'T+0',
        }
    },
    mounted() {
        this.id = this.$route.query.id;
        this.getInfo();
    },
    methods: {
        audit() {
            this.auditIsShow = true;
        },
        auditClose(ref) {
            this.auditIsShow = false;
            if (ref) {
                this.getInfo();
            }
        },
        getInfo() {
            this.$api.post('terminal/getRateApplyInfo', { id: this.$route.query.id }, res => { //初始化
                if (res.code == 0) {
                    let data = res.data;
                    this.resData = data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    },
    components: {
        rateAudit
    },
    computed: {
        auditStatus() {
            let status;
            switch (this.resData.status) {
                case 0:
                    status = '待审核';
                    break;
                case 1:
                    status = '审核通过';
                    break;
                case 2:
                    status = '审核拒绝';
                    break;
                case 3:
                    status = '审核通过';
                    break;

            }
            return status
        }
    }
};
</script>

<style lang="scss" scope>
.rateInfo {
    font-size: 12px;
    .rate-title {
        height: 40px;
        margin-bottom: 5px;
    }
    .labels {
        font-weight: 700;
    }
    .title {
        margin: 10px 0;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #40b8ff;
        padding: 0 0 0 10px;
        .el-button--text {
            color: #303133;
            font-size: 13px;
            font-weight: 700;
        }
    }
    .el-row {
        margin: 10px 0;
        &:last-child {
            margin-bottom: 0;
        }
        .el-col {
            margin: 10px 0;
        }
    }
    .el-table .cell {
        padding: 0;
    }
    .el-tabs--border-card > .el-tabs__header .el-tabs__item {
        font-size: 12px;
    }
    .tableScope {
        .tableTitle {
            font-weight: 700;
        }
        line-height: 32px;
        :not(:last-child) {
            border-bottom: 1px solid #ebeef5;
        }
        .first {
            padding-bottom: 8px;
        }
        .second {
            padding: 8px 0;
        }
        .third {
            padding-top: 8px;
        }
    }
}
</style>
