<template>
    <div class="FM_topUp">
        <!-- 充值 -->
        <el-dialog
            title="充值"
            :visible="topUpIsShow"
            @close="handleClose"
            :close-on-click-modal="false"
        >
            <el-card class="box-card" shadow="hover">
                <el-form label-position="left" label-width="100px" :model="topUpForm">
                    <div class="purseTitle">
                        <div>充值信息</div>
                    </div>
                    <el-form-item label="充值金额">
                        <el-input v-model="topUpForm.money" placeholder="请输入充值金额">
                            <span slot="suffix" style="font-size:12px">元</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="实际入账金额">
                        <el-input v-model="topUpForm.money" placeholder="请输入实际入账金额" readonly>
                            <span slot="suffix" style="font-size:12px">元</span>
                        </el-input>
                    </el-form-item>
                    <!-- <template>
                        <div class="purseTitle">
                            <div>支付方式</div>
                        </div>
                        <el-form-item label="账户姓名">
                            <el-input
                                v-model="payMethods.owner_name"
                                placeholder="账户姓名"
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="银行卡号">
                            <el-input
                                v-model="payMethods.card_no"
                                placeholder="银行卡号"
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="银行名称">
                            <el-input
                                v-model="payMethods.bank_name"
                                placeholder="银行名称"
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="开户行">
                            <el-input
                                v-model="payMethods.open_bank"
                                placeholder="开户行"
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                    </template> -->
                    <el-form-item label="上传转账凭证">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadUrl"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                        >
                            <div slot="tip" class="el-upload__tip">
                                <el-button
                                    @click="checkPic"
                                    type="text"
                                    size="small"
                                    icon="el-icon-picture"
                                >点击查看图片规范</el-button>
                            </div>
                            <img v-if="imgPath" :src="imgPath" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <div style="margin-top:20px">
                        <el-button
                            type="primary"
                            size="medium"
                            @click="submit(topUpForm)"
                            :loading="submitLoading"
                            style="font-size:12px"
                        >提交审核</el-button>
                        <el-button
                            type="primary"
                            size="medium"
                            @click="cancel"
                            style="font-size:12px"
                        >取消</el-button>
                    </div>
                </el-form>
            </el-card>
        </el-dialog>
        <el-dialog :visible.sync="dialogIsShow" width="300px">
            <img width="100%" :src="defaultPic" alt />
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "FM_topUp",
    props: {
        topUpIsShow: {
            type: Boolean,
            default: false
        },
        detail: {
            type: Object
        }
    },
    data() {
        return {
            uploadUrl: '',
            dialogIsShow: false,
            submitLoading: false,
            defaultPic: require('../../../assets/template.png'),
            imgPath: '',//渲染地址
            topUpForm: { //提交表单
                money: '',
                path: ''
            },
            payMethods: {}
        }
    },
    mounted() {
        this.uploadUrl = this.$api.uploadUrl;//上传文件地址 
        this.getPayMethods();
    },
    methods: {
        submit(topUpForm) {
            this.$api.post('organization/rechargeApply', topUpForm, res => {
                if (res.code == 0) {
                    let that = this;
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.handleClose(1);
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        cancel() {
            this.handleClose(); //取消
        },
        handleClose() {
            this.$emit('topUpClose')
        },
        checkPic() { //查看图片规范
            this.dialogIsShow = true;
        },
        getPayMethods() { //获取支付方式
            this.$api.post('organization/getReChargeConfig', {}, res => {
                if (res.code == 0) {
                    this.payMethods = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        handleAvatarSuccess(res) { //上传成功后
            if (res.code === 0) {
                this.imgPath = res.data.file_url; //渲染地址
                this.topUpForm.path = res.data.file_path;
            } else {
                //错误提示
                this.$message.error(res.msg);
            }
        },
        beforeAvatarUpload() { //上传前的提示
            // params:file
            // const isJPG = file.type === 'image/jpeg';
            // const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 2MB!');
            // }
            // return isJPG && isLt2M;

        }
    }
};
</script>

<style lang="scss" scope>
.FM_topUp {
    .purseTitle {
        font-size: 12px;
        margin: 10px 0;
        color: #909399;
        :nth-child(1) {
            font-weight: 700;
            color: #7f7f7f;
            padding-left: 10px;
            border-left: 4px solid #909399;
        }
    }
    .rowTitle {
        color: #909399;
        font-size: 12px;
        .el-col {
            margin: 5px 0;
        }
        .labels {
            font-weight: 700;
        }
    }

    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        .el-form-item__label {
            font-size: 12px;
            font-weight: 700;
        }
        // margin-bottom: 5px;
    }
    .el-dialog {
        min-width: 500px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background-color: #fdfdfd;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 40px;
        color: #8c939d;
        width: 300px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }
    .avatar {
        width: 300px;
        height: 200px;
        display: block;
    }
    // .el-dialog {
    //     display: flex;
    // flex-direction: column;
    // margin: 0 !important;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // // height:600px;
    // max-height: calc(100% - 30px);
    // max-width: calc(100% - 30px);
    // }
    // .el-dialog .el-dialog__body {
    //     flex: 1;
    //     overflow: auto;
    // }
}
</style>
