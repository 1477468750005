<template>
    <div class="BS_AgreementSetting">
        <!-- 协议设置 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="agreementSetForm" class="demo-form-inline">
                <el-form-item>
                    <el-select size v-model="agreementSetForm.type" clearable placeholder="请选择协议类型">
                        <el-option label="注册协议" value="1"></el-option>
                        <el-option label="绑卡协议" value="2"></el-option>
                        <el-option label="结算协议" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="agreementSetForm.title" placeholder="请输入协议名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(agreementSetForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click>导出数据</el-button>
            </div>-->
            <el-table
                :data="agreementTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 协议类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="type_name"
                    label="协议类型"
                    min-width="120"
                ></el-table-column>
                <!-- 协议名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="title"
                    label="协议名称"
                    min-width="120"
                ></el-table-column>
                <!-- 更新时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="update_time"
                    label="更新时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "BS_AgreementSetting",
    data() {
        return {
            agreementSetForm: {
                type: '',  //协议类型
                title: '', //协议名称

            },
            agreementTable: [],
            loading: true
        }
    },
    mounted() {
        this.getData({});
    },
    methods: {
        onSearch(agreementSetForm) { //搜索
            this.getData(agreementSetForm);
        },
        handleEdit(row) { //编辑
            this.$router.push({ path: '/editAgreement', query: { type: row.type } })
        },
        infoClose(ref) { //关闭弹窗
            this.infoIsShow = false;
            if (ref) {
                this.getData(this.agreementSetForm);
            }
        },
        getData(params) {
            this.$api.post("agreement/agreement_list", params, res => {
                let data = res.data;
                this.agreementTable = data;
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.BS_AgreementSetting {
}
</style>
