<template>
    <div class>
        <el-dialog
            :visible="info.isShow"
            @close="handleClose"
            :close-on-click-modal="false"
            width="800px"
            title="基本信息修改"
        >
            <el-card class="box-card" shadow="hover" v-loading="initLoading">
                <el-form
                    :inline="true"
                    :model="baseForm"
                    :rules="rules"
                    ref="baseForm"
                    label-position="right"
                    label-width="140px"
                >
                    <!-- 基本信息 -->
                    <template>
                        <div class="m-title">基本信息</div>
                        <el-form-item label="代理商角色" prop="role_id">
                            <el-select
                                size="medium"
                                v-model="baseForm.role_id"
                                placeholder="请选择代理角色"
                            >
                                <el-option
                                    v-for="item in roleList"
                                    :label="item.name"
                                    :value="item.id"
                                    :key="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="代理商名称">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                disabled
                                v-model="baseForm.agent_name"
                                placeholder="请输入代理商名称"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="统一社会信用代码" prop="credit_code">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                v-model="baseForm.credit_code_show"
                                placeholder="请输入统一社会信用代码"
                                @blur="checkField(baseForm.credit_code_show,'credit_code')"
                            ></el-input>
                        </el-form-item>
                        <br />
                        <el-form-item label="营业执照有效期" prop="bus_license_valid_sdate">
                            <el-date-picker
                                v-model="licenseRange"
                                type="daterange"
                                :clearable="false"
                                :disabled="baseForm.is_license_long_time == 1"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                @change="handleLicenseChange"
                            ></el-date-picker>
                            <span style="margin-left:10px">
                                <el-checkbox
                                    :true-label="1"
                                    :false-label="0"
                                    :disabled="baseForm.bus_license_valid_sdate == ''"
                                    @change="handleBusCheckboxChange"
                                    v-model="baseForm.is_license_long_time"
                                >长期</el-checkbox>
                            </span>
                        </el-form-item>
                        <br />
                        <el-form-item label="法人姓名" prop="legal_person">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                v-model="baseForm.legal_person"
                                placeholder="请输入法人姓名"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="法人手机号" prop="legal_tel">
                            <el-input
                                suffix-icon="el-icon-aux"
                                v-model="baseForm.legal_tel_show"
                                placeholder="请输入法人手机号"
                                @blur="checkField(baseForm.legal_tel_show,'legal_tel')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="法人证件类型" prop="card_type">
                            <el-select
                                size="medium"
                                v-model="baseForm.card_type"
                                placeholder="请选择证件类型"
                            >
                                <el-option label="身份证" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="证件号" prop="id_card">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                v-model="baseForm.id_card_show"
                                placeholder="请输入证件号"
                                @blur="checkField(baseForm.id_card_show,'id_card')"
                            ></el-input>
                        </el-form-item>
                        <br />
                        <el-form-item label="证件有效期" prop="card_valid_sdate">
                            <el-date-picker
                                v-model="cardRange"
                                type="daterange"
                                :clearable="false"
                                range-separator="至"
                                :disabled="baseForm.is_long_time == 1"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                @change="handleCardChange"
                            ></el-date-picker>
                            <span style="margin-left:10px">
                                <el-checkbox
                                    :true-label="1"
                                    :false-label="0"
                                    :disabled="baseForm.card_valid_sdate == ''"
                                    @change="handleCheckboxChange"
                                    v-model="baseForm.is_long_time"
                                >长期</el-checkbox>
                            </span>
                        </el-form-item>
                        <br />
                        <el-form-item label="联系人" prop="contact_name">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                v-model="baseForm.contact_name"
                                placeholder="请输入联系人"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="contact_tel">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                v-model="baseForm.contact_tel_show"
                                placeholder="请输入联系电话"
                                @blur="checkField(baseForm.contact_tel_show,'contact_tel')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="联系邮箱" prop="contact_email">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                v-model="baseForm.contact_email_show"
                                placeholder="请输入联系邮箱"
                                @blur="checkField(baseForm.contact_email_show,'contact_email')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="联系地址" prop="contact_addr">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                v-model="baseForm.contact_addr_show"
                                placeholder="请输入联系地址"
                                @blur="checkField(baseForm.contact_addr_show,'contact_addr')"
                            ></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="代理商后台登录账号">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                v-model="baseForm.username"
                                placeholder="请输入代理商后台登录账号"
                            ></el-input>
                        </el-form-item>-->
                    </template>
                    <!-- 资质信息 -->
                    <template>
                        <div class="m-title">资质信息</div>
                        <div v-loading="imgLoading" element-loading-text="图片上传中,请稍等....">
                            <el-form-item label="营业执照" prop="bus_license_img">
                                <el-upload
                                    class="avatar-uploader"
                                    :action="uploadUrl"
                                    :show-file-list="false"
                                    :before-upload="handleBeforeUpload"
                                    :on-progress="handleImgLoading"
                                    :on-success="handleBusinessLicenseImg"
                                >
                                    <!-- <div slot="tip" class="el-upload__tip">
                                <span>支持JPEG、PNG格式文件，建议尺寸1080X1920像素</span>
                                    </div>-->
                                    <img
                                        v-if="baseForm.bus_license_img != ''"
                                        :src="baseForm.bus_license_img_path"
                                        class="avatar"
                                    />
                                    <img
                                        v-else
                                        :src="require('../../../assets/images/permit3x.png')"
                                        class="avatar"
                                    />
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="开户许可证" prop="opening_permit_img">
                                <el-upload
                                    class="avatar-uploader"
                                    :action="uploadUrl"
                                    :show-file-list="false"
                                    :before-upload="handleBeforeUpload"
                                    :on-progress="handleImgLoading"
                                    :on-success="handleOpenAccountImg"
                                >
                                    <!-- <div slot="tip" class="el-upload__tip">
                                <span>支持JPEG、PNG格式文件，建议尺寸1080X1920像素</span>
                                    </div>-->
                                    <img
                                        v-if="baseForm.opening_permit_img_path != ''"
                                        :src="baseForm.opening_permit_img_path"
                                        class="avatar"
                                    />
                                    <img
                                        v-else
                                        :src="require('../../../assets/images/other3x.png')"
                                        class="avatar"
                                    />
                                </el-upload>
                            </el-form-item>
                            <br />
                            <el-form-item label="法人身份证正面照" prop="card_positive_img">
                                <el-upload
                                    class="avatar-uploader"
                                    :action="uploadUrl"
                                    :show-file-list="false"
                                    :before-upload="handleBeforeUpload"
                                    :on-progress="handleImgLoading"
                                    :on-success="handleFrontIdCardImg"
                                >
                                    <!-- <div slot="tip" class="el-upload__tip">
                                <span>支持JPEG、PNG格式文件，建议尺寸1080X1920像素</span>
                                    </div>-->
                                    <img
                                        v-if="baseForm.card_positive_img_path != ''"
                                        :src="baseForm.card_positive_img_path"
                                        class="avatar"
                                    />
                                    <img
                                        v-else
                                        :src="require('../../../assets/images/idcard_f3x.png')"
                                        class="avatar"
                                    />
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="法人身份证反面照" prop="card_negative_img">
                                <el-upload
                                    class="avatar-uploader"
                                    :action="uploadUrl"
                                    :show-file-list="false"
                                    :before-upload="handleBeforeUpload"
                                    :on-progress="handleImgLoading"
                                    :on-success="handleBackIdCardImg"
                                >
                                    <!-- <div slot="tip" class="el-upload__tip">
                                <span>支持JPEG、PNG格式文件，建议尺寸1080X1920像素</span>
                                    </div>-->
                                    <img
                                        v-if="baseForm.card_negative_img_path != ''"
                                        :src="baseForm.card_negative_img_path"
                                        class="avatar"
                                    />
                                    <img
                                        v-else
                                        :src="require('../../../assets/images/idcard_r3x.png')"
                                        class="avatar"
                                    />
                                </el-upload>
                            </el-form-item>
                        </div>
                    </template>
                    <!-- 账号信息 -->
                    <template>
                        <div class="m-title">账号信息</div>
                        <el-form-item label="APP登录账号(手机号)">
                            <el-input
                                size="medium"
                                disabled
                                suffix-icon="el-icon-aux"
                                v-model="baseForm.app_phone"
                                placeholder="请输入APP登录账号"
                            >
                                <!-- <el-button
                                    :loading="codeLoading"
                                    @click="handleSendCode"
                                    slot="append"
                                >{{ codeText }}</el-button>-->
                            </el-input>
                        </el-form-item>
                        <!-- <el-form-item label="验证码">
                            <el-input
                                size="medium"
                                suffix-icon="el-icon-aux"
                                v-model="baseForm.check_code"
                                placeholder="请输入验证码"
                            ></el-input>
                        </el-form-item>-->
                        <el-form-item prop="exhibition_mode">
                            <span slot="label">
                                展业模式
                                <el-tooltip placement="top" effect="light">
                                    <div slot="content">
                                        <div style="color:#F56C6C">
                                            <i class="el-icon-warning"></i>
                                            温馨提示：配置完成后不支持修改
                                        </div>
                                        <br />
                                        <div style="color:#909399">1、传统模式适合传统代理商进行展业。</div>
                                        <br />
                                        <div style="color:#909399">2、直营模式适合直营业务员进行展业。</div>
                                    </div>
                                    <el-button type="text">
                                        <i class="el-icon-question" style="font-size:15px"></i>
                                    </el-button>
                                </el-tooltip>
                            </span>
                            <el-radio-group v-model="baseForm.exhibition_mode" disabled>
                                <el-radio :label="1">传统模式</el-radio>
                                <el-radio :label="2">直营模式</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item
                            label="直营运营权限"
                            prop="operation_authority"
                            v-if="baseForm.exhibition_mode == 2"
                        >
                            <el-select
                                v-model="baseForm.operation_authority"
                                placeholder="请选择直营运营权限"
                                disabled
                            >
                                <el-option label="一级" :value="1"></el-option>
                                <el-option label="二级" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </template>
                    <!-- <el-form-item prop="is_direct_signature">
                        <span slot="label">
                            是否海科直签
                            <el-tooltip placement="top" effect="light">
                                <div slot="content">
                                    <div style="color:#F56C6C">
                                        <i class="el-icon-warning"></i>
                                        温馨提示：配置完成后不支持修改
                                    </div>
                                </div>
                                <el-button type="text">
                                    <i class="el-icon-question" style="font-size:15px"></i>
                                </el-button>
                            </el-tooltip>
                        </span>
                        <el-radio-group v-model="baseForm.is_direct_signature" disabled>
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="2">否</el-radio>
                        </el-radio-group>
                    </el-form-item> -->
                </el-form>
                <div style="margin-top:20px">
                    <el-button
                        type="primary"
                        :loading="loading"
                        style="font-size: 12px;"
                        @click="handleSubmit(baseForm)"
                    >保存设置</el-button>
                </div>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import rules from './addAgentValid';
export default {
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            uploadUrl: this.$api.uploadUrl, // 上传图片的地址
            initLoading: false, // 初始化loading
            loading: false, // 提交loading
            codeLoading: false, // 发送验证loading
            imgLoading: false, // 上传图片loading
            codeText: '发送验证码', // 验证码文字
            licenseRange: [], // 营业执照有效期
            cardRange: [], // 身份证有效期
            isSelect: false, // 根据是否选中长期来判断身份证截止日期是否可选
            isSelectBus: false, // 根据是否选中长期来判断营业执照截止日期是否可选
            roleList: [], // 角色列表
            baseForm: {},
            rules: rules
        }
    },
    created() {
        this.getData().then((result) => {
            // 营业执照有效期、证件有效期赋初始值
            this.licenseRange.push(result.bus_license_valid_sdate, result.bus_license_valid_edate);
            this.cardRange.push(result.card_valid_sdate, result.card_valid_edate);
        })
        this.getRole(); //获取代理商列表
    },
    mounted() {
    },
    methods: {
        handleClose(ref) {
            this.$emit('infoClose', ref)
        },
        handleSubmit(baseForm) { //后台提交
            this.$refs.baseForm.validate(valid => {
                if (valid) {
                    this.loading = true; //防重复
                    this.$api.post('agent/update_agent_info', baseForm, res => {
                        let that = this;
                        if (res.code == 0) {
                            this.$message({
                                type: 'success',
                                message: res.msg,
                                duration: 1500,
                                onClose() {
                                    that.loading = false;
                                    that.handleClose(1);
                                }
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.msg,
                                duration: 1500,
                                onClose() {
                                    that.loading = false;
                                }
                            });
                        }
                    })
                }
            })
        },
        checkField(n, v) {
            if (n.indexOf('*') == -1) { // 判断内容中是否存*
                this.baseForm[v] = n;
            }
        },
        handleSendCode() { // 发送验证码
            this.codeLoading = true;
            let that = this;
            let timer = null;
            let count = 60;
            timer = setInterval(() => {
                count--;
                this.codeText = count;
                if (count == 0) {
                    clearInterval(timer);
                    this.codeText = '发送验证码';
                    this.codeLoading = false;
                }
            }, 1000);
            this.$api.post('send/sms', { phone: this.baseForm.app_phone, type: 2 }, res => { //发送
                if (res.code == 0) {
                    this.$message.success(res.msg);
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 2000,
                        onClose() { //错误提示信息
                            clearInterval(timer);
                            that.codeLoading = false; //解开按钮
                            that.codeText = '发送验证码'; //重置按钮内容
                        }
                    });

                }
            })
        },
        handleCheckboxChange(value) { // 处理是否长期
            if (value == 1) {
                this.isSelect = true;
                if (this.cardRange.length) { // 已选择有效期后进行修改
                    this.cardRange = [this.cardRange[0], '2099-01-01'];
                    this.baseForm.card_valid_edate = '2099-01-01';
                }
            } else {
                this.isSelect = false;
                if (this.cardRange.length) {
                    this.cardRange = [this.cardRange[0], this.$moment(Date.now()).format('YYYY-MM-DD')];
                    this.baseForm.card_valid_edate = '';
                }
            }
        },
        handleBusCheckboxChange(value) { // 营业执照有效期
            if (value == 1) {
                this.isSelectBus = true;
                if (this.licenseRange.length) { // 已选择有效期后进行修改
                    this.licenseRange = [this.licenseRange[0], '2099-01-01'];
                    this.baseForm.bus_license_valid_edate = '2099-01-01';
                }
            } else {
                this.isSelectBus = false;
                if (this.licenseRange.length) {
                    this.licenseRange = [this.licenseRange[0], this.$moment(Date.now()).format('YYYY-MM-DD')];
                    this.baseForm.bus_license_valid_edate = '';
                }
            }
        },
        handleBeforeUpload(file) {
            let isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            return isLt5M;
        },
        handleImgLoading() { // 上传文件时加载loading
            this.imgLoading = true;
        },
        handleBusinessLicenseImg(res) { // 营业执照
            this.upImg({
                res,
                path: 'bus_license_img',
                img: 'bus_license_img_path'
            });
        },
        handleOpenAccountImg(res) { // 开户许可证
            this.upImg({
                res,
                path: 'opening_permit_img',
                img: 'opening_permit_img_path'
            });
        },
        handleFrontIdCardImg(res) { // 身份证正面
            this.upImg({
                res,
                path: 'card_positive_img',
                img: 'card_positive_img_path'
            });
        },
        handleBackIdCardImg(res) { // 身份证反面
            this.upImg({
                res,
                path: 'card_negative_img',
                img: 'card_negative_img_path'
            });
        },
        getRole() { // 获取代理商角色
            this.$api.post("agent/get_role_list", {}, res => {
                if (res.code == 0) {
                    this.roleList = res.data;
                }
            })
        },
        upImg(res) { //公用
            let resData = res.res;
            if (resData.code === 0) {
                this.baseForm[res.img] = resData.data.file_url; //展示路径
                this.baseForm[res.path] = resData.data.file_path //请求路径
            } else {
                //错误提示
                this.$message.error(res.msg);
            }
            this.imgLoading = false;
        },
        getData() { // 获取结算信息
            return new Promise((resolve) => {
                this.initLoading = true;
                this.$api.post('agent/get_update_back', { id: this.info.row.id, type: 1 }, res => {
                    if (res.code == 0) {
                        let data = res.data;
                        this.baseForm = { ...this.baseForm, ...data };
                        resolve(data);
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.initLoading = false;
                })
            })

        },
        handleLicenseChange(dateArr) { // 营业执照有效期处理
            if (dateArr) {
                this.baseForm.bus_license_valid_sdate = dateArr[0];
                this.baseForm.bus_license_valid_edate = dateArr[1];
            }
        },
        handleCardChange(dateArr) { // 身份证有效期处理
            if (dateArr) {
                this.baseForm.card_valid_sdate = dateArr[0];
                this.baseForm.card_valid_edate = dateArr[1];
            }
        }
    }
}
</script>

<style scoped lang="scss">
.el-form-item {
    margin-bottom: 22px;
}
.m-title {
    color: #303133;
    font-size: 14px;
    font-weight: 700;
    padding-left: 10px;
    margin: 10px 0;
    border-left: 4px solid #409eff;
}
.line {
    text-align: center;
}
::v-deep .el-input-group__append {
    color: #ffffff;
    font-size: 12px;
    background-color: #409eff;
}
.el-form-item__content .el-input-group {
    vertical-align: middle;
}
.avatar-uploader {
    width: 200px;
    height: 130px;
    border-radius: 10px;
    overflow: hidden;
}
::v-deep .avatar-uploader .el-upload {
    width: 100%;
    height: 100%;
}
.avatar {
    width: 100%;
    height: 100%;
}
</style>
