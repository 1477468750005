<template>
    <div class="terminalAudit">
        <el-dialog
            ref="terminalAudit"
            :visible="auditIsShow"
            @close="handleClose"
            :close-on-click-modal="false"
            title="终端费率修改审核"
        >
            <el-card class="box-card" shadow="hover">
                <el-form :model="auditForm" label-position="top">
                    <el-form-item label="审核方式">
                        <el-select size v-model="auditForm.type" clearable placeholder="请选择审核结果">
                            <el-option label="审核通过" value="1"></el-option>
                            <el-option label="审核拒绝" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="拒绝原因" v-if="auditForm.type == 2">
                        <el-input
                            type="textarea"
                            v-model="auditForm.describe"
                            placeholder="请输入审核拒绝原因"
                        ></el-input>
                    </el-form-item>
                    <el-form-item style="margin-top:20px">
                        <el-button
                            type="primary"
                            :loading="auditLoading"
                            style="font-size: 12px;"
                            @click="submit(auditForm)"
                        >提交审核</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "MAM_terminalAudit",
    props: {
        auditIsShow: {
            type: Boolean,
            default: false
        },
        id: {
            type: [Number, String]
        }
    },
    data() {
        return {
            auditLoading: false,
            auditForm: {
                apply_id: '', //申请id
                type: '', //审核方式
                describe: '' //拒绝理由
            }
        }
    },
    mounted() {
        this.auditForm.apply_id = this.id;
    },
    methods: {
        submit(reqData) { //确认冻结
            let that = this;
            this.auditLoading = true; //防重复
            this.$api.post('terminal/checkTerminalRateApply', reqData, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.handleClose(1); //刷新父页面
                        }
                    })
                } else {
                    this.$message.error(res.msg);
                    this.auditLoading = false;
                }
            })
        },
        handleClose(ref) {
            this.$emit('auditClose', ref)
        }
    }
}
</script>

<style scoped lang="scss">
.terminalAudit {
    ::v-deep .el-form-item__label {
        font-size: 12px;
        font-weight: 700;
    }
    .el-textarea {
        height: 80px;
        ::v-deep .el-textarea__inner {
            font-size: 12px;
            min-height: 80px !important;
        }
    }
}
</style>
