<template>
    <div class>
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm">
                <el-form-item>
                    <el-select v-model="searchForm.role_id" clearable placeholder="请选择代理商所属角色">
                        <el-option
                            v-for="item in roleList"
                            :label="item.name"
                            :value="item.id"
                            :key="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="searchForm.is_direct_signature"
                        clearable
                        placeholder="请选择是否直签代理"
                    >
                        <el-option label="是" :value="1"></el-option>
                        <el-option label="否" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.exhibition_mode" clearable placeholder="请选择展业模式">
                        <el-option label="传统模式" :value="1"></el-option>
                        <el-option label="直营模式" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.agent_code" placeholder="请输入代理商编号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.agent_name" placeholder="请输入代理商名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="searchForm.legal_person"
                        placeholder="请输入法人名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="signInDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="申请开始日期"
                        end-placeholder="申请结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="handleSignInDateChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div class="add-tools">
                <div>
                    <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-circle-plus"
                        @click="handleAddAgent"
                    >添加签约代理</el-button>
                </div>
                <div class="rm-word-aux">
                    <el-popover
                        placement="top-start"
                        width="200"
                        trigger="hover"
                        :content="loginUrl"
                    >
                        <el-button
                            slot="reference"
                            size="small"
                            type="primary"
                            icon="el-icon-question"
                        >代理商登录地址</el-button>
                    </el-popover>
                </div>
            </div>
            <el-table
                :data="agentList"
                max-height="500px"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 代理商编号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_code"
                    label="代理商编号"
                    min-width="150"
                ></el-table-column>
                <!-- 代理名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_name"
                    label="代理名称"
                    min-width="150"
                ></el-table-column>
                <!-- 展业模式 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="exhibition_mode_name"
                    label="展业模式"
                    min-width="200"
                ></el-table-column>
                <!-- 所属角色 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="role_name"
                    label="所属角色"
                    min-width="200"
                ></el-table-column>
                <!-- 是否直签 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="is_direct_signature_name"
                    label="是否直签"
                    min-width="120"
                ></el-table-column>
                <!-- 法人名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="legal_person"
                    label="法人名称"
                    min-width="200"
                ></el-table-column>
                <!-- 代理登录账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="username"
                    label="代理登录账号"
                    min-width="150"
                ></el-table-column>
                <!-- APP登录账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="app_phone"
                    label="APP登录账号"
                    min-width="150"
                ></el-table-column>
                <!-- 邀请码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="code"
                    label="邀请码"
                    min-width="120"
                ></el-table-column>
                <!-- 注册时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="注册时间"
                    min-width="150"
                ></el-table-column>
                <!-- 最近登陆 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="type_name"
                    label="最近登陆"
                    min-width="150"
                ></el-table-column>-->
                <!-- 日结开关 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="status_name"
                    label="日结开关"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                            :content="scope.row.clearing_type == 1? '开启' : '关闭'"
                            placement="left"
                        >
                            <el-switch
                                v-model="scope.row.clearing_type"
                                :active-value="1"
                                :inactive-value="2"
                                @change="handleSwitchChange(scope.row)"
                            ></el-switch>
                        </el-tooltip>
                    </template>
                </el-table-column>-->
                <el-table-column
                    header-align="center"
                    align="center"
                    min-width="150"
                    prop="is_unbind"
                    label="解绑权限"
                >
                    <template #default="{row}">
                        <el-switch
                            class="switchStyle"
                            v-model="row.is_unbind"
                            active-text="启用"
                            :active-value="1"
                            inactive-text="禁用"
                            :inactive-value="2"
                            @change="handleUnBindSwitchChange(row)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="200"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">详情</el-button>
                        <el-button
                            @click="handleEidtBaseInfo(scope.row)"
                            type="text"
                            size="small"
                        >基本信息修改</el-button>
                        <el-button
                            @click="handleEditBankInfo(scope.row)"
                            type="text"
                            size="small"
                        >结算信息修改</el-button>
                        <el-button
                            @click="handlePolicyConfig(scope.row)"
                            type="text"
                            size="small"
                        >政策配置</el-button>
                        <!-- <el-button
                            @click="handleSetCost(scope.row)"
                            type="text"
                            size="small"
                        >提现手续费配置</el-button>-->
                        <el-button
                            @click="handleDependence(scope.row)"
                            type="text"
                            size="small"
                        >修改挂靠关系</el-button>
                        <el-button
                            @click="handleResetPassword(scope.row)"
                            type="text"
                            size="small"
                        >重置后台密码</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <!-- 配置提现手续费 -->
        <set-cost v-if="costInfo.isShow" :info="costInfo" @infoClose="handleCloseCost"></set-cost>
        <!-- 基础信息修改 -->
        <base-info v-if="baseDetail.isShow" :info="baseDetail" @infoClose="handleCloseBase"></base-info>
        <!-- 结算信息修改 -->
        <bank-info v-if="bankDetail.isShow" :info="bankDetail" @infoClose="handleCloseBank"></bank-info>
        <!-- 修改挂靠信息 -->
        <agent-dependence
            v-if="dependenceInfo.isShow"
            :info="dependenceInfo"
            @infoClose="handleCloseDependence"
        ></agent-dependence>
    </div>
</template>

<script>

import setCost from './components/setCost'; // 提现手续费配置
import baseInfo from './components/eidtBaseInfo'; // 基础信息修改
import bankInfo from './components/editBankInfo'; // 结算信息修改
import agentDependence from './components/agentDependence'; // 修改挂靠信息

export default {
    data() {
        return {
            loginUrl: '', // 后台登录地址
            signInDate: [], //注册时间
            roleList: [], // 代理商角色
            costInfo: {
                isShow: false,
                row: {}
            },
            baseDetail: {
                isShow: false,
                row: {}
            },
            bankDetail: {
                isShow: false,
                row: {}
            },
            dependenceInfo: {
                isShow: false,
                row: {}
            },
            searchForm: {
                role_id: '', // 所属角色
                exhibition_mode: '', // 展业模式 1.传统 2.直营
                is_direct_signature: '', // 是否直签 1.是 2.否
                agent_code: '', // 代理商编号
                agent_name: '', // 代理商名称
                legal_person: '', // 法人名称
                start_date: '', // 开始时间
                end_date: '', // 结束时间
                page: 1,
                limit: 10
            },
            agentList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false
        }
    },
    created() {
        this.getRole(); // 获取代理商角色
        this.getData({
            page: 1,
            limit: 10
        })
    },
    mounted() {
    },
    components: {
        setCost, // 配置提现手续费
        baseInfo, // 基础信息修改
        bankInfo, // 结算信息修改
        agentDependence // 修改挂靠信息
    },
    methods: {
        handleSearch(searchFrom) { //搜索
            this.currentPage = 1;
            searchFrom.page = 1;
            this.getData(searchFrom);
        },
        handleAddAgent() { // 添加签约代理
            this.$router.push({ path: '/addAgent' })
        },
        handleInfo(row) { //详情
            this.$router.push({ name: 'agentDetail', params: { id: row.id } })
        },
        handleCloseInfo() { //关闭详情
            this.info.isShow = false;
            this.info.row = {};
        },
        handleEidtBaseInfo(row) { // 基本信息修改
            this.baseDetail.isShow = true;
            this.baseDetail.row = row;
        },
        handleCloseBase(ref) { // 关闭基本信息修改
            this.baseDetail.isShow = false;
            this.baseDetail.row = {};
            if (ref) {
                let params = this.searchForm;
                this.getData(params);
            }
        },
        handleEditBankInfo(row) { // 结算信息修改
            this.bankDetail.isShow = true;
            this.bankDetail.row = row;
        },
        handleCloseBank(ref) { //关闭结算信息修改
            this.bankDetail.isShow = false;
            this.bankDetail.row = {};
            if (ref) {
                let params = this.searchForm;
                this.getData(params);
            }
        },
        handlePolicyConfig(row) {
            this.$router.push({ name: 'agentPolicyConfig', params: { id: row.user_id, realName: row.legal_person } });
        },
        handleSetCost(row) { // 提现手续费配置
            this.costInfo.isShow = true;
            this.costInfo.row = row;
        },
        handleCloseCost(ref) { // 关闭提现手续费配置
            this.costInfo.isShow = false;
            this.costInfo.row = {};
            if (ref) {
                let params = this.searchForm;
                this.getData(params);
            }
        },
        handleDependence(row) { // 选择挂靠关系
            this.dependenceInfo.isShow = true;
            this.dependenceInfo.row = row;
        },
        handleCloseDependence(ref) { // 关闭选择挂靠关系
            this.dependenceInfo.isShow = false;
            this.dependenceInfo.row = {};
            if (ref) {
                let params = this.searchForm;
                this.getData(params);
            }
        },
        handleResetPassword(row) { // 重置密码
            this.$confirm('密码重置后，初始密码为hk123456，请确认是否重置该代理商后台登录密码!', '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    let _this = this;
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('agent/reset_password', { agent_login_id: row.agent_login_id, user_id: row.user_id }, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                        _this.getData({
                                            page: _this.currentPage,
                                            limit: _this.pageSize
                                        });
                                    }
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg
                                });
                                done();
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleSwitchChange(row) { // 监听日结开关
            let { user_id, clearing_type } = row;
            this.$api.post('user/update_clearing_type', { user_id, clearing_type }, res => {
                if (res.code != 0) {
                    this.$message.error(res.msg);
                }
            })
        },
        handleUnBindSwitchChange(row, ) { // 处理解绑权限开关
            let tips = '';
            if (row.is_unbind == 1) {
                tips = '请确认是否启用？';
            } else {
                tips = '请确认是否禁用？';
            }
            this.$confirm(tips, '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    // let _this = this;
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('user/setting_user_unbind', { user_id: row.user_id, status: row.is_unbind }, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                    }
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg,
                                    duration: 1500,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                        if (row.is_unbind == 1) {
                                            row.is_unbind = 2;
                                        } else {
                                            row.is_unbind = 1;
                                        }
                                    }
                                });
                            }
                        })
                    } else {
                        done();
                        if (row.is_unbind == 1) {
                            row.is_unbind = 2;
                        } else {
                            row.is_unbind = 1;
                        }
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("agent/get_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.agentList = res.data.list;
                    this.loginUrl = data.login_address;
                    //渲染结束后取消加载
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false;
            })
        },
        getRole() { // 获取代理商角色
            this.$api.post("agent/get_role_list", {}, res => {
                if (res.code == 0) {
                    this.roleList = res.data;
                }
            })
        },
        handleSignInDateChange(DateArr) { //申请时间
            this.searchForm.start_date = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.searchForm.end_date = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
}
</script>

<style scoped lang="scss">
.add-tools {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    div:last-child {
        margin-left: 15px;
    }
}
</style>