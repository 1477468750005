<template>
    <div class="serviceApplyOrder">
        <el-dialog
            width="600px"
            :title="info.type == 1?'添加操作员':'编辑操作员'"
            :visible="info.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-card class="box-card" shadow="always">
                <el-form :model="operatorForm" label-position="left" label-width="80px">
                    <el-form-item label="选择角色：">
                        <el-select
                            v-model="operatorForm.role_id"
                            clearable
                            placeholder="请选择角色"
                            style="width:100%"
                        >
                            <el-option
                                v-for="(item,index) in roleList"
                                :label="item.name"
                                :value="item.id"
                                :key="index"
                                :disabled="item.status != 1"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="登录账号：">
                        <el-input
                            :disabled="info.type == 2"
                            clearable
                            v-model="operatorForm.username"
                            placeholder="请输入登录账号"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="姓名：">
                        <el-input clearable v-model="operatorForm.realname" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号：">
                        <el-input clearable v-model="operatorForm.phone" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="当前状态：">
                        <el-radio-group v-model="operatorForm.status">
                            <el-radio :label="1">启用</el-radio>
                            <el-radio :label="2">禁用</el-radio>
                        </el-radio-group>
                        <div v-if="operatorForm.status == 2" style="font-size:12px;color:#F56C6C">
                            <i class="el-icon-warning" style="font-size:14px;"></i>
                            禁用后，该账号将无法登陆系统
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            size="medium"
                            type="primary"
                            icon="el-icon-check"
                            style="font-size: 12px;"
                            @click="handleSubmit(operatorForm)"
                        >保 存 设 置</el-button>
                        <div style="font-size:12px;color:#F56C6C">
                            <i class="el-icon-info" style="font-size:14px;"></i>
                            默认密码：hk123456
                        </div>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            roleList: [],
            operatorForm: {
                type: this.info.type, //1.添加 2.编辑
                role_id: '', //角色id
                username: '', //账号
                realname: '', //姓名
                phone: '', //手机号
                status: 1 //状态
            }
        };
    },
    mounted() {
        this.getInfo({
            type: 1
        });
        if (this.info.type == 2) { //编辑
            this.getInfo({
                type: 2,
                id: this.info.row.id
            });
        }
    },
    methods: {
        handleSubmit(operatorForm) { //提交
            let _this = this;
            this.$confirm('是否保存该配置？', '提示', {
                confirmButtonText: '保存',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('rule_menu/operator_update_create', operatorForm, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1500,
                                    onClose() {
                                        done();
                                        instance.confirmButtonLoading = false;
                                        _this.handleClose(1);
                                    }
                                })
                            } else {
                                this.$message.error(res.msg);
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleClose(ref) { //关闭
            this.$emit('handleClose', ref)
        },
        getInfo(params) { //获取数据
            this.$api.post("rule_menu/get_operator_management_show", params, res => {
                if (res.code != 0) {
                    this.$message.error(res.msg);
                } else {
                    let data = res.data;
                    if (params.type == 1) { //添加
                        this.roleList = data;
                    } else { //编辑
                        Object.assign(this.operatorForm, res.data);
                    }
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.el-form {
    width: 500px;
}
.form-tree {
    .tree-box {
        // width: 500px;
        height: 400px;
        overflow: auto;
        border-radius: 4px;
        padding: 10px;
        border: 1px solid #dcdfe6;
    }
}
::v-deep .el-tree__empty-text {
    font-size: 12px;
}
</style>