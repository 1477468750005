<template>
    <div class="bigBoss">
        <el-dialog
            title="详情"
            width="550px"
            :visible="detail.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <div class="title">
                <p>
                    <span>终端类型名称：</span>
                    <span>{{title.brandName}}</span>
                </p>
                <p>
                    <span>库存所属：</span>
                    <span>{{title.belongTo}}</span>
                </p>
                <p>
                    <span>{{titleCount}}：</span>
                    <span>{{title.count}}</span>
                </p>
            </div>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="terminal_sn" placeholder="请输入SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(terminal_sn)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
            <el-table
                :data="stockInfoList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- Sn -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="left_show"
                    :label="leftName"
                    min-width="200"
                ></el-table-column>
                <!-- 被划拨人||入库时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="right_show"
                    :label="rightName"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    label="操作"
                    min-width="120"
                    v-if="detail.type == 1 && detail.info.user_id == 0"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleDel(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                small
                layout="prev, pager, next"
                @current-change="getCurrent"
                :total="page.total"
            ></el-pagination>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "MAM_stockInfo",
    props: {
        detail: {
            type: Object
        }
    },
    data() {
        return {
            stockInfoList: [],
            title: {
                brandName: '',
                belongTo: '',
                count: ''
            },
            terminal_sn: '',
            leftName: '',
            rightName: '',
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: false
        };
    },
    mounted() {
        this.getData({
            page: this.page.currentPage,
            limit: this.page.pageSize,
            type: this.detail.type,
            user_id: this.detail.info.user_id,
            brand_id: this.detail.info.brand_id
        });
    },
    methods: {
        onSearch(terminal_sn) {
            this.getData({
                page: 1,
                limit: 10,
                type: this.detail.type,
                terminal_sn,
                user_id: this.detail.info.user_id,
                brand_id: this.detail.info.brand_id
            });
        },
        handleClose(ref) { //关闭弹窗
            this.$emit('infoClose', ref);
        },
        handleDel(row) { //删除
            this.$confirm('确认删除该终端?', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action == 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('terminal/delete_single_terminal', { terminal_id: row.id }, res => {
                            if (res.code == 0) {
                                this.$message.success(res.msg);
                                this.loading = true;
                                this.getData({
                                    page: this.page.currentPage,
                                    limit: this.page.pageSize,
                                    type: this.detail.type,
                                    user_id: this.detail.info.user_id,
                                    brand_id: this.detail.info.brand_id
                                });
                                instance.confirmButtonLoading = false;
                                done();
                            } else {
                                this.$message.error(res.msg);
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                    }
                }
            }).then(() => {
            }).catch(() => { });
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.loading = true;
            this.getData({
                page: this.page.currentPage,
                limit: this.page.pageSize,
                type: this.detail.type,
                user_id: this.detail.info.user_id,
                brand_id: this.detail.info.brand_id
            });
        },
        getData(params) {
            this.$api.post('terminal/get_terminal_list', params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.leftName = data.left_name; //左表头
                this.rightName = data.right_name; //右表头
                this.stockInfoList = data.list; //列表
                this.title.brandName = data.brand_name; //终端类型名称
                this.title.belongTo = data.belong_to; //库存所属
                this.title.count = data.count; //库存数量
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    },
    computed: {
        titleCount() {
            let title;
            switch (this.detail.type) {
                case 1:
                    title = '库存数量';
                    break;
                case 2:
                    title = '划拨中数量';
                    break;
                case 3:
                    title = '退回中数量';
                    break;
                default:
                    title = '入网中数量';
                    break;
            }
            return title;
        }
    }
};
</script>

<style scoped lang="scss">
.bigBoss {
    font-size: 12px;
    .el-input__inner,
    .tips {
        font-size: 12px;
    }

    .el-form-item {
        margin-bottom: 5px;
    }

    ::v-deep .el-form-item__label {
        padding: 0;
        font-size: 12px;
    }
    .titleTip {
        padding: 10px 10px;
        background-color: #ecf8ff;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #50bfff;
        margin: 10px 0;
    }
    .title p {
        font-size: 12px;
        :first-child {
            font-weight: 700;
        }
    }
}
</style>