<template>
    <div class="userInfo">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <el-card class="box-card" shadow="hover">
            <!-- 基础信息 -->
            <div>
                <div class="title">基础信息</div>
                <el-row :gutter="20">
                    <el-col :sm="20" :md="20" :lg="20">
                        <el-row>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="m-title">所属角色：</span>
                                <span>{{info.role_name}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="m-title">代理商名称：</span>
                                <span>{{info.agent_name}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="m-title">账号：</span>
                                <span>{{info.username}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="m-title">邀请码：</span>
                                <span>{{info.code}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="m-title">联系人名称：</span>
                                <span>{{info.contact_name}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="m-title">联系电话：</span>
                                <span>{{info.contact_tel}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="m-title">联系邮箱：</span>
                                <span>{{info.contact_email}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="m-title">联系地址：</span>
                                <span>{{info.contact_addr}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="m-title">注册时间：</span>
                                <span>{{info.create_time}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="m-title">最近登录：</span>
                                <span>{{info.last_login_time}}</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :sm="4" :md="4" :lg="4">
                        <div class="avatarImage">
                            <div class="avatar">
                                <el-image
                                    :src="info.avatar ? info.avatar : 'http://img.mock.98shuju.com/0_gbase/avatar.png'"
                                    :preview-src-list="srcList"
                                >
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline" style="font-size:20px"></i>
                                    </div>
                                </el-image>
                            </div>
                            <div>{{info.nickname}}</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <!-- 资质信息 -->
            <div>
                <div class="title">资质信息</div>
                <el-row :gutter="20">
                    <el-col :sm="12" :md="8" :lg="8">
                        <span class="m-title">姓名：</span>
                        <span>{{info.legal_person}}</span>
                    </el-col>
                    <el-col :sm="12" :md="8" :lg="8">
                        <span class="m-title">身份证号码：</span>
                        <span>{{info.id_card}}</span>
                    </el-col>
                    <el-col :sm="12" :md="8" :lg="8">
                        <span class="m-title">证件有效期：</span>
                        <span>{{info.card_valid_sdate}}至{{info.card_valid_edate}}</span>
                    </el-col>
                    <el-col :sm="12" :md="8" :lg="8">
                        <span class="m-title">身份证正面：</span>
                        <span>
                            <el-button
                                type="text"
                                style="padding:0;font-size:12px"
                                @click="handleViewImage(info.card_positive_img_path)"
                            >点击查看</el-button>
                        </span>
                    </el-col>
                    <el-col :sm="12" :md="8" :lg="8">
                        <span class="m-title">身份证反面：</span>
                        <span>
                            <el-button
                                type="text"
                                style="padding:0;font-size:12px"
                                @click="handleViewImage(info.card_negative_img_path)"
                            >点击查看</el-button>
                        </span>
                    </el-col>
                    <!-- <el-col :sm="12" :md="8" :lg="8">
                        <span class="m-title">法人手持证件照：</span>
                        <span>
                            <el-button
                                type="text"
                                style="padding:0;font-size:12px"
                                @click="handleViewImage(info.card_negative_img)"
                            >点击查看</el-button>
                        </span>
                    </el-col>-->
                    <el-col :sm="12" :md="8" :lg="8">
                        <span class="m-title">社会统一信用代码：</span>
                        <span>{{info.credit_code}}</span>
                    </el-col>
                    <el-col :sm="12" :md="8" :lg="8">
                        <span class="m-title">营业执照有效期：</span>
                        <span>{{info.bus_license_valid_sdate}}至{{info.bus_license_valid_edate}}</span>
                    </el-col>
                    <el-col :sm="12" :md="8" :lg="8">
                        <span class="m-title">营业执照照片：</span>
                        <span>
                            <el-button
                                type="text"
                                style="padding:0;font-size:12px"
                                @click="handleViewImage(info.bus_license_img_path)"
                            >点击查看</el-button>
                        </span>
                    </el-col>
                    <el-col :sm="12" :md="8" :lg="8">
                        <span class="m-title">开户许可证：</span>
                        <span>
                            <el-button
                                type="text"
                                style="padding:0;font-size:12px"
                                @click="handleViewImage(info.opening_permit_img_path)"
                            >点击查看</el-button>
                        </span>
                    </el-col>
                </el-row>
            </div>
            <!-- 终端类型政策 -->
            <!-- <div class="title">
                <el-button type="text" @click="brandTitle = !brandTitle">终端类型政策</el-button>&nbsp;
                <i class="el-icon-arrow-down"></i>
            </div>
            <el-collapse-transition>
                <div v-show="brandTitle">
                    <el-table
                        :data="baseInfo.brand_list"
                        style="width: 100%"
                        height="200"
                        size="small"
                        :header-cell-style="{
                            background:'#EBEEF5'
                        }"
                    >
                        <el-table-column align="center" prop="name" label="终端类型" min-width="180"></el-table-column>
                        <el-table-column align="center" label="结算成本" min-width="180">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="text"
                                    @click="handleSetRate(scope.row)"
                                >设置</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="平台奖励" min-width="180">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="text"
                                    @click="handleSetReward(scope.row)"
                                >设置</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-collapse-transition> -->
            <!-- 银行卡 -->
            <!-- <div class="title">
                <el-button type="text" @click="bankTitle = !bankTitle">银行卡</el-button>&nbsp;
                <i class="el-icon-arrow-down"></i>
            </div>
            <el-collapse-transition>
                <div v-show="bankTitle">
                    <el-table
                        :data="baseInfo.bank_list"
                        style="width: 100%"
                        height="200"
                        size="small"
                        border
                    >
                        <el-table-column
                            align="center"
                            prop="bank_name"
                            label="银行名称"
                            min-width="180"
                        ></el-table-column>
                        <el-table-column align="center" prop="bank_code" label="卡号" min-width="180"></el-table-column>
                        <el-table-column
                            align="center"
                            prop="branch_bank"
                            label="开户行"
                            min-width="180"
                        ></el-table-column>
                        <el-table-column align="center" prop="line_num" label="联行号" min-width="180"></el-table-column>
                        <el-table-column align="center" prop="phone" label="预留手机号" min-width="180"></el-table-column>
                    </el-table>
                </div>
            </el-collapse-transition> -->
        </el-card>
        <!-- 交易费率 -->
        <!-- <user-rate
            v-if="isShowRate"
            :isShowRate="isShowRate"
            :publickData="publickData"
            @rateClose="handleRateClose"
        ></user-rate>-->
        <!-- 平台奖励 -->
        <!-- <user-reward
            v-if="isShowReward"
            :isShowReward="isShowReward"
            :publickData="publickData"
            @rewardClose="handleRewardClose"
        ></user-reward>-->
        <preview-pic
            v-if="picInfo.isShow"
            :picInfo="picInfo"
            @handleCloseImgView="handleCloseImgView"
        ></preview-pic>
    </div>
</template>

<script>
// import userRate from './UM_setUserRate'; //费率组件
// import userReward from './UM_setUserReward'; //奖励组件
import previewPic from '../../../components/previewPic'; //图片预览组件
export default {
    data() {
        return {
            info: {}, // 详情
            picInfo: {
                isShow: false,
                imgUrl: ''
            },
            isShowRate: false, //交易费率
            isShowReward: false, //平台奖励
            isShowRise: false, //平台奖励
            brandTitle: false, //终端类型政策
            bankTitle: false, //银行卡
            baseInfo: {},
            publickData: { //父子组件共用值
                userId: null,
                userName: '',
                brandId: null,
                brandName: '',
                isRise: null //是否支持涨价  0.不支持 1.支持
            },
            srcList: []

        }
    },
    created() {
        this.getAgentDetail();
    },
    mounted() {
        // this.$route.params.id
        // this.publickData.userId = this.$route.query.id; //取id
    },
    components: {
        // userRate,
        // userReward,
        previewPic
    },
    methods: {
        handleSetRate(row) { //费率
            this.publickData.brandId = row.id; //终端类型id
            this.publickData.isRise = row.is_rise_price; //是否支持涨价
            this.publickData.brandName = row.name; //终端类型名称
            this.isShowRate = true
        },
        handleRateClose() { //关闭费率
            this.isShowRate = false;
        },
        handleSetReward(row) { //奖励
            this.publickData.brandId = row.id; //终端类型id
            this.publickData.brandName = row.name; //终端类型名称
            this.isShowReward = true;
        },
        handleRewardClose() { //关闭奖励
            this.isShowReward = false;
        },
        getAgentDetail() { // 获取代理商详情
            this.$api.post('agent/get_info', { id: this.$route.params.id }, res => {
                if (res.code == 0) {
                    this.info = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        handleViewImage(picUrl) { //预览图片
            if (picUrl != '') {
                this.picInfo.isShow = true;
                this.picInfo.imgUrl = picUrl;
            } else {
                this.$message.error('没有可以预览的图片哦！');
            }
        },
        handleCloseImgView() { //关闭图片预览
            this.picInfo.isShow = false;
            this.picInfo.imgUrl = '';
        }
    }
}
</script>

<style scoped lang="scss">
.userInfo {
    font-size: 12px;
    .avatarImage {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .avatar {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            overflow: hidden;
            .el-image {
                width: 100%;
                height: 100%;
                background-color: #c0c4cc;
                ::v-deep .image-slot {
                    text-align: center;
                    line-height: 110px;
                }
            }
        }
        div:nth-child(2) {
            padding: 0 20px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            margin-top: 15px;
            font-size: 13px;
            border-radius: 30px;
            box-shadow: 2px 2px 10px #888888;
            background-color: #ebedee;
        }
    }
    .m-title {
        font-weight: 700;
    }
    .title {
        margin: 10px 0;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #40b8ff;
        padding: 0 0 0 10px;
        .el-button--text {
            color: #303133;
            font-size: 13px;
            font-weight: 700;
        }
    }
    .el-row {
        margin: 10px 0;
        &:last-child {
            margin-bottom: 0;
        }
        .el-col {
            margin: 10px 0;
        }
    }
    // 滚动条
    ::v-deep
        .el-table--scrollable-y
        .el-table__body-wrapper::-webkit-scrollbar {
        width: 4px;
    }
    ::v-deep
        .el-table--scrollable-y
        .el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }
    ::v-deep
        .el-table--scrollable-y
        .el-table__body-wrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }
}
</style>
