<template>
    <div class="MAM_stockList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="stockForm" class="demo-form-inline">
                <el-form-item>
                    <el-select size v-model="stockForm.type" clearable placeholder="请选择库存所属">
                        <el-option label="全部" value="0"></el-option>
                        <el-option label="平台库存" value="1"></el-option>
                        <el-option label="代理库存" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="stockForm.type == 2">
                    <el-input size clearable v-model="stockForm.user_name" placeholder="请输入代理商名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="stockForm.terminal_sn" placeholder="请输入SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(stockForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="addStock">导入库存</el-button>
                <el-button size="small" type="primary" icon="el-icon-delete" @click="delStock">删除库存</el-button>
            </div> -->
            <el-table
                :data="stockTable.list"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 库存所属 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="库存所属"
                    min-width="120"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="120"
                ></el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="my_stock"
                    label="当前库存总台数"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleTotal(scope.row)"
                            type="text"
                            size="small"
                        >{{scope.row.my_stock}}</el-button>
                    </template>
                </el-table-column>
                <!-- 划拨中台数 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="my_in_transfer"
                    label="划拨中台数"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleTransfer(scope.row)"
                            type="text"
                            size="small"
                        >{{scope.row.my_in_transfer}}</el-button>
                    </template>
                </el-table-column>
                <!-- 退回中台数 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="my_in_return"
                    label="退回中台数"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleReturn(scope.row)"
                            type="text"
                            size="small"
                        >{{scope.row.my_in_return}}</el-button>
                    </template>
                </el-table-column>
                <!-- 入网中 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="my_in_net"
                    label="入网中台数"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleNet(scope.row)"
                            type="text"
                            size="small"
                        >{{scope.row.my_in_net}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <stock-tools
            v-if="toolsIsShow"
            :toolsIsShow="toolsIsShow"
            :isAdd="isAdd"
            @toolsClose="toolsClose"
        ></stock-tools>
        <stock-info v-if="detail.isShow" :detail="detail" @infoClose="infoClose"></stock-info>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import stockTools from './MAM_stockTools';
import stockInfo from './MAM_stockInfo';
export default {
    name: "MAM_stockList",
    data() {
        return {
            isAdd: false, //添加库存、删除库存
            isTotal: false, //是否为库存总台数
            toolsIsShow: false, //导入、删除库存
            infoIsShow: false, //详情
            detail: {
                isShow: false,
                type: null, //1.当前库存 2.划拨中 3.退回中 4.入网中
                info: null
            },
            stockForm: {
                type: '',
                user_name: '',
                terminal_sn: ''
            },
            stockTable: {
                list: [],
            },
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 100  //总数
            },
            loading: true
        }
    },
    mounted() {
        //初始化
        this.getData({
            page: this.page.currentPage,
            limit: this.page.pageSize
        })
    },
    components: {
        paginations,
        stockTools,
        stockInfo
    },
    methods: {
        onSearch(stockForm) {
            this.page.currentPage = 1;
            stockForm.page = this.page.currentPage;
            stockForm.limit = this.page.pageSize;
            // 表单提交
            this.getData(stockForm);
        },
        handleTotal(row) { //剩余台数
            this.detail.isShow = true;
            this.detail.type = 1;
            this.detail.info = row;
        },
        handleTransfer(row) { //划拨中台数
            this.detail.isShow = true;
            this.detail.type = 2;
            this.detail.info = row;
        },
        handleReturn(row) { //退回中台数
            this.detail.isShow = true;
            this.detail.type = 3;
            this.detail.info = row;
        },
        handleNet(row) {
            this.detail.isShow = true;
            this.detail.type = 4;
            this.detail.info = row;
        },
        infoClose() { //关闭详情
            this.detail.isShow = false;
        },
        addStock() {
            this.isAdd = true
            this.toolsIsShow = true;
        },
        delStock() {
            this.toolsIsShow = true;
        },
        toolsClose(ref) { //关闭导入、删除库存
            this.isAdd = false;
            this.toolsIsShow = false;
            if (ref) { //重载表格
                this.getData({
                    page: this.page.currentPage,
                    limit: this.page.pageSize
                })
            }
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.loading = true;
            this.page.currentPage = page;
            this.stockForm.page = page;
            this.stockForm.limit = this.page.pageSize;
            this.getData(this.stockForm)
        },
        getPageSize(limit) {
            this.page.currentPage = 1;
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.stockForm.page = this.page.currentPage;
            this.stockForm.limit = limit;
            this.getData(this.stockForm);
        },
        getData(params) {
            this.$api.post("terminal/get_stock_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.stockTable.list = data.list;
                //渲染结束后取消加载
                this.loading = false;
            });
        }
    },
    watch: {
        'stockForm.type'(newVal) {
            if (newVal != 2) {
                this.stockForm.user_name = '';
            }
        }
    }
};
</script>

<style lang="scss" scope>
.MAM_stockList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
