<template>
    <div class="showlogo">
        <div class="logo" v-if="!isCollapse">{{title}}</div>
        <div class="logom" v-else>{{title}}</div>

        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu
                :default-active="$route.path"
                class="el-menu-vertical-demo"
                :background-color="variables.menuBg"
                :text-color="variables.menuText"
                :active-text-color="variables.menuActiveText"
                :collapse="isCollapse"
                unique-opened
                router
                @open="handleopen"
                @close="handleclose"
                @select="handleselect"
            >
                <template v-for="(item,index) in routeslist">
                    <!-- <div v-if="!item.hidden" :key="index"> -->
                    <template v-if="item.children.length > 1">
                        <el-submenu :key="index" :index="index+''">
                            <template slot="title">
                                <i :class="item.icon"></i>
                                <span slot="title">{{item.title}}</span>
                            </template>
                            <el-menu-item
                                v-for="child in item.children"
                                :index="child.path"
                                :key="child.path"
                                v-show="child.name != 'hidden'"
                            >{{child.title}}</el-menu-item>
                        </el-submenu>
                    </template>
                    <template v-else>
                        <el-menu-item :key="index" :index="item.children[0].path">
                            <i :class="item.icon"></i>
                            <span v-if="!isCollapse">{{item.title}}</span>
                        </el-menu-item>
                    </template>
                    <!-- </div> -->
                </template>
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
import variables from "@/assets/styles/variables.scss";
import { mapGetters } from "vuex";
import sideBarList from './sideBarList';
export default {
    name: "index",
    data() {
        return {
            title: '',
            routeslists: sideBarList, //测试数据
            routeslist: {},
        };
    },
    created() {
        this.$api.post("menu/sub_menu_list", {}, res => {
            if (res.code === 0) {
                this.routeslist = res.data
            } else {
                this.$message.error(res.code);
            }
        });
    },
    mounted() {
        this.title = sessionStorage.getItem('title'); //机构名称
    },
    computed: {
        ...mapGetters(["sidebar"]),
        variables() {
            return variables;
        },
        isCollapse() {
            return !this.sidebar.opened;
        }
    },
    methods: {
        handleopen() {
            //console.log('handleopen');
        },
        handleclose() {
            //console.log('handleclose');
        },
        // eslint-disable-next-line
        handleselect(index, indexPath) {
            // console.log(index)
            // console.log(indexPath)
        }
    }
};

</script>

<style scoped lang="scss">
.logo {
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #eee;
    background-color: #23262e;
}
.logom {
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #eee;
    text-align: center;
    background-color: #23262e;
}
::v-deep .el-submenu__title {
    font-size: 13px;
}
.el-menu-item {
    font-size: 13px;
}
</style>
