<template>
    <div class="amortizationSetting">
        <el-card class="box-card" shadow="always">
            <el-form
                :model="submitForm"
                label-position="top"
                label-width="100px"
                @submit.native.prevent
            >
                <!-- <el-form-item>
                    <span class="label-border" slot="label">是否有标准利息：</span>
                    <template>
                        <el-form :inline="true">
                            <el-form-item>
                                <el-radio-group v-model="submitForm.withdraw_fee_switch">
                                    <el-radio label="1">是</el-radio>
                                    <el-radio label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-form-item>
                <el-form-item>
                    <span class="label-border" slot="label">利息归属方：</span>
                    <template>
                        <el-form :inline="true">
                            <el-form-item>
                                <span class="rm-word-aux">还款日前</span>
                            </el-form-item>
                            <el-form-item>
                                <el-input
                                    size="medium"
                                    v-model="submitForm.at_once_money"
                                    placeholder="请输入数字"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <span class="rm-word-aux">天，发送账单</span>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-form-item>-->
                <el-form-item>
                    <span class="label-border" slot="label">还款日设置：</span>
                    <template>
                        <el-form :inline="true" @submit.native.prevent>
                            <el-form-item>
                                <span class="rm-word-aux">每月</span>
                            </el-form-item>
                            <el-form-item>
                                <el-input
                                    class="input-text_point"
                                    size="medium"
                                    v-model.number="submitForm.repayment_day"
                                    placeholder="请输入6~28之间"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <span class="rm-word-aux">日，还款。</span>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-form-item>
                <el-form-item>
                    <span class="label-border" slot="label">账单日设置：</span>
                    <template>
                        <el-form :inline="true" @submit.native.prevent>
                            <el-form-item>
                                <span class="rm-word-aux">还款日前</span>
                            </el-form-item>
                            <el-form-item>
                                <el-input
                                    class="input-text_point"
                                    size="medium"
                                    v-model.number="submitForm.bill_day"
                                    placeholder="请输入1~5之间"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <span class="rm-word-aux">天，发送账单通知。</span>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-form-item>
            </el-form>
            <div style="margin-top:20px">
                <el-button
                    type="primary"
                    :loading="btnLoading"
                    style="font-size: 12px;"
                    @click="handleSubmit(submitForm)"
                >保存设置</el-button>
                <el-button @click="showUpdateRecord" type="text" size="small">查看修改记录</el-button>
            </div>
        </el-card>
        <el-card class="box-card" style="margin-top: 20px;" shadow="always" v-if="showHideRecord">
            <el-button
                @click="hideUpdateRecord"
                type="text"
                size="small"
                icon="el-icon-caret-top"
            >隐藏修改记录</el-button>
            <el-table
                :data="updateRecordList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 还款日 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="repayment_day"
                    label="还款日"
                    min-width="100"
                ></el-table-column>
                <!-- 账单日 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="bill_day"
                    label="账单日"
                    min-width="100"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="状态"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">未生效</span>
                        <span v-else-if="scope.row.status == 2">生效</span>
                        <span v-else>失效</span>
                    </template>
                </el-table-column>
                <!-- 提交时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="提交时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作员 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="admin_name"
                    label="操作员"
                    min-width="120"
                ></el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "amortizationSetting",
    data() {
        return {
            loading: false, //记录列表
            btnLoading: false, //提交loading
            submitForm: {
                repayment_day: '', //还款日
                bill_day: '' //账单日
            },
            showHideRecord: false, //记录列表是否展示
            updateRecordList: []
        }
    },
    mounted() {
        this.getConfig();
    },
    methods: {
        handleSubmit(filed) { //提交
            let reg = /[\S]+/; //必填
            let _this = this;
            if (!reg.test(filed.repayment_day)) {
                this.$message.error('还款日不能为空');
            } else
            if (!reg.test(filed.bill_day)) {
                this.$message.error('账单日不能为空');
            } else {
                this.btnLoading = true;
                filed.k = 'installment';
                this.$api.post('setting/update_setting', filed, res => {
                    if (res.code == 0) {
                        this.$message({
                            type: 'success',
                            duration: 1500,
                            message: res.msg,
                            onClose() {
                                _this.btnLoading = false;
                                if (_this.showUpdateRecord) { //如果修改记录展示状态则刷新
                                    _this.getRecord();
                                }
                            }
                        })
                    } else {
                        this.$message.error(res.msg);
                        this.btnLoading = false;
                    }
                })
            }
        },
        showUpdateRecord() { //查看修改记录
            this.showHideRecord = true;
            this.getRecord();
        },
        hideUpdateRecord() { //隐藏修改记录
            this.showHideRecord = false;
            this.updateRecordList = [];
        },
        getConfig() { //获取设置
            this.$api.post('setting/getSetting', { k: 'installment' }, res => {
                if (res.code == 0) {
                    this.submitForm = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        getRecord() { //分期设置记录列表
            this.loading = true;
            this.$api.post('installment_setting_log/get_list', {}, res => {
                if (res.code == 0) {
                    this.updateRecordList = res.data.list;
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.amortizationSetting {
    ::v-deep .el-form {
        min-width: 500px;
        .el-input {
            max-width: 500px;
        }
    }
    ::v-deep .input-text_point {
        width: 120px;
        .el-input__inner {
            text-align: center;
        }
    }
    .label-border {
        border-left: 4px solid #606266;
        padding-left: 5px;
    }
}
</style>
