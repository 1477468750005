/** 商户管理 */
import merchantNet from '../../views/merchantManagement/merchantNet'; //商户进件管理列表
import merchantNetInfo from '../../views/merchantManagement/merchantNetInfo'; //商户详情
import merchantClassSetting from '../../views/merchantManagement/merchantClassList'; //商户分类设置
import rateTemplateManagement from '../../views/merchantManagement/rateTemplateList'; //费率模板管理
import terminalMerchant from '../../views/machineManagement/terminalMerchant/MAM_terminalList'; //终端&商户
import rateChange from '../../views/machineManagement/rateChange/MAM_rateList'; //费率修改记录
import rateInfo from '../../views/machineManagement/rateChange/MAM_rateInfo'; //费率修改记录详情
import ImportResultList from '../../views/merchantManagement/ImportResultList'; // 导入结果查询

export default [
    {
        path: '/merchantNet',
        name: 'merchantNet',
        meta: {
            title: '商户进件管理'
        },
        component: merchantNet
    },
    {
        path: '/merchantNetInfo',
        name: 'merchantNetInfo',
        meta: {
            title: '商户详情'
        },
        component: merchantNetInfo
    },
    {
        path: '/rateTemplateManagement',
        name: 'rateTemplateManagement',
        meta: {
            title: '费率模板管理'
        },
        component: rateTemplateManagement
    },
    {
        path: '/merchantClassSetting',
        name: 'merchantClassSetting',
        meta: {
            title: '商户分类设置'
        },
        component: merchantClassSetting
    },
    {
        path: '/terminalMerchant',
        name: 'terminalMerchant',
        meta: {
            title: '终端&商户'
        },
        component: terminalMerchant
    },
    {
        path: '/rateChange',
        name: 'rateChange',
        meta: {
            title: '费率修改记录'
        },
        component: rateChange
    },
    {
        path: '/rateInfo',
        name: 'rateInfo',
        meta: {
            title: '费率修改记录详情'
        },
        component: rateInfo
    },
    {
        path: '/importUpdateRate',
        name: 'importUpdateRate',
        meta: {
            title: '导入结果查询'
        },
        component: ImportResultList
    }
]