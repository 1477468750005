<template>
    <div class="BC_categoryList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="searchForm.name" placeholder="请输入分类名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="searchForm.status" clearable placeholder="请选择显示状态">
                        <el-option label="显示" value="1"></el-option>
                        <el-option label="隐藏" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="addCategory">添加分类</el-button>
            </div>
            <el-table
                :data="categoryList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 分类名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="name"
                    label="分类名称"
                    min-width="120"
                ></el-table-column>
                <!-- 文章数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="count"
                    label="文章数量"
                    min-width="150"
                ></el-table-column>
                <!-- 排序 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="sort"
                    label="排序"
                    min-width="150"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="状态"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.status"
                            active-text="显示"
                            inactive-text="隐藏"
                            :active-value="1"
                            :inactive-value="0"
                            @change="statusChange(scope.row)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>&nbsp;
                        <el-popconfirm
                            title="是否确认删除此分类？"
                            @confirm="handleDel(scope.row)"
                            v-if="scope.row.status == 0"
                        >
                            <el-button slot="reference" type="text" size="small">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <category-info v-if="info.isShow" :info="info" @infoClose="infoClose"></category-info>
    </div>
</template>

<script>
import categoryInfo from './BC_categoryInfo';
export default {
    name: "BC_categoryList",
    data() {
        return {
            categoryList: [], //table
            searchForm: {
                page: 1,
                limit: 10,
                name: '',
                status: ''
            },
            info: {
                isShow: false,
                type: null, //1.新增 2.编辑
                id: null
            },
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true,
            flag: true, //删除防重复
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        })
    },
    components: {
        categoryInfo
    },
    methods: {
        onSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        addCategory() { //添加分类
            this.info.isShow = true;
            this.info.type = 1;
            this.info.id = null;
        },
        handleEdit(row) { //编辑分类
            this.info.isShow = true;
            this.info.type = 2;
            this.info.id = row.id;
        },
        infoClose(ref) {
            this.info.isShow = false;
            if (ref) {
                this.getData({
                    page: this.currentPage,
                    limit: this.pageSize
                })
            }
        },
        handleDel(row) { //删除
            if (this.flag) {
                this.flag = false;
                this.$api.post('college/category_delete', { id: row.id }, res => {
                    if (res.code == 0) {
                        this.$message.success(res.msg);
                        this.getData({
                            page: this.currentPage,
                            limit: this.pageSize
                        })
                        this.flag = true;
                    } else {
                        this.$message.error(res.msg);
                        this.flag = true;
                    }
                })
            }
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("college/category_list", params, res => {
                let data = res.data;
                this.total = data.count; //总数
                this.categoryList = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        statusChange(row) { //改变状态
            console.log(row)
            this.$api.post('college/category_status', {
                id: row.id,
                status: row.status
            }, res => {
                if (res.code == 0) {
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                    row.status = row.status == 0 ? 1 : 0;
                }
            })
        },
    }
};
</script>

<style lang="scss" scope>
.BC_categoryList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
    .el-switch__label *,
    .el-button--text {
        font-size: 12px;
    }
}
</style>
