<template>
    <div class="downloadList">
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-table
                :data="downloadList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="name"
                    label="文件名"
                    min-width="150"
                ></el-table-column>
                <!-- 申请人 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="admin_id"
                    label="申请人"
                    min-width="150"
                ></el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="创建时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.status == 20"
                            @click="handleDownload(scope.row)"
                            type="text"
                            size="small"
                        >
                            <el-link
                                :underline="false"
                                type="primary"
                                :href="scope.row.file_url"
                                target="_blank"
                                style="font-size:12px"
                            >下载</el-link>
                        </el-button>
                        <el-button v-else-if="scope.row.status == 10" type="text" size="small">队列中</el-button>
                        <el-button v-else-if="scope.row.status == 30" type="text" size="small">失败</el-button>
                        <el-button v-else type="text" size="small">处理中</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "downloadList",
    data() {
        return {
            downloadList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        })
    },
    methods: {
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.getData({
                page: page,
                limit: this.pageSize
            });
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.getData({
                page: this.currentPage,
                limit: limit
            });
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("export/list", params, res => {
                let data = res.data;
                this.total = data.count; //总数
                this.downloadList = res.data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
