import Cookies from 'js-cookie'

const state = {
    sidebar:{
        opened:Cookies.get('sidebarStatus')? !!+Cookies.get('sidebarStatus'):true
    }
}

const mutations = {
    TOGGLE_SIDEBAR: state => {
        state.sidebar.opened = !state.sidebar.opened
        if(state.sidebar.opened){
            Cookies.set('sidebarStatus',1)
        }else {
            Cookies.set('sidebarStatus',0)
        }
    },
    CLOSE_SIDEBAR:(state) => {
      Cookies.set('sidebarStatus',0)
      state.sidebar.opened = false
    }
}

const actions = {
    toggleSideBar({commit}){
        commit('TOGGLE_SIDEBAR')
    },
    closeSideBar({commit}){
         commit('CLOSE_SIDEBAR')
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}