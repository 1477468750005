<template>
    <div class="FM_purseInfo">
        <!-- 剩余扣款钱包/冻结钱包 1.冻结 2.剩余扣款 -->
        <el-dialog
            ref="terminalAudit"
            :visible="infoIsShow"
            @close="handleClose"
            :close-on-click-modal="false"
        >
            <el-card class="box-card" shadow="hover">
                <!-- 冻结 -->
                <div style="margin-bottom:10px" v-if="detail.infoType != 2">
                    <div class="purseTitle">
                        <div>钱包定义：</div>
                        <p>由于代理当前层级链条，有出现挂账，无法入账的金额。</p>
                    </div>
                    <div class="purseTitle">
                        <div>挂账订单：</div>
                    </div>
                </div>
                <!-- 剩余扣款 -->
                <div style="margin-bottom:10px" v-if="detail.infoType != 1">
                    <div class="purseTitle">
                        <div>钱包定义：</div>
                        <p v-show="detail.data.repayment_type == 2">
                            1、进行还款时，当前代理代理商余额不足还款时，记录挂账。
                            <br />2、SN触发风控规则后，账户余额不足时，进入扣款钱包。
                        </p>
                        <p v-show="detail.data.repayment_type == 1">进行还款时，当前代理代理商余额不足还款时，记录挂账。</p>
                    </div>
                    <div class="purseTitle">
                        <div>挂账订单：</div>
                    </div>
                </div>
                <el-table
                    :data="purseInfo"
                    size="mini"
                    border
                    style="width: 100%"
                    v-loading="loading"
                >
                    <!-- id -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        fixed
                        prop="id"
                        label="序号"
                        min-width="80"
                    ></el-table-column>
                    <!-- 订单号 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="order_num"
                        label="订单号"
                        min-width="200"
                    ></el-table-column>
                    <!-- 终端类型 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="brand_name"
                        label="终端类型"
                        min-width="120"
                    ></el-table-column>
                    <!-- SN码 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="sn"
                        label="SN码"
                        min-width="150"
                    ></el-table-column>
                    <!-- 挂账类型 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="type"
                        label="挂账类型"
                        min-width="200"
                        :formatter="formatType"
                    ></el-table-column>
                    <!-- 冻结时间/挂账时间 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="create_time"
                        :label="detail.infoType == 1? '冻结时间' : '挂账时间'"
                        min-width="200"
                    ></el-table-column>
                    <!-- 冻结金额/挂账金额 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="money"
                        :label="detail.infoType == 1? '冻结金额' : '挂账金额'"
                        min-width="200"
                    ></el-table-column>
                    <!-- 处理结果 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="is_deal"
                        label="处理结果"
                        min-width="200"
                        :formatter="formatDeal"
                    ></el-table-column>
                </el-table>
                <!-- 分页部分 -->
                <paginations
                    :currentPage="page.currentPage"
                    :pageSize="page.pageSize"
                    :total="page.total"
                    @watchCurrent="getCurrent"
                    @watchPageSize="getPageSize"
                ></paginations>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
export default {
    name: "FM_purseInfo",
    props: {
        infoIsShow: {
            type: Boolean,
            default: false
        },
        detail: {
            type: Object
        }
    },
    data() {
        return {
            purseInfo: [], //表格
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            reqData: { //请求的数据
                page: 1,
                limit: 10,
                type: null, //1.冻结金 2.挂账(剩余扣款)
                user_id: null
            },
            loading: false,
        }
    },
    mounted() {
        this.reqData.type = this.detail.infoType; //类型
        this.reqData.user_id = this.detail.data.id; //代理商id
        this.getData(this.reqData);//初始化
    },
    components: {
        paginations
    },
    methods: {
        handleClose() {
            this.$emit('infoClose')
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.reqData.page = this.page.currentPage;
            this.reqData.limit = this.page.pageSize;
            this.getData(this.reqData);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.reqData.page = this.page.currentPage;
            this.reqData.limit = limit;
            this.getData(this.reqData);
        },
        getData(params) {
            this.$api.post("user/abnormal_log", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.purseInfo = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        formatDeal(row) { //处理结果
            let deal;
            switch (row.is_deal) {
                case 0:
                    deal = '未处理'
                    break;
                default:
                    deal = '已处理'
                    break;
            }
            return deal;
        },
        formatType(row) { //处理结果
            let type;
            switch (row.type) {
                case 1:
                    type = '交易分润'
                    break;
                case 2:
                    type = '激活奖励'
                    break;
                case 3:
                    type = '风控规则扣款'
                    break;
                case 4:
                    type = '账单还款'
                    break;
            }
            return type;
        },

    }
};
</script>

<style lang="scss" scope>
.FM_purseInfo {
    .purseTitle {
        font-size: 12px;
        color: #909399;
        :nth-child(1) {
            font-weight: 700;
            color: #7f7f7f;
        }
    }
    // .el-dialog__header {
    //     padding: 20px 20px 0px;
    // }
    // .el-dialog__body {
    //     padding: 0px 20px 30px 20px;
    // }
    // .el-input__inner {
    //     font-size: 12px;
    // }
    // .el-form-item {
    //     margin-bottom: 5px;
    // }
}
</style>
