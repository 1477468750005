<template>
    <div class="serviceRenewRecord">
        <!-- 表格部分 -->
        <!-- <el-card class="box-card" style="margin-top: 20px;" shadow="hover"> -->
        <el-table
            :data="recordList"
            size="mini"
            border
            style="width: 100%"
            v-loading="loading"
            max-height="500px"
        >
            <!-- 续费金额 -->
            <el-table-column
                header-align="center"
                align="center"
                prop="money"
                label="续费金额"
                min-width="80"
            ></el-table-column>
            <!-- 续费时间 -->
            <el-table-column
                header-align="center"
                align="center"
                prop="create_time"
                label="续费时间"
                min-width="150"
            ></el-table-column>
            <!-- 续费状态 -->
            <el-table-column
                header-align="center"
                align="center"
                prop="status_name"
                label="续费状态"
                min-width="120"
            ></el-table-column>
        </el-table>
        <!-- 分页部分 -->
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10,20,50,100]"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
        <!-- </el-card> -->
    </div>
</template>

<script>
export default {
    name: "serviceRenewRecord",
    props: {
        recordId: {
            type: [Number, String]
        }
    },
    data() {
        return {
            recordList: [], //续费记录列表
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true
        }
    },
    mounted() {
    },
    methods: {
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.getData({
                id: this.recordId,
                page: this.currentPage,
                limit: this.pageSize
            });
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.getData({
                id: this.recordId,
                page: this.currentPage,
                limit: this.pageSize
            });
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("online_service/get_online_service_renewal_record", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.recordList = data.list;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    },
    watch: {
        recordId(newVal) { //监听id变化
            this.getData({
                id: newVal,
                page: 1,
                limit: 10
            })
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
