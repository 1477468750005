<template>
    <!-- 平台支出统计详情 -->
    <div class="moneyStatisticsDetail">
        <el-dialog
            :visible="info.isShow"
            @close="handleClose"
            :close-on-click-modal="false"
            width="850px"
        >
            <el-card class="box-card" shadow="hover">
                <el-table
                    :data="detailList"
                    size="mini"
                    border
                    style="width: 100%"
                    v-loading="loading"
                    max-height="500px"
                >
                    <!-- id -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        fixed
                        prop="id"
                        label="序号"
                        min-width="80"
                    ></el-table-column>
                    <!-- 终端类型 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="brand"
                        label="终端类型"
                        min-width="150"
                    ></el-table-column>
                    <!-- 总支出 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="total_money"
                        label="总支出"
                        min-width="150"
                    ></el-table-column>
                    <!-- 奖励支出 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="award_money"
                        label="奖励支出"
                        min-width="150"
                    ></el-table-column>
                    <!-- 分润支出 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="profit_money"
                        label="分润支出"
                        min-width="150"
                    ></el-table-column>
                </el-table>
                <!-- 分页部分 -->
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,50,100]"
                    :current-page="page"
                    :page-size="limit"
                    :total="total"
                    layout="total, sizes, prev, pager, next, jumper"
                ></el-pagination>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            page: 1, //当前页码
            limit: 10, //条数
            total: 0,  //总数
            detailList: [],
            loading: false,
        }
    },
    mounted() {
        this.getData({ //初始化
            page: 1,
            limit: 10
        });
    },
    methods: {
        handleClose() {
            this.$emit('infoClose');
        },
        handleCurrentChange(page) { //当前选中页
            this.page = page;
            this.getData({
                page: page,
                limit: this.limit
            })
        },
        handleSizeChange(limit) { //条数
            this.page = 1;
            this.limit = limit;
            this.getData({
                page: 1,
                limit: limit
            })
        },
        getData(obj) { //初始化
            this.loading = true;
            let { month } = this.info.row;
            let params = { ...{ month }, ...obj };
            this.$api.post("organization/brand_money_statistics_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.detailList = data.list;
                    //渲染结束后取消加载
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
