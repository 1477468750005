<template>
    <div class="UM_realNameList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="realForm" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="realForm.phone" placeholder="请输入代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="realForm.real_name" placeholder="请输入代理商真实姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="realForm.id_card" placeholder="请输入证件号码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="realForm.auth_status" clearable placeholder="请选择认证状态">
                        <el-option label="验证通过" value="1"></el-option>
                        <el-option label="验证失败" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="代理商申请开始日期"
                        end-placeholder="代理商申请结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(realForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-s-promotion" @click>占位</el-button>
            </div>-->
            <el-table :data="realTable" size="mini" border style="width: 100%" v-loading="loading">
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="user_id"
                    label="代理商ID"
                    min-width="80"
                ></el-table-column>
                <!-- 代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 真实姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="真实姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 邀请码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="code"
                    label="邀请码"
                    min-width="120"
                ></el-table-column>
                <!-- 昵称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="nickname"
                    label="昵称"
                    min-width="200"
                ></el-table-column>
                <!-- 证件类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="证件类型"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span>身份证</span>
                    </template>
                </el-table-column>
                <!-- 证件号码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="id_card"
                    label="证件号码"
                    min-width="220"
                ></el-table-column>
                <!-- 申请时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="申请时间"
                    min-width="150"
                ></el-table-column>
                <!-- 认证时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="update_time"
                    label="认证时间"
                    min-width="150"
                ></el-table-column>
                <!-- 认证状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="auth_status"
                    label="认证状态"
                    min-width="120"
                    :formatter="formatAuth"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="200"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleInfo(scope.row)"
                            type="text"
                            size="small"
                            slot="reference"
                        >详情</el-button>&nbsp;
                        <!-- 撤销验证 -->
                        <el-button
                            type="text"
                            size="small"
                            slot="reference"
                            @click="handleUndo(scope.row)"
                            v-if="scope.row.auth_status == 1"
                        >撤销验证</el-button>
                        <template v-if="scope.row.auth_status == 0">
                            <!-- 上送验证 -->
                            <el-popconfirm title="是否手动上送实名验证？" @confirm="handleSend(scope.row)">
                                <el-button type="text" size="small" slot="reference">上送验证</el-button>
                            </el-popconfirm>&nbsp;
                            <!-- 跳过验证 -->
                            <el-popconfirm title="是否确认跳过实名验证？" @confirm="handleSkip(scope.row)">
                                <el-button slot="reference" type="text" size="small">跳过验证</el-button>
                            </el-popconfirm>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <el-dialog v-if="dialogVisible" title="认证结果" :visible.sync="dialogVisible" width="600px">
            <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
                <div class="realTitle">
                    <div>身份证正面</div>
                    <div class="imgParent">
                        <img :src="dialogData.id_card_positive" alt />
                    </div>
                </div>
                <div class="realTitle">
                    <div>身份证反面</div>
                    <div class="imgParent">
                        <img :src="dialogData.id_card_negative" alt />
                    </div>
                </div>
                <div class="realTitle">
                    <div v-if="dialogData.auth_status == 1">已实名验证通过！</div>
                    <div v-else>
                        失败原因：
                        <span style="font-weight:400">{{dialogData.reason}}</span>
                    </div>
                </div>
            </el-card>
            <!-- <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" @click="dialogVisible = false">确 定</el-button>
            </div>-->
        </el-dialog>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
export default {
    name: "UM_realNameList",
    data() {
        return {
            rangeDate: '', //时间范围
            reason: '', //拒绝原因
            realTable: [], //表格
            sendFlag: true, //上送防重复
            skipFlag: true, //跳过防重复
            undoFlag: true, //撤销防重复
            dialogVisible: false,
            dialogData: {},
            realForm: {
                phone: '', //代理商账号
                real_name: '',   //代理商真实姓名
                id_card: '', //证件号码
                auth_status: '', //实名认证状态
                start_create_time: '',    //申请开始时间
                end_create_time: '',    //申请结束时间
                page: 1,
                limit: 10
            },
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true,
        }
    },
    mounted() {
        this.getData( //初始化
            {
                page: 1,
                limit: this.page.pageSize
            }
        );
    },
    components: {
        paginations
    },
    methods: {
        onSearch(realForm) {
            this.realForm.page = 1;
            this.page.currentPage = 1;
            // 表单提交
            this.getData(realForm);
        },
        //
        handleInfo(row) { //详情
            this.dialogData = row;
            this.dialogVisible = true;
        },
        handleSend(row) { //上送验证
            if (this.sendFlag) {
                this.sendFlag = false;
                let reqData = { //请求参数
                    id: row.id,
                    user_id: row.user_id,
                    real_name: row.real_name,
                    id_card: row.id_card
                };
                this.$api.post('user/real_name', reqData, res => {
                    if (res.code == 0) {
                        this.$message.success(res.msg);
                        this.sendFlag = true;
                        this.getData(this.realForm);
                    } else {
                        this.sendFlag = true;
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        handleSkip(row) { //跳过验证
            if (this.skipFlag) {
                this.skipFlag = false;
                this.$api.post('user/jump_check', { id: row.id }, res => {
                    if (res.code == 0) {
                        this.$message.success(res.msg);
                        this.skipFlag = true;
                        this.getData(this.realForm);
                    } else {
                        this.skipFlag = true;
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        handleUndo(row) { //撤销验证
            this.$confirm('撤销验证后该代理商实名信息将被清除，是否确定撤销验证？', ' ', {
                confirmButtonText: '确 定',
                cancelButtonText: '取 消',
                type: 'warning',
            })
                .then(() => {
                    this.undoFlag = false;
                    this.$api.post('user/revocation_check', { id: row.id, user_id: row.user_id }, res => {
                        if (res.code == 0) {
                            this.$message.success(res.msg);
                            this.undoFlag = true;
                            this.getData(this.realForm);
                        } else {
                            this.undoFlag = true;
                            this.$message.error(res.msg);
                        }
                    })
                })
                .catch(() => { });
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.realForm.page = this.page.currentPage;
            this.realForm.limit = this.page.pageSize;
            this.getData(this.realForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.realForm.page = this.page.currentPage;
            this.realForm.limit = limit;
            this.getData(this.realForm);
        },
        getData(params) {
            this.$api.post("user/auth_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.realTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        formatAuth(row) {
            switch (row.auth_status) {
                case 1:
                    return '验证通过';
                    break;
                default:
                    return '验证失败'
                    break;
            }
        },
        pickerChange(DateArr) { //时间选择器
            this.realForm.start_create_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.realForm.end_create_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
};
</script>

<style lang="scss" scope>
.UM_realNameList {
    .realTitle {
        :nth-child(1) {
            font-size: 12px;
            font-weight: 700;
            color: #1e1e1e;
        }
        .imgParent {
            margin: 10px 0;
            // width: 500px;
            overflow: hidden;
            img {
                width: 100%;
                height: 250px;
                border-radius: 10px;
            }
        }
    }
    .el-dialog__header {
        padding: 20px 20px 0px;
    }
    .el-dialog__body {
        padding: 0px 20px 30px 20px;
    }
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
