<template>
	<!-- 导入结果查询 -->
	<div class="ResultList">
		<el-card class="search-card" shadow="hover">
			<el-form :inline="true" :model="searchForm">
				<el-form-item>
					<el-input
						clearable
						v-model="searchForm.number"
						placeholder="请输入批次号查询"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-date-picker
						v-model="searchForm.create_time"
						type="datetimerange"
						range-separator="至"
						start-placeholder="导入开始日期"
						end-placeholder="导入结束日期"
						value-format="yyyy-MM-dd HH:mm:ss"
						:default-time="['00:00:00', '23:59:59']"
					></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-date-picker
						v-model="searchForm.complete_time"
						type="datetimerange"
						range-separator="至"
						start-placeholder="完成开始日期"
						end-placeholder="完成结束日期"
						value-format="yyyy-MM-dd HH:mm:ss"
						:default-time="['00:00:00', '23:59:59']"
					></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-select
						v-model="searchForm.status"
						clearable
						placeholder="请选择扣款状态"
					>
						<el-option label="待处理" value="1"></el-option>
						<el-option label="处理中" value="2"></el-option>
						<el-option label="处理成功" value="3"></el-option>
						<el-option label="处理失败" value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select
						v-model="searchForm.type"
						clearable
						placeholder="请选择类型"
					>
						<el-option label="未激活扣款" value="1"></el-option>
						<el-option label="伪激活扣款" value="2"></el-option>
					</el-select>
				</el-form-item>
				<div class="search-card-button">
					<el-button
						type="primary"
						icon="el-icon-search"
						style="font-size: 12px"
						@click="handleSearch(searchForm)"
					>
						查询
					</el-button>
					<el-button
						type="info"
						icon="el-icon-refresh-left"
						style="font-size: 12px"
						@click="searchForm = { ...{}, ...searchReset }"
					>
						重置
					</el-button>
				</div>
			</el-form>
		</el-card>
		<el-card style="margin-top: 20px;" shadow="hover">
			<div style="margin-bottom: 20px;">
				<!-- <el-button
					size="small"
					type="primary"
					icon="el-icon-download"
					:loading="downLoading"
					@click="handleDownLoad"
				>
					导出数据
				</el-button> -->
			</div>
			<el-table
				:data="tableList"
				size="small"
				border
				style="width: 100%"
				v-loading="loading"
				max-height="500px"
			>
				<!-- 序号 -->
				<el-table-column
					type="index"
					header-align="center"
					align="center"
					fixed
					label="序号"
					width="100"
					:index="handleTableIndex"
				></el-table-column>
				<!-- 批次号 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="number"
					label="批次号"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 类型 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="type"
					label="类型"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 导入数量 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="num"
					label="导入数量"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 扣款状态 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="status"
					label="扣款状态"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 成功条数 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="success_num"
					label="成功条数"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 失败条数 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="error_num"
					label="失败条数"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 导入时间 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="create_time"
					label="导入时间"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 完成时间 -->
				<el-table-column
					header-align="center"
					align="center"
					prop="complete_time"
					label="完成时间"
					min-width="150"
					show-overflow-tooltip
				></el-table-column>
				<!-- 操作 -->
				<el-table-column
					header-align="center"
					align="center"
					fixed="right"
					label="操作"
					min-width="200"
				>
					<template #default="{row}">
						<el-button
							type="text"
							size="small"
							@click="handleOpenPage(row.original_file)"
						>
							下载源文件
						</el-button>
						<el-button
							v-if="row.error_file"
							type="text"
							size="small"
							@click="handleOpenPage(row.error_file)"
						>
							下载失败数据
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页部分 -->
			<el-pagination
				background
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:page-sizes="[10, 20, 50, 100]"
				:current-page="currentPage"
				:page-size="pageSize"
				:total="total"
				layout="total, sizes, prev, pager, next, jumper"
			></el-pagination>
		</el-card>
	</div>
</template>

<script>
export default {
	name: "ResultList",
	data() {
		return {
			searchReset: {}, // 重置搜索条件
			searchForm: {
				page: 1,
				limit: 10,
				number: "", // 终端政策
				create_time: [], // 规则类型
				complete_time: [], // 开始类型
				status: "", // 终端归属代理商编号
				type: "", // 终端归属代理商名称
			},
			tableList: [],
			currentPage: 1, //当前页码
			pageSize: 10, //条数
			total: 0, //总数
			loading: false,
			downLoading: false,
		};
	},
	created() {
		this.searchReset = Object.assign({}, this.searchForm);
		this.getData({
			//初始化表格数据
			page: 1,
			limit: 10,
		});
	},
	methods: {
		handleSearch(searchForm) {
			//搜索
			this.currentPage = 1;
			searchForm.page = 1;
			this.getData(searchForm);
		},
		handleOpenPage(url) {
			// 下载
			window.open(url);
		},
		// 分页部分
		handleCurrentChange(page) {
			//当前选中页
			this.currentPage = page;
			this.searchForm.page = page;
			this.searchForm.limit = this.pageSize;
			this.getData(this.searchForm);
		},
		handleSizeChange(limit) {
			//条数
			this.currentPage = 1;
			this.pageSize = limit;
			this.searchForm.page = 1;
			this.searchForm.limit = limit;
			this.getData(this.searchForm);
		},
		getData(params) {
			//获取数据
			this.loading = true;
			this.$api.post("import/get_import_list", params, (res) => {
				if (res.code == 0) {
					const { count, list } = res.data;
					this.total = count; //总数
					this.tableList = list;
				} else {
					this.$message.error(res.msg);
				}
				//渲染结束后取消加载
				this.loading = false;
			});
		},
		handleTableIndex(index) {
			// 处理序号
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
	},
};
</script>

<style lang="scss" scoped></style>
