<template>
    <div class>
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm">
                <el-form-item>
                    <el-select
                        v-model="searchForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.name" placeholder="请输入代理商名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.agent_code" placeholder="请输入代理商编号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.order_num" placeholder="请输入申请订单号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="searchForm.status"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择审核状态"
                    >
                        <el-option
                            v-for="(item,index) in statusList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item>
                    <el-select
                        v-model="searchForm.consignor"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择发货人"
                    >
                        <el-option
                            v-for="(item,index) in shipperList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>-->
                <el-form-item>
                    <el-date-picker
                        v-model="createdRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="创建开始日期"
                        end-placeholder="创建结束日期"
                        value-format="yyyy-MM-dd"
                        @change="createPickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <!-- <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    :loading="downLoading"
                    @click="handleDownLoad"
                >导出数据</el-button>-->
            </div>
            <el-table
                :data="tableList"
                size="small"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <!-- 序号 -->
                <el-table-column
                    type="index"
                    header-align="center"
                    align="center"
                    fixed
                    label="序号"
                    width="100"
                    :index="handleTableIndex"
                ></el-table-column>
                <!-- 订单号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="order_num"
                    label="订单号"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 是否直签采购 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="is_direct_procurement"
                    label="是否直签采购"
                    min-width="100"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 代理商编号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_code"
                    label="代理商编号"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="代理商名称"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 产品类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="product_type"
                    label="产品类型"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 采购价格 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="procurement_price"
                    label="采购价格"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 采购数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="procurement_num"
                    label="采购数量"
                    min-width="120"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 实付款 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="money"
                    label="实付款"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 采购模式 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="procurement_model"
                    label="采购模式"
                    min-width="120"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 打款人 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="pay_user_name"
                    label="打款人"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 发货人 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="consignor"
                    label="发货人"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 收货地址 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="address"
                    label="收货地址"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 收货人 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="receiver_name"
                    label="收货人"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 收货人联系方式 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="receiver_tel"
                    label="收货人联系方式"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status_name"
                    label="状态"
                    min-width="120"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="创建时间"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 操作时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="update_time"
                    label="操作时间"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    label="操作"
                    min-width="200"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <el-button
                            style="margin-right:10px"
                            @click="handleAudit(scope.row)"
                            type="text"
                            size="small"
                        >
                            <span v-if="scope.row.status == 1">审核</span>
                            <span v-else>详情</span>
                        </el-button>
                        <el-button
                            style="margin-right:10px"
                            @click="handleCheckVoucher(scope.row)"
                            type="text"
                            size="small"
                        >打款凭证</el-button>
                        <el-popover placement="left" width="400" trigger="click" title="拒绝原因">
                            <el-input
                                type="textarea"
                                v-model="reason"
                                :disabled="true"
                                style="font-size:12px"
                            ></el-input>
                            <el-button
                                v-show="scope.row.status == 2 || scope.row.status == 4"
                                @click="handlecheckReason(scope.row)"
                                type="text"
                                size="small"
                                slot="reference"
                            >拒绝原因</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <PreviewPic
            v-if="picInfo.isShow"
            :pic-info="picInfo"
            @handleCloseImgView="handleCloseVoucher"
        />
        <ProcurementOrderListDetail
            v-if="detail.isShow"
            :detail="detail"
            @detailClose="handleCloseDetail"
        />
    </div>
</template>
<script>
import PreviewPic from '../../components/previewPic';
import ProcurementOrderListDetail from './components/ProcurementOrderListDetail'; //图片预览组件
export default {
    data() {
        return {
            reason: '',
            picInfo: {
                isShow: false,
                imgUrl: []
            },
            detail: {
                isShow: false,
                row: {}
            },
            createdRange: [], // 创建时间范围
            searchForm: {
                page: 1,
                limit: 10,
                name: '', // 代理商名称
                status: '', // 订单审核状态
                brand_id: '', // 品牌id
                order_num: '', // 订单号
                consignor: '', // 发货人id
                start_time: '', // 开始时间
                end_time: '', // 结束时间
                agent_code: '' // 代理商编号
            },
            brandList: [], // 品牌列表
            statusList: [], // 审核状态
            shipperList: [], // 发货人
            tableList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false,
            downLoading: false //导出loading
        }
    },
    created() {
        this.getStatusShipper();
        this.getBrand();
        this.getData({
            page: 1,
            limit: 10
        })
    },
    mounted() {
    },
    components: {
        PreviewPic,
        ProcurementOrderListDetail
    },
    methods: {
        handleSearch(searchForm) {
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleAudit(row) { // 审核
            this.detail.isShow = true;
            this.detail.row = row;
        },
        handleCloseDetail(ref) { // 关闭详情
            this.detail.isShow = false;
            this.detail.row = {};
            if (ref) {
                this.getData(this.searchForm);
            }
        },
        handleCheckVoucher(row) { // 打款凭证
            this.loading = true;
            this.$api.post("procurement/cat_order_img", { id: row.id }, res => {
                if (res.code == 0) {
                    let data = res.data;
                    if (data.list.length != 0) {
                        data.list.forEach(item => {
                            this.picInfo.imgUrl.push(item.url);
                        })
                        this.picInfo.isShow = true;
                    } else {
                        this.$message.error('未查询到相关打款凭证')
                    }
                }
                //渲染结束后取消加载
                this.loading = false;
            });

        },
        handleCloseVoucher() { // 关闭打款凭证
            this.picInfo.isShow = false;
            this.picInfo.imgUrl = [];
        },
        handlecheckReason(row) { // 拒绝原因
            this.reason = row.reason;
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("procurement/get_order_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.tableList = data.list;
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        getStatusShipper() { // 获取审核状态及发货人
            this.$api.post('procurement/send_user_list', {}, res => {
                if (res.code == 0) {
                    this.statusList = res.data.order_status;
                    this.shipperList = res.data.people;
                } else {
                    this.$message.success(res.msg);
                }
            });
        },
        getBrand() { // 获取终端类型
            this.$api.post('brand/brand_list', {}, res => {
                if (res.code == 0) {
                    this.brandList = res.data.list;
                } else {
                    this.$message.success(res.msg);
                }
            });
        },
        createPickerChange(DateArr) { // 创建时间
            this.searchForm.start_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.searchForm.end_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        },
        handleTableIndex(index) { // 处理序号
            return (this.currentPage - 1) * this.pageSize + index + 1;
        }
    }
}
</script>

<style scoped lang="scss">
</style>