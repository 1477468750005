<template>
    <div class="DM_ladderLogList">
        <!-- 阶梯记录查询 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="ladderLogForm" class="demo-form-inline">
                <el-form-item>
                    <el-select
                        size="medium"
                        v-model="ladderLogForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="ladderLogForm.sn" placeholder="请输入SN码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="ladderLogForm.phone" placeholder="请输入归属代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="ladderLogForm.real_name" placeholder="请输入归属代理商名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="请选择达标开始日期"
                        end-placeholder="请选择达标结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(ladderLogForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    :loading="downLoading"
                    @click="handleDownLoad"
                >导出数据</el-button>
            </div>
            <el-table
                :data="ladderLogTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="120"
                ></el-table-column>
                <!-- SN码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_sn"
                    label="终端SN"
                    min-width="120"
                ></el-table-column>
                <!-- 终端归属代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="name"
                    label="终端归属代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 终端归属代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="终端归属代理商账号"
                    min-width="150"
                ></el-table-column>
                <!-- 达标类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="ladder_name"
                    label="达标类型"
                    min-width="120"
                ></el-table-column>
                <!-- 达标时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="award_time"
                    label="达标时间"
                    min-width="150"
                ></el-table-column>
                <!-- 达标奖励金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="reward_money"
                    label="达标奖励金额"
                    min-width="120"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <ladderlog-info
            v-if="infoIsShow"
            :infoIsShow="infoIsShow"
            :detail="detail"
            @infoClose="infoClose"
        ></ladderlog-info>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import ladderlogInfo from './DM_ladderLogInfo';
export default {
    name: "DM_ladderLogList",
    data() {
        return {
            infoIsShow: false, //弹窗
            detail: {}, //当前行
            brandList: [], //终端类型列表
            rangeDate: '', //时间范围
            ladderLogForm: {
                brand_id: '',  //终端类型id
                terminal_sn: '', //终端SN
                phone: '', //归属代理商账号
                name: '', //归属代理商名称
                end_time: '', //达标结束日期
                start_time: '', //达标开始日期
                page: 1,
                limit: 10,

            },
            ladderLogTable: [],
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true,
            downLoading: false //导出loading
        }
    },
    mounted() {
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$message.success(res.msg);
            }
        });
        this.getData( //初始化
            {
                page: 1,
                limit: this.page.pageSize
            }
        );
    },
    components: {
        paginations,
        ladderlogInfo
    },
    methods: {
        //
        onSearch(ladderLogForm) {
            this.page.currentPage = 1;
            this.ladderLogForm.page = 1; //重置
            // 表单提交
            this.getData(ladderLogForm);
        },
        handleInfo(row) { //查看
            this.infoIsShow = true;
            this.detail = row;
        },
        infoClose() { //关闭弹窗
            this.infoIsShow = false;
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.ladderLogForm.page = this.page.currentPage;
            this.ladderLogForm.limit = this.page.pageSize;
            this.getData(this.ladderLogForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.ladderLogForm.page = this.page.currentPage;
            this.ladderLogForm.limit = limit;
            this.getData(this.ladderLogForm);
        },
        getData(params) {
            this.$api.post("transaction/ladder_award", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.ladderLogTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        pickerChange(DateArr) { //时间选择器
            this.ladderLogForm.start_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.ladderLogForm.end_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        },
        handleDownLoad() { //导出
            let handleExportExcel = this.common.handleExportExcel;
            this.downLoading = true;
            let searchVal = this._.omit(this.ladderLogForm, ['page', 'limit']);
            let value = {};
            this._.forEach(searchVal, (item, index) => {
                if (item != '') {
                    value[index] = item;
                }
            });
            handleExportExcel({ type: 6, value: JSON.stringify(value) }).then(() => {
                this.downLoading = false;
            });
        },
    }
};
</script>

<style lang="scss" scope>
.DM_ladderLogList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
