import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
/* eslint-disable */
import $ from 'jquery';


import _ from 'lodash';
import md5 from 'js-md5';
import api from './api/index.js';
import common from '@/utils/commonUtils.js';
import port from './api/port.js';
import echarts from 'echarts';
import moment from 'moment';

Vue.prototype.$md5 = md5;
Vue.prototype.$api = api;
Vue.prototype.$port = port;
Vue.prototype._ = _; //loadsh
Vue.prototype.$echarts = echarts //echarts
Vue.prototype.$moment = moment //moment
Vue.prototype.common = common;

import '@/assets/styles/index.scss';
import '@/permission';

import './assets/iconfont/iconfont.css';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
