<template>
    <div class="merchantClassInfo">
        <!-- 分类详情 -->
        <el-dialog
            :visible="info.isShow"
            @close="handleClose"
            :close-on-click-modal="false"
            width="600px"
        >
            <el-card class="box-card" shadow="always">
                <!-- 表单部分 -->
                <el-form label-position="top" label-width="80px">
                    <el-form-item label="商户类型：">
                        <el-input v-model="info.row.name" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="规则设置："></el-form-item>
                    <!-- 优质、活跃商户 -->
                    <template v-if="info.row.type == 1 || info.row.type == 2">
                        <el-form-item>
                            <div class="rm-form-inline">
                                <span class="rm-word-aux">近</span>
                                <el-form-item style="width:100px;margin-left:10px">
                                    <el-input
                                        :maxlength="3"
                                        @keyup.native="handleInt($event)"
                                        v-model="classForm.number"
                                        :disabled="info.row.status == 3"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item style="width:100px">
                                    <el-select
                                        clearable
                                        :disabled="info.row.status == 3"
                                        v-model="classForm.number_type"
                                        placeholder="X天/X月"
                                    >
                                        <el-option label="天" :value="1"></el-option>
                                        <el-option label="月" :value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                                <span class="rm-word-aux">内</span>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="rm-form-inline">
                                <el-form-item>
                                    <el-checkbox
                                        :disabled="info.row.status == 3"
                                        v-model="classForm.transaction_money_selected"
                                        :true-label="1"
                                        :false-label="2"
                                        checked
                                    >
                                        <span v-if="info.row.type == 1">交易额 ≥</span>
                                        <span v-if="info.row.type == 2">连续每月/天交易额 ≥</span>
                                    </el-checkbox>
                                </el-form-item>
                                <el-form-item>
                                    <el-input
                                        @keyup.native="handleInt($event)"
                                        v-model="classForm.transaction_money"
                                        :disabled="info.row.status == 3"
                                    >
                                        <span slot="suffix">元</span>
                                    </el-input>
                                </el-form-item>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="rm-form-inline">
                                <el-form-item>
                                    <el-checkbox
                                        :disabled="info.row.status == 3"
                                        v-model="classForm.transaction_number_selected"
                                        :true-label="1"
                                        :false-label="2"
                                        checked
                                    >
                                        <span v-if="info.row.type == 1">交易笔数 ≥</span>
                                        <span v-if="info.row.type == 2">连续每月/天交易笔数 ≥</span>
                                    </el-checkbox>
                                </el-form-item>
                                <el-form-item>
                                    <el-input
                                        @keyup.native="handleInt($event)"
                                        v-model="classForm.transaction_number"
                                        :disabled="info.row.status == 3"
                                    >
                                        <span slot="suffix">笔</span>
                                    </el-input>
                                </el-form-item>
                            </div>
                        </el-form-item>
                        <el-form-item
                            v-if="classForm.transaction_money_selected == 1 && classForm.transaction_number_selected == 1"
                        >
                            <el-form :inline="true">
                                <el-form-item label="笔数/金额关系：">
                                    <el-radio-group
                                        v-model="classForm.transaction_and_or"
                                        :disabled="info.row.status == 3"
                                    >
                                        <el-radio :label="1">且</el-radio>
                                        <el-radio :label="2">或</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                        </el-form-item>
                    </template>
                    <el-form :inline="true">
                        <!-- 沉默商户 -->
                        <el-form-item v-if="info.row.type == 3">
                            <div class="rm-form-inline">
                                <span class="rm-word-aux">连续</span>
                                <el-form-item style="width:100px;margin-left:10px">
                                    <el-input
                                        :maxlength="3"
                                        @keyup.native="handleInt($event)"
                                        v-model="classForm.number"
                                        :disabled="info.row.status == 3"
                                    ></el-input>
                                </el-form-item>
                                <span class="rm-word-aux">天内无交易</span>
                            </div>
                        </el-form-item>
                        <!-- 新增商户 -->
                        <template v-if="info.row.type == 4">
                            <el-form-item>
                                <el-select
                                    clearable
                                    :disabled="info.row.status == 3"
                                    v-model="classForm.number_type"
                                    placeholder="请选择"
                                >
                                    <el-option label="首笔交易" :value="1"></el-option>
                                    <!-- <el-option label="入网" :value="2"></el-option> -->
                                    <el-option label="激活" :value="3"></el-option>
                                    <!-- <el-option label="绑定" :value="4"></el-option> -->
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <div class="rm-form-inline">
                                    <span class="rm-word-aux">≤</span>
                                    <el-form-item style="width:100px;margin-left:10px">
                                        <el-input
                                            :maxlength="3"
                                            @keyup.native="handleInt($event)"
                                            v-model="classForm.number"
                                            :disabled="info.row.status == 3"
                                        ></el-input>
                                    </el-form-item>
                                    <span class="rm-word-aux">天</span>
                                </div>
                            </el-form-item>
                        </template>
                    </el-form>
                    <el-form-item style="margin-top:20px;">
                        <el-button
                            type="primary"
                            :disabled="info.row.status == 3"
                            @click="handleSubmit(classForm)"
                            :loading="submitLoading"
                        >提 交</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "merchantClassInfo",
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            classForm: {

            },
            submitLoading: false, //提交loading
        }
    },
    mounted() {
        let row = this.info.row;
        this.getData({
            type: row.type,
            type_name: row.name
        });
    },
    methods: {
        handleSubmit(classForm) { //提交
            this.submitLoading = true;
            classForm.type = this.info.row.type;
            let reqData = classForm;
            let _this = this;
            if (classForm.transaction_money_selected != 1 || classForm.transaction_number_selected != 1) {
                reqData = this._.omit(classForm, ['transaction_and_or']);
            }
            this.$api.post("merchant_classification/merchant_classification_create_update", reqData, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            _this.submitLoading = false;
                            _this.handleClose(1);
                        }
                    })
                } else {
                    this.$message.error(res.msg);
                    this.submitLoading = false;
                }
            });
        },
        handleClose(ref) { //关闭详情
            this.$emit('infoClose', ref)
        },
        getData(params) { //获取详情数据
            this.$api.post("merchant_classification/get_merchant_classification_deploy_info", params, res => {
                if (res.code == 0) {
                    this.classForm = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        handleInt(e) { //替换不为数字的值
            e.target.value = e.target.value.replace(/\D/g, '');
            let isInt = /^[1-9]([0-9])*$/; //判断是否为正整数
            if (!isInt.test(e.target.value)) {
                e.target.value = '';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.merchantClassInfo {
    font-size: 12px;
}
</style>
