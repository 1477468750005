<template>
    <!-- 代理商层级 -->
    <div class="userLevel-container">
        <el-card shadow="always">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="searchForm.phone" placeholder="请输入代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card shadow="always" style="margin-top:10px">
            <el-button
                size="medium"
                icon="el-icon-folder"
                style="font-size: 12px;margin-bottom:20px"
                @click="handleClose()"
            >全部收起</el-button>
            <el-table
                v-if="refreshTable"
                ref="userLevel"
                :data="userList"
                style="width: 100%;margin-bottom: 20px;"
                row-key="id"
                border
                lazy
                :load="load"
                size="small"
                :default-expand-all="expands"
                max-height="800px"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
                <el-table-column
                    align="left"
                    header-align="center"
                    prop="name"
                    label="代理商名称"
                    min-width="300"
                >
                    <template slot-scope="scope">
                        <img
                            :src="scope.row.avatar"
                            alt
                            class="user-img"
                            @click="viewImage(scope.row.avatar)"
                        />
                        <span style="margin-left:5px">{{scope.row.name}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    header-align="center"
                    prop="phone"
                    label="代理商账号"
                    min-width="120"
                ></el-table-column>
                <el-table-column
                    align="center"
                    header-align="center"
                    prop="level"
                    label="代理商层级"
                    min-width="120"
                ></el-table-column>
                <el-table-column
                    align="center"
                    header-align="center"
                    prop="role_id"
                    label="代理商所属角色"
                    min-width="120"
                ></el-table-column>
                <el-table-column
                    align="center"
                    header-align="center"
                    prop="parent_agent_name"
                    label="挂靠上级代理商名称"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 加载时占位 -->
            <div v-if="!refreshTable" v-loading="loading" element-loading-text="嘿咻嘿咻拼命加载中！">
                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple-dark"></div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <div class="grid-content bg-purple"></div>
                    </el-col>
                </el-row>
                <el-row>
                    <div v-for="n in 10" :key="n">
                        <el-col :span="8">
                            <div class="grid-content bg-purple"></div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple-light"></div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple"></div>
                        </el-col>
                    </div>
                </el-row>
            </div>
            <preview-pic
                v-if="picInfo.isShow"
                :picInfo="picInfo"
                @handleCloseImgView="handleCloseImgView"
            ></preview-pic>
        </el-card>
    </div>
</template>

<script>
import previewPic from '../../components/previewPic'; //图片预览组件
export default {
    data() {
        return {
            refreshTable: false,
            expands: false, //是否自动展开
            picInfo: {
                isShow: false,
                imgUrl: ''
            },
            loading: false,
            searchForm: {
                phone: ''
            },
            userList: []
        }
    },
    mounted() {
        this.getUserList({});
    },
    components: {
        previewPic
    },
    methods: {
        handleSearch(searchForm) { //搜索
            this.refreshTable = false;
            this.expands = /[\S]+/.test(searchForm.phone) ? true : false;
            this.getUserList(searchForm);
        },
        handleInfo(row) { //查看代理商详情
            if (row.level != 1) {
                this.$router.push({ path: '/userInfo', query: { id: row.id } });
            } else {
                this.$router.push({ name: 'agentDetail', params: { id: row.agent_id } });
            }
        },
        load(tree, treeNode, resolve) {//动态加载
            this.$api.post('user/underling', { pid: tree.id }, res => {
                resolve(res.data);
            })
        },
        handleClose() {//折叠
            this.refreshTable = false;
            this.expands = false;
            this.$nextTick(() => {
                this.refreshTable = true;
            });
        },
        getUserList(params) { //获取列表
            this.loading = true;
            this.$api.post('user/level', params, res => {
                if (res.code == 0) {
                    this.refreshTable = true;
                    this.userList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false;
            })
        },
        viewImage(picUrl) { //预览图片
            if (picUrl != '') {
                this.picInfo.isShow = true;
                this.picInfo.imgUrl = picUrl;
            } else {
                this.$message.error('没有可以预览的图片哦！');
            }
        },
        handleCloseImgView() { //关闭图片预览
            this.picInfo.isShow = false;
            this.picInfo.imgUrl = '';
        }
    }
}

</script>

<style scoped lang="scss">
.userLevel-container {
    .user-img {
        width: 20px;
        height: 20px;
        vertical-align: text-bottom;
    }
    .el-row {
        &:last-child {
            margin-bottom: 0;
        }
    }
    .el-col {
        margin-bottom: 20px;
        border-radius: 4px;
    }
    .bg-purple-dark {
        background: #99a9bf;
    }
    .bg-purple {
        background: #d3dce6;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
}
</style>