<template>
    <div class="tinymce-box">
        <editor v-model="myValue" :init="init" :disabled="disabled" @onClick="onClick"></editor>
    </div>
</template>

<script>
import tinymce from 'tinymce/tinymce' //tinymce默认hidden，不引入不显示
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/plugins/image'// 插入上传图片插件
// import 'tinymce/plugins/media'// 插入视频插件
import 'tinymce/plugins/table'// 插入表格插件
import 'tinymce/plugins/lists'// 列表插件
import 'tinymce/plugins/wordcount'// 字数统计插件
import 'tinymce/icons/default'

export default {
    components: {
        Editor
    },
    name: 'tinymce',
    props: {
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        plugins: {
            type: [String, Array],
            default: 'lists image table wordcount'
        },
        toolbar: {
            type: [String, Array],
            default: 'undo redo |  formatselect | fontsizeselect bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image table | removeformat '
        }
    },
    data() {
        return {
            init: {
                language_url: '/tinymce/langs/zh_CN.js',
                language: 'zh_CN',
                skin_url: '/tinymce/skins/ui/oxide',
                // skin_url: 'tinymce/skins/ui/oxide-dark',//暗色系
                content_css: '/tinymce/skins/content/default/content.min.css',
                height: 300,
                plugins: this.plugins,
                toolbar: this.toolbar,
                branding: false,
                menubar: false,
                //上传图片
                images_upload_handler: (blobInfo, view, failure) => {
                    let url = this.$api.uploadUrl;
                    var form = new FormData();
                    form.append('file', blobInfo.blob(), blobInfo.filename());
                    this.$api.EditorFile(url, form, res => {
                        let data = res.data;
                        if (res.code == 0) {
                            view(data.file_url);
                        }else{
                            failure(res.msg);
                            return ;
                        }
                    })
                },
            },
            myValue: this.value
        }
    },
    mounted() {
        tinymce.init({})
    },
    methods: {
        onClick(e) {
            this.$emit('onClick', e, tinymce)
        },
        clear() {
            this.myValue = ''
        }
    },
    watch: {
        value(newValue) {
            this.myValue = newValue
        },
        myValue(newValue) {
            this.$emit('getVal', newValue)
        }
    }
}

</script>
<style scoped lang="scss">
</style>