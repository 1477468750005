/** 代理商管理 */
import userClassSetting from '../../views/userManagement/userClassSetting'; //代理商分类设置
import userList from '../../views/userManagement/user/UM_userList'; //代理商列表
import userLevel from '../../views/userManagement/userLevel'; //代理商层级
import userInfo from '../../views/userManagement/user/UM_userInfo'; //代理商详情
import loanManagement from '../../views/userManagement/loanManagement/UM_loanList'; //贷款管理-流水
import loanInfo from '../../views/userManagement/loanManagement/UM_loanInfo'; //贷款详情-流水
import installmentLoan from '../../views/userManagement/installmentLoanList'; //贷款管理-分期
import installmentLoanInfo from '../../views/userManagement/components/installmentLoanInfo'; //贷款管理-详情
import repaymentManagement from '../../views/userManagement/repaymentManagement/UM_repaymentList'; //还款比例管理
import realNameAuthentication from '../../views/userManagement/realNameAuthentication/UM_realNameList'; //实名认证
import blockedAccount from '../../views/userManagement/blockedAccount/UM_blockedList'; //冻结账户
import accountChangeRecord from '../../views/userManagement/accountChangeRecord/UM_changeList'; //账户变更记录
import ShippingAddressList from '../../views/userManagement/ShippingAddressList'; //收货地址
import emailAddress from '../../views/userManagement/emailAddress/UM_emailList'; //邮箱地址
import bankCard from '../../views/userManagement/bankCard/UM_cardList'; //银行卡
import smsVerificationCode from '../../views/userManagement/smsVerificationCode/UM_messageList'; //短信验证码

export default [
    {
        path: '/userClassSetting',
        name: 'userClassSetting',
        meta: {
            title: '代理商分类设置'
        },
        component: userClassSetting
    },
    {
        path: '/userList',
        name: 'userList',
        meta: {
            title: '代理商列表'
        },
        component: userList
    },
    {
        path: '/userLevel',
        name: 'userLevel',
        meta: {
            title: '代理商层级'
        },
        component: userLevel
    },
    {
        path: '/userInfo',
        name: 'userInfo',
        meta: {
            title: '代理商详情'
        },
        component: userInfo
    },
    {
        path: '/loan',
        name: 'loan',
        meta: {
            title: '贷款管理'
        },
        component: loanManagement
    },
    {
        path: '/loanInfo',
        name: 'loanInfo',
        meta: {
            title: '贷款详情'
        },
        component: loanInfo
    },
    {
        path: '/installmentLoan',
        name: 'installmentLoan',
        meta: {
            title: '贷款管理'
        },
        component: installmentLoan
    },
    {
        path: '/installmentLoanInfo',
        name: 'installmentLoanInfo',
        meta: {
            title: '贷款详情'
        },
        component: installmentLoanInfo
    },
    {
        path: '/repayment',
        name: 'repayment',
        meta: {
            title: '还款比例管理'
        },
        component: repaymentManagement
    },
    {
        path: '/realName',
        name: 'realName',
        meta: {
            title: '实名认证'
        },
        component: realNameAuthentication
    },
    {
        path: '/blockedAccount',
        name: 'blockedAccount',
        meta: {
            title: '冻结账户'
        },
        component: blockedAccount
    },
    {
        path: '/accountChange',
        name: 'accountChange',
        meta: {
            title: '账户变更记录'
        },
        component: accountChangeRecord
    },
    {
        path: '/receiptAddressQuery',
        name: 'receiptAddressQuery',
        meta: {
            title: '收货地址查询'
        },
        component: ShippingAddressList
    },
    {
        path: '/emailAddress',
        name: 'emailAddress',
        meta: {
            title: '邮箱地址'
        },
        component: emailAddress
    },
    {
        path: '/bankCard',
        name: 'bankCard',
        meta: {
            title: '银行卡'
        },
        component: bankCard
    },
    {
        path: '/messageCode',
        name: 'messageCode',
        meta: {
            title: '短信验证码'
        },
        component: smsVerificationCode
    }
]