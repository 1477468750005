<template>
    <div>
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="repaymentForm" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="repaymentForm.name" placeholder="请输入代理商名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="repaymentForm.phone" placeholder="请输入代理商账户"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        size
                        v-model="repaymentForm.is_config"
                        clearable
                        placeholder="请选择配置状态"
                    >
                        <el-option label="已配置" value="1"></el-option>
                        <el-option label="未配置" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(repaymentForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-s-promotion" @click>占位</el-button>
            </div>-->
            <el-table
                :data="repaymentTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 代理商账户 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账户"
                    min-width="120"
                ></el-table-column>
                <!-- 上级代理名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_name"
                    label="上级代理名称"
                    min-width="120"
                ></el-table-column>
                <!-- 上级代理账户 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_phone"
                    label="上级代理账户"
                    min-width="120"
                ></el-table-column>
                <!-- 是否为总代 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="parent_id"
                    label="是否为总代"
                    min-width="120"
                    :formatter="isPlatform"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="is_config"
                    label="状态"
                    min-width="120"
                    :formatter="configStatus"
                ></el-table-column>
                <!-- 注册时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="注册时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <!-- parent_id 0平台
                    is_config 1配置-->
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.is_config == 1"
                            @click="handleInfo(scope.row)"
                            type="text"
                            size="small"
                        >查看</el-button>
                        <el-button
                            v-if="scope.row.parent_id == 0 && scope.row.is_config == 1"
                            @click="handleEdit(scope.row)"
                            type="text"
                            size="small"
                        >编辑</el-button>
                        <el-button
                            v-if="scope.row.parent_id == 0 && scope.row.is_config == 0"
                            @click="handleSet(scope.row)"
                            type="text"
                            size="small"
                        >配置</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <set-repayment
            v-if="setRepaymentIsShow"
            :setRepaymentIsShow="setRepaymentIsShow"
            :info="info"
            @configClose="configClose"
        ></set-repayment>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import setRepayment from './UM_setRepayment';
export default {
    name: "UM_repaymentList",
    data() {
        return {
            info: {
                id: null,
                name: '',
                operation: null // 1查看 2编辑 3配置
            },
            setRepaymentIsShow: false,
            repaymentForm: { //搜索表单
                name: '',
                phone: '',
                is_config: '',
            },
            repaymentTable: [], //列表
            loading: true, //列表加载状态
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
        }
    },
    mounted() {
        this.getData({
            page: this.page.currentPage,
            limit: this.page.pageSize,
        });
    },
    components: {
        paginations,
        setRepayment
    },
    methods: {
        onSearch(repaymentForm) {
            this.loading = true;
            // 表单提交
            this.page.currentPage = 1;
            let searchData = this._.assign(this._.cloneDeep(repaymentForm), { page: this.page.currentPage, limit: this.page.pageSize });
            this.getData(searchData);
        },
        //
        handleInfo(row) { //查看
            this.info.id = row.id;
            this.info.operation = 1;
            this.info.name = row.real_name;
            this.setRepaymentIsShow = true;
        },
        handleEdit(row) { //编辑
            this.info.id = row.id;
            this.info.operation = 2;
            this.info.name = row.real_name;
            this.setRepaymentIsShow = true;
        },
        handleSet(row) { //配置
            this.info.id = row.id;
            this.info.operation = 3;
            this.info.name = row.real_name;
            this.setRepaymentIsShow = true;
        },
        configClose(ref) { //关闭弹窗
            this.setRepaymentIsShow = false;
            if (ref) {
                let searchData = this._.assign(this._.cloneDeep(this.repaymentForm), { page: this.page.currentPage, limit: this.page.pageSize });
                this.getData(searchData)
            }
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.loading = true;
            let searchData = this._.assign(this._.cloneDeep(this.repaymentForm), { page: this.page.currentPage, limit: this.page.pageSize });
            this.getData(searchData);
        },
        getPageSize(limit) {
            // 获取条数发生改变的值
            this.page.pageSize = limit;
            this.loading = true;
            let searchData = this._.assign(this._.cloneDeep(this.repaymentForm), { page: this.page.currentPage, limit: this.page.pageSize });
            this.getData(searchData)
        },
        getData(params) {
            this.$api.post("user/getArrearsConfigList", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.repaymentTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        configStatus(row) { //格式化状态
            let is_config = ''
            switch (row.is_config) {
                case 0:
                    is_config = '未配置';
                    break;
                case 1:
                    is_config = '已配置';
                    break;
            }
            return is_config;
        },
        isPlatform(row) {
            let parent_id = '';
            switch (row.parent_id) {
                case 0:
                    parent_id = '是';
                    break;

                default:
                    parent_id = '否';
                    break;
            }
            return parent_id;
        }
    }
};
</script>

<style lang="scss" scope>
.el-input__inner {
    font-size: 12px;
}
.el-form-item {
    margin-bottom: 5px;
}
</style>
