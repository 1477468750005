<template>
    <div class="BS_dataAccount">
        <!-- 数据账号 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click>占位</el-button>
            </div>-->
            <el-table
                :data="accountList"
                size="mini"
                border
                style="width: 100%"
                max-height="500px"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="account_id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 支付平台 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="account_organization_name"
                    label="支付平台"
                    min-width="150"
                ></el-table-column>
                <!-- 账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="account"
                    label="账号"
                    min-width="100"
                ></el-table-column>
                <!-- 密码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="password"
                    label="密码"
                    min-width="120"
                ></el-table-column>
                <!-- 账号状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="account_status"
                    label="账号状态"
                    min-width="120"
                ></el-table-column>
                <!-- 推送状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status_name"
                    label="推送状态"
                    min-width="120"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column header-align="center" align="center" label="操作" min-width="120">
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">密码修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <account-info
            v-if="infoIsShow"
            :infoIsShow="infoIsShow"
            :detail="detail"
            @infoClose="infoClose"
        ></account-info>
    </div>
</template>

<script>
import accountInfo from './BS_accountInfo';
export default {
    name: "BS_dataAccount",
    data() {
        return {
            infoIsShow: false, //弹窗
            detail: {},
            accountList: [],//账号表格
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        })
    },
    components: {
        accountInfo
    },
    methods: {
        handleInfo(row) { //详情
            this.infoIsShow = true; //弹窗
            this.detail = row;
        },
        infoClose(ref) {
            this.infoIsShow = false;
            if (ref) {
                this.getData({
                    page: this.currentPage,
                    limit: this.pageSize
                })
            }
        },
        //分页
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.getData({
                page: this.currentPage,
                limit: this.pageSize
            })
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.getData({
                page: this.currentPage,
                limit: this.pageSize
            })
        },
        getData(params) {
            this.loading = true;
            this.$api.post("setting/pay_account_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.accountList = data.list;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.BS_dataAccount {
}
</style>
