<template>
    <div class>
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input clearable v-model="searchForm.agent_name" placeholder="请输入代理真实姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.parent_role" clearable placeholder="请选择挂靠上级所属角色">
                        <el-option
                            v-for="item in roleList"
                            :label="item.name"
                            :value="item.id"
                            :key="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.agent_role" clearable placeholder="请选择挂靠下级所属角色">
                        <el-option
                            v-for="item in roleList"
                            :label="item.name"
                            :value="item.id"
                            :key="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="signInDate"
                        type="monthrange"
                        range-separator="至"
                        start-placeholder="统计开始日期"
                        end-placeholder="统计结束日期"
                        value-format="yyyy-MM-dd"
                        @change="handleSignInDateChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    :loading="downLoading"
                    @click="handleDownLoad(searchForm)"
                >导出数据</el-button>
            </div>
            <el-table :data="agentList" size="mini" border style="width: 100%" v-loading="loading">
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 挂靠上级所属角色 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="parent_role_name"
                    label="挂靠上级所属角色"
                    min-width="150"
                ></el-table-column>
                <!-- 挂靠上级名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="parent_agent_name"
                    label="挂靠上级名称"
                    min-width="150"
                ></el-table-column>
                <!-- 挂靠上级账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="parent_phone"
                    label="挂靠上级账号"
                    min-width="150"
                ></el-table-column>
                <!-- 挂靠下级所属角色 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_role_name"
                    label="挂靠下级所属角色"
                    min-width="150"
                ></el-table-column>
                <!-- 挂靠下级名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_name"
                    label="挂靠下级名称"
                    min-width="150"
                ></el-table-column>
                <!-- 挂靠下级账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="挂靠下级账号"
                    min-width="150"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="150"
                ></el-table-column>
                <!-- 总交易额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="transaction_money"
                    label="总交易额"
                    min-width="150"
                ></el-table-column>
                <!-- 暗返比例 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rate"
                    label="暗返比例"
                    min-width="150"
                >
                    <template slot-scope="scope">{{scope.row.rate}} %</template>
                </el-table-column>
                <!-- 预估返现金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="total_profit"
                    label="预估返现金额"
                    min-width="150"
                ></el-table-column>
                <!-- 统计日期 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="month"
                    label="统计日期"
                    min-width="150"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            downLoading: false,
            signInDate: [], //注册时间
            roleList: [], // 代理商角色
            searchForm: {
                page: 1,
                limit: 10
            },
            agentList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false
        }
    },
    created() {
        this.getRole(); // 获取代理商角色
        this.getData({
            page: 1,
            limit: 10
        })
    },
    methods: {
        handleSearch(searchFrom) { //搜索
            this.currentPage = 1;
            searchFrom.page = 1;
            this.getData(searchFrom);
        },
        handleDownLoad(searchFrom) { // 导出数据
            let reqData = this._.omit(searchFrom, ['page', 'limit', 'sign']);
            this.downLoading = true;
            this.$api.post('export/add', { type: 10, value: JSON.stringify(reqData) }, res => {
                let _this = this;
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            _this.downLoading = false;
                        }
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            _this.downLoading = false;
                        }
                    });
                }
            })
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("agent/get_cash_back_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.agentList = res.data.list;
                    //渲染结束后取消加载
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false;
            })
        },
        getRole() { // 获取代理商角色
            this.$api.post("agent/get_role_list", {}, res => {
                if (res.code == 0) {
                    this.roleList = res.data;
                }
            })
        },
        handleSignInDateChange(DateArr) { //申请时间
            this.searchForm.start_date = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.searchForm.end_date = this._.isEmpty(DateArr) ? '' : DateArr[1];
        },
    }
}
</script>

<style scoped lang="scss">
</style>