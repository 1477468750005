import axios from 'axios'
import _ from 'lodash';
import router from '../router';
import { Message } from 'element-ui';
import md5 from 'js-md5';

// const REQ_URL = "http://sub.hkrt.cn/"   //正式地址
// const UPLOAD_URL = 'http://wap.hkrt.cn/file_upload';//正式上传文件地址

/********************************************************************************/
const REQ_URL = "http://sub.hkmock.yinchuangyun.cn/"   //测试地址
const UPLOAD_URL = 'http://wap.hkmock.yinchuangyun.cn/file_upload';//测试上传文件地址

const selfConfig = {
	apiDomain: REQ_URL, // 线上接口地址
	preAPIDomain: REQ_URL, //育发接口地址
	devApiPort: REQ_URL, //本地走测试接口
	// devApiPort: '3000', //mock数据端口号
}

const TIME_OUT_MS = 60 * 1000 // 默认请求超时时间

const PRO_HOST = process.env.VUE_APP_PREAPI == 'true' && selfConfig.preAPIDomain != '' ? selfConfig.preAPIDomain :
	selfConfig.apiDomain
const BASE_URL = process.env.NODE_ENV === 'production' ? PRO_HOST : process.env.VUE_APP_PREAPI == 'true' ? selfConfig.preAPIDomain :
	`${selfConfig.devApiPort}` //本地走测试接口
// `//localhost:${selfConfig.devApiPort}` mock

// console.log(process.env);

/*
 * @param response 返回数据列表
 */
function handleResults(resolve) {
	var result = resolve.data
	if (result.code == 500000) { //token失效后退出
		Message.error(result.msg);
		router.push({ path: '/login' })
	}
	// switch (result.code) {
	// 	case 0:

	// 		break
	// 	default:
	// 		result = {
	// 			code: result.code
	// 		}
	// 		Toast('系统报错了')
	// 		break
	// }
	return result
}

function handleUrl(url) {
	url = BASE_URL + url
	// BASE_URL是接口的ip前缀，比如http:10.100.1.1:8989/
	return url
}

/*
 * @param data 参数列表
 * @return
 */
function handleParams(data) {
	let key = "key=baGxm8";
	let sorts = []; //排序数组
	if (!_.isEmpty(data)) { //如果data为空不进入
		_.forEach(data, (v, k) => {
			sorts.push(`${k}=${v}`)
		})
	}

	let concatArr = sorts.sort(); //排序
	concatArr.push(key); //key始终在最后
	_.remove(concatArr, (v) => {
		return v == sign;
	});
	let reqArr = concatArr.join('&').toLowerCase(); //&连接
	let sign = md5(reqArr); //加密
	data.sign = sign;
	return data
}


export default {
	uploadUrl: UPLOAD_URL,//上传文件
	reqUrl: REQ_URL,//请求
	/*
	 * @param url
	 * @param data
	 * @param response 请求成功时的回调函数
	 * @param exception 异常的回调函数
	 */
	post(url, data, response, exception) {
		axios({
			method: 'post',
			url: handleUrl(url),
			// url: url,
			data: handleParams(data),
			timeout: TIME_OUT_MS,
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				"Organization-Code": location.hostname.split('.')[0],
				'Authorization': 'Bearer' + sessionStorage.getItem('token')
			}
		}).then(
			(result) => {
				response(handleResults(result))
			}
		).catch(
			(error) => {
				if (exception) {
					exception(error)
				} else {
					// console.log(error)
				}
			}
		)
	},
	/*
	 * get 请求
	 * @param url
	 * @param response 请求成功时的回调函数
	 * @param exception 异常的回调函数
	 */
	get(url, response, exception) {
		axios({
			method: 'get',
			url: handleUrl(url),
			timeout: TIME_OUT_MS,
			headers: {
				// 	// 'Content-Type': 'application/json; charset=UTF-8',
				"Organization-Code": location.hostname.split('.')[0],
				// 'Authorization:': 'Bearer' + sessionStorage.getItem('token')
			}
		}).then(
			(result) => {
				response(handleResults(result))
			}
		).catch(
			(error) => {
				if (exception) {
					exception(error)
				} else {
					// console.log(error)
				}
			}
		)
	},
	/*
	 * 导入文件
	 * @param url
	 * @param data
	 * @param response 请求成功时的回调函数
	 * @param exception 异常的回调函数
	 */
	uploadFile(url, data, response, exception) {
		axios({
			method: 'post',
			url: handleUrl(url),
			data: handleParams(data),
			dataType: 'json',
			processData: false,
			contentType: false
		}).then(
			(result) => {
				response(handleResults(result, data))
			}
		).catch(
			(error) => {
				if (exception) {
					exception(error)
				} else {
					// console.log(error)
				}
			}
		)
	},
	/*
	 * 下载文件用，导出 Excel 表格可以用这个方法
	 * @param url
	 * @param param
	 * @param fileName 如果是导出 Excel 表格文件名后缀最好用.xls 而不是.xlsx，否则文件可能会因为格式错误导致无法打开
	 * @param exception 异常的回调函数
	 */
	downloadFile(url, data, fileName, exception) {
		axios({
			method: 'post',
			url: handleUrl(url),
			data: handleParams(data),
			responseType: 'blob'
		}).then(
			(result) => {
				const excelBlob = result.data
				if ('msSaveOrOpenBlob' in navigator) {
					// Microsoft Edge and Microsoft Internet Explorer 10-11
					window.navigator.msSaveOrOpenBlob(excelBlob, fileName)
				} else {
					const elink = document.createElement('a')
					elink.download = fileName
					elink.style.display = 'none'
					const blob = new Blob([excelBlob])
					elink.href = URL.createObjectURL(blob)
					document.body.appendChild(elink)
					elink.click()
					document.body.removeChild(elink)
				}
			}
		).catch(
			(error) => {
				if (exception) {
					exception(error)
				} else {
					// console.log(error)
				}
			}
		)
	},
	uploadFileFormData(url, data, response, exception) {
		axios({
			method: 'post',
			url: handleUrl(url),
			data: data,
			timeout: TIME_OUT_MS,
			headers: {
				'Content-Type': 'multipart/form-data',
				"Organization-Code": 'st',
				'Authorization': 'Bearer' + sessionStorage.getItem('token')
			}
		}).then(
			(result) => {
				response(handleResults(result))
			}
		).catch(
			(error) => {
				if (exception) {
					exception(error)
				} else {
					// console.log(error)
				}
			}
		)
	},
	EditorFile(url, data, response, exception) {
		axios({
			method: 'post',
			url: url,
			data: data,
			timeout: TIME_OUT_MS,
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': 'Bearer' + sessionStorage.getItem('token')
			}
		}).then(
			(result) => {
				response(handleResults(result))
			}
		).catch(
			(error) => {
				if (exception) {
					exception(error)
				} else {
					// console.log(error)
				}
			}
		)
	},
	/*
	* 查看验证码
	* @param url
	* @param response
	* @param exception 异常的回调函数
	*/
	readVerifyCode(url, response, exception) {
		axios({
			method: 'get',
			url: handleUrl(url),
			responseType: 'blob'
		}).then(
			(result) => {
				response(result);
			}
		).catch(
			(error) => {
				if (exception) {
					exception(error)
				} else {
					// console.log(error)
				}
			}
		)
	}
}
