<template>
    <div class="MAM_risePrice">
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
            </div>
            <template>
                <el-tabs v-model="activeName">
                    <el-tab-pane
                        v-for="(items,indexs) in tabList"
                        :label="items.title"
                        :name="items.title"
                        :key="indexs"
                    >
                        <!-- 表格部分 -->
                        <el-table :data="[items]" size="mini" border style="width: 100%">
                            <!-- 交易方式 -->
                            <el-table-column
                                header-align="center"
                                fixed
                                align="center"
                                min-width="120"
                                label="交易方式"
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.title}}</span>
                                </template>
                            </el-table-column>
                            <!-- 结算周期 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="结算周期"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope">
                                        <!-- T0 & T1 -->
                                        <div
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >{{item.title}}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 卡类型 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="卡类型"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="four-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >{{v.title}}</div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 终端/底价 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="终端/底价"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-eights">
                                        <div
                                            class="tableScope-eight"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="eight-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >
                                                <div
                                                    class="view-data"
                                                    v-for="(j,k) in v.list"
                                                    :key="k"
                                                >{{j.title}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 涨价后费率% -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="涨价后费率%"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-eights">
                                        <div
                                            class="tableScope-eight"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="eight-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >
                                                <div
                                                    class="view-data"
                                                    v-for="(j,k) in v.list"
                                                    :key="k"
                                                >
                                                    <template v-if="j.type != 0">
                                                        <el-input
                                                            size="sm"
                                                            v-model="j.base_rate"
                                                            :placeholder="j.base_rate_placeholder"
                                                        ></el-input>
                                                    </template>
                                                    <template v-else>--</template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 是否设置封顶值 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="是否设置封顶值"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="four-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >{{v.list[i].is_overrun == 1? '是' : '否'}}</div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 达到某一金额时 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="达到某一金额时"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <template v-for="(v,i) in item.list">
                                                <div
                                                    class="four-mbox"
                                                    :key="i"
                                                    v-if="v.list[i].is_overrun == 1"
                                                >{{v.list[i].overrun_money}}</div>
                                                <div class="four-mbox" :key="i" v-else>--</div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 费率收取方式 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="费率收取方式"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <template v-for="(v,i) in item.list">
                                                <div
                                                    class="four-mbox"
                                                    :key="i"
                                                    v-if="v.list[i].overrun_type == 1"
                                                >按交易比例</div>
                                                <div
                                                    class="four-mbox"
                                                    :key="i"
                                                    v-if="v.list[i].overrun_type == 2"
                                                >按固定</div>
                                                <div
                                                    class="four-mbox"
                                                    :key="i"
                                                    v-if="v.list[i].overrun_type == 0"
                                                >--</div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 费率/金额 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="费率/金额"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-eights">
                                        <div
                                            class="tableScope-eight"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="eight-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >
                                                <div
                                                    class="view-data"
                                                    v-for="(j,k) in v.list"
                                                    :key="k"
                                                >
                                                    <template v-if="j.overrun_type == 1">
                                                        <el-input
                                                            size="sm"
                                                            v-model="j.overrun_rate"
                                                            :placeholder="j.overrun_rate_placeholder"
                                                        ></el-input>
                                                    </template>
                                                    <template v-if="j.overrun_type == 2">
                                                        <el-input
                                                            size="sm"
                                                            v-model="j.overrun_cap"
                                                            :placeholder="j.overrun_cap_placeholder"
                                                        ></el-input>
                                                    </template>
                                                    <template v-if="j.overrun_type == 0">--</template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 是否添加秒到费 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="是否添加秒到费"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-fours">
                                        <div
                                            class="tableScope-four"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="four-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >{{v.list[i].is_sec == 1? '是' : '否'}}</div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 秒到费（单笔添加） -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="150"
                                label="秒到费（单笔添加）"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope-eights">
                                        <div
                                            class="tableScope-eight"
                                            v-for="(item,index) in scope.row.list"
                                            :key="index"
                                        >
                                            <div
                                                class="eight-mbox"
                                                v-for="(v,i) in item.list"
                                                :key="i"
                                            >
                                                <div
                                                    class="view-data"
                                                    v-for="(j,k) in v.list"
                                                    :key="k"
                                                >
                                                    <template v-if="j.is_sec == 1">
                                                        <el-input
                                                            size="sm"
                                                            v-model="j.sec_fee"
                                                            :placeholder="j.sec_fee_placeholder"
                                                        ></el-input>
                                                    </template>
                                                    <template v-else>--</template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
                <el-button
                    type="primary"
                    :loading="loading"
                    style="font-size: 12px;margin-top:10px"
                    @click="handleSubmit()"
                >提 交</el-button>
            </template>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "MAM_risePrice",
    data() {
        return {
            tabList: [],
            activeName: '',
            loading: false,
            submitData: [],
        }
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        handleSubmit() { //提交
            this.submitData = [];
            let that = this;
            this.handleSubmitData(this.tabList);
            this.loading = true;
            this.$api.post('brand_rate_rise/upd', { brand_id: this.$route.query.id, data: this.submitData }, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.loading = false;
                        }
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 5000,
                    })
                    this.loading = false;
                }
            })

        },
        getInfo() {
            this.$api.post('brand_rate_rise/get_info', { brand_id: this.$route.query.id }, res => {
                if (res.code === 0) {
                    this.tabList = res.data;
                    this.activeName = res.data[0].title;
                } else {
                    if (res.code == 630003) {
                        this.$router.back();
                    }
                    this.$message.error(res.msg);
                }
            })
        },
        handleSubmitData(array) { //递归数据
            this._.forEach(array, item => {
                if (item.list) {
                    this.handleSubmitData(item.list);
                } else {
                    if (item.type != 0) {
                        if (item.base_rate == '') {
                            item.base_rate = item.base_rate_placeholder
                        }
                        if (item.overrun_rate == '') {
                            item.overrun_rate = item.overrun_rate_placeholder;
                        }
                        if (item.overrun_cap == '') {
                            item.overrun_cap = item.overrun_cap_placeholder;
                        }
                        if (item.sec_fee == '') {
                            item.sec_fee = item.sec_fee_placeholder;
                        }
                        this.submitData.push(item);
                    }
                }
            })
        }
    }
};
</script>

<style lang="scss">
.MAM_risePrice {
    .el-table--mini td,
    .el-table--mini th {
        padding: 0;
    }
    .el-table .el-table__header td,
    .el-table .el-table__header th {
        padding: 12px 0;
    }
    .el-table .cell {
        padding: 0;
    }
    .el-switch__label *,
    .el-button--text {
        // border: none;
        font-size: 12px;
    }
    .el-switch__label--left {
        border: none !important;
    }
    .el-input {
        width: 100px;
        .el-input__inner {
            // border: none;
            // height: 100%;
            text-align: center;
            font-size: 12px;
        }
    }
    .tableScope {
        display: flex;
        flex-direction: column;
        div {
            height: 200px !important;
            // flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
        }
        :not(:last-child) {
            border-bottom: 1px solid #ebeef5;
        }
        .isNull {
            background-color: #f5f7fa;
        }
        .notNull {
            background-color: none;
        }
    }
    .tableScope-fours {
        .tableScope-four {
            display: flex;
            flex-direction: column;
            .four-mbox {
                height: 100px;
                // flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-bottom: 1px solid #ebeef5;
            }
            .isNull {
                background-color: #f5f7fa;
            }
            .notNull {
                background-color: none;
            }
        }
        :last-child {
            :last-child {
                border-bottom: none;
            }
        }
    }
    .tableScope-eights {
        .tableScope-eight {
            .eight-mbox {
                display: flex;
                flex-direction: column;
                .view-data {
                    height: 50px;
                    // flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border-bottom: 1px solid #ebeef5;
                }
            }
        }
        :last-child {
            :last-child {
                .view-data:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
</style>