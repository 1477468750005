<template>
    <div class="serviceApplyOrder">
        <el-dialog
            width="800px"
            title="交易方式自定义"
            :visible="customInfo.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-card class="box-card" style="margin-top: 20px;" shadow="always">
                <div class="rm-word-aux">注：自定义前端交易方式名称及图标（建议尺寸117*99，jpg、png格式）</div>
                <el-row :gutter="20">
                    <el-col :span="12" v-for="(item,index) in payMentList" :key="index">
                        <div class="custom-uploader">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :show-file-list="false"
                                :on-success="handleUploadSuccess"
                            >
                                <img
                                    :src="item.icon_path"
                                    class="avatar"
                                    width="100%"
                                    height="100%"
                                />
                                <i
                                    slot="trigger"
                                    class="replaceIcon"
                                    @click="handleReplace(item)"
                                >替换图标</i>
                            </el-upload>
                        </div>
                        <div class="custom-name">
                            <div class="custom-title">{{item.name}}</div>
                            <div class="custom-input">
                                <el-input size="small" v-model="item.custom_name" maxlength="5"></el-input>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-button type="primary" @click="handleSubmit">保存配置</el-button>
            </el-card>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        customInfo: {
            type: Object
        }
    },
    data() {
        return {
            picFlag: {}, // 临时存储标记
            payMentList: [],
            uploadUrl: '',
        };
    },
    mounted() {
        this.uploadUrl = this.$api.uploadUrl;//上传文件地址
        this.getPayment();
    },
    methods: {
        handleReplace(val) { //存储标记
            this.picFlag = val;
        },
        handleUploadSuccess(res) {  //上传成功后赋值
            this.picFlag.icon = res.data.file_path;
            this.picFlag.icon_path = res.data.file_url;
        },
        handleSubmit() { //提交
            let _this = this;
            let reqData = [];
            this.payMentList.forEach((item) => {
                reqData.push(this._.omit(item, ['icon_path']));
            });
            this.$api.post('brand/update_custom_brand_pay_type', {
                brand_id: this.customInfo.row.id,
                data: JSON.stringify(reqData)
            }, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            _this.handleClose(1);
                        }
                    })
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        handleClose(ref) { //关闭
            this.$emit('handleClose', ref)
        },
        getPayment() { //获取交易方式
            this.$api.post('brand/custom_brand_pay_type', { brand_id: this.customInfo.row.id }, res => {
                if (res.code == 0) {
                    this.payMentList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.rm-word-aux {
    margin-bottom: 10px;
}
.el-col {
    display: flex;
    margin-bottom: 10px;
    .custom-uploader {
        width: 60px;
        height: 60px;
        overflow: hidden;
        background-color: #fdfdfd;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        position: relative;
        top: 0;
        left: 0;
        .avatar-uploader {
            height: 100%;
        }
        .replaceIcon {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0px;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.4);
            font-size: 13px;
            color: #ffffff;
            font-style: inherit;
            cursor: pointer;
            transition: all 0.5s;
        }
    }
    .custom-uploader:hover .replaceIcon {
        height: 20px;
    }
    .custom-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        .custom-title {
            font-size: 12px;
            font-weight: 700;
            margin-bottom: 10px;
        }
    }
    .custom-uploader,
    .custom-name {
        box-sizing: border-box;
    }
}
</style>