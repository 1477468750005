<template>
    <div class="serviceApplicationList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input clearable v-model="searchForm.account" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.audit_status" clearable placeholder="请选择审核类型">
                        <el-option label="待审核" value="1"></el-option>
                        <el-option label="审核通过" value="2"></el-option>
                        <el-option label="审核拒绝" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="handleApply">立即申请</el-button>
            </div>
            <el-table
                :data="applyList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 申请时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="application_date"
                    label="申请时间"
                    min-width="150"
                ></el-table-column>
                <!-- 总金额(元) -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="application_money"
                    label="总金额(元)"
                    min-width="120"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status_name"
                    label="状态"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span v-show="scope.row.audit_status == 1">待审核</span>
                        <span v-show="scope.row.audit_status == 2">审核通过</span>
                        <el-popover placement="right" width="400" trigger="click">
                            <!-- <div>

                            </div>-->
                            <div class="rm-label" style="display:inline-block">审核状态：</div>
                            <span class="rm-word-aux">{{scope.row.status_name}}</span>
                            <div class="rm-label">拒绝原因：</div>
                            <el-input type="textarea" :disabled="true" v-model="scope.row.remark"></el-input>
                            <el-button
                                v-show="scope.row.audit_status == 3"
                                type="text"
                                size="small"
                                slot="reference"
                            >审核拒绝</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
                <!-- 审核时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="audit_date"
                    label="审核时间"
                    min-width="150"
                ></el-table-column>
                <!-- 账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="account"
                    label="账号"
                    min-width="120"
                ></el-table-column>
                <!-- 密码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="password"
                    label="密码"
                    min-width="120"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <serviceApplyOrder v-if="info.isShow" :info="info" @close="handleApplyClose"></serviceApplyOrder>
    </div>
</template>

<script>
import serviceApplyOrder from './components/serviceApplyOrder';
export default {
    name: "serviceApplicationList",
    data() {
        return {
            info: {
                type: 1,
                isShow: false
            },
            applyList: [], //table
            searchForm: {
                page: 1,
                limit: 10,
                account: '', //代理商账号
                audit_status: '', //审核状态

            },
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        })
    },
    components: {
        serviceApplyOrder
    },
    methods: {
        handleSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleApply() { //立即申请
            this.info.isShow = true;
        },
        handleApplyClose(ref) { //关闭申请
            this.info.isShow = false;
            if (ref) {
                this.getData({
                    page: 1,
                    limit: 10
                })
            }
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("online_service/get_online_service_application_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.applyList = data.list;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
