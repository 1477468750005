<template>
    <div class>
        <el-dialog
            :title="spreadHead"
            width="550px"
            :visible="terminalInfo.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-form>
                <el-form-item>
                    <el-input size="medium" placeholder="请输入SN搜索" v-model="searchForm.sn">
                        <el-button
                            size="medium"
                            slot="append"
                            type="primary"
                            icon="el-icon-search"
                            style="font-size: 12px;"
                            @click="handleSearch(searchForm)"
                        >搜索</el-button>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-table
                :data="terminalList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- 序号 -->
                <el-table-column
                    type="index"
                    header-align="center"
                    align="center"
                    label="序号"
                    width="100"
                    :index="handleTableIndex"
                ></el-table-column>
                <!-- Sn -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="sn"
                    label="SN码"
                    min-width="200"
                ></el-table-column>
                <!-- 政策标识 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="policy"
                    label="政策标识"
                    min-width="100"
                ></el-table-column>
                <!-- 终端来源 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="term_source_name"
                    label="终端来源"
                    min-width="80"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                small
                layout="prev, pager, next"
                :current-page="currentPage"
                @current-change="getCurrent"
                :total="total"
            ></el-pagination>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        terminalInfo: {
            type: Object
        }
    },
    data() {
        return {
            searchForm: { // 初始数据
                sn: '',
                type: 1, // 1=全部，2未入库的,3已入库的
                page: 1,
                limit: 10
            },
            terminalList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false,
        };
    },
    computed: {
        spreadHead() {
            let title = '';
            switch (this.terminalInfo.type) {
                case 1:
                    title = '总数量详情';
                    break;
                case 2:
                    title = '未入库详情';
                    break;
                case 3:
                    title = '已入库详情';
                    break;
            }
            return title;
        }
    },
    created() {
        if (this.terminalInfo.statisticalType == 1) {
            this.searchForm.id = this.terminalInfo.info.id;
        }
        this.searchForm.type = this.terminalInfo.type;
        this.getData(this.searchForm);
    },
    mounted() {
    },
    methods: {
        handleSearch(searchForm) {
            searchForm.page = 1;
            this.currentPage = 1;
            this.getData(searchForm);
        },
        handleClose(ref) { //关闭弹窗
            this.$emit('infoClose', ref);
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.currentPage = page;
            this.searchForm.page = page;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            let BASEURL = '';
            switch (this.terminalInfo.statisticalType) {
                case 1:
                    BASEURL = 'sub_terminal_in_stock_log/get_terminal_sn_list';
                    break;
                case 2:
                    BASEURL = 'sub_terminal_in_stock_log/get_statistics_terminal_info';
                    break;
            }
            this.$api.post(BASEURL, params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.terminalList = data.list; //列表
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            })
        },
        handleTableIndex(index) { // 处理序号
            return (this.currentPage - 1) * this.pageSize + index + 1;
        }
    }
};
</script>

<style scoped lang="scss">
</style>