<template>
    <div class>
        <el-dialog
            ref="checkForm"
            :visible="info.isShow"
            @close="handleClose"
            :close-on-click-modal="false"
            width="570px"
            title="查看提现手续费"
        >
            <el-card class="box-card" shadow="hover">
                <el-form :model="checkForm" label-position="top" label-width="100px">
                    <template v-if="checkForm.collection_tax_point_switch == 1">
                        <div class="costTitle">代收税点</div>
                        <el-form-item :label="checkForm.collection_tax_point_title">
                            <el-input
                                disabled
                                clearable
                                placeholder
                                size="medium"
                                v-model="checkForm.collection_tax_point"
                            ></el-input>
                        </el-form-item>
                    </template>
                    <template v-if="checkForm.withdrawal_service_fee_switch == 1">
                        <div class="costTitle">出款服务费</div>
                        <el-form-item :label="checkForm.withdrawal_service_fee_title">
                            <el-input
                                disabled
                                clearable
                                placeholder
                                size="medium"
                                v-model="checkForm.withdrawal_service_fee"
                            ></el-input>
                        </el-form-item>
                    </template>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            agentLoading: false,
            codeButton: false,
            codeText: '发送验证码',
            checkForm: {

            },

        }
    },
    created() {
        this.getCost();
    },
    mounted() {
    },
    methods: {
        getCost() { // 获取提现手续费
            let id = this.info.row.id;
            this.$api.post('user/show_user_withdrawal_fee', { id }, res => {
                if (res.code == 0) {
                    this.checkForm = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        handleClose(ref) {
            this.$emit('infoClose', ref)
        }
    }
}
</script>

<style scoped lang="scss">
.costTitle {
    color: #000000;
    font-size: 13px;
    font-weight: 700;
    padding-left: 5px;
    margin: 15px 0;
    border-left: 4px solid #409eff;
}
</style>
