<template>
    <div class="MAM_terminalReturn">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input clearable v-model="searchForm.order_num" placeholder="请输入订单号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        clearable
                        v-model="searchForm.launch_user_name"
                        placeholder="请输入申请人姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        clearable
                        v-model="searchForm.receive_user_name"
                        placeholder="请输入接收人姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="searchForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.is_offset" clearable placeholder="请选择是否抵消欠款">
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.status" clearable placeholder="请选择退回状态">
                        <el-option label="等待确认中" value="1"></el-option>
                        <el-option label="已确认" value="2"></el-option>
                        <el-option label="已拒绝" value="3"></el-option>
                        <el-option label="已撤销" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="订单创建开始日期"
                        end-placeholder="订单创建结束日期"
                        value-format="yyyy-MM-dd"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-table
                :data="returnList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 订单号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="order_num"
                    label="订单号"
                    min-width="250"
                ></el-table-column>
                <!-- 申请人姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="l_real_name"
                    label="申请人姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 申请人账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="l_phone"
                    label="申请人账号"
                    min-width="120"
                ></el-table-column>
                <!-- 接收人姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="r_real_name"
                    label="接收人姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 接收人账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="r_phone"
                    label="接收人账号"
                    min-width="120"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="120"
                ></el-table-column>
                <!-- 终端数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_num"
                    label="终端数量"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleDetail(scope.row)"
                            type="text"
                            size="small"
                        >{{scope.row.terminal_num}}</el-button>
                    </template>
                </el-table-column>
                <!-- 是否抵消欠款 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="is_offset"
                    label="是否抵消欠款"
                    min-width="120"
                >
                    <template slot-scope="scope">{{scope.row.is_offset == 1? '是':'否'}}</template>
                </el-table-column>
                <!-- 终端单价 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_price"
                    label="终端单价"
                    min-width="120"
                ></el-table-column>
                <!-- 抵消金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="offset_money"
                    label="抵消金额"
                    min-width="120"
                ></el-table-column>
                <!-- 订单状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="订单状态"
                    min-width="120"
                    :formatter="formatStatus"
                ></el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="创建时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="deal_time"
                    label="操作时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleAudit(scope.row)"
                            type="text"
                            size="small"
                            v-show="scope.row.receive_user_id == 0 && scope.row.status == 1"
                        >审核</el-button>
                        <el-popover placement="left" width="400" trigger="click" title="拒绝原因">
                            <el-input
                                type="textarea"
                                v-model="reason"
                                :disabled="true"
                                style="font-size:12px"
                            ></el-input>
                            <el-button
                                @click="handleRefusalCause(scope.row)"
                                type="text"
                                size="small"
                                slot="reference"
                                v-show="scope.row.receive_user_id == 0 && scope.row.status == 3"
                            >查看拒绝原因</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <!-- 终端数量 -->
        <return-count v-if="countInfo.isShow" :countInfo="countInfo" @countClose="countClose"></return-count>
        <!-- 审核 -->
        <audit-info v-if="auditInfo.isShow" :auditInfo="auditInfo" @auditClose="auditClose"></audit-info>
    </div>
</template>

<script>
import returnCount from './MAM_returnCount';
import auditInfo from './MAM_auditInfo';
export default {
    name: "MAM_terminalReturn",
    data() {
        return {
            countInfo: { //终端明细
                isShow: false,
                info: {}
            },
            auditInfo: { //审核
                isShow: false,
                info: {}
            },
            brandList: [],
            reason: '', //拒绝原因
            searchForm: {
                page: 1,
                limit: 10,
                order_num: '', //订单号
                launch_user_name: '', //申请人姓名
                receive_user_name: '', //接收人姓名
                brand_id: '', //终端类型
                is_offset: '', //是否抵消欠款
                status: '', // 划拨状态
                start_time: '',
                end_time: '',

            },
            rangeDate: '', //订单创建时间
            returnList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true
        }
    },
    mounted() {
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$$message.success(res.msg);
            }
        });
        this.getData({
            page: 1,
            limit: 10
        })
    },
    components: {
        returnCount,
        auditInfo
    },
    methods: {
        onSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleDetail(row) { //终端数量
            this.countInfo.isShow = true;
            this.countInfo.info = row;
        },
        countClose() { //关闭终端数量
            this.countInfo.isShow = false;
        },
        handleAudit(row) { //审核
            this.auditInfo.isShow = true;
            this.auditInfo.info = row;
        },
        auditClose(ref) {
            this.auditInfo.isShow = false;
            if (ref) {
                this.getData(this.searchForm);
            }
        },
        handleRefusalCause(row) { //查看拒绝原因
            this.reason = row.reason;
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("terminal_return/get_terminal_return_list", params, res => {
                let data = res.data;
                this.total = data.count; //总数
                this.returnList = res.data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        formatStatus(row) { //格式化订单状态
            let status;
            switch (row.status) {
                case 1:
                    status = '等待确认中';
                    break;
                case 2:
                    status = '已确认';
                    break;
                case 3:
                    status = '已拒绝';
                    break;
                default:
                    status = '已撤销';
                    break;
            }
            return status;
        },
        pickerChange(DateArr) { //时间选择器
            this.searchForm.start_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.searchForm.end_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
};
</script>

<style lang="scss" scope>
.MAM_terminalReturn {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
    .el-date-editor .el-range-input,
    .el-date-editor .el-range-separator {
        font-size: 12px;
    }
    .el-popover__title {
        font-size: 12px;
        font-weight: 700;
    }
}
</style>
