<template>
    <div>
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="loanSearchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="loanSearchForm.borrow_name"
                        placeholder="请输入借款代理商名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="loanSearchForm.borrow_phone"
                        placeholder="请输入借款代理商账号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="loanSearchForm.loan_name"
                        placeholder="请输入出款代理商名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="loanSearchForm.loan_phone"
                        placeholder="请输入出款代理商账号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(loanSearchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-table :data="loanTable" size="mini" border style="width: 100%" v-loading="loading">
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="borrow_user_id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 出款方代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="loan_name"
                    label="出款方代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 出款方代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="loan_phone"
                    label="出款方代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 借款代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="borrow_name"
                    label="借款代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 借款代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="borrow_phone"
                    label="借款代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 贷款金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="loan_money"
                    label="贷款金额"
                    min-width="120"
                ></el-table-column>
                <!-- 当前欠款金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="arrears_money"
                    label="当前欠款金额"
                    min-width="120"
                ></el-table-column>
                <!-- 交易分润还款比例 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="profit"
                    label="交易分润还款比例"
                    min-width="120"
                ></el-table-column>
                <!-- 激活奖励还款比例 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="active"
                    label="激活奖励还款比例"
                    min-width="120"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">详情</el-button>
                        <el-button
                            @click="handleOffsetLoan(scope.row)"
                            type="text"
                            size="small"
                            v-if="scope.row.is_repeal == 1"
                        >终端抵消欠款</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <terminal-offset
            v-if="offsetLoanIsShow"
            :offsetLoanIsShow="offsetLoanIsShow"
            :detail="detail"
            @terminalOffsetClose="terminalOffsetClose"
        ></terminal-offset>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import terminalOffset from './UM_terminalOffset';
export default {
    name: "UM_loanList",
    data() {
        return {
            loanTable: [], //列表
            loading: true, //列表loadding
            offsetLoanIsShow: false, //终端抵消欠款
            detail: {
                userId: null, //代理商id
                amount: null //欠款金额
            },
            loanSearchForm: { //搜索表单
                loan_name: '',
                loan_phone: '',
                borrow_name: '',
                borrow_phone: '',
                page: 1,
                limit: 10
            },
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
        }
    },
    mounted() {
        this.getData({
            page: this.page.currentPage,
            limit: this.page.pageSize,
        });
    },
    components: {
        paginations,
        terminalOffset
    },
    methods: {
        onSearch(loanSearchForm) {
            this.page.currentPage = 1;
            let searchData = this._.assign(this._.cloneDeep(loanSearchForm), { page: this.page.currentPage, limit: this.page.pageSize });
            // 表单提交
            this.getData(searchData);
        },
        handleInfo(row) { //查看
            this.$router.push({ path: '/loanInfo', query: { id: row.borrow_user_id } });
        },
        handleOffsetLoan(row) { //终端撤销抵消欠款
            this.offsetLoanIsShow = true;
            this.detail.userId = row.borrow_user_id;
            this.detail.amount = row.arrears_money;
        },
        terminalOffsetClose(ref) { //关闭抵消欠款弹窗
            this.offsetLoanIsShow = false;
            if (ref) {
                let searchData = this._.assign(this._.cloneDeep(this.loanSearchForm), { page: this.page.currentPage, limit: this.page.pageSize });
                this.loading = true;
                this.getData(searchData);
            }
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.loanSearchForm.page = this.page.currentPage;
            this.loanSearchForm.limit = this.page.pageSize;
            this.getData(this.loanSearchForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.loanSearchForm.page = this.page.currentPage;
            this.loanSearchForm.limit = limit;
            this.getData(this.loanSearchForm);
        },
        getData(params) {
            this.$api.post("user/loan_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.loanTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scope>
.el-input__inner {
    font-size: 12px;
}
.el-form-item {
    margin-bottom: 5px;
}
</style>
