import Vue from 'vue'
// eslint-disable-next-line
import _ from 'lodash';
import ajax from '../api/index';


export default {
	handleExportExcel(option) { //导出
		const message = Vue.prototype.$message;
		return new Promise(resolve => {
			ajax.post('export/add', option, res => {
				if (res.code == 0) {
					message.success(res.msg);
				} else {
					message.error(res.msg);
				}
				resolve(res);
			})
		});
	}
}