<template>
    <div :class="classObj" class="app-wrapper">
        <Sidebar class="sidebar-container"></Sidebar>
        <div class="main-container">
            <navbar></navbar>
            <app-main></app-main>
        </div>
    </div>
</template>

<script>
    import Sidebar from './components/sidebar/index.vue'
    import Navbar from "./components/navbar";
    import AppMain from "./components/appMain";

    export default {
        name: "index",
        components: {
            AppMain,
            Navbar,
            Sidebar
        },
        data() {
            return {}
        },
        computed:{
            sidebar() {
                return this.$store.state.app.sidebar
            },
            classObj() {
                return {
                    hideSidebar: !this.sidebar.opened,
                    openSidebar: this.sidebar.opened,
                }
            }
        },
        methods: {}
    }
</script>

<style scoped lang="scss">
    @import "~@/assets/styles/mixin.scss";

    .app-wrapper {
        @include clearfix;
        position: relative;
        height: 100%;
        width: 100%;
    }
</style>