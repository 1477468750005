<template>
    <div class="FM_withdrawalList">
        <!-- 代理商提现 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-select v-model="searchForm.role_id" clearable placeholder="请选择代理商所属角色">
                        <el-option
                            v-for="item in roleList"
                            :label="item.name"
                            :value="item.id"
                            :key="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.real_name" placeholder="请输入代理商姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.phone" placeholder="请输入代理商账户"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.level" placeholder="请输入代理商层级"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.bank_code" placeholder="请输入银行卡号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="searchForm.status" clearable placeholder="请选择当前状态">
                        <el-option label="待审核" value="0"></el-option>
                        <el-option label="提现成功" value="1"></el-option>
                        <el-option label="审核拒绝" value="2"></el-option>
                        <el-option label="代付提交中" value="3"></el-option>
                        <el-option label="队列中" value="4"></el-option>
                        <el-option label="提现失败" value="5"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item>
                    <el-select size v-model="searchForm.type" clearable placeholder="请选择结算方式">
                        <el-option label="线下支付" value="1"></el-option>
                        <el-option label="平台支付" value="4"></el-option>
                        <el-option label="海科出款" value="5"></el-option>
                    </el-select>
                </el-form-item>-->
                <el-form-item>
                    <el-date-picker
                        v-model="creatDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="申请开始日期"
                        end-placeholder="申请结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="creatDateChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="auditDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="审核开始日期"
                        end-placeholder="审核结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="auditDateChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button
                    v-show="is_payment_account == 1"
                    size="small"
                    type="primary"
                    icon="el-icon-bank-card"
                    @click="batchPaid"
                >批量代付</el-button>
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    :loading="downLoading"
                    @click="handleDownLoad"
                >导出</el-button>
            </div>
            <el-table
                :data="withdrawalTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                @selection-change="selectChange"
            >
                <!-- :selectable="handleSelectable" -->
                <el-table-column
                    fixed
                    label="序号"
                    align="center"
                    min-width="80"
                    type="selection"
                    header-align="center"
                ></el-table-column>
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 代理所属角色 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_level"
                    label="代理所属角色"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.agent_level == 1">主机构</span>
                        <span v-else-if="scope.row.agent_level == 2">机构</span>
                        <span v-else-if="scope.row.agent_level == 3">签约代理</span>
                        <span v-else>--</span>
                    </template>
                </el-table-column>
                <!-- 代理商姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="代理商姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 代理商账户 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账户"
                    min-width="120"
                ></el-table-column>
                <!-- 卡号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="bank_code"
                    label="卡号"
                    min-width="200"
                ></el-table-column>
                <!-- 提现金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="money"
                    label="提现金额"
                    min-width="200"
                ></el-table-column>
                <!-- 代收税点 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="fee_money"
                    label="代收税点"
                    min-width="120"
                ></el-table-column>
                <!-- 出款服务费 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="service_fee"
                    label="出款服务费"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.service_fee == 0? '--':scope.row.service_fee}}</span>
                    </template>
                </el-table-column>
                <!-- 税率扣点 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="id_card"
                    label="税率扣点"
                    min-width="220"
                ></el-table-column>-->
                <!-- 税票抵扣 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="税票抵扣"
                    min-width="150"
                ></el-table-column>-->
                <!-- 结算金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="true_money"
                    label="结算金额"
                    min-width="150"
                ></el-table-column>
                <!-- 代付费用 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="pay_fee"
                    label="代付费用"
                    min-width="120"
                ></el-table-column>-->
                <!-- 当前状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="当前状态"
                    min-width="120"
                    :formatter="formatStatus"
                ></el-table-column>
                <!-- 结算方式 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="type"
                    label="结算方式"
                    min-width="120"
                    :formatter="formatType"
                ></el-table-column>
                <!-- 申请时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="申请时间"
                    min-width="150"
                ></el-table-column>
                <!-- 审核时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="audit_time"
                    label="审核时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleInfo(scope.row)"
                            type="text"
                            size="small"
                            slot="reference"
                        >详情</el-button>&nbsp;
                        <!-- 撤销验证 -->
                        <el-button
                            type="text"
                            size="small"
                            slot="reference"
                            @click="handleUndo(scope.row)"
                            v-if="scope.row.status == 0 || scope.row.status == 5"
                        >审核</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <!-- 批量支付弹窗 -->
        <el-dialog :visible.sync="batchDialog" width="430px">
            <div class="batchTitle">
                <span>付款金额：</span>
                <span>{{batchInfo.payMoney.toFixed(2)}}&nbsp;元</span>
            </div>
            <div class="batchTitle">
                <span>付款笔数：</span>
                <span>{{batchInfo.payNum}}&nbsp;笔</span>
            </div>
            <div class="batchTitle">
                <span>付款费用：</span>
                <span>{{batchInfo.payCost.toFixed(2)}}&nbsp;元</span>
            </div>
            <el-divider></el-divider>
            <div class="batchTitle">
                <span>代付账户余额：</span>
                <span>{{batchInfo.payBalance}}&nbsp;元</span>
                <span class="batchTitle" style="margin-left:10px;">
                    <span>所需费用：</span>
                    <span>{{batchInfo.payCount.toFixed(2)}}&nbsp;元</span>
                </span>
            </div>
            <el-button
                type="primary"
                @click="submitBatch"
                size="small"
                :disabled="batchInfo.payButton"
            >确 定</el-button>
            <el-button @click="batchDialog = false" size="small">取 消</el-button>
        </el-dialog>
        <!-- 审核&详情 -->
        <info-audit
            v-if="infoIsShow"
            :infoIsShow="infoIsShow"
            :dialogData="dialogData"
            @infoClose="infoClose"
        ></info-audit>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import infoAudit from './FM_withdrawalInfo';
export default {
    data() {
        return {
            roleList: [], // 代理商角色
            is_payment_account: 0, //是否设置代付通道：0=否，1=是
            payRate: 0.071, //代付费率
            creatDate: '', //申请时间范围
            auditDate: '', //审核时间范围
            batchDialog: false, //批量代付弹窗
            infoIsShow: false, //审核弹窗
            dialogData: {
                isInfo: null, //弹窗类型 1.详情 2.审核
                data: {} //当前行的数据
            },
            batchList: [], //批量支付数据源
            batchInfo: {
                batchReqData: [], //请求的id
                payMoney: 0, //付款金额
                payNum: 0, //付款笔数
                payCost: 0, //付款费用
                payBalance: 0, //代付账户余额
                payCount: 0, //所需费用
                payButton: false
            },
            withdrawalTable: [], //表格
            searchForm: {
                phone: '', //代理商账号
                real_name: '',   //代理商真实姓名
                type: '',// 结算方式
                status: '',//当前状态
                bank_code: '',
                start_create_time: '',    //申请开始时间
                end_create_time: '',    //申请结束时间
                start_audit_time: '',  //审核开始时间
                end_audit_time: '',  //审核结束时间
                page: 1,
                limit: 10
            },
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true,
            downLoading: false //导出loading
        }
    },
    created() {
        this.getRole();
    },
    mounted() {
        this.getData( //初始化
            {
                page: 1,
                limit: this.page.pageSize
            }
        );
    },
    components: {
        paginations,
        infoAudit
    },
    methods: {
        handleSearch(searchForm) {
            this.searchForm.page = 1;
            this.page.currentPage = 1;
            // 表单提交
            this.getData(searchForm);
        },
        handleDownLoad() { //导出
            let handleExportExcel = this.common.handleExportExcel;
            this.downLoading = true;
            let searchVal = this._.omit(this.searchForm, ['page', 'limit']);
            let value = {};
            this._.forEach(searchVal, (item, index) => {
                if (item != '') {
                    value[index] = item;
                }
            });
            handleExportExcel({ type: 1, value: JSON.stringify(value) }).then(() => {
                this.downLoading = false;
            });
        },
        handleInfo(row) { //详情
            this.infoIsShow = true;
            this.dialogData.isInfo = 1;
            this.dialogData.data = row;
        },
        handleUndo(row) { //审核
            this.infoIsShow = true;
            this.dialogData.isInfo = 2;
            this.dialogData.data = row;
            this.dialogData.data.isShowPayMethods = this.is_payment_account;
        },
        batchPaid() { //批量代付
            let batchData = this.batchList;
            if (batchData.length != 0) {
                let flag = true;
                this._.forEach(batchData, item => {
                    if (item.status != 0) {
                        this.$message.error('如需批量代付，“当前状态”需筛选成“待审核”状态');
                        flag = false;
                        return false;
                    }
                });
                if (flag) {
                    //重置
                    this.batchInfo.batchReqData = [];
                    this.batchInfo.payMoney = 0;
                    this.batchInfo.payNum = 0;
                    this.batchInfo.payCost = 0;
                    this.batchInfo.payBalance = 0;
                    this.batchInfo.payCount = 0;
                    //赋值
                    this._.forEach(batchData, item => {
                        this.batchInfo.batchReqData.push(item.id); //请求的数据
                        this.batchInfo.payMoney = this.batchInfo.payMoney + Number(item.true_money); //付款金额
                    });
                    this.batchInfo.payNum = batchData.length; //付款笔数
                    this.batchInfo.payCost = this.batchInfo.payMoney * this.payRate;  //付款费用
                    this.batchInfo.payCount = this.batchInfo.payMoney + this.batchInfo.payCost;  //所需费用
                    this.batchDialog = true; //弹窗
                    //获取代付账户余额
                    this.$api.post('organization/getBalance', {}, res => {
                        if (res.code == 0) {
                            this.batchInfo.payBalance = res.data.payment_balance;
                            if (this.batchInfo.payCount > this.batchInfo.payBalance) {
                                this.batchInfo.payButton = true; //所需费用大于余额禁用
                            } else {
                                this.batchInfo.payButton = false;
                            }
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }
            } else {
                this.$message.error('请先选择批量代付数据');
            }
        },
        submitBatch() { //提交批量代付
            this.$api.post('user/withdrawAuditBatch', { ids: this.batchInfo.batchReqData }, res => {
                if (res.code == 0) {
                    this.$message.success(res.msg);
                    this.batchDialog = false;
                    this.getData(this.searchForm);
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        infoClose(ref) {
            this.infoIsShow = false;
            if (ref) {
                this.getData(this.searchForm);//刷新
            }
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.searchForm.page = this.page.currentPage;
            this.searchForm.limit = this.page.pageSize;
            this.getData(this.searchForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.page.pageSize = limit;
            this.searchForm.page = this.page.currentPage;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取列表
            this.loading = true;
            this.$api.post("user/withdraw_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.page.total = data.count; //总数
                    this.withdrawalTable = data.list;
                    this.payRate = Number(data.rate);
                    this.is_payment_account = data.is_payment_account;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        getRole() { // 获取代理商角色
            this.$api.post("agent/get_role_list", {}, res => {
                if (res.code == 0) {
                    this.roleList = res.data;
                }
            })
        },
        // handleSelectable(row) { // 一级代理商禁用
        //     if (row.level == 1) {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // },
        selectChange(val) { //多选发生改变时
            this.batchList = val;
        },
        formatStatus(row) { //格式化当前状态
            var status = '';
            switch (row.status) {
                case 0:
                    status = '待审核';
                    break;
                case 1:
                    status = '提现成功';
                    break;
                case 2:
                    status = '审核拒绝';
                    break;
                case 3:
                    status = '代付提交中';
                    break;
                case 4:
                    status = '队列中';
                    break;
                case 5:
                    status = '提现失败';
                    break;
            }
            return status;
        },
        formatType(row) { //格式化结算方式
            var type = '';
            switch (row.type) {
                case 0:
                    type = '- -';
                    break;
                case 1:
                    type = '线下支付';
                    break;
                case 2:
                    type = '微信支付';
                    break;
                case 3:
                    type = '支付宝支付';
                    break;
                case 4:
                    type = '平台代付';
                    break;
                case 5:
                    type = '海科出款';
                    break;
            }
            return type;
        },
        creatDateChange(DateArr) { //申请时间
            this.searchForm.start_create_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.searchForm.end_create_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        },
        auditDateChange(DateArr) { //审核时间
            this.searchForm.start_audit_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.searchForm.end_audit_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
};
</script>

<style lang="scss" scope>
.FM_withdrawalList {
    .batchTitle {
        font-size: 12px;
        margin: 20px 0;
        :nth-child(1) {
            font-weight: 700;
            color: #1e1e1e;
        }
    }
    // .el-dialog__header {
    //     padding: 20px 20px 0px;
    // }
    .el-dialog__body {
        padding: 0px 20px 30px 20px;
    }
    // .el-input__inner {
    //     font-size: 12px;
    // }
    // .el-form-item {
    //     margin-bottom: 5px;
    // }
    .el-dialog {
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /*height:600px;*/
        max-height: calc(100% - 30px);
        max-width: calc(100% - 30px);
    }
    .el-dialog .el-dialog__body {
        flex: 1;
        overflow: auto;
    }
}
</style>
