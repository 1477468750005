<template>
    <div class="DM_ladderLogInfo">
        <!-- 上级补贴记录详情 1.配置记录 2.触发记录 -->
        <el-dialog :visible="info.isShow" @close="handleClose" :close-on-click-modal="false">
            <el-card class="box-card" shadow="hover">
                <div class="purseTitle">
                    <div>基本信息</div>
                </div>
                <el-row class="rowTitle">
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">终端品牌：</span>
                        <span>{{info.row.brand_name}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">奖励类型：</span>
                        <span>{{info.row.type}}</span>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="24">
                        <span class="labels">奖励描述：</span>
                        <span>{{info.row.reward_description}}</span>
                    </el-col>
                </el-row>
                <div class="purseTitle">
                    <div>{{info.type == 1? '配置':'触发'}}记录</div>
                </div>
                <template v-if="info.type == 1">
                    <el-table
                        :data="recordList"
                        size="mini"
                        border
                        style="width: 100%"
                        v-loading="loading"
                    >
                        <!-- 配置时间 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            fixed
                            prop="create_time"
                            label="配置时间"
                            min-width="150"
                        ></el-table-column>
                        <!-- 修改前奖励金额 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="before_reward_money"
                            label="修改前奖励金额"
                            min-width="120"
                        ></el-table-column>
                        <!-- 修改前奖励个数 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="before_reward_num"
                            label="修改前奖励个数"
                            min-width="120"
                        ></el-table-column>
                        <!-- 修改前已消耗个数 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="before_consume_num"
                            label="修改前已消耗个数"
                            min-width="120"
                        ></el-table-column>
                        <!-- 调整后奖励金额 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="after_reward_money"
                            label="调整后奖励金额"
                            min-width="120"
                        ></el-table-column>
                        <!-- 调整后奖励个数 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="after_reward_num"
                            label="调整后奖励个数"
                            min-width="120"
                            show-overflow-tooltip
                        ></el-table-column>
                        <!-- 变动金额 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="change_money"
                            label="变动金额"
                            min-width="150"
                        ></el-table-column>
                        <!-- 支付方式 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="pay_type"
                            label="支付方式"
                            min-width="150"
                        ></el-table-column>
                        <!-- 订单号 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="order_num"
                            label="订单号"
                            min-width="150"
                        ></el-table-column>
                    </el-table>
                </template>
                <template v-else>
                    <el-table
                        :data="recordList"
                        size="mini"
                        border
                        style="width: 100%"
                        v-loading="loading"
                    >
                        <!-- 触发时间 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            fixed
                            prop="trigger_time"
                            label="触发时间"
                            min-width="150"
                        ></el-table-column>
                        <!-- 归属代理商名称 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="real_name"
                            label="归属代理商名称"
                            min-width="120"
                        ></el-table-column>
                        <!-- 归属代理商账号 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="phone"
                            label="归属代理商账号"
                            min-width="120"
                        ></el-table-column>
                        <!-- SN -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="terminal_sn"
                            label="SN"
                            min-width="120"
                        ></el-table-column>
                        <!-- 奖励金额 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="reward_money"
                            label="奖励金额"
                            min-width="120"
                        ></el-table-column>
                        <!-- 奖励时间 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="create_time"
                            label="奖励时间"
                            min-width="150"
                            show-overflow-tooltip
                        ></el-table-column>
                    </el-table>
                </template>
                <!-- 分页部分 -->
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,50,100]"
                    :current-page="page"
                    :page-size="limit"
                    :total="total"
                    layout="total, sizes, prev, pager, next, jumper"
                ></el-pagination>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            url: '',//记录地址
            recordList: [], //表格
            page: 1, //当前页码
            limit: 10, //条数
            total: 0,  //总数
            loading: true
        }
    },
    created() {
        let settingUrl = 'brand_reward_subsidy/brand_reward_subsidy_edit_list'; //配置接口
        let triggerUrl = 'brand_reward_subsidy/brand_reward_subsidy_trigger_list'; //触发接口
        this.url = this.info.type == 1 ? settingUrl : triggerUrl
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10,
            brand_reward_subsidy_id: this.info.row.id
        });
    },
    methods: {
        handleClose() {
            this.$emit('infoClose')
        },
        handleCurrentChange(page) { //当前选中页
            this.page = page;
            this.getData({
                page,
                limit: this.limit
            });
        },
        handleSizeChange(limit) { //条数
            this.page = 1;
            this.limit = limit;
            this.getData({
                limit,
                page: this.page
            });
        },
        getData(obj) { //表格数据
            let params = Object.assign({
                brand_reward_subsidy_id: this.info.row.id
            }, obj);
            this.$api.post(this.url, params, res => {
                let data = res.data;
                if (res.code == 0) {
                    this.recordList = data.list;
                    this.total = data.count;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scope>
.DM_ladderLogInfo {
    .purseTitle {
        font-size: 12px;
        margin: 10px 0;
        color: #909399;
        :nth-child(1) {
            font-weight: 700;
            color: #7f7f7f;
            padding-left: 10px;
            border-left: 4px solid #909399;
        }
    }
    .rowTitle {
        color: #909399;
        font-size: 12px;
        .el-col {
            margin: 5px 0;
        }
        .labels {
            font-weight: 700;
        }
    }
}
</style>
