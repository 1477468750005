<template>
    <div class="FM_accountList">
        <!-- 平台账户 -->
        <el-card class="box-card">
            <el-row>
                <el-col :xs="24" :sm="24" :md="12" :lg="12">
                    <el-card class="cardChild" shadow="hover">
                        <div slot="header">
                            <span style="font-size:13px;font-weight:700">代付账户</span>
                        </div>
                        <div class="price">
                            <span>¥{{balance.payment_balance}}</span>
                        </div>
                        <div class="bottom">
                            <el-button
                                type="primary"
                                class="button"
                                size="medium"
                                :loading="refLoading"
                                @click="refBalance"
                            >刷新余额</el-button>
                            <el-button
                                type="primary"
                                class="button"
                                size="medium"
                                @click="openPaymentRecord"
                            >账户记录查询</el-button>
                        </div>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12">
                    <el-card class="cardChild" shadow="hover" style="height:230px">
                        <div class="rightTitle">
                            <span>可开具增值税发票及个人所得税发票</span>
                        </div>
                        <div class="rightTitle">
                            <span>用途：</span>
                            <span>用于发放平台代理商提现。</span>
                        </div>
                        <div class="rightTitle">
                            <span>充值方式：</span>
                            <span>对公</span>
                        </div>
                        <div class="rightTitle">
                            <span>注：</span>
                            <span>资金由“第三方代付平台”托管，为保证资金安全，请勿将过多资金放置平台。</span>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row>
                <el-col :xs="24" :sm="24" :md="12" :lg="12">
                    <el-card class="cardChild" shadow="hover">
                        <div slot="header">
                            <span style="font-size:13px;font-weight:700">平台账户</span>
                        </div>
                        <div class="price">
                            <span>¥{{balance.organization_balance}}</span>
                        </div>
                        <div class="bottom">
                            <el-button
                                type="primary"
                                class="button"
                                size="medium"
                                @click="addTopUp"
                            >充值</el-button>
                            <el-button
                                type="primary"
                                class="button"
                                size="medium"
                                @click="openplatformRecord"
                            >平台账户记录查询</el-button>
                        </div>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12">
                    <el-card class="cardChild" shadow="hover" style="height:230px">
                        <div class="rightTitle">
                            <span>用途：</span>
                            <span>短信验证码、实名认证、银行卡鉴权、数据等费用。</span>
                        </div>
                        <div class="rightTitle">
                            <span>充值方式：</span>
                            <span>对私</span>
                        </div>
                        <div class="rightTitle">
                            <span>注：</span>
                            <span>为保障服务正常，账户余额剩余2000元时会收到预警短信，每天发送1次，共发送3次。</span>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-card>
        <!-- 充值 -->
        <top-up v-if="topUpIsShow" :topUpIsShow="topUpIsShow" @topUpClose="topUpClose"></top-up>
        <!-- 代付账户记录 -->
        <payment-record
            v-if="paymentRecordShow"
            :paymentRecordShow="paymentRecordShow"
            @paymentClose="paymentClose"
        ></payment-record>
        <!-- 平台账户记录查询 -->
        <platform-record
            v-if="platformRecordShow"
            :platformRecordShow="platformRecordShow"
            @platformClose="platformClose"
        ></platform-record>
    </div>
</template>

<script>
import topUp from './FM_topUp';
import paymentRecord from './FM_paymentRecord';
import platformRecord from './FM_platformRecord';
export default {
    name: "FM_accountList",
    data() {
        return {
            paymentRecordShow: false, //代付记录
            platformRecordShow: false, //平台账户记录
            topUpIsShow: false, //充值
            balance: {},//账户余额
            refLoading: false, //刷新余额加载
        }
    },
    components: {
        topUp,
        paymentRecord,
        platformRecord
    },
    mounted() {
        this.getBalance();
    },
    methods: {
        refBalance() { //刷新余额
            this.refLoading = true;
            this.getBalance();
        },
        addTopUp() {
            this.topUpIsShow = true;
        },
        topUpClose(ref) { //关闭充值弹窗
            this.topUpIsShow = false;
            if (ref) {
                this.getBalance();//刷新余额
            }
        },
        openPaymentRecord() { //查看代付记录
            this.paymentRecordShow = true;
        },
        paymentClose() { //关闭代付记录弹窗
            this.paymentRecordShow = false;
        },
        openplatformRecord() { //查看平台账户记录
            this.platformRecordShow = true;
        },
        platformClose() { //关闭平台账户记录查询
            this.platformRecordShow = false;
        },
        getBalance() { //获取余额
            this.$api.post('organization/getBalance', {}, res => {
                if (res.code == 0) {
                    this.balance = res.data;
                    let timer = null;
                    timer = setInterval(() => { //防止刷新时多次点击
                        this.refLoading = false;
                        clearInterval(timer);
                    }, 1000);
                } else {
                    this.$message.error(res.msg);
                    this.refLoading = false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scope>
.FM_accountList {
    font-size: 12px;
    .price {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .el-col {
        margin: 10px 0;
    }
    .rightTitle {
        margin: 20px;
        :nth-child(1) {
            font-weight: 700;
        }
    }
    .cardChild {
        min-width: 350px;
        max-width: 450px;
    }
    .bottom {
        .el-button {
            font-size: 12px;
        }
        display: flex;
        justify-content: flex-end;
    }
    // .el-dialog {
    //     display: flex;
    //     flex-direction: column;
    //     margin: 0 !important;
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     /*height:600px;*/
    //     max-height: calc(100% - 30px);
    //     max-width: calc(100% - 30px);
    // }
    // .el-dialog .el-dialog__body {
    //     flex: 1;
    //     overflow: auto;
    // }
}
</style>
