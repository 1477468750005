<template>
    <div class="UM_emailList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input clearable v-model="searchForm.phone" placeholder="请输入代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.real_name" placeholder="请输入代理商真实姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.email" placeholder="请输入邮箱账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.email_type" clearable placeholder="请选择邮箱类型">
                        <el-option label="普通邮箱" value="0"></el-option>
                        <el-option label="默认邮箱" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-table
                :data="emailList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 真实姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="真实姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 邮箱账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="email"
                    label="邮箱账号"
                    show-overflow-tooltip
                    min-width="120"
                ></el-table-column>
                <!-- 邮箱类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="is_default"
                    label="邮箱类型"
                    min-width="120"
                >
                    <template slot-scope="scope">{{scope.row.is_default == 0? '普通邮箱' : '默认邮箱'}}</template>
                </el-table-column>
                <!-- 绑定时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="绑定时间"
                    min-width="120"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "UM_emailList",
    data() {
        return {
            emailList: [], //table
            searchForm: {
                page: 1,
                limit: 10,
                phone: '', //代理商账号
                real_name: '', //代理商姓名
                email: '', //邮箱账号
                email_type: '', //邮箱类型

            },
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        })
    },
    methods: {
        onSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("user/user_email", params, res => {
                let data = res.data;
                this.total = data.count; //总数
                this.emailList = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scope>
.UM_emailList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
