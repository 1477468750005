<template>
    <div class="DM_cashBackInfo">
        <!-- 倒挂订单审核 -->
        <el-dialog
            title="审核"
            :visible="detail.isShow"
            @close="handleClose"
            :close-on-click-modal="false"
        >
            <el-card class="box-card" shadow="hover">
                <el-row class="rowTitle">
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">终端类型：</span>
                        <span>{{detail.info.brand_name}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">终端SN：</span>
                        <span>{{detail.info.terminal_sn}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">归属代理商名称：</span>
                        <span>{{detail.info.name}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">归属代理商账号：</span>
                        <span>{{detail.info.phone}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">模版：</span>
                        <span>{{detail.info.rule}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">奖励金额：</span>
                        <span>{{detail.info.reward_money}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">奖励时间：</span>
                        <span>{{detail.info.award_time}}</span>
                    </el-col>
                </el-row>
                <el-table
                    :data="infoTable"
                    size="mini"
                    height="200"
                    border
                    style="width: 100%;margin-top:20px"
                    v-loading="loading"
                >
                    <!-- 序号 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        fixed
                        prop="id"
                        label="序号"
                        min-width="80"
                    ></el-table-column>
                    <!-- 代理名称 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="name"
                        label="代理名称"
                        min-width="120"
                    ></el-table-column>
                    <!-- 层级 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="level"
                        label="层级"
                        min-width="120"
                    ></el-table-column>
                    <!-- 政策 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="reward_money"
                        label="政策"
                        min-width="150"
                    ></el-table-column>
                    <!-- 奖励分润 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="profit_money"
                        label="奖励分润"
                        min-width="120"
                    ></el-table-column>
                </el-table>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "DM_cashBackInfo",
    props: {
        detail: {
            type: Object
        }
    },
    data() {
        return {
            infoTable: [], //表格
            viewData: {},
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        handleClose() {
            this.$emit('infoClose')
        },
        getData() { //初始化
            this.$api.post("terminal_cash_back/cash_back_detail", { id: this.detail.info.id }, res => {
                if (res.code == 0) {
                    this.infoTable = res.data;
                    //渲染结束后取消加载
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                    //渲染结束后取消加载
                    this.loading = false;
                }
            })
        }
    }
};
</script>

<style lang="scss" scope>
.DM_cashBackInfo {
    .purseTitle {
        font-size: 12px;
        margin: 10px 0;
        color: #909399;
        :nth-child(1) {
            font-weight: 700;
            color: #7f7f7f;
            padding-left: 10px;
            border-left: 4px solid #909399;
        }
    }
    .rowTitle {
        color: #909399;
        font-size: 12px;
        .el-col {
            margin: 5px 0;
        }
        .labels {
            font-weight: 700;
        }
    }
}
</style>
