<template>
    <div class>
        <el-dialog
            ref="setForm"
            :visible="info.isShow"
            @close="handleClose"
            :close-on-click-modal="false"
            width="570px"
            title="配置提现手续费"
        >
            <el-card class="box-card" shadow="hover">
                <el-form :model="setForm" label-position="top" label-width="100px">
                    <template v-if="setForm.collection_tax_point_switch == 1">
                        <div class="costTitle">代收税点</div>
                        <el-form-item :label="setForm.collection_tax_point_title">
                            <el-input
                                clearable
                                :placeholder="setForm.collection_tax_point_min +'~'+setForm.collection_tax_point_max"
                                size="medium"
                                v-model="setForm.collection_tax_point"
                            ></el-input>
                        </el-form-item>
                    </template>
                    <template v-if="setForm.withdrawal_service_fee_switch == 1">
                        <div class="costTitle">出款服务费</div>
                        <el-form-item :label="setForm.withdrawal_service_fee_title">
                            <el-input
                                clearable
                                :placeholder="setForm.withdrawal_service_fee_min +'~'+setForm.withdrawal_service_fee_max"
                                size="medium"
                                v-model="setForm.withdrawal_service_fee"
                            ></el-input>
                        </el-form-item>
                    </template>
                    <el-form-item>
                        <el-button
                            @click="handleSubmit(setForm)"
                            type="primary"
                            size="small"
                            :loading="loading"
                        >保存配置</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            codeButton: false,
            setForm: {

            },

        }
    },
    created() {
        this.getCost();
    },
    mounted() {
    },
    methods: {
        handleSubmit(setForm) { //提交
            let _this = this;
            this.loading = true;
            setForm.user_id = this.info.row.user_id;
            this.$api.post('user/save_first_level_user_withdrawal_fee', setForm, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000,
                        onClose() {
                            _this.handleClose(1);
                            _this.loading = false;
                        }
                    })
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            });
        },
        getCost() { // 获取提现手续费
            let user_id = this.info.row.user_id;
            this.$api.post('user/set_first_level_user_withdrawal_fee', { user_id }, res => {
                if (res.code == 0) {
                    this.setForm = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        handleClose(ref) {
            this.$emit('infoClose', ref)
        }
    }
}
</script>

<style scoped lang="scss">
.costTitle {
    color: #000000;
    font-size: 13px;
    font-weight: 700;
    padding-left: 5px;
    margin: 15px 0;
    border-left: 4px solid #409eff;
}
</style>
