<template>
    <div class="activityCenterInfo">
        <el-dialog
            title="活动详情"
            :visible="info.isShow"
            @close="handleClose"
            :close-on-click-modal="false"
            width="90%"
        >
            <el-card class="box-card" shadow="always" v-loading="loading">
                <div v-for="(item,index) in centerInfoList" :key="index">
                    <div class="record-view rm-label">{{item.name}}</div>

                    <el-table
                        :data="item.list"
                        size="mini"
                        border
                        max-height="300px"
                        style="width: 100%"
                    >
                        <template v-for="(v,i) in item.title">
                            <el-table-column
                                :key="i"
                                header-align="center"
                                align="center"
                                :prop="v.key"
                                :label="v.name"
                                min-width="150"
                            ></el-table-column>
                        </template>
                    </el-table>
                    <div class="view-time">
                        <span class="rm-label">活动截止日期：</span>
                        <span>{{item.cut_off_time}}</span>
                    </div>
                    <el-divider></el-divider>
                </div>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "activityCenterInfo",
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            centerInfoList: [],
            loading: false,
        }
    },
    mounted() {
        let row = this.info.row;
        this.getData({
            id: row.id
        });
    },
    methods: {
        handleClose(ref) { //关闭详情
            this.$emit('infoClose', ref)
        },
        getData(params) { //获取详情数据
            this.loading = true;
            this.$api.post("activity_merchant_status/get_activity_merchant_status_detail", params, res => {
                if (res.code == 0) {
                    this.centerInfoList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.record-view {
    padding: 8px 16px;
    font-size: 12px;
    background-color: #f2f6fc;
    border-radius: 4px;
    border-left: 5px solid #dcdfe6;
    margin: 20px 0;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.view-time {
    padding: 0px 16px;
    font-size: 12px;
    margin: 24px 0 0 0;
}
</style>
