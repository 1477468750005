<template>
    <div class="transferContainer">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <el-card class="box-card" shadow="hover">
            <div class="tansferBox">
                <el-form :model="transferForm" label-position="top" label-width="80px">
                    <!-- 划拨对象 -->
                    <el-form-item label="划拨对象：">
                        <el-select
                            size="medium"
                            v-model="transferForm.receive_user_id"
                            filterable
                            clearable
                            style="width:500px"
                            placeholder="请选择划拨对象"
                        >
                            <el-option
                                v-for="(item,index) in transferUser"
                                :key="index"
                                :label="item.real_name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 终端类型 -->
                    <el-form-item label="终端类型：">
                        <el-select
                            size="medium"
                            @change="getTerminal(transferForm.brand_id)"
                            v-model="transferForm.brand_id"
                            filterable
                            clearable
                            style="width:500px"
                            placeholder="请选择终端类型"
                        >
                            <el-option
                                v-for="(item,index) in brandList"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 划拨方式 -->
                    <el-form-item label="划拨方式：">
                        <!-- <el-radio  @change="handleTransferMethod" v-model="transferMethod" :label="1">导表选择</el-radio> -->
                        <el-radio
                            @change="handleTransferMethod"
                            v-model="transferMethod"
                            :label="2"
                        >列表选择</el-radio>
                        <el-radio
                            @change="handleTransferMethod"
                            v-model="transferMethod"
                            :label="3"
                        >批量选择</el-radio>
                    </el-form-item>
                    <!-- 选择终端->批量选择 -->
                    <el-form-item style="width:500px" v-if="transferMethod == 3">
                        <el-form-item label="起始SN：">
                            <el-input
                                size="medium"
                                v-model="batchForm.start_sn"
                                placeholder="请输入起始SN"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="结束SN：">
                            <el-input
                                size="medium"
                                v-model="batchForm.end_sn"
                                @input="handleBatchChange"
                                :disabled="batchForm.transfer_num != ''"
                                placeholder="请输入结束SN"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="终端数量：">
                            <el-input
                                size="medium"
                                @keyup.native="handleInt($event)"
                                v-model="batchForm.transfer_num"
                                @input="handleBatchChange"
                                :disabled="batchForm.end_sn != ''"
                                placeholder="请输入终端数量"
                            ></el-input>
                        </el-form-item>
                        <div class="batchTip">
                            <div style="font-size:12px;font-weight:700;color:#EC808D">
                                <span>搜索结果：{{searchCount}}台&nbsp;</span>
                                <span>
                                    <el-button @click="handleCheck" type="text" size="small">查看</el-button>
                                </span>
                            </div>
                            <div>
                                <el-button
                                    @click="handleVerify(batchForm)"
                                    type="text"
                                    size="small"
                                >验证</el-button>
                            </div>
                        </div>
                    </el-form-item>
                    <!-- 选择终端->列表选择 -->
                    <el-form-item label="选择终端：" v-if="transferMethod == 2">
                        <el-form-item>
                            <el-row style="margin-bottom:10px;">
                                <el-col :span="12">
                                    <el-input
                                        size="medium"
                                        clearable
                                        v-model="search"
                                        placeholder="搜索终端"
                                    ></el-input>
                                </el-col>
                                <el-col :span="10" :offset="1">
                                    <el-button
                                        size="medium"
                                        type="primary"
                                        icon="el-icon-search"
                                        style="font-size: 12px;"
                                        @click="searchTerminal"
                                    >搜 索</el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-transfer
                            v-loading="loading"
                            element-loading-text="拼命加载中"
                            :titles="titles"
                            filter-placeholder="搜索SN码"
                            v-model="transferForm.terminal_id"
                            :data="terminalList"
                            @change="getAllMoney(transferForm.terminal_id)"
                            :props="{key:'id',label:'sn'}"
                        ></el-transfer>
                    </el-form-item>
                    <div class="title">
                        <span>终端单价(元)：</span>
                        <span>{{brandPrice}}&nbsp;元</span>
                    </div>
                    <div class="title">
                        <span>应收金额(元)：</span>
                        <span>{{transferForm.all_money.toFixed(2)}}&nbsp;元</span>
                    </div>
                    <el-form-item label="支付方式：">
                        <el-radio v-model="transferForm.pay_type" :label="2">全款</el-radio>
                        <el-radio v-model="transferForm.pay_type" :label="1">贷款</el-radio>
                    </el-form-item>
                    <div class="isLoan" v-if="transferForm.pay_type == 1">
                        <el-form-item label="终端首付比例：">
                            <el-input
                                size="medium"
                                maxlength="2"
                                @keyup.native="handleInt($event)"
                                v-model="loanForm.down_proportion"
                                placeholder="请输入比例0-99%之间"
                            ></el-input>
                        </el-form-item>
                        <div class="title">
                            <span>首付金额(元)：</span>
                            <span>{{loanForm.down_money}}&nbsp;元</span>
                        </div>
                        <template v-if="repayment_type == 2">
                            <el-form-item label="货款期数：">
                                <el-select
                                    size="medium"
                                    v-model="loanForm.loan_period"
                                    filterable
                                    clearable
                                    style="width:500px"
                                    placeholder="请选择货款期数"
                                    @change="handlePeriod()"
                                >
                                    <el-option
                                        v-for="item in 12"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <div class="title">
                                <span>每期约还：</span>
                                <span>{{loanForm.every_period_money}}&nbsp;元</span>
                            </div>
                        </template>
                        <el-form-item label="是否延期入账：">
                            <el-radio v-model="loanForm.is_postponed" :label="1">是</el-radio>
                            <el-radio v-model="loanForm.is_postponed" :label="0">否</el-radio>
                        </el-form-item>
                        <div class="isPostponed" v-show="loanForm.is_postponed == 1">
                            <el-form-item label="入账日期：">
                                <el-date-picker
                                    v-model="loanForm.date"
                                    type="date"
                                    placeholder="按所选日期进行入账，下级开始还款"
                                    value-format="yyyy-MM-dd"
                                ></el-date-picker>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item>
                        <el-button
                            type="primary"
                            style="font-size: 12px;margin-top:10px"
                            :disabled="preventRepeat"
                            @click="handleSubmit"
                        >提 交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <search-result v-if="info.isShow" :info="info" @infoClose="infoClose"></search-result>
    </div>
</template>

<script>
import searchResult from './MAM_searchResult';
export default {
    name: "MAM_transferTerminal",
    data() {
        return {
            repayment_type: '',
            info: {
                isShow: false,
                detail: []
            },
            transferMethod: 2,
            terminalList: [], //穿梭框数据源
            transferUser: [], //划拨代理商
            brandList: [], //终端类型下拉框
            brandPrice: 0, //终端价格
            titles: ['库存', '已选'],
            transferForm: {
                receive_user_id: '',  //被划拨人
                brand_id: '', //终端类型id
                terminal_id: [], //被选中的sn
                all_money: 0, //应收金额
                pay_type: 2, //支付类型 1贷款 2全款
                terminal_num: '', //终端数量
            },
            loanForm: { //选择贷款时被合并的form
                down_proportion: '', //首付比例
                down_money: 0, //首付金额
                is_postponed: 1, //是否延期入账 1是 0否
                date: '', //延期入账日期 年-月-日形式
                loan_period: 1,//货款期数 如果为分期时传此参数
                every_period_money: 0,//每期约应还 如果为分期时传此参数
            },
            batchForm: { //批量选择的表单
                id: null, //终端类型id
                type: 1, //1.起始SN+结束SN 2.起始sn+数量
                start_sn: '',
                end_sn: '',
                transfer_num: '' //终端数量
            },
            searchCount: 0,
            search: '', //搜索
            loading: false,
            preventRepeat: false
        }
    },
    components: {
        searchResult
    },
    mounted() {
        this.repayment_type = sessionStorage.getItem('repayment_type'); //是否为分期代理商
        this.$api.post('terminal_transfer/get_transfer_user', {}, res => { //划拨代理商
            if (res.code == 0) {
                this.transferUser = res.data;
            } else {
                this.$message.error(res.msg);
            }
        });
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$message.error(res.msg);
            }
        });
    },
    methods: {
        handleSubmit() { //提交
            this.preventRepeat = true; //禁用按钮
            let fullPayment = this._.cloneDeep(this.transferForm); //全款的参数
            fullPayment.terminal_id = fullPayment.terminal_id.join(',');

            let loan = this._.assign(fullPayment, this.loanForm); //贷款的参数
            if (this.repayment_type != 2) {
                loan = this._.omit(loan, ['loan_period', 'every_period_money'])
            }
            if (loan.is_postponed == 0) { //是否延期入账
                delete loan.date;
            }
            if (fullPayment.pay_type != 1) {
                this.sendData(fullPayment);
            } else {
                this.sendData(loan);
            }
        },
        handleCheck() { //查看
            this.info.isShow = true;
        },
        infoClose() { //关闭弹窗
            this.info.isShow = false;
        },
        handleVerify(batchForm) { //验证
            if (batchForm.start_sn != '' && batchForm.end_sn != '') {
                batchForm.type = 1;
            }
            if (batchForm.start_sn != '' && batchForm.transfer_num != '') {
                batchForm.type = 2;
            }
            if (batchForm.id) { //是否选择终端类型
                this.$api.post('terminal/batch_search', batchForm, res => {
                    if (res.code == 0) {
                        this.searchCount = res.data.count; //搜索结果
                        this.transferForm.all_money = Number(this.brandPrice) * res.data.count; //应收金额
                        this.transferForm.terminal_num = res.data.count; //终端数量
                        this.info.detail = res.data.list; //给到搜索结果
                        this._.forEach(res.data.list, (item) => {//获取的终端
                            this.transferForm.terminal_id.push(item.id);
                        })
                        this.loanForm.down_money = ((Number(this.loanForm.down_proportion) / 100) * Number(this.transferForm.all_money)).toFixed(2);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            } else {
                this.$message.error('请选择终端类型');
            }
        },
        handleTransferMethod() { //划拨方式
            this.resetSeleted(); //重置
        },
        searchTerminal() {   //搜索终端
            let searchTerminals = {
                brand_id: this.transferForm.brand_id, //终端类型id
                select_terminal_id: this.transferForm.terminal_id,
                condition: this.search, //搜索条件
            };
            this.$api.post('terminal_transfer/get_transfer_terminal', searchTerminals, res => {
                if (res.code == 0) {
                    this.terminalList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getTerminal(brandId) { //获取终端
            this.batchForm.id = brandId; //设置批量终端类型id
            this.resetSeleted(); //重置
            //获取终端价格
            this._.forEach(this.brandList, item => {
                if (brandId == item.id) {
                    this.brandPrice = item.price;
                }
            });
            //开启加载 
            this.loading = true;
            this.$api.post('terminal_transfer/get_transfer_terminal', { brand_id: brandId }, res => { //划拨代理商
                if (res.code == 0) {
                    this.terminalList = res.data;
                    //关闭加载 
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                    //关闭加载 
                    this.loading = false;
                }
            });
        },
        getAllMoney(val) {
            this.transferForm.all_money = Number(this.brandPrice) * val.length; //应收金额
            this.transferForm.terminal_num = val.length; //终端数量
            this.loanForm.down_money = ((Number(this.loanForm.down_proportion) / 100) * Number(this.transferForm.all_money)).toFixed(2);
        },
        sendData(data) { //请求
            let that = this;
            this.$api.post('terminal_transfer/terminal_transfer', data, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.$router.back();
                        }
                    });
                } else {
                    this.$message({
                        showClose: true,
                        type: 'error',
                        message: res.msg,
                        duration: 5000
                    });
                    this.preventRepeat = false; //解禁
                }
            });
        },
        handleBatchChange() { //监听结束SN、终端数量
            // this.resetSeleted(); //重置
        },
        resetSeleted() { //重置选择
            this.searchCount = 0; //搜索结果
            this.info.detail = []; //重置查看 
            this.transferForm.all_money = 0; //重置应收金额
            this.transferForm.terminal_id = []; //重置已选终端
        },
        handleInt(e) { //替换不为数字的值
            e.target.value = e.target.value.replace(/\D/g, '');
            let isInt = /^[0-9]([0-9])*$/; //判断是否为正整数
            if (!isInt.test(e.target.value)) {
                e.target.value = '';
            }
        },
        handlePeriod() { //期数改变时
            this.computeAbout();
        },
        computeAbout() { //计算约还
            let allMoney = Number(this.transferForm.all_money); //应收
            let downMoney = Number(this.loanForm.down_money); //首付
            let loanPeriod = Number(this.loanForm.loan_period); //期数
            let getMOD = ((allMoney - downMoney) * 100) % loanPeriod; //得到余数
            this.loanForm.every_period_money = ((allMoney - downMoney) * 100 - getMOD) / loanPeriod / 100;
        }
    },
    watch: {
        'loanForm.down_proportion'(newVal) { //首付金额
            if (Number(newVal) || newVal == 0) {
                this.loanForm.down_money = ((Number(newVal) / 100) * Number(this.transferForm.all_money)).toFixed(2);
            }
            this.computeAbout();
        },
        'transferForm.all_money'() { //应收金额
            this.computeAbout();
        }
    }
}
</script>

<style scoped lang="scss">
.transferContainer {
    .tansferBox {
        min-width: 920px;
    }
    font-size: 12px !important;
    .title {
        margin: 20px 0 10px 0;
        color: #606266;
        :nth-child(1) {
            font-weight: 700;
        }
    }
    .el-input__inner,
    ::v-deep .el-radio__label {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
        ::v-deep .el-form-item__label {
            padding: 0;
            font-weight: 700;
            font-size: 12px;
        }
        ::v-deep .el-form-item__content {
            line-height: 0;
        }
    }
    ::v-deep .el-checkbox__label {
        font-size: 12px;
    }
    ::v-deep .el-transfer {
        font-size: 12px;
        .el-transfer-panel__header {
            .el-checkbox__label {
                font-size: 13px;
                font-weight: 700;
            }
        }
        .el-transfer-panel {
            width: 40%;
        }
    }
    .batchTip {
        line-height: 15px;
        display: flex;
        justify-content: space-between;
    }
}
</style>
