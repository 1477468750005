let sideBarList = [
    {
        path: '',
        name: '',
        meta: {
            title: ''
        },
        children: [
            {
                path: '/home',
                name: 'home',
                meta: {
                    title: '系统首页'
                },
                component: 'home'
            },
        ],
        icon: 'el-icon-s-home',
        component: 'layout',
    },
    {
        path: '/',
        name: 'basicSetting',
        meta: {
            title: '基础设置'
        },
        icon: 'el-icon-s-tools',
        component: 'layout',
        children: [
            {
                path: '/setPlatform',
                name: 'setPlatform',
                meta: {
                    title: '平台设置'
                },
                component: 'platformSetting'
            },
            {
                path: '/setParam',
                name: 'setParam',
                meta: {
                    title: '参数设置'
                },
                component: 'paramSetting'
            },
            {
                path: '/setAgreements',
                name: 'setAgreements',
                meta: {
                    title: '协议设置'
                },
                component: 'agreementSetting'
            },
            {
                path: '/aboutUs',
                name: 'aboutUs',
                meta: {
                    title: '关于我们'
                },
                component: 'aboutUs'
            },
        ]
    },
    {
        path: '/',
        name: 'authority',
        meta: {
            title: '权限管理'
        },
        icon: 'el-icon-lock',
        component: 'layout',
        children: [
            {
                path: '/divisional',
                name: 'divisional',
                meta: {
                    title: '部门管理'
                },
                component: 'divisionalManagement'
            },
            {
                path: '/role',
                name: 'role',
                meta: {
                    title: '角色管理'
                },
                component: 'roleManagement'
            },
            {
                path: '/operator',
                name: 'operator',
                meta: {
                    title: '操作员管理'
                },
                component: 'operatorManagement'
            }
        ]
    },
    {
        path: '/',
        name: 'userManagement',
        meta: {
            title: '代理商管理'
        },
        icon: 'el-icon-user-solid',
        component: 'layout',
        children: [
            {
                path: '/userList',
                name: 'userList',
                meta: {
                    title: '代理商列表'
                },
                component: 'userList'
            },
            {
                path: '/loan',
                name: 'loan',
                meta: {
                    title: '贷款管理'
                },
                component: 'loanManagement'
            },
            {
                path: '/repayment',
                name: 'repayment',
                meta: {
                    title: '还款比例管理'
                },
                component: 'repaymentManagement'
            },
            {
                path: '/realName',
                name: 'realName',
                meta: {
                    title: '实名认证'
                },
                component: 'realNameAuthentication'
            },
            {
                path: '/blockedAccount',
                name: 'blockedAccount',
                meta: {
                    title: '冻结账户'
                },
                component: 'blockedAccount'
            },
            {
                path: '/accountChange',
                name: 'accountChange',
                meta: {
                    title: '账户变更记录'
                },
                component: 'accountChangeRecord'
            },
            {
                path: '/placeOfReceipt',
                name: 'placeOfReceipt',
                meta: {
                    title: '收货地址'
                },
                component: 'placeOfReceipt'
            },
            {
                path: '/emailAddress',
                name: 'emailAddress',
                meta: {
                    title: '邮箱地址'
                },
                component: 'emailAddress'
            },
            {
                path: '/bankCard',
                name: 'bankCard',
                meta: {
                    title: '银行卡'
                },
                component: 'bankCard'
            },
            {
                path: '/messageCode',
                name: 'messageCode',
                meta: {
                    title: '短信验证码'
                },
                component: 'smsVerificationCode'
            }
        ]
    },
    {
        path: '/',
        name: 'machineManagement',
        meta: {
            title: '机具管理'
        },
        icon: 'el-icon-s-platform',
        component: 'layout',
        children: [
            {
                path: '/brand',
                name: 'brand',
                meta: {
                    title: '终端类型管理'
                },
                component: 'brandManagement'
            },
            {
                path: '/terminalMerchant',
                name: 'terminalMerchant',
                meta: {
                    title: '终端&商户'
                },
                component: 'terminalMerchant'
            },
            {
                path: '/stockManagement',
                name: 'stockManagement',
                meta: {
                    title: '库存管理'
                },
                component: 'stockManagement'
            },
            {
                path: '/stockInOut',
                name: 'stockInOut',
                meta: {
                    title: '出入库记录查询'
                },
                component: 'stockInOutQuery'
            },
            {
                path: '/transfer',
                name: 'transfer',
                meta: {
                    title: '划拨管理'
                },
                component: 'transferManagement'
            },
            {
                path: '/rateChange',
                name: 'rateChange',
                meta: {
                    title: '费率修改记录'
                },
                component: 'rateChange'
            }
        ]
    },
    {
        path: '/',
        name: 'dataManagement',
        meta: {
            title: '数据管理'
        },
        icon: 'el-icon-s-data',
        component: 'layout',
        children: [
            {
                path: '/activationRecord',
                name: 'activationRecord',
                meta: {
                    title: '激活记录查询'
                },
                component: 'activationRecord'
            },
            {
                path: '/awarding',
                name: 'awarding',
                meta: {
                    title: '奖励发放查询'
                },
                component: 'awarding'
            },
            {
                path: '/transactionDetail',
                name: 'transactionDetail',
                meta: {
                    title: '交易明细'
                },
                component: 'transactionDetail'
            },
            {
                path: '/terminalStatistics',
                name: 'terminalStatistics',
                meta: {
                    title: '终端统计'
                },
                component: 'terminalStatistics'
            },
            {
                path: '/upsideOrder',
                name: 'upsideOrder',
                meta: {
                    title: '倒挂订单审核'
                },
                component: "upsideOrder"
            }
        ]
    },
    {
        path: '/',
        name: 'financialManagement',
        meta: {
            title: '财务管理'
        },
        icon: 'el-icon-money',
        component: 'layout',
        children: [
            {
                path: '/userWithdrawal',
                name: 'userWithdrawal',
                meta: {
                    title: '代理商提现'
                },
                component: 'userWithdrawal'
            },
            {
                path: '/userPurse',
                name: 'userPurse',
                meta: {
                    title: '代理商钱包'
                },
                component: 'userPurse'
            },
            {
                path: '/userFinancial',
                name: 'userFinancial',
                meta: {
                    title: '代理商财务记录'
                },
                component: 'userFinancial'
            },
            {
                path: '/userAlipayRecord',
                name: 'userAlipayRecord',
                meta: {
                    title: '代理商支付宝支付记录'
                },
                component: 'userAlipayRecord'
            },
            {
                path: '/platformAccount',
                name: 'platformAccount',
                meta: {
                    title: '平台账户'
                },
                component: 'platformAccount'
            },
        ]
    },
    {
        path: '/',
        name: 'appManagement',
        meta: {
            title: 'APP管理'
        },
        icon: 'el-icon-mobile-phone',
        component: 'layout',
        children: [
            {
                path: '/banner',
                name: 'banner',
                meta: {
                    title: 'banner图管理'
                },
                component: 'banner'
            },
            {
                path: '/recruit',
                name: 'recruit',
                meta: {
                    title: '招募背景管理'
                },
                component: 'recruit'
            },
            {
                path: '/feedback',
                name: 'feedback',
                meta: {
                    title: '意见反馈'
                },
                component: 'feedback'
            }
        ]
    },
    {
        path: '/',
        name: 'businessCollege',
        meta: {
            title: '商学院'
        },
        icon: 'el-icon-school',
        component: 'layout',
        children: [
            {
                path: '/articleCategory',
                name: 'articleCategory',
                meta: {
                    title: '文章分类'
                },
                component: 'articleCategory'
            },
            {
                path: '/articleList',
                name: 'articleList',
                meta: {
                    title: '文章列表'
                },
                component: 'articleList'
            }
        ]
    }
]

export default sideBarList;