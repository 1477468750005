<template>
    <div class="UM_changeList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="changeForm" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="changeForm.name" placeholder="请输入代理商真实姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="changeForm.old_phone" placeholder="请输入变更前账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="changeForm.new_phone" placeholder="请输入变更后账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="代理商变更开始日期"
                        end-placeholder="代理商变更结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(changeForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- 占位 -->
            <!-- <div style="margin-bottom: 20px;"> -->
            <!-- <el-button size="small" type="primary" icon="el-icon-download" @click>数据导出</el-button> -->
            <!-- </div> -->
            <el-table
                :data="changeTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="user_id"
                    label="序号"
                    min-width="100"
                ></el-table-column>
                <!-- 代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 真实姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="真实姓名"
                    min-width="200"
                ></el-table-column>
                <!-- 邀请码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="code"
                    label="邀请码"
                    min-width="120"
                ></el-table-column>
                <!-- 昵称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="nickname"
                    label="昵称"
                    min-width="150"
                ></el-table-column>
                <!-- 变更前账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="old_phone"
                    label="变更前账号"
                    min-width="150"
                ></el-table-column>
                <!-- 变更后账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="new_phone"
                    label="变更后账号"
                    min-width="150"
                ></el-table-column>
                <!-- 变更时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="变更时间"
                    min-width="150"
                ></el-table-column>
                <!-- 设备型号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="device_model"
                    label="设备型号"
                    min-width="150"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
export default {
    name: "UM_changeList",
    data() {
        return {
            rangeDate: '',
            changeForm: {
                name: '', //真实姓名
                old_phone: '', //更改前
                new_phone: '', //更改后
                start_time: '', //更改开始时间
                end_time: '', //更改结束时间
                page: 1,
                limit: 10,
            },
            changeTable: [],
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true
        }
    },
    mounted() {
        this.getData({
            page: this.page.currentPage,
            limit: this.page.pageSize
        });
    },
    components: {
        paginations
    },
    methods: {
        onSearch(changeForm) {
            // 表单提交
            this.getData(changeForm);
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.cardForm.page = this.page.currentPage;
            this.cardForm.limit = this.page.pageSize;
            this.getData(this.cardForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.cardForm.page = this.page.currentPage;
            this.cardForm.limit = limit;
            this.getData(this.cardForm);
        },
        getData(params) {
            this.$api.post("user/getPhoneChangeLog", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.changeTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        pickerChange(DateArr) { //时间选择器
            this.changeForm.start_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.changeForm.end_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
};
</script>

<style lang="scss" scope>
.UM_changeList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
