<template>
    <div class="MAM_returnDeposit">
        <el-dialog
            :title="`配置“${returnDeposit.info.brand_name}”的服务费返现模版`"
            width="800px"
            :visible="returnDeposit.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <!-- 活动说明 -->
            <!-- <el-tooltip class="item" effect="dark" placement="right-start"> -->
            <el-button type="text" style="color:#606266;font-weight:700">服务费返现模版配置</el-button>
            <!-- </el-tooltip> -->
            <!-- 表单部分 -->
            <el-form label-position="top" label-width="80px" :model="depositForm">
                <el-form-item>
                    <el-table :data="setting" border max-height="456px">
                        <el-table-column
                            header-align="center"
                            align="center"
                            min-width="100"
                            prop="is_default"
                            label="默认值"
                        >
                            <template slot-scope="scope">
                                <el-checkbox
                                    v-model="scope.row.is_default"
                                    :true-label="1"
                                    :false-label="0"
                                    :disabled="scope.row.status == 0 || returnDeposit.info.status == 2"
                                    @change="handleDefault(scope.$index)"
                                ></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column
                            header-align="center"
                            align="center"
                            width="120"
                            type="index"
                            label="模板ID"
                        ></el-table-column>
                        <el-table-column
                            header-align="center"
                            align="center"
                            min-width="120"
                            prop="target_money"
                            label="服务费"
                        >
                            <template slot-scope="scope">
                                <el-input disabled v-model="scope.row.target_money"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            header-align="center"
                            align="center"
                            min-width="120"
                            prop="reward_money"
                            label="返现"
                        >
                            <template slot-scope="scope">
                                <el-input
                                    :disabled="scope.row.brand_reward_conf_id != '' && returnDeposit.type == 2"
                                    v-model="scope.row.reward_money"
                                ></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            header-align="center"
                            align="center"
                            min-width="150"
                            prop="status"
                            label="状态"
                        >
                            <template slot-scope="scope">
                                <el-switch
                                    v-model="scope.row.status"
                                    active-text="展示"
                                    :active-value="1"
                                    inactive-text="隐藏"
                                    :inactive-value="0"
                                    :disabled="returnDeposit.info.status == 2"
                                    @change="handleSwitch(scope.row,scope.$index)"
                                ></el-switch>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column
                            header-align="center"
                            align="center"
                            min-width="120"
                            label="操作"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    v-if="scope.$index != 0"
                                    :disabled="scope.row.brand_reward_conf_id != '' && returnDeposit.type == 2"
                                    @click="handleDel(scope.row,scope.$index)"
                                    type="text"
                                    size="small"
                                >删除</el-button>
                            </template>
                        </el-table-column>-->
                    </el-table>
                </el-form-item>
                <!-- <el-form-item v-if="setting.length < 30">
                    <el-button
                        type="text"
                        icon="el-icon-plus"
                        @click="handleAdd"
                        :disabled="returnDeposit.info.status == 2"
                    >增加模板</el-button>
                </el-form-item>-->
                <el-form-item label="是否支持层层返现：">
                    <el-radio v-model="depositForm.is_layer" :label="1">是</el-radio>
                    <el-radio v-model="depositForm.is_layer" :label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="活动截止时间：">
                    <el-date-picker
                        size="medium"
                        v-model="depositForm.cut_off_time"
                        type="date"
                        placeholder="活动截止时间"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        :disabled="returnDeposit.info.status == 2"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="APP提示语设置">
                    <el-input
                        v-model="depositForm.tips"
                        size="medium"
                        :disabled="returnDeposit.info.status != 0"
                    ></el-input>
                </el-form-item>
                <el-form-item style="margin-top:20px;">
                    <el-button
                        type="primary"
                        @click="handleSubmit(depositForm)"
                        :disabled="returnDeposit.info.status == 2"
                    >提 交</el-button>
                    <div style="margin-top:10px">
                        <el-tag type="danger">温馨提示：模版配置成功后，不能修改金额。</el-tag>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "MAM_returnDeposit",
    props: {
        returnDeposit: {
            type: Object
        },
        brandId: {
            type: [String, Number]
        }
    },
    data() {
        return {
            pickerOptions: { //禁止选之前的时间
                disabledDate(time) {
                    return time.getTime() < Date.now();
                }
            },
            is_layer_flag: '', // 是否支持层层返现标识
            setting: [],
            depositForm: {
            }
        };
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        handleSubmit(field) { //提交
            let confirmText = '活动奖励规则保存后，将不能进行修改，请确认是否保存？';
            if (this.returnDeposit.type == 2) { // 查看为编辑
                if (field.is_layer != this.is_layer_flag) {
                    if (field.is_layer == 0) {
                        confirmText = '设置当前服务费不支持层层返，将会清空已给下级配置的返现金额。'
                    } else {
                        confirmText = '设置当前服务费支持层层返，需要重新给下级配置返现金额。'
                    }
                }
            }
            let that = this;
            this.depositForm.setting = JSON.stringify(this.setting);
            this.depositForm.brand_id = this.brandId;
            this.$confirm(confirmText, '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('brand/brand_reward_cash_back_update', this.depositForm, res => {
                            if (res.code == 0) {
                                this.$message.success({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1500,
                                    onClose() {
                                        done();
                                        that.handleClose(1);
                                        instance.confirmButtonLoading = false;
                                    }
                                });
                            } else {
                                this.$message.error(res.msg);
                                done();
                                instance.confirmButtonLoading = false;
                            }
                        });
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleAdd() { //增加交易量奖励规则
            this.setting.push({
                brand_reward_conf_id: "",
                target_money: "",
                reward_money: "",
                status: 0,
                is_default: 0
            });
        },
        handleSwitch(row) {
            if (row.is_default == 1) {
                row.status = 1;
                this.$message.error('默认值不允许隐藏');
            }
        },
        handleDel(row, index) { //删除
            this.$confirm('确定删除该模板吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.setting.splice(index, 1);
            }).catch(() => {

            })
        },
        handleDefault(mark) { //处理勾选时默认值
            this._.forEach(this.setting, item => {
                item.is_default = 0;
            })
            this.setting[mark].is_default = 1;
        },
        handleClose(ref) { //关闭弹窗
            this.$emit('depositClose', ref)
        },
        getInfo() { //初始化
            this.$api.post('brand/brand_reward_cash_back', { brand_id: this.brandId }, res => {
                if (res.code === 0) {
                    let data = res.data;
                    this.depositForm = data;
                    this.is_layer_flag = data.is_layer;
                    if (data.setting.length != 0) {
                        this.setting = data.setting;
                    } else {
                        let money = [0, 68, 99, 168, 298, 398];
                        for (let i = 0; i <= 5; i++) {
                            this.setting.push({
                                brand_reward_conf_id: "",
                                is_default: i == 0 ? 1 : 0,
                                reward_money: "",
                                status: 1,
                                target_money: money[i],
                            })
                        }
                    }
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
.MAM_returnDeposit * {
    font-size: 12px;
    // .el-input__inner {
    //     font-size: 12px;
    // }
    ::v-deep .el-dialog__body {
        height: 600px;
        overflow: auto;
    }
    ::v-deep .el-form-item {
        margin-bottom: 5px;
        .el-form-item__label,
        .el-radio__label {
            font-size: 12px;
        }
    }
    .el-form--label-top ::v-deep .el-form-item__label {
        padding: 0;
    }
    .el-checkbox ::v-deep .el-checkbox__label {
        font-size: 12px;
    }
}
</style>