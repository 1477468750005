<template>
    <div class="breadcrumb">
        <el-breadcrumb separator="/">
            <template v-for="item in $route.matched">
                <el-breadcrumb-item :key="item.path" v-if="item.name">{{ item.meta.title}}</el-breadcrumb-item>
            </template>
        </el-breadcrumb>
    </div>
</template>

<script>
export default {
    name: "breadcrumb",
    data() {
        return {

        }
    },
    created() {
        // console.log(this.$route)
    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
.breadcrumb {
    height: 40px;
    min-width: 500px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    border-bottom: solid 1px rgba(0, 21, 41, 0.08);
}
</style>