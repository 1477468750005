<template>
    <div class="DM_transctionInfo">
        <!-- 交易明细详情 -->
        <el-dialog :visible="infoIsShow" @close="handleClose" :close-on-click-modal="false">
            <el-card class="box-card" shadow="hover">
                <div class="purseTitle">
                    <div>交易信息</div>
                </div>
                <el-row class="rowTitle">
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">订单号：</span>
                        <span>{{viewData.transaction_number}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">终端类型：</span>
                        <span>{{viewData.brand_name}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">终端SN：</span>
                        <span>{{viewData.terminal_sn}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">终端归属代理商账号：</span>
                        <span>{{viewData.phone}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">交易方式：</span>
                        <span>{{viewData.pay_type}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">交易金额：</span>
                        <span>{{viewData.transaction_money}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">终端归属代理商名称：</span>
                        <span>{{viewData.real_name}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">实发分润：</span>
                        <span>{{viewData.rebate_money}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">交易时间：</span>
                        <span>{{viewData.transaction_time}}</span>
                    </el-col>
                </el-row>
                <div class="purseTitle">
                    <div>各级实发分润详情</div>
                </div>
                <el-table
                    :data="transctionInfoTable"
                    size="mini"
                    height="200"
                    border
                    style="width: 100%"
                    v-loading="loading"
                >
                    <!-- id -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        fixed
                        prop="num"
                        label="序号"
                        min-width="80"
                    ></el-table-column>
                    <!-- 代理名称 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="name"
                        label="代理名称"
                        min-width="120"
                    ></el-table-column>
                    <!-- 层级 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="level"
                        label="层级"
                        min-width="120"
                    ></el-table-column>
                    <!-- 政策 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="rate"
                        label="政策"
                        min-width="150"
                    ></el-table-column>
                    <!-- 实发分润 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="profit"
                        label="实发分润"
                        min-width="120"
                    ></el-table-column>
                </el-table>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "DM_transctionInfo",
    props: {
        infoIsShow: {
            type: Boolean,
            default: false
        },
        detail: {
            type: Object
        }
    },
    data() {
        return {
            transctionInfoTable: [], //表格
            viewData: {},
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            id: null,
            loading: true,
        }
    },
    mounted() {
        this.id = this.detail.id;
        this.getData();
    },
    methods: {
        handleClose() {
            this.$emit('infoClose')
        },
        getData() {
            this.$api.post("transaction/getInfo", { id: this.detail.id }, res => {
                let data = res.data;
                if (res.code == 0) {
                    this.transctionInfoTable = data.profit_list;
                    this.viewData = data.info;
                    //渲染结束后取消加载
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                    //渲染结束后取消加载
                    this.loading = false;
                }
            })
        }
    }
};
</script>

<style lang="scss" scope>
.DM_transctionInfo {
    .purseTitle {
        font-size: 12px;
        margin: 10px 0;
        color: #909399;
        :nth-child(1) {
            font-weight: 700;
            color: #7f7f7f;
            padding-left: 10px;
            border-left: 4px solid #909399;
        }
    }
    .rowTitle {
        color: #909399;
        font-size: 12px;
        .el-col {
            margin: 5px 0;
        }
        .labels {
            font-weight: 700;
        }
    }
}
</style>
