<template>
    <div class="MAM_brandInfo">
        <el-dialog
            :title="brandDetail.type == 1?'添加终端类型':'编辑终端类型'"
            width="700px"
            :visible="brandDetail.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-card shadow="always">
                <el-form label-position="top" label-width="100px" :model="submitForm">
                    <el-form-item label="终端类型名称：">
                        <el-input v-model="submitForm.name" placeholder="请输入终端类型名称" maxlength="7"></el-input>
                    </el-form-item>
                    <el-form-item label="终端类型Logo：">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadUrl"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                        >
                            <div slot="tip" class="el-upload__tip">支持JPG、PNG格式，建议尺寸：118x118</div>
                            <img v-if="submitForm.logo" :src="submitForm.logo" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="机具单价(元/台)：">
                        <el-input v-model="submitForm.price" placeholder="请输入机具单价"></el-input>
                    </el-form-item>
                    <el-form-item label="主政策标识：">
                        <el-input disabled v-model="submitForm.main_policy" placeholder="请输入主政策标识"></el-input>
                    </el-form-item>
                    <el-form-item label="机器来源：">
                        <el-radio-group
                            v-model="submitForm.term_source"
                            :disabled="brandDetail.type == 2"
                        >
                            <el-radio :label="0">商户自备</el-radio>
                            <el-radio :label="1">厂商发货</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否支持采购：">
                        <el-radio-group v-model="submitForm.is_support_procurement">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <template v-if="submitForm.is_support_procurement == 1">
                        <el-form-item label="产品类型：">
                            <el-radio-group v-model="submitForm.product_type_id">
                                <el-radio
                                    v-for="(item,index) in productList"
                                    :key="index"
                                    :label="item.id"
                                >{{item.name}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="直签采购价格(元）：">
                            <el-table
                                :data="tableList"
                                size="small"
                                border
                                style="width: 100%"
                                max-height="500px"
                            >
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    prop="name"
                                    label="类型"
                                    min-width="100"
                                ></el-table-column>
                                <!-- 单价 -->
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    prop="price"
                                    label="单价"
                                    min-width="120"
                                ></el-table-column>
                                <!-- 状态 -->
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    prop="price"
                                    label="状态(隐藏/开启)"
                                    min-width="120"
                                >
                                    <template slot-scope="scope">
                                        <el-tooltip
                                            :content="scope.row.status == 1? '开启' : '隐藏'"
                                            placement="left"
                                        >
                                            <el-switch
                                                v-model="scope.row.status"
                                                :active-value="1"
                                                :inactive-value="0"
                                            ></el-switch>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                        <el-form-item label="产品介绍：">
                            <el-input
                                maxlength="10"
                                v-model="submitForm.product_introduce"
                                placeholder="6-10个字产品介绍"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="产品宣传图：">
                            <div class="banner-content">
                                <el-row :gutter="20">
                                    <transition-group name="el-fade-in">
                                        <el-col
                                            :span="12"
                                            v-for="(item,index) in product_fileList"
                                            :key="item.file_url"
                                        >
                                            <div class="banner-img">
                                                <img :src="item.file_url" alt />
                                                <i
                                                    class="el-icon-circle-close"
                                                    @click="handleDelbanner(index)"
                                                ></i>
                                            </div>
                                        </el-col>
                                        <el-col
                                            :span="12"
                                            key="uploads"
                                            v-if="product_fileList.length != 3"
                                        >
                                            <el-upload
                                                class="avatar-uploader"
                                                :action="uploadUrl"
                                                multiple
                                                :show-file-list="false"
                                                :on-success="handleProductSuccess"
                                                :before-upload="beforeProductUpload"
                                            >
                                                <i class="el-icon-plus avatar-uploader-icon"></i>
                                            </el-upload>
                                        </el-col>
                                    </transition-group>
                                </el-row>
                            </div>
                        </el-form-item>
                    </template>
                    <el-form-item>
                        <el-button
                            style="margin-top:10px"
                            type="primary"
                            :loading="loading"
                            @click="handleSubmit(submitForm)"
                        >保 存 配 置</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    inject: ['reload'],
    props: {
        brandDetail: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            uploadUrl: '',
            productList: [], // 产品类型列表
            tableList: [],  // 直签采购价格列表
            product_fileList: [],
            submitForm: {
                name: '', // 品牌名称
                logo: '', // logo展示地址
                path: '', // logo半路径
                price: '', // 机具单价
                is_rise_price: 0, // 是否支持涨价 0.不支持 1.支持 --默认不支持
                main_policy: '66', // 主政策标识
                term_source: 1, // 机器来源 0.商户自备 1.厂商发货
                is_support_procurement: 0, // 是否支持采购 0.不支持 1.支持
                product_type_id: 1, // 产品类型
                procurement_price_id: '', // 选中的产品价格id 逗号拼接id
                product_introduce: '', // 产品介绍
                banner: '', // banner图
            },
            loading: false
        };
    },
    created() {
        this.uploadUrl = this.$api.uploadUrl;//上传文件地址
        this.getProduct();
        if (this.brandDetail.type == 1) {
            this.getProcurementPrice();
        } else {
            this.getData();
        }
    },
    mounted() {
    },
    methods: {
        handleSubmit(submitForm) {
            let reqData = {};
            if (this.brandDetail.type == 2) { // 是否编辑
                submitForm.brand_id = this.brandDetail.row.id
            }
            if (submitForm.is_support_procurement == 1) { // 是否支持采购
                reqData = submitForm;
                submitForm.procurement_price_id = this.formatPrice(this.tableList);
                submitForm.banner = this.formatBanner(this.product_fileList);
            } else {
                reqData = this._.omit(submitForm, ['product_type_id', 'procurement_price_id', 'product_introduce', 'banner'])
            }
            let _this = this;
            this.loading = true;
            this.$api.post('brand/add_or_update_brand', reqData, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            // 刷新页面
                            _this.loading = false;
                            _this.handleClose(1);
                        }
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            _this.loading = false;
                        }
                    })
                }
            })
        },
        handleClose(ref) { //关闭弹窗
            this.$emit('brandClose', ref);
        },
        formatPrice(arr) { // 格式化价格
            let priceArr = [];
            arr.forEach(item => {
                if (item.status != 0) {
                    priceArr.push(item.id);
                }
            });
            return priceArr.join(',');
        },
        formatBanner(arr) { // 格式化banner
            let bannerArr = [];
            arr.forEach(item => {
                if (item.status != 0) {
                    bannerArr.push(item.path);
                }
            });
            return bannerArr.join(',');
        },
        getData() { // 获取详情
            this.$api.post('brand/brand_detail', { brand_id: this.brandDetail.row.id }, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.submitForm = { ...this.submitForm, ...data };
                    this.product_fileList = data.banner;
                    this.tableList = data.procurement_price_ids;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        getProduct() { // 获取产品类型
            this.$api.post('brand/product_type_list', {}, res => {
                if (res.code == 0) {
                    this.productList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        getProcurementPrice() { // 获取采购价格
            this.$api.post('brand/procurement_price_list', {}, res => {
                if (res.code == 0) {
                    this.tableList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        beforeAvatarUpload(file) { //上传前的提示
            // const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            if (!isLt2M) {
                this.$message.error('上传Logo图片大小不能超过 2MB!');
            }
            return isLt2M;

        },
        handleAvatarSuccess(res) { //logo
            if (res.code === 0) {
                this.submitForm.logo = res.data.file_url;
                this.submitForm.path = res.data.file_path;
            } else {
                //错误提示
                this.$message.error(res.msg);
            }
        },
        handleProductSuccess(res) { // 产品图片
            if (res.code === 0) {
                let obj = {
                    path: res.data.file_path,
                    file_url: res.data.file_url
                }
                if (this.product_fileList.length < 3) {
                    this.product_fileList.push(obj);
                }
            } else {
                //错误提示
                this.$message.error(res.msg);
            }
        },
        beforeProductUpload(file) { // banner图校验
            const isLt2M = file.size / 1024 / 1024 < 2;
            const isLt2P = this.product_fileList.length < 3;
            if (!isLt2M) {
                this.$message.error('上传Banner图片大小不能超过 2MB!');
            }
            if (!isLt2P) {
                this.$message.error('最多只能上传3张图片!');
            }
            return isLt2P && isLt2M;

        },
        handleDelbanner(index) { // 删除
            this.product_fileList.splice(index, 1);
        }
    }
};
</script>

<style scoped lang="scss">
.MAM_brandInfo {
    .el-form {
        width: 550px;
    }
    ::v-deep .el-card__body {
        max-height: 500px;
        overflow: auto;
    }
    .el-input__inner {
        font-size: 12px;
    }

    .el-form-item {
        margin-bottom: 5px;
    }
    .banner-content {
        .banner-img {
            width: 250px;
            height: 100px;
            border-radius: 5px;
            position: relative;
            top: 0;
            left: 0;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                overflow: hidden;
            }
            i {
                font-size: 25px;
                position: absolute;
                top: -5px;
                right: -5px;
                cursor: pointer;
                background-color: #ffffff;
                border-radius: 50%;
            }
            i:hover {
                color: #409eff;
            }
        }
    }
    .el-col {
        margin-bottom: 20px;
    }
    .avatar-uploader::v-deep .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader::v-deep .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon::v-deep {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }

    .avatar::v-deep {
        width: 100px;
        height: 100px;
        display: block;
    }
}
</style>