<template>
    <div class="MAM_riskControlList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-select
                        v-model="searchForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.type" clearable placeholder="请选择规则类型">
                        <el-option
                            v-for="(item,index) in ruleList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.status" clearable placeholder="请选择状态">
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="失效" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="handleAdd">新增规则</el-button>
            </div>
            <el-table
                :data="riskControlList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="150"
                ></el-table-column>
                <!-- 规则类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="risk_control_name"
                    label="规则类型"
                    min-width="120"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="状态"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.status == 1? '正常' :'失效'}}</span>
                    </template>
                </el-table-column>
                <!-- 失效时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="cut_off_time"
                    label="失效时间"
                    min-width="150"
                ></el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="创建时间"
                    min-width="150"
                ></el-table-column>
                <!-- 最近一次修改时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="update_time"
                    label="最近一次修改时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="handleDel(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <risk-info v-if="info.isShow" :info="info" @infoClose="infoClose"></risk-info>
    </div>
</template>

<script>
import riskInfo from './MAM_riskControlInfo';
export default {
    name: "MAM_riskControlList",
    data() {
        return {
            brandList: [], //终端类型
            ruleList: [],//规则类型
            searchForm: {
                page: 1,
                limit: 10,
                brand_id: '', //终端类型
                type: '', //规则类型
                status: '' //状态

            },
            riskControlList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true,
            info: { //添加编辑规则
                type: 1, //1.添加 2.编辑
                isShow: false,
                detail: {}
            }
        }
    },
    components: {
        riskInfo
    },
    mounted() {
        this.getBrand();//获取终端类型
        this.getRule(); //获取规则类型
        this.getData({ //初始化表格数据
            page: 1,
            limit: 10
        })
    },
    methods: {
        onSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleAdd() { //添加
            this.info.isShow = true;
            this.info.type = 1;
            this.info.detail = {};
        },
        handleEdit(row) {  //编辑
            this.info.isShow = true;
            this.info.type = 2;
            this.info.detail = row;
        },
        handleDel(row) {
            this.$confirm('确认删除该规则配置？', '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    let _this = this;
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('brand_risk_control/del_brand_risk_control', { id: row.id }, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                        _this.getData({
                                            page: _this.currentPage,
                                            limit: _this.pageSize
                                        });
                                    }
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg
                                });
                                done();
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        infoClose(ref) { //关闭弹窗
            this.info.isShow = false;
            if (ref) {
                this.getData({ //初始化表格数据
                    page: this.currentPage,
                    limit: this.pageSize
                })
            }
        },
        // 分页部分
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("brand_risk_control/get_brand_risk_control_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.riskControlList = data.list;
                    //渲染结束后取消加载
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        getBrand() { //获取终端类型
            this.$api.post('brand/brand_list', {}, res => {
                if (res.code == 0) {
                    this.brandList = res.data.list;
                } else {
                    this.$message.success(res.msg);
                }
            });
        },
        getRule() { //获取规则类型
            this.$api.post('get_risk_control', {}, res => {
                if (res.code == 0) {
                    this.ruleList = res.data;
                } else {
                    this.$message.success(res.msg);
                }
            });
        }
    }
};
</script>

<style lang="scss" scope>
.MAM_riskControlList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
    .el-popover__title {
        font-size: 12px;
        font-weight: 700;
    }
}
</style>
