<template>
    <div class="FM_paymentRecord">
        <!-- 代付账户记录查询 -->
        <el-dialog
            :visible="paymentRecordShow"
            @close="handleClose"
            :close-on-click-modal="false"
            title="代付账户记录查询"
            width="800px"
        >
            <el-card class="box-card" shadow="hover">
                <el-table
                    :data="paymentRecordTable"
                    size="mini"
                    border
                    style="width: 100%"
                    v-loading="loading"
                >
                    <!-- id -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        fixed
                        prop="id"
                        label="序号"
                        min-width="80"
                    ></el-table-column>
                    <!-- 账户类型 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="account_type"
                        label="账户类型"
                        min-width="200"
                    ></el-table-column>
                    <!-- 入账类型 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="pay_type"
                        label="入账类型"
                        min-width="120"
                    ></el-table-column>
                    <!-- 科目 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="type"
                        label="科目"
                        min-width="150"
                    ></el-table-column>
                    <!-- 金额 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="money"
                        label="金额"
                        min-width="200"
                    ></el-table-column>
                    <!-- 日期 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="create_time"
                        label="日期"
                        min-width="200"
                    ></el-table-column>
                </el-table>
                <!-- 分页部分 -->
                <paginations
                    :currentPage="page.currentPage"
                    :pageSize="page.pageSize"
                    :total="page.total"
                    @watchCurrent="getCurrent"
                    @watchPageSize="getPageSize"
                ></paginations>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
export default {
    name: "FM_paymentRecord",
    props: {
        paymentRecordShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            paymentRecordTable: [],
            loading: false,
        }
    },
    mounted() {
        this.getData({ //初始化
            page: this.page.currentPage,
            limit: this.page.pageSize
        });
    },
    components: {
        paginations
    },
    methods: {
        handleClose() {
            this.$emit('paymentClose');
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.getData({
                page: this.page.currentPage,
                limit: this.page.pageSize
            });
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.getData({ //初始化
                page: this.page.currentPage,
                limit: this.page.pageSize
            });
        },
        getData(params) {
            this.$api.post("payment_log_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.paymentRecordTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scope>
.FM_paymentRecord {
    .purseTitle {
        font-size: 12px;
        color: #909399;
        :nth-child(1) {
            font-weight: 700;
            color: #7f7f7f;
        }
    }
    // .el-dialog__header {
    //     padding: 20px 20px 0px;
    // }
    // .el-dialog__body {
    //     padding: 0px 20px 30px 20px;
    // }
    // .el-input__inner {
    //     font-size: 12px;
    // }
    // .el-form-item {
    //     margin-bottom: 5px;
    // }
}
</style>
