<template>
    <div class="bigBoss">
        <el-dialog
            :title="isAdd?'添加库存':'删除库存'"
            width="50%"
            :visible="toolsIsShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-form label-position="top" label-width="80px" :model="stockForm">
                <el-form-item>
                    <div class="titleTip">{{isAdd?'添加库存':'删除库存'}}</div>
                </el-form-item>
                <el-form-item label="终端类型">
                    <el-select
                        size="medium"
                        v-model="stockForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上传文件">
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        :drag="true"
                        :show-file-list="false"
                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        :on-change="beforeUpload"
                        :auto-upload="false"
                        :headers="headers"
                        :data="{brand_id:stockForm.brand_id}"
                        :on-success="handleUploadSuccess"
                        :on-error="handleUploadError"
                        :action="action"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            <div v-if="!upText" style="font-size:12px;">
                                <em style="font-size:14px;font-weight:700">点击添加文件</em>
                            </div>
                            <div v-else style="font-size:12px;">{{upText}}</div>
                        </div>
                        <div class="el-upload__tip" slot="tip">
                            <span style="color:#D9001B">*请按照模版规范进行导入。</span>
                            <el-link type="primary" :href="templateUrl">下载模版</el-link>
                        </div>
                    </el-upload>

                    <el-button type="primary" style="margin-top:10px;" @click="submit">点击提交</el-button>
                </el-form-item>
                <el-collapse-transition>
                    <el-form-item v-if="statusIsShow">
                        <div class="titleTip">数据状态</div>
                        <div class="tips">共计数据:{{dataStatus.total}}条</div>
                        <div class="tips">成功数据:{{dataStatus.successCount}}条</div>
                        <div class="tips">失败数据:{{dataStatus.errorCount}}条</div>
                        <div class="tips" v-if="dataStatus.errorData">
                            <span style="color:#D9001B">*导出失败数据</span>
                            <el-link type="primary" :href="dataStatus.errorData">下载失败数据</el-link>
                        </div>
                    </el-form-item>
                </el-collapse-transition>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "MAM_stockTools",
    inject: ['reload'],
    props: {
        toolsIsShow: {
            type: Boolean,
            default: false
        },
        isAdd: {
            type: [String, Boolean]
        },
        isDel: {
            type: [String, Boolean]
        }
    },
    data() {
        return {
            uploadUrl:'',
            templateUrl: '',
            statusIsShow: false,
            brandList: [], //下拉框
            stockForm: {
                brand_id: '',
                file: null,
            },
            upText: '',
            headers: {
                'Organization-Code': location.hostname.split('.')[0],
                'Authorization': 'Bearer' + sessionStorage.getItem('token')
            },
            dataStatus: {
                total: '', //总数
                successCount: '', //成功条数
                errorCount: '', //失败条数
                errorData: '' //失败地址
            },
            action: ''
        };
    },
    mounted() {
        this.uploadUrl = this.$api.uploadUrl;//上传文件地址
        
        const BASE_URL = `${this.$api.reqUrl}terminal/`;
        this.action = this.isAdd ? BASE_URL + 'insert_terminal' : BASE_URL + 'delete_terminal';
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$$message.success(res.msg);
            }
        });

        let type = this.isAdd ? 1 : 2; //1.添加库存 2.删除库存
        this.$api.post('terminal/template_url', { type }, res => { //获取下载模板
            if (res.code == 0) {
                this.templateUrl = res.data;
            } else {
                this.$$message.success(res.msg);
            }
        })
    },
    methods: {
        submit() {
            if (!this.stockForm.brand_id) {
                this.$message.error('请选择终端类型');
            }
            else
                if (!this.stockForm.file) {
                    this.$message.error('请添加文件');
                } else {

                    this.$refs.upload.submit();
                }

        },
        handleClose(ref) { //关闭弹窗
            this.$emit('toolsClose', ref);
        },
        beforeUpload(file, fileList) {
            if (fileList.length > 1) {
                //上传前的提示
                fileList.splice(0, 1);
            }
            this.upText = file.name;
            this.stockForm.file = file.raw;
        },
        handleUploadSuccess(res) {
            if (res.code == 0) {
                this.tipMessage('success', res) //上传成功
            } else {
                this.tipMessage('error', res); //上传失败
            }
        },
        handleUploadError() { //上传报错
            this.$message.error('上传错误');
        },
        tipMessage(type, res) {
            let that = this;
            this.$message({
                type: type,
                message: res.msg,
                duration: 2000,
                onClose() {
                    let { count, download_url, error, success } = res.data;
                    that.dataStatus.total = count;
                    that.dataStatus.successCount = success;
                    that.dataStatus.errorCount = error;
                    that.dataStatus.errorData = download_url;
                    that.statusIsShow = true;
                    that.upText = '';
                    that.stockForm.file = '';
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.bigBoss {
    font-size: 12px;
    .el-input__inner,
    .tips {
        font-size: 12px;
    }

    .el-form-item {
        margin-bottom: 5px;
    }

    ::v-deep .el-form-item__label {
        padding: 0;
        font-size: 12px;
    }
    .titleTip {
        padding: 10px 10px;
        background-color: #ecf8ff;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #50bfff;
        margin: 10px 0;
    }
    ::v-deep .el-dialog{
        min-width: 500px;
    }
}
</style>