<template>
    <div
        class="nav-container"
        :style="{width:sidebar.opened ? 'calc(100% - 210px)':'calc(100% - 54px)'}"
    >
        <div class="navbar">
            <div
                class="headerMenu"
                @click="toggleSideBar"
                :class="[sidebar.opened?'el-icon-s-fold':'el-icon-s-unfold']"
            ></div>
            <div class="userinfo">
                <div class="service">
                    <el-dropdown placement="bottom">
                        <span class="el-dropdown-link">
                            <el-badge is-dot :hidden="messageIcon == 0" class="message-icon">
                                <i class="el-icon-message-solid"></i>
                            </el-badge>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-for="(item,index) in messageList"
                                :key="index"
                                @click.native="handleEnterPage(item.path)"
                            >
                                <img :src="item.image_url" alt class="message-img" />
                                {{item.title}}
                                <el-badge class="mark" :hidden="item.count==0" :value="item.count" />
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="service">
                    <i class="iconfont icon-shop-cservice" @click="handleService"></i>
                </div>
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{userName}}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item>设置头像</el-dropdown-item> -->
                        <el-dropdown-item command="editPassword">修改密码</el-dropdown-item>
                        <el-dropdown-item @click.native="handleLogOut">退出账户</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <breadcrumb></breadcrumb>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "./breadcrumb";

export default {
    components: { Breadcrumb },
    name: "navbar",
    data() {
        return {
            messageIcon: 0,
            messageList: [],//消息列表
            userName: '',
            userimg: require("../../assets/images/user_d.gif")
        };
    },
    mounted() {
        this.userName = sessionStorage.getItem('organization_name');
        this.getMessage();
    },
    computed: {
        ...mapGetters(["sidebar"])
    },
    methods: {
        toggleSideBar() {
            this.$store.dispatch("app/toggleSideBar");
        },
        handleLogOut() { //退出
            this.$api.post('logout', {}, res => {
                if (res.code == 0) {
                    this.$message.success('退出成功')
                    sessionStorage.clear();
                    this.$router.push({ path: "/login" });
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        handleService() { //在线客服
            this.$api.post('online_service/online_service_avatar_jump', {}, res => {
                if (res.code != 0) {
                    this.$message.error(res.msg);
                } else {
                    let data = res.data;
                    if (data.status == 1) {
                        this.$alert('您还没有申请在线客服功能哦，点击下方按钮前去申请 !<hr style="margin-top:20px;">', '', {
                            center: true,
                            type: 'warning',
                            dangerouslyUseHTMLString: true,
                            confirmButtonClass: 'apply-button',
                            confirmButtonText: '去 申 请',
                            callback: action => {
                                if (action === 'confirm') {
                                    this.$router.push('serviceApplication');
                                }
                            }
                        });
                    } else {
                        // var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
                        var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/;
                        const DATA_URL = Expression.test(data.url) ? data.url : `http://${data.url}`;
                        window.open(DATA_URL);
                    }
                }
            })
        },
        handleCommand(events) { //修改密码
            if (events == 'editPassword') {
                this.$router.push('/editPassword');
            }
        },
        handleEnterPage(path) { //消息列表进入目标页
            this.$router.push({ path });
        },
        getMessage() { //获取消息
            this.$api.post('index', {}, res => {
                if (res.code == 0) {
                    this.messageList = res.data;
                    res.data.forEach(v => {
                        this.messageIcon += v.count;
                    });
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
.message-img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}
.nav-container {
    position: fixed;
    width: calc(100% - 210px);
    transition: width 0.28s;
    height: 100px;
    top: 0;
    .navbar {
        height: 50px;
        overflow: hidden;
        background: #fff;
        border-bottom: solid 1px rgba(0, 21, 41, 0.08);
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .headerMenu {
            font-size: 26px;
        }
        .userinfo {
            display: flex;
            align-items: center;
        }
        .el-dropdown-link:hover {
            // cursor: pointer;
            color: #409eff;
        }
        .el-dropdown {
            color: #000000;
            cursor: pointer;
        }
        .service {
            margin: 0 10px;
            display: inline-block;
            i {
                cursor: pointer;
                font-size: 25px;
            }
            i:hover {
                color: #409eff;
            }
        }
    }
}
</style>