<template>
    <div class>
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <el-card class="box-card" shadow="hover">
            <div class="tansferBox">
                <el-form label-position="top" label-width="80px">
                    <!-- 终端类型 -->
                    <el-form-item label="终端类型：">
                        <el-select
                            size="medium"
                            @change="handleGetTerminal(searchForm)"
                            filterable
                            v-model="searchForm.brand_id"
                            :style="{width:formItemWidth}"
                            placeholder="请选择终端类型"
                        >
                            <el-option
                                v-for="(item,index) in brandList"
                                :key="index"
                                :label="`${item.name}--${formatSource(item.term_source)}--${item.main_policy}`"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 入库方式 -->
                    <el-form-item label="入库方式：">
                        <el-radio-group @change="handleStorageMethod" v-model="storageMethod">
                            <el-radio :label="1">列表选择</el-radio>
                            <el-radio :label="2">批量选择</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!-- 选择终端->批量选择 -->
                    <el-form-item :style="{width:formItemWidth}" v-show="storageMethod == 2">
                        <el-form-item label="起始SN：">
                            <el-input
                                size="medium"
                                @input="handleBatchChange"
                                v-model="batchForm.start_sn"
                                placeholder="请输入起始SN"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="结束SN：">
                            <el-input
                                size="medium"
                                v-model="batchForm.end_sn"
                                @input="handleBatchChange"
                                :disabled="batchForm.transfer_num != ''"
                                placeholder="请输入结束SN"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="终端数量：">
                            <el-input
                                size="medium"
                                @keyup.native="handleInt($event)"
                                v-model="batchForm.transfer_num"
                                @input="handleBatchChange"
                                :disabled="batchForm.end_sn != ''"
                                placeholder="请输入终端数量"
                            ></el-input>
                        </el-form-item>
                        <div class="batchTip">
                            <div style="font-size:12px;font-weight:700;color:#EC808D">
                                <span>搜索结果：{{searchCount}}台&nbsp;</span>
                                <span>
                                    <el-button @click="handleCheck" type="text" size="small">查看</el-button>
                                </span>
                            </div>
                            <div>
                                <el-button
                                    @click="handleVerify(batchForm)"
                                    type="text"
                                    size="small"
                                >验证</el-button>
                            </div>
                        </div>
                    </el-form-item>
                    <!-- 选择终端->列表选择 -->
                    <el-form-item label="选择终端：" v-show="storageMethod == 1">
                        <el-form-item class :style="{width:formItemWidth}">
                            <el-input size="medium" placeholder="请输入SN搜索" v-model="searchForm.sn">
                                <el-button
                                    size="medium"
                                    slot="append"
                                    type="primary"
                                    icon="el-icon-search"
                                    style="font-size: 12px;"
                                    @click="handleSearch(searchForm)"
                                >搜索</el-button>
                            </el-input>
                        </el-form-item>
                        <el-transfer
                            v-loading="loading"
                            element-loading-text="拼命加载中"
                            :titles="titles"
                            filter-placeholder="搜索SN码"
                            v-model="terminal_id"
                            :data="terminalList"
                            :props="{key:'id',label:'sn'}"
                        ></el-transfer>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            style="font-size: 12px;margin-top:10px"
                            :loading="submitLoading"
                            @click="handleSubmit($route.params.id)"
                        >提 交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <search-result v-if="info.isShow" :info="info" @infoClose="infoClose"></search-result>
    </div>
</template>

<script>
import searchResult from '../transferManagement/MAM_searchResult';
export default {
    data() {
        return {
            brandList: [], //终端类型列表
            storageMethod: 1, // 入库方式 1.列表 2.批量
            searchForm: {  // 列表选择时的搜索
                id: '', // 入库记录id
                sn: '', // 搜索
                page: 1, // 页码
                limit: 1000, // 默认1000条
                brand_id: '' // 终端类型id
            },
            batchForm: { //批量选择的搜索
                id: '', // 库存列表id
                type: '', // 类型：1=起始---结束sn,2=起始sn ---- 终端数量
                end_sn: '', // 结束sn
                start_sn: '', // 开始sn
                brand_id: '', // 终端类型id
                transfer_num: '', // 终端数量
            },
            terminal_id: [], //被选中的sn
            formItemWidth: '500px',
            info: {
                isShow: false,
                detail: []
            },
            terminalList: [], // 穿梭框数据源
            titles: ['库存', '已选'], // 穿梭框title 
            searchCount: 0, // 批量
            loading: false,
            submitLoading: false
        }
    },
    computed: {
        formatSource() {
            return (data) => {
                let source = '';
                switch (data) {
                    case 0:
                        source = '商户自备';
                        break;
                    case 1:
                        source = '厂商发货';
                        break;
                    default:
                        source = '未知';
                        break;
                }
                return source;
            }
        }
    },
    watch: {
        'searchForm.brand_id'(newVal) {
            this.batchForm.brand_id = newVal;
        }
    },
    components: {
        searchResult
    },
    created() {
        let id = this.$route.params.id;
        this.searchForm.id = id;
        this.batchForm.id = id;
        this.getBrandList();
    },
    mounted() {
        let els = document.querySelector('.el-transfer-panel');
        this.formItemWidth = els.clientWidth + 'px';
        this.$nextTick(() => {
            window.onresize = () => {
                if (els.clientWidth) {
                    this.formItemWidth = els.clientWidth + 'px';
                } else {
                    this.formItemWidth = els.clientWidth + '400px';
                }
            }
        })
    },
    methods: {
        handleSubmit(id) { //提交
            let that = this;
            let reqData = {
                id,
                brand_id: this.searchForm.brand_id,
                terminal_id: this.terminal_id.join(',')
            }
            if (this.searchForm.brand_id == '') {
                this.$message.error('请选择终端类型');
            } else if (this.terminal_id.length == 0) {
                this.$message.error('请选择终端sn');
            } else {
                this.submitLoading = true;
                this.$api.post('sub_terminal_in_stock_log/put_storage', reqData, res => {
                    if (res.code == 0) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1500,
                            onClose() {
                                that.submitLoading = false;
                                that.$router.back();
                            }
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            type: 'error',
                            message: res.msg,
                            duration: 5000
                        });
                        this.submitLoading = false;
                    }
                });
            }
        },
        handleCheck() { //查看
            this.info.isShow = true;
        },
        infoClose() { //关闭弹窗
            this.info.isShow = false;
        },
        handleVerify(batchForm) { //验证
            if (batchForm.start_sn != '' && batchForm.end_sn != '') {
                batchForm.type = 1;
            }
            if (batchForm.start_sn != '' && batchForm.transfer_num != '') {
                batchForm.type = 2;
            }
            if (batchForm.id) { // 是否选择终端类型
                this.$api.post('sub_terminal_in_stock_log/batch_search', batchForm, res => {
                    if (res.code == 0) {
                        this.searchCount = res.data.length; //搜索结果
                        this.info.detail = res.data; //给到搜索结果
                        this._.forEach(res.data, (item) => {//获取的终端
                            this.terminal_id.push(item.id);
                        })
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            } else {
                this.$message.error('请选择终端类型');
            }
        },
        handleStorageMethod() { //入库方式
            this.resetSeleted(); //重置
        },
        handleSearch(searchForm) {   //搜索终端
            this.getTerminalList(searchForm);
        },
        handleGetTerminal(searchForm) { //获取终端
            this.resetSeleted(); //重置
            this.getTerminalList(searchForm);
        },
        handleBatchChange() { //监听开始sn、结束SN、终端数量
            this.resetSeleted(); //重置
        },
        resetSeleted() { //重置选择
            this.searchCount = 0; //搜索结果
            this.info.detail = []; //重置查看 
            this.terminal_id = []; //重置已选终端
        },
        handleInt(e) { //替换不为数字的值
            e.target.value = e.target.value.replace(/\D/g, '');
            let isInt = /^[0-9]([0-9])*$/; //判断是否为正整数
            if (!isInt.test(e.target.value)) {
                e.target.value = '';
            }
        },
        getBrandList() { // 获取品牌
            this.$api.post('brand/brand_list', {}, res => { //获取终端类型
                if (res.code == 0) {
                    this.brandList = res.data.list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getTerminalList(params) {
            //开启加载 
            this.loading = true;
            this.$api.post('sub_terminal_in_stock_log/get_sn_list', params, res => {
                if (res.code == 0) {
                    this.terminalList = res.data;
                    //关闭加载 
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                    //关闭加载 
                    this.loading = false;
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
.tansferBox {
    min-width: 920px;
}
.title {
    margin: 20px 0 10px 0;
    color: #606266;
    :nth-child(1) {
        font-weight: 700;
    }
}
.el-input__inner,
::v-deep .el-radio__label {
    font-size: 12px;
}
.el-form-item {
    margin-bottom: 5px;
    ::v-deep .el-form-item__label {
        padding: 0;
        font-weight: 700;
        font-size: 12px;
    }
    ::v-deep .el-form-item__content {
        line-height: 0;
    }
}
::v-deep .el-checkbox__label {
    font-size: 12px;
}
::v-deep .el-transfer {
    font-size: 12px;
    .el-transfer-panel__header {
        .el-checkbox__label {
            font-size: 13px;
            font-weight: 700;
        }
    }
    .el-transfer-panel {
        width: 40%;
    }
}
.batchTip {
    line-height: 15px;
    display: flex;
    justify-content: space-between;
}
</style>
