/** 商学院 */
import articleCategory from '../../views/businessCollege/articleCategory/BC_categoryList'; //文章分类
import articleList from '../../views/businessCollege/ArticleList/BC_articleList'; //文章列表
import articleInfo from '../../views/businessCollege/ArticleList/BC_articleInfo'; //文章详情

export default [
    {
        path: '/articleCategory',
        name: 'articleCategory',
        meta: {
            title: '文章分类'
        },
        component: articleCategory
    },
    {
        path: '/articleList',
        name: 'articleList',
        meta: {
            title: '文章列表'
        },
        component: articleList
    },
    {
        path: '/articleInfo',
        name: 'articleInfo',
        meta: {
            title: '文章详情'
        },
        component: articleInfo
    }
]