<template>
    <div class="MAM_riskControlLogList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-select v-model="searchForm.type" clearable placeholder="请选择规则名称">
                        <el-option
                            v-for="(item,index) in ruleList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="searchForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.terminal_sn" placeholder="请输入SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.real_name" placeholder="请输入SN归属人名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.phone" placeholder="请输入SN归属人账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.status" clearable placeholder="请选择扣款状态">
                        <el-option label="扣款失败" value="0"></el-option>
                        <el-option label="扣款成功" value="1"></el-option>
                        <el-option label="核销成功" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="触发开始日期"
                        end-placeholder="触发结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-table
                :data="riskControlLogList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 规则名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="type_name"
                    label="规则名称"
                    min-width="120"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="150"
                ></el-table-column>
                <!-- 终端SN -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_sn"
                    label="终端SN"
                    min-width="200"
                ></el-table-column>
                <!-- 终端归属人名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="终端归属人名称"
                    min-width="120"
                ></el-table-column>
                <!-- 终端归属人账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="终端归属人账号"
                    min-width="150"
                ></el-table-column>
                <!-- 触发时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="触发时间"
                    min-width="150"
                ></el-table-column>
                <!-- 扣款金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="deduction_money"
                    label="扣款金额"
                    min-width="150"
                ></el-table-column>
                <!-- 扣款情况 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status_name"
                    label="扣款情况"
                    min-width="120"
                ></el-table-column>
                <!-- 扣款成功时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="deduction_time"
                    label="扣款成功时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.status == 0"
                            @click="handleSkip(scope.row)"
                            type="text"
                            size="small"
                        >核销</el-button>
                        <div v-else>--</div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "MAM_riskControlLogList",
    data() {
        return {
            rangeDate: '',
            brandList: [], //终端类型
            ruleList: [],//规则类型
            searchForm: {
                page: 1,
                limit: 10,
                type: '', //规则类型
                brand_id: '', //终端类型
                terminal_sn: '', //SN
                real_name: '', //SN归属人姓名
                phone: '', //SN归属人手机号
                status: '', //扣款状态 0失败 1成功
                start_time: '', //触发开始日期
                end_time: '', //触发结束日期

            },
            riskControlLogList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true,
        }
    },
    mounted() {
        this.getBrand();//获取终端类型
        this.getRule(); //获取规则类型
        this.getData({ //初始化表格数据
            page: 1,
            limit: 10
        })
    },
    methods: {
        onSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleSkip(row) {  //编辑
            this.$confirm('核销后，将扣除所属代理的剩余扣款钱包对应的金额，扣款状态更新为扣款成功。', '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                closeOnClickModal: false,
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action == 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('brand_risk_control/deal_brand_risk_control_trigger', { id: row.id }, res => {
                            if (res.code == 0) {
                                this.$message.success(res.msg);
                                this.loading = true;
                                this.getData(this.searchForm);
                                done();
                            } else {
                                this.$message.error(res.msg);
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                    }
                }
            }).then(() => {
            }).catch(() => { });
        },
        // 分页部分
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("brand_risk_control/get_brand_risk_control_trigger_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.riskControlLogList = data.list;
                    //渲染结束后取消加载
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        getBrand() { //获取终端类型
            this.$api.post('brand/brand_list', {}, res => {
                if (res.code == 0) {
                    this.brandList = res.data.list;
                } else {
                    this.$message.success(res.msg);
                }
            });
        },
        getRule() { //获取规则类型
            this.$api.post('get_risk_control', {}, res => {
                if (res.code == 0) {
                    this.ruleList = res.data;
                } else {
                    this.$message.success(res.msg);
                }
            });
        },
        pickerChange(DateArr) { //时间选择器
            this.searchForm.start_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.searchForm.end_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
};
</script>

<style lang="scss" scope>
.MAM_riskControlLogList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
    .el-popover__title {
        font-size: 12px;
        font-weight: 700;
    }
}
</style>
