<template>
    <div class="MAM_queryList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="queryForm" class="demo-form-inline">
                <el-form-item>
                    <el-select
                        size="medium"
                        v-model="queryForm.brandId"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="queryForm.type" clearable placeholder="请选择库存状态">
                        <el-option label="导入终端入库" value="1"></el-option>
                        <el-option label="划拨终端出库" value="2"></el-option>
                        <el-option label="删除终端出库" value="3"></el-option>
                        <el-option label="终端抵消入库" value="4"></el-option>
                        <el-option label="终端退回入库" value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="queryForm.terminal_sn" placeholder="请输入SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(queryForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click>占位</el-button>
            </div>-->
            <el-table
                :data="stockTable.list"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="150"
                ></el-table-column>
                <!-- 类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="type_name"
                    label="类型"
                    min-width="100"
                ></el-table-column>
                <!-- 机具台数 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_num"
                    label="机具台数"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleInfo(scope.row)"
                            type="text"
                            size="small"
                        >{{scope.row.terminal_num}}</el-button>
                    </template>
                </el-table-column>
                <!-- 代理商 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="代理商"
                    min-width="120"
                ></el-table-column>
                <!-- 代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 订单号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="order_num"
                    label="订单号"
                    min-width="250"
                ></el-table-column>
                <!-- 操作员 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="admin_name"
                    label="操作员"
                    min-width="120"
                ></el-table-column>
                <!-- 出/入库时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="出/入库时间"
                    min-width="180"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
        <query-info
            v-if="infoIsShow"
            :infoIsShow="infoIsShow"
            :listId="listId"
            :detail="detail"
            @infoClose="infoClose"
        ></query-info>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import queryInfo from './MAM_queryInfo';
export default {
    name: "MAM_queryList",
    data() {
        return {
            infoIsShow: false, //弹窗
            listId: null,
            detail: {
                brandName: null,
                terminalNum: null
            },
            queryForm: {
                brandId: '',
                type: '',
                terminal_sn: ''
            },
            stockTable: { //列表
                list: [],
            },
            brandList: [],
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 100  //总数
            },
            loading: true
        }
    },
    mounted() {
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$$message.success(res.msg);
            }
        });
        this.getData({
            page: this.page.currentPage,
            limit: this.page.pageSize,
        })
    },
    components: {
        paginations,
        queryInfo
    },
    methods: {
        onSearch(queryForm) {
            this.page.currentPage = 1;
            queryForm.page = this.page.currentPage;
            queryForm.limit = this.page.pageSize;
            // 表单提交
            this.getData(queryForm);
        },
        handleInfo(row) { //划拨中台数
            this.infoIsShow = true; //弹窗
            this.detail.brandName = row.brand_name; //终端类型名称
            this.detail.terminalNum = row.terminal_num; //终端数量
            this.listId = row.id; //id
        },
        infoClose() {
            this.infoIsShow = false;
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.loading = true;
            this.queryForm.page = page;
            this.queryForm.limit = this.page.pageSize;
            this.getData(this.queryForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1;
            // 获取条数发生改变的值
            this.page.pageSize = limit;
            this.loading = true;
            this.queryForm.page = this.page.currentPage;
            this.queryForm.limit = limit;
            this.getData(this.queryForm);
        },
        getData(params) {
            this.$api.post("terminal_in_out/get_terminal_in_out_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.stockTable.list = res.data.list;
                // //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scope>
.MAM_queryList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
