<template>
    <div class="merchantList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-select
                        v-model="searchForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.sn" placeholder="请输入终端SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="searchForm.merchant_name"
                        placeholder="请输入商户名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        size
                        v-model="searchForm.cerchant_type"
                        clearable
                        placeholder="请选择商户类型"
                    >
                        <el-option label="个人商户" value="1"></el-option>
                        <el-option label="企业商户" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="searchForm.status" clearable placeholder="请选择审核状态">
                        <el-option label="审核中" value="1"></el-option>
                        <el-option label="审核通过" value="2"></el-option>
                        <el-option label="审核拒绝" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="searchForm.real_name"
                        placeholder="请输入终端归属代理商姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.phone" placeholder="请输入终端归属代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="申请开始日期"
                        end-placeholder="申请结束日期"
                        value-format="yyyy-MM-dd"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-table
                :data="marchantList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- 序号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="120"
                ></el-table-column>
                <!-- 终端SN -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="sn"
                    label="终端SN"
                    min-width="120"
                ></el-table-column>
                <!-- 商户类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="cerchant_type"
                    label="商户类型"
                    min-width="120"
                >
                    <template slot-scope="scope">{{scope.row.cerchant_type == 1? '个人': '企业'}}</template>
                </el-table-column>
                <!-- 商户名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="merchant_name"
                    label="商户名称"
                    min-width="120"
                ></el-table-column>
                <!-- 终端归属代理商姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="终端归属代理商姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 终端归属代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="u_phone"
                    label="终端归属代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 商户状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="商户状态"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">审核中</span>
                        <span v-if="scope.row.status == 2">审核通过</span>
                        <span v-if="scope.row.status == 3">审核拒绝</span>
                    </template>
                </el-table-column>
                <!-- 申请时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="申请时间"
                    min-width="150"
                ></el-table-column>
                <!-- 审核时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="check_time"
                    label="审核时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "merchant-net",
    data() {
        return {
            searchForm: {
                brand_id: '',
                sn: '',
                merchant_name: '',
                cerchant_type: '',
                status: '',
                real_name: '',
                phone: '',
                start_time: '',
                end_time: ''
            },
            rangeDate: '',
            brandList: [],
            marchantList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true
        }
    },
    created() {

    },
    mounted() {
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$$message.success(res.msg);
            }
        });
        this.getData({
            page: 1,
            limit: 10
        })
    },
    methods: {
        onSearch(searchFrom) {
            this.currentPage = 1;
            searchFrom.page = 1;
            this.getData(searchFrom);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("merchant_net/getList", params, res => {
                let data = res.data;
                this.total = data.count; //总数
                this.marchantList = res.data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        handleInfo(row) { //详情
            this.$router.push({ path: '/merchantNetInfo', query: { id: row.id, cerchant_type: row.cerchant_type } });
        },
        pickerChange(DateArr) { //时间选择器
            this.searchForm.start_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.searchForm.end_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
}
</script>

<style scoped lang="scss">
.merchantList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>