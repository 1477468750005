<template>
    <div class="DM_activationInfo">
        <!-- 阶梯记录查询详情 -->
        <el-dialog :visible="infoIsShow" @close="handleClose" :close-on-click-modal="false">
            <el-card class="box-card" shadow="hover">
                <div class="purseTitle">
                    <div>基本信息</div>
                </div>
                <el-row class="rowTitle">
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">终端类型：</span>
                        <span>{{viewData.brand_name}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">终端SN：</span>
                        <span>{{viewData.terminal_sn}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">激活方式：</span>
                        <span>{{viewData.active_method == 1? '交易量激活':'正常激活'}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">激活代理商名称：</span>
                        <span>{{viewData.user_name}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">激活代理商账号：</span>
                        <span>{{viewData.user_number}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">激活时间：</span>
                        <span>{{viewData.active_time}}</span>
                    </el-col>
                    <el-col :sm="12" :md="12" :lg="8">
                        <span class="labels">激活奖励总金额：</span>
                        <span>{{viewData.reward_money}}</span>
                    </el-col>
                </el-row>
                <div class="purseTitle">
                    <div>平台奖励各级分润详情</div>
                </div>
                <el-table
                    :data="activationInfoTable"
                    size="mini"
                    height="200"
                    border
                    style="width: 100%"
                    v-loading="loading"
                >
                    <!-- id -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        fixed
                        prop="id"
                        label="序号"
                        min-width="80"
                    ></el-table-column>
                    <!-- 代理名称 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="proxy"
                        label="代理名称"
                        min-width="120"
                    ></el-table-column>
                    <!-- 层级 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="user_level"
                        label="层级"
                        min-width="120"
                    ></el-table-column>
                    <!-- 政策 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="reward_money"
                        label="政策"
                        min-width="150"
                    ></el-table-column>
                    <!-- 奖励分润 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="profit_money"
                        label="奖励分润"
                        min-width="120"
                    ></el-table-column>
                    <!-- 上级补贴金额 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="personal_money"
                        label="上级补贴金额"
                        min-width="120"
                    ></el-table-column>
                    <!-- 奖励总金额 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="total_award_money"
                        label="奖励总金额"
                        min-width="120"
                    ></el-table-column>
                </el-table>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "DM_activationInfo",
    props: {
        infoIsShow: {
            type: Boolean,
            default: false
        },
        detail: {
            type: Object
        }
    },
    data() {
        return {
            activationInfoTable: [], //表格
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            viewData: {}, //视图数据
            id: null, //id
            loading: true,
        }
    },
    mounted() {
        this.id = this.detail.id;
        this.getData();
    },
    methods: {
        handleClose() {
            this.$emit('infoClose')
        },
        getData() {
            this.$api.post("dataManagement/terminalActiveDetail", { id: this.detail.id }, res => {
                let data = res.data;
                if (res.code == 0) {
                    this.activationInfoTable = data.list;
                    this.viewData = data;
                    //渲染结束后取消加载
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                    //渲染结束后取消加载
                    this.loading = false;
                }
            })
        }
    }
};
</script>

<style lang="scss" scope>
.DM_activationInfo {
    .purseTitle {
        font-size: 12px;
        margin: 10px 0;
        color: #909399;
        :nth-child(1) {
            font-weight: 700;
            color: #7f7f7f;
            padding-left: 10px;
            border-left: 4px solid #909399;
        }
    }
    .rowTitle {
        color: #909399;
        font-size: 12px;
        .el-col {
            margin: 5px 0;
        }
        .labels {
            font-weight: 700;
        }
    }
}
</style>
