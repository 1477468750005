<template>
    <div class="roleList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm">
                <el-form-item>
                    <el-input clearable v-model="searchForm.role_name" placeholder="请输入角色名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.status" clearable placeholder="请选择角色状态">
                        <el-option label="启用" value="1"></el-option>
                        <el-option label="禁用" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-plus"
                    @click="handleAddRole"
                >添加角色</el-button>
            </div>
            <el-table
                :data="roleList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
                highlight-current-row
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 角色名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="name"
                    label="角色名称"
                    min-width="150"
                ></el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="创建时间"
                    min-width="150"
                ></el-table-column>
                <!-- 当前状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="当前状态"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">启用</span>
                        <span v-else>禁用</span>
                    </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    label="操作"
                    width="250"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            icon="el-icon-edit"
                            @click="handleEditRole(scope.row)"
                        >编辑</el-button>
                        <el-button
                            style="color:#F56C6C"
                            type="text"
                            size="small"
                            icon="el-icon-delete"
                            @click="handleDelRole(scope.row)"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <role-info v-if="info.isShow" :info="info" @handleClose="handleCloseRoleInfo"></role-info>
    </div>
</template>

<script>
import roleInfo from './components/roleInfo';
export default {
    name: "roleList",
    data() {
        return {
            info: {
                type: null, //1.添加 2.编辑
                row: {},
                isShow: false
            },
            roleList: [], //角色列表
            searchForm: {
                page: 1,
                limit: 10,
                role_name: '', //角色名称
                status: '', //角色状态

            },
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        })
    },
    components: {
        roleInfo
    },
    methods: {
        handleSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleAddRole() { //添加角色
            this.info.type = 1;
            this.info.isShow = true;
            this.info.row = {};
        },
        handleEditRole(row) { //编辑角色
            this.info.type = 2;
            this.info.isShow = true;
            this.info.row = row;
        },
        handleDelRole(row) { //删除角色
            let _this = this;
            this.$confirm('您确定要删除该角色吗？', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post("rule_menu/role_deleted", { id: row.id }, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1500,
                                    onClose() {
                                        done();
                                        instance.confirmButtonLoading = false;
                                        _this.getData({
                                            page: _this.currentPage,
                                            limit: _this.pageSize
                                        })
                                    }
                                })
                            } else {
                                this.$message.error(res.msg);
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleCloseRoleInfo(ref) { //关闭角色详情
            this.info.type = null;
            this.info.isShow = false;
            this.info.row = {};
            if (ref) {
                this.getData({
                    page: this.currentPage,
                    limit: this.pageSize
                })
            }
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("rule_menu/get_role_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.roleList = data.list;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
