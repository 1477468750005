<template>
    <div class>
        <el-card shadow="hover">
            <tinymce ref="editor" :value="content" @getVal="getVal" />
            <div style="margin-top:15px;">
                <el-button type="primary" @click="onSubmit" :loading="loading">保&nbsp;存</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import tinymce from '../../../components/Editor/Editor'
export default {
    name: "BS_AboutUs",
    data() {
        return {
            content: '',
            type: null,
            loading: false
        }
    },
    mounted() {
        this.getContent();//初始化
    },
    components: {
        tinymce
    },
    methods: {
        onSubmit() {
            let that = this;
            this.loading = true;
            this.$api.post('about/edit_detail', {
                content: this.content,
                type: this.type
            }, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.loading = false;
                        }
                    })
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            })
        },
        getVal(val) {
            this.content = val;
        },
        getContent() {
            this.$api.post('about/detail', {}, res => {
                if (res.code == 0) {
                    let { content, is_insert } = res.data;
                    this.content = content;
                    this.type = is_insert;
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
</style>
