<template>
    <div class="MAM_auditInfo">
        <el-dialog
            title="审核"
            width="850px"
            :visible="auditInfo.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <template v-if="repayment_type == 1">
                <div class="purseTitle">
                    <div>基本信息</div>
                </div>
                <el-row class="rowTitle">
                    <el-col :span="8">
                        <span class="labels">发起人：</span>
                        <span>{{auditInfo.info.l_real_name}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">发起人账户：</span>
                        <span>{{auditInfo.info.l_phone}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">是否抵消欠款：</span>
                        <span>{{auditInfo.info.is_offset == 1? '是':'否'}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">终端类型：</span>
                        <span>{{auditInfo.info.brand_name}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">终端数量：</span>
                        <span>{{auditInfo.info.terminal_num}}</span>
                    </el-col>
                    <div v-if="auditInfo.info.is_offset == 1">
                        <el-col :span="8">
                            <span class="labels">终端单价：</span>
                            <span>{{auditInfo.info.terminal_price}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">抵消金额：</span>
                            <span>{{auditInfo.info.offset_money}}</span>
                        </el-col>
                    </div>
                </el-row>
            </template>
            <template v-if="repayment_type == 2">
                <div class="purseTitle">
                    <div>基本信息</div>
                </div>
                <el-row class="rowTitle">
                    <el-col :span="8">
                        <span class="labels">发起人：</span>
                        <span>{{repaymentData.real_name}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">发起人账户：</span>
                        <span>{{repaymentData.phone}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">是否抵消欠款：</span>
                        <span>{{repaymentData.is_offset == 1? '是' : '否'}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">终端类型：</span>
                        <span>{{repaymentData.brand_name}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span class="labels">终端数量：</span>
                        <span>{{repaymentData.terminal_num}}</span>
                    </el-col>
                    <template v-if="repaymentData.is_offset == 1">
                        <el-col :span="8">
                            <span class="labels">贷款订单号：</span>
                            <span>{{repaymentData.loan_order_num}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">欠款总额：</span>
                            <span>{{repaymentData.loan_money}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">剩余应还金额：</span>
                            <span>{{repaymentData.lave_money}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">终端单价：</span>
                            <span>{{repaymentData.terminal_price}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">抵消金额：</span>
                            <span>{{repaymentData.offset_money}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">剩余欠款金额：</span>
                            <span>{{repaymentData.lave_offset_money}}</span>
                        </el-col>
                    </template>
                </el-row>
                <template
                    v-if="repaymentData.is_offset == 1 && Number(repaymentData.lave_offset_money) > 0"
                >
                    <div class="purseTitle">
                        <div>剩余欠款</div>
                    </div>
                    <el-row class="rowTitle">
                        <el-col :span="8">
                            <span class="labels">处理方式：</span>
                            <span v-show="repaymentData.processing_method == 1">货款调整</span>
                            <span v-show="repaymentData.processing_method == 2">线下支付</span>
                        </el-col>
                        <div v-show="repaymentData.processing_method == 1">
                            <el-col :span="8">
                                <span class="labels">货款期数：</span>
                                <span>{{repaymentData.loan_period}}期</span>
                            </el-col>
                            <el-col :span="8">
                                <span class="labels">每期应还：</span>
                                <span>{{repaymentData.every_period_money}}</span>
                            </el-col>
                        </div>
                    </el-row>
                </template>
            </template>
            <div class="purseTitle">
                <div>终端明细</div>
            </div>
            <el-table
                :data="returnList"
                size="mini"
                border
                ref="returnList"
                max-height="215px"
                style="width: 100%"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="id"
                    label="序号"
                    min-width="120"
                ></el-table-column>
                <!-- Sn -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="sn"
                    label="SN码"
                    min-width="120"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                small
                layout="prev, pager, next"
                @current-change="getCurrent"
                :total="page.total"
            ></el-pagination>
            <el-button
                size="small"
                type="primary"
                style="font-size: 12px;"
                :loading="submitLoading"
                @click="onSubmit"
            >审核通过</el-button>
            <el-button
                size="small"
                type="primary"
                style="font-size: 12px;"
                :loading="submitLoading"
                @click="openConfirm"
            >审核拒绝</el-button>
            <el-dialog
                width="500px"
                title="拒绝原因"
                :visible.sync="reasonIsShow"
                :close-on-click-modal="false"
                append-to-body
            >
                <el-input
                    type="textarea"
                    placeholder="请输入拒绝原因"
                    style="font-size: 12px;"
                    v-model="reason"
                ></el-input>
                <el-button
                    size="small"
                    type="primary"
                    style="font-size: 12px; margin-top:10px"
                    :loading="confirmLoading"
                    @click="onRefuse"
                >确 认</el-button>
            </el-dialog>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "MAM_auditInfo",
    props: {
        auditInfo: {
            type: Object
        }
    },
    data() {
        return {
            repayment_type: '',
            repaymentData: {},
            returnList: [],
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            reason: '',
            reasonIsShow: false,
            loading: false,
            submitLoading: false,
            confirmLoading: false
        };
    },
    mounted() {
        this.repayment_type = sessionStorage.getItem('repayment_type');
        if (this.repayment_type == 2) {
            this.$api.post('terminal_return/terminal_return_detail', { id: this.auditInfo.info.id }, res => {
                if (res.code == 0) {
                    this.repaymentData = res.data;
                }
            });
        }
        this.getData({
            page: 1,
            limit: 10,
            return_log_id: this.auditInfo.info.id
        });
    },
    methods: {
        onSubmit() { //审核通过
            let that = this;
            this.submitLoading = true;
            this.$api.post('terminal_return/terminal_return_audit', {
                return_log_id: this.auditInfo.info.id,
                type: 2
            }, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.handleClose(1);
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                    this.submitLoading = false;
                }
            })
        },
        openConfirm() { //审核拒绝弹窗
            this.reasonIsShow = true;
        },
        onRefuse() {
            let that = this;
            this.submitLoading = true;
            this.confirmLoading = true;
            this.$api.post('terminal_return/terminal_return_audit', {
                type: 3,
                reason: this.reason,
                return_log_id: this.auditInfo.info.id,
            }, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() {
                            that.handleClose(1);
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                    this.submitLoading = false;
                    this.confirmLoading = false;
                }
            })
        },
        handleClose(ref) { //关闭弹窗
            this.$emit('auditClose', ref);
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.getData({
                page: this.page.currentPage,
                limit: this.page.pageSize,
                return_log_id: this.auditInfo.info.id,
                terminal_sn: this.terminal_sn
            });
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post('terminal_return/get_log_terminal', params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.returnList = data.list; //列表
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style scoped lang="scss">
.MAM_auditInfo {
    font-size: 12px;
    .el-input__inner,
    .tips {
        font-size: 12px;
    }
    .rowTitle {
        color: #909399;
        font-size: 12px;
        .el-col {
            margin: 5px 0;
        }
        .labels {
            font-weight: 700;
        }
    }
    .purseTitle {
        font-size: 12px;
        margin: 10px 0;
        color: #909399;
        :nth-child(1) {
            font-weight: 700;
            color: #7f7f7f;
            padding-left: 10px;
            border-left: 4px solid #909399;
        }
    }
    .el-form-item {
        margin-bottom: 5px;
    }

    ::v-deep .el-form-item__label {
        padding: 0;
        font-size: 12px;
    }
    .titleTip {
        padding: 10px 10px;
        background-color: #ecf8ff;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #50bfff;
        margin: 10px 0;
    }
    .title p {
        font-size: 12px;
        :first-child {
            font-weight: 700;
        }
    }
}
</style>