<template>
    <div class="setRepayment">
        <el-dialog
            ref="setRepayment"
            :visible="setRepaymentIsShow"
            @close="handleClose"
            :close-on-click-modal="false"
            :title="operations"
            width="45%"
        >
            <el-card
                class="box-card"
                shadow="hover"
                v-loading="dialogLoading"
                element-loading-text="拼命加载中"
            >
                <el-form :model="setRepaymentForm" label-position="left">
                    <el-form-item label="代理商姓名：">
                        <span style="font-size:12px">{{info.name}}</span>
                    </el-form-item>
                    <el-form-item label="交易分润扣除（%）：">
                        <el-input
                            size
                            clearable
                            v-model="setRepaymentForm.profit"
                            :placeholder="`比例区间${resData.profit_start}~100`"
                            :disabled="info.operation ==1"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="激活奖励扣除（%）：">
                        <el-input
                            size
                            clearable
                            v-model="setRepaymentForm.active"
                            :placeholder="`比例区间${resData.active_start}~100`"
                            :disabled="info.operation ==1"
                        ></el-input>
                    </el-form-item>
                    <el-form-item style="margin-top:20px">
                        <el-button
                            v-if="info.operation != 1"
                            type="primary"
                            :loading="loading"
                            style="font-size: 12px;"
                            @click="submit(setRepaymentForm)"
                        >确认添加</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "UM_setRepayment",
    props: {
        setRepaymentIsShow: {
            type: Boolean,
            default: false
        },
        info: {
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            dialogLoading: true,
            setRepaymentForm: {
                user_id: null, //代理商id
                active: '', //激活
                profit: ''   //分润
            },
            resData: {}
        }
    },
    mounted() {
        this.setRepaymentForm.user_id = this.info.id; //初始化id
        this.$api.post('user/getArrearsConfigInfo', { user_id: this.info.id }, res => {
            let data = res.data;
            if (res.code == 0) {
                this.resData = data;
                if (this.info.operation != 3) { //不为配置状态回显
                    let { active, profit } = data;
                    this.setRepaymentForm.active = active; //激活
                    this.setRepaymentForm.profit = profit; //分润
                }
                this.dialogLoading = false; //关闭加载
            } else {
                this.$message.error(res.msg);
                this.dialogLoading = false; //关闭加载
            }

        });
    },
    methods: {
        handleClose() { //关闭配置
            this.$emit('configClose');
        },
        submit(reqData) {
            let that = this;
            this.loading = true;
            this.$api.post('user/saveArrearsConfig', reqData, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.handleClose(1);
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            })
        }
    },
    computed: {
        operations() {
            let operation = '';
            switch (this.info.operation) {
                case 1:
                    operation = '查看';
                    break;
                case 2:
                    operation = "编辑";
                    break;
                default:
                    operation = "配置";
                    break;
            }
            return operation;
        }
    }
}
</script>

<style scoped lang="scss">
.setRepayment {
    ::v-deep .el-form-item__label {
        font-size: 12px;
        font-weight: 700;
    }
}
</style>
