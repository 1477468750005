<template>
    <div class>
        <el-card class="box-card" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
            </div>
            <el-form
                :inline="true"
                :rules="rules"
                :model="agentForm"
                ref="agentForm"
                label-position="right"
                label-width="140px"
            >
                <!-- 基本信息 -->
                <template>
                    <div class="m-title">基本信息</div>
                    <el-form-item label="代理商角色" prop="role_id">
                        <el-select v-model="agentForm.role_id" placeholder="请选择代理角色">
                            <el-option
                                v-for="item in roleList"
                                :label="item.name"
                                :value="item.id"
                                :key="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="代理商名称" prop="agent_name">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.agent_name"
                            placeholder="请输入代理商名称"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="统一社会信用代码" prop="credit_code">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.credit_code"
                            placeholder="请输入统一社会信用代码"
                        ></el-input>
                    </el-form-item>
                    <br />
                    <el-form-item label="营业执照有效期" prop="bus_license_valid_sdate">
                        <el-date-picker
                            v-model="licenseRange"
                            type="daterange"
                            :clearable="false"
                            :disabled="isSelectBus"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            @change="handleLicenseChange"
                        ></el-date-picker>
                        <span style="margin-left:10px">
                            <el-checkbox
                                :true-label="1"
                                :false-label="0"
                                :disabled="agentForm.bus_license_valid_sdate == ''"
                                @change="handleBusCheckboxChange"
                                v-model="agentForm.is_license_long_time"
                            >长期</el-checkbox>
                        </span>
                    </el-form-item>
                    <br />
                    <el-form-item label="法人姓名" prop="legal_person">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.legal_person"
                            placeholder="请输入法人姓名"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="法人手机号" prop="legal_tel">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.legal_tel"
                            placeholder="请输入法人手机号"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="法人证件类型" prop="card_type">
                        <el-select v-model="agentForm.card_type" placeholder="请选择证件类型">
                            <el-option label="身份证" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="证件号" prop="id_card">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.id_card"
                            placeholder="请输入证件号"
                        ></el-input>
                    </el-form-item>
                    <br />
                    <el-form-item label="证件有效期" prop="card_valid_sdate">
                        <el-date-picker
                            v-model="cardRange"
                            type="daterange"
                            :clearable="false"
                            range-separator="至"
                            :disabled="isSelect"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            @change="handleCardChange"
                        ></el-date-picker>
                        <span style="margin-left:10px">
                            <el-checkbox
                                :true-label="1"
                                :false-label="0"
                                :disabled="agentForm.card_valid_sdate == ''"
                                @change="handleCheckboxChange"
                                v-model="agentForm.is_long_time"
                            >长期</el-checkbox>
                        </span>
                    </el-form-item>
                    <br />
                    <el-form-item label="联系人" prop="contact_name">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.contact_name"
                            placeholder="请输入联系人"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="contact_tel">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.contact_tel"
                            placeholder="请输入联系电话"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="联系邮箱" prop="contact_email">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.contact_email"
                            placeholder="请输入联系邮箱"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="联系地址" prop="contact_addr">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.contact_addr"
                            placeholder="请输入联系地址"
                        ></el-input>
                    </el-form-item>
                </template>
                <!-- 结算信息 -->
                <template>
                    <div class="m-title">结算信息</div>
                    <el-form-item label="银行账户名称" prop="account_name">
                        <el-input
                            disabled
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.account_name"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="账户类型" prop="account_type">
                        <el-select v-model="agentForm.account_type" placeholder="请选择账户类型">
                            <el-option label="对私" value="1"></el-option>
                            <!-- <el-option label="对公" value="2"></el-option> -->
                        </el-select>
                    </el-form-item>
                    <el-form-item label="银行名称" prop="bank_id">
                        <el-select
                            v-model="agentForm.bank_id"
                            filterable
                            @change="handleBankChange"
                            placeholder="请选择银行名称"
                        >
                            <el-option
                                v-for="item in bankList"
                                :label="item.bank_name"
                                :value="item.id"
                                :key="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="银行归属地区" prop="bank_prov">
                        <el-cascader
                            separator="-"
                            filterable
                            :disabled="agentForm.bank_id == ''"
                            v-model="areaTempArr"
                            :options="areaList"
                            :props="cascaderProps"
                            placeholder="请选择银行归属地区"
                            @change="handleAreaChange"
                        ></el-cascader>
                    </el-form-item>-->
                    <!-- <el-form-item label="结算支行名称" prop="branch_bank_id">
                        <el-select
                            
                            v-model="agentForm.branch_bank_id"
                            filterable
                            :disabled="agentForm.bank_prov == ''"
                            @change="handleBranchChange"
                            placeholder="请选择结算支行名称"
                        >
                            <el-option
                                v-for="item in branchList"
                                :label="item.bank_branch"
                                :value="item.id"
                                :key="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>-->
                    <!-- <el-form-item label="联行号" prop="line_num">
                        <el-input
                            
                            disabled
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.line_num"
                            placeholder="请输入联行号"
                        ></el-input>
                    </el-form-item>-->
                    <el-form-item label="银行账号" prop="bank_code">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.bank_code"
                            placeholder="请输入银行账号"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        v-if="agentForm.account_type == 1"
                        label="银行卡预留手机"
                        prop="bankcard_phone"
                    >
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.bankcard_phone"
                            placeholder="请输入银行卡预留手机"
                        ></el-input>
                    </el-form-item>
                </template>
                <!-- 资质信息 -->
                <template>
                    <div class="m-title">资质信息</div>
                    <div v-loading="imgLoading" element-loading-text="图片上传中,请稍等....">
                        <el-form-item label="营业执照" prop="bus_license_img">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :show-file-list="false"
                                :before-upload="handleBeforeUpload"
                                :on-progress="handleImgLoading"
                                :on-success="handleBusinessLicenseImg"
                            >
                                <img
                                    v-if="agentForm.bus_license_img != ''"
                                    :src="agentForm.bus_license_img_path"
                                    class="avatar"
                                />
                                <img
                                    v-else
                                    :src="require('../../../assets/images/permit3x.png')"
                                    class="avatar"
                                />
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="开户许可证" prop="opening_permit_img">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :show-file-list="false"
                                :before-upload="handleBeforeUpload"
                                :on-progress="handleImgLoading"
                                :on-success="handleOpenAccountImg"
                            >
                                <img
                                    v-if="agentForm.opening_permit_img_path != ''"
                                    :src="agentForm.opening_permit_img_path"
                                    class="avatar"
                                />
                                <img
                                    v-else
                                    :src="require('../../../assets/images/other3x.png')"
                                    class="avatar"
                                />
                            </el-upload>
                        </el-form-item>
                        <br />
                        <el-form-item label="法人身份证正面照" prop="card_positive_img">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :show-file-list="false"
                                :before-upload="handleBeforeUpload"
                                :on-progress="handleImgLoading"
                                :on-success="handleFrontIdCardImg"
                            >
                                <img
                                    v-if="agentForm.card_positive_img_path != ''"
                                    :src="agentForm.card_positive_img_path"
                                    class="avatar"
                                />
                                <img
                                    v-else
                                    :src="require('../../../assets/images/idcard_f3x.png')"
                                    class="avatar"
                                />
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="法人身份证反面照" prop="card_negative_img">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :show-file-list="false"
                                :before-upload="handleBeforeUpload"
                                :on-progress="handleImgLoading"
                                :on-success="handleBackIdCardImg"
                            >
                                <img
                                    v-if="agentForm.card_negative_img_path != ''"
                                    :src="agentForm.card_negative_img_path"
                                    class="avatar"
                                />
                                <img
                                    v-else
                                    :src="require('../../../assets/images/idcard_r3x.png')"
                                    class="avatar"
                                />
                            </el-upload>
                        </el-form-item>
                    </div>
                </template>
                <!-- 账号信息 -->
                <template>
                    <div class="m-title">账号信息</div>
                    <el-form-item label="代理商后台登录账号" prop="username">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.username"
                            placeholder="请输入代理商后台登录账号"
                        ></el-input>
                        <span style="color:red;font-size:12px">默认密码：hk123456</span>
                    </el-form-item>
                    <el-form-item label="APP登录账号(手机号)" prop="app_phone">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.app_phone"
                            placeholder="请输入APP登录账号"
                        >
                            <el-button
                                :loading="codeLoading"
                                @click="handleSendCode"
                                slot="append"
                            >{{ codeText }}</el-button>
                        </el-input>
                        <span style="color:red;font-size:12px">默认密码：hk123456</span>
                    </el-form-item>
                    <el-form-item label="验证码" prop="check_code">
                        <el-input
                            suffix-icon="el-icon-aux"
                            v-model="agentForm.check_code"
                            placeholder="请输入验证码"
                        ></el-input>
                    </el-form-item>
                    <!-- <el-form-item prop="is_direct_signature">
                        <span slot="label">
                            是否海科直签
                            <el-tooltip placement="top" effect="light">
                                <div slot="content">
                                    <div style="color:#F56C6C">
                                        <i class="el-icon-warning"></i>
                                        温馨提示：配置完成后不支持修改
                                    </div>
                                </div>
                                <el-button type="text">
                                    <i class="el-icon-question" style="font-size:15px"></i>
                                </el-button>
                            </el-tooltip>
                        </span>
                        <el-radio-group v-model="agentForm.is_direct_signature">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="2">否</el-radio>
                        </el-radio-group>
                    </el-form-item> -->
                    <el-form-item prop="exhibition_mode">
                        <span slot="label">
                            展业模式
                            <el-tooltip placement="top" effect="light">
                                <div slot="content">
                                    <div style="color:#F56C6C">
                                        <i class="el-icon-warning"></i>
                                        温馨提示：配置完成后不支持修改
                                    </div>
                                    <br />
                                    <div style="color:#909399">1、传统模式适合传统代理商进行展业。</div>
                                    <br />
                                    <div style="color:#909399">2、直营模式适合直营业务员进行展业。</div>
                                </div>
                                <el-button type="text">
                                    <i class="el-icon-question" style="font-size:15px"></i>
                                </el-button>
                            </el-tooltip>
                        </span>
                        <el-radio-group v-model="agentForm.exhibition_mode">
                            <el-radio :label="1">传统模式</el-radio>
                            <el-radio :label="2">直营模式</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item
                        label="直营运营权限"
                        prop="operation_authority"
                        v-if="agentForm.exhibition_mode == 2"
                    >
                        <el-select v-model="agentForm.operation_authority" placeholder="请选择直营运营权限">
                            <el-option label="一级" :value="1"></el-option>
                            <el-option label="二级" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </template>
            </el-form>
            <div style="margin-top:20px">
                <el-button
                    type="primary"
                    :loading="loading"
                    style="font-size: 12px;"
                    @click="handleSubmit(agentForm)"
                >保存设置</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import areaList from '../../../utils/area';
import rules from './addAgentValid';
export default {
    data() {
        return {
            licenseRange: [], // 营业执照有效期
            cardRange: [], // 身份证有效期
            uploadUrl: this.$api.uploadUrl, // 上传图片的地址
            loading: false, // 提交loading
            codeLoading: false, // 发送验证loading
            imgLoading: false, // 上传图片loading
            codeText: '发送验证码', // 验证码文字
            isSelect: false, // 根据是否选中长期来判断身份证截止日期是否可选
            isSelectBus: false, // 根据是否选中长期来判断营业执照截止日期是否可选
            roleList: [], // 角色列表
            bankList: [], // 银行列表
            bankSign: '', // 选中银行的标识
            branchList: [], // 银行支行列表
            areaList, // 省市区数据
            areaTempArr: [], // 省市区列表
            cascaderProps: { // 省市区指定属性
                value: 'areaName',
                label: 'areaName'
            },
            agentForm: {
                role_id: '', // 代理角色
                agent_name: '', // 代理名
                contact_name: '', // 联系人名
                contact_tel: '', // 联系电话
                contact_email: '', // 联系邮箱
                contact_addr: '', // 联系地址
                credit_code: '', // 营业执照信用代码
                username: '', // 后台登录名
                legal_person: '', // 法人
                legal_tel: '', // 法人手机号
                card_type: '', //法人证件类型
                bus_license_valid_sdate: '', // 营业执照开始时间
                bus_license_valid_edate: '', // 营业执照结束时间
                card_valid_sdate: '', // 身份证开始时间
                card_valid_edate: '', // 身份证结束时间
                is_long_time: 0, // 是否长期-身份证
                is_license_long_time: 0, // 是否长期-营业执照
                account_name: '', // 账户名
                bank_id: '', // 银行id
                // branch_bank_id: '', // 分行id
                // line_num: '', // 联行号
                bank_code: '', // 银行卡号
                bankcard_phone: '', // 银行卡预留手机号
                is_signature: 0, // 是否电子签章1=是，0=否 一期不做，默认否
                bus_license_img: '', // 营业执照 照片
                bus_license_img_path: '', // 营业执照 照片--展示
                opening_permit_img: '', // 开户许可照片
                opening_permit_img_path: '', // 开户许可照片--展示
                card_positive_img: '', // 身份证正面
                card_positive_img_path: '', // 身份证正面--展示
                card_negative_img: '', // 身份证反面
                card_negative_img_path: '', // 身份证反面--展示
                // bank_prov: '', // 开户省
                // bank_city: '', // 开户市
                // bank_area: '', // 开户区
                account_type: '', //账户类型
                app_phone: '', // app登录手机
                check_code: '', // 验证码
                id_card: '', // 身份证
                branch_bank: '', // 分行名
                exhibition_mode: 1, // 展业模式：1 = 传统，2 = 直营
                operation_authority: 1, // 运营权限：1级，2级
                is_direct_signature: 2 // 是否直签 1.是 2.否
            },
            rules: rules
        }
    },
    watch: {
        'agentForm.account_type'(newVal) { // 监听账户类型
            if (newVal == 1) {
                this.agentForm.account_name = this.agentForm.legal_person;
            } else {
                this.agentForm.account_name = this.agentForm.agent_name;
            }
        },
        'agentForm.legal_person'(newVal) { // 监听代理商名称--银行账户名称与代理商名称一致--对私
            this.agentForm.account_name = newVal;
        }
    },
    created() {
        this.getRole(); //获取代理商列表
        this.getBank(); //获取银行列表
    },
    mounted() {
        // this.uploadUrl = this.$api.uploadUrl;//上传文件地址
    },
    methods: {
        handleSubmit(agentForm) { //后台提交
            this.$refs.agentForm.validate(valid => {
                if (valid) {
                    this.loading = true; //防重复
                    this.$api.post('agent/add', agentForm, res => {
                        let that = this;
                        if (res.code == 0) {
                            this.$message({
                                type: 'success',
                                message: res.msg,
                                duration: 1500,
                                onClose() {
                                    that.loading = false;
                                    that.$router.back();
                                }
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.msg,
                                duration: 1500,
                                onClose() {
                                    that.loading = false;
                                }
                            });
                        }
                    })
                }
            })
        },
        handleSendCode() { // 发送验证码
            this.codeLoading = true;
            let that = this;
            let timer = null;
            let count = 60;
            timer = setInterval(() => {
                count--;
                this.codeText = count;
                if (count == 0) {
                    clearInterval(timer);
                    this.codeText = '发送验证码';
                    this.codeLoading = false;
                }
            }, 1000);
            this.$api.post('send/sms', { phone: this.agentForm.app_phone, type: 2 }, res => { //发送
                if (res.code == 0) {
                    this.$message.success(res.msg);
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 2000,
                        onClose() { //错误提示信息
                            clearInterval(timer);
                            that.codeLoading = false; //解开按钮
                            that.codeText = '发送验证码'; //重置按钮内容
                        }
                    });

                }
            })
        },
        handleAreaChange(areaArr) { // 处理银行归属地区数据
            if (areaArr.length != 0) {
                this.agentForm.bank_prov = areaArr[0];
                this.agentForm.bank_city = areaArr[1];
                this.agentForm.bank_area = areaArr[2];
                if (this.agentForm.bank_id) {
                    this.getBranch();
                }
            } else {
                this.agentForm.bank_prov = '';
                this.agentForm.bank_city = '';
                this.agentForm.bank_area = '';
            }
        },
        handleCheckboxChange(value) { // 处理是否长期
            if (value == 1) {
                this.isSelect = true;
                if (this.cardRange.length) { // 已选择有效期后进行修改
                    this.cardRange = [this.cardRange[0], '2099-01-01'];
                    this.agentForm.card_valid_edate = '2099-01-01';
                }
            } else {
                this.isSelect = false;
                if (this.cardRange.length) {
                    this.cardRange = [this.cardRange[0], this.$moment(Date.now()).format('YYYY-MM-DD')];
                    this.agentForm.card_valid_edate = '';
                }
            }
        },
        handleBusCheckboxChange(value) { // 营业执照有效期
            if (value == 1) {
                this.isSelectBus = true;
                if (this.licenseRange.length) { // 已选择有效期后进行修改
                    this.licenseRange = [this.licenseRange[0], '2099-01-01'];
                    this.agentForm.bus_license_valid_edate = '2099-01-01';
                }
            } else {
                this.isSelectBus = false;
                if (this.licenseRange.length) {
                    this.licenseRange = [this.licenseRange[0], this.$moment(Date.now()).format('YYYY-MM-DD')];
                    this.agentForm.bus_license_valid_edate = '';
                }
            }
        },
        handleBankChange(bankVal) { // 处理银行选中时获取值
            if (bankVal) {
                let bank = this.bankList.filter(item => {
                    return item.id == bankVal;
                });
                this.bankSign = bank[0].bank_symbol;
                if (this.agentForm.bank_prov) { //银行和省不为空的情况选择银行可取支行
                    this.getBranch();
                }
            } else {
                this.areaTempArr = []; // 清空银行时将归属地清空
                this.agentForm.bank_prov = '';
                this.agentForm.bank_city = '';
                this.agentForm.bank_area = '';
            }
        },
        handleBranchChange(branchVal) { // 支行选中的处理
            if (branchVal) {
                let branchLineNum = this.branchList.filter(item => {
                    return item.id == branchVal;
                });
                this.agentForm.line_num = branchLineNum[0].line_num; // 联行号
                this.agentForm.branch_bank = branchLineNum[0].bank_branch; // 支行名称
            }
        },
        handleBeforeUpload(file) {
            let isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            return isLt5M;
        },
        handleImgLoading() { // 上传文件时加载loading
            this.imgLoading = true;
        },
        handleBusinessLicenseImg(res) { // 营业执照
            this.upImg({
                res,
                path: 'bus_license_img',
                img: 'bus_license_img_path'
            });
        },
        handleOpenAccountImg(res) { // 开户许可证
            this.upImg({
                res,
                path: 'opening_permit_img',
                img: 'opening_permit_img_path'
            });
        },
        handleFrontIdCardImg(res) { // 身份证正面
            this.upImg({
                res,
                path: 'card_positive_img',
                img: 'card_positive_img_path'
            });
        },
        handleBackIdCardImg(res) { // 身份证反面
            this.upImg({
                res,
                path: 'card_negative_img',
                img: 'card_negative_img_path'
            });
        },
        getBranch() { // 获取支行
            this.$api.post('bank/branch', { bank_symbol: this.bankSign, bank_prov: this.agentForm.bank_prov }, res => {
                if (res.code == 0) {
                    this.agentForm.branch_bank_id = '';
                    this.agentForm.line_num = '';
                    this.branchList = [];
                    this.branchList = res.data.bank;
                } else {
                    this.$message.error('获取支行信息失败，请重新选择银行或归属地进行获取!');
                }
            });
        },
        getRole() { // 获取代理商角色
            this.$api.post("agent/get_role_list", {}, res => {
                if (res.code == 0) {
                    this.roleList = res.data;
                }
            })
        },
        getBank() { // 获取银行列表
            this.$api.post("bank/list", {}, res => {
                if (res.code == 0) {
                    this.bankList = res.data;
                }
            })
        },
        upImg(res) { //公用
            let resData = res.res;
            if (resData.code === 0) {
                this.agentForm[res.img] = resData.data.file_url; //展示路径
                this.agentForm[res.path] = resData.data.file_path //请求路径
            } else {
                //错误提示
                this.$message.error(res.msg);
            }
            this.imgLoading = false;
        },
        handleLicenseChange(dateArr) { // 营业执照有效期处理
            if (dateArr) {
                this.agentForm.bus_license_valid_sdate = dateArr[0];
                this.agentForm.bus_license_valid_edate = dateArr[1];
            }
        },
        handleCardChange(dateArr) { // 身份证有效期处理
            if (dateArr) {
                this.agentForm.card_valid_sdate = dateArr[0];
                this.agentForm.card_valid_edate = dateArr[1];
            }
        }
    }
}
</script>

<style scoped lang="scss">
.el-form-item {
    margin-bottom: 22px;
}
.m-title {
    color: #303133;
    font-size: 14px;
    font-weight: 700;
    padding-left: 10px;
    margin: 10px 0;
    border-left: 4px solid #409eff;
}
.line {
    text-align: center;
}
::v-deep .el-input-group__append {
    color: #ffffff;
    font-size: 12px;
    background-color: #409eff;
}
.el-form-item__content .el-input-group {
    vertical-align: middle;
}
.avatar-uploader {
    width: 200px;
    height: 130px;
    border-radius: 10px;
    overflow: hidden;
}
::v-deep .avatar-uploader .el-upload {
    width: 100%;
    height: 100%;
}
.avatar {
    width: 100%;
    height: 100%;
}
</style>
