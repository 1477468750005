<template>
    <div class="messageList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="messageForm" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="messageForm.phone" placeholder="请输入手机号码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        size
                        v-model="messageForm.code_type"
                        clearable
                        placeholder="请选择验证码类型"
                    >
                        <el-option label="登录" value="1"></el-option>
                        <el-option label="注册" value="2"></el-option>
                        <el-option label="找回密码" value="3"></el-option>
                        <el-option label="更改手机号(原手机号)" value="4"></el-option>
                        <el-option label="更改手机号(新手机号)" value="5"></el-option>
                        <el-option label="银行卡验证" value="6"></el-option>
                        <el-option label="商户入网" value="7"></el-option>
                        <el-option label="添加代理" value="8"></el-option>
                        <el-option label="添加机构" value="10"></el-option>
                        <el-option label="开通代理商后台" value="11"></el-option>
                        <el-option label="重置代理商密码" value="12"></el-option>
                        <el-option label="重置机构密码" value="13"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="发送开始日期"
                        end-placeholder="发送结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(messageForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- 占位 -->
            <!-- <div style="margin-bottom: 20px;"> -->
            <!-- <el-button size="small" type="primary" icon="el-icon-download" @click>数据导出</el-button> -->
            <!-- </div> -->
            <el-table
                :data="messageTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="100"
                ></el-table-column>
                <!-- 手机号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="手机号"
                    min-width="120"
                ></el-table-column>
                <!-- 验证码类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="sub_type"
                    label="验证码类型"
                    min-width="120"
                    :formatter="formatType"
                ></el-table-column>
                <!-- 验证码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="code"
                    label="验证码"
                    min-width="150"
                ></el-table-column>
                <!-- 发送时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="发送时间"
                    min-width="150"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
export default {
    name: "UM_messageList",
    data() {
        return {
            search: '',
            rangeDate: '',
            messageForm: {
                phone: '', //代理商账号
                code_type: '', //验证码类型
                start_time: '', //代理商真实姓名
                end_time: '' //发送时间
            },
            messageTable: [],
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true
        }
    },
    mounted() {
        this.getData( //初始化
            {
                page: 1,
                limit: this.page.pageSize
            }
        );
    },
    components: {
        paginations
    },
    methods: {
        onSearch(messageForm) { //搜索
            this.page.currentPage = 1; //重置分页
            let searchData = this._.cloneDeep(messageForm);
            searchData.page = this.page.currentPage;
            searchData.limit = this.page.pageSize;
            this.getData(searchData);
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            let searchData = this._.cloneDeep(this.messageForm);
            searchData.page = this.page.currentPage;
            searchData.limit = this.page.pageSize;
            this.getData(searchData);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            let searchData = this._.cloneDeep(this.messageForm);
            searchData.page = this.page.currentPage;
            searchData.limit = limit;
            this.getData(searchData);
        },
        getData(params) {
            this.$api.post("user/getSmsLogList", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.messageTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        formatType(row) {
            let sub_type;
            switch (row.sub_type) {
                case 1:
                    sub_type = '登录'
                    break;
                case 2:
                    sub_type = '注册'
                    break;
                case 3:
                    sub_type = '找回密码'
                    break;
                case 4:
                    sub_type = '更改手机号(原手机号)'
                    break;
                case 5:
                    sub_type = '更改手机号(新手机号)'
                    break;
                case 6:
                    sub_type = '银行卡验证'
                    break;
                case 7:
                    sub_type = '商户入网'
                    break;
                case 8:
                    sub_type = '添加代理'
                    break;
                case 10:
                    sub_type = '添加机构'
                    break;
                case 11:
                    sub_type = '开通代理商后台'
                    break;
                case 12:
                    sub_type = '重置代理商密码'
                    break;
                case 13:
                    sub_type = '重置机构密码'
                    break;
            }
            return sub_type;
        },
        pickerChange(DateArr) { //时间选择器
            this.messageForm.start_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.messageForm.end_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
};
</script>

<style lang="scss" scope>
.messageList {
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
