<template>
    <div class="userList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm">
                <el-form-item>
                    <el-input clearable v-model="searchForm.agent_name" placeholder="请输入一级代理姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.agent_phone" placeholder="请输入一级代理账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.agent_code" placeholder="请输入代理商编号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.real_name" placeholder="请输入代理商真实姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.phone" placeholder="请输入代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.auth_status" clearable placeholder="请选择实名认证状态">
                        <el-option label="已认证" value="1"></el-option>
                        <el-option label="未认证" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input
                        clearable
                        v-model="searchForm.parent_real_name"
                        placeholder="请输入推荐人真实姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        clearable
                        v-model="searchForm.parent_real_phone"
                        placeholder="请输入推荐人手机号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.level" placeholder="请输入层级"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="searchForm.team_classification"
                        clearable
                        placeholder="请选择所属分类"
                    >
                        <el-option
                            v-for="item in teamList"
                            :label="item.name"
                            :value="item.type"
                            :key="item.type"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="注册开始日期"
                        end-placeholder="注册结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="pickerChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div class="add-tools">
                <!-- <div>
                    <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-circle-plus"
                        @click="handleAddAgent"
                    >添加签约代理</el-button>
                </div>-->
                <div class="rm-word-aux">
                    <el-popover
                        placement="top-start"
                        width="200"
                        trigger="hover"
                        :content="loginUrl"
                    >
                        <el-button
                            slot="reference"
                            size="small"
                            type="primary"
                            icon="el-icon-question"
                        >代理商登录地址</el-button>
                    </el-popover>
                </div>
            </div>
            <el-table :data="userTable" size="mini" border style="width: 100%" v-loading="loading">
                <!-- 代理商ID -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="代理商ID"
                    min-width="80"
                ></el-table-column>
                <!-- 代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_code"
                    label="代理商编号"
                    min-width="200"
                ></el-table-column>
                <!-- 代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 真实姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="真实姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 层级-字段名待修改 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="level"
                    label="层级"
                    min-width="120"
                ></el-table-column>
                <!-- 邀请码 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="code"
                    label="邀请码"
                    min-width="120"
                ></el-table-column>
                <!-- 代理后台登录账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="username"
                    label="代理后台登录账号"
                    min-width="120"
                ></el-table-column>
                <!-- 昵称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="nickname"
                    label="昵称"
                    min-width="120"
                ></el-table-column>
                <!-- 实名认证 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="auth_status"
                    label="实名认证"
                    min-width="120"
                    :formatter="authStatus"
                ></el-table-column>
                <!-- 推荐人姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="parent_name"
                    label="推荐人姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 推荐人账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="parent_phone"
                    label="推荐人账号"
                    min-width="120"
                ></el-table-column>
                <!-- 一级代理名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_name"
                    label="一级代理名称"
                    min-width="120"
                ></el-table-column>
                <!-- 一级代理账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="agent_phone"
                    label="一级代理账号"
                    min-width="120"
                ></el-table-column>
                <!-- 所属分类 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="team_classification"
                    label="所属分类"
                    min-width="120"
                ></el-table-column>
                <!-- 是否日结 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="clearing_type"
                    label="是否日结"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.clearing_type == 1">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>-->
                <!-- 注册时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="注册时间"
                    min-width="150"
                ></el-table-column>
                <!-- 最近登陆 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="login_last_time"
                    label="最近登陆"
                    min-width="150"
                ></el-table-column>
                <el-table-column
                    header-align="center"
                    align="center"
                    min-width="150"
                    prop="is_unbind"
                    label="解绑权限"
                >
                    <template #default="{row}">
                        <el-switch
                            class="switchStyle"
                            v-model="row.is_unbind"
                            active-text="启用"
                            :active-value="1"
                            inactive-text="禁用"
                            :inactive-value="2"
                            @change="handleUnBindSwitchChange(row)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="200"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">详情</el-button>
                        <el-button @click="BlockedDialog(scope.row)" type="text" size="small">冻结</el-button>
                        <!-- <el-button
                            @click="handleCheckCost(scope.row)"
                            type="text"
                            size="small"
                        >查看手续费</el-button>-->
                        <el-button
                            v-if="scope.row.is_open_agent_account == 0"
                            @click="handleOpenAgentCMS(scope.row)"
                            type="text"
                            size="small"
                        >开通代理商后台</el-button>
                        <el-button
                            v-if="scope.row.is_open_agent_account == 1"
                            @click="handleResetPassword(scope.row)"
                            type="text"
                            size="small"
                        >重置后台登录密码</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <!-- 添加代理 -->
        <add-agent v-if="agentIsShow" :agentIsShow="agentIsShow" @agentClose="agentClose"></add-agent>
        <!-- 冻结代理商 -->
        <blocked-user
            v-if="blockedIsShow"
            :blockedIsShow="blockedIsShow"
            :userId="userId"
            @blockedClose="blockedClose"
        ></blocked-user>
        <!-- 查看手续费 -->
        <check-cost v-if="costInfo.isShow" :info="costInfo" @infoClose="handleCheckCostClose"></check-cost>
    </div>
</template>

<script>
import addAgent from './UM_addAgent';
import blockedUser from './UM_blockedUser';
import checkCost from '../components/checkCost';
export default {
    name: "UM_userList",
    data() {
        return {
            loginUrl: '', // 后台登录地址
            costInfo: { // 查看手续费
                isShow: false,
                row: {}
            },
            teamList: [],
            agentIsShow: false,
            blockedIsShow: false,
            userId: null,
            rangeDate: '',
            searchForm: {
                page: 1,
                limit: 10,
                agent_name: '', //一级代理姓名
                agent_phone: '',    //一级代理账号
                real_name: '',   //代理商真实姓名
                phone: '',   //代理商账号
                auth_status: '', //实名认证状态
                parent_real_name: '', // 推荐人真实姓名
                parent_real_phone: '', //推荐人手机号
                level: '', //层级字段
                team_classification: '', //所属分类
                create_start_time: '', //代理商注册开始时间
                create_end_time: '' //代理商注册结束时间
            },
            userTable: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false
        }
    },
    mounted() {
        this.getTeam();//获取所属分类
        this.getData({
            page: 1,
            limit: 10
        });
    },
    components: {
        addAgent, // 添加代理
        blockedUser, //冻结
        checkCost // 查看手续费
    },
    methods: {
        handleSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        addAgentDialog() { //添加代理弹窗
            this.agentIsShow = true;
        },
        BlockedDialog(row) { //冻结弹窗
            this.userId = row.id; //代理商id
            this.blockedIsShow = true;
        },
        handleInfo(row) { //详情
            this.$router.push({ path: '/userInfo', query: { id: row.id } });
        },
        handleCheckCost(row) { // 查看手续费弹窗
            this.costInfo.isShow = true;
            this.costInfo.row = row;
        },
        handleCheckCostClose(ref) { // 关闭查看手续费弹窗
            this.costInfo.isShow = false;
            this.costInfo.row = {};
            if (ref) {
                this.getData(this.searchForm);
            }
        },
        handleUnBindSwitchChange(row, ) { // 处理解绑权限开关
            let tips = '';
            if (row.status == 1) {
                tips = '请确认是否启用！';
            } else {
                tips = '请确认是否禁用！';
            }
            this.$confirm(tips, '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    // let _this = this;
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('user/setting_user_unbind', { user_id: row.id, status: row.is_unbind }, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                    }
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg,
                                    duration: 1500,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                        if (row.is_unbind == 1) {
                                            row.is_unbind = 2;
                                        } else {
                                            row.is_unbind = 1;
                                        }
                                    }
                                });
                            }
                        })
                    } else {
                        done();
                        if (row.is_unbind == 1) {
                            row.is_unbind = 2;
                        } else {
                            row.is_unbind = 1;
                        }
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleOpenAgentCMS(row) {
            let elm = this.$createElement;
            this.$msgbox({
                title: '提示',
                // '代理商开通后台管理系统后可登陆该系统进行数据查询，请确认是否为该代理开通？'
                message: elm('div', null, [
                    elm('p', { style: 'color: #909399;font-size:14px' }, '代理商开通后台管理系统后可登陆该系统进行数据查询，请确认是否为该代理开通？'),
                    elm('p', { style: 'color: #F56C6C;font-size:12px' }, `默认后台登录账号：${row.code}`),
                    elm('p', { style: 'color: #F56C6C;font-size:12px' }, `默认后台登录密码：hk123456`)
                ]),
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                showCancelButton: true,
                type: 'warning',
                center: false,
                beforeClose: (action, instance, done) => {
                    let _this = this;
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('agent/open_agent_account', { username: row.code, user_id: row.id }, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                        _this.getData({
                                            page: _this.currentPage,
                                            limit: _this.pageSize
                                        });
                                    }
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg
                                });
                                done();
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {
            }).catch(() => {
            });
        },
        handleResetPassword(row) { // 重置密码
            this.$confirm('密码重置后，初始密码为hk123456，请确认是否重置该代理商后台登录密码!', '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    let _this = this;
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('agent/reset_password', { agent_login_id: row.agent_login_id, user_id: row.id }, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                        _this.getData({
                                            page: _this.currentPage,
                                            limit: _this.pageSize
                                        });
                                    }
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg
                                });
                                done();
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        //分页
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //初始化数据
            this.loading = true;
            this.$api.post("user/list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.userTable = data.list;
                    this.loginUrl = data.login_address;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        getTeam() { //获取所属分类
            this.$api.post('team_classification/team_classification', {}, res => {
                if (res.code == 0) {
                    this.teamList = res.data
                }
            })
        },
        authStatus(row) { //表格内容转换
            let auth_status;
            switch (row.auth_status) {
                case 1:
                    auth_status = "已实名";
                    break;
                case 0:
                    auth_status = "未实名";
                    break;
            }
            return auth_status;
        },
        agentClose(ref) { //关闭添加代理弹窗
            this.agentIsShow = false;
            if (ref) {
                this.getData({
                    page: this.currentPage,
                    limit: this.pageSize,
                });
            }
        },
        blockedClose(ref) { //关闭冻结代理商弹窗
            this.blockedIsShow = false;
            if (ref) {
                this.getData({
                    page: this.currentPage,
                    limit: this.pageSize,
                });
            }
        },
        pickerChange(DateArr) { //时间选择器
            this.searchForm.create_start_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.searchForm.create_end_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
};
</script>

<style lang="scss" scoped>
.add-tools {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    div:last-child {
        margin-left: 15px;
    }
}
</style>
