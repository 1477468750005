<template>
    <div class="APP_informationInfo">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <el-card shadow="hover" style="min-width:500px">
            <el-form
                :model="infoForm"
                class="demo-form-inline"
                label-position="left"
                label-width="60px"
            >
                <el-form-item label="标题：">
                    <el-input
                        clearable
                        v-model="infoForm.title"
                        placeholder="请输入标题"
                        style="width:500px"
                    ></el-input>
                    <br />
                    <div style="font-size:12px;color:#606266;">1-30位，可由汉字，字母和数字，特殊符号组成，且不能以空格开头。</div>
                </el-form-item>
                <!-- <el-form-item label="图片：">
                    <el-upload
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                    >
                        <div slot="tip" class="el-upload__tip">
                            <span>支持JPG、PNG格式，建议尺寸：1320x468</span>
                        </div>
                        <img v-if="imgPath" :src="imgPath" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>-->
                <el-form-item label="内容：">
                    <tinymce ref="editor" :value="infoForm.content" @getVal="getVal" />
                </el-form-item>
                <el-form-item label="状态：">
                    <el-radio v-model="infoForm.status" :label="1">显示</el-radio>
                    <el-radio v-model="infoForm.status" :label="0">隐藏</el-radio>
                </el-form-item>
            </el-form>
            <div style="margin-top:15px;">
                <el-button type="primary" @click="submit" :loading="loading">保&nbsp;存</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import tinymce from '../../../components/Editor/Editor';
export default {
    name: "APP_informationInfo",
    data() {
        return {
            uploadUrl: '',
            type: null, //0.添加 1.编辑
            imgPath: '',
            infoForm: {
                title: '', //标题
                image: '', //图片地址
                status: 1, //内容类型
                content: '', //内容
            },
            loading: false //按钮loading
        }
    },
    components: {
        tinymce
    },
    mounted() {
        this.uploadUrl = this.$api.uploadUrl;//上传文件地址
        let { type, id } = this.$route.query;
        this.type = type;
        if (type == 1) { //编辑
            this.$api.post('system_message/get_message_one', { id }, res => {
                if (res.code == 0) {
                    this.infoForm = res.data;
                    this.imgPath = res.data.image_path;
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    },
    methods: {
        submit() { //提交
            if (this.type == 0) {
                this.saveInformation('system_message/add_message', this.infoForm);
            } else {
                let reqData = this._.omit(this.infoForm, ['image_path', 'path']); //剔除不需要的
                reqData.id = this.$route.query.id;
                this.saveInformation('system_message/edit_message', reqData);
            }
        },
        handleAvatarSuccess(res) { //logo
            if (res.code === 0) {
                this.imgPath = res.data.file_url; //请求路径
                this.infoForm.image = res.data.file_path //展示路径
            } else {
                //错误提示
                this.$message.error(res.msg);
            }
        },
        getVal(val) { //富文本的值
            this.infoForm.content = val;
        },
        saveInformation(url, params) { //请求
            this.loading = true;
            let that = this
            this.$api.post(url, params, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1500,
                        onClose() { //关闭
                            that.$router.back();
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.APP_informationInfo {
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background-color: #fdfdfd;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 40px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }
    .avatar {
        width: 200px;
        height: 200px;
        display: block;
    }
}
</style>
