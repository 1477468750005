import { render, staticRenderFns } from "./UM_setUserRate.vue?vue&type=template&id=47181bac&scoped=true&"
import script from "./UM_setUserRate.vue?vue&type=script&lang=js&"
export * from "./UM_setUserRate.vue?vue&type=script&lang=js&"
import style0 from "./UM_setUserRate.vue?vue&type=style&index=0&id=47181bac&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47181bac",
  null
  
)

export default component.exports