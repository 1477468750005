<template>
    <div class="merchantClassList">
        <el-card class="box-card" shadow="always">
            <el-table
                :data="classifyList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <!-- ID -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="type"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 商户分类 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="name"
                    label="商户分类"
                    min-width="100"
                ></el-table-column>
                <!-- 规则定义 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="rule_definition"
                    label="规则定义"
                    min-width="300"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="状态"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">已配置</span>
                        <span v-if="scope.row.status == 2">未配置</span>
                        <span v-if="scope.row.status == 3">变更中</span>
                    </template>
                </el-table-column>
                <!-- 展示状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="show_status"
                    label="展示状态"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.show_status"
                            active-text="显示"
                            inactive-text="隐藏"
                            :active-value="1"
                            :inactive-value="2"
                            :disabled="scope.row.status == 2"
                            @change="handleStatusChange(scope.row)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <!-- 最后一次修改时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="update_time"
                    label="最后一次修改时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.status == 2 && scope.row.type != 5"
                            @click="handleAdd(scope.row)"
                            type="text"
                            size="small"
                        >配置</el-button>
                        <el-button
                            v-if="scope.row.status == 1 && scope.row.type != 5"
                            @click="handleEdit(scope.row)"
                            type="text"
                            size="small"
                        >编辑</el-button>
                        <el-popover title="变更记录" placement="left" width="400" trigger="click">
                            <div>
                                <div class="record-view">
                                    <span class="rm-label">变更前：</span>
                                    <p class="rm-word-aux">{{record.before}}</p>
                                </div>
                                <div class="record-view">
                                    <span class="rm-label">变更后：</span>
                                    <p class="rm-word-aux">{{record.after}}</p>
                                </div>
                            </div>
                            <el-button
                                slot="reference"
                                v-show="scope.row.status == 3 && scope.row.type != 5"
                                @click="handleRecord(scope.row)"
                                type="text"
                                size="small"
                            >变更记录</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <merchant-class-info v-if="info.isShow" :info="info" @infoClose="handleInfoClose"></merchant-class-info>
    </div>
</template>

<script>
import merchantClassInfo from './components/merchantClassInfo';
export default {
    data() {
        return {
            info: { //详情
                isShow: false,
                row: {}
            },
            loading: false, //加载
            classifyList: [], //列表
            record: {
                before: '', //变更前
                after: '' //变更后
            }
        }
    },
    mounted() {
        this.getList();
    },
    components: {
        merchantClassInfo
    },
    methods: {
        handleAdd(row) { //配置
            this.info.isShow = true;
            this.info.row = row;
        },
        handleEdit(row) { //编辑
            this.info.isShow = true;
            this.info.row = row;
        },
        handleRecord(row) { //变更记录
            this.$api.post('merchant_classification/get_merchant_classification_deploy_log', { id: row.id, type: row.type }, res => {
                if (res.code == 0) {
                    this.record = res.data;
                }
            })
        },
        handleInfoClose(ref) { //关闭详情
            this.info.isShow = false;
            if (ref) {
                this.getList();
            }
        },
        handleStatusChange(row) { //展示状态
            this.$api.post('merchant_classification/update_show_status', { type: row.type, show_status: row.show_status }, res => {
                if (res.code != 0) {
                    this.$message.error(res.msg);
                }
            })
        },
        getList() { // 获取列表
            this.loading = true;
            this.$api.post('merchant_classification/get_merchant_classification_deploy_list', {}, res => {
                if (res.code == 0) {
                    this.classifyList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false;
            })
        }
    }
}

</script>

<style scoped lang="scss">
.record-view {
    padding: 8px 16px;
    background-color: #f2f6fc;
    border-radius: 4px;
    border-left: 5px solid #dcdfe6;
    margin: 20px 0;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>