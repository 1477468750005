/** App管理 */
import banner from '../../views/appManagement/banner/APP_bannerList'; //banner图管理
import bannerInfo from '../../views/appManagement/banner/APP_bannerInfo'; //banner图详情
import recruit from '../../views/appManagement/recruit/APP_recruitList'; //招募背景管理
import recruitInfo from '../../views/appManagement/recruit/APP_recruitInfo'; //招募背景详情
import feedback from '../../views/appManagement/feedback/APP_feedbackList'; //意见反馈
import informationList from '../../views/appManagement/information/APP_informationList'; //消息列表
import informationInfo from '../../views/appManagement/information/APP_informationInfo'; //消息详情

export default [
    {
        path: '/banner',
        name: 'banner',
        meta: {
            title: 'banner图管理'
        },
        component: banner
    },
    {
        path: '/bannerInfo',
        name: 'bannerInfo',
        meta: {
            title: 'banner图详情'
        },
        component: bannerInfo
    },
    {
        path: '/recruit',
        name: 'recruit',
        meta: {
            title: '招募背景管理'
        },
        component: recruit
    },
    {
        path: '/recruitInfo',
        name: 'recruitInfo',
        meta: {
            title: '招募背景详情'
        },
        component: recruitInfo
    },
    {
        path: '/feedback',
        name: 'feedback',
        meta: {
            title: '意见反馈'
        },
        component: feedback
    },
    {
        path: '/informationList',
        name: 'informationList',
        meta: {
            title: '消息列表'
        },
        component: informationList
    },
    {
        path: '/informationInfo',
        name: 'informationInfo',
        meta: {
            title: '消息详情'
        },
        component: informationInfo
    }
]