<template>
    <div class>
        <el-dialog
            title="终端费率配置"
            width="90%"
            :visible="rateIsShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <!-- <el-card class="box-card">
                <span style="font-weight:700">请选择终端类型支持的交易方式</span>
                <el-form :model="rateForm" class="demo-form-inline">
                    <el-form-item label>
                        <el-checkbox
                            :indeterminate="rateForm.transtionMethods.isIndeterminate"
                            v-model="rateForm.transtionMethods.checkAll"
                            @change="handleCheckAll"
                        >全选</el-checkbox>
                        <div style="margin: 10px 0;"></div>
                        <el-checkbox-group
                            v-model="rateForm.transtionMethods.checkList"
                            @change="handleCheckListChange"
                        >
                            <el-checkbox
                                style="font-size: 12px;"
                                v-for="(item,index) in rateForm.transtionMethods.methodList"
                                :label="item.id"
                                :checked="item.is_selected != 0"
                                :key="index"
                            >{{item.name}}</el-checkbox>
                        </el-checkbox-group>
                        <el-button
                            type="primary"
                            size="medium"
                            @click="getTab"
                            style="font-size: 12px;"
                        >确认并刷新</el-button>
                    </el-form-item>
                </el-form>
            </el-card> -->
            <el-card class="box-card" style="margin-top:20px">
                <span style="font-weight:700">配置终端费率及我的底价</span>
                <el-tabs v-model="activeName" @tab-click="handleTab" v-show="isShowmyRate">
                    <el-tab-pane
                        style="font-size: 12px;"
                        :label="item.title"
                        :name="String(item.id)"
                        v-for="(item,index) in tabList"
                        :key="index"
                    >
                        <rate-table
                            v-if="isShowmyRate"
                            :TbList="item"
                            :brandId="brandId"
                            @closeDia="closeDia"
                        ></rate-table>
                    </el-tab-pane>
                </el-tabs>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import rateTable from './MAM_rateTable';
export default {
    name: 'MAM_setRate',
    props: {
        rateIsShow: {
            type: Boolean,
            default: false
        },
        brandId: {
            type: [Number, String]
        }
    },
    data() {
        return {
            rateForm: {
                transtionMethods: {
                    checkAll: false, //全选
                    checkList: [],   //被选择数据
                    methodList: [],  //默认渲染数据
                    isIndeterminate: false //全选按钮效果
                }
            },
            tabList: [],
            activeName: null,
            loading: true, //加载
            isShowmyRate: true //展示终端费率及我的底价
        }
    },
    mounted() {
        // 请求交易方式
        // let payType = [];
        // this.$api.post("brand/brand_pay_type", { brand_id: this.brandId }, res => {
        //     let thatForm = this.rateForm.transtionMethods;
        //     if (res.code === 0) {
        //         this.activeName = res.data[0].id + ''
        //         thatForm.methodList = res.data;
        //         //遍历出被选中的checkbox
        //         this._.forEach(res.data, function (item, index) {
        //             if (item.is_selected == 1) {
        //                 payType.push(item.id);
        //             }
        //         })
        //         let setRate = {
        //             pay_type: payType.join(','),
        //             brand_id: this.brandId
        //         }
        //         //请求配置终端费率及底价
        //         this.$api.post("brand/brand_rate", setRate, res => {
        //             let data = res.data
        //             if (res.code === 0) {
        //                 // 赋值给选项卡
        //                 this.tabList = data;
        //                 this.isShowmyRate = this.tabList.length == 0 ? false : true;

        //             } else {
        //                 this.$message.error(res.msg);
        //             }
        //         })
        //     } else {
        //         this.$message.error(res.msg);
        //     }
        // });
        this.getData();
    },
    components: {
        rateTable
    },
    methods: {
        handleClose() { //关闭弹窗
            this.$emit('rateClose', 1)
        },
        getTab() { //确定并刷新
            // let checkeds = this.rateForm.transtionMethods.checkList;
            let payType = this.rateForm.transtionMethods.checkList.join(',')
            //请求配置终端费率及底价
            this.$api.post("brand/brand_rate", { pay_type: payType, brand_id: this.brandId }, res => {
                let data = res.data
                if (res.code === 0) {
                    if (data.length != 0) {
                        this.activeName = res.data[0].id + ''
                    }
                    // 赋值给选项卡
                    this.tabList = data;
                    this.isShowmyRate = this.tabList.length == 0 ? false : true;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        closeDia() { //表格提交后通知刷新
            this.getData();
            this.isShowmyRate = false;
        },
        handleTab(){

        },
        //处理全选
        handleCheckAll(newVal) {
            let thatForm = this.rateForm.transtionMethods;
            // 原始methodList与checklist格式不符,计算属性后重新比对
            thatForm.checkList = newVal ? this.methodArray : [];
            thatForm.isIndeterminate = false;
        },
        handleCheckListChange(value) {
            let thatForm = this.rateForm.transtionMethods;
            thatForm.checkAll = value.length === thatForm.methodList.length;
            thatForm.isIndeterminate = value.length > 0 && value.length < thatForm.methodList.length;
        },
        getData() {
            let payType = [];
            this.$api.post("brand/brand_pay_type", { brand_id: this.brandId }, res => {
                let thatForm = this.rateForm.transtionMethods;
                if (res.code === 0) {
                    // this.activeName = res.data[0].id + ''
                    thatForm.methodList = res.data;
                    //遍历出被选中的checkbox
                    this._.forEach(res.data, item => {
                        if (item.is_selected == 1) {
                            payType.push(item.id);
                        }
                    })
                    this.activeName = payType[0] + ''
                    let setRate = {
                        pay_type: payType.join(','),
                        brand_id: this.brandId
                    }
                    //请求配置终端费率及底价
                    this.$api.post("brand/brand_rate", setRate, res => {
                        let data = res.data
                        if (res.code === 0) {
                            // 赋值给选项卡
                            this.tabList = data;
                            this.isShowmyRate = this.tabList.length == 0 ? false : true;

                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    },
    computed: {
        methodArray() { //与checkList比对
            let newArr = [];
            let oldArr = this.rateForm.transtionMethods.methodList;
            this._.forEach(oldArr, v => {
                newArr.push(v.id);
            });
            return newArr;
        }
    }
}
</script>

<style scoped lang="scss">
</style>
