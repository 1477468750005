/** 客服管理 */
import serviceApplication from '../../views/serviceManagement/serviceApplicationList'; //客服申请
import serviceRenew from '../../views/serviceManagement/serviceRenewList'; //客服续费

export default [
    {
        path: '/serviceApplication',
        name: 'serviceApplication',
        meta: {
            title: '客服申请'
        },
        component: serviceApplication
    },
    {
        path: '/serviceRenew',
        name: 'serviceRenew',
        meta: {
            title: '客服续费'
        },
        component: serviceRenew
    }
]