<template>
    <div class="APP_recruitInfo">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <el-card shadow="hover" style="min-width:500px">
            <el-form :model="infoForm" class="demo-form-inline" label-position="top">
                <el-form-item label="招募代理背景标题：">
                    <el-input clearable v-model="infoForm.title" placeholder="请输入背景标题"></el-input>
                </el-form-item>
                <el-form-item label="招募代理背景图：">
                    <el-upload
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                    >
                        <div slot="tip" class="el-upload__tip">
                            <span>支持JPG、PNG格式，建议尺寸：600*915</span>
                        </div>
                        <img v-if="imgPath" :src="imgPath" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input clearable v-model="infoForm.sort" placeholder="整数数字"></el-input>
                    <span style="font-size:12px;color:#606266">数字越小，显示顺序越靠前，请输入0-99的整数</span>
                </el-form-item>
            </el-form>
            <div style="margin-top:15px;">
                <el-button type="primary" @click="submit" :loading="loading">保&nbsp;存</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "APP_recruitInfo",
    data() {
        return {
            uploadUrl: '',
            type: null, //0.添加 1.编辑
            imgPath: '',
            infoForm: {
                title: '', //标题
                image: '', //图片地址
                sort: '' //排序
            },
            loading: false //按钮loading
        }
    },
    mounted() {
        this.uploadUrl = this.$api.uploadUrl;//上传文件地址
        let { type, id } = this.$route.query;
        this.type = type;
        if (type == 1) {
            this.$api.post('expand_background/getInfo', { id }, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.infoForm = data;
                    this.imgPath = data.image_path;
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    },
    methods: {
        submit() {
            this.loading = true;
            let reqData = this.type == 1 ? this._.pick(this.infoForm, ['id', 'title', 'image', 'sort']) : this.infoForm;
            this.$api.post('expand_background/save', reqData, res => {
                if (res.code == 0) {
                    let that = this;
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000,
                        onClose() {
                            that.$router.push('/recruit');
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            })
        },
        handleAvatarSuccess(res) { //上传图片
            if (res.code === 0) {
                this.imgPath = res.data.file_url; //请求路径
                this.infoForm.image = res.data.file_path //展示路径
            } else {
                //错误提示
                this.$message.error(res.msg);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.APP_recruitInfo {
    .el-form {
        max-width: 500px;
    }
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background-color: #fdfdfd;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 40px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }
    .avatar {
        width: 200px;
        height: 200px;
        display: block;
    }
}
</style>
