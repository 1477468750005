<template>
    <div class="APP_feedbackList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-date-picker
                        v-model="rangeDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="提出开始时间"
                        end-placeholder="提出结束时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="DateChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-table
                :data="feedbackList"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 手机号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="手机号"
                    min-width="120"
                ></el-table-column>
                <!-- 内容简介 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="content"
                    label="内容简介"
                    show-overflow-tooltip
                    min-width="120"
                ></el-table-column>
                <!-- 联系电话 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="contact_number"
                    label="联系电话"
                    min-width="120"
                ></el-table-column>
                <!-- 提出时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="提出时间"
                    min-width="150"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "APP_feedbackList",
    data() {
        return {
            feedbackList: [], //table
            rangeDate: [],
            searchForm: {
                page: 1,
                limit: 10,
                start_create_time: '', //提出开始时间
                end_create_time: '' //提出结束时间

            },
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        })
    },
    methods: {
        onSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("feedback/list", params, res => {
                let data = res.data;
                this.total = data.count; //总数
                this.feedbackList = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        DateChange(DateArr) {
            this.searchForm.start_create_time = this._.isEmpty(DateArr) ? '' : DateArr[0];
            this.searchForm.end_create_time = this._.isEmpty(DateArr) ? '' : DateArr[1];
        }
    }
};
</script>

<style lang="scss" scoped>
.APP_feedbackList {
}
</style>
