<template>
    <div class="addAgent">
        <el-dialog
            ref="agentForm"
            :visible="agentIsShow"
            @close="handleClose"
            :close-on-click-modal="false"
            width="570px"
            title="添加一级代理"
        >
            <el-card class="box-card" shadow="hover">
                <el-form :model="agentForm" label-position="top">
                    <el-form-item label="代理商昵称">
                        <el-input size clearable v-model="agentForm.nickname" placeholder="请输入代理商昵称"></el-input>
                    </el-form-item>
                    <el-form-item label="注册手机号">
                        <el-row>
                            <el-col :span="19">
                                <el-input
                                    size
                                    clearable
                                    v-model="agentForm.phone"
                                    placeholder="请输入注册手机号"
                                ></el-input>
                            </el-col>
                            <el-col :span="5">
                                <div style="min-width:100px">
                                    <el-button
                                        type="primary"
                                        style="min-width:100%;font-size: 12px;"
                                        @click="sendCode"
                                        :disabled="codeButton"
                                    >{{codeText}}</el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="验证码">
                        <el-input size clearable v-model="agentForm.code" placeholder="请输入验证码"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-top:20px">
                        <div style="color:red;font-size:12px">初始密码：hk123456</div>
                        <el-button
                            type="primary"
                            :loading="agentLoading"
                            style="font-size: 12px;"
                            @click="addAgent(agentForm)"
                        >确认添加</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "UM_addAgent",
    props: {
        agentIsShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            agentLoading: false,
            codeButton: false,
            codeText: '发送验证码',
            agentForm: {
                nickname: '',
                phone: '',
                code: ''
            },

        }
    },
    mounted() {

    },
    methods: {
        addAgent(reqData) { //添加代理
            // 添加之前先重置表单
            let that = this;
            this.agentLoading = true; //防重复
            this.$api.post('user/add_agent', reqData, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.handleClose(1); //刷新父页面
                        }
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.agentLoading = false; //防重复
                        }
                    })
                }
            })
        },
        sendCode() { //发送验证码
            this.codeButton = true;
            let that = this;
            let timer = null;
            let count = 60;
            timer = setInterval(() => {
                count--;
                this.codeText = count;
                if (count == 0) {
                    clearInterval(timer);
                    this.codeText = '发送验证码';
                    this.codeButton = false;
                }
            }, 1000);
            this.$api.post('send/sms', { phone: this.agentForm.phone, type: 2 }, res => { //发送
                if (res.code == 0) {
                    this.$message.success(res.msg);
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 2000,
                        onClose() { //错误提示信息
                            clearInterval(timer);
                            that.codeButton = false; //解开按钮
                            that.codeText = '发送验证码'; //重置按钮内容
                        }
                    });

                }
            })
        },
        handleClose(ref) {
            this.$emit('agentClose', ref)
        }
    }
}
</script>

<style scoped lang="scss">

</style>
