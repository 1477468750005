<template>
    <div class="APP_bannerList">
        <!-- banner管理 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="addBanner">添加背景图</el-button>
            </div>
            <el-table
                :data="bannerTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 标题 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="title"
                    label="标题"
                    min-width="120"
                ></el-table-column>
                <!-- 缩略图 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="image"
                    label="缩略图"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.image_path"
                            :preview-src-list="[scope.row.image_path]"
                        ></el-image>
                    </template>
                </el-table-column>
                <!-- 内容类型 -->
                <!-- <el-table-column
                    header-align="center"
                    align="center"
                    prop="type"
                    label="内容类型"
                    min-width="150"
                ></el-table-column>-->
                <!-- 排序 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="sort"
                    label="排序"
                    min-width="100"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="状态"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.status"
                            active-text="开启"
                            inactive-text="关闭"
                            :active-value="1"
                            :inactive-value="0"
                            @change="statusChange(scope.row)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="创建时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "APP_bannerList",
    data() {
        return {
            bannerTable: [],
            loading: true
        }
    },
    mounted() {
        this.getData({});
    },
    methods: {
        addBanner() { //添加
            if (this.bannerTable.length >= 5) {
                this.$message.error('最多只能添加5条数据');
            } else {
                this.$router.push({ path: '/bannerInfo', query: { type: 0 } });
                this.$route.meta.title = '添加banner图';
            }
        },
        handleEdit(row) { //编辑
            this.$router.push({ path: '/bannerInfo', query: { type: 1, id: row.id } });
            this.$route.meta.title = '编辑banner图';
        },
        infoClose(ref) { //关闭弹窗
            this.infoIsShow = false;
            if (ref) {
                this.getData({});
            }
        },
        statusChange(row) {
            this.$api.post('banner/updateStatus', { id: row.id, status: row.status }, res => {
                if (res.code == 0) {
                    this.$message.success(res.msg);
                    this.getData({});
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        getData(params) {
            this.$api.post("banner/getList", params, res => {
                let data = res.data;
                this.bannerTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.APP_bannerList {
}
</style>
