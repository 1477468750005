<template>
    <div class="FM_alipayList">
        <!-- 代理商支付宝支付记录 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="alipayForm" class="demo-form-inline">
                <el-form-item>
                    <el-input size clearable v-model="alipayForm.real_name" placeholder="请输入代理商真实姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="alipayForm.phone" placeholder="请输入代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="alipayForm.order_num" placeholder="请输入平台订单号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size
                        clearable
                        v-model="alipayForm.ali_pay_num"
                        placeholder="请输入第三方订单号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="onSearch(alipayForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button size="small" type="primary" icon="el-icon-s-promotion" @click>占位</el-button>
            </div>-->
            <el-table
                :data="alipayTable"
                size="mini"
                border
                style="width: 100%"
                v-loading="loading"
            >
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 订单号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="order_num"
                    label="订单号"
                    min-width="220"
                ></el-table-column>
                <!-- 订单类型 -->
                <el-table-column
                    header-align="center"
                    prop="order_type"
                    align="center"
                    label="订单类型"
                    min-width="120"
                ></el-table-column>
                <!-- 代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 代理商账户 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="代理商账户"
                    min-width="150"
                ></el-table-column>
                <!-- 交易金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="money"
                    label="交易金额"
                    min-width="120"
                ></el-table-column>
                <!-- 交易时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="update_time"
                    label="交易时间"
                    min-width="150"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="状态"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.status == 1? '成功': '--'}}</span>
                    </template>
                </el-table-column>
                <!-- 第三方订单号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="alipay_num"
                    label="第三方订单号"
                    min-width="220"
                ></el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <paginations
                :currentPage="page.currentPage"
                :pageSize="page.pageSize"
                :total="page.total"
                @watchCurrent="getCurrent"
                @watchPageSize="getPageSize"
            ></paginations>
        </el-card>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
export default {
    name: "FM_alipayList",
    data() {
        return {
            alipayTable: [], //表格
            alipayForm: {
                real_name: '', //代理商名称
                phone: '', //代理商账户
                order_num: '', //平台订单号
                ali_pay_num: '', //三方订单号
                page: 1,
                limit: 10
            },
            page: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            loading: true,
        }
    },
    mounted() {
        this.getData( //初始化
            {
                page: 1,
                limit: this.page.pageSize
            }
        );
    },
    components: {
        paginations
    },
    methods: {
        onSearch(alipayForm) {
            this.alipayForm.page = 1; //重置
            // 表单提交
            this.getData(alipayForm);
        },
        //分页
        getCurrent(page) {
            this.loading = true;
            // 获取当前页修改的值
            this.page.currentPage = page;
            this.alipayForm.page = this.page.currentPage;
            this.alipayForm.limit = this.page.pageSize;
            this.getData(this.alipayForm);
        },
        getPageSize(limit) {
            this.page.currentPage = 1; //重置分页
            // 获取条数发生改变的值
            this.loading = true;
            this.page.pageSize = limit;
            this.alipayForm.page = this.page.currentPage;
            this.alipayForm.limit = limit;
            this.getData(this.alipayForm);
        },
        getData(params) {
            this.$api.post("user/ali_pay_list", params, res => {
                let data = res.data;
                this.page.total = data.count; //总数
                this.alipayTable = data.list;
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="scss" scope>
.FM_alipayList {
    .realTitle {
        :nth-child(1) {
            font-size: 12px;
            font-weight: 700;
            color: #1e1e1e;
        }
        .imgParent {
            margin: 10px 0;
            // width: 500px;
            overflow: hidden;
            img {
                width: 100%;
                height: 250px;
                border-radius: 10px;
            }
        }
    }
    .el-dialog__header {
        padding: 20px 20px 0px;
    }
    .el-dialog__body {
        padding: 0px 20px 30px 20px;
    }
    .el-input__inner {
        font-size: 12px;
    }
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>
