<template>
    <div class="CloseBindRecordListDetail">
        <el-dialog
            title="终端解绑"
            :visible="bindDetail.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-card class="box-card" shadow="hover">
                <template>
                    <div class="title">终端&商户信息</div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <span class="labels">终端政策：</span>
                            <span>{{bindDetail.row.brand_name}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">终端SN：</span>
                            <span>{{bindDetail.row.terminal_sn}}</span>
                        </el-col>
                        <!-- <el-col :span="8">
                            <span class="labels">终端编号：</span>
                            <span>{{bindDetail.row.terminal_number}}</span>
                        </el-col> -->
                        <el-col :span="8">
                            <span class="labels">商户编号：</span>
                            <span>{{bindDetail.row.merchant_number}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">商户名称：</span>
                            <span>{{bindDetail.row.merchant_name}}</span>
                        </el-col>
                        <!-- <el-col :span="8">
                            <span class="labels">商户备注手机号：</span>
                            <span>{{bindDetail.row.tel}}</span>
                        </el-col> -->
                        <!-- <el-col :span="8">
                            <span class="labels">商户备注名称：</span>
                            <span>{{bindDetail.row.merchant_nickname}}</span>
                        </el-col> -->
                        <el-col :span="8">
                            <span class="labels">解绑时间：</span>
                            <span>{{bindDetail.row.create_time}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">归属代理商账号：</span>
                            <span>{{bindDetail.row.phone}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="labels">归属代理商姓名：</span>
                            <span>{{bindDetail.row.real_name}}</span>
                        </el-col>
                    </el-row>
                </template>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        bindDetail: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            terminalCollection: {},
            terminalList: [],
            closeBindNum:'',
            closeBindName:"",
            loading: false,
            btnLoading: false // 按钮
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        handleClose(ref) {
            this.$emit('detailClose', ref)
        }
    }
};
</script>

<style lang="scss" scoped>
.CloseBindRecordListDetail {
    ::v-deep .el-dialog {
        min-width: 800px;
    }
    ::v-deep .labels {
        font-size: 12px;
        font-weight: 700;
    }
    ::v-deep .note {
        font-size: 10px;
        font-weight: 700;
        color: #FF0000;
    }
    ::v-deep .title {
        margin: 10px 0;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #40b8ff;
        padding: 0 0 0 10px;
        .el-button--text {
            color: #303133;
            font-size: 13px;
            font-weight: 700;
        }
    }
    ::v-deep .el-row {
        margin: 10px 0;
        font-size: 12px;
        &:last-child {
            margin-bottom: 0;
        }
        .el-col {
            margin: 10px 0;
        }
    }
    ::v-deep .el-table .cell {
        padding: 0;
    }

    ::v-deep .el-table--small td,
    .el-table--small th {
        padding: 0;
    }
    .tableScope {
        display: flex;
        flex-direction: column;
        div {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            ::v-deep .el-input__inner {
                text-align: center;
                font-size: 12px;
            }
        }
        :not(:last-child) {
            border-bottom: 1px solid #ebeef5;
        }
        .isNull {
            background-color: #f5f7fa;
        }
        .notNull {
            background-color: none;
        }
    }
}
</style>
