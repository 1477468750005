<template>
    <div class="BS_settingInfo">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <el-card shadow="hover">
            <div class="purseTitle">
                <span>协议标题</span>
            </div>
            <el-form :inline="true" :model="infoForm" class="demo-form-inline">
                <el-form-item>
                    <el-input clearable v-model="infoForm.title" placeholder="请输入协议标题"></el-input>
                </el-form-item>
            </el-form>
            <div class="purseTitle">
                <span>协议内容</span>
            </div>
            <tinymce ref="editor" :value="infoForm.content" @onClick="onClick" @getVal="getVal" />
            <div style="margin-top:15px;">
                <el-button type="primary" @click="submit">保&nbsp;存</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import tinymce from '../../../components/Editor/Editor'
export default {
    name: "BS_settingInfo",
    data() {
        return {
            infoForm: {
                content: '',
                title: '',
                type: null
            }
        }
    },
    components: {
        tinymce
    },
    mounted() {
        this.infoForm.type = this.$route.query.type;
        this.$api.post('agreement/agreement_detail', { type: this.$route.query.type }, res => {
            if (res.code == 0) {
                let data = res.data;
                this.infoForm.title = data.title;
                this.infoForm.content = data.content;
            }
        })
    },
    methods: {
        submit() {
            this.$api.post('agreement/update_agreement', this.infoForm, res => {
                if (res.code == 0) {
                    let that = this;
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000,
                        onClose() {
                            that.$router.push('/setAgreements');
                        }
                    });
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        onClick() {
            // e, editor
            // console.log('Element clicked')
            // console.log(e)
            // execCommand方法待细看
            // editor.execCommand('mceImage')
        },
        // 清空内容
        clear() {
            this.$refs.editor.clear()
        },
        getVal(val) {
            this.infoForm.content = val;
        }
    }
}
</script>

<style scoped lang="scss">
.BS_settingInfo {
    .purseTitle {
        font-size: 13px;
        margin: 10px 0;
        color: #909399;
        :nth-child(1) {
            font-weight: 700;
            color: #7f7f7f;
            padding-left: 10px;
            border-left: 4px solid #909399;
        }
    }
    .rowTitle {
        color: #909399;
        font-size: 12px;
        .el-col {
            margin: 5px 0;
        }
        .labels {
            font-weight: 700;
        }
    }
}
</style>
