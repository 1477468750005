<template>
    <div class="serviceApplyOrder">
        <el-dialog
            width="800px"
            :title="info.type == 1?'添加角色':'编辑角色'"
            :visible="info.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-card class="box-card" shadow="always">
                <el-form :model="roleForm" label-position="left" label-width="80px">
                    <el-form-item label="角色名称：">
                        <el-input
                            maxlength="10"
                            clearable
                            v-model="roleForm.role_name"
                            placeholder="请输入角色名称"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="创建权限：" class="form-tree">
                        <div class="tree-box" v-loading="treeLoading">
                            <el-tree
                                ref="tree"
                                :data="treeData"
                                show-checkbox
                                node-key="id"
                                :default-expanded-keys="defaultChecked"
                                :default-checked-keys="defaultChecked"
                                :props="defaultProps"
                            ></el-tree>
                        </div>
                    </el-form-item>
                    <el-form-item label="当前状态：">
                        <el-radio-group v-model="roleForm.status">
                            <el-radio :label="1">启用</el-radio>
                            <el-radio :label="2">禁用</el-radio>
                        </el-radio-group>
                        <div v-if="roleForm.status == 2" style="font-size:12px;color:red">
                            <i class="el-icon-warning" style="font-size:14px;"></i>
                            禁用后，添加操作员时无法选择该角色。
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            size="medium"
                            type="primary"
                            icon="el-icon-check"
                            style="font-size: 12px;"
                            @click="handleSubmit(roleForm)"
                        >保 存 设 置</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            treeLoading: false,
            roleForm: {
                type: this.info.type, //1.添加 2.编辑
                role_name: '', //角色名称
                status: 1, //状态
                menu_id: [], //被选中的
                menu_expand: []
            },
            treeData: [], //树形图
            defaultChecked: [],//默认被选中的
            defaultProps: { //指定标识
                children: 'children',
                label: 'title'
            },
        };
    },
    mounted() {
        if (this.info.type == 1) {
            this.getTreeData({
                type: 1
            });
        } else {
            this.getTreeData({
                type: 2,
                id: this.info.row.id
            });
        }
    },
    methods: {
        handleSubmit(roleForm) { //提交
            roleForm.menu_expand = this.$refs.tree.getHalfCheckedKeys(); //半选择状态
            roleForm.menu_id = this.$refs.tree.getCheckedKeys().concat(roleForm.menu_expand); //选中的权限
            let _this = this;
            if (this.info.type == 2) {
                roleForm.id = this.info.row.id;
            }
            this.$confirm('是否确认保存该配置？', '提示', {
                confirmButtonText: '确认保存',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('rule_menu/role_create_update', roleForm, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 1500,
                                    onClose() {
                                        done();
                                        instance.confirmButtonLoading = false;
                                        _this.handleClose(1);
                                    }
                                })
                            } else {
                                this.$message.error(res.msg);
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleClose(ref) { //关闭
            this.$emit('handleClose', ref)
        },
        getTreeData(params) { //获取数据
            this.treeLoading = true;
            this.$api.post("rule_menu/get_role_info", params, res => {
                if (res.code != 0) {
                    this.$message.error(res.msg);
                } else {
                    let data = res.data;
                    if (params.type == 1) { //添加
                        this.treeData = data;
                    } else { //编辑
                        this.treeData = data.menu;
                        //如果存在半展开的key则进行比较。
                        if (data.menu_expand.length != 0) {
                            data.menu_expand.forEach(item => {
                                data.menu_id.forEach((v, i) => {
                                    if (item == v) {
                                        data.menu_id.splice(i, 1);
                                    }
                                })
                            })
                        }
                        this.defaultChecked = data.menu_id;
                        this.roleForm.menu_expand = data.menu_expand;
                        this.roleForm.role_name = data.role_name;
                        this.roleForm.status = data.status;
                    }
                }
                this.treeLoading = false;
            });
        }
    }
};
</script>

<style scoped lang="scss">
.form-tree {
    .tree-box {
        // width: 500px;
        height: 400px;
        overflow: auto;
        border-radius: 4px;
        padding: 10px;
        border: 1px solid #dcdfe6;
    }
}
::v-deep .el-tree__empty-text {
    font-size: 12px;
}
</style>