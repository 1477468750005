/** 机具管理 */
import brandManagement from '../../views/machineManagement/brandManagement/MAM_brandList'; //终端类型-管理
import brandRewardList from '../../views/machineManagement/brandManagement/MAM_brandRewardList'; //终端类型-活动
import merchantRate from '../../views/machineManagement/brandManagement/MAM_merchantRate'; //终端类型-商户费率
import risePrice from '../../views/machineManagement/brandManagement/MAM_risePrice'; //终端类型-涨价后费率
import stockManagement from '../../views/machineManagement/stockManagement/MAM_stockList'; //库存管理
import stockInOutQuery from '../../views/machineManagement/stockInOutQuery/MAM_queryList'; //出入库记录查询
import transferManagement from '../../views/machineManagement/transferManagement/MAM_transferList'; //划拨管理
import transferTerminal from '../../views/machineManagement/transferManagement/MAM_transferTerminal'; //划拨管理
import terminalReturn from '../../views/machineManagement/terminalReturn/MAM_terminalReturn'; //终端退回
import totalInventory from '../../views/machineManagement/TotalInventory'; // 总库存管理
import totalInventoryStorage from '../../views/machineManagement/components/TotalInventoryStorage'; // 入库
import CloseBindRecordList from '../../views/machineManagement/CloseBindRecordList'; // 解绑记录
import InspectionFeeList from '../../views/machineManagement/InspectionFeeList'; // 考核扣费记录

export default [{
        path: '/brand',
        name: 'brand',
        meta: {
            title: '终端类型管理'
        },
        component: brandManagement
    },
    {
        path: '/brandRewardList',
        name: 'brandRewardList',
        meta: {
            title: '营销活动'
        },
        component: brandRewardList
    },
    {
        path: '/merchantRate',
        name: 'merchantRate',
        meta: {
            title: '商户费率'
        },
        component: merchantRate
    },
    {
        path: '/risePrice',
        name: 'risePrice',
        meta: {
            title: '涨价后费率'
        },
        component: risePrice
    },
    {
        path: '/stockManagement',
        name: 'stockManagement',
        meta: {
            title: '库存管理'
        },
        component: stockManagement
    },
    {
        path: '/stockInOut',
        name: 'stockInOut',
        meta: {
            title: '出入库记录查询'
        },
        component: stockInOutQuery
    },
    {
        path: '/transfer',
        name: 'transfer',
        meta: {
            title: '划拨管理'
        },
        component: transferManagement
    },
    {
        path: '/transferTerminal',
        name: 'transferTerminal',
        meta: {
            title: '终端划拨'
        },
        component: transferTerminal
    },
    {
        path: '/terminalReturn',
        name: 'terminalReturn',
        meta: {
            title: '终端退回'
        },
        component: terminalReturn
    },
    {
        path: '/terminalInStock',
        name: 'terminalInStock',
        meta: {
            title: '总库存管理'
        },
        component: totalInventory
    },
    {
        path: '/totalInventoryStorage/:id',
        name: 'totalInventoryStorage',
        meta: {
            title: '入库'
        },
        component: totalInventoryStorage
    },
    {
        path: '/unbindLog',
        name: 'unbindLog',
        meta: {
            title: '解绑记录'
        },
        component: CloseBindRecordList
    },
    {
        path: '/terminalNactiveDecMoneyLog',
        name: 'terminalNactiveDecMoneyLog',
        meta: {
            title: '考核扣费记录'
        },
        component: InspectionFeeList
    }
]