<template>
    <div class>
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <el-card shadow="hover">
            <el-form :inline="true">
                <el-form-item>
                    <el-input clearable v-model="search" placeholder="请输入终端类型名称"></el-input>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card class="box-card" shadow="hover" style="margin-top: 20px;">
            <el-table
                :data="brandList.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                style="width: 100%"
                height="500"
                size="small"
                :header-cell-style="{
                            background:'#EBEEF5'
                        }"
            >
                <el-table-column align="center" prop="name" label="终端类型" min-width="180"></el-table-column>
                <el-table-column
                    align="center"
                    prop="is_config_rate"
                    sortable
                    label="结算成本"
                    min-width="180"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.is_config_rate == 0"
                            size="mini"
                            type="text"
                            style="color:#F56C6C"
                            @click="handleSetRate(scope.row)"
                        >配置</el-button>
                        <el-button
                            v-else
                            size="mini"
                            type="text"
                            @click="handleSetRate(scope.row)"
                        >编辑</el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="is_config_reward"
                    sortable
                    label="平台奖励"
                    min-width="180"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.is_config_reward == 0"
                            size="mini"
                            type="text"
                            style="color:#F56C6C"
                            @click="handleSetReward(scope.row)"
                        >配置</el-button>
                        <el-button
                            v-else
                            size="mini"
                            type="text"
                            @click="handleSetReward(scope.row)"
                        >编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!-- 交易费率 -->
        <user-rate
            v-if="isShowRate"
            :isShowRate="isShowRate"
            :publickData="publickData"
            @rateClose="handleRateClose"
        ></user-rate>
        <!-- 平台奖励 -->
        <user-reward
            v-if="isShowReward"
            :isShowReward="isShowReward"
            :publickData="publickData"
            @rewardClose="handleRewardClose"
        ></user-reward>
    </div>
</template>

<script>
import userRate from '../../userManagement/user/UM_setUserRate'; //费率组件
import userReward from '../../userManagement/user/UM_setUserReward'; //奖励组件
export default {
    data() {
        return {
            search: '',
            brandList: [], // 品牌列表
            isShowRate: false, //交易费率
            isShowReward: false, //平台奖励
            isShowRise: false, //平台奖励
            brandTitle: false, //终端类型政策
            baseInfo: {},
            publickData: { //父子组件共用值
                userId: this.$route.params.id,
                userName: this.$route.params.realName,
                brandId: null,
                brandName: '',
                isRise: null //是否支持涨价  0.不支持 1.支持
            },

        }
    },
    created() {
        this.getData({
            user_id: this.$route.params.id,
            is_config: 1
        });
    },
    components: {
        userRate,
        userReward
    },
    methods: {
        handleSetRate(row) { //费率
            this.publickData.brandId = row.id; //终端类型id
            this.publickData.isRise = row.is_rise_price; //是否支持涨价
            this.publickData.brandName = row.name; //终端类型名称
            // console.log(this.publickData);
            this.isShowRate = true
        },
        handleRateClose() { //关闭费率
            this.isShowRate = false;
        },
        handleSetReward(row) { //奖励
            this.publickData.brandId = row.id; //终端类型id
            this.publickData.brandName = row.name; //终端类型名称
            this.isShowReward = true;
        },
        handleRewardClose() { //关闭奖励
            this.isShowReward = false;
        },
        getData(params) { // 初始化数据
            this.$api.post('brand/brand_list', params, res => {
                if (res.code == 0) {
                    this.brandList = res.data.list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
// 滚动条
::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
    width: 4px;
}
::v-deep
    .el-table--scrollable-y
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}
::v-deep
    .el-table--scrollable-y
    .el-table__body-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}
</style>
