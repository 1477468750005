<template>
    <div class="MAM_searchResult">
        <el-dialog
            title="已选机具"
            width="500px"
            :visible="info.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-input size="mini" clearable v-model="search" placeholder="请输入SN进行搜索"></el-input>
                </el-form-item>
            </el-form>
            <el-table
                :data="info.detail.filter(data => !search || data.sn.toLowerCase().includes(search.toLowerCase()))"
                size="mini"
                style="width: 100%"
                height="400px"
                v-loading="loading"
            >
                <!-- id -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="id"
                    label="序号"
                    min-width="120"
                ></el-table-column>
                <!-- Sn -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="sn"
                    label="SN码"
                    min-width="120"
                ></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object
        }
    },
    data() {
        return {
            search: '', //搜索条件
            loading: false
        };
    },
    methods: {
        handleClose() { //关闭弹窗
            this.$emit('infoClose');
        }
    }
};
</script>

<style scoped lang="scss">
.MAM_searchResult {
    font-size: 12px;
    .el-input__inner,
    .tips {
        font-size: 12px;
    }

    .el-form-item {
        margin-bottom: 5px;
    }

    ::v-deep .el-form-item__label {
        padding: 0;
        font-size: 12px;
    }
    .titleTip {
        padding: 10px 10px;
        background-color: #ecf8ff;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #50bfff;
        margin: 10px 0;
    }
    .title p {
        font-size: 12px;
        :first-child {
            font-weight: 700;
        }
    }
}
</style>