<template>
    <div class="ProcurementOrderListDetail">
        <el-dialog
            :title="formatTitle"
            width="800px"
            :visible="detail.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-card shadow="always">
                <template>
                    <div class="title">订单信息</div>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <span class="labels">订单号：</span>
                            <span>{{detail.row.order_num}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">是否直签：</span>
                            <span>{{detail.row.is_direct_procurement}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">采购模式：</span>
                            <span>{{detail.row.procurement_model}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">代理商编号：</span>
                            <span>{{detail.row.agent_code}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">代理商名称：</span>
                            <span>{{detail.row.real_name}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">终端类型：</span>
                            <span>{{detail.row.brand_name}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">产品类型：</span>
                            <span>{{detail.row.product_type}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">采购价格：</span>
                            <span>{{detail.row.price_name}}-{{detail.row.price}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">采购数量：</span>
                            <span>{{detail.row.procurement_num}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">实付款：</span>
                            <span>{{detail.row.money}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">发货人：</span>
                            <span>{{detail.row.consignor}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">打款人：</span>
                            <span>{{detail.row.pay_user_name}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">订单状态：</span>
                            <span>{{detail.row.status_name}}</span>
                        </el-col>
                        <el-col :span="24">
                            <div class="labels">备注：</div>
                            <div style="margin-top:10px">
                                <el-input
                                    type="textarea"
                                    v-model="detail.row.remarks"
                                    :disabled="true"
                                    style="font-size:12px"
                                ></el-input>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">创建时间：</span>
                            <span>{{detail.row.create_time}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="labels">操作时间：</span>
                            <span>{{detail.row.update_time}}</span>
                        </el-col>
                        <el-col :span="24" v-if="detail.row.status == 2 || detail.row.status == 4">
                            <div class="labels">拒绝原因：</div>
                            <div style="margin-top:10px">
                                <el-input
                                    type="textarea"
                                    v-model="detail.row.reason"
                                    :disabled="true"
                                    style="font-size:12px"
                                ></el-input>
                            </div>
                        </el-col>
                    </el-row>
                </template>
                <!-- 待审核展示 -->
                <template v-if="detail.row.status == 1">
                    <template>
                        <div class="title">收货信息</div>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <span class="labels">收货人：</span>
                                <span>{{detail.row.receiver_name}}</span>
                            </el-col>
                            <el-col :span="12">
                                <span class="labels">收货人联系方式：</span>
                                <span>{{detail.row.receiver_tel}}</span>
                            </el-col>
                            <el-col :span="24">
                                <span class="labels">收货地址：</span>
                                <span>{{detail.row.address}}</span>
                            </el-col>
                        </el-row>
                    </template>
                    <el-form :model="submitForm">
                        <el-form-item>
                            <el-radio-group v-model="submitForm.type">
                                <el-radio :label="1">审核通过</el-radio>
                                <el-radio :label="2">审核拒绝</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                v-show="submitForm.type == 2"
                                type="textarea"
                                v-model="submitForm.reason"
                                style="font-size:12px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                type="primary"
                                style="font-size: 12px;"
                                @click="handleSubmit(submitForm)"
                            >保 存 提 交</el-button>
                        </el-form-item>
                    </el-form>
                </template>
                <!-- 待收货或已完成展示 -->
                <template v-if="detail.row.status == 5 || detail.row.status == 6">
                    <el-form>
                        <el-form-item>
                            <el-input size="medium" placeholder="请输入SN搜索" v-model="searchForm.sn">
                                <el-button
                                    size="medium"
                                    slot="append"
                                    type="primary"
                                    icon="el-icon-search"
                                    style="font-size: 12px;"
                                    @click="handleSearch(searchForm)"
                                >搜索</el-button>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-table
                        :data="tableList"
                        size="mini"
                        border
                        style="width: 100%"
                        v-loading="loading"
                    >
                        <!-- 序号 -->
                        <el-table-column
                            type="index"
                            header-align="center"
                            align="center"
                            fixed
                            label="序号"
                            width="100"
                            :index="handleTableIndex"
                        ></el-table-column>
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="brand_name"
                            label="终端类型"
                            min-width="200"
                        ></el-table-column>
                        <!-- SN -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="sn"
                            label="SN"
                            min-width="200"
                        ></el-table-column>
                    </el-table>
                    <!-- 分页部分 -->
                    <el-pagination
                        small
                        layout="prev, pager, next"
                        :current-page="currentPage"
                        @current-change="getCurrent"
                        :total="total"
                    ></el-pagination>
                </template>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'ProcurementOrderListDetail',
    props: {
        detail: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false,
            tableList: [],
            submitForm: {
                type: 1,
                reason: ''
            },
            searchForm: { // 初始数据
                id: this.detail.row.id,
                sn: '',
                page: 1,
                limit: 10
            },
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
        }
    },
    created() {
        let { status } = this.detail.row;
        if (status == 5 || status == 6) {
            this.getTerminal(this.searchForm);
        }
    },
    mounted() {

    },
    computed: {
        formatTitle() {
            let { status } = this.detail.row;
            if (status == 1 || status == 3) {
                return '审核';
            } else {
                return "详情";
            }
        }
    },
    methods: {
        handleSearch(searchForm) {
            searchForm.page = 1;
            this.currentPage = 1;
            this.getTerminal(searchForm);
        },
        handleClose(ref) {
            this.$emit('detailClose', ref)
        },
        handleSubmit(filed) { // 提交
            filed.id = this.detail.row.id;
            this.$confirm('是否确认提交？', '提示', {
                confirmButtonText: '确 认',
                cancelButtonText: '取 消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    let _this = this;
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('procurement/order_examine', filed, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: res.msg,
                                    duration: 2000,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                        _this.handleClose(1);
                                    }
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.msg
                                });
                                done();
                                instance.confirmButtonLoading = false;
                            }
                        })
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        getTerminal(params) { //获取详情信息
            this.loading = true;
            this.$api.post('procurement/order_terminal_list', params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.tableList = data.list;
                    this.total = data.count;
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false;
            });
        },
        //分页
        getCurrent(page) {
            // 获取当前页修改的值
            this.currentPage = page;
            this.searchForm.page = page;
            this.getTerminal(this.searchForm);
        },
        handleTableIndex(index) { // 处理序号
            return (this.currentPage - 1) * this.pageSize + index + 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.ProcurementOrderListDetail {
    ::v-deep .el-card__body {
        max-height: 600px;
        overflow: auto;
    }
    .rate-title {
        height: 40px;
        margin-bottom: 5px;
    }
    .labels {
        font-size: 12px;
        font-weight: 700;
    }
    .title {
        margin: 10px 0;
        font-size: 13px;
        font-weight: 700;
        border-left: 4px solid #40b8ff;
        padding: 0 0 0 10px;
        .el-button--text {
            color: #303133;
            font-size: 13px;
            font-weight: 700;
        }
    }
    .el-row {
        margin: 10px 0;
        font-size: 12px;
        &:last-child {
            margin-bottom: 0;
        }
        .el-col {
            margin: 10px 0;
        }
    }
}
</style>
