export default [
    {
        "code": 110000,
        "areaName": "北京市",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 110100,
                "areaName": "市辖区",
                "parent_code": 110000,
                "level": 2,
                "children": [
                    {
                        "code": 110101,
                        "areaName": "东城区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110102,
                        "areaName": "西城区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110105,
                        "areaName": "朝阳区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110106,
                        "areaName": "丰台区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110107,
                        "areaName": "石景山区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110108,
                        "areaName": "海淀区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110109,
                        "areaName": "门头沟区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110111,
                        "areaName": "房山区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110112,
                        "areaName": "通州区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110113,
                        "areaName": "顺义区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110114,
                        "areaName": "昌平区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110115,
                        "areaName": "大兴区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110116,
                        "areaName": "怀柔区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110117,
                        "areaName": "平谷区",
                        "parent_code": 110100,
                        "level": 3
                    },
                    {
                        "code": 110118,
                        "areaName": "市辖区",
                        "parent_code": 110100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 110200,
                "areaName": "县",
                "parent_code": 110000,
                "level": 2,
                "children": [
                    {
                        "code": 110228,
                        "areaName": "密云区",
                        "parent_code": 110200,
                        "level": 3
                    },
                    {
                        "code": 110229,
                        "areaName": "延庆区",
                        "parent_code": 110200,
                        "level": 3
                    },
                    {
                        "code": 110230,
                        "areaName": "县",
                        "parent_code": 110200,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 120000,
        "areaName": "天津市",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 120100,
                "areaName": "市辖区",
                "parent_code": 120000,
                "level": 2,
                "children": [
                    {
                        "code": 120101,
                        "areaName": "和平区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120102,
                        "areaName": "河东区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120103,
                        "areaName": "河西区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120104,
                        "areaName": "南开区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120105,
                        "areaName": "河北区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120106,
                        "areaName": "红桥区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120110,
                        "areaName": "东丽区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120111,
                        "areaName": "西青区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120112,
                        "areaName": "津南区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120113,
                        "areaName": "北辰区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120114,
                        "areaName": "武清区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120115,
                        "areaName": "宝坻区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120116,
                        "areaName": "滨海新区",
                        "parent_code": 120100,
                        "level": 3
                    },
                    {
                        "code": 120117,
                        "areaName": "市辖区",
                        "parent_code": 120100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 120200,
                "areaName": "县",
                "parent_code": 120000,
                "level": 2,
                "children": [
                    {
                        "code": 120221,
                        "areaName": "宁河县",
                        "parent_code": 120200,
                        "level": 3
                    },
                    {
                        "code": 120223,
                        "areaName": "静海县",
                        "parent_code": 120200,
                        "level": 3
                    },
                    {
                        "code": 120225,
                        "areaName": "蓟县",
                        "parent_code": 120200,
                        "level": 3
                    },
                    {
                        "code": 120226,
                        "areaName": "县",
                        "parent_code": 120200,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 130000,
        "areaName": "河北省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 130100,
                "areaName": "石家庄市",
                "parent_code": 130000,
                "level": 2,
                "children": [
                    {
                        "code": 130102,
                        "areaName": "长安区",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130103,
                        "areaName": "桥东区",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130104,
                        "areaName": "桥西区",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130105,
                        "areaName": "新华区",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130107,
                        "areaName": "井陉矿区",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130108,
                        "areaName": "裕华区",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130121,
                        "areaName": "井陉县",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130123,
                        "areaName": "正定县",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130124,
                        "areaName": "栾城区",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130125,
                        "areaName": "行唐县",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130126,
                        "areaName": "灵寿县",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130127,
                        "areaName": "高邑县",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130128,
                        "areaName": "深泽县",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130129,
                        "areaName": "赞皇县",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130130,
                        "areaName": "无极县",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130131,
                        "areaName": "平山县",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130132,
                        "areaName": "元氏县",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130133,
                        "areaName": "赵县",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130181,
                        "areaName": "辛集市",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130182,
                        "areaName": "藁城区",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130183,
                        "areaName": "晋州市",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130184,
                        "areaName": "新乐市",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130185,
                        "areaName": "鹿泉区",
                        "parent_code": 130100,
                        "level": 3
                    },
                    {
                        "code": 130186,
                        "areaName": "石家庄市",
                        "parent_code": 130100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 130200,
                "areaName": "唐山市",
                "parent_code": 130000,
                "level": 2,
                "children": [
                    {
                        "code": 130202,
                        "areaName": "路南区",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130203,
                        "areaName": "路北区",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130204,
                        "areaName": "古冶区",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130205,
                        "areaName": "开平区",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130207,
                        "areaName": "丰南区",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130208,
                        "areaName": "丰润区",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130209,
                        "areaName": "曹妃甸区",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130223,
                        "areaName": "滦县",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130224,
                        "areaName": "滦南县",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130225,
                        "areaName": "乐亭县",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130227,
                        "areaName": "迁西县",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130229,
                        "areaName": "玉田县",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130281,
                        "areaName": "遵化市",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130283,
                        "areaName": "迁安市",
                        "parent_code": 130200,
                        "level": 3
                    },
                    {
                        "code": 130284,
                        "areaName": "唐山市",
                        "parent_code": 130200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 130300,
                "areaName": "秦皇岛市",
                "parent_code": 130000,
                "level": 2,
                "children": [
                    {
                        "code": 130302,
                        "areaName": "海港区",
                        "parent_code": 130300,
                        "level": 3
                    },
                    {
                        "code": 130303,
                        "areaName": "山海关区",
                        "parent_code": 130300,
                        "level": 3
                    },
                    {
                        "code": 130304,
                        "areaName": "北戴河区",
                        "parent_code": 130300,
                        "level": 3
                    },
                    {
                        "code": 130321,
                        "areaName": "青龙满族自治县",
                        "parent_code": 130300,
                        "level": 3
                    },
                    {
                        "code": 130322,
                        "areaName": "昌黎县",
                        "parent_code": 130300,
                        "level": 3
                    },
                    {
                        "code": 130323,
                        "areaName": "抚宁县",
                        "parent_code": 130300,
                        "level": 3
                    },
                    {
                        "code": 130324,
                        "areaName": "卢龙县",
                        "parent_code": 130300,
                        "level": 3
                    },
                    {
                        "code": 130325,
                        "areaName": "秦皇岛市",
                        "parent_code": 130300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 130400,
                "areaName": "邯郸市",
                "parent_code": 130000,
                "level": 2,
                "children": [
                    {
                        "code": 130402,
                        "areaName": "邯山区",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130403,
                        "areaName": "丛台区",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130404,
                        "areaName": "复兴区",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130406,
                        "areaName": "峰峰矿区",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130421,
                        "areaName": "邯郸县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130423,
                        "areaName": "临漳县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130424,
                        "areaName": "成安县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130425,
                        "areaName": "大名县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130426,
                        "areaName": "涉县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130427,
                        "areaName": "磁县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130428,
                        "areaName": "肥乡县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130429,
                        "areaName": "永年县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130430,
                        "areaName": "邱县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130431,
                        "areaName": "鸡泽县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130432,
                        "areaName": "广平县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130433,
                        "areaName": "馆陶县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130434,
                        "areaName": "魏县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130435,
                        "areaName": "曲周县",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130481,
                        "areaName": "武安市",
                        "parent_code": 130400,
                        "level": 3
                    },
                    {
                        "code": 130482,
                        "areaName": "邯郸市",
                        "parent_code": 130400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 130500,
                "areaName": "邢台市",
                "parent_code": 130000,
                "level": 2,
                "children": [
                    {
                        "code": 130502,
                        "areaName": "桥东区",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130503,
                        "areaName": "桥西区",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130521,
                        "areaName": "邢台县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130522,
                        "areaName": "临城县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130523,
                        "areaName": "内丘县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130524,
                        "areaName": "柏乡县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130525,
                        "areaName": "隆尧县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130526,
                        "areaName": "任县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130527,
                        "areaName": "南和县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130528,
                        "areaName": "宁晋县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130529,
                        "areaName": "巨鹿县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130530,
                        "areaName": "新河县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130531,
                        "areaName": "广宗县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130532,
                        "areaName": "平乡县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130533,
                        "areaName": "威县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130534,
                        "areaName": "清河县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130535,
                        "areaName": "临西县",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130581,
                        "areaName": "南宫市",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130582,
                        "areaName": "沙河市",
                        "parent_code": 130500,
                        "level": 3
                    },
                    {
                        "code": 130583,
                        "areaName": "邢台市",
                        "parent_code": 130500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 130600,
                "areaName": "保定市",
                "parent_code": 130000,
                "level": 2,
                "children": [
                    {
                        "code": 130602,
                        "areaName": "新市区",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130603,
                        "areaName": "北市区",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130604,
                        "areaName": "南市区",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130621,
                        "areaName": "满城县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130622,
                        "areaName": "清苑县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130623,
                        "areaName": "涞水县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130624,
                        "areaName": "阜平县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130625,
                        "areaName": "徐水县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130626,
                        "areaName": "定兴县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130627,
                        "areaName": "唐县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130628,
                        "areaName": "高阳县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130629,
                        "areaName": "容城县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130630,
                        "areaName": "涞源县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130631,
                        "areaName": "望都县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130632,
                        "areaName": "安新县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130633,
                        "areaName": "易县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130634,
                        "areaName": "曲阳县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130635,
                        "areaName": "蠡县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130636,
                        "areaName": "顺平县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130637,
                        "areaName": "博野县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130638,
                        "areaName": "雄县",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130681,
                        "areaName": "涿州市",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130682,
                        "areaName": "定州市",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130683,
                        "areaName": "安国市",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130684,
                        "areaName": "高碑店市",
                        "parent_code": 130600,
                        "level": 3
                    },
                    {
                        "code": 130685,
                        "areaName": "保定市",
                        "parent_code": 130600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 130700,
                "areaName": "张家口市",
                "parent_code": 130000,
                "level": 2,
                "children": [
                    {
                        "code": 130702,
                        "areaName": "桥东区",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130703,
                        "areaName": "桥西区",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130705,
                        "areaName": "宣化区",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130706,
                        "areaName": "下花园区",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130721,
                        "areaName": "宣化县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130722,
                        "areaName": "张北县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130723,
                        "areaName": "康保县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130724,
                        "areaName": "沽源县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130725,
                        "areaName": "尚义县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130726,
                        "areaName": "蔚县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130727,
                        "areaName": "阳原县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130728,
                        "areaName": "怀安县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130729,
                        "areaName": "万全县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130730,
                        "areaName": "怀来县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130731,
                        "areaName": "涿鹿县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130732,
                        "areaName": "赤城县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130733,
                        "areaName": "崇礼县",
                        "parent_code": 130700,
                        "level": 3
                    },
                    {
                        "code": 130734,
                        "areaName": "张家口市",
                        "parent_code": 130700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 130800,
                "areaName": "承德市",
                "parent_code": 130000,
                "level": 2,
                "children": [
                    {
                        "code": 130802,
                        "areaName": "双桥区",
                        "parent_code": 130800,
                        "level": 3
                    },
                    {
                        "code": 130803,
                        "areaName": "双滦区",
                        "parent_code": 130800,
                        "level": 3
                    },
                    {
                        "code": 130804,
                        "areaName": "鹰手营子矿区",
                        "parent_code": 130800,
                        "level": 3
                    },
                    {
                        "code": 130821,
                        "areaName": "承德县",
                        "parent_code": 130800,
                        "level": 3
                    },
                    {
                        "code": 130822,
                        "areaName": "兴隆县",
                        "parent_code": 130800,
                        "level": 3
                    },
                    {
                        "code": 130823,
                        "areaName": "平泉县",
                        "parent_code": 130800,
                        "level": 3
                    },
                    {
                        "code": 130824,
                        "areaName": "滦平县",
                        "parent_code": 130800,
                        "level": 3
                    },
                    {
                        "code": 130825,
                        "areaName": "隆化县",
                        "parent_code": 130800,
                        "level": 3
                    },
                    {
                        "code": 130826,
                        "areaName": "丰宁满族自治县",
                        "parent_code": 130800,
                        "level": 3
                    },
                    {
                        "code": 130827,
                        "areaName": "宽城满族自治县",
                        "parent_code": 130800,
                        "level": 3
                    },
                    {
                        "code": 130828,
                        "areaName": "围场满族蒙古族自治县",
                        "parent_code": 130800,
                        "level": 3
                    },
                    {
                        "code": 130829,
                        "areaName": "承德市",
                        "parent_code": 130800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 130900,
                "areaName": "沧州市",
                "parent_code": 130000,
                "level": 2,
                "children": [
                    {
                        "code": 130902,
                        "areaName": "新华区",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130903,
                        "areaName": "运河区",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130921,
                        "areaName": "沧县",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130922,
                        "areaName": "青县",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130923,
                        "areaName": "东光县",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130924,
                        "areaName": "海兴县",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130925,
                        "areaName": "盐山县",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130926,
                        "areaName": "肃宁县",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130927,
                        "areaName": "南皮县",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130928,
                        "areaName": "吴桥县",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130929,
                        "areaName": "献县",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130930,
                        "areaName": "孟村回族自治县",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130981,
                        "areaName": "泊头市",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130982,
                        "areaName": "任丘市",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130983,
                        "areaName": "黄骅市",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130984,
                        "areaName": "河间市",
                        "parent_code": 130900,
                        "level": 3
                    },
                    {
                        "code": 130985,
                        "areaName": "沧州市",
                        "parent_code": 130900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 131000,
                "areaName": "廊坊市",
                "parent_code": 130000,
                "level": 2,
                "children": [
                    {
                        "code": 131002,
                        "areaName": "安次区",
                        "parent_code": 131000,
                        "level": 3
                    },
                    {
                        "code": 131003,
                        "areaName": "广阳区",
                        "parent_code": 131000,
                        "level": 3
                    },
                    {
                        "code": 131022,
                        "areaName": "固安县",
                        "parent_code": 131000,
                        "level": 3
                    },
                    {
                        "code": 131023,
                        "areaName": "永清县",
                        "parent_code": 131000,
                        "level": 3
                    },
                    {
                        "code": 131024,
                        "areaName": "香河县",
                        "parent_code": 131000,
                        "level": 3
                    },
                    {
                        "code": 131025,
                        "areaName": "大城县",
                        "parent_code": 131000,
                        "level": 3
                    },
                    {
                        "code": 131026,
                        "areaName": "文安县",
                        "parent_code": 131000,
                        "level": 3
                    },
                    {
                        "code": 131028,
                        "areaName": "大厂回族自治县",
                        "parent_code": 131000,
                        "level": 3
                    },
                    {
                        "code": 131081,
                        "areaName": "霸州市",
                        "parent_code": 131000,
                        "level": 3
                    },
                    {
                        "code": 131082,
                        "areaName": "三河市",
                        "parent_code": 131000,
                        "level": 3
                    },
                    {
                        "code": 131083,
                        "areaName": "廊坊市",
                        "parent_code": 131000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 131100,
                "areaName": "衡水市",
                "parent_code": 130000,
                "level": 2,
                "children": [
                    {
                        "code": 131102,
                        "areaName": "桃城区",
                        "parent_code": 131100,
                        "level": 3
                    },
                    {
                        "code": 131121,
                        "areaName": "枣强县",
                        "parent_code": 131100,
                        "level": 3
                    },
                    {
                        "code": 131122,
                        "areaName": "武邑县",
                        "parent_code": 131100,
                        "level": 3
                    },
                    {
                        "code": 131123,
                        "areaName": "武强县",
                        "parent_code": 131100,
                        "level": 3
                    },
                    {
                        "code": 131124,
                        "areaName": "饶阳县",
                        "parent_code": 131100,
                        "level": 3
                    },
                    {
                        "code": 131125,
                        "areaName": "安平县",
                        "parent_code": 131100,
                        "level": 3
                    },
                    {
                        "code": 131126,
                        "areaName": "故城县",
                        "parent_code": 131100,
                        "level": 3
                    },
                    {
                        "code": 131127,
                        "areaName": "景县",
                        "parent_code": 131100,
                        "level": 3
                    },
                    {
                        "code": 131128,
                        "areaName": "阜城县",
                        "parent_code": 131100,
                        "level": 3
                    },
                    {
                        "code": 131181,
                        "areaName": "冀州市",
                        "parent_code": 131100,
                        "level": 3
                    },
                    {
                        "code": 131182,
                        "areaName": "深州市",
                        "parent_code": 131100,
                        "level": 3
                    },
                    {
                        "code": 131183,
                        "areaName": "衡水市",
                        "parent_code": 131100,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 140000,
        "areaName": "山西省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 140100,
                "areaName": "太原市",
                "parent_code": 140000,
                "level": 2,
                "children": [
                    {
                        "code": 140105,
                        "areaName": "小店区",
                        "parent_code": 140100,
                        "level": 3
                    },
                    {
                        "code": 140106,
                        "areaName": "迎泽区",
                        "parent_code": 140100,
                        "level": 3
                    },
                    {
                        "code": 140107,
                        "areaName": "杏花岭区",
                        "parent_code": 140100,
                        "level": 3
                    },
                    {
                        "code": 140108,
                        "areaName": "尖草坪区",
                        "parent_code": 140100,
                        "level": 3
                    },
                    {
                        "code": 140109,
                        "areaName": "万柏林区",
                        "parent_code": 140100,
                        "level": 3
                    },
                    {
                        "code": 140110,
                        "areaName": "晋源区",
                        "parent_code": 140100,
                        "level": 3
                    },
                    {
                        "code": 140121,
                        "areaName": "清徐县",
                        "parent_code": 140100,
                        "level": 3
                    },
                    {
                        "code": 140122,
                        "areaName": "阳曲县",
                        "parent_code": 140100,
                        "level": 3
                    },
                    {
                        "code": 140123,
                        "areaName": "娄烦县",
                        "parent_code": 140100,
                        "level": 3
                    },
                    {
                        "code": 140181,
                        "areaName": "古交市",
                        "parent_code": 140100,
                        "level": 3
                    },
                    {
                        "code": 140182,
                        "areaName": "太原市",
                        "parent_code": 140100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 140200,
                "areaName": "大同市",
                "parent_code": 140000,
                "level": 2,
                "children": [
                    {
                        "code": 140202,
                        "areaName": "城区",
                        "parent_code": 140200,
                        "level": 3
                    },
                    {
                        "code": 140203,
                        "areaName": "矿区",
                        "parent_code": 140200,
                        "level": 3
                    },
                    {
                        "code": 140211,
                        "areaName": "南郊区",
                        "parent_code": 140200,
                        "level": 3
                    },
                    {
                        "code": 140212,
                        "areaName": "新荣区",
                        "parent_code": 140200,
                        "level": 3
                    },
                    {
                        "code": 140221,
                        "areaName": "阳高县",
                        "parent_code": 140200,
                        "level": 3
                    },
                    {
                        "code": 140222,
                        "areaName": "天镇县",
                        "parent_code": 140200,
                        "level": 3
                    },
                    {
                        "code": 140223,
                        "areaName": "广灵县",
                        "parent_code": 140200,
                        "level": 3
                    },
                    {
                        "code": 140224,
                        "areaName": "灵丘县",
                        "parent_code": 140200,
                        "level": 3
                    },
                    {
                        "code": 140225,
                        "areaName": "浑源县",
                        "parent_code": 140200,
                        "level": 3
                    },
                    {
                        "code": 140226,
                        "areaName": "左云县",
                        "parent_code": 140200,
                        "level": 3
                    },
                    {
                        "code": 140227,
                        "areaName": "大同县",
                        "parent_code": 140200,
                        "level": 3
                    },
                    {
                        "code": 140228,
                        "areaName": "大同市",
                        "parent_code": 140200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 140300,
                "areaName": "阳泉市",
                "parent_code": 140000,
                "level": 2,
                "children": [
                    {
                        "code": 140302,
                        "areaName": "城区",
                        "parent_code": 140300,
                        "level": 3
                    },
                    {
                        "code": 140303,
                        "areaName": "矿区",
                        "parent_code": 140300,
                        "level": 3
                    },
                    {
                        "code": 140311,
                        "areaName": "郊区",
                        "parent_code": 140300,
                        "level": 3
                    },
                    {
                        "code": 140321,
                        "areaName": "平定县",
                        "parent_code": 140300,
                        "level": 3
                    },
                    {
                        "code": 140322,
                        "areaName": "盂县",
                        "parent_code": 140300,
                        "level": 3
                    },
                    {
                        "code": 140323,
                        "areaName": "阳泉市",
                        "parent_code": 140300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 140400,
                "areaName": "长治市",
                "parent_code": 140000,
                "level": 2,
                "children": [
                    {
                        "code": 140402,
                        "areaName": "城区",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140411,
                        "areaName": "郊区",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140421,
                        "areaName": "长治县",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140423,
                        "areaName": "襄垣县",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140424,
                        "areaName": "屯留县",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140425,
                        "areaName": "平顺县",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140426,
                        "areaName": "黎城县",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140427,
                        "areaName": "壶关县",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140428,
                        "areaName": "长子县",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140429,
                        "areaName": "武乡县",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140430,
                        "areaName": "沁县",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140431,
                        "areaName": "沁源县",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140481,
                        "areaName": "潞城市",
                        "parent_code": 140400,
                        "level": 3
                    },
                    {
                        "code": 140482,
                        "areaName": "长治市",
                        "parent_code": 140400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 140500,
                "areaName": "晋城市",
                "parent_code": 140000,
                "level": 2,
                "children": [
                    {
                        "code": 140502,
                        "areaName": "城区",
                        "parent_code": 140500,
                        "level": 3
                    },
                    {
                        "code": 140521,
                        "areaName": "沁水县",
                        "parent_code": 140500,
                        "level": 3
                    },
                    {
                        "code": 140522,
                        "areaName": "阳城县",
                        "parent_code": 140500,
                        "level": 3
                    },
                    {
                        "code": 140524,
                        "areaName": "陵川县",
                        "parent_code": 140500,
                        "level": 3
                    },
                    {
                        "code": 140525,
                        "areaName": "泽州县",
                        "parent_code": 140500,
                        "level": 3
                    },
                    {
                        "code": 140581,
                        "areaName": "高平市",
                        "parent_code": 140500,
                        "level": 3
                    },
                    {
                        "code": 140582,
                        "areaName": "晋城市",
                        "parent_code": 140500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 140600,
                "areaName": "朔州市",
                "parent_code": 140000,
                "level": 2,
                "children": [
                    {
                        "code": 140602,
                        "areaName": "朔城区",
                        "parent_code": 140600,
                        "level": 3
                    },
                    {
                        "code": 140603,
                        "areaName": "平鲁区",
                        "parent_code": 140600,
                        "level": 3
                    },
                    {
                        "code": 140621,
                        "areaName": "山阴县",
                        "parent_code": 140600,
                        "level": 3
                    },
                    {
                        "code": 140622,
                        "areaName": "应县",
                        "parent_code": 140600,
                        "level": 3
                    },
                    {
                        "code": 140623,
                        "areaName": "右玉县",
                        "parent_code": 140600,
                        "level": 3
                    },
                    {
                        "code": 140624,
                        "areaName": "怀仁县",
                        "parent_code": 140600,
                        "level": 3
                    },
                    {
                        "code": 140625,
                        "areaName": "朔州市",
                        "parent_code": 140600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 140700,
                "areaName": "晋中市",
                "parent_code": 140000,
                "level": 2,
                "children": [
                    {
                        "code": 140702,
                        "areaName": "榆次区",
                        "parent_code": 140700,
                        "level": 3
                    },
                    {
                        "code": 140721,
                        "areaName": "榆社县",
                        "parent_code": 140700,
                        "level": 3
                    },
                    {
                        "code": 140722,
                        "areaName": "左权县",
                        "parent_code": 140700,
                        "level": 3
                    },
                    {
                        "code": 140723,
                        "areaName": "和顺县",
                        "parent_code": 140700,
                        "level": 3
                    },
                    {
                        "code": 140724,
                        "areaName": "昔阳县",
                        "parent_code": 140700,
                        "level": 3
                    },
                    {
                        "code": 140725,
                        "areaName": "寿阳县",
                        "parent_code": 140700,
                        "level": 3
                    },
                    {
                        "code": 140726,
                        "areaName": "太谷县",
                        "parent_code": 140700,
                        "level": 3
                    },
                    {
                        "code": 140727,
                        "areaName": "祁县",
                        "parent_code": 140700,
                        "level": 3
                    },
                    {
                        "code": 140728,
                        "areaName": "平遥县",
                        "parent_code": 140700,
                        "level": 3
                    },
                    {
                        "code": 140729,
                        "areaName": "灵石县",
                        "parent_code": 140700,
                        "level": 3
                    },
                    {
                        "code": 140781,
                        "areaName": "介休市",
                        "parent_code": 140700,
                        "level": 3
                    },
                    {
                        "code": 140782,
                        "areaName": "晋中市",
                        "parent_code": 140700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 140800,
                "areaName": "运城市",
                "parent_code": 140000,
                "level": 2,
                "children": [
                    {
                        "code": 140802,
                        "areaName": "盐湖区",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140821,
                        "areaName": "临猗县",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140822,
                        "areaName": "万荣县",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140823,
                        "areaName": "闻喜县",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140824,
                        "areaName": "稷山县",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140825,
                        "areaName": "新绛县",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140826,
                        "areaName": "绛县",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140827,
                        "areaName": "垣曲县",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140828,
                        "areaName": "夏县",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140829,
                        "areaName": "平陆县",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140830,
                        "areaName": "芮城县",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140881,
                        "areaName": "永济市",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140882,
                        "areaName": "河津市",
                        "parent_code": 140800,
                        "level": 3
                    },
                    {
                        "code": 140883,
                        "areaName": "运城市",
                        "parent_code": 140800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 140900,
                "areaName": "忻州市",
                "parent_code": 140000,
                "level": 2,
                "children": [
                    {
                        "code": 140902,
                        "areaName": "忻府区",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140921,
                        "areaName": "定襄县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140922,
                        "areaName": "五台县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140923,
                        "areaName": "代县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140924,
                        "areaName": "繁峙县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140925,
                        "areaName": "宁武县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140926,
                        "areaName": "静乐县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140927,
                        "areaName": "神池县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140928,
                        "areaName": "五寨县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140929,
                        "areaName": "岢岚县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140930,
                        "areaName": "河曲县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140931,
                        "areaName": "保德县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140932,
                        "areaName": "偏关县",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140981,
                        "areaName": "原平市",
                        "parent_code": 140900,
                        "level": 3
                    },
                    {
                        "code": 140982,
                        "areaName": "忻州市",
                        "parent_code": 140900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 141000,
                "areaName": "临汾市",
                "parent_code": 140000,
                "level": 2,
                "children": [
                    {
                        "code": 141002,
                        "areaName": "尧都区",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141021,
                        "areaName": "曲沃县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141022,
                        "areaName": "翼城县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141023,
                        "areaName": "襄汾县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141024,
                        "areaName": "洪洞县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141025,
                        "areaName": "古县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141026,
                        "areaName": "安泽县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141027,
                        "areaName": "浮山县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141028,
                        "areaName": "吉县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141029,
                        "areaName": "乡宁县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141030,
                        "areaName": "大宁县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141031,
                        "areaName": "隰县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141032,
                        "areaName": "永和县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141033,
                        "areaName": "蒲县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141034,
                        "areaName": "汾西县",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141081,
                        "areaName": "侯马市",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141082,
                        "areaName": "霍州市",
                        "parent_code": 141000,
                        "level": 3
                    },
                    {
                        "code": 141083,
                        "areaName": "临汾市",
                        "parent_code": 141000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 141100,
                "areaName": "吕梁市",
                "parent_code": 140000,
                "level": 2,
                "children": [
                    {
                        "code": 141102,
                        "areaName": "离石区",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141121,
                        "areaName": "文水县",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141122,
                        "areaName": "交城县",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141123,
                        "areaName": "兴县",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141124,
                        "areaName": "临县",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141125,
                        "areaName": "柳林县",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141126,
                        "areaName": "石楼县",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141127,
                        "areaName": "岚县",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141128,
                        "areaName": "方山县",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141129,
                        "areaName": "中阳县",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141130,
                        "areaName": "交口县",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141181,
                        "areaName": "孝义市",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141182,
                        "areaName": "汾阳市",
                        "parent_code": 141100,
                        "level": 3
                    },
                    {
                        "code": 141183,
                        "areaName": "吕梁市",
                        "parent_code": 141100,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 150000,
        "areaName": "内蒙古自治区",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 150100,
                "areaName": "呼和浩特市",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 150102,
                        "areaName": "新城区",
                        "parent_code": 150100,
                        "level": 3
                    },
                    {
                        "code": 150103,
                        "areaName": "回民区",
                        "parent_code": 150100,
                        "level": 3
                    },
                    {
                        "code": 150104,
                        "areaName": "玉泉区",
                        "parent_code": 150100,
                        "level": 3
                    },
                    {
                        "code": 150105,
                        "areaName": "赛罕区",
                        "parent_code": 150100,
                        "level": 3
                    },
                    {
                        "code": 150121,
                        "areaName": "土默特左旗",
                        "parent_code": 150100,
                        "level": 3
                    },
                    {
                        "code": 150122,
                        "areaName": "托克托县",
                        "parent_code": 150100,
                        "level": 3
                    },
                    {
                        "code": 150123,
                        "areaName": "和林格尔县",
                        "parent_code": 150100,
                        "level": 3
                    },
                    {
                        "code": 150124,
                        "areaName": "清水河县",
                        "parent_code": 150100,
                        "level": 3
                    },
                    {
                        "code": 150125,
                        "areaName": "武川县",
                        "parent_code": 150100,
                        "level": 3
                    },
                    {
                        "code": 150126,
                        "areaName": "呼和浩特市",
                        "parent_code": 150100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 150200,
                "areaName": "包头市",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 150202,
                        "areaName": "东河区",
                        "parent_code": 150200,
                        "level": 3
                    },
                    {
                        "code": 150203,
                        "areaName": "昆都仑区",
                        "parent_code": 150200,
                        "level": 3
                    },
                    {
                        "code": 150204,
                        "areaName": "青山区",
                        "parent_code": 150200,
                        "level": 3
                    },
                    {
                        "code": 150205,
                        "areaName": "石拐区",
                        "parent_code": 150200,
                        "level": 3
                    },
                    {
                        "code": 150206,
                        "areaName": "白云鄂博矿区",
                        "parent_code": 150200,
                        "level": 3
                    },
                    {
                        "code": 150207,
                        "areaName": "九原区",
                        "parent_code": 150200,
                        "level": 3
                    },
                    {
                        "code": 150221,
                        "areaName": "土默特右旗",
                        "parent_code": 150200,
                        "level": 3
                    },
                    {
                        "code": 150222,
                        "areaName": "固阳县",
                        "parent_code": 150200,
                        "level": 3
                    },
                    {
                        "code": 150223,
                        "areaName": "达尔罕茂明安联合旗",
                        "parent_code": 150200,
                        "level": 3
                    },
                    {
                        "code": 150224,
                        "areaName": "包头市",
                        "parent_code": 150200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 150300,
                "areaName": "乌海市",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 150302,
                        "areaName": "海勃湾区",
                        "parent_code": 150300,
                        "level": 3
                    },
                    {
                        "code": 150303,
                        "areaName": "海南区",
                        "parent_code": 150300,
                        "level": 3
                    },
                    {
                        "code": 150304,
                        "areaName": "乌达区",
                        "parent_code": 150300,
                        "level": 3
                    },
                    {
                        "code": 150305,
                        "areaName": "乌海市",
                        "parent_code": 150300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 150400,
                "areaName": "赤峰市",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 150402,
                        "areaName": "红山区",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150403,
                        "areaName": "元宝山区",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150404,
                        "areaName": "松山区",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150421,
                        "areaName": "阿鲁科尔沁旗",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150422,
                        "areaName": "巴林左旗",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150423,
                        "areaName": "巴林右旗",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150424,
                        "areaName": "林西县",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150425,
                        "areaName": "克什克腾旗",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150426,
                        "areaName": "翁牛特旗",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150428,
                        "areaName": "喀喇沁旗",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150429,
                        "areaName": "宁城县",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150430,
                        "areaName": "敖汉旗",
                        "parent_code": 150400,
                        "level": 3
                    },
                    {
                        "code": 150431,
                        "areaName": "赤峰市",
                        "parent_code": 150400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 150500,
                "areaName": "通辽市",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 150502,
                        "areaName": "科尔沁区",
                        "parent_code": 150500,
                        "level": 3
                    },
                    {
                        "code": 150521,
                        "areaName": "科尔沁左翼中旗",
                        "parent_code": 150500,
                        "level": 3
                    },
                    {
                        "code": 150522,
                        "areaName": "科尔沁左翼后旗",
                        "parent_code": 150500,
                        "level": 3
                    },
                    {
                        "code": 150523,
                        "areaName": "开鲁县",
                        "parent_code": 150500,
                        "level": 3
                    },
                    {
                        "code": 150524,
                        "areaName": "库伦旗",
                        "parent_code": 150500,
                        "level": 3
                    },
                    {
                        "code": 150525,
                        "areaName": "奈曼旗",
                        "parent_code": 150500,
                        "level": 3
                    },
                    {
                        "code": 150526,
                        "areaName": "扎鲁特旗",
                        "parent_code": 150500,
                        "level": 3
                    },
                    {
                        "code": 150581,
                        "areaName": "霍林郭勒市",
                        "parent_code": 150500,
                        "level": 3
                    },
                    {
                        "code": 150582,
                        "areaName": "通辽市",
                        "parent_code": 150500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 150600,
                "areaName": "鄂尔多斯市",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 150602,
                        "areaName": "东胜区",
                        "parent_code": 150600,
                        "level": 3
                    },
                    {
                        "code": 150621,
                        "areaName": "达拉特旗",
                        "parent_code": 150600,
                        "level": 3
                    },
                    {
                        "code": 150622,
                        "areaName": "准格尔旗",
                        "parent_code": 150600,
                        "level": 3
                    },
                    {
                        "code": 150623,
                        "areaName": "鄂托克前旗",
                        "parent_code": 150600,
                        "level": 3
                    },
                    {
                        "code": 150624,
                        "areaName": "鄂托克旗",
                        "parent_code": 150600,
                        "level": 3
                    },
                    {
                        "code": 150625,
                        "areaName": "杭锦旗",
                        "parent_code": 150600,
                        "level": 3
                    },
                    {
                        "code": 150626,
                        "areaName": "乌审旗",
                        "parent_code": 150600,
                        "level": 3
                    },
                    {
                        "code": 150627,
                        "areaName": "伊金霍洛旗",
                        "parent_code": 150600,
                        "level": 3
                    },
                    {
                        "code": 150628,
                        "areaName": "鄂尔多斯市",
                        "parent_code": 150600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 150700,
                "areaName": "呼伦贝尔市",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 150702,
                        "areaName": "海拉尔区",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150703,
                        "areaName": "扎赉诺尔区",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150721,
                        "areaName": "阿荣旗",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150722,
                        "areaName": "莫力达瓦达斡尔族自治旗",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150723,
                        "areaName": "鄂伦春自治旗",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150724,
                        "areaName": "鄂温克族自治旗",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150725,
                        "areaName": "陈巴尔虎旗",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150726,
                        "areaName": "新巴尔虎左旗",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150727,
                        "areaName": "新巴尔虎右旗",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150781,
                        "areaName": "满洲里市",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150782,
                        "areaName": "牙克石市",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150783,
                        "areaName": "扎兰屯市",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150784,
                        "areaName": "额尔古纳市",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150785,
                        "areaName": "根河市",
                        "parent_code": 150700,
                        "level": 3
                    },
                    {
                        "code": 150786,
                        "areaName": "呼伦贝尔市",
                        "parent_code": 150700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 150800,
                "areaName": "巴彦淖尔市",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 150802,
                        "areaName": "临河区",
                        "parent_code": 150800,
                        "level": 3
                    },
                    {
                        "code": 150821,
                        "areaName": "五原县",
                        "parent_code": 150800,
                        "level": 3
                    },
                    {
                        "code": 150822,
                        "areaName": "磴口县",
                        "parent_code": 150800,
                        "level": 3
                    },
                    {
                        "code": 150823,
                        "areaName": "乌拉特前旗",
                        "parent_code": 150800,
                        "level": 3
                    },
                    {
                        "code": 150824,
                        "areaName": "乌拉特中旗",
                        "parent_code": 150800,
                        "level": 3
                    },
                    {
                        "code": 150825,
                        "areaName": "乌拉特后旗",
                        "parent_code": 150800,
                        "level": 3
                    },
                    {
                        "code": 150826,
                        "areaName": "杭锦后旗",
                        "parent_code": 150800,
                        "level": 3
                    },
                    {
                        "code": 150827,
                        "areaName": "巴彦淖尔市",
                        "parent_code": 150800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 150900,
                "areaName": "乌兰察布市",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 150902,
                        "areaName": "集宁区",
                        "parent_code": 150900,
                        "level": 3
                    },
                    {
                        "code": 150921,
                        "areaName": "卓资县",
                        "parent_code": 150900,
                        "level": 3
                    },
                    {
                        "code": 150922,
                        "areaName": "化德县",
                        "parent_code": 150900,
                        "level": 3
                    },
                    {
                        "code": 150923,
                        "areaName": "商都县",
                        "parent_code": 150900,
                        "level": 3
                    },
                    {
                        "code": 150924,
                        "areaName": "兴和县",
                        "parent_code": 150900,
                        "level": 3
                    },
                    {
                        "code": 150925,
                        "areaName": "凉城县",
                        "parent_code": 150900,
                        "level": 3
                    },
                    {
                        "code": 150926,
                        "areaName": "察哈尔右翼前旗",
                        "parent_code": 150900,
                        "level": 3
                    },
                    {
                        "code": 150927,
                        "areaName": "察哈尔右翼中旗",
                        "parent_code": 150900,
                        "level": 3
                    },
                    {
                        "code": 150928,
                        "areaName": "察哈尔右翼后旗",
                        "parent_code": 150900,
                        "level": 3
                    },
                    {
                        "code": 150929,
                        "areaName": "四子王旗",
                        "parent_code": 150900,
                        "level": 3
                    },
                    {
                        "code": 150981,
                        "areaName": "丰镇市",
                        "parent_code": 150900,
                        "level": 3
                    },
                    {
                        "code": 150982,
                        "areaName": "乌兰察布市",
                        "parent_code": 150900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 152200,
                "areaName": "兴安盟",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 152201,
                        "areaName": "乌兰浩特市",
                        "parent_code": 152200,
                        "level": 3
                    },
                    {
                        "code": 152202,
                        "areaName": "阿尔山市",
                        "parent_code": 152200,
                        "level": 3
                    },
                    {
                        "code": 152221,
                        "areaName": "科尔沁右翼前旗",
                        "parent_code": 152200,
                        "level": 3
                    },
                    {
                        "code": 152222,
                        "areaName": "科尔沁右翼中旗",
                        "parent_code": 152200,
                        "level": 3
                    },
                    {
                        "code": 152223,
                        "areaName": "扎赉特旗",
                        "parent_code": 152200,
                        "level": 3
                    },
                    {
                        "code": 152224,
                        "areaName": "突泉县",
                        "parent_code": 152200,
                        "level": 3
                    },
                    {
                        "code": 152225,
                        "areaName": "兴安盟",
                        "parent_code": 152200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 152500,
                "areaName": "锡林郭勒盟",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 152501,
                        "areaName": "二连浩特市",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152502,
                        "areaName": "锡林浩特市",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152522,
                        "areaName": "阿巴嘎旗",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152523,
                        "areaName": "苏尼特左旗",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152524,
                        "areaName": "苏尼特右旗",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152525,
                        "areaName": "东乌珠穆沁旗",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152526,
                        "areaName": "西乌珠穆沁旗",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152527,
                        "areaName": "太仆寺旗",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152528,
                        "areaName": "镶黄旗",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152529,
                        "areaName": "正镶白旗",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152530,
                        "areaName": "正蓝旗",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152531,
                        "areaName": "多伦县",
                        "parent_code": 152500,
                        "level": 3
                    },
                    {
                        "code": 152532,
                        "areaName": "锡林郭勒盟",
                        "parent_code": 152500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 152900,
                "areaName": "阿拉善盟",
                "parent_code": 150000,
                "level": 2,
                "children": [
                    {
                        "code": 152921,
                        "areaName": "阿拉善左旗",
                        "parent_code": 152900,
                        "level": 3
                    },
                    {
                        "code": 152922,
                        "areaName": "阿拉善右旗",
                        "parent_code": 152900,
                        "level": 3
                    },
                    {
                        "code": 152923,
                        "areaName": "额济纳旗",
                        "parent_code": 152900,
                        "level": 3
                    },
                    {
                        "code": 152924,
                        "areaName": "阿拉善盟",
                        "parent_code": 152900,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 210000,
        "areaName": "辽宁省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 210100,
                "areaName": "沈阳市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 210102,
                        "areaName": "和平区",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210103,
                        "areaName": "沈河区",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210104,
                        "areaName": "大东区",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210105,
                        "areaName": "皇姑区",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210106,
                        "areaName": "铁西区",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210111,
                        "areaName": "苏家屯区",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210112,
                        "areaName": "浑南区",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210113,
                        "areaName": "沈北新区",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210114,
                        "areaName": "于洪区",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210122,
                        "areaName": "辽中县",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210123,
                        "areaName": "康平县",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210124,
                        "areaName": "法库县",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210181,
                        "areaName": "新民市",
                        "parent_code": 210100,
                        "level": 3
                    },
                    {
                        "code": 210182,
                        "areaName": "沈阳市",
                        "parent_code": 210100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 210200,
                "areaName": "大连市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 210202,
                        "areaName": "中山区",
                        "parent_code": 210200,
                        "level": 3
                    },
                    {
                        "code": 210203,
                        "areaName": "西岗区",
                        "parent_code": 210200,
                        "level": 3
                    },
                    {
                        "code": 210204,
                        "areaName": "沙河口区",
                        "parent_code": 210200,
                        "level": 3
                    },
                    {
                        "code": 210211,
                        "areaName": "甘井子区",
                        "parent_code": 210200,
                        "level": 3
                    },
                    {
                        "code": 210212,
                        "areaName": "旅顺口区",
                        "parent_code": 210200,
                        "level": 3
                    },
                    {
                        "code": 210213,
                        "areaName": "金州区",
                        "parent_code": 210200,
                        "level": 3
                    },
                    {
                        "code": 210224,
                        "areaName": "长海县",
                        "parent_code": 210200,
                        "level": 3
                    },
                    {
                        "code": 210281,
                        "areaName": "瓦房店市",
                        "parent_code": 210200,
                        "level": 3
                    },
                    {
                        "code": 210282,
                        "areaName": "普兰店市",
                        "parent_code": 210200,
                        "level": 3
                    },
                    {
                        "code": 210283,
                        "areaName": "庄河市",
                        "parent_code": 210200,
                        "level": 3
                    },
                    {
                        "code": 210284,
                        "areaName": "大连市",
                        "parent_code": 210200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 210300,
                "areaName": "鞍山市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 210302,
                        "areaName": "铁东区",
                        "parent_code": 210300,
                        "level": 3
                    },
                    {
                        "code": 210303,
                        "areaName": "铁西区",
                        "parent_code": 210300,
                        "level": 3
                    },
                    {
                        "code": 210304,
                        "areaName": "立山区",
                        "parent_code": 210300,
                        "level": 3
                    },
                    {
                        "code": 210311,
                        "areaName": "千山区",
                        "parent_code": 210300,
                        "level": 3
                    },
                    {
                        "code": 210321,
                        "areaName": "台安县",
                        "parent_code": 210300,
                        "level": 3
                    },
                    {
                        "code": 210323,
                        "areaName": "岫岩满族自治县",
                        "parent_code": 210300,
                        "level": 3
                    },
                    {
                        "code": 210381,
                        "areaName": "海城市",
                        "parent_code": 210300,
                        "level": 3
                    },
                    {
                        "code": 210382,
                        "areaName": "鞍山市",
                        "parent_code": 210300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 210400,
                "areaName": "抚顺市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 210402,
                        "areaName": "新抚区",
                        "parent_code": 210400,
                        "level": 3
                    },
                    {
                        "code": 210403,
                        "areaName": "东洲区",
                        "parent_code": 210400,
                        "level": 3
                    },
                    {
                        "code": 210404,
                        "areaName": "望花区",
                        "parent_code": 210400,
                        "level": 3
                    },
                    {
                        "code": 210411,
                        "areaName": "顺城区",
                        "parent_code": 210400,
                        "level": 3
                    },
                    {
                        "code": 210421,
                        "areaName": "抚顺县",
                        "parent_code": 210400,
                        "level": 3
                    },
                    {
                        "code": 210422,
                        "areaName": "新宾满族自治县",
                        "parent_code": 210400,
                        "level": 3
                    },
                    {
                        "code": 210423,
                        "areaName": "清原满族自治县",
                        "parent_code": 210400,
                        "level": 3
                    },
                    {
                        "code": 210424,
                        "areaName": "抚顺市",
                        "parent_code": 210400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 210500,
                "areaName": "本溪市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 210502,
                        "areaName": "平山区",
                        "parent_code": 210500,
                        "level": 3
                    },
                    {
                        "code": 210503,
                        "areaName": "溪湖区",
                        "parent_code": 210500,
                        "level": 3
                    },
                    {
                        "code": 210504,
                        "areaName": "明山区",
                        "parent_code": 210500,
                        "level": 3
                    },
                    {
                        "code": 210505,
                        "areaName": "南芬区",
                        "parent_code": 210500,
                        "level": 3
                    },
                    {
                        "code": 210521,
                        "areaName": "本溪满族自治县",
                        "parent_code": 210500,
                        "level": 3
                    },
                    {
                        "code": 210522,
                        "areaName": "桓仁满族自治县",
                        "parent_code": 210500,
                        "level": 3
                    },
                    {
                        "code": 210523,
                        "areaName": "本溪市",
                        "parent_code": 210500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 210600,
                "areaName": "丹东市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 210602,
                        "areaName": "元宝区",
                        "parent_code": 210600,
                        "level": 3
                    },
                    {
                        "code": 210603,
                        "areaName": "振兴区",
                        "parent_code": 210600,
                        "level": 3
                    },
                    {
                        "code": 210604,
                        "areaName": "振安区",
                        "parent_code": 210600,
                        "level": 3
                    },
                    {
                        "code": 210624,
                        "areaName": "宽甸满族自治县",
                        "parent_code": 210600,
                        "level": 3
                    },
                    {
                        "code": 210681,
                        "areaName": "东港市",
                        "parent_code": 210600,
                        "level": 3
                    },
                    {
                        "code": 210682,
                        "areaName": "凤城市",
                        "parent_code": 210600,
                        "level": 3
                    },
                    {
                        "code": 210683,
                        "areaName": "丹东市",
                        "parent_code": 210600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 210700,
                "areaName": "锦州市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 210702,
                        "areaName": "古塔区",
                        "parent_code": 210700,
                        "level": 3
                    },
                    {
                        "code": 210703,
                        "areaName": "凌河区",
                        "parent_code": 210700,
                        "level": 3
                    },
                    {
                        "code": 210711,
                        "areaName": "太和区",
                        "parent_code": 210700,
                        "level": 3
                    },
                    {
                        "code": 210726,
                        "areaName": "黑山县",
                        "parent_code": 210700,
                        "level": 3
                    },
                    {
                        "code": 210727,
                        "areaName": "义县",
                        "parent_code": 210700,
                        "level": 3
                    },
                    {
                        "code": 210781,
                        "areaName": "凌海市",
                        "parent_code": 210700,
                        "level": 3
                    },
                    {
                        "code": 210782,
                        "areaName": "北镇市",
                        "parent_code": 210700,
                        "level": 3
                    },
                    {
                        "code": 210783,
                        "areaName": "锦州市",
                        "parent_code": 210700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 210800,
                "areaName": "营口市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 210802,
                        "areaName": "站前区",
                        "parent_code": 210800,
                        "level": 3
                    },
                    {
                        "code": 210803,
                        "areaName": "西市区",
                        "parent_code": 210800,
                        "level": 3
                    },
                    {
                        "code": 210804,
                        "areaName": "鲅鱼圈区",
                        "parent_code": 210800,
                        "level": 3
                    },
                    {
                        "code": 210811,
                        "areaName": "老边区",
                        "parent_code": 210800,
                        "level": 3
                    },
                    {
                        "code": 210881,
                        "areaName": "盖州市",
                        "parent_code": 210800,
                        "level": 3
                    },
                    {
                        "code": 210882,
                        "areaName": "大石桥市",
                        "parent_code": 210800,
                        "level": 3
                    },
                    {
                        "code": 210883,
                        "areaName": "营口市",
                        "parent_code": 210800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 210900,
                "areaName": "阜新市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 210902,
                        "areaName": "海州区",
                        "parent_code": 210900,
                        "level": 3
                    },
                    {
                        "code": 210903,
                        "areaName": "新邱区",
                        "parent_code": 210900,
                        "level": 3
                    },
                    {
                        "code": 210904,
                        "areaName": "太平区",
                        "parent_code": 210900,
                        "level": 3
                    },
                    {
                        "code": 210905,
                        "areaName": "清河门区",
                        "parent_code": 210900,
                        "level": 3
                    },
                    {
                        "code": 210911,
                        "areaName": "细河区",
                        "parent_code": 210900,
                        "level": 3
                    },
                    {
                        "code": 210921,
                        "areaName": "阜新蒙古族自治县",
                        "parent_code": 210900,
                        "level": 3
                    },
                    {
                        "code": 210922,
                        "areaName": "彰武县",
                        "parent_code": 210900,
                        "level": 3
                    },
                    {
                        "code": 210923,
                        "areaName": "阜新市",
                        "parent_code": 210900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 211000,
                "areaName": "辽阳市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 211002,
                        "areaName": "白塔区",
                        "parent_code": 211000,
                        "level": 3
                    },
                    {
                        "code": 211003,
                        "areaName": "文圣区",
                        "parent_code": 211000,
                        "level": 3
                    },
                    {
                        "code": 211004,
                        "areaName": "宏伟区",
                        "parent_code": 211000,
                        "level": 3
                    },
                    {
                        "code": 211005,
                        "areaName": "弓长岭区",
                        "parent_code": 211000,
                        "level": 3
                    },
                    {
                        "code": 211011,
                        "areaName": "太子河区",
                        "parent_code": 211000,
                        "level": 3
                    },
                    {
                        "code": 211021,
                        "areaName": "辽阳县",
                        "parent_code": 211000,
                        "level": 3
                    },
                    {
                        "code": 211081,
                        "areaName": "灯塔市",
                        "parent_code": 211000,
                        "level": 3
                    },
                    {
                        "code": 211082,
                        "areaName": "辽阳市",
                        "parent_code": 211000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 211100,
                "areaName": "盘锦市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 211102,
                        "areaName": "双台子区",
                        "parent_code": 211100,
                        "level": 3
                    },
                    {
                        "code": 211103,
                        "areaName": "兴隆台区",
                        "parent_code": 211100,
                        "level": 3
                    },
                    {
                        "code": 211121,
                        "areaName": "大洼县",
                        "parent_code": 211100,
                        "level": 3
                    },
                    {
                        "code": 211122,
                        "areaName": "盘山县",
                        "parent_code": 211100,
                        "level": 3
                    },
                    {
                        "code": 211123,
                        "areaName": "盘锦市",
                        "parent_code": 211100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 211200,
                "areaName": "铁岭市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 211202,
                        "areaName": "银州区",
                        "parent_code": 211200,
                        "level": 3
                    },
                    {
                        "code": 211204,
                        "areaName": "清河区",
                        "parent_code": 211200,
                        "level": 3
                    },
                    {
                        "code": 211221,
                        "areaName": "铁岭县",
                        "parent_code": 211200,
                        "level": 3
                    },
                    {
                        "code": 211223,
                        "areaName": "西丰县",
                        "parent_code": 211200,
                        "level": 3
                    },
                    {
                        "code": 211224,
                        "areaName": "昌图县",
                        "parent_code": 211200,
                        "level": 3
                    },
                    {
                        "code": 211281,
                        "areaName": "调兵山市",
                        "parent_code": 211200,
                        "level": 3
                    },
                    {
                        "code": 211282,
                        "areaName": "开原市",
                        "parent_code": 211200,
                        "level": 3
                    },
                    {
                        "code": 211283,
                        "areaName": "铁岭市",
                        "parent_code": 211200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 211300,
                "areaName": "朝阳市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 211302,
                        "areaName": "双塔区",
                        "parent_code": 211300,
                        "level": 3
                    },
                    {
                        "code": 211303,
                        "areaName": "龙城区",
                        "parent_code": 211300,
                        "level": 3
                    },
                    {
                        "code": 211321,
                        "areaName": "朝阳县",
                        "parent_code": 211300,
                        "level": 3
                    },
                    {
                        "code": 211322,
                        "areaName": "建平县",
                        "parent_code": 211300,
                        "level": 3
                    },
                    {
                        "code": 211324,
                        "areaName": "喀喇沁左翼蒙古族自治县",
                        "parent_code": 211300,
                        "level": 3
                    },
                    {
                        "code": 211381,
                        "areaName": "北票市",
                        "parent_code": 211300,
                        "level": 3
                    },
                    {
                        "code": 211382,
                        "areaName": "凌源市",
                        "parent_code": 211300,
                        "level": 3
                    },
                    {
                        "code": 211383,
                        "areaName": "朝阳市",
                        "parent_code": 211300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 211400,
                "areaName": "葫芦岛市",
                "parent_code": 210000,
                "level": 2,
                "children": [
                    {
                        "code": 211402,
                        "areaName": "连山区",
                        "parent_code": 211400,
                        "level": 3
                    },
                    {
                        "code": 211403,
                        "areaName": "龙港区",
                        "parent_code": 211400,
                        "level": 3
                    },
                    {
                        "code": 211404,
                        "areaName": "南票区",
                        "parent_code": 211400,
                        "level": 3
                    },
                    {
                        "code": 211421,
                        "areaName": "绥中县",
                        "parent_code": 211400,
                        "level": 3
                    },
                    {
                        "code": 211422,
                        "areaName": "建昌县",
                        "parent_code": 211400,
                        "level": 3
                    },
                    {
                        "code": 211481,
                        "areaName": "兴城市",
                        "parent_code": 211400,
                        "level": 3
                    },
                    {
                        "code": 211482,
                        "areaName": "葫芦岛市",
                        "parent_code": 211400,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 220000,
        "areaName": "吉林省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 220100,
                "areaName": "长春市",
                "parent_code": 220000,
                "level": 2,
                "children": [
                    {
                        "code": 220102,
                        "areaName": "南关区",
                        "parent_code": 220100,
                        "level": 3
                    },
                    {
                        "code": 220103,
                        "areaName": "宽城区",
                        "parent_code": 220100,
                        "level": 3
                    },
                    {
                        "code": 220104,
                        "areaName": "朝阳区",
                        "parent_code": 220100,
                        "level": 3
                    },
                    {
                        "code": 220105,
                        "areaName": "二道区",
                        "parent_code": 220100,
                        "level": 3
                    },
                    {
                        "code": 220106,
                        "areaName": "绿园区",
                        "parent_code": 220100,
                        "level": 3
                    },
                    {
                        "code": 220112,
                        "areaName": "双阳区",
                        "parent_code": 220100,
                        "level": 3
                    },
                    {
                        "code": 220122,
                        "areaName": "农安县",
                        "parent_code": 220100,
                        "level": 3
                    },
                    {
                        "code": 220181,
                        "areaName": "九台区",
                        "parent_code": 220100,
                        "level": 3
                    },
                    {
                        "code": 220182,
                        "areaName": "榆树市",
                        "parent_code": 220100,
                        "level": 3
                    },
                    {
                        "code": 220183,
                        "areaName": "德惠市",
                        "parent_code": 220100,
                        "level": 3
                    },
                    {
                        "code": 220184,
                        "areaName": "长春市",
                        "parent_code": 220100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 220200,
                "areaName": "吉林市",
                "parent_code": 220000,
                "level": 2,
                "children": [
                    {
                        "code": 220202,
                        "areaName": "昌邑区",
                        "parent_code": 220200,
                        "level": 3
                    },
                    {
                        "code": 220203,
                        "areaName": "龙潭区",
                        "parent_code": 220200,
                        "level": 3
                    },
                    {
                        "code": 220204,
                        "areaName": "船营区",
                        "parent_code": 220200,
                        "level": 3
                    },
                    {
                        "code": 220211,
                        "areaName": "丰满区",
                        "parent_code": 220200,
                        "level": 3
                    },
                    {
                        "code": 220221,
                        "areaName": "永吉县",
                        "parent_code": 220200,
                        "level": 3
                    },
                    {
                        "code": 220281,
                        "areaName": "蛟河市",
                        "parent_code": 220200,
                        "level": 3
                    },
                    {
                        "code": 220282,
                        "areaName": "桦甸市",
                        "parent_code": 220200,
                        "level": 3
                    },
                    {
                        "code": 220283,
                        "areaName": "舒兰市",
                        "parent_code": 220200,
                        "level": 3
                    },
                    {
                        "code": 220284,
                        "areaName": "磐石市",
                        "parent_code": 220200,
                        "level": 3
                    },
                    {
                        "code": 220285,
                        "areaName": "吉林市",
                        "parent_code": 220200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 220300,
                "areaName": "四平市",
                "parent_code": 220000,
                "level": 2,
                "children": [
                    {
                        "code": 220302,
                        "areaName": "铁西区",
                        "parent_code": 220300,
                        "level": 3
                    },
                    {
                        "code": 220303,
                        "areaName": "铁东区",
                        "parent_code": 220300,
                        "level": 3
                    },
                    {
                        "code": 220322,
                        "areaName": "梨树县",
                        "parent_code": 220300,
                        "level": 3
                    },
                    {
                        "code": 220323,
                        "areaName": "伊通满族自治县",
                        "parent_code": 220300,
                        "level": 3
                    },
                    {
                        "code": 220381,
                        "areaName": "公主岭市",
                        "parent_code": 220300,
                        "level": 3
                    },
                    {
                        "code": 220382,
                        "areaName": "双辽市",
                        "parent_code": 220300,
                        "level": 3
                    },
                    {
                        "code": 220383,
                        "areaName": "四平市",
                        "parent_code": 220300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 220400,
                "areaName": "辽源市",
                "parent_code": 220000,
                "level": 2,
                "children": [
                    {
                        "code": 220402,
                        "areaName": "龙山区",
                        "parent_code": 220400,
                        "level": 3
                    },
                    {
                        "code": 220403,
                        "areaName": "西安区",
                        "parent_code": 220400,
                        "level": 3
                    },
                    {
                        "code": 220421,
                        "areaName": "东丰县",
                        "parent_code": 220400,
                        "level": 3
                    },
                    {
                        "code": 220422,
                        "areaName": "东辽县",
                        "parent_code": 220400,
                        "level": 3
                    },
                    {
                        "code": 220423,
                        "areaName": "辽源市",
                        "parent_code": 220400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 220500,
                "areaName": "通化市",
                "parent_code": 220000,
                "level": 2,
                "children": [
                    {
                        "code": 220502,
                        "areaName": "东昌区",
                        "parent_code": 220500,
                        "level": 3
                    },
                    {
                        "code": 220503,
                        "areaName": "二道江区",
                        "parent_code": 220500,
                        "level": 3
                    },
                    {
                        "code": 220521,
                        "areaName": "通化县",
                        "parent_code": 220500,
                        "level": 3
                    },
                    {
                        "code": 220523,
                        "areaName": "辉南县",
                        "parent_code": 220500,
                        "level": 3
                    },
                    {
                        "code": 220524,
                        "areaName": "柳河县",
                        "parent_code": 220500,
                        "level": 3
                    },
                    {
                        "code": 220581,
                        "areaName": "梅河口市",
                        "parent_code": 220500,
                        "level": 3
                    },
                    {
                        "code": 220582,
                        "areaName": "集安市",
                        "parent_code": 220500,
                        "level": 3
                    },
                    {
                        "code": 220583,
                        "areaName": "通化市",
                        "parent_code": 220500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 220600,
                "areaName": "白山市",
                "parent_code": 220000,
                "level": 2,
                "children": [
                    {
                        "code": 220602,
                        "areaName": "浑江区",
                        "parent_code": 220600,
                        "level": 3
                    },
                    {
                        "code": 220605,
                        "areaName": "江源区",
                        "parent_code": 220600,
                        "level": 3
                    },
                    {
                        "code": 220621,
                        "areaName": "抚松县",
                        "parent_code": 220600,
                        "level": 3
                    },
                    {
                        "code": 220622,
                        "areaName": "靖宇县",
                        "parent_code": 220600,
                        "level": 3
                    },
                    {
                        "code": 220623,
                        "areaName": "长白朝鲜族自治县",
                        "parent_code": 220600,
                        "level": 3
                    },
                    {
                        "code": 220681,
                        "areaName": "临江市",
                        "parent_code": 220600,
                        "level": 3
                    },
                    {
                        "code": 220682,
                        "areaName": "白山市",
                        "parent_code": 220600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 220700,
                "areaName": "松原市",
                "parent_code": 220000,
                "level": 2,
                "children": [
                    {
                        "code": 220702,
                        "areaName": "宁江区",
                        "parent_code": 220700,
                        "level": 3
                    },
                    {
                        "code": 220721,
                        "areaName": "前郭尔罗斯蒙古族自治县",
                        "parent_code": 220700,
                        "level": 3
                    },
                    {
                        "code": 220722,
                        "areaName": "长岭县",
                        "parent_code": 220700,
                        "level": 3
                    },
                    {
                        "code": 220723,
                        "areaName": "乾安县",
                        "parent_code": 220700,
                        "level": 3
                    },
                    {
                        "code": 220781,
                        "areaName": "扶余市",
                        "parent_code": 220700,
                        "level": 3
                    },
                    {
                        "code": 220782,
                        "areaName": "松原市",
                        "parent_code": 220700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 220800,
                "areaName": "白城市",
                "parent_code": 220000,
                "level": 2,
                "children": [
                    {
                        "code": 220802,
                        "areaName": "洮北区",
                        "parent_code": 220800,
                        "level": 3
                    },
                    {
                        "code": 220821,
                        "areaName": "镇赉县",
                        "parent_code": 220800,
                        "level": 3
                    },
                    {
                        "code": 220822,
                        "areaName": "通榆县",
                        "parent_code": 220800,
                        "level": 3
                    },
                    {
                        "code": 220881,
                        "areaName": "洮南市",
                        "parent_code": 220800,
                        "level": 3
                    },
                    {
                        "code": 220882,
                        "areaName": "大安市",
                        "parent_code": 220800,
                        "level": 3
                    },
                    {
                        "code": 220883,
                        "areaName": "白城市",
                        "parent_code": 220800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 222400,
                "areaName": "延边朝鲜族自治州",
                "parent_code": 220000,
                "level": 2,
                "children": [
                    {
                        "code": 222401,
                        "areaName": "延吉市",
                        "parent_code": 222400,
                        "level": 3
                    },
                    {
                        "code": 222402,
                        "areaName": "图们市",
                        "parent_code": 222400,
                        "level": 3
                    },
                    {
                        "code": 222403,
                        "areaName": "敦化市",
                        "parent_code": 222400,
                        "level": 3
                    },
                    {
                        "code": 222404,
                        "areaName": "珲春市",
                        "parent_code": 222400,
                        "level": 3
                    },
                    {
                        "code": 222405,
                        "areaName": "龙井市",
                        "parent_code": 222400,
                        "level": 3
                    },
                    {
                        "code": 222406,
                        "areaName": "和龙市",
                        "parent_code": 222400,
                        "level": 3
                    },
                    {
                        "code": 222424,
                        "areaName": "汪清县",
                        "parent_code": 222400,
                        "level": 3
                    },
                    {
                        "code": 222426,
                        "areaName": "安图县",
                        "parent_code": 222400,
                        "level": 3
                    },
                    {
                        "code": 222427,
                        "areaName": "延边朝鲜族自治州",
                        "parent_code": 222400,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 230000,
        "areaName": "黑龙江省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 230100,
                "areaName": "哈尔滨市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 230102,
                        "areaName": "道里区",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230103,
                        "areaName": "南岗区",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230104,
                        "areaName": "道外区",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230108,
                        "areaName": "平房区",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230109,
                        "areaName": "松北区",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230110,
                        "areaName": "香坊区",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230111,
                        "areaName": "呼兰区",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230112,
                        "areaName": "阿城区",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230123,
                        "areaName": "依兰县",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230124,
                        "areaName": "方正县",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230125,
                        "areaName": "宾县",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230126,
                        "areaName": "巴彦县",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230127,
                        "areaName": "木兰县",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230128,
                        "areaName": "通河县",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230129,
                        "areaName": "延寿县",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230182,
                        "areaName": "双城区",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230183,
                        "areaName": "尚志市",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230184,
                        "areaName": "五常市",
                        "parent_code": 230100,
                        "level": 3
                    },
                    {
                        "code": 230185,
                        "areaName": "哈尔滨市",
                        "parent_code": 230100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 230200,
                "areaName": "齐齐哈尔市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 230202,
                        "areaName": "龙沙区",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230203,
                        "areaName": "建华区",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230204,
                        "areaName": "铁锋区",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230205,
                        "areaName": "昂昂溪区",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230206,
                        "areaName": "富拉尔基区",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230207,
                        "areaName": "碾子山区",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230208,
                        "areaName": "梅里斯达斡尔族区",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230221,
                        "areaName": "龙江县",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230223,
                        "areaName": "依安县",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230224,
                        "areaName": "泰来县",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230225,
                        "areaName": "甘南县",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230227,
                        "areaName": "富裕县",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230229,
                        "areaName": "克山县",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230230,
                        "areaName": "克东县",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230231,
                        "areaName": "拜泉县",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230281,
                        "areaName": "讷河市",
                        "parent_code": 230200,
                        "level": 3
                    },
                    {
                        "code": 230282,
                        "areaName": "齐齐哈尔市",
                        "parent_code": 230200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 230300,
                "areaName": "鸡西市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 230302,
                        "areaName": "鸡冠区",
                        "parent_code": 230300,
                        "level": 3
                    },
                    {
                        "code": 230303,
                        "areaName": "恒山区",
                        "parent_code": 230300,
                        "level": 3
                    },
                    {
                        "code": 230304,
                        "areaName": "滴道区",
                        "parent_code": 230300,
                        "level": 3
                    },
                    {
                        "code": 230305,
                        "areaName": "梨树区",
                        "parent_code": 230300,
                        "level": 3
                    },
                    {
                        "code": 230306,
                        "areaName": "城子河区",
                        "parent_code": 230300,
                        "level": 3
                    },
                    {
                        "code": 230307,
                        "areaName": "麻山区",
                        "parent_code": 230300,
                        "level": 3
                    },
                    {
                        "code": 230321,
                        "areaName": "鸡东县",
                        "parent_code": 230300,
                        "level": 3
                    },
                    {
                        "code": 230381,
                        "areaName": "虎林市",
                        "parent_code": 230300,
                        "level": 3
                    },
                    {
                        "code": 230382,
                        "areaName": "密山市",
                        "parent_code": 230300,
                        "level": 3
                    },
                    {
                        "code": 230383,
                        "areaName": "鸡西市",
                        "parent_code": 230300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 230400,
                "areaName": "鹤岗市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 230402,
                        "areaName": "向阳区",
                        "parent_code": 230400,
                        "level": 3
                    },
                    {
                        "code": 230403,
                        "areaName": "工农区",
                        "parent_code": 230400,
                        "level": 3
                    },
                    {
                        "code": 230404,
                        "areaName": "南山区",
                        "parent_code": 230400,
                        "level": 3
                    },
                    {
                        "code": 230405,
                        "areaName": "兴安区",
                        "parent_code": 230400,
                        "level": 3
                    },
                    {
                        "code": 230406,
                        "areaName": "东山区",
                        "parent_code": 230400,
                        "level": 3
                    },
                    {
                        "code": 230407,
                        "areaName": "兴山区",
                        "parent_code": 230400,
                        "level": 3
                    },
                    {
                        "code": 230421,
                        "areaName": "萝北县",
                        "parent_code": 230400,
                        "level": 3
                    },
                    {
                        "code": 230422,
                        "areaName": "绥滨县",
                        "parent_code": 230400,
                        "level": 3
                    },
                    {
                        "code": 230423,
                        "areaName": "鹤岗市",
                        "parent_code": 230400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 230500,
                "areaName": "双鸭山市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 230502,
                        "areaName": "尖山区",
                        "parent_code": 230500,
                        "level": 3
                    },
                    {
                        "code": 230503,
                        "areaName": "岭东区",
                        "parent_code": 230500,
                        "level": 3
                    },
                    {
                        "code": 230505,
                        "areaName": "四方台区",
                        "parent_code": 230500,
                        "level": 3
                    },
                    {
                        "code": 230506,
                        "areaName": "宝山区",
                        "parent_code": 230500,
                        "level": 3
                    },
                    {
                        "code": 230521,
                        "areaName": "集贤县",
                        "parent_code": 230500,
                        "level": 3
                    },
                    {
                        "code": 230522,
                        "areaName": "友谊县",
                        "parent_code": 230500,
                        "level": 3
                    },
                    {
                        "code": 230523,
                        "areaName": "宝清县",
                        "parent_code": 230500,
                        "level": 3
                    },
                    {
                        "code": 230524,
                        "areaName": "饶河县",
                        "parent_code": 230500,
                        "level": 3
                    },
                    {
                        "code": 230525,
                        "areaName": "双鸭山市",
                        "parent_code": 230500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 230600,
                "areaName": "大庆市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 230602,
                        "areaName": "萨尔图区",
                        "parent_code": 230600,
                        "level": 3
                    },
                    {
                        "code": 230603,
                        "areaName": "龙凤区",
                        "parent_code": 230600,
                        "level": 3
                    },
                    {
                        "code": 230604,
                        "areaName": "让胡路区",
                        "parent_code": 230600,
                        "level": 3
                    },
                    {
                        "code": 230605,
                        "areaName": "红岗区",
                        "parent_code": 230600,
                        "level": 3
                    },
                    {
                        "code": 230606,
                        "areaName": "大同区",
                        "parent_code": 230600,
                        "level": 3
                    },
                    {
                        "code": 230621,
                        "areaName": "肇州县",
                        "parent_code": 230600,
                        "level": 3
                    },
                    {
                        "code": 230622,
                        "areaName": "肇源县",
                        "parent_code": 230600,
                        "level": 3
                    },
                    {
                        "code": 230623,
                        "areaName": "林甸县",
                        "parent_code": 230600,
                        "level": 3
                    },
                    {
                        "code": 230624,
                        "areaName": "杜尔伯特蒙古族自治县",
                        "parent_code": 230600,
                        "level": 3
                    },
                    {
                        "code": 230625,
                        "areaName": "大庆市",
                        "parent_code": 230600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 230700,
                "areaName": "伊春市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 230702,
                        "areaName": "伊春区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230703,
                        "areaName": "南岔区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230704,
                        "areaName": "友好区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230705,
                        "areaName": "西林区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230706,
                        "areaName": "翠峦区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230707,
                        "areaName": "新青区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230708,
                        "areaName": "美溪区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230709,
                        "areaName": "金山屯区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230710,
                        "areaName": "五营区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230711,
                        "areaName": "乌马河区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230712,
                        "areaName": "汤旺河区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230713,
                        "areaName": "带岭区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230714,
                        "areaName": "乌伊岭区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230715,
                        "areaName": "红星区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230716,
                        "areaName": "上甘岭区",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230722,
                        "areaName": "嘉荫县",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230781,
                        "areaName": "铁力市",
                        "parent_code": 230700,
                        "level": 3
                    },
                    {
                        "code": 230782,
                        "areaName": "伊春市",
                        "parent_code": 230700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 230800,
                "areaName": "佳木斯市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 230803,
                        "areaName": "向阳区",
                        "parent_code": 230800,
                        "level": 3
                    },
                    {
                        "code": 230804,
                        "areaName": "前进区",
                        "parent_code": 230800,
                        "level": 3
                    },
                    {
                        "code": 230805,
                        "areaName": "东风区",
                        "parent_code": 230800,
                        "level": 3
                    },
                    {
                        "code": 230811,
                        "areaName": "郊区",
                        "parent_code": 230800,
                        "level": 3
                    },
                    {
                        "code": 230822,
                        "areaName": "桦南县",
                        "parent_code": 230800,
                        "level": 3
                    },
                    {
                        "code": 230826,
                        "areaName": "桦川县",
                        "parent_code": 230800,
                        "level": 3
                    },
                    {
                        "code": 230828,
                        "areaName": "汤原县",
                        "parent_code": 230800,
                        "level": 3
                    },
                    {
                        "code": 230833,
                        "areaName": "抚远县",
                        "parent_code": 230800,
                        "level": 3
                    },
                    {
                        "code": 230881,
                        "areaName": "同江市",
                        "parent_code": 230800,
                        "level": 3
                    },
                    {
                        "code": 230882,
                        "areaName": "富锦市",
                        "parent_code": 230800,
                        "level": 3
                    },
                    {
                        "code": 230883,
                        "areaName": "佳木斯市",
                        "parent_code": 230800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 230900,
                "areaName": "七台河市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 230902,
                        "areaName": "新兴区",
                        "parent_code": 230900,
                        "level": 3
                    },
                    {
                        "code": 230903,
                        "areaName": "桃山区",
                        "parent_code": 230900,
                        "level": 3
                    },
                    {
                        "code": 230904,
                        "areaName": "茄子河区",
                        "parent_code": 230900,
                        "level": 3
                    },
                    {
                        "code": 230921,
                        "areaName": "勃利县",
                        "parent_code": 230900,
                        "level": 3
                    },
                    {
                        "code": 230922,
                        "areaName": "七台河市",
                        "parent_code": 230900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 231000,
                "areaName": "牡丹江市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 231002,
                        "areaName": "东安区",
                        "parent_code": 231000,
                        "level": 3
                    },
                    {
                        "code": 231003,
                        "areaName": "阳明区",
                        "parent_code": 231000,
                        "level": 3
                    },
                    {
                        "code": 231004,
                        "areaName": "爱民区",
                        "parent_code": 231000,
                        "level": 3
                    },
                    {
                        "code": 231005,
                        "areaName": "西安区",
                        "parent_code": 231000,
                        "level": 3
                    },
                    {
                        "code": 231024,
                        "areaName": "东宁县",
                        "parent_code": 231000,
                        "level": 3
                    },
                    {
                        "code": 231025,
                        "areaName": "林口县",
                        "parent_code": 231000,
                        "level": 3
                    },
                    {
                        "code": 231081,
                        "areaName": "绥芬河市",
                        "parent_code": 231000,
                        "level": 3
                    },
                    {
                        "code": 231083,
                        "areaName": "海林市",
                        "parent_code": 231000,
                        "level": 3
                    },
                    {
                        "code": 231084,
                        "areaName": "宁安市",
                        "parent_code": 231000,
                        "level": 3
                    },
                    {
                        "code": 231085,
                        "areaName": "穆棱市",
                        "parent_code": 231000,
                        "level": 3
                    },
                    {
                        "code": 231086,
                        "areaName": "牡丹江市",
                        "parent_code": 231000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 231100,
                "areaName": "黑河市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 231102,
                        "areaName": "爱辉区",
                        "parent_code": 231100,
                        "level": 3
                    },
                    {
                        "code": 231121,
                        "areaName": "嫩江县",
                        "parent_code": 231100,
                        "level": 3
                    },
                    {
                        "code": 231123,
                        "areaName": "逊克县",
                        "parent_code": 231100,
                        "level": 3
                    },
                    {
                        "code": 231124,
                        "areaName": "孙吴县",
                        "parent_code": 231100,
                        "level": 3
                    },
                    {
                        "code": 231181,
                        "areaName": "北安市",
                        "parent_code": 231100,
                        "level": 3
                    },
                    {
                        "code": 231182,
                        "areaName": "五大连池市",
                        "parent_code": 231100,
                        "level": 3
                    },
                    {
                        "code": 231183,
                        "areaName": "黑河市",
                        "parent_code": 231100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 231200,
                "areaName": "绥化市",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 231202,
                        "areaName": "北林区",
                        "parent_code": 231200,
                        "level": 3
                    },
                    {
                        "code": 231221,
                        "areaName": "望奎县",
                        "parent_code": 231200,
                        "level": 3
                    },
                    {
                        "code": 231222,
                        "areaName": "兰西县",
                        "parent_code": 231200,
                        "level": 3
                    },
                    {
                        "code": 231223,
                        "areaName": "青冈县",
                        "parent_code": 231200,
                        "level": 3
                    },
                    {
                        "code": 231224,
                        "areaName": "庆安县",
                        "parent_code": 231200,
                        "level": 3
                    },
                    {
                        "code": 231225,
                        "areaName": "明水县",
                        "parent_code": 231200,
                        "level": 3
                    },
                    {
                        "code": 231226,
                        "areaName": "绥棱县",
                        "parent_code": 231200,
                        "level": 3
                    },
                    {
                        "code": 231281,
                        "areaName": "安达市",
                        "parent_code": 231200,
                        "level": 3
                    },
                    {
                        "code": 231282,
                        "areaName": "肇东市",
                        "parent_code": 231200,
                        "level": 3
                    },
                    {
                        "code": 231283,
                        "areaName": "海伦市",
                        "parent_code": 231200,
                        "level": 3
                    },
                    {
                        "code": 231284,
                        "areaName": "绥化市",
                        "parent_code": 231200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 232700,
                "areaName": "大兴安岭地区",
                "parent_code": 230000,
                "level": 2,
                "children": [
                    {
                        "code": 232721,
                        "areaName": "呼玛县",
                        "parent_code": 232700,
                        "level": 3
                    },
                    {
                        "code": 232722,
                        "areaName": "塔河县",
                        "parent_code": 232700,
                        "level": 3
                    },
                    {
                        "code": 232723,
                        "areaName": "漠河县",
                        "parent_code": 232700,
                        "level": 3
                    },
                    {
                        "code": 232724,
                        "areaName": "大兴安岭地区",
                        "parent_code": 232700,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 310000,
        "areaName": "上海市",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 310100,
                "areaName": "市辖区",
                "parent_code": 310000,
                "level": 2,
                "children": [
                    {
                        "code": 310101,
                        "areaName": "黄浦区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310104,
                        "areaName": "徐汇区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310105,
                        "areaName": "长宁区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310106,
                        "areaName": "静安区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310107,
                        "areaName": "普陀区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310108,
                        "areaName": "闸北区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310109,
                        "areaName": "虹口区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310110,
                        "areaName": "杨浦区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310112,
                        "areaName": "闵行区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310113,
                        "areaName": "宝山区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310114,
                        "areaName": "嘉定区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310115,
                        "areaName": "浦东新区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310116,
                        "areaName": "金山区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310117,
                        "areaName": "松江区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310118,
                        "areaName": "青浦区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310120,
                        "areaName": "奉贤区",
                        "parent_code": 310100,
                        "level": 3
                    },
                    {
                        "code": 310121,
                        "areaName": "市辖区",
                        "parent_code": 310100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 310200,
                "areaName": "县",
                "parent_code": 310000,
                "level": 2,
                "children": [
                    {
                        "code": 310230,
                        "areaName": "崇明县",
                        "parent_code": 310200,
                        "level": 3
                    },
                    {
                        "code": 310231,
                        "areaName": "县",
                        "parent_code": 310200,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 320000,
        "areaName": "江苏省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 320100,
                "areaName": "南京市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 320102,
                        "areaName": "玄武区",
                        "parent_code": 320100,
                        "level": 3
                    },
                    {
                        "code": 320104,
                        "areaName": "秦淮区",
                        "parent_code": 320100,
                        "level": 3
                    },
                    {
                        "code": 320105,
                        "areaName": "建邺区",
                        "parent_code": 320100,
                        "level": 3
                    },
                    {
                        "code": 320106,
                        "areaName": "鼓楼区",
                        "parent_code": 320100,
                        "level": 3
                    },
                    {
                        "code": 320111,
                        "areaName": "浦口区",
                        "parent_code": 320100,
                        "level": 3
                    },
                    {
                        "code": 320113,
                        "areaName": "栖霞区",
                        "parent_code": 320100,
                        "level": 3
                    },
                    {
                        "code": 320114,
                        "areaName": "雨花台区",
                        "parent_code": 320100,
                        "level": 3
                    },
                    {
                        "code": 320115,
                        "areaName": "江宁区",
                        "parent_code": 320100,
                        "level": 3
                    },
                    {
                        "code": 320116,
                        "areaName": "六合区",
                        "parent_code": 320100,
                        "level": 3
                    },
                    {
                        "code": 320117,
                        "areaName": "溧水区",
                        "parent_code": 320100,
                        "level": 3
                    },
                    {
                        "code": 320118,
                        "areaName": "高淳区",
                        "parent_code": 320100,
                        "level": 3
                    },
                    {
                        "code": 320119,
                        "areaName": "南京市",
                        "parent_code": 320100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 320200,
                "areaName": "无锡市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 320202,
                        "areaName": "崇安区",
                        "parent_code": 320200,
                        "level": 3
                    },
                    {
                        "code": 320203,
                        "areaName": "南长区",
                        "parent_code": 320200,
                        "level": 3
                    },
                    {
                        "code": 320204,
                        "areaName": "北塘区",
                        "parent_code": 320200,
                        "level": 3
                    },
                    {
                        "code": 320205,
                        "areaName": "锡山区",
                        "parent_code": 320200,
                        "level": 3
                    },
                    {
                        "code": 320206,
                        "areaName": "惠山区",
                        "parent_code": 320200,
                        "level": 3
                    },
                    {
                        "code": 320211,
                        "areaName": "滨湖区",
                        "parent_code": 320200,
                        "level": 3
                    },
                    {
                        "code": 320281,
                        "areaName": "江阴市",
                        "parent_code": 320200,
                        "level": 3
                    },
                    {
                        "code": 320282,
                        "areaName": "宜兴市",
                        "parent_code": 320200,
                        "level": 3
                    },
                    {
                        "code": 320283,
                        "areaName": "无锡市",
                        "parent_code": 320200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 320300,
                "areaName": "徐州市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 320302,
                        "areaName": "鼓楼区",
                        "parent_code": 320300,
                        "level": 3
                    },
                    {
                        "code": 320303,
                        "areaName": "云龙区",
                        "parent_code": 320300,
                        "level": 3
                    },
                    {
                        "code": 320305,
                        "areaName": "贾汪区",
                        "parent_code": 320300,
                        "level": 3
                    },
                    {
                        "code": 320311,
                        "areaName": "泉山区",
                        "parent_code": 320300,
                        "level": 3
                    },
                    {
                        "code": 320312,
                        "areaName": "铜山区",
                        "parent_code": 320300,
                        "level": 3
                    },
                    {
                        "code": 320321,
                        "areaName": "丰县",
                        "parent_code": 320300,
                        "level": 3
                    },
                    {
                        "code": 320322,
                        "areaName": "沛县",
                        "parent_code": 320300,
                        "level": 3
                    },
                    {
                        "code": 320324,
                        "areaName": "睢宁县",
                        "parent_code": 320300,
                        "level": 3
                    },
                    {
                        "code": 320381,
                        "areaName": "新沂市",
                        "parent_code": 320300,
                        "level": 3
                    },
                    {
                        "code": 320382,
                        "areaName": "邳州市",
                        "parent_code": 320300,
                        "level": 3
                    },
                    {
                        "code": 320383,
                        "areaName": "徐州市",
                        "parent_code": 320300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 320400,
                "areaName": "常州市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 320402,
                        "areaName": "天宁区",
                        "parent_code": 320400,
                        "level": 3
                    },
                    {
                        "code": 320404,
                        "areaName": "钟楼区",
                        "parent_code": 320400,
                        "level": 3
                    },
                    {
                        "code": 320405,
                        "areaName": "戚墅堰区",
                        "parent_code": 320400,
                        "level": 3
                    },
                    {
                        "code": 320411,
                        "areaName": "新北区",
                        "parent_code": 320400,
                        "level": 3
                    },
                    {
                        "code": 320412,
                        "areaName": "武进区",
                        "parent_code": 320400,
                        "level": 3
                    },
                    {
                        "code": 320481,
                        "areaName": "溧阳市",
                        "parent_code": 320400,
                        "level": 3
                    },
                    {
                        "code": 320482,
                        "areaName": "金坛市",
                        "parent_code": 320400,
                        "level": 3
                    },
                    {
                        "code": 320483,
                        "areaName": "常州市",
                        "parent_code": 320400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 320500,
                "areaName": "苏州市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 320505,
                        "areaName": "虎丘区",
                        "parent_code": 320500,
                        "level": 3
                    },
                    {
                        "code": 320506,
                        "areaName": "吴中区",
                        "parent_code": 320500,
                        "level": 3
                    },
                    {
                        "code": 320507,
                        "areaName": "相城区",
                        "parent_code": 320500,
                        "level": 3
                    },
                    {
                        "code": 320508,
                        "areaName": "姑苏区",
                        "parent_code": 320500,
                        "level": 3
                    },
                    {
                        "code": 320509,
                        "areaName": "吴江区",
                        "parent_code": 320500,
                        "level": 3
                    },
                    {
                        "code": 320581,
                        "areaName": "常熟市",
                        "parent_code": 320500,
                        "level": 3
                    },
                    {
                        "code": 320582,
                        "areaName": "张家港市",
                        "parent_code": 320500,
                        "level": 3
                    },
                    {
                        "code": 320583,
                        "areaName": "昆山市",
                        "parent_code": 320500,
                        "level": 3
                    },
                    {
                        "code": 320585,
                        "areaName": "太仓市",
                        "parent_code": 320500,
                        "level": 3
                    },
                    {
                        "code": 320586,
                        "areaName": "苏州市",
                        "parent_code": 320500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 320600,
                "areaName": "南通市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 320602,
                        "areaName": "崇川区",
                        "parent_code": 320600,
                        "level": 3
                    },
                    {
                        "code": 320611,
                        "areaName": "港闸区",
                        "parent_code": 320600,
                        "level": 3
                    },
                    {
                        "code": 320612,
                        "areaName": "通州区",
                        "parent_code": 320600,
                        "level": 3
                    },
                    {
                        "code": 320621,
                        "areaName": "海安县",
                        "parent_code": 320600,
                        "level": 3
                    },
                    {
                        "code": 320623,
                        "areaName": "如东县",
                        "parent_code": 320600,
                        "level": 3
                    },
                    {
                        "code": 320681,
                        "areaName": "启东市",
                        "parent_code": 320600,
                        "level": 3
                    },
                    {
                        "code": 320682,
                        "areaName": "如皋市",
                        "parent_code": 320600,
                        "level": 3
                    },
                    {
                        "code": 320684,
                        "areaName": "海门市",
                        "parent_code": 320600,
                        "level": 3
                    },
                    {
                        "code": 320685,
                        "areaName": "南通市",
                        "parent_code": 320600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 320700,
                "areaName": "连云港市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 320703,
                        "areaName": "连云区",
                        "parent_code": 320700,
                        "level": 3
                    },
                    {
                        "code": 320705,
                        "areaName": "新浦区",
                        "parent_code": 320700,
                        "level": 3
                    },
                    {
                        "code": 320706,
                        "areaName": "海州区",
                        "parent_code": 320700,
                        "level": 3
                    },
                    {
                        "code": 320721,
                        "areaName": "赣榆区",
                        "parent_code": 320700,
                        "level": 3
                    },
                    {
                        "code": 320722,
                        "areaName": "东海县",
                        "parent_code": 320700,
                        "level": 3
                    },
                    {
                        "code": 320723,
                        "areaName": "灌云县",
                        "parent_code": 320700,
                        "level": 3
                    },
                    {
                        "code": 320724,
                        "areaName": "灌南县",
                        "parent_code": 320700,
                        "level": 3
                    },
                    {
                        "code": 320725,
                        "areaName": "连云港市",
                        "parent_code": 320700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 320800,
                "areaName": "淮安市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 320802,
                        "areaName": "清河区",
                        "parent_code": 320800,
                        "level": 3
                    },
                    {
                        "code": 320803,
                        "areaName": "淮安区",
                        "parent_code": 320800,
                        "level": 3
                    },
                    {
                        "code": 320804,
                        "areaName": "淮阴区",
                        "parent_code": 320800,
                        "level": 3
                    },
                    {
                        "code": 320811,
                        "areaName": "清浦区",
                        "parent_code": 320800,
                        "level": 3
                    },
                    {
                        "code": 320826,
                        "areaName": "涟水县",
                        "parent_code": 320800,
                        "level": 3
                    },
                    {
                        "code": 320829,
                        "areaName": "洪泽县",
                        "parent_code": 320800,
                        "level": 3
                    },
                    {
                        "code": 320830,
                        "areaName": "盱眙县",
                        "parent_code": 320800,
                        "level": 3
                    },
                    {
                        "code": 320831,
                        "areaName": "金湖县",
                        "parent_code": 320800,
                        "level": 3
                    },
                    {
                        "code": 320832,
                        "areaName": "淮安市",
                        "parent_code": 320800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 320900,
                "areaName": "盐城市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 320902,
                        "areaName": "亭湖区",
                        "parent_code": 320900,
                        "level": 3
                    },
                    {
                        "code": 320903,
                        "areaName": "盐都区",
                        "parent_code": 320900,
                        "level": 3
                    },
                    {
                        "code": 320921,
                        "areaName": "响水县",
                        "parent_code": 320900,
                        "level": 3
                    },
                    {
                        "code": 320922,
                        "areaName": "滨海县",
                        "parent_code": 320900,
                        "level": 3
                    },
                    {
                        "code": 320923,
                        "areaName": "阜宁县",
                        "parent_code": 320900,
                        "level": 3
                    },
                    {
                        "code": 320924,
                        "areaName": "射阳县",
                        "parent_code": 320900,
                        "level": 3
                    },
                    {
                        "code": 320925,
                        "areaName": "建湖县",
                        "parent_code": 320900,
                        "level": 3
                    },
                    {
                        "code": 320981,
                        "areaName": "东台市",
                        "parent_code": 320900,
                        "level": 3
                    },
                    {
                        "code": 320982,
                        "areaName": "大丰市",
                        "parent_code": 320900,
                        "level": 3
                    },
                    {
                        "code": 320983,
                        "areaName": "盐城市",
                        "parent_code": 320900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 321000,
                "areaName": "扬州市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 321002,
                        "areaName": "广陵区",
                        "parent_code": 321000,
                        "level": 3
                    },
                    {
                        "code": 321003,
                        "areaName": "邗江区",
                        "parent_code": 321000,
                        "level": 3
                    },
                    {
                        "code": 321012,
                        "areaName": "江都区",
                        "parent_code": 321000,
                        "level": 3
                    },
                    {
                        "code": 321023,
                        "areaName": "宝应县",
                        "parent_code": 321000,
                        "level": 3
                    },
                    {
                        "code": 321081,
                        "areaName": "仪征市",
                        "parent_code": 321000,
                        "level": 3
                    },
                    {
                        "code": 321084,
                        "areaName": "高邮市",
                        "parent_code": 321000,
                        "level": 3
                    },
                    {
                        "code": 321085,
                        "areaName": "扬州市",
                        "parent_code": 321000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 321100,
                "areaName": "镇江市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 321102,
                        "areaName": "京口区",
                        "parent_code": 321100,
                        "level": 3
                    },
                    {
                        "code": 321111,
                        "areaName": "润州区",
                        "parent_code": 321100,
                        "level": 3
                    },
                    {
                        "code": 321112,
                        "areaName": "丹徒区",
                        "parent_code": 321100,
                        "level": 3
                    },
                    {
                        "code": 321181,
                        "areaName": "丹阳市",
                        "parent_code": 321100,
                        "level": 3
                    },
                    {
                        "code": 321182,
                        "areaName": "扬中市",
                        "parent_code": 321100,
                        "level": 3
                    },
                    {
                        "code": 321183,
                        "areaName": "句容市",
                        "parent_code": 321100,
                        "level": 3
                    },
                    {
                        "code": 321184,
                        "areaName": "镇江市",
                        "parent_code": 321100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 321200,
                "areaName": "泰州市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 321202,
                        "areaName": "海陵区",
                        "parent_code": 321200,
                        "level": 3
                    },
                    {
                        "code": 321203,
                        "areaName": "高港区",
                        "parent_code": 321200,
                        "level": 3
                    },
                    {
                        "code": 321204,
                        "areaName": "姜堰区",
                        "parent_code": 321200,
                        "level": 3
                    },
                    {
                        "code": 321281,
                        "areaName": "兴化市",
                        "parent_code": 321200,
                        "level": 3
                    },
                    {
                        "code": 321282,
                        "areaName": "靖江市",
                        "parent_code": 321200,
                        "level": 3
                    },
                    {
                        "code": 321283,
                        "areaName": "泰兴市",
                        "parent_code": 321200,
                        "level": 3
                    },
                    {
                        "code": 321284,
                        "areaName": "泰州市",
                        "parent_code": 321200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 321300,
                "areaName": "宿迁市",
                "parent_code": 320000,
                "level": 2,
                "children": [
                    {
                        "code": 321302,
                        "areaName": "宿城区",
                        "parent_code": 321300,
                        "level": 3
                    },
                    {
                        "code": 321311,
                        "areaName": "宿豫区",
                        "parent_code": 321300,
                        "level": 3
                    },
                    {
                        "code": 321322,
                        "areaName": "沭阳县",
                        "parent_code": 321300,
                        "level": 3
                    },
                    {
                        "code": 321323,
                        "areaName": "泗阳县",
                        "parent_code": 321300,
                        "level": 3
                    },
                    {
                        "code": 321324,
                        "areaName": "泗洪县",
                        "parent_code": 321300,
                        "level": 3
                    },
                    {
                        "code": 321325,
                        "areaName": "宿迁市",
                        "parent_code": 321300,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 330000,
        "areaName": "浙江省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 330100,
                "areaName": "杭州市",
                "parent_code": 330000,
                "level": 2,
                "children": [
                    {
                        "code": 330102,
                        "areaName": "上城区",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330103,
                        "areaName": "下城区",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330104,
                        "areaName": "江干区",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330105,
                        "areaName": "拱墅区",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330106,
                        "areaName": "西湖区",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330108,
                        "areaName": "滨江区",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330109,
                        "areaName": "萧山区",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330110,
                        "areaName": "余杭区",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330122,
                        "areaName": "桐庐县",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330127,
                        "areaName": "淳安县",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330182,
                        "areaName": "建德市",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330183,
                        "areaName": "富阳区",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330185,
                        "areaName": "临安市",
                        "parent_code": 330100,
                        "level": 3
                    },
                    {
                        "code": 330186,
                        "areaName": "杭州市",
                        "parent_code": 330100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 330200,
                "areaName": "宁波市",
                "parent_code": 330000,
                "level": 2,
                "children": [
                    {
                        "code": 330203,
                        "areaName": "海曙区",
                        "parent_code": 330200,
                        "level": 3
                    },
                    {
                        "code": 330204,
                        "areaName": "江东区",
                        "parent_code": 330200,
                        "level": 3
                    },
                    {
                        "code": 330205,
                        "areaName": "江北区",
                        "parent_code": 330200,
                        "level": 3
                    },
                    {
                        "code": 330206,
                        "areaName": "北仑区",
                        "parent_code": 330200,
                        "level": 3
                    },
                    {
                        "code": 330211,
                        "areaName": "镇海区",
                        "parent_code": 330200,
                        "level": 3
                    },
                    {
                        "code": 330212,
                        "areaName": "鄞州区",
                        "parent_code": 330200,
                        "level": 3
                    },
                    {
                        "code": 330225,
                        "areaName": "象山县",
                        "parent_code": 330200,
                        "level": 3
                    },
                    {
                        "code": 330226,
                        "areaName": "宁海县",
                        "parent_code": 330200,
                        "level": 3
                    },
                    {
                        "code": 330281,
                        "areaName": "余姚市",
                        "parent_code": 330200,
                        "level": 3
                    },
                    {
                        "code": 330282,
                        "areaName": "慈溪市",
                        "parent_code": 330200,
                        "level": 3
                    },
                    {
                        "code": 330283,
                        "areaName": "奉化市",
                        "parent_code": 330200,
                        "level": 3
                    },
                    {
                        "code": 330284,
                        "areaName": "宁波市",
                        "parent_code": 330200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 330300,
                "areaName": "温州市",
                "parent_code": 330000,
                "level": 2,
                "children": [
                    {
                        "code": 330302,
                        "areaName": "鹿城区",
                        "parent_code": 330300,
                        "level": 3
                    },
                    {
                        "code": 330303,
                        "areaName": "龙湾区",
                        "parent_code": 330300,
                        "level": 3
                    },
                    {
                        "code": 330304,
                        "areaName": "瓯海区",
                        "parent_code": 330300,
                        "level": 3
                    },
                    {
                        "code": 330322,
                        "areaName": "洞头县",
                        "parent_code": 330300,
                        "level": 3
                    },
                    {
                        "code": 330324,
                        "areaName": "永嘉县",
                        "parent_code": 330300,
                        "level": 3
                    },
                    {
                        "code": 330326,
                        "areaName": "平阳县",
                        "parent_code": 330300,
                        "level": 3
                    },
                    {
                        "code": 330327,
                        "areaName": "苍南县",
                        "parent_code": 330300,
                        "level": 3
                    },
                    {
                        "code": 330328,
                        "areaName": "文成县",
                        "parent_code": 330300,
                        "level": 3
                    },
                    {
                        "code": 330329,
                        "areaName": "泰顺县",
                        "parent_code": 330300,
                        "level": 3
                    },
                    {
                        "code": 330381,
                        "areaName": "瑞安市",
                        "parent_code": 330300,
                        "level": 3
                    },
                    {
                        "code": 330382,
                        "areaName": "乐清市",
                        "parent_code": 330300,
                        "level": 3
                    },
                    {
                        "code": 330383,
                        "areaName": "温州市",
                        "parent_code": 330300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 330400,
                "areaName": "嘉兴市",
                "parent_code": 330000,
                "level": 2,
                "children": [
                    {
                        "code": 330402,
                        "areaName": "南湖区",
                        "parent_code": 330400,
                        "level": 3
                    },
                    {
                        "code": 330411,
                        "areaName": "秀洲区",
                        "parent_code": 330400,
                        "level": 3
                    },
                    {
                        "code": 330421,
                        "areaName": "嘉善县",
                        "parent_code": 330400,
                        "level": 3
                    },
                    {
                        "code": 330424,
                        "areaName": "海盐县",
                        "parent_code": 330400,
                        "level": 3
                    },
                    {
                        "code": 330481,
                        "areaName": "海宁市",
                        "parent_code": 330400,
                        "level": 3
                    },
                    {
                        "code": 330482,
                        "areaName": "平湖市",
                        "parent_code": 330400,
                        "level": 3
                    },
                    {
                        "code": 330483,
                        "areaName": "桐乡市",
                        "parent_code": 330400,
                        "level": 3
                    },
                    {
                        "code": 330484,
                        "areaName": "嘉兴市",
                        "parent_code": 330400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 330500,
                "areaName": "湖州市",
                "parent_code": 330000,
                "level": 2,
                "children": [
                    {
                        "code": 330502,
                        "areaName": "吴兴区",
                        "parent_code": 330500,
                        "level": 3
                    },
                    {
                        "code": 330503,
                        "areaName": "南浔区",
                        "parent_code": 330500,
                        "level": 3
                    },
                    {
                        "code": 330521,
                        "areaName": "德清县",
                        "parent_code": 330500,
                        "level": 3
                    },
                    {
                        "code": 330522,
                        "areaName": "长兴县",
                        "parent_code": 330500,
                        "level": 3
                    },
                    {
                        "code": 330523,
                        "areaName": "安吉县",
                        "parent_code": 330500,
                        "level": 3
                    },
                    {
                        "code": 330524,
                        "areaName": "湖州市",
                        "parent_code": 330500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 330600,
                "areaName": "绍兴市",
                "parent_code": 330000,
                "level": 2,
                "children": [
                    {
                        "code": 330602,
                        "areaName": "越城区",
                        "parent_code": 330600,
                        "level": 3
                    },
                    {
                        "code": 330621,
                        "areaName": "柯桥区",
                        "parent_code": 330600,
                        "level": 3
                    },
                    {
                        "code": 330624,
                        "areaName": "新昌县",
                        "parent_code": 330600,
                        "level": 3
                    },
                    {
                        "code": 330681,
                        "areaName": "诸暨市",
                        "parent_code": 330600,
                        "level": 3
                    },
                    {
                        "code": 330682,
                        "areaName": "上虞区",
                        "parent_code": 330600,
                        "level": 3
                    },
                    {
                        "code": 330683,
                        "areaName": "嵊州市",
                        "parent_code": 330600,
                        "level": 3
                    },
                    {
                        "code": 330684,
                        "areaName": "绍兴市",
                        "parent_code": 330600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 330700,
                "areaName": "金华市",
                "parent_code": 330000,
                "level": 2,
                "children": [
                    {
                        "code": 330702,
                        "areaName": "婺城区",
                        "parent_code": 330700,
                        "level": 3
                    },
                    {
                        "code": 330703,
                        "areaName": "金东区",
                        "parent_code": 330700,
                        "level": 3
                    },
                    {
                        "code": 330723,
                        "areaName": "武义县",
                        "parent_code": 330700,
                        "level": 3
                    },
                    {
                        "code": 330726,
                        "areaName": "浦江县",
                        "parent_code": 330700,
                        "level": 3
                    },
                    {
                        "code": 330727,
                        "areaName": "磐安县",
                        "parent_code": 330700,
                        "level": 3
                    },
                    {
                        "code": 330781,
                        "areaName": "兰溪市",
                        "parent_code": 330700,
                        "level": 3
                    },
                    {
                        "code": 330782,
                        "areaName": "义乌市",
                        "parent_code": 330700,
                        "level": 3
                    },
                    {
                        "code": 330783,
                        "areaName": "东阳市",
                        "parent_code": 330700,
                        "level": 3
                    },
                    {
                        "code": 330784,
                        "areaName": "永康市",
                        "parent_code": 330700,
                        "level": 3
                    },
                    {
                        "code": 330785,
                        "areaName": "金华市",
                        "parent_code": 330700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 330800,
                "areaName": "衢州市",
                "parent_code": 330000,
                "level": 2,
                "children": [
                    {
                        "code": 330802,
                        "areaName": "柯城区",
                        "parent_code": 330800,
                        "level": 3
                    },
                    {
                        "code": 330803,
                        "areaName": "衢江区",
                        "parent_code": 330800,
                        "level": 3
                    },
                    {
                        "code": 330822,
                        "areaName": "常山县",
                        "parent_code": 330800,
                        "level": 3
                    },
                    {
                        "code": 330824,
                        "areaName": "开化县",
                        "parent_code": 330800,
                        "level": 3
                    },
                    {
                        "code": 330825,
                        "areaName": "龙游县",
                        "parent_code": 330800,
                        "level": 3
                    },
                    {
                        "code": 330881,
                        "areaName": "江山市",
                        "parent_code": 330800,
                        "level": 3
                    },
                    {
                        "code": 330882,
                        "areaName": "衢州市",
                        "parent_code": 330800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 330900,
                "areaName": "舟山市",
                "parent_code": 330000,
                "level": 2,
                "children": [
                    {
                        "code": 330902,
                        "areaName": "定海区",
                        "parent_code": 330900,
                        "level": 3
                    },
                    {
                        "code": 330903,
                        "areaName": "普陀区",
                        "parent_code": 330900,
                        "level": 3
                    },
                    {
                        "code": 330921,
                        "areaName": "岱山县",
                        "parent_code": 330900,
                        "level": 3
                    },
                    {
                        "code": 330922,
                        "areaName": "嵊泗县",
                        "parent_code": 330900,
                        "level": 3
                    },
                    {
                        "code": 330923,
                        "areaName": "舟山市",
                        "parent_code": 330900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 331000,
                "areaName": "台州市",
                "parent_code": 330000,
                "level": 2,
                "children": [
                    {
                        "code": 331002,
                        "areaName": "椒江区",
                        "parent_code": 331000,
                        "level": 3
                    },
                    {
                        "code": 331003,
                        "areaName": "黄岩区",
                        "parent_code": 331000,
                        "level": 3
                    },
                    {
                        "code": 331004,
                        "areaName": "路桥区",
                        "parent_code": 331000,
                        "level": 3
                    },
                    {
                        "code": 331021,
                        "areaName": "玉环县",
                        "parent_code": 331000,
                        "level": 3
                    },
                    {
                        "code": 331022,
                        "areaName": "三门县",
                        "parent_code": 331000,
                        "level": 3
                    },
                    {
                        "code": 331023,
                        "areaName": "天台县",
                        "parent_code": 331000,
                        "level": 3
                    },
                    {
                        "code": 331024,
                        "areaName": "仙居县",
                        "parent_code": 331000,
                        "level": 3
                    },
                    {
                        "code": 331081,
                        "areaName": "温岭市",
                        "parent_code": 331000,
                        "level": 3
                    },
                    {
                        "code": 331082,
                        "areaName": "临海市",
                        "parent_code": 331000,
                        "level": 3
                    },
                    {
                        "code": 331083,
                        "areaName": "台州市",
                        "parent_code": 331000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 331100,
                "areaName": "丽水市",
                "parent_code": 330000,
                "level": 2,
                "children": [
                    {
                        "code": 331102,
                        "areaName": "莲都区",
                        "parent_code": 331100,
                        "level": 3
                    },
                    {
                        "code": 331121,
                        "areaName": "青田县",
                        "parent_code": 331100,
                        "level": 3
                    },
                    {
                        "code": 331122,
                        "areaName": "缙云县",
                        "parent_code": 331100,
                        "level": 3
                    },
                    {
                        "code": 331123,
                        "areaName": "遂昌县",
                        "parent_code": 331100,
                        "level": 3
                    },
                    {
                        "code": 331124,
                        "areaName": "松阳县",
                        "parent_code": 331100,
                        "level": 3
                    },
                    {
                        "code": 331125,
                        "areaName": "云和县",
                        "parent_code": 331100,
                        "level": 3
                    },
                    {
                        "code": 331126,
                        "areaName": "庆元县",
                        "parent_code": 331100,
                        "level": 3
                    },
                    {
                        "code": 331127,
                        "areaName": "景宁畲族自治县",
                        "parent_code": 331100,
                        "level": 3
                    },
                    {
                        "code": 331181,
                        "areaName": "龙泉市",
                        "parent_code": 331100,
                        "level": 3
                    },
                    {
                        "code": 331182,
                        "areaName": "丽水市",
                        "parent_code": 331100,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 340000,
        "areaName": "安徽省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 340100,
                "areaName": "合肥市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 340102,
                        "areaName": "瑶海区",
                        "parent_code": 340100,
                        "level": 3
                    },
                    {
                        "code": 340103,
                        "areaName": "庐阳区",
                        "parent_code": 340100,
                        "level": 3
                    },
                    {
                        "code": 340104,
                        "areaName": "蜀山区",
                        "parent_code": 340100,
                        "level": 3
                    },
                    {
                        "code": 340111,
                        "areaName": "包河区",
                        "parent_code": 340100,
                        "level": 3
                    },
                    {
                        "code": 340121,
                        "areaName": "长丰县",
                        "parent_code": 340100,
                        "level": 3
                    },
                    {
                        "code": 340122,
                        "areaName": "肥东县",
                        "parent_code": 340100,
                        "level": 3
                    },
                    {
                        "code": 340123,
                        "areaName": "肥西县",
                        "parent_code": 340100,
                        "level": 3
                    },
                    {
                        "code": 340124,
                        "areaName": "庐江县",
                        "parent_code": 340100,
                        "level": 3
                    },
                    {
                        "code": 340181,
                        "areaName": "巢湖市",
                        "parent_code": 340100,
                        "level": 3
                    },
                    {
                        "code": 340182,
                        "areaName": "合肥市",
                        "parent_code": 340100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 340200,
                "areaName": "芜湖市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 340202,
                        "areaName": "镜湖区",
                        "parent_code": 340200,
                        "level": 3
                    },
                    {
                        "code": 340203,
                        "areaName": "弋江区",
                        "parent_code": 340200,
                        "level": 3
                    },
                    {
                        "code": 340207,
                        "areaName": "鸠江区",
                        "parent_code": 340200,
                        "level": 3
                    },
                    {
                        "code": 340208,
                        "areaName": "三山区",
                        "parent_code": 340200,
                        "level": 3
                    },
                    {
                        "code": 340221,
                        "areaName": "芜湖县",
                        "parent_code": 340200,
                        "level": 3
                    },
                    {
                        "code": 340222,
                        "areaName": "繁昌县",
                        "parent_code": 340200,
                        "level": 3
                    },
                    {
                        "code": 340223,
                        "areaName": "南陵县",
                        "parent_code": 340200,
                        "level": 3
                    },
                    {
                        "code": 340225,
                        "areaName": "无为县",
                        "parent_code": 340200,
                        "level": 3
                    },
                    {
                        "code": 340226,
                        "areaName": "芜湖市",
                        "parent_code": 340200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 340300,
                "areaName": "蚌埠市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 340302,
                        "areaName": "龙子湖区",
                        "parent_code": 340300,
                        "level": 3
                    },
                    {
                        "code": 340303,
                        "areaName": "蚌山区",
                        "parent_code": 340300,
                        "level": 3
                    },
                    {
                        "code": 340304,
                        "areaName": "禹会区",
                        "parent_code": 340300,
                        "level": 3
                    },
                    {
                        "code": 340311,
                        "areaName": "淮上区",
                        "parent_code": 340300,
                        "level": 3
                    },
                    {
                        "code": 340321,
                        "areaName": "怀远县",
                        "parent_code": 340300,
                        "level": 3
                    },
                    {
                        "code": 340322,
                        "areaName": "五河县",
                        "parent_code": 340300,
                        "level": 3
                    },
                    {
                        "code": 340323,
                        "areaName": "固镇县",
                        "parent_code": 340300,
                        "level": 3
                    },
                    {
                        "code": 340324,
                        "areaName": "蚌埠市",
                        "parent_code": 340300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 340400,
                "areaName": "淮南市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 340402,
                        "areaName": "大通区",
                        "parent_code": 340400,
                        "level": 3
                    },
                    {
                        "code": 340403,
                        "areaName": "田家庵区",
                        "parent_code": 340400,
                        "level": 3
                    },
                    {
                        "code": 340404,
                        "areaName": "谢家集区",
                        "parent_code": 340400,
                        "level": 3
                    },
                    {
                        "code": 340405,
                        "areaName": "八公山区",
                        "parent_code": 340400,
                        "level": 3
                    },
                    {
                        "code": 340406,
                        "areaName": "潘集区",
                        "parent_code": 340400,
                        "level": 3
                    },
                    {
                        "code": 340421,
                        "areaName": "凤台县",
                        "parent_code": 340400,
                        "level": 3
                    },
                    {
                        "code": 340422,
                        "areaName": "淮南市",
                        "parent_code": 340400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 340500,
                "areaName": "马鞍山市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 340503,
                        "areaName": "花山区",
                        "parent_code": 340500,
                        "level": 3
                    },
                    {
                        "code": 340504,
                        "areaName": "雨山区",
                        "parent_code": 340500,
                        "level": 3
                    },
                    {
                        "code": 340506,
                        "areaName": "博望区",
                        "parent_code": 340500,
                        "level": 3
                    },
                    {
                        "code": 340521,
                        "areaName": "当涂县",
                        "parent_code": 340500,
                        "level": 3
                    },
                    {
                        "code": 340522,
                        "areaName": "含山县",
                        "parent_code": 340500,
                        "level": 3
                    },
                    {
                        "code": 340523,
                        "areaName": "和县",
                        "parent_code": 340500,
                        "level": 3
                    },
                    {
                        "code": 340524,
                        "areaName": "马鞍山市",
                        "parent_code": 340500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 340600,
                "areaName": "淮北市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 340602,
                        "areaName": "杜集区",
                        "parent_code": 340600,
                        "level": 3
                    },
                    {
                        "code": 340603,
                        "areaName": "相山区",
                        "parent_code": 340600,
                        "level": 3
                    },
                    {
                        "code": 340604,
                        "areaName": "烈山区",
                        "parent_code": 340600,
                        "level": 3
                    },
                    {
                        "code": 340621,
                        "areaName": "濉溪县",
                        "parent_code": 340600,
                        "level": 3
                    },
                    {
                        "code": 340622,
                        "areaName": "淮北市",
                        "parent_code": 340600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 340700,
                "areaName": "铜陵市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 340702,
                        "areaName": "铜官山区",
                        "parent_code": 340700,
                        "level": 3
                    },
                    {
                        "code": 340703,
                        "areaName": "狮子山区",
                        "parent_code": 340700,
                        "level": 3
                    },
                    {
                        "code": 340711,
                        "areaName": "郊区",
                        "parent_code": 340700,
                        "level": 3
                    },
                    {
                        "code": 340721,
                        "areaName": "铜陵县",
                        "parent_code": 340700,
                        "level": 3
                    },
                    {
                        "code": 340722,
                        "areaName": "铜陵市",
                        "parent_code": 340700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 340800,
                "areaName": "安庆市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 340802,
                        "areaName": "迎江区",
                        "parent_code": 340800,
                        "level": 3
                    },
                    {
                        "code": 340803,
                        "areaName": "大观区",
                        "parent_code": 340800,
                        "level": 3
                    },
                    {
                        "code": 340811,
                        "areaName": "宜秀区",
                        "parent_code": 340800,
                        "level": 3
                    },
                    {
                        "code": 340822,
                        "areaName": "怀宁县",
                        "parent_code": 340800,
                        "level": 3
                    },
                    {
                        "code": 340823,
                        "areaName": "枞阳县",
                        "parent_code": 340800,
                        "level": 3
                    },
                    {
                        "code": 340824,
                        "areaName": "潜山县",
                        "parent_code": 340800,
                        "level": 3
                    },
                    {
                        "code": 340825,
                        "areaName": "太湖县",
                        "parent_code": 340800,
                        "level": 3
                    },
                    {
                        "code": 340826,
                        "areaName": "宿松县",
                        "parent_code": 340800,
                        "level": 3
                    },
                    {
                        "code": 340827,
                        "areaName": "望江县",
                        "parent_code": 340800,
                        "level": 3
                    },
                    {
                        "code": 340828,
                        "areaName": "岳西县",
                        "parent_code": 340800,
                        "level": 3
                    },
                    {
                        "code": 340881,
                        "areaName": "桐城市",
                        "parent_code": 340800,
                        "level": 3
                    },
                    {
                        "code": 340882,
                        "areaName": "安庆市",
                        "parent_code": 340800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 341000,
                "areaName": "黄山市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 341002,
                        "areaName": "屯溪区",
                        "parent_code": 341000,
                        "level": 3
                    },
                    {
                        "code": 341003,
                        "areaName": "黄山区",
                        "parent_code": 341000,
                        "level": 3
                    },
                    {
                        "code": 341004,
                        "areaName": "徽州区",
                        "parent_code": 341000,
                        "level": 3
                    },
                    {
                        "code": 341021,
                        "areaName": "歙县",
                        "parent_code": 341000,
                        "level": 3
                    },
                    {
                        "code": 341022,
                        "areaName": "休宁县",
                        "parent_code": 341000,
                        "level": 3
                    },
                    {
                        "code": 341023,
                        "areaName": "黟县",
                        "parent_code": 341000,
                        "level": 3
                    },
                    {
                        "code": 341024,
                        "areaName": "祁门县",
                        "parent_code": 341000,
                        "level": 3
                    },
                    {
                        "code": 341025,
                        "areaName": "黄山市",
                        "parent_code": 341000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 341100,
                "areaName": "滁州市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 341102,
                        "areaName": "琅琊区",
                        "parent_code": 341100,
                        "level": 3
                    },
                    {
                        "code": 341103,
                        "areaName": "南谯区",
                        "parent_code": 341100,
                        "level": 3
                    },
                    {
                        "code": 341122,
                        "areaName": "来安县",
                        "parent_code": 341100,
                        "level": 3
                    },
                    {
                        "code": 341124,
                        "areaName": "全椒县",
                        "parent_code": 341100,
                        "level": 3
                    },
                    {
                        "code": 341125,
                        "areaName": "定远县",
                        "parent_code": 341100,
                        "level": 3
                    },
                    {
                        "code": 341126,
                        "areaName": "凤阳县",
                        "parent_code": 341100,
                        "level": 3
                    },
                    {
                        "code": 341181,
                        "areaName": "天长市",
                        "parent_code": 341100,
                        "level": 3
                    },
                    {
                        "code": 341182,
                        "areaName": "明光市",
                        "parent_code": 341100,
                        "level": 3
                    },
                    {
                        "code": 341183,
                        "areaName": "滁州市",
                        "parent_code": 341100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 341200,
                "areaName": "阜阳市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 341202,
                        "areaName": "颍州区",
                        "parent_code": 341200,
                        "level": 3
                    },
                    {
                        "code": 341203,
                        "areaName": "颍东区",
                        "parent_code": 341200,
                        "level": 3
                    },
                    {
                        "code": 341204,
                        "areaName": "颍泉区",
                        "parent_code": 341200,
                        "level": 3
                    },
                    {
                        "code": 341221,
                        "areaName": "临泉县",
                        "parent_code": 341200,
                        "level": 3
                    },
                    {
                        "code": 341222,
                        "areaName": "太和县",
                        "parent_code": 341200,
                        "level": 3
                    },
                    {
                        "code": 341225,
                        "areaName": "阜南县",
                        "parent_code": 341200,
                        "level": 3
                    },
                    {
                        "code": 341226,
                        "areaName": "颍上县",
                        "parent_code": 341200,
                        "level": 3
                    },
                    {
                        "code": 341282,
                        "areaName": "界首市",
                        "parent_code": 341200,
                        "level": 3
                    },
                    {
                        "code": 341283,
                        "areaName": "阜阳市",
                        "parent_code": 341200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 341300,
                "areaName": "宿州市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 341302,
                        "areaName": "埇桥区",
                        "parent_code": 341300,
                        "level": 3
                    },
                    {
                        "code": 341321,
                        "areaName": "砀山县",
                        "parent_code": 341300,
                        "level": 3
                    },
                    {
                        "code": 341322,
                        "areaName": "萧县",
                        "parent_code": 341300,
                        "level": 3
                    },
                    {
                        "code": 341323,
                        "areaName": "灵璧县",
                        "parent_code": 341300,
                        "level": 3
                    },
                    {
                        "code": 341324,
                        "areaName": "泗县",
                        "parent_code": 341300,
                        "level": 3
                    },
                    {
                        "code": 341325,
                        "areaName": "宿州市",
                        "parent_code": 341300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 341500,
                "areaName": "六安市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 341502,
                        "areaName": "金安区",
                        "parent_code": 341500,
                        "level": 3
                    },
                    {
                        "code": 341503,
                        "areaName": "裕安区",
                        "parent_code": 341500,
                        "level": 3
                    },
                    {
                        "code": 341521,
                        "areaName": "寿县",
                        "parent_code": 341500,
                        "level": 3
                    },
                    {
                        "code": 341522,
                        "areaName": "霍邱县",
                        "parent_code": 341500,
                        "level": 3
                    },
                    {
                        "code": 341523,
                        "areaName": "舒城县",
                        "parent_code": 341500,
                        "level": 3
                    },
                    {
                        "code": 341524,
                        "areaName": "金寨县",
                        "parent_code": 341500,
                        "level": 3
                    },
                    {
                        "code": 341525,
                        "areaName": "霍山县",
                        "parent_code": 341500,
                        "level": 3
                    },
                    {
                        "code": 341526,
                        "areaName": "六安市",
                        "parent_code": 341500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 341600,
                "areaName": "亳州市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 341602,
                        "areaName": "谯城区",
                        "parent_code": 341600,
                        "level": 3
                    },
                    {
                        "code": 341621,
                        "areaName": "涡阳县",
                        "parent_code": 341600,
                        "level": 3
                    },
                    {
                        "code": 341622,
                        "areaName": "蒙城县",
                        "parent_code": 341600,
                        "level": 3
                    },
                    {
                        "code": 341623,
                        "areaName": "利辛县",
                        "parent_code": 341600,
                        "level": 3
                    },
                    {
                        "code": 341624,
                        "areaName": "亳州市",
                        "parent_code": 341600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 341700,
                "areaName": "池州市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 341702,
                        "areaName": "贵池区",
                        "parent_code": 341700,
                        "level": 3
                    },
                    {
                        "code": 341721,
                        "areaName": "东至县",
                        "parent_code": 341700,
                        "level": 3
                    },
                    {
                        "code": 341722,
                        "areaName": "石台县",
                        "parent_code": 341700,
                        "level": 3
                    },
                    {
                        "code": 341723,
                        "areaName": "青阳县",
                        "parent_code": 341700,
                        "level": 3
                    },
                    {
                        "code": 341724,
                        "areaName": "池州市",
                        "parent_code": 341700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 341800,
                "areaName": "宣城市",
                "parent_code": 340000,
                "level": 2,
                "children": [
                    {
                        "code": 341802,
                        "areaName": "宣州区",
                        "parent_code": 341800,
                        "level": 3
                    },
                    {
                        "code": 341821,
                        "areaName": "郎溪县",
                        "parent_code": 341800,
                        "level": 3
                    },
                    {
                        "code": 341822,
                        "areaName": "广德县",
                        "parent_code": 341800,
                        "level": 3
                    },
                    {
                        "code": 341823,
                        "areaName": "泾县",
                        "parent_code": 341800,
                        "level": 3
                    },
                    {
                        "code": 341824,
                        "areaName": "绩溪县",
                        "parent_code": 341800,
                        "level": 3
                    },
                    {
                        "code": 341825,
                        "areaName": "旌德县",
                        "parent_code": 341800,
                        "level": 3
                    },
                    {
                        "code": 341881,
                        "areaName": "宁国市",
                        "parent_code": 341800,
                        "level": 3
                    },
                    {
                        "code": 341882,
                        "areaName": "宣城市",
                        "parent_code": 341800,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 350000,
        "areaName": "福建省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 350100,
                "areaName": "福州市",
                "parent_code": 350000,
                "level": 2,
                "children": [
                    {
                        "code": 350102,
                        "areaName": "鼓楼区",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350103,
                        "areaName": "台江区",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350104,
                        "areaName": "仓山区",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350105,
                        "areaName": "马尾区",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350111,
                        "areaName": "晋安区",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350121,
                        "areaName": "闽侯县",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350122,
                        "areaName": "连江县",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350123,
                        "areaName": "罗源县",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350124,
                        "areaName": "闽清县",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350125,
                        "areaName": "永泰县",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350128,
                        "areaName": "平潭县",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350181,
                        "areaName": "福清市",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350182,
                        "areaName": "长乐市",
                        "parent_code": 350100,
                        "level": 3
                    },
                    {
                        "code": 350183,
                        "areaName": "福州市",
                        "parent_code": 350100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 350200,
                "areaName": "厦门市",
                "parent_code": 350000,
                "level": 2,
                "children": [
                    {
                        "code": 350203,
                        "areaName": "思明区",
                        "parent_code": 350200,
                        "level": 3
                    },
                    {
                        "code": 350205,
                        "areaName": "海沧区",
                        "parent_code": 350200,
                        "level": 3
                    },
                    {
                        "code": 350206,
                        "areaName": "湖里区",
                        "parent_code": 350200,
                        "level": 3
                    },
                    {
                        "code": 350211,
                        "areaName": "集美区",
                        "parent_code": 350200,
                        "level": 3
                    },
                    {
                        "code": 350212,
                        "areaName": "同安区",
                        "parent_code": 350200,
                        "level": 3
                    },
                    {
                        "code": 350213,
                        "areaName": "翔安区",
                        "parent_code": 350200,
                        "level": 3
                    },
                    {
                        "code": 350214,
                        "areaName": "厦门市",
                        "parent_code": 350200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 350300,
                "areaName": "莆田市",
                "parent_code": 350000,
                "level": 2,
                "children": [
                    {
                        "code": 350302,
                        "areaName": "城厢区",
                        "parent_code": 350300,
                        "level": 3
                    },
                    {
                        "code": 350303,
                        "areaName": "涵江区",
                        "parent_code": 350300,
                        "level": 3
                    },
                    {
                        "code": 350304,
                        "areaName": "荔城区",
                        "parent_code": 350300,
                        "level": 3
                    },
                    {
                        "code": 350305,
                        "areaName": "秀屿区",
                        "parent_code": 350300,
                        "level": 3
                    },
                    {
                        "code": 350322,
                        "areaName": "仙游县",
                        "parent_code": 350300,
                        "level": 3
                    },
                    {
                        "code": 350323,
                        "areaName": "莆田市",
                        "parent_code": 350300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 350400,
                "areaName": "三明市",
                "parent_code": 350000,
                "level": 2,
                "children": [
                    {
                        "code": 350402,
                        "areaName": "梅列区",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350403,
                        "areaName": "三元区",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350421,
                        "areaName": "明溪县",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350423,
                        "areaName": "清流县",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350424,
                        "areaName": "宁化县",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350425,
                        "areaName": "大田县",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350426,
                        "areaName": "尤溪县",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350427,
                        "areaName": "沙县",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350428,
                        "areaName": "将乐县",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350429,
                        "areaName": "泰宁县",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350430,
                        "areaName": "建宁县",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350481,
                        "areaName": "永安市",
                        "parent_code": 350400,
                        "level": 3
                    },
                    {
                        "code": 350482,
                        "areaName": "三明市",
                        "parent_code": 350400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 350500,
                "areaName": "泉州市",
                "parent_code": 350000,
                "level": 2,
                "children": [
                    {
                        "code": 350502,
                        "areaName": "鲤城区",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350503,
                        "areaName": "丰泽区",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350504,
                        "areaName": "洛江区",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350505,
                        "areaName": "泉港区",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350521,
                        "areaName": "惠安县",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350524,
                        "areaName": "安溪县",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350525,
                        "areaName": "永春县",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350526,
                        "areaName": "德化县",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350527,
                        "areaName": "金门县",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350581,
                        "areaName": "石狮市",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350582,
                        "areaName": "晋江市",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350583,
                        "areaName": "南安市",
                        "parent_code": 350500,
                        "level": 3
                    },
                    {
                        "code": 350584,
                        "areaName": "泉州市",
                        "parent_code": 350500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 350600,
                "areaName": "漳州市",
                "parent_code": 350000,
                "level": 2,
                "children": [
                    {
                        "code": 350602,
                        "areaName": "芗城区",
                        "parent_code": 350600,
                        "level": 3
                    },
                    {
                        "code": 350603,
                        "areaName": "龙文区",
                        "parent_code": 350600,
                        "level": 3
                    },
                    {
                        "code": 350622,
                        "areaName": "云霄县",
                        "parent_code": 350600,
                        "level": 3
                    },
                    {
                        "code": 350623,
                        "areaName": "漳浦县",
                        "parent_code": 350600,
                        "level": 3
                    },
                    {
                        "code": 350624,
                        "areaName": "诏安县",
                        "parent_code": 350600,
                        "level": 3
                    },
                    {
                        "code": 350625,
                        "areaName": "长泰县",
                        "parent_code": 350600,
                        "level": 3
                    },
                    {
                        "code": 350626,
                        "areaName": "东山县",
                        "parent_code": 350600,
                        "level": 3
                    },
                    {
                        "code": 350627,
                        "areaName": "南靖县",
                        "parent_code": 350600,
                        "level": 3
                    },
                    {
                        "code": 350628,
                        "areaName": "平和县",
                        "parent_code": 350600,
                        "level": 3
                    },
                    {
                        "code": 350629,
                        "areaName": "华安县",
                        "parent_code": 350600,
                        "level": 3
                    },
                    {
                        "code": 350681,
                        "areaName": "龙海市",
                        "parent_code": 350600,
                        "level": 3
                    },
                    {
                        "code": 350682,
                        "areaName": "漳州市",
                        "parent_code": 350600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 350700,
                "areaName": "南平市",
                "parent_code": 350000,
                "level": 2,
                "children": [
                    {
                        "code": 350702,
                        "areaName": "延平区",
                        "parent_code": 350700,
                        "level": 3
                    },
                    {
                        "code": 350721,
                        "areaName": "顺昌县",
                        "parent_code": 350700,
                        "level": 3
                    },
                    {
                        "code": 350722,
                        "areaName": "浦城县",
                        "parent_code": 350700,
                        "level": 3
                    },
                    {
                        "code": 350723,
                        "areaName": "光泽县",
                        "parent_code": 350700,
                        "level": 3
                    },
                    {
                        "code": 350724,
                        "areaName": "松溪县",
                        "parent_code": 350700,
                        "level": 3
                    },
                    {
                        "code": 350725,
                        "areaName": "政和县",
                        "parent_code": 350700,
                        "level": 3
                    },
                    {
                        "code": 350781,
                        "areaName": "邵武市",
                        "parent_code": 350700,
                        "level": 3
                    },
                    {
                        "code": 350782,
                        "areaName": "武夷山市",
                        "parent_code": 350700,
                        "level": 3
                    },
                    {
                        "code": 350783,
                        "areaName": "建瓯市",
                        "parent_code": 350700,
                        "level": 3
                    },
                    {
                        "code": 350784,
                        "areaName": "建阳区",
                        "parent_code": 350700,
                        "level": 3
                    },
                    {
                        "code": 350785,
                        "areaName": "南平市",
                        "parent_code": 350700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 350800,
                "areaName": "龙岩市",
                "parent_code": 350000,
                "level": 2,
                "children": [
                    {
                        "code": 350802,
                        "areaName": "新罗区",
                        "parent_code": 350800,
                        "level": 3
                    },
                    {
                        "code": 350821,
                        "areaName": "长汀县",
                        "parent_code": 350800,
                        "level": 3
                    },
                    {
                        "code": 350822,
                        "areaName": "永定区",
                        "parent_code": 350800,
                        "level": 3
                    },
                    {
                        "code": 350823,
                        "areaName": "上杭县",
                        "parent_code": 350800,
                        "level": 3
                    },
                    {
                        "code": 350824,
                        "areaName": "武平县",
                        "parent_code": 350800,
                        "level": 3
                    },
                    {
                        "code": 350825,
                        "areaName": "连城县",
                        "parent_code": 350800,
                        "level": 3
                    },
                    {
                        "code": 350881,
                        "areaName": "漳平市",
                        "parent_code": 350800,
                        "level": 3
                    },
                    {
                        "code": 350882,
                        "areaName": "龙岩市",
                        "parent_code": 350800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 350900,
                "areaName": "宁德市",
                "parent_code": 350000,
                "level": 2,
                "children": [
                    {
                        "code": 350902,
                        "areaName": "蕉城区",
                        "parent_code": 350900,
                        "level": 3
                    },
                    {
                        "code": 350921,
                        "areaName": "霞浦县",
                        "parent_code": 350900,
                        "level": 3
                    },
                    {
                        "code": 350922,
                        "areaName": "古田县",
                        "parent_code": 350900,
                        "level": 3
                    },
                    {
                        "code": 350923,
                        "areaName": "屏南县",
                        "parent_code": 350900,
                        "level": 3
                    },
                    {
                        "code": 350924,
                        "areaName": "寿宁县",
                        "parent_code": 350900,
                        "level": 3
                    },
                    {
                        "code": 350925,
                        "areaName": "周宁县",
                        "parent_code": 350900,
                        "level": 3
                    },
                    {
                        "code": 350926,
                        "areaName": "柘荣县",
                        "parent_code": 350900,
                        "level": 3
                    },
                    {
                        "code": 350981,
                        "areaName": "福安市",
                        "parent_code": 350900,
                        "level": 3
                    },
                    {
                        "code": 350982,
                        "areaName": "福鼎市",
                        "parent_code": 350900,
                        "level": 3
                    },
                    {
                        "code": 350983,
                        "areaName": "宁德市",
                        "parent_code": 350900,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 360000,
        "areaName": "江西省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 360100,
                "areaName": "南昌市",
                "parent_code": 360000,
                "level": 2,
                "children": [
                    {
                        "code": 360102,
                        "areaName": "东湖区",
                        "parent_code": 360100,
                        "level": 3
                    },
                    {
                        "code": 360103,
                        "areaName": "西湖区",
                        "parent_code": 360100,
                        "level": 3
                    },
                    {
                        "code": 360104,
                        "areaName": "青云谱区",
                        "parent_code": 360100,
                        "level": 3
                    },
                    {
                        "code": 360105,
                        "areaName": "湾里区",
                        "parent_code": 360100,
                        "level": 3
                    },
                    {
                        "code": 360111,
                        "areaName": "青山湖区",
                        "parent_code": 360100,
                        "level": 3
                    },
                    {
                        "code": 360121,
                        "areaName": "南昌县",
                        "parent_code": 360100,
                        "level": 3
                    },
                    {
                        "code": 360122,
                        "areaName": "新建县",
                        "parent_code": 360100,
                        "level": 3
                    },
                    {
                        "code": 360123,
                        "areaName": "安义县",
                        "parent_code": 360100,
                        "level": 3
                    },
                    {
                        "code": 360124,
                        "areaName": "进贤县",
                        "parent_code": 360100,
                        "level": 3
                    },
                    {
                        "code": 360125,
                        "areaName": "南昌市",
                        "parent_code": 360100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 360200,
                "areaName": "景德镇市",
                "parent_code": 360000,
                "level": 2,
                "children": [
                    {
                        "code": 360202,
                        "areaName": "昌江区",
                        "parent_code": 360200,
                        "level": 3
                    },
                    {
                        "code": 360203,
                        "areaName": "珠山区",
                        "parent_code": 360200,
                        "level": 3
                    },
                    {
                        "code": 360222,
                        "areaName": "浮梁县",
                        "parent_code": 360200,
                        "level": 3
                    },
                    {
                        "code": 360281,
                        "areaName": "乐平市",
                        "parent_code": 360200,
                        "level": 3
                    },
                    {
                        "code": 360282,
                        "areaName": "景德镇市",
                        "parent_code": 360200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 360300,
                "areaName": "萍乡市",
                "parent_code": 360000,
                "level": 2,
                "children": [
                    {
                        "code": 360302,
                        "areaName": "安源区",
                        "parent_code": 360300,
                        "level": 3
                    },
                    {
                        "code": 360313,
                        "areaName": "湘东区",
                        "parent_code": 360300,
                        "level": 3
                    },
                    {
                        "code": 360321,
                        "areaName": "莲花县",
                        "parent_code": 360300,
                        "level": 3
                    },
                    {
                        "code": 360322,
                        "areaName": "上栗县",
                        "parent_code": 360300,
                        "level": 3
                    },
                    {
                        "code": 360323,
                        "areaName": "芦溪县",
                        "parent_code": 360300,
                        "level": 3
                    },
                    {
                        "code": 360324,
                        "areaName": "萍乡市",
                        "parent_code": 360300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 360400,
                "areaName": "九江市",
                "parent_code": 360000,
                "level": 2,
                "children": [
                    {
                        "code": 360402,
                        "areaName": "庐山区",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360403,
                        "areaName": "浔阳区",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360421,
                        "areaName": "九江县",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360423,
                        "areaName": "武宁县",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360424,
                        "areaName": "修水县",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360425,
                        "areaName": "永修县",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360426,
                        "areaName": "德安县",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360427,
                        "areaName": "星子县",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360428,
                        "areaName": "都昌县",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360429,
                        "areaName": "湖口县",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360430,
                        "areaName": "彭泽县",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360481,
                        "areaName": "瑞昌市",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360482,
                        "areaName": "共青城市",
                        "parent_code": 360400,
                        "level": 3
                    },
                    {
                        "code": 360483,
                        "areaName": "九江市",
                        "parent_code": 360400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 360500,
                "areaName": "新余市",
                "parent_code": 360000,
                "level": 2,
                "children": [
                    {
                        "code": 360502,
                        "areaName": "渝水区",
                        "parent_code": 360500,
                        "level": 3
                    },
                    {
                        "code": 360521,
                        "areaName": "分宜县",
                        "parent_code": 360500,
                        "level": 3
                    },
                    {
                        "code": 360522,
                        "areaName": "新余市",
                        "parent_code": 360500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 360600,
                "areaName": "鹰潭市",
                "parent_code": 360000,
                "level": 2,
                "children": [
                    {
                        "code": 360602,
                        "areaName": "月湖区",
                        "parent_code": 360600,
                        "level": 3
                    },
                    {
                        "code": 360622,
                        "areaName": "余江县",
                        "parent_code": 360600,
                        "level": 3
                    },
                    {
                        "code": 360681,
                        "areaName": "贵溪市",
                        "parent_code": 360600,
                        "level": 3
                    },
                    {
                        "code": 360682,
                        "areaName": "鹰潭市",
                        "parent_code": 360600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 360700,
                "areaName": "赣州市",
                "parent_code": 360000,
                "level": 2,
                "children": [
                    {
                        "code": 360702,
                        "areaName": "章贡区",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360721,
                        "areaName": "赣县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360722,
                        "areaName": "信丰县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360723,
                        "areaName": "大余县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360724,
                        "areaName": "上犹县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360725,
                        "areaName": "崇义县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360726,
                        "areaName": "安远县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360727,
                        "areaName": "龙南县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360728,
                        "areaName": "定南县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360729,
                        "areaName": "全南县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360730,
                        "areaName": "宁都县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360731,
                        "areaName": "于都县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360732,
                        "areaName": "兴国县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360733,
                        "areaName": "会昌县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360734,
                        "areaName": "寻乌县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360735,
                        "areaName": "石城县",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360781,
                        "areaName": "瑞金市",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360782,
                        "areaName": "南康区",
                        "parent_code": 360700,
                        "level": 3
                    },
                    {
                        "code": 360783,
                        "areaName": "赣州市",
                        "parent_code": 360700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 360800,
                "areaName": "吉安市",
                "parent_code": 360000,
                "level": 2,
                "children": [
                    {
                        "code": 360802,
                        "areaName": "吉州区",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360803,
                        "areaName": "青原区",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360821,
                        "areaName": "吉安县",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360822,
                        "areaName": "吉水县",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360823,
                        "areaName": "峡江县",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360824,
                        "areaName": "新干县",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360825,
                        "areaName": "永丰县",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360826,
                        "areaName": "泰和县",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360827,
                        "areaName": "遂川县",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360828,
                        "areaName": "万安县",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360829,
                        "areaName": "安福县",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360830,
                        "areaName": "永新县",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360881,
                        "areaName": "井冈山市",
                        "parent_code": 360800,
                        "level": 3
                    },
                    {
                        "code": 360882,
                        "areaName": "吉安市",
                        "parent_code": 360800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 360900,
                "areaName": "宜春市",
                "parent_code": 360000,
                "level": 2,
                "children": [
                    {
                        "code": 360902,
                        "areaName": "袁州区",
                        "parent_code": 360900,
                        "level": 3
                    },
                    {
                        "code": 360921,
                        "areaName": "奉新县",
                        "parent_code": 360900,
                        "level": 3
                    },
                    {
                        "code": 360922,
                        "areaName": "万载县",
                        "parent_code": 360900,
                        "level": 3
                    },
                    {
                        "code": 360923,
                        "areaName": "上高县",
                        "parent_code": 360900,
                        "level": 3
                    },
                    {
                        "code": 360924,
                        "areaName": "宜丰县",
                        "parent_code": 360900,
                        "level": 3
                    },
                    {
                        "code": 360925,
                        "areaName": "靖安县",
                        "parent_code": 360900,
                        "level": 3
                    },
                    {
                        "code": 360926,
                        "areaName": "铜鼓县",
                        "parent_code": 360900,
                        "level": 3
                    },
                    {
                        "code": 360981,
                        "areaName": "丰城市",
                        "parent_code": 360900,
                        "level": 3
                    },
                    {
                        "code": 360982,
                        "areaName": "樟树市",
                        "parent_code": 360900,
                        "level": 3
                    },
                    {
                        "code": 360983,
                        "areaName": "高安市",
                        "parent_code": 360900,
                        "level": 3
                    },
                    {
                        "code": 360984,
                        "areaName": "宜春市",
                        "parent_code": 360900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 361000,
                "areaName": "抚州市",
                "parent_code": 360000,
                "level": 2,
                "children": [
                    {
                        "code": 361002,
                        "areaName": "临川区",
                        "parent_code": 361000,
                        "level": 3
                    },
                    {
                        "code": 361021,
                        "areaName": "南城县",
                        "parent_code": 361000,
                        "level": 3
                    },
                    {
                        "code": 361022,
                        "areaName": "黎川县",
                        "parent_code": 361000,
                        "level": 3
                    },
                    {
                        "code": 361023,
                        "areaName": "南丰县",
                        "parent_code": 361000,
                        "level": 3
                    },
                    {
                        "code": 361024,
                        "areaName": "崇仁县",
                        "parent_code": 361000,
                        "level": 3
                    },
                    {
                        "code": 361025,
                        "areaName": "乐安县",
                        "parent_code": 361000,
                        "level": 3
                    },
                    {
                        "code": 361026,
                        "areaName": "宜黄县",
                        "parent_code": 361000,
                        "level": 3
                    },
                    {
                        "code": 361027,
                        "areaName": "金溪县",
                        "parent_code": 361000,
                        "level": 3
                    },
                    {
                        "code": 361028,
                        "areaName": "资溪县",
                        "parent_code": 361000,
                        "level": 3
                    },
                    {
                        "code": 361029,
                        "areaName": "东乡县",
                        "parent_code": 361000,
                        "level": 3
                    },
                    {
                        "code": 361030,
                        "areaName": "广昌县",
                        "parent_code": 361000,
                        "level": 3
                    },
                    {
                        "code": 361031,
                        "areaName": "抚州市",
                        "parent_code": 361000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 361100,
                "areaName": "上饶市",
                "parent_code": 360000,
                "level": 2,
                "children": [
                    {
                        "code": 361102,
                        "areaName": "信州区",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361121,
                        "areaName": "上饶县",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361122,
                        "areaName": "广丰县",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361123,
                        "areaName": "玉山县",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361124,
                        "areaName": "铅山县",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361125,
                        "areaName": "横峰县",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361126,
                        "areaName": "弋阳县",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361127,
                        "areaName": "余干县",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361128,
                        "areaName": "鄱阳县",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361129,
                        "areaName": "万年县",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361130,
                        "areaName": "婺源县",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361181,
                        "areaName": "德兴市",
                        "parent_code": 361100,
                        "level": 3
                    },
                    {
                        "code": 361182,
                        "areaName": "上饶市",
                        "parent_code": 361100,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 370000,
        "areaName": "山东省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 370100,
                "areaName": "济南市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 370102,
                        "areaName": "历下区",
                        "parent_code": 370100,
                        "level": 3
                    },
                    {
                        "code": 370103,
                        "areaName": "市中区",
                        "parent_code": 370100,
                        "level": 3
                    },
                    {
                        "code": 370104,
                        "areaName": "槐荫区",
                        "parent_code": 370100,
                        "level": 3
                    },
                    {
                        "code": 370105,
                        "areaName": "天桥区",
                        "parent_code": 370100,
                        "level": 3
                    },
                    {
                        "code": 370112,
                        "areaName": "历城区",
                        "parent_code": 370100,
                        "level": 3
                    },
                    {
                        "code": 370113,
                        "areaName": "长清区",
                        "parent_code": 370100,
                        "level": 3
                    },
                    {
                        "code": 370124,
                        "areaName": "平阴县",
                        "parent_code": 370100,
                        "level": 3
                    },
                    {
                        "code": 370125,
                        "areaName": "济阳县",
                        "parent_code": 370100,
                        "level": 3
                    },
                    {
                        "code": 370126,
                        "areaName": "商河县",
                        "parent_code": 370100,
                        "level": 3
                    },
                    {
                        "code": 370181,
                        "areaName": "章丘市",
                        "parent_code": 370100,
                        "level": 3
                    },
                    {
                        "code": 370182,
                        "areaName": "济南市",
                        "parent_code": 370100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 370200,
                "areaName": "青岛市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 370202,
                        "areaName": "市南区",
                        "parent_code": 370200,
                        "level": 3
                    },
                    {
                        "code": 370203,
                        "areaName": "市北区",
                        "parent_code": 370200,
                        "level": 3
                    },
                    {
                        "code": 370211,
                        "areaName": "西海岸新区",
                        "parent_code": 370200,
                        "level": 3
                    },
                    {
                        "code": 370212,
                        "areaName": "崂山区",
                        "parent_code": 370200,
                        "level": 3
                    },
                    {
                        "code": 370213,
                        "areaName": "李沧区",
                        "parent_code": 370200,
                        "level": 3
                    },
                    {
                        "code": 370214,
                        "areaName": "城阳区",
                        "parent_code": 370200,
                        "level": 3
                    },
                    {
                        "code": 370281,
                        "areaName": "胶州市",
                        "parent_code": 370200,
                        "level": 3
                    },
                    {
                        "code": 370282,
                        "areaName": "即墨市",
                        "parent_code": 370200,
                        "level": 3
                    },
                    {
                        "code": 370283,
                        "areaName": "平度市",
                        "parent_code": 370200,
                        "level": 3
                    },
                    {
                        "code": 370285,
                        "areaName": "莱西市",
                        "parent_code": 370200,
                        "level": 3
                    },
                    {
                        "code": 370286,
                        "areaName": "青岛市",
                        "parent_code": 370200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 370300,
                "areaName": "淄博市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 370302,
                        "areaName": "淄川区",
                        "parent_code": 370300,
                        "level": 3
                    },
                    {
                        "code": 370303,
                        "areaName": "张店区",
                        "parent_code": 370300,
                        "level": 3
                    },
                    {
                        "code": 370304,
                        "areaName": "博山区",
                        "parent_code": 370300,
                        "level": 3
                    },
                    {
                        "code": 370305,
                        "areaName": "临淄区",
                        "parent_code": 370300,
                        "level": 3
                    },
                    {
                        "code": 370306,
                        "areaName": "周村区",
                        "parent_code": 370300,
                        "level": 3
                    },
                    {
                        "code": 370321,
                        "areaName": "桓台县",
                        "parent_code": 370300,
                        "level": 3
                    },
                    {
                        "code": 370322,
                        "areaName": "高青县",
                        "parent_code": 370300,
                        "level": 3
                    },
                    {
                        "code": 370323,
                        "areaName": "沂源县",
                        "parent_code": 370300,
                        "level": 3
                    },
                    {
                        "code": 370324,
                        "areaName": "淄博市",
                        "parent_code": 370300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 370400,
                "areaName": "枣庄市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 370402,
                        "areaName": "市中区",
                        "parent_code": 370400,
                        "level": 3
                    },
                    {
                        "code": 370403,
                        "areaName": "薛城区",
                        "parent_code": 370400,
                        "level": 3
                    },
                    {
                        "code": 370404,
                        "areaName": "峄城区",
                        "parent_code": 370400,
                        "level": 3
                    },
                    {
                        "code": 370405,
                        "areaName": "台儿庄区",
                        "parent_code": 370400,
                        "level": 3
                    },
                    {
                        "code": 370406,
                        "areaName": "山亭区",
                        "parent_code": 370400,
                        "level": 3
                    },
                    {
                        "code": 370481,
                        "areaName": "滕州市",
                        "parent_code": 370400,
                        "level": 3
                    },
                    {
                        "code": 370482,
                        "areaName": "枣庄市",
                        "parent_code": 370400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 370500,
                "areaName": "东营市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 370502,
                        "areaName": "东营区",
                        "parent_code": 370500,
                        "level": 3
                    },
                    {
                        "code": 370503,
                        "areaName": "河口区",
                        "parent_code": 370500,
                        "level": 3
                    },
                    {
                        "code": 370521,
                        "areaName": "垦利县",
                        "parent_code": 370500,
                        "level": 3
                    },
                    {
                        "code": 370522,
                        "areaName": "利津县",
                        "parent_code": 370500,
                        "level": 3
                    },
                    {
                        "code": 370523,
                        "areaName": "广饶县",
                        "parent_code": 370500,
                        "level": 3
                    },
                    {
                        "code": 370524,
                        "areaName": "东营市",
                        "parent_code": 370500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 370600,
                "areaName": "烟台市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 370602,
                        "areaName": "芝罘区",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370611,
                        "areaName": "福山区",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370612,
                        "areaName": "牟平区",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370613,
                        "areaName": "莱山区",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370634,
                        "areaName": "长岛县",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370681,
                        "areaName": "龙口市",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370682,
                        "areaName": "莱阳市",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370683,
                        "areaName": "莱州市",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370684,
                        "areaName": "蓬莱市",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370685,
                        "areaName": "招远市",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370686,
                        "areaName": "栖霞市",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370687,
                        "areaName": "海阳市",
                        "parent_code": 370600,
                        "level": 3
                    },
                    {
                        "code": 370688,
                        "areaName": "烟台市",
                        "parent_code": 370600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 370700,
                "areaName": "潍坊市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 370702,
                        "areaName": "潍城区",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370703,
                        "areaName": "寒亭区",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370704,
                        "areaName": "坊子区",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370705,
                        "areaName": "奎文区",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370724,
                        "areaName": "临朐县",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370725,
                        "areaName": "昌乐县",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370781,
                        "areaName": "青州市",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370782,
                        "areaName": "诸城市",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370783,
                        "areaName": "寿光市",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370784,
                        "areaName": "安丘市",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370785,
                        "areaName": "高密市",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370786,
                        "areaName": "昌邑市",
                        "parent_code": 370700,
                        "level": 3
                    },
                    {
                        "code": 370787,
                        "areaName": "潍坊市",
                        "parent_code": 370700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 370800,
                "areaName": "济宁市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 370802,
                        "areaName": "市中区",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370811,
                        "areaName": "任城区",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370826,
                        "areaName": "微山县",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370827,
                        "areaName": "鱼台县",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370828,
                        "areaName": "金乡县",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370829,
                        "areaName": "嘉祥县",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370830,
                        "areaName": "汶上县",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370831,
                        "areaName": "泗水县",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370832,
                        "areaName": "梁山县",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370881,
                        "areaName": "曲阜市",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370882,
                        "areaName": "兖州区",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370883,
                        "areaName": "邹城市",
                        "parent_code": 370800,
                        "level": 3
                    },
                    {
                        "code": 370884,
                        "areaName": "济宁市",
                        "parent_code": 370800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 370900,
                "areaName": "泰安市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 370902,
                        "areaName": "泰山区",
                        "parent_code": 370900,
                        "level": 3
                    },
                    {
                        "code": 370911,
                        "areaName": "岱岳区",
                        "parent_code": 370900,
                        "level": 3
                    },
                    {
                        "code": 370921,
                        "areaName": "宁阳县",
                        "parent_code": 370900,
                        "level": 3
                    },
                    {
                        "code": 370923,
                        "areaName": "东平县",
                        "parent_code": 370900,
                        "level": 3
                    },
                    {
                        "code": 370982,
                        "areaName": "新泰市",
                        "parent_code": 370900,
                        "level": 3
                    },
                    {
                        "code": 370983,
                        "areaName": "肥城市",
                        "parent_code": 370900,
                        "level": 3
                    },
                    {
                        "code": 370984,
                        "areaName": "泰安市",
                        "parent_code": 370900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 371000,
                "areaName": "威海市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 371002,
                        "areaName": "环翠区",
                        "parent_code": 371000,
                        "level": 3
                    },
                    {
                        "code": 371081,
                        "areaName": "文登区",
                        "parent_code": 371000,
                        "level": 3
                    },
                    {
                        "code": 371082,
                        "areaName": "荣成市",
                        "parent_code": 371000,
                        "level": 3
                    },
                    {
                        "code": 371083,
                        "areaName": "乳山市",
                        "parent_code": 371000,
                        "level": 3
                    },
                    {
                        "code": 371084,
                        "areaName": "威海市",
                        "parent_code": 371000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 371100,
                "areaName": "日照市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 371102,
                        "areaName": "东港区",
                        "parent_code": 371100,
                        "level": 3
                    },
                    {
                        "code": 371103,
                        "areaName": "岚山区",
                        "parent_code": 371100,
                        "level": 3
                    },
                    {
                        "code": 371121,
                        "areaName": "五莲县",
                        "parent_code": 371100,
                        "level": 3
                    },
                    {
                        "code": 371122,
                        "areaName": "莒县",
                        "parent_code": 371100,
                        "level": 3
                    },
                    {
                        "code": 371123,
                        "areaName": "日照市",
                        "parent_code": 371100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 371200,
                "areaName": "莱芜市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 371202,
                        "areaName": "莱城区",
                        "parent_code": 371200,
                        "level": 3
                    },
                    {
                        "code": 371203,
                        "areaName": "钢城区",
                        "parent_code": 371200,
                        "level": 3
                    },
                    {
                        "code": 371204,
                        "areaName": "莱芜市",
                        "parent_code": 371200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 371300,
                "areaName": "临沂市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 371302,
                        "areaName": "兰山区",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371311,
                        "areaName": "罗庄区",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371312,
                        "areaName": "河东区",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371321,
                        "areaName": "沂南县",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371322,
                        "areaName": "郯城县",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371323,
                        "areaName": "沂水县",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371324,
                        "areaName": "兰陵县",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371325,
                        "areaName": "费县",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371326,
                        "areaName": "平邑县",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371327,
                        "areaName": "莒南县",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371328,
                        "areaName": "蒙阴县",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371329,
                        "areaName": "临沭县",
                        "parent_code": 371300,
                        "level": 3
                    },
                    {
                        "code": 371330,
                        "areaName": "临沂市",
                        "parent_code": 371300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 371400,
                "areaName": "德州市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 371402,
                        "areaName": "德城区",
                        "parent_code": 371400,
                        "level": 3
                    },
                    {
                        "code": 371421,
                        "areaName": "陵城区",
                        "parent_code": 371400,
                        "level": 3
                    },
                    {
                        "code": 371422,
                        "areaName": "宁津县",
                        "parent_code": 371400,
                        "level": 3
                    },
                    {
                        "code": 371423,
                        "areaName": "庆云县",
                        "parent_code": 371400,
                        "level": 3
                    },
                    {
                        "code": 371424,
                        "areaName": "临邑县",
                        "parent_code": 371400,
                        "level": 3
                    },
                    {
                        "code": 371425,
                        "areaName": "齐河县",
                        "parent_code": 371400,
                        "level": 3
                    },
                    {
                        "code": 371426,
                        "areaName": "平原县",
                        "parent_code": 371400,
                        "level": 3
                    },
                    {
                        "code": 371427,
                        "areaName": "夏津县",
                        "parent_code": 371400,
                        "level": 3
                    },
                    {
                        "code": 371428,
                        "areaName": "武城县",
                        "parent_code": 371400,
                        "level": 3
                    },
                    {
                        "code": 371481,
                        "areaName": "乐陵市",
                        "parent_code": 371400,
                        "level": 3
                    },
                    {
                        "code": 371482,
                        "areaName": "禹城市",
                        "parent_code": 371400,
                        "level": 3
                    },
                    {
                        "code": 371483,
                        "areaName": "德州市",
                        "parent_code": 371400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 371500,
                "areaName": "聊城市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 371502,
                        "areaName": "东昌府区",
                        "parent_code": 371500,
                        "level": 3
                    },
                    {
                        "code": 371521,
                        "areaName": "阳谷县",
                        "parent_code": 371500,
                        "level": 3
                    },
                    {
                        "code": 371522,
                        "areaName": "莘县",
                        "parent_code": 371500,
                        "level": 3
                    },
                    {
                        "code": 371523,
                        "areaName": "茌平县",
                        "parent_code": 371500,
                        "level": 3
                    },
                    {
                        "code": 371524,
                        "areaName": "东阿县",
                        "parent_code": 371500,
                        "level": 3
                    },
                    {
                        "code": 371525,
                        "areaName": "冠县",
                        "parent_code": 371500,
                        "level": 3
                    },
                    {
                        "code": 371526,
                        "areaName": "高唐县",
                        "parent_code": 371500,
                        "level": 3
                    },
                    {
                        "code": 371581,
                        "areaName": "临清市",
                        "parent_code": 371500,
                        "level": 3
                    },
                    {
                        "code": 371582,
                        "areaName": "聊城市",
                        "parent_code": 371500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 371600,
                "areaName": "滨州市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 371602,
                        "areaName": "滨城区",
                        "parent_code": 371600,
                        "level": 3
                    },
                    {
                        "code": 371621,
                        "areaName": "惠民县",
                        "parent_code": 371600,
                        "level": 3
                    },
                    {
                        "code": 371622,
                        "areaName": "阳信县",
                        "parent_code": 371600,
                        "level": 3
                    },
                    {
                        "code": 371623,
                        "areaName": "无棣县",
                        "parent_code": 371600,
                        "level": 3
                    },
                    {
                        "code": 371624,
                        "areaName": "沾化区",
                        "parent_code": 371600,
                        "level": 3
                    },
                    {
                        "code": 371625,
                        "areaName": "博兴县",
                        "parent_code": 371600,
                        "level": 3
                    },
                    {
                        "code": 371626,
                        "areaName": "邹平县",
                        "parent_code": 371600,
                        "level": 3
                    },
                    {
                        "code": 371627,
                        "areaName": "滨州市",
                        "parent_code": 371600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 371700,
                "areaName": "菏泽市",
                "parent_code": 370000,
                "level": 2,
                "children": [
                    {
                        "code": 371702,
                        "areaName": "牡丹区",
                        "parent_code": 371700,
                        "level": 3
                    },
                    {
                        "code": 371721,
                        "areaName": "曹县",
                        "parent_code": 371700,
                        "level": 3
                    },
                    {
                        "code": 371722,
                        "areaName": "单县",
                        "parent_code": 371700,
                        "level": 3
                    },
                    {
                        "code": 371723,
                        "areaName": "成武县",
                        "parent_code": 371700,
                        "level": 3
                    },
                    {
                        "code": 371724,
                        "areaName": "巨野县",
                        "parent_code": 371700,
                        "level": 3
                    },
                    {
                        "code": 371725,
                        "areaName": "郓城县",
                        "parent_code": 371700,
                        "level": 3
                    },
                    {
                        "code": 371726,
                        "areaName": "鄄城县",
                        "parent_code": 371700,
                        "level": 3
                    },
                    {
                        "code": 371727,
                        "areaName": "定陶县",
                        "parent_code": 371700,
                        "level": 3
                    },
                    {
                        "code": 371728,
                        "areaName": "东明县",
                        "parent_code": 371700,
                        "level": 3
                    },
                    {
                        "code": 371729,
                        "areaName": "菏泽市",
                        "parent_code": 371700,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 410000,
        "areaName": "河南省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 410100,
                "areaName": "郑州市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 410102,
                        "areaName": "中原区",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410103,
                        "areaName": "二七区",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410104,
                        "areaName": "管城回族区",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410105,
                        "areaName": "金水区",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410106,
                        "areaName": "上街区",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410108,
                        "areaName": "惠济区",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410122,
                        "areaName": "中牟县",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410181,
                        "areaName": "巩义市",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410182,
                        "areaName": "荥阳市",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410183,
                        "areaName": "新密市",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410184,
                        "areaName": "新郑市",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410185,
                        "areaName": "登封市",
                        "parent_code": 410100,
                        "level": 3
                    },
                    {
                        "code": 410186,
                        "areaName": "郑州市",
                        "parent_code": 410100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 410200,
                "areaName": "开封市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 410202,
                        "areaName": "龙亭区",
                        "parent_code": 410200,
                        "level": 3
                    },
                    {
                        "code": 410203,
                        "areaName": "顺河回族区",
                        "parent_code": 410200,
                        "level": 3
                    },
                    {
                        "code": 410204,
                        "areaName": "鼓楼区",
                        "parent_code": 410200,
                        "level": 3
                    },
                    {
                        "code": 410205,
                        "areaName": "禹王台区",
                        "parent_code": 410200,
                        "level": 3
                    },
                    {
                        "code": 410211,
                        "areaName": "金明区",
                        "parent_code": 410200,
                        "level": 3
                    },
                    {
                        "code": 410221,
                        "areaName": "杞县",
                        "parent_code": 410200,
                        "level": 3
                    },
                    {
                        "code": 410222,
                        "areaName": "通许县",
                        "parent_code": 410200,
                        "level": 3
                    },
                    {
                        "code": 410223,
                        "areaName": "尉氏县",
                        "parent_code": 410200,
                        "level": 3
                    },
                    {
                        "code": 410224,
                        "areaName": "祥符区",
                        "parent_code": 410200,
                        "level": 3
                    },
                    {
                        "code": 410225,
                        "areaName": "兰考县",
                        "parent_code": 410200,
                        "level": 3
                    },
                    {
                        "code": 410226,
                        "areaName": "开封市",
                        "parent_code": 410200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 410300,
                "areaName": "洛阳市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 410302,
                        "areaName": "老城区",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410303,
                        "areaName": "西工区",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410304,
                        "areaName": "瀍河回族区",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410305,
                        "areaName": "涧西区",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410306,
                        "areaName": "吉利区",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410311,
                        "areaName": "洛龙区",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410322,
                        "areaName": "孟津县",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410323,
                        "areaName": "新安县",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410324,
                        "areaName": "栾川县",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410325,
                        "areaName": "嵩县",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410326,
                        "areaName": "汝阳县",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410327,
                        "areaName": "宜阳县",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410328,
                        "areaName": "洛宁县",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410329,
                        "areaName": "伊川县",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410381,
                        "areaName": "偃师市",
                        "parent_code": 410300,
                        "level": 3
                    },
                    {
                        "code": 410382,
                        "areaName": "洛阳市",
                        "parent_code": 410300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 410400,
                "areaName": "平顶山市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 410402,
                        "areaName": "新华区",
                        "parent_code": 410400,
                        "level": 3
                    },
                    {
                        "code": 410403,
                        "areaName": "卫东区",
                        "parent_code": 410400,
                        "level": 3
                    },
                    {
                        "code": 410404,
                        "areaName": "石龙区",
                        "parent_code": 410400,
                        "level": 3
                    },
                    {
                        "code": 410411,
                        "areaName": "湛河区",
                        "parent_code": 410400,
                        "level": 3
                    },
                    {
                        "code": 410421,
                        "areaName": "宝丰县",
                        "parent_code": 410400,
                        "level": 3
                    },
                    {
                        "code": 410422,
                        "areaName": "叶县",
                        "parent_code": 410400,
                        "level": 3
                    },
                    {
                        "code": 410423,
                        "areaName": "鲁山县",
                        "parent_code": 410400,
                        "level": 3
                    },
                    {
                        "code": 410425,
                        "areaName": "郏县",
                        "parent_code": 410400,
                        "level": 3
                    },
                    {
                        "code": 410481,
                        "areaName": "舞钢市",
                        "parent_code": 410400,
                        "level": 3
                    },
                    {
                        "code": 410482,
                        "areaName": "汝州市",
                        "parent_code": 410400,
                        "level": 3
                    },
                    {
                        "code": 410483,
                        "areaName": "平顶山市",
                        "parent_code": 410400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 410500,
                "areaName": "安阳市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 410502,
                        "areaName": "文峰区",
                        "parent_code": 410500,
                        "level": 3
                    },
                    {
                        "code": 410503,
                        "areaName": "北关区",
                        "parent_code": 410500,
                        "level": 3
                    },
                    {
                        "code": 410505,
                        "areaName": "殷都区",
                        "parent_code": 410500,
                        "level": 3
                    },
                    {
                        "code": 410506,
                        "areaName": "龙安区",
                        "parent_code": 410500,
                        "level": 3
                    },
                    {
                        "code": 410522,
                        "areaName": "安阳县",
                        "parent_code": 410500,
                        "level": 3
                    },
                    {
                        "code": 410523,
                        "areaName": "汤阴县",
                        "parent_code": 410500,
                        "level": 3
                    },
                    {
                        "code": 410526,
                        "areaName": "滑县",
                        "parent_code": 410500,
                        "level": 3
                    },
                    {
                        "code": 410527,
                        "areaName": "内黄县",
                        "parent_code": 410500,
                        "level": 3
                    },
                    {
                        "code": 410581,
                        "areaName": "林州市",
                        "parent_code": 410500,
                        "level": 3
                    },
                    {
                        "code": 410582,
                        "areaName": "安阳市",
                        "parent_code": 410500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 410600,
                "areaName": "鹤壁市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 410602,
                        "areaName": "鹤山区",
                        "parent_code": 410600,
                        "level": 3
                    },
                    {
                        "code": 410603,
                        "areaName": "山城区",
                        "parent_code": 410600,
                        "level": 3
                    },
                    {
                        "code": 410611,
                        "areaName": "淇滨区",
                        "parent_code": 410600,
                        "level": 3
                    },
                    {
                        "code": 410621,
                        "areaName": "浚县",
                        "parent_code": 410600,
                        "level": 3
                    },
                    {
                        "code": 410622,
                        "areaName": "淇县",
                        "parent_code": 410600,
                        "level": 3
                    },
                    {
                        "code": 410623,
                        "areaName": "鹤壁市",
                        "parent_code": 410600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 410700,
                "areaName": "新乡市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 410702,
                        "areaName": "红旗区",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410703,
                        "areaName": "卫滨区",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410704,
                        "areaName": "凤泉区",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410711,
                        "areaName": "牧野区",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410721,
                        "areaName": "新乡县",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410724,
                        "areaName": "获嘉县",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410725,
                        "areaName": "原阳县",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410726,
                        "areaName": "延津县",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410727,
                        "areaName": "封丘县",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410728,
                        "areaName": "长垣县",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410781,
                        "areaName": "卫辉市",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410782,
                        "areaName": "辉县市",
                        "parent_code": 410700,
                        "level": 3
                    },
                    {
                        "code": 410783,
                        "areaName": "新乡市",
                        "parent_code": 410700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 410800,
                "areaName": "焦作市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 410802,
                        "areaName": "解放区",
                        "parent_code": 410800,
                        "level": 3
                    },
                    {
                        "code": 410803,
                        "areaName": "中站区",
                        "parent_code": 410800,
                        "level": 3
                    },
                    {
                        "code": 410804,
                        "areaName": "马村区",
                        "parent_code": 410800,
                        "level": 3
                    },
                    {
                        "code": 410811,
                        "areaName": "山阳区",
                        "parent_code": 410800,
                        "level": 3
                    },
                    {
                        "code": 410821,
                        "areaName": "修武县",
                        "parent_code": 410800,
                        "level": 3
                    },
                    {
                        "code": 410822,
                        "areaName": "博爱县",
                        "parent_code": 410800,
                        "level": 3
                    },
                    {
                        "code": 410823,
                        "areaName": "武陟县",
                        "parent_code": 410800,
                        "level": 3
                    },
                    {
                        "code": 410825,
                        "areaName": "温县",
                        "parent_code": 410800,
                        "level": 3
                    },
                    {
                        "code": 410882,
                        "areaName": "沁阳市",
                        "parent_code": 410800,
                        "level": 3
                    },
                    {
                        "code": 410883,
                        "areaName": "孟州市",
                        "parent_code": 410800,
                        "level": 3
                    },
                    {
                        "code": 410884,
                        "areaName": "焦作市",
                        "parent_code": 410800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 410900,
                "areaName": "濮阳市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 410902,
                        "areaName": "华龙区",
                        "parent_code": 410900,
                        "level": 3
                    },
                    {
                        "code": 410922,
                        "areaName": "清丰县",
                        "parent_code": 410900,
                        "level": 3
                    },
                    {
                        "code": 410923,
                        "areaName": "南乐县",
                        "parent_code": 410900,
                        "level": 3
                    },
                    {
                        "code": 410926,
                        "areaName": "范县",
                        "parent_code": 410900,
                        "level": 3
                    },
                    {
                        "code": 410927,
                        "areaName": "台前县",
                        "parent_code": 410900,
                        "level": 3
                    },
                    {
                        "code": 410928,
                        "areaName": "濮阳县",
                        "parent_code": 410900,
                        "level": 3
                    },
                    {
                        "code": 410929,
                        "areaName": "濮阳市",
                        "parent_code": 410900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 411000,
                "areaName": "许昌市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 411002,
                        "areaName": "魏都区",
                        "parent_code": 411000,
                        "level": 3
                    },
                    {
                        "code": 411023,
                        "areaName": "许昌县",
                        "parent_code": 411000,
                        "level": 3
                    },
                    {
                        "code": 411024,
                        "areaName": "鄢陵县",
                        "parent_code": 411000,
                        "level": 3
                    },
                    {
                        "code": 411025,
                        "areaName": "襄城县",
                        "parent_code": 411000,
                        "level": 3
                    },
                    {
                        "code": 411081,
                        "areaName": "禹州市",
                        "parent_code": 411000,
                        "level": 3
                    },
                    {
                        "code": 411082,
                        "areaName": "长葛市",
                        "parent_code": 411000,
                        "level": 3
                    },
                    {
                        "code": 411083,
                        "areaName": "许昌市",
                        "parent_code": 411000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 411100,
                "areaName": "漯河市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 411102,
                        "areaName": "源汇区",
                        "parent_code": 411100,
                        "level": 3
                    },
                    {
                        "code": 411103,
                        "areaName": "郾城区",
                        "parent_code": 411100,
                        "level": 3
                    },
                    {
                        "code": 411104,
                        "areaName": "召陵区",
                        "parent_code": 411100,
                        "level": 3
                    },
                    {
                        "code": 411121,
                        "areaName": "舞阳县",
                        "parent_code": 411100,
                        "level": 3
                    },
                    {
                        "code": 411122,
                        "areaName": "临颍县",
                        "parent_code": 411100,
                        "level": 3
                    },
                    {
                        "code": 411123,
                        "areaName": "漯河市",
                        "parent_code": 411100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 411200,
                "areaName": "三门峡市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 411202,
                        "areaName": "湖滨区",
                        "parent_code": 411200,
                        "level": 3
                    },
                    {
                        "code": 411221,
                        "areaName": "渑池县",
                        "parent_code": 411200,
                        "level": 3
                    },
                    {
                        "code": 411222,
                        "areaName": "陕县",
                        "parent_code": 411200,
                        "level": 3
                    },
                    {
                        "code": 411224,
                        "areaName": "卢氏县",
                        "parent_code": 411200,
                        "level": 3
                    },
                    {
                        "code": 411281,
                        "areaName": "义马市",
                        "parent_code": 411200,
                        "level": 3
                    },
                    {
                        "code": 411282,
                        "areaName": "灵宝市",
                        "parent_code": 411200,
                        "level": 3
                    },
                    {
                        "code": 411283,
                        "areaName": "三门峡市",
                        "parent_code": 411200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 411300,
                "areaName": "南阳市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 411302,
                        "areaName": "宛城区",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411303,
                        "areaName": "卧龙区",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411321,
                        "areaName": "南召县",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411322,
                        "areaName": "方城县",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411323,
                        "areaName": "西峡县",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411324,
                        "areaName": "镇平县",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411325,
                        "areaName": "内乡县",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411326,
                        "areaName": "淅川县",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411327,
                        "areaName": "社旗县",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411328,
                        "areaName": "唐河县",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411329,
                        "areaName": "新野县",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411330,
                        "areaName": "桐柏县",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411381,
                        "areaName": "邓州市",
                        "parent_code": 411300,
                        "level": 3
                    },
                    {
                        "code": 411382,
                        "areaName": "南阳市",
                        "parent_code": 411300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 411400,
                "areaName": "商丘市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 411402,
                        "areaName": "梁园区",
                        "parent_code": 411400,
                        "level": 3
                    },
                    {
                        "code": 411403,
                        "areaName": "睢阳区",
                        "parent_code": 411400,
                        "level": 3
                    },
                    {
                        "code": 411421,
                        "areaName": "民权县",
                        "parent_code": 411400,
                        "level": 3
                    },
                    {
                        "code": 411422,
                        "areaName": "睢县",
                        "parent_code": 411400,
                        "level": 3
                    },
                    {
                        "code": 411423,
                        "areaName": "宁陵县",
                        "parent_code": 411400,
                        "level": 3
                    },
                    {
                        "code": 411424,
                        "areaName": "柘城县",
                        "parent_code": 411400,
                        "level": 3
                    },
                    {
                        "code": 411425,
                        "areaName": "虞城县",
                        "parent_code": 411400,
                        "level": 3
                    },
                    {
                        "code": 411426,
                        "areaName": "夏邑县",
                        "parent_code": 411400,
                        "level": 3
                    },
                    {
                        "code": 411481,
                        "areaName": "永城市",
                        "parent_code": 411400,
                        "level": 3
                    },
                    {
                        "code": 411482,
                        "areaName": "商丘市",
                        "parent_code": 411400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 411500,
                "areaName": "信阳市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 411502,
                        "areaName": "浉河区",
                        "parent_code": 411500,
                        "level": 3
                    },
                    {
                        "code": 411503,
                        "areaName": "平桥区",
                        "parent_code": 411500,
                        "level": 3
                    },
                    {
                        "code": 411521,
                        "areaName": "罗山县",
                        "parent_code": 411500,
                        "level": 3
                    },
                    {
                        "code": 411522,
                        "areaName": "光山县",
                        "parent_code": 411500,
                        "level": 3
                    },
                    {
                        "code": 411523,
                        "areaName": "新县",
                        "parent_code": 411500,
                        "level": 3
                    },
                    {
                        "code": 411524,
                        "areaName": "商城县",
                        "parent_code": 411500,
                        "level": 3
                    },
                    {
                        "code": 411525,
                        "areaName": "固始县",
                        "parent_code": 411500,
                        "level": 3
                    },
                    {
                        "code": 411526,
                        "areaName": "潢川县",
                        "parent_code": 411500,
                        "level": 3
                    },
                    {
                        "code": 411527,
                        "areaName": "淮滨县",
                        "parent_code": 411500,
                        "level": 3
                    },
                    {
                        "code": 411528,
                        "areaName": "息县",
                        "parent_code": 411500,
                        "level": 3
                    },
                    {
                        "code": 411529,
                        "areaName": "信阳市",
                        "parent_code": 411500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 411600,
                "areaName": "周口市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 411602,
                        "areaName": "川汇区",
                        "parent_code": 411600,
                        "level": 3
                    },
                    {
                        "code": 411621,
                        "areaName": "扶沟县",
                        "parent_code": 411600,
                        "level": 3
                    },
                    {
                        "code": 411622,
                        "areaName": "西华县",
                        "parent_code": 411600,
                        "level": 3
                    },
                    {
                        "code": 411623,
                        "areaName": "商水县",
                        "parent_code": 411600,
                        "level": 3
                    },
                    {
                        "code": 411624,
                        "areaName": "沈丘县",
                        "parent_code": 411600,
                        "level": 3
                    },
                    {
                        "code": 411625,
                        "areaName": "郸城县",
                        "parent_code": 411600,
                        "level": 3
                    },
                    {
                        "code": 411626,
                        "areaName": "淮阳县",
                        "parent_code": 411600,
                        "level": 3
                    },
                    {
                        "code": 411627,
                        "areaName": "太康县",
                        "parent_code": 411600,
                        "level": 3
                    },
                    {
                        "code": 411628,
                        "areaName": "鹿邑县",
                        "parent_code": 411600,
                        "level": 3
                    },
                    {
                        "code": 411681,
                        "areaName": "项城市",
                        "parent_code": 411600,
                        "level": 3
                    },
                    {
                        "code": 411682,
                        "areaName": "周口市",
                        "parent_code": 411600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 411700,
                "areaName": "驻马店市",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 411702,
                        "areaName": "驿城区",
                        "parent_code": 411700,
                        "level": 3
                    },
                    {
                        "code": 411721,
                        "areaName": "西平县",
                        "parent_code": 411700,
                        "level": 3
                    },
                    {
                        "code": 411722,
                        "areaName": "上蔡县",
                        "parent_code": 411700,
                        "level": 3
                    },
                    {
                        "code": 411723,
                        "areaName": "平舆县",
                        "parent_code": 411700,
                        "level": 3
                    },
                    {
                        "code": 411724,
                        "areaName": "正阳县",
                        "parent_code": 411700,
                        "level": 3
                    },
                    {
                        "code": 411725,
                        "areaName": "确山县",
                        "parent_code": 411700,
                        "level": 3
                    },
                    {
                        "code": 411726,
                        "areaName": "泌阳县",
                        "parent_code": 411700,
                        "level": 3
                    },
                    {
                        "code": 411727,
                        "areaName": "汝南县",
                        "parent_code": 411700,
                        "level": 3
                    },
                    {
                        "code": 411728,
                        "areaName": "遂平县",
                        "parent_code": 411700,
                        "level": 3
                    },
                    {
                        "code": 411729,
                        "areaName": "新蔡县",
                        "parent_code": 411700,
                        "level": 3
                    },
                    {
                        "code": 411730,
                        "areaName": "驻马店市",
                        "parent_code": 411700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 419000,
                "areaName": "省直辖县级行政区划",
                "parent_code": 410000,
                "level": 2,
                "children": [
                    {
                        "code": 419001,
                        "areaName": "济源市",
                        "parent_code": 419000,
                        "level": 3
                    },
                    {
                        "code": 419002,
                        "areaName": "省直辖县级行政区划",
                        "parent_code": 419000,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 420000,
        "areaName": "湖北省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 420100,
                "areaName": "武汉市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 420102,
                        "areaName": "江岸区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420103,
                        "areaName": "江汉区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420104,
                        "areaName": "硚口区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420105,
                        "areaName": "汉阳区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420106,
                        "areaName": "武昌区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420107,
                        "areaName": "青山区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420111,
                        "areaName": "洪山区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420112,
                        "areaName": "东西湖区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420113,
                        "areaName": "汉南区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420114,
                        "areaName": "蔡甸区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420115,
                        "areaName": "江夏区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420116,
                        "areaName": "黄陂区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420117,
                        "areaName": "新洲区",
                        "parent_code": 420100,
                        "level": 3
                    },
                    {
                        "code": 420118,
                        "areaName": "武汉市",
                        "parent_code": 420100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 420200,
                "areaName": "黄石市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 420202,
                        "areaName": "黄石港区",
                        "parent_code": 420200,
                        "level": 3
                    },
                    {
                        "code": 420203,
                        "areaName": "西塞山区",
                        "parent_code": 420200,
                        "level": 3
                    },
                    {
                        "code": 420204,
                        "areaName": "下陆区",
                        "parent_code": 420200,
                        "level": 3
                    },
                    {
                        "code": 420205,
                        "areaName": "铁山区",
                        "parent_code": 420200,
                        "level": 3
                    },
                    {
                        "code": 420222,
                        "areaName": "阳新县",
                        "parent_code": 420200,
                        "level": 3
                    },
                    {
                        "code": 420281,
                        "areaName": "大冶市",
                        "parent_code": 420200,
                        "level": 3
                    },
                    {
                        "code": 420282,
                        "areaName": "黄石市",
                        "parent_code": 420200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 420300,
                "areaName": "十堰市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 420302,
                        "areaName": "茅箭区",
                        "parent_code": 420300,
                        "level": 3
                    },
                    {
                        "code": 420303,
                        "areaName": "张湾区",
                        "parent_code": 420300,
                        "level": 3
                    },
                    {
                        "code": 420321,
                        "areaName": "郧阳区",
                        "parent_code": 420300,
                        "level": 3
                    },
                    {
                        "code": 420322,
                        "areaName": "郧西县",
                        "parent_code": 420300,
                        "level": 3
                    },
                    {
                        "code": 420323,
                        "areaName": "竹山县",
                        "parent_code": 420300,
                        "level": 3
                    },
                    {
                        "code": 420324,
                        "areaName": "竹溪县",
                        "parent_code": 420300,
                        "level": 3
                    },
                    {
                        "code": 420325,
                        "areaName": "房县",
                        "parent_code": 420300,
                        "level": 3
                    },
                    {
                        "code": 420381,
                        "areaName": "丹江口市",
                        "parent_code": 420300,
                        "level": 3
                    },
                    {
                        "code": 420382,
                        "areaName": "十堰市",
                        "parent_code": 420300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 420500,
                "areaName": "宜昌市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 420502,
                        "areaName": "西陵区",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420503,
                        "areaName": "伍家岗区",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420504,
                        "areaName": "点军区",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420505,
                        "areaName": "猇亭区",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420506,
                        "areaName": "夷陵区",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420525,
                        "areaName": "远安县",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420526,
                        "areaName": "兴山县",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420527,
                        "areaName": "秭归县",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420528,
                        "areaName": "长阳土家族自治县",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420529,
                        "areaName": "五峰土家族自治县",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420581,
                        "areaName": "宜都市",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420582,
                        "areaName": "当阳市",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420583,
                        "areaName": "枝江市",
                        "parent_code": 420500,
                        "level": 3
                    },
                    {
                        "code": 420584,
                        "areaName": "宜昌市",
                        "parent_code": 420500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 420600,
                "areaName": "襄阳市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 420602,
                        "areaName": "襄城区",
                        "parent_code": 420600,
                        "level": 3
                    },
                    {
                        "code": 420606,
                        "areaName": "樊城区",
                        "parent_code": 420600,
                        "level": 3
                    },
                    {
                        "code": 420607,
                        "areaName": "襄州区",
                        "parent_code": 420600,
                        "level": 3
                    },
                    {
                        "code": 420624,
                        "areaName": "南漳县",
                        "parent_code": 420600,
                        "level": 3
                    },
                    {
                        "code": 420625,
                        "areaName": "谷城县",
                        "parent_code": 420600,
                        "level": 3
                    },
                    {
                        "code": 420626,
                        "areaName": "保康县",
                        "parent_code": 420600,
                        "level": 3
                    },
                    {
                        "code": 420682,
                        "areaName": "老河口市",
                        "parent_code": 420600,
                        "level": 3
                    },
                    {
                        "code": 420683,
                        "areaName": "枣阳市",
                        "parent_code": 420600,
                        "level": 3
                    },
                    {
                        "code": 420684,
                        "areaName": "宜城市",
                        "parent_code": 420600,
                        "level": 3
                    },
                    {
                        "code": 420685,
                        "areaName": "襄阳市",
                        "parent_code": 420600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 420700,
                "areaName": "鄂州市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 420702,
                        "areaName": "梁子湖区",
                        "parent_code": 420700,
                        "level": 3
                    },
                    {
                        "code": 420703,
                        "areaName": "华容区",
                        "parent_code": 420700,
                        "level": 3
                    },
                    {
                        "code": 420704,
                        "areaName": "鄂城区",
                        "parent_code": 420700,
                        "level": 3
                    },
                    {
                        "code": 420705,
                        "areaName": "鄂州市",
                        "parent_code": 420700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 420800,
                "areaName": "荆门市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 420802,
                        "areaName": "东宝区",
                        "parent_code": 420800,
                        "level": 3
                    },
                    {
                        "code": 420804,
                        "areaName": "掇刀区",
                        "parent_code": 420800,
                        "level": 3
                    },
                    {
                        "code": 420821,
                        "areaName": "京山县",
                        "parent_code": 420800,
                        "level": 3
                    },
                    {
                        "code": 420822,
                        "areaName": "沙洋县",
                        "parent_code": 420800,
                        "level": 3
                    },
                    {
                        "code": 420881,
                        "areaName": "钟祥市",
                        "parent_code": 420800,
                        "level": 3
                    },
                    {
                        "code": 420882,
                        "areaName": "荆门市",
                        "parent_code": 420800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 420900,
                "areaName": "孝感市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 420902,
                        "areaName": "孝南区",
                        "parent_code": 420900,
                        "level": 3
                    },
                    {
                        "code": 420921,
                        "areaName": "孝昌县",
                        "parent_code": 420900,
                        "level": 3
                    },
                    {
                        "code": 420922,
                        "areaName": "大悟县",
                        "parent_code": 420900,
                        "level": 3
                    },
                    {
                        "code": 420923,
                        "areaName": "云梦县",
                        "parent_code": 420900,
                        "level": 3
                    },
                    {
                        "code": 420981,
                        "areaName": "应城市",
                        "parent_code": 420900,
                        "level": 3
                    },
                    {
                        "code": 420982,
                        "areaName": "安陆市",
                        "parent_code": 420900,
                        "level": 3
                    },
                    {
                        "code": 420984,
                        "areaName": "汉川市",
                        "parent_code": 420900,
                        "level": 3
                    },
                    {
                        "code": 420985,
                        "areaName": "孝感市",
                        "parent_code": 420900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 421000,
                "areaName": "荆州市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 421002,
                        "areaName": "沙市区",
                        "parent_code": 421000,
                        "level": 3
                    },
                    {
                        "code": 421003,
                        "areaName": "荆州区",
                        "parent_code": 421000,
                        "level": 3
                    },
                    {
                        "code": 421022,
                        "areaName": "公安县",
                        "parent_code": 421000,
                        "level": 3
                    },
                    {
                        "code": 421023,
                        "areaName": "监利县",
                        "parent_code": 421000,
                        "level": 3
                    },
                    {
                        "code": 421024,
                        "areaName": "江陵县",
                        "parent_code": 421000,
                        "level": 3
                    },
                    {
                        "code": 421081,
                        "areaName": "石首市",
                        "parent_code": 421000,
                        "level": 3
                    },
                    {
                        "code": 421083,
                        "areaName": "洪湖市",
                        "parent_code": 421000,
                        "level": 3
                    },
                    {
                        "code": 421087,
                        "areaName": "松滋市",
                        "parent_code": 421000,
                        "level": 3
                    },
                    {
                        "code": 421088,
                        "areaName": "荆州市",
                        "parent_code": 421000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 421100,
                "areaName": "黄冈市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 421102,
                        "areaName": "黄州区",
                        "parent_code": 421100,
                        "level": 3
                    },
                    {
                        "code": 421121,
                        "areaName": "团风县",
                        "parent_code": 421100,
                        "level": 3
                    },
                    {
                        "code": 421122,
                        "areaName": "红安县",
                        "parent_code": 421100,
                        "level": 3
                    },
                    {
                        "code": 421123,
                        "areaName": "罗田县",
                        "parent_code": 421100,
                        "level": 3
                    },
                    {
                        "code": 421124,
                        "areaName": "英山县",
                        "parent_code": 421100,
                        "level": 3
                    },
                    {
                        "code": 421125,
                        "areaName": "浠水县",
                        "parent_code": 421100,
                        "level": 3
                    },
                    {
                        "code": 421126,
                        "areaName": "蕲春县",
                        "parent_code": 421100,
                        "level": 3
                    },
                    {
                        "code": 421127,
                        "areaName": "黄梅县",
                        "parent_code": 421100,
                        "level": 3
                    },
                    {
                        "code": 421181,
                        "areaName": "麻城市",
                        "parent_code": 421100,
                        "level": 3
                    },
                    {
                        "code": 421182,
                        "areaName": "武穴市",
                        "parent_code": 421100,
                        "level": 3
                    },
                    {
                        "code": 421183,
                        "areaName": "黄冈市",
                        "parent_code": 421100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 421200,
                "areaName": "咸宁市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 421202,
                        "areaName": "咸安区",
                        "parent_code": 421200,
                        "level": 3
                    },
                    {
                        "code": 421221,
                        "areaName": "嘉鱼县",
                        "parent_code": 421200,
                        "level": 3
                    },
                    {
                        "code": 421222,
                        "areaName": "通城县",
                        "parent_code": 421200,
                        "level": 3
                    },
                    {
                        "code": 421223,
                        "areaName": "崇阳县",
                        "parent_code": 421200,
                        "level": 3
                    },
                    {
                        "code": 421224,
                        "areaName": "通山县",
                        "parent_code": 421200,
                        "level": 3
                    },
                    {
                        "code": 421281,
                        "areaName": "赤壁市",
                        "parent_code": 421200,
                        "level": 3
                    },
                    {
                        "code": 421282,
                        "areaName": "咸宁市",
                        "parent_code": 421200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 421300,
                "areaName": "随州市",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 421303,
                        "areaName": "曾都区",
                        "parent_code": 421300,
                        "level": 3
                    },
                    {
                        "code": 421321,
                        "areaName": "随县",
                        "parent_code": 421300,
                        "level": 3
                    },
                    {
                        "code": 421381,
                        "areaName": "广水市",
                        "parent_code": 421300,
                        "level": 3
                    },
                    {
                        "code": 421382,
                        "areaName": "随州市",
                        "parent_code": 421300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 422800,
                "areaName": "恩施土家族苗族自治州",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 422801,
                        "areaName": "恩施市",
                        "parent_code": 422800,
                        "level": 3
                    },
                    {
                        "code": 422802,
                        "areaName": "利川市",
                        "parent_code": 422800,
                        "level": 3
                    },
                    {
                        "code": 422822,
                        "areaName": "建始县",
                        "parent_code": 422800,
                        "level": 3
                    },
                    {
                        "code": 422823,
                        "areaName": "巴东县",
                        "parent_code": 422800,
                        "level": 3
                    },
                    {
                        "code": 422825,
                        "areaName": "宣恩县",
                        "parent_code": 422800,
                        "level": 3
                    },
                    {
                        "code": 422826,
                        "areaName": "咸丰县",
                        "parent_code": 422800,
                        "level": 3
                    },
                    {
                        "code": 422827,
                        "areaName": "来凤县",
                        "parent_code": 422800,
                        "level": 3
                    },
                    {
                        "code": 422828,
                        "areaName": "鹤峰县",
                        "parent_code": 422800,
                        "level": 3
                    },
                    {
                        "code": 422829,
                        "areaName": "恩施土家族苗族自治州",
                        "parent_code": 422800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 429000,
                "areaName": "省直辖县级行政区划",
                "parent_code": 420000,
                "level": 2,
                "children": [
                    {
                        "code": 429004,
                        "areaName": "仙桃市",
                        "parent_code": 429000,
                        "level": 3
                    },
                    {
                        "code": 429005,
                        "areaName": "潜江市",
                        "parent_code": 429000,
                        "level": 3
                    },
                    {
                        "code": 429006,
                        "areaName": "天门市",
                        "parent_code": 429000,
                        "level": 3
                    },
                    {
                        "code": 429021,
                        "areaName": "神农架林区",
                        "parent_code": 429000,
                        "level": 3
                    },
                    {
                        "code": 429022,
                        "areaName": "省直辖县级行政区划",
                        "parent_code": 429000,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 430000,
        "areaName": "湖南省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 430100,
                "areaName": "长沙市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 430102,
                        "areaName": "芙蓉区",
                        "parent_code": 430100,
                        "level": 3
                    },
                    {
                        "code": 430103,
                        "areaName": "天心区",
                        "parent_code": 430100,
                        "level": 3
                    },
                    {
                        "code": 430104,
                        "areaName": "岳麓区",
                        "parent_code": 430100,
                        "level": 3
                    },
                    {
                        "code": 430105,
                        "areaName": "开福区",
                        "parent_code": 430100,
                        "level": 3
                    },
                    {
                        "code": 430111,
                        "areaName": "雨花区",
                        "parent_code": 430100,
                        "level": 3
                    },
                    {
                        "code": 430112,
                        "areaName": "望城区",
                        "parent_code": 430100,
                        "level": 3
                    },
                    {
                        "code": 430121,
                        "areaName": "长沙县",
                        "parent_code": 430100,
                        "level": 3
                    },
                    {
                        "code": 430124,
                        "areaName": "宁乡县",
                        "parent_code": 430100,
                        "level": 3
                    },
                    {
                        "code": 430181,
                        "areaName": "浏阳市",
                        "parent_code": 430100,
                        "level": 3
                    },
                    {
                        "code": 430182,
                        "areaName": "长沙市",
                        "parent_code": 430100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 430200,
                "areaName": "株洲市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 430202,
                        "areaName": "荷塘区",
                        "parent_code": 430200,
                        "level": 3
                    },
                    {
                        "code": 430203,
                        "areaName": "芦淞区",
                        "parent_code": 430200,
                        "level": 3
                    },
                    {
                        "code": 430204,
                        "areaName": "石峰区",
                        "parent_code": 430200,
                        "level": 3
                    },
                    {
                        "code": 430211,
                        "areaName": "天元区",
                        "parent_code": 430200,
                        "level": 3
                    },
                    {
                        "code": 430221,
                        "areaName": "株洲县",
                        "parent_code": 430200,
                        "level": 3
                    },
                    {
                        "code": 430223,
                        "areaName": "攸县",
                        "parent_code": 430200,
                        "level": 3
                    },
                    {
                        "code": 430224,
                        "areaName": "茶陵县",
                        "parent_code": 430200,
                        "level": 3
                    },
                    {
                        "code": 430225,
                        "areaName": "炎陵县",
                        "parent_code": 430200,
                        "level": 3
                    },
                    {
                        "code": 430281,
                        "areaName": "醴陵市",
                        "parent_code": 430200,
                        "level": 3
                    },
                    {
                        "code": 430282,
                        "areaName": "株洲市",
                        "parent_code": 430200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 430300,
                "areaName": "湘潭市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 430302,
                        "areaName": "雨湖区",
                        "parent_code": 430300,
                        "level": 3
                    },
                    {
                        "code": 430304,
                        "areaName": "岳塘区",
                        "parent_code": 430300,
                        "level": 3
                    },
                    {
                        "code": 430321,
                        "areaName": "湘潭县",
                        "parent_code": 430300,
                        "level": 3
                    },
                    {
                        "code": 430381,
                        "areaName": "湘乡市",
                        "parent_code": 430300,
                        "level": 3
                    },
                    {
                        "code": 430382,
                        "areaName": "韶山市",
                        "parent_code": 430300,
                        "level": 3
                    },
                    {
                        "code": 430383,
                        "areaName": "湘潭市",
                        "parent_code": 430300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 430400,
                "areaName": "衡阳市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 430405,
                        "areaName": "珠晖区",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430406,
                        "areaName": "雁峰区",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430407,
                        "areaName": "石鼓区",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430408,
                        "areaName": "蒸湘区",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430412,
                        "areaName": "南岳区",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430421,
                        "areaName": "衡阳县",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430422,
                        "areaName": "衡南县",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430423,
                        "areaName": "衡山县",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430424,
                        "areaName": "衡东县",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430426,
                        "areaName": "祁东县",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430481,
                        "areaName": "耒阳市",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430482,
                        "areaName": "常宁市",
                        "parent_code": 430400,
                        "level": 3
                    },
                    {
                        "code": 430483,
                        "areaName": "衡阳市",
                        "parent_code": 430400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 430500,
                "areaName": "邵阳市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 430502,
                        "areaName": "双清区",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430503,
                        "areaName": "大祥区",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430511,
                        "areaName": "北塔区",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430521,
                        "areaName": "邵东县",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430522,
                        "areaName": "新邵县",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430523,
                        "areaName": "邵阳县",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430524,
                        "areaName": "隆回县",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430525,
                        "areaName": "洞口县",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430527,
                        "areaName": "绥宁县",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430528,
                        "areaName": "新宁县",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430529,
                        "areaName": "城步苗族自治县",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430581,
                        "areaName": "武冈市",
                        "parent_code": 430500,
                        "level": 3
                    },
                    {
                        "code": 430582,
                        "areaName": "邵阳市",
                        "parent_code": 430500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 430600,
                "areaName": "岳阳市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 430602,
                        "areaName": "岳阳楼区",
                        "parent_code": 430600,
                        "level": 3
                    },
                    {
                        "code": 430603,
                        "areaName": "云溪区",
                        "parent_code": 430600,
                        "level": 3
                    },
                    {
                        "code": 430611,
                        "areaName": "君山区",
                        "parent_code": 430600,
                        "level": 3
                    },
                    {
                        "code": 430621,
                        "areaName": "岳阳县",
                        "parent_code": 430600,
                        "level": 3
                    },
                    {
                        "code": 430623,
                        "areaName": "华容县",
                        "parent_code": 430600,
                        "level": 3
                    },
                    {
                        "code": 430624,
                        "areaName": "湘阴县",
                        "parent_code": 430600,
                        "level": 3
                    },
                    {
                        "code": 430626,
                        "areaName": "平江县",
                        "parent_code": 430600,
                        "level": 3
                    },
                    {
                        "code": 430681,
                        "areaName": "汨罗市",
                        "parent_code": 430600,
                        "level": 3
                    },
                    {
                        "code": 430682,
                        "areaName": "临湘市",
                        "parent_code": 430600,
                        "level": 3
                    },
                    {
                        "code": 430683,
                        "areaName": "岳阳市",
                        "parent_code": 430600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 430700,
                "areaName": "常德市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 430702,
                        "areaName": "武陵区",
                        "parent_code": 430700,
                        "level": 3
                    },
                    {
                        "code": 430703,
                        "areaName": "鼎城区",
                        "parent_code": 430700,
                        "level": 3
                    },
                    {
                        "code": 430721,
                        "areaName": "安乡县",
                        "parent_code": 430700,
                        "level": 3
                    },
                    {
                        "code": 430722,
                        "areaName": "汉寿县",
                        "parent_code": 430700,
                        "level": 3
                    },
                    {
                        "code": 430723,
                        "areaName": "澧县",
                        "parent_code": 430700,
                        "level": 3
                    },
                    {
                        "code": 430724,
                        "areaName": "临澧县",
                        "parent_code": 430700,
                        "level": 3
                    },
                    {
                        "code": 430725,
                        "areaName": "桃源县",
                        "parent_code": 430700,
                        "level": 3
                    },
                    {
                        "code": 430726,
                        "areaName": "石门县",
                        "parent_code": 430700,
                        "level": 3
                    },
                    {
                        "code": 430781,
                        "areaName": "津市市",
                        "parent_code": 430700,
                        "level": 3
                    },
                    {
                        "code": 430782,
                        "areaName": "常德市",
                        "parent_code": 430700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 430800,
                "areaName": "张家界市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 430802,
                        "areaName": "永定区",
                        "parent_code": 430800,
                        "level": 3
                    },
                    {
                        "code": 430811,
                        "areaName": "武陵源区",
                        "parent_code": 430800,
                        "level": 3
                    },
                    {
                        "code": 430821,
                        "areaName": "慈利县",
                        "parent_code": 430800,
                        "level": 3
                    },
                    {
                        "code": 430822,
                        "areaName": "桑植县",
                        "parent_code": 430800,
                        "level": 3
                    },
                    {
                        "code": 430823,
                        "areaName": "张家界市",
                        "parent_code": 430800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 430900,
                "areaName": "益阳市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 430902,
                        "areaName": "资阳区",
                        "parent_code": 430900,
                        "level": 3
                    },
                    {
                        "code": 430903,
                        "areaName": "赫山区",
                        "parent_code": 430900,
                        "level": 3
                    },
                    {
                        "code": 430921,
                        "areaName": "南县",
                        "parent_code": 430900,
                        "level": 3
                    },
                    {
                        "code": 430922,
                        "areaName": "桃江县",
                        "parent_code": 430900,
                        "level": 3
                    },
                    {
                        "code": 430923,
                        "areaName": "安化县",
                        "parent_code": 430900,
                        "level": 3
                    },
                    {
                        "code": 430981,
                        "areaName": "沅江市",
                        "parent_code": 430900,
                        "level": 3
                    },
                    {
                        "code": 430982,
                        "areaName": "益阳市",
                        "parent_code": 430900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 431000,
                "areaName": "郴州市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 431002,
                        "areaName": "北湖区",
                        "parent_code": 431000,
                        "level": 3
                    },
                    {
                        "code": 431003,
                        "areaName": "苏仙区",
                        "parent_code": 431000,
                        "level": 3
                    },
                    {
                        "code": 431021,
                        "areaName": "桂阳县",
                        "parent_code": 431000,
                        "level": 3
                    },
                    {
                        "code": 431022,
                        "areaName": "宜章县",
                        "parent_code": 431000,
                        "level": 3
                    },
                    {
                        "code": 431023,
                        "areaName": "永兴县",
                        "parent_code": 431000,
                        "level": 3
                    },
                    {
                        "code": 431024,
                        "areaName": "嘉禾县",
                        "parent_code": 431000,
                        "level": 3
                    },
                    {
                        "code": 431025,
                        "areaName": "临武县",
                        "parent_code": 431000,
                        "level": 3
                    },
                    {
                        "code": 431026,
                        "areaName": "汝城县",
                        "parent_code": 431000,
                        "level": 3
                    },
                    {
                        "code": 431027,
                        "areaName": "桂东县",
                        "parent_code": 431000,
                        "level": 3
                    },
                    {
                        "code": 431028,
                        "areaName": "安仁县",
                        "parent_code": 431000,
                        "level": 3
                    },
                    {
                        "code": 431081,
                        "areaName": "资兴市",
                        "parent_code": 431000,
                        "level": 3
                    },
                    {
                        "code": 431082,
                        "areaName": "郴州市",
                        "parent_code": 431000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 431100,
                "areaName": "永州市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 431102,
                        "areaName": "零陵区",
                        "parent_code": 431100,
                        "level": 3
                    },
                    {
                        "code": 431103,
                        "areaName": "冷水滩区",
                        "parent_code": 431100,
                        "level": 3
                    },
                    {
                        "code": 431121,
                        "areaName": "祁阳县",
                        "parent_code": 431100,
                        "level": 3
                    },
                    {
                        "code": 431122,
                        "areaName": "东安县",
                        "parent_code": 431100,
                        "level": 3
                    },
                    {
                        "code": 431123,
                        "areaName": "双牌县",
                        "parent_code": 431100,
                        "level": 3
                    },
                    {
                        "code": 431124,
                        "areaName": "道县",
                        "parent_code": 431100,
                        "level": 3
                    },
                    {
                        "code": 431125,
                        "areaName": "江永县",
                        "parent_code": 431100,
                        "level": 3
                    },
                    {
                        "code": 431126,
                        "areaName": "宁远县",
                        "parent_code": 431100,
                        "level": 3
                    },
                    {
                        "code": 431127,
                        "areaName": "蓝山县",
                        "parent_code": 431100,
                        "level": 3
                    },
                    {
                        "code": 431128,
                        "areaName": "新田县",
                        "parent_code": 431100,
                        "level": 3
                    },
                    {
                        "code": 431129,
                        "areaName": "江华瑶族自治县",
                        "parent_code": 431100,
                        "level": 3
                    },
                    {
                        "code": 431130,
                        "areaName": "永州市",
                        "parent_code": 431100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 431200,
                "areaName": "怀化市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 431202,
                        "areaName": "鹤城区",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431221,
                        "areaName": "中方县",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431222,
                        "areaName": "沅陵县",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431223,
                        "areaName": "辰溪县",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431224,
                        "areaName": "溆浦县",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431225,
                        "areaName": "会同县",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431226,
                        "areaName": "麻阳苗族自治县",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431227,
                        "areaName": "新晃侗族自治县",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431228,
                        "areaName": "芷江侗族自治县",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431229,
                        "areaName": "靖州苗族侗族自治县",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431230,
                        "areaName": "通道侗族自治县",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431281,
                        "areaName": "洪江市",
                        "parent_code": 431200,
                        "level": 3
                    },
                    {
                        "code": 431282,
                        "areaName": "怀化市",
                        "parent_code": 431200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 431300,
                "areaName": "娄底市",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 431302,
                        "areaName": "娄星区",
                        "parent_code": 431300,
                        "level": 3
                    },
                    {
                        "code": 431321,
                        "areaName": "双峰县",
                        "parent_code": 431300,
                        "level": 3
                    },
                    {
                        "code": 431322,
                        "areaName": "新化县",
                        "parent_code": 431300,
                        "level": 3
                    },
                    {
                        "code": 431381,
                        "areaName": "冷水江市",
                        "parent_code": 431300,
                        "level": 3
                    },
                    {
                        "code": 431382,
                        "areaName": "涟源市",
                        "parent_code": 431300,
                        "level": 3
                    },
                    {
                        "code": 431383,
                        "areaName": "娄底市",
                        "parent_code": 431300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 433100,
                "areaName": "湘西土家族苗族自治州",
                "parent_code": 430000,
                "level": 2,
                "children": [
                    {
                        "code": 433101,
                        "areaName": "吉首市",
                        "parent_code": 433100,
                        "level": 3
                    },
                    {
                        "code": 433122,
                        "areaName": "泸溪县",
                        "parent_code": 433100,
                        "level": 3
                    },
                    {
                        "code": 433123,
                        "areaName": "凤凰县",
                        "parent_code": 433100,
                        "level": 3
                    },
                    {
                        "code": 433124,
                        "areaName": "花垣县",
                        "parent_code": 433100,
                        "level": 3
                    },
                    {
                        "code": 433125,
                        "areaName": "保靖县",
                        "parent_code": 433100,
                        "level": 3
                    },
                    {
                        "code": 433126,
                        "areaName": "古丈县",
                        "parent_code": 433100,
                        "level": 3
                    },
                    {
                        "code": 433127,
                        "areaName": "永顺县",
                        "parent_code": 433100,
                        "level": 3
                    },
                    {
                        "code": 433130,
                        "areaName": "龙山县",
                        "parent_code": 433100,
                        "level": 3
                    },
                    {
                        "code": 433131,
                        "areaName": "湘西土家族苗族自治州",
                        "parent_code": 433100,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 440000,
        "areaName": "广东省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 440100,
                "areaName": "广州市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 440103,
                        "areaName": "荔湾区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440104,
                        "areaName": "越秀区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440105,
                        "areaName": "海珠区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440106,
                        "areaName": "天河区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440111,
                        "areaName": "白云区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440112,
                        "areaName": "黄埔区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440113,
                        "areaName": "番禺区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440114,
                        "areaName": "花都区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440115,
                        "areaName": "南沙区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440116,
                        "areaName": "萝岗区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440183,
                        "areaName": "增城区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440184,
                        "areaName": "从化区",
                        "parent_code": 440100,
                        "level": 3
                    },
                    {
                        "code": 440185,
                        "areaName": "广州市",
                        "parent_code": 440100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 440200,
                "areaName": "韶关市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 440203,
                        "areaName": "武江区",
                        "parent_code": 440200,
                        "level": 3
                    },
                    {
                        "code": 440204,
                        "areaName": "浈江区",
                        "parent_code": 440200,
                        "level": 3
                    },
                    {
                        "code": 440205,
                        "areaName": "曲江区",
                        "parent_code": 440200,
                        "level": 3
                    },
                    {
                        "code": 440222,
                        "areaName": "始兴县",
                        "parent_code": 440200,
                        "level": 3
                    },
                    {
                        "code": 440224,
                        "areaName": "仁化县",
                        "parent_code": 440200,
                        "level": 3
                    },
                    {
                        "code": 440229,
                        "areaName": "翁源县",
                        "parent_code": 440200,
                        "level": 3
                    },
                    {
                        "code": 440232,
                        "areaName": "乳源瑶族自治县",
                        "parent_code": 440200,
                        "level": 3
                    },
                    {
                        "code": 440233,
                        "areaName": "新丰县",
                        "parent_code": 440200,
                        "level": 3
                    },
                    {
                        "code": 440281,
                        "areaName": "乐昌市",
                        "parent_code": 440200,
                        "level": 3
                    },
                    {
                        "code": 440282,
                        "areaName": "南雄市",
                        "parent_code": 440200,
                        "level": 3
                    },
                    {
                        "code": 440283,
                        "areaName": "韶关市",
                        "parent_code": 440200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 440300,
                "areaName": "深圳市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 440303,
                        "areaName": "罗湖区",
                        "parent_code": 440300,
                        "level": 3
                    },
                    {
                        "code": 440304,
                        "areaName": "福田区",
                        "parent_code": 440300,
                        "level": 3
                    },
                    {
                        "code": 440305,
                        "areaName": "南山区",
                        "parent_code": 440300,
                        "level": 3
                    },
                    {
                        "code": 440306,
                        "areaName": "宝安区",
                        "parent_code": 440300,
                        "level": 3
                    },
                    {
                        "code": 440307,
                        "areaName": "龙岗区",
                        "parent_code": 440300,
                        "level": 3
                    },
                    {
                        "code": 440308,
                        "areaName": "盐田区",
                        "parent_code": 440300,
                        "level": 3
                    },
                    {
                        "code": 440309,
                        "areaName": "深圳市",
                        "parent_code": 440300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 440400,
                "areaName": "珠海市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 440402,
                        "areaName": "香洲区",
                        "parent_code": 440400,
                        "level": 3
                    },
                    {
                        "code": 440403,
                        "areaName": "斗门区",
                        "parent_code": 440400,
                        "level": 3
                    },
                    {
                        "code": 440404,
                        "areaName": "金湾区",
                        "parent_code": 440400,
                        "level": 3
                    },
                    {
                        "code": 440405,
                        "areaName": "珠海市",
                        "parent_code": 440400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 440500,
                "areaName": "汕头市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 440507,
                        "areaName": "龙湖区",
                        "parent_code": 440500,
                        "level": 3
                    },
                    {
                        "code": 440511,
                        "areaName": "金平区",
                        "parent_code": 440500,
                        "level": 3
                    },
                    {
                        "code": 440512,
                        "areaName": "濠江区",
                        "parent_code": 440500,
                        "level": 3
                    },
                    {
                        "code": 440513,
                        "areaName": "潮阳区",
                        "parent_code": 440500,
                        "level": 3
                    },
                    {
                        "code": 440514,
                        "areaName": "潮南区",
                        "parent_code": 440500,
                        "level": 3
                    },
                    {
                        "code": 440515,
                        "areaName": "澄海区",
                        "parent_code": 440500,
                        "level": 3
                    },
                    {
                        "code": 440523,
                        "areaName": "南澳县",
                        "parent_code": 440500,
                        "level": 3
                    },
                    {
                        "code": 440524,
                        "areaName": "汕头市",
                        "parent_code": 440500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 440600,
                "areaName": "佛山市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 440604,
                        "areaName": "禅城区",
                        "parent_code": 440600,
                        "level": 3
                    },
                    {
                        "code": 440605,
                        "areaName": "南海区",
                        "parent_code": 440600,
                        "level": 3
                    },
                    {
                        "code": 440606,
                        "areaName": "顺德区",
                        "parent_code": 440600,
                        "level": 3
                    },
                    {
                        "code": 440607,
                        "areaName": "三水区",
                        "parent_code": 440600,
                        "level": 3
                    },
                    {
                        "code": 440608,
                        "areaName": "高明区",
                        "parent_code": 440600,
                        "level": 3
                    },
                    {
                        "code": 440609,
                        "areaName": "佛山市",
                        "parent_code": 440600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 440700,
                "areaName": "江门市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 440703,
                        "areaName": "蓬江区",
                        "parent_code": 440700,
                        "level": 3
                    },
                    {
                        "code": 440704,
                        "areaName": "江海区",
                        "parent_code": 440700,
                        "level": 3
                    },
                    {
                        "code": 440705,
                        "areaName": "新会区",
                        "parent_code": 440700,
                        "level": 3
                    },
                    {
                        "code": 440781,
                        "areaName": "台山市",
                        "parent_code": 440700,
                        "level": 3
                    },
                    {
                        "code": 440783,
                        "areaName": "开平市",
                        "parent_code": 440700,
                        "level": 3
                    },
                    {
                        "code": 440784,
                        "areaName": "鹤山市",
                        "parent_code": 440700,
                        "level": 3
                    },
                    {
                        "code": 440785,
                        "areaName": "恩平市",
                        "parent_code": 440700,
                        "level": 3
                    },
                    {
                        "code": 440786,
                        "areaName": "江门市",
                        "parent_code": 440700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 440800,
                "areaName": "湛江市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 440802,
                        "areaName": "赤坎区",
                        "parent_code": 440800,
                        "level": 3
                    },
                    {
                        "code": 440803,
                        "areaName": "霞山区",
                        "parent_code": 440800,
                        "level": 3
                    },
                    {
                        "code": 440804,
                        "areaName": "坡头区",
                        "parent_code": 440800,
                        "level": 3
                    },
                    {
                        "code": 440811,
                        "areaName": "麻章区",
                        "parent_code": 440800,
                        "level": 3
                    },
                    {
                        "code": 440823,
                        "areaName": "遂溪县",
                        "parent_code": 440800,
                        "level": 3
                    },
                    {
                        "code": 440825,
                        "areaName": "徐闻县",
                        "parent_code": 440800,
                        "level": 3
                    },
                    {
                        "code": 440881,
                        "areaName": "廉江市",
                        "parent_code": 440800,
                        "level": 3
                    },
                    {
                        "code": 440882,
                        "areaName": "雷州市",
                        "parent_code": 440800,
                        "level": 3
                    },
                    {
                        "code": 440883,
                        "areaName": "吴川市",
                        "parent_code": 440800,
                        "level": 3
                    },
                    {
                        "code": 440884,
                        "areaName": "湛江市",
                        "parent_code": 440800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 440900,
                "areaName": "茂名市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 440902,
                        "areaName": "茂南区",
                        "parent_code": 440900,
                        "level": 3
                    },
                    {
                        "code": 440903,
                        "areaName": "茂港区",
                        "parent_code": 440900,
                        "level": 3
                    },
                    {
                        "code": 440923,
                        "areaName": "电白区",
                        "parent_code": 440900,
                        "level": 3
                    },
                    {
                        "code": 440981,
                        "areaName": "高州市",
                        "parent_code": 440900,
                        "level": 3
                    },
                    {
                        "code": 440982,
                        "areaName": "化州市",
                        "parent_code": 440900,
                        "level": 3
                    },
                    {
                        "code": 440983,
                        "areaName": "信宜市",
                        "parent_code": 440900,
                        "level": 3
                    },
                    {
                        "code": 440984,
                        "areaName": "茂名市",
                        "parent_code": 440900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 441200,
                "areaName": "肇庆市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 441202,
                        "areaName": "端州区",
                        "parent_code": 441200,
                        "level": 3
                    },
                    {
                        "code": 441203,
                        "areaName": "鼎湖区",
                        "parent_code": 441200,
                        "level": 3
                    },
                    {
                        "code": 441223,
                        "areaName": "广宁县",
                        "parent_code": 441200,
                        "level": 3
                    },
                    {
                        "code": 441224,
                        "areaName": "怀集县",
                        "parent_code": 441200,
                        "level": 3
                    },
                    {
                        "code": 441225,
                        "areaName": "封开县",
                        "parent_code": 441200,
                        "level": 3
                    },
                    {
                        "code": 441226,
                        "areaName": "德庆县",
                        "parent_code": 441200,
                        "level": 3
                    },
                    {
                        "code": 441283,
                        "areaName": "高要市",
                        "parent_code": 441200,
                        "level": 3
                    },
                    {
                        "code": 441284,
                        "areaName": "四会市",
                        "parent_code": 441200,
                        "level": 3
                    },
                    {
                        "code": 441285,
                        "areaName": "肇庆市",
                        "parent_code": 441200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 441300,
                "areaName": "惠州市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 441302,
                        "areaName": "惠城区",
                        "parent_code": 441300,
                        "level": 3
                    },
                    {
                        "code": 441303,
                        "areaName": "惠阳区",
                        "parent_code": 441300,
                        "level": 3
                    },
                    {
                        "code": 441322,
                        "areaName": "博罗县",
                        "parent_code": 441300,
                        "level": 3
                    },
                    {
                        "code": 441323,
                        "areaName": "惠东县",
                        "parent_code": 441300,
                        "level": 3
                    },
                    {
                        "code": 441324,
                        "areaName": "龙门县",
                        "parent_code": 441300,
                        "level": 3
                    },
                    {
                        "code": 441325,
                        "areaName": "惠州市",
                        "parent_code": 441300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 441400,
                "areaName": "梅州市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 441402,
                        "areaName": "梅江区",
                        "parent_code": 441400,
                        "level": 3
                    },
                    {
                        "code": 441421,
                        "areaName": "梅县区",
                        "parent_code": 441400,
                        "level": 3
                    },
                    {
                        "code": 441422,
                        "areaName": "大埔县",
                        "parent_code": 441400,
                        "level": 3
                    },
                    {
                        "code": 441423,
                        "areaName": "丰顺县",
                        "parent_code": 441400,
                        "level": 3
                    },
                    {
                        "code": 441424,
                        "areaName": "五华县",
                        "parent_code": 441400,
                        "level": 3
                    },
                    {
                        "code": 441426,
                        "areaName": "平远县",
                        "parent_code": 441400,
                        "level": 3
                    },
                    {
                        "code": 441427,
                        "areaName": "蕉岭县",
                        "parent_code": 441400,
                        "level": 3
                    },
                    {
                        "code": 441481,
                        "areaName": "兴宁市",
                        "parent_code": 441400,
                        "level": 3
                    },
                    {
                        "code": 441482,
                        "areaName": "梅州市",
                        "parent_code": 441400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 441500,
                "areaName": "汕尾市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 441502,
                        "areaName": "城区",
                        "parent_code": 441500,
                        "level": 3
                    },
                    {
                        "code": 441521,
                        "areaName": "海丰县",
                        "parent_code": 441500,
                        "level": 3
                    },
                    {
                        "code": 441523,
                        "areaName": "陆河县",
                        "parent_code": 441500,
                        "level": 3
                    },
                    {
                        "code": 441581,
                        "areaName": "陆丰市",
                        "parent_code": 441500,
                        "level": 3
                    },
                    {
                        "code": 441582,
                        "areaName": "汕尾市",
                        "parent_code": 441500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 441600,
                "areaName": "河源市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 441602,
                        "areaName": "源城区",
                        "parent_code": 441600,
                        "level": 3
                    },
                    {
                        "code": 441621,
                        "areaName": "紫金县",
                        "parent_code": 441600,
                        "level": 3
                    },
                    {
                        "code": 441622,
                        "areaName": "龙川县",
                        "parent_code": 441600,
                        "level": 3
                    },
                    {
                        "code": 441623,
                        "areaName": "连平县",
                        "parent_code": 441600,
                        "level": 3
                    },
                    {
                        "code": 441624,
                        "areaName": "和平县",
                        "parent_code": 441600,
                        "level": 3
                    },
                    {
                        "code": 441625,
                        "areaName": "东源县",
                        "parent_code": 441600,
                        "level": 3
                    },
                    {
                        "code": 441626,
                        "areaName": "河源市",
                        "parent_code": 441600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 441700,
                "areaName": "阳江市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 441702,
                        "areaName": "江城区",
                        "parent_code": 441700,
                        "level": 3
                    },
                    {
                        "code": 441721,
                        "areaName": "阳西县",
                        "parent_code": 441700,
                        "level": 3
                    },
                    {
                        "code": 441723,
                        "areaName": "阳东区",
                        "parent_code": 441700,
                        "level": 3
                    },
                    {
                        "code": 441781,
                        "areaName": "阳春市",
                        "parent_code": 441700,
                        "level": 3
                    },
                    {
                        "code": 441782,
                        "areaName": "阳江市",
                        "parent_code": 441700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 441800,
                "areaName": "清远市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 441802,
                        "areaName": "清城区",
                        "parent_code": 441800,
                        "level": 3
                    },
                    {
                        "code": 441803,
                        "areaName": "清新区",
                        "parent_code": 441800,
                        "level": 3
                    },
                    {
                        "code": 441821,
                        "areaName": "佛冈县",
                        "parent_code": 441800,
                        "level": 3
                    },
                    {
                        "code": 441823,
                        "areaName": "阳山县",
                        "parent_code": 441800,
                        "level": 3
                    },
                    {
                        "code": 441825,
                        "areaName": "连山壮族瑶族自治县",
                        "parent_code": 441800,
                        "level": 3
                    },
                    {
                        "code": 441826,
                        "areaName": "连南瑶族自治县",
                        "parent_code": 441800,
                        "level": 3
                    },
                    {
                        "code": 441881,
                        "areaName": "英德市",
                        "parent_code": 441800,
                        "level": 3
                    },
                    {
                        "code": 441882,
                        "areaName": "连州市",
                        "parent_code": 441800,
                        "level": 3
                    },
                    {
                        "code": 441883,
                        "areaName": "清远市",
                        "parent_code": 441800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 441900,
                "areaName": "东莞市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 441901,
                        "areaName": "东莞市",
                        "parent_code": 441900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 442000,
                "areaName": "中山市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 442001,
                        "areaName": "中山市",
                        "parent_code": 442000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 445100,
                "areaName": "潮州市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 445102,
                        "areaName": "湘桥区",
                        "parent_code": 445100,
                        "level": 3
                    },
                    {
                        "code": 445103,
                        "areaName": "潮安区",
                        "parent_code": 445100,
                        "level": 3
                    },
                    {
                        "code": 445122,
                        "areaName": "饶平县",
                        "parent_code": 445100,
                        "level": 3
                    },
                    {
                        "code": 445123,
                        "areaName": "潮州市",
                        "parent_code": 445100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 445200,
                "areaName": "揭阳市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 445202,
                        "areaName": "榕城区",
                        "parent_code": 445200,
                        "level": 3
                    },
                    {
                        "code": 445203,
                        "areaName": "揭东区",
                        "parent_code": 445200,
                        "level": 3
                    },
                    {
                        "code": 445222,
                        "areaName": "揭西县",
                        "parent_code": 445200,
                        "level": 3
                    },
                    {
                        "code": 445224,
                        "areaName": "惠来县",
                        "parent_code": 445200,
                        "level": 3
                    },
                    {
                        "code": 445281,
                        "areaName": "普宁市",
                        "parent_code": 445200,
                        "level": 3
                    },
                    {
                        "code": 445282,
                        "areaName": "揭阳市",
                        "parent_code": 445200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 445300,
                "areaName": "云浮市",
                "parent_code": 440000,
                "level": 2,
                "children": [
                    {
                        "code": 445302,
                        "areaName": "云城区",
                        "parent_code": 445300,
                        "level": 3
                    },
                    {
                        "code": 445321,
                        "areaName": "新兴县",
                        "parent_code": 445300,
                        "level": 3
                    },
                    {
                        "code": 445322,
                        "areaName": "郁南县",
                        "parent_code": 445300,
                        "level": 3
                    },
                    {
                        "code": 445323,
                        "areaName": "云安区",
                        "parent_code": 445300,
                        "level": 3
                    },
                    {
                        "code": 445381,
                        "areaName": "罗定市",
                        "parent_code": 445300,
                        "level": 3
                    },
                    {
                        "code": 445382,
                        "areaName": "云浮市",
                        "parent_code": 445300,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 450000,
        "areaName": "广西壮族自治区",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 450100,
                "areaName": "南宁市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 450102,
                        "areaName": "兴宁区",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450103,
                        "areaName": "青秀区",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450105,
                        "areaName": "江南区",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450107,
                        "areaName": "西乡塘区",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450108,
                        "areaName": "良庆区",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450109,
                        "areaName": "邕宁区",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450122,
                        "areaName": "武鸣县",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450123,
                        "areaName": "隆安县",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450124,
                        "areaName": "马山县",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450125,
                        "areaName": "上林县",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450126,
                        "areaName": "宾阳县",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450127,
                        "areaName": "横县",
                        "parent_code": 450100,
                        "level": 3
                    },
                    {
                        "code": 450128,
                        "areaName": "南宁市",
                        "parent_code": 450100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 450200,
                "areaName": "柳州市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 450202,
                        "areaName": "城中区",
                        "parent_code": 450200,
                        "level": 3
                    },
                    {
                        "code": 450203,
                        "areaName": "鱼峰区",
                        "parent_code": 450200,
                        "level": 3
                    },
                    {
                        "code": 450204,
                        "areaName": "柳南区",
                        "parent_code": 450200,
                        "level": 3
                    },
                    {
                        "code": 450205,
                        "areaName": "柳北区",
                        "parent_code": 450200,
                        "level": 3
                    },
                    {
                        "code": 450221,
                        "areaName": "柳江县",
                        "parent_code": 450200,
                        "level": 3
                    },
                    {
                        "code": 450222,
                        "areaName": "柳城县",
                        "parent_code": 450200,
                        "level": 3
                    },
                    {
                        "code": 450223,
                        "areaName": "鹿寨县",
                        "parent_code": 450200,
                        "level": 3
                    },
                    {
                        "code": 450224,
                        "areaName": "融安县",
                        "parent_code": 450200,
                        "level": 3
                    },
                    {
                        "code": 450225,
                        "areaName": "融水苗族自治县",
                        "parent_code": 450200,
                        "level": 3
                    },
                    {
                        "code": 450226,
                        "areaName": "三江侗族自治县",
                        "parent_code": 450200,
                        "level": 3
                    },
                    {
                        "code": 450227,
                        "areaName": "柳州市",
                        "parent_code": 450200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 450300,
                "areaName": "桂林市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 450302,
                        "areaName": "秀峰区",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450303,
                        "areaName": "叠彩区",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450304,
                        "areaName": "象山区",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450305,
                        "areaName": "七星区",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450311,
                        "areaName": "雁山区",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450312,
                        "areaName": "临桂区",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450321,
                        "areaName": "阳朔县",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450323,
                        "areaName": "灵川县",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450324,
                        "areaName": "全州县",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450325,
                        "areaName": "兴安县",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450326,
                        "areaName": "永福县",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450327,
                        "areaName": "灌阳县",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450328,
                        "areaName": "龙胜各族自治县",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450329,
                        "areaName": "资源县",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450330,
                        "areaName": "平乐县",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450331,
                        "areaName": "荔浦县",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450332,
                        "areaName": "恭城瑶族自治县",
                        "parent_code": 450300,
                        "level": 3
                    },
                    {
                        "code": 450333,
                        "areaName": "桂林市",
                        "parent_code": 450300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 450400,
                "areaName": "梧州市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 450403,
                        "areaName": "万秀区",
                        "parent_code": 450400,
                        "level": 3
                    },
                    {
                        "code": 450405,
                        "areaName": "长洲区",
                        "parent_code": 450400,
                        "level": 3
                    },
                    {
                        "code": 450406,
                        "areaName": "龙圩区",
                        "parent_code": 450400,
                        "level": 3
                    },
                    {
                        "code": 450421,
                        "areaName": "苍梧县",
                        "parent_code": 450400,
                        "level": 3
                    },
                    {
                        "code": 450422,
                        "areaName": "藤县",
                        "parent_code": 450400,
                        "level": 3
                    },
                    {
                        "code": 450423,
                        "areaName": "蒙山县",
                        "parent_code": 450400,
                        "level": 3
                    },
                    {
                        "code": 450481,
                        "areaName": "岑溪市",
                        "parent_code": 450400,
                        "level": 3
                    },
                    {
                        "code": 450482,
                        "areaName": "梧州市",
                        "parent_code": 450400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 450500,
                "areaName": "北海市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 450502,
                        "areaName": "海城区",
                        "parent_code": 450500,
                        "level": 3
                    },
                    {
                        "code": 450503,
                        "areaName": "银海区",
                        "parent_code": 450500,
                        "level": 3
                    },
                    {
                        "code": 450512,
                        "areaName": "铁山港区",
                        "parent_code": 450500,
                        "level": 3
                    },
                    {
                        "code": 450521,
                        "areaName": "合浦县",
                        "parent_code": 450500,
                        "level": 3
                    },
                    {
                        "code": 450522,
                        "areaName": "北海市",
                        "parent_code": 450500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 450600,
                "areaName": "防城港市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 450602,
                        "areaName": "港口区",
                        "parent_code": 450600,
                        "level": 3
                    },
                    {
                        "code": 450603,
                        "areaName": "防城区",
                        "parent_code": 450600,
                        "level": 3
                    },
                    {
                        "code": 450621,
                        "areaName": "上思县",
                        "parent_code": 450600,
                        "level": 3
                    },
                    {
                        "code": 450681,
                        "areaName": "东兴市",
                        "parent_code": 450600,
                        "level": 3
                    },
                    {
                        "code": 450682,
                        "areaName": "防城港市",
                        "parent_code": 450600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 450700,
                "areaName": "钦州市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 450702,
                        "areaName": "钦南区",
                        "parent_code": 450700,
                        "level": 3
                    },
                    {
                        "code": 450703,
                        "areaName": "钦北区",
                        "parent_code": 450700,
                        "level": 3
                    },
                    {
                        "code": 450721,
                        "areaName": "灵山县",
                        "parent_code": 450700,
                        "level": 3
                    },
                    {
                        "code": 450722,
                        "areaName": "浦北县",
                        "parent_code": 450700,
                        "level": 3
                    },
                    {
                        "code": 450723,
                        "areaName": "钦州市",
                        "parent_code": 450700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 450800,
                "areaName": "贵港市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 450802,
                        "areaName": "港北区",
                        "parent_code": 450800,
                        "level": 3
                    },
                    {
                        "code": 450803,
                        "areaName": "港南区",
                        "parent_code": 450800,
                        "level": 3
                    },
                    {
                        "code": 450804,
                        "areaName": "覃塘区",
                        "parent_code": 450800,
                        "level": 3
                    },
                    {
                        "code": 450821,
                        "areaName": "平南县",
                        "parent_code": 450800,
                        "level": 3
                    },
                    {
                        "code": 450881,
                        "areaName": "桂平市",
                        "parent_code": 450800,
                        "level": 3
                    },
                    {
                        "code": 450882,
                        "areaName": "贵港市",
                        "parent_code": 450800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 450900,
                "areaName": "玉林市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 450902,
                        "areaName": "玉州区",
                        "parent_code": 450900,
                        "level": 3
                    },
                    {
                        "code": 450903,
                        "areaName": "福绵区",
                        "parent_code": 450900,
                        "level": 3
                    },
                    {
                        "code": 450921,
                        "areaName": "容县",
                        "parent_code": 450900,
                        "level": 3
                    },
                    {
                        "code": 450922,
                        "areaName": "陆川县",
                        "parent_code": 450900,
                        "level": 3
                    },
                    {
                        "code": 450923,
                        "areaName": "博白县",
                        "parent_code": 450900,
                        "level": 3
                    },
                    {
                        "code": 450924,
                        "areaName": "兴业县",
                        "parent_code": 450900,
                        "level": 3
                    },
                    {
                        "code": 450981,
                        "areaName": "北流市",
                        "parent_code": 450900,
                        "level": 3
                    },
                    {
                        "code": 450982,
                        "areaName": "玉林市",
                        "parent_code": 450900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 451000,
                "areaName": "百色市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 451002,
                        "areaName": "右江区",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451021,
                        "areaName": "田阳县",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451022,
                        "areaName": "田东县",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451023,
                        "areaName": "平果县",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451024,
                        "areaName": "德保县",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451025,
                        "areaName": "靖西县",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451026,
                        "areaName": "那坡县",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451027,
                        "areaName": "凌云县",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451028,
                        "areaName": "乐业县",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451029,
                        "areaName": "田林县",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451030,
                        "areaName": "西林县",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451031,
                        "areaName": "隆林各族自治县",
                        "parent_code": 451000,
                        "level": 3
                    },
                    {
                        "code": 451032,
                        "areaName": "百色市",
                        "parent_code": 451000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 451100,
                "areaName": "贺州市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 451102,
                        "areaName": "八步区",
                        "parent_code": 451100,
                        "level": 3
                    },
                    {
                        "code": 451121,
                        "areaName": "昭平县",
                        "parent_code": 451100,
                        "level": 3
                    },
                    {
                        "code": 451122,
                        "areaName": "钟山县",
                        "parent_code": 451100,
                        "level": 3
                    },
                    {
                        "code": 451123,
                        "areaName": "富川瑶族自治县",
                        "parent_code": 451100,
                        "level": 3
                    },
                    {
                        "code": 451124,
                        "areaName": "贺州市",
                        "parent_code": 451100,
                        "level": 3
                    },
                    {
                        "code": 451103,
                        "areaName": "平桂区",
                        "parent_code": 451100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 451200,
                "areaName": "河池市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 451202,
                        "areaName": "金城江区",
                        "parent_code": 451200,
                        "level": 3
                    },
                    {
                        "code": 451221,
                        "areaName": "南丹县",
                        "parent_code": 451200,
                        "level": 3
                    },
                    {
                        "code": 451222,
                        "areaName": "天峨县",
                        "parent_code": 451200,
                        "level": 3
                    },
                    {
                        "code": 451223,
                        "areaName": "凤山县",
                        "parent_code": 451200,
                        "level": 3
                    },
                    {
                        "code": 451224,
                        "areaName": "东兰县",
                        "parent_code": 451200,
                        "level": 3
                    },
                    {
                        "code": 451225,
                        "areaName": "罗城仫佬族自治县",
                        "parent_code": 451200,
                        "level": 3
                    },
                    {
                        "code": 451226,
                        "areaName": "环江毛南族自治县",
                        "parent_code": 451200,
                        "level": 3
                    },
                    {
                        "code": 451227,
                        "areaName": "巴马瑶族自治县",
                        "parent_code": 451200,
                        "level": 3
                    },
                    {
                        "code": 451228,
                        "areaName": "都安瑶族自治县",
                        "parent_code": 451200,
                        "level": 3
                    },
                    {
                        "code": 451229,
                        "areaName": "大化瑶族自治县",
                        "parent_code": 451200,
                        "level": 3
                    },
                    {
                        "code": 451281,
                        "areaName": "宜州市",
                        "parent_code": 451200,
                        "level": 3
                    },
                    {
                        "code": 451282,
                        "areaName": "河池市",
                        "parent_code": 451200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 451300,
                "areaName": "来宾市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 451302,
                        "areaName": "兴宾区",
                        "parent_code": 451300,
                        "level": 3
                    },
                    {
                        "code": 451321,
                        "areaName": "忻城县",
                        "parent_code": 451300,
                        "level": 3
                    },
                    {
                        "code": 451322,
                        "areaName": "象州县",
                        "parent_code": 451300,
                        "level": 3
                    },
                    {
                        "code": 451323,
                        "areaName": "武宣县",
                        "parent_code": 451300,
                        "level": 3
                    },
                    {
                        "code": 451324,
                        "areaName": "金秀瑶族自治县",
                        "parent_code": 451300,
                        "level": 3
                    },
                    {
                        "code": 451381,
                        "areaName": "合山市",
                        "parent_code": 451300,
                        "level": 3
                    },
                    {
                        "code": 451382,
                        "areaName": "来宾市",
                        "parent_code": 451300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 451400,
                "areaName": "崇左市",
                "parent_code": 450000,
                "level": 2,
                "children": [
                    {
                        "code": 451402,
                        "areaName": "江州区",
                        "parent_code": 451400,
                        "level": 3
                    },
                    {
                        "code": 451421,
                        "areaName": "扶绥县",
                        "parent_code": 451400,
                        "level": 3
                    },
                    {
                        "code": 451422,
                        "areaName": "宁明县",
                        "parent_code": 451400,
                        "level": 3
                    },
                    {
                        "code": 451423,
                        "areaName": "龙州县",
                        "parent_code": 451400,
                        "level": 3
                    },
                    {
                        "code": 451424,
                        "areaName": "大新县",
                        "parent_code": 451400,
                        "level": 3
                    },
                    {
                        "code": 451425,
                        "areaName": "天等县",
                        "parent_code": 451400,
                        "level": 3
                    },
                    {
                        "code": 451481,
                        "areaName": "凭祥市",
                        "parent_code": 451400,
                        "level": 3
                    },
                    {
                        "code": 451482,
                        "areaName": "崇左市",
                        "parent_code": 451400,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 460000,
        "areaName": "海南省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 460100,
                "areaName": "海口市",
                "parent_code": 460000,
                "level": 2,
                "children": [
                    {
                        "code": 460105,
                        "areaName": "秀英区",
                        "parent_code": 460100,
                        "level": 3
                    },
                    {
                        "code": 460106,
                        "areaName": "龙华区",
                        "parent_code": 460100,
                        "level": 3
                    },
                    {
                        "code": 460107,
                        "areaName": "琼山区",
                        "parent_code": 460100,
                        "level": 3
                    },
                    {
                        "code": 460108,
                        "areaName": "美兰区",
                        "parent_code": 460100,
                        "level": 3
                    },
                    {
                        "code": 460109,
                        "areaName": "海口市",
                        "parent_code": 460100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 460200,
                "areaName": "三亚市",
                "parent_code": 460000,
                "level": 2,
                "children": [
                    {
                        "code": 460201,
                        "areaName": "三亚市",
                        "parent_code": 460200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 460300,
                "areaName": "三沙市",
                "parent_code": 460000,
                "level": 2,
                "children": [
                    {
                        "code": 460321,
                        "areaName": "西沙群岛",
                        "parent_code": 460300,
                        "level": 3
                    },
                    {
                        "code": 460322,
                        "areaName": "南沙群岛",
                        "parent_code": 460300,
                        "level": 3
                    },
                    {
                        "code": 460323,
                        "areaName": "中沙群岛的岛礁及其海域",
                        "parent_code": 460300,
                        "level": 3
                    },
                    {
                        "code": 460324,
                        "areaName": "三沙市",
                        "parent_code": 460300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 469000,
                "areaName": "省直辖县级行政区划",
                "parent_code": 460000,
                "level": 2,
                "children": [
                    {
                        "code": 469001,
                        "areaName": "五指山市",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469002,
                        "areaName": "琼海市",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469003,
                        "areaName": "儋州市",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469005,
                        "areaName": "文昌市",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469006,
                        "areaName": "万宁市",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469007,
                        "areaName": "东方市",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469021,
                        "areaName": "定安县",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469022,
                        "areaName": "屯昌县",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469023,
                        "areaName": "澄迈县",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469024,
                        "areaName": "临高县",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469025,
                        "areaName": "白沙黎族自治县",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469026,
                        "areaName": "昌江黎族自治县",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469027,
                        "areaName": "乐东黎族自治县",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469028,
                        "areaName": "陵水黎族自治县",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469029,
                        "areaName": "保亭黎族苗族自治县",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469030,
                        "areaName": "琼中黎族苗族自治县",
                        "parent_code": 469000,
                        "level": 3
                    },
                    {
                        "code": 469031,
                        "areaName": "省直辖县级行政区划",
                        "parent_code": 469000,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 500000,
        "areaName": "重庆市",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 500100,
                "areaName": "市辖区",
                "parent_code": 500000,
                "level": 2,
                "children": [
                    {
                        "code": 500101,
                        "areaName": "万州区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500102,
                        "areaName": "涪陵区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500103,
                        "areaName": "渝中区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500104,
                        "areaName": "大渡口区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500105,
                        "areaName": "江北区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500106,
                        "areaName": "沙坪坝区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500107,
                        "areaName": "九龙坡区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500108,
                        "areaName": "南岸区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500109,
                        "areaName": "北碚区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500110,
                        "areaName": "綦江区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500111,
                        "areaName": "大足区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500112,
                        "areaName": "渝北区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500113,
                        "areaName": "巴南区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500114,
                        "areaName": "黔江区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500115,
                        "areaName": "长寿区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500116,
                        "areaName": "江津区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500117,
                        "areaName": "合川区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500118,
                        "areaName": "永川区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500119,
                        "areaName": "南川区",
                        "parent_code": 500100,
                        "level": 3
                    },
                    {
                        "code": 500120,
                        "areaName": "市辖区",
                        "parent_code": 500100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 500200,
                "areaName": "县",
                "parent_code": 500000,
                "level": 2,
                "children": [
                    {
                        "code": 500223,
                        "areaName": "潼南县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500224,
                        "areaName": "铜梁区",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500226,
                        "areaName": "荣昌县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500227,
                        "areaName": "璧山区",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500228,
                        "areaName": "梁平县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500229,
                        "areaName": "城口县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500230,
                        "areaName": "丰都县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500231,
                        "areaName": "垫江县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500232,
                        "areaName": "武隆县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500233,
                        "areaName": "忠县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500234,
                        "areaName": "开县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500235,
                        "areaName": "云阳县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500236,
                        "areaName": "奉节县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500237,
                        "areaName": "巫山县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500238,
                        "areaName": "巫溪县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500240,
                        "areaName": "石柱土家族自治县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500241,
                        "areaName": "秀山土家族苗族自治县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500242,
                        "areaName": "酉阳土家族苗族自治县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500243,
                        "areaName": "彭水苗族土家族自治县",
                        "parent_code": 500200,
                        "level": 3
                    },
                    {
                        "code": 500244,
                        "areaName": "县",
                        "parent_code": 500200,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 510000,
        "areaName": "四川省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 510100,
                "areaName": "成都市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 510104,
                        "areaName": "锦江区",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510105,
                        "areaName": "青羊区",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510106,
                        "areaName": "金牛区",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510107,
                        "areaName": "武侯区",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510108,
                        "areaName": "成华区",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510112,
                        "areaName": "龙泉驿区",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510113,
                        "areaName": "青白江区",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510114,
                        "areaName": "新都区",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510115,
                        "areaName": "温江区",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510121,
                        "areaName": "金堂县",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510122,
                        "areaName": "双流县",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510124,
                        "areaName": "郫县",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510129,
                        "areaName": "大邑县",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510131,
                        "areaName": "蒲江县",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510132,
                        "areaName": "新津县",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510181,
                        "areaName": "都江堰市",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510182,
                        "areaName": "彭州市",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510183,
                        "areaName": "邛崃市",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510184,
                        "areaName": "崇州市",
                        "parent_code": 510100,
                        "level": 3
                    },
                    {
                        "code": 510185,
                        "areaName": "成都市",
                        "parent_code": 510100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 510300,
                "areaName": "自贡市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 510302,
                        "areaName": "自流井区",
                        "parent_code": 510300,
                        "level": 3
                    },
                    {
                        "code": 510303,
                        "areaName": "贡井区",
                        "parent_code": 510300,
                        "level": 3
                    },
                    {
                        "code": 510304,
                        "areaName": "大安区",
                        "parent_code": 510300,
                        "level": 3
                    },
                    {
                        "code": 510311,
                        "areaName": "沿滩区",
                        "parent_code": 510300,
                        "level": 3
                    },
                    {
                        "code": 510321,
                        "areaName": "荣县",
                        "parent_code": 510300,
                        "level": 3
                    },
                    {
                        "code": 510322,
                        "areaName": "富顺县",
                        "parent_code": 510300,
                        "level": 3
                    },
                    {
                        "code": 510323,
                        "areaName": "自贡市",
                        "parent_code": 510300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 510400,
                "areaName": "攀枝花市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 510402,
                        "areaName": "东区",
                        "parent_code": 510400,
                        "level": 3
                    },
                    {
                        "code": 510403,
                        "areaName": "西区",
                        "parent_code": 510400,
                        "level": 3
                    },
                    {
                        "code": 510411,
                        "areaName": "仁和区",
                        "parent_code": 510400,
                        "level": 3
                    },
                    {
                        "code": 510421,
                        "areaName": "米易县",
                        "parent_code": 510400,
                        "level": 3
                    },
                    {
                        "code": 510422,
                        "areaName": "盐边县",
                        "parent_code": 510400,
                        "level": 3
                    },
                    {
                        "code": 510423,
                        "areaName": "攀枝花市",
                        "parent_code": 510400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 510500,
                "areaName": "泸州市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 510502,
                        "areaName": "江阳区",
                        "parent_code": 510500,
                        "level": 3
                    },
                    {
                        "code": 510503,
                        "areaName": "纳溪区",
                        "parent_code": 510500,
                        "level": 3
                    },
                    {
                        "code": 510504,
                        "areaName": "龙马潭区",
                        "parent_code": 510500,
                        "level": 3
                    },
                    {
                        "code": 510521,
                        "areaName": "泸县",
                        "parent_code": 510500,
                        "level": 3
                    },
                    {
                        "code": 510522,
                        "areaName": "合江县",
                        "parent_code": 510500,
                        "level": 3
                    },
                    {
                        "code": 510524,
                        "areaName": "叙永县",
                        "parent_code": 510500,
                        "level": 3
                    },
                    {
                        "code": 510525,
                        "areaName": "古蔺县",
                        "parent_code": 510500,
                        "level": 3
                    },
                    {
                        "code": 510526,
                        "areaName": "泸州市",
                        "parent_code": 510500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 510600,
                "areaName": "德阳市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 510603,
                        "areaName": "旌阳区",
                        "parent_code": 510600,
                        "level": 3
                    },
                    {
                        "code": 510623,
                        "areaName": "中江县",
                        "parent_code": 510600,
                        "level": 3
                    },
                    {
                        "code": 510626,
                        "areaName": "罗江县",
                        "parent_code": 510600,
                        "level": 3
                    },
                    {
                        "code": 510681,
                        "areaName": "广汉市",
                        "parent_code": 510600,
                        "level": 3
                    },
                    {
                        "code": 510682,
                        "areaName": "什邡市",
                        "parent_code": 510600,
                        "level": 3
                    },
                    {
                        "code": 510683,
                        "areaName": "绵竹市",
                        "parent_code": 510600,
                        "level": 3
                    },
                    {
                        "code": 510684,
                        "areaName": "德阳市",
                        "parent_code": 510600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 510700,
                "areaName": "绵阳市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 510703,
                        "areaName": "涪城区",
                        "parent_code": 510700,
                        "level": 3
                    },
                    {
                        "code": 510704,
                        "areaName": "游仙区",
                        "parent_code": 510700,
                        "level": 3
                    },
                    {
                        "code": 510722,
                        "areaName": "三台县",
                        "parent_code": 510700,
                        "level": 3
                    },
                    {
                        "code": 510723,
                        "areaName": "盐亭县",
                        "parent_code": 510700,
                        "level": 3
                    },
                    {
                        "code": 510724,
                        "areaName": "安州区",
                        "parent_code": 510700,
                        "level": 3
                    },
                    {
                        "code": 510725,
                        "areaName": "梓潼县",
                        "parent_code": 510700,
                        "level": 3
                    },
                    {
                        "code": 510726,
                        "areaName": "北川羌族自治县",
                        "parent_code": 510700,
                        "level": 3
                    },
                    {
                        "code": 510727,
                        "areaName": "平武县",
                        "parent_code": 510700,
                        "level": 3
                    },
                    {
                        "code": 510781,
                        "areaName": "江油市",
                        "parent_code": 510700,
                        "level": 3
                    },
                    {
                        "code": 510782,
                        "areaName": "绵阳市",
                        "parent_code": 510700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 510800,
                "areaName": "广元市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 510802,
                        "areaName": "利州区",
                        "parent_code": 510800,
                        "level": 3
                    },
                    {
                        "code": 510811,
                        "areaName": "昭化区",
                        "parent_code": 510800,
                        "level": 3
                    },
                    {
                        "code": 510812,
                        "areaName": "朝天区",
                        "parent_code": 510800,
                        "level": 3
                    },
                    {
                        "code": 510821,
                        "areaName": "旺苍县",
                        "parent_code": 510800,
                        "level": 3
                    },
                    {
                        "code": 510822,
                        "areaName": "青川县",
                        "parent_code": 510800,
                        "level": 3
                    },
                    {
                        "code": 510823,
                        "areaName": "剑阁县",
                        "parent_code": 510800,
                        "level": 3
                    },
                    {
                        "code": 510824,
                        "areaName": "苍溪县",
                        "parent_code": 510800,
                        "level": 3
                    },
                    {
                        "code": 510825,
                        "areaName": "广元市",
                        "parent_code": 510800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 510900,
                "areaName": "遂宁市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 510903,
                        "areaName": "船山区",
                        "parent_code": 510900,
                        "level": 3
                    },
                    {
                        "code": 510904,
                        "areaName": "安居区",
                        "parent_code": 510900,
                        "level": 3
                    },
                    {
                        "code": 510921,
                        "areaName": "蓬溪县",
                        "parent_code": 510900,
                        "level": 3
                    },
                    {
                        "code": 510922,
                        "areaName": "射洪县",
                        "parent_code": 510900,
                        "level": 3
                    },
                    {
                        "code": 510923,
                        "areaName": "大英县",
                        "parent_code": 510900,
                        "level": 3
                    },
                    {
                        "code": 510924,
                        "areaName": "遂宁市",
                        "parent_code": 510900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 511000,
                "areaName": "内江市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 511002,
                        "areaName": "市中区",
                        "parent_code": 511000,
                        "level": 3
                    },
                    {
                        "code": 511011,
                        "areaName": "东兴区",
                        "parent_code": 511000,
                        "level": 3
                    },
                    {
                        "code": 511024,
                        "areaName": "威远县",
                        "parent_code": 511000,
                        "level": 3
                    },
                    {
                        "code": 511025,
                        "areaName": "资中县",
                        "parent_code": 511000,
                        "level": 3
                    },
                    {
                        "code": 511028,
                        "areaName": "隆昌县",
                        "parent_code": 511000,
                        "level": 3
                    },
                    {
                        "code": 511029,
                        "areaName": "内江市",
                        "parent_code": 511000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 511100,
                "areaName": "乐山市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 511102,
                        "areaName": "市中区",
                        "parent_code": 511100,
                        "level": 3
                    },
                    {
                        "code": 511111,
                        "areaName": "沙湾区",
                        "parent_code": 511100,
                        "level": 3
                    },
                    {
                        "code": 511112,
                        "areaName": "五通桥区",
                        "parent_code": 511100,
                        "level": 3
                    },
                    {
                        "code": 511113,
                        "areaName": "金口河区",
                        "parent_code": 511100,
                        "level": 3
                    },
                    {
                        "code": 511123,
                        "areaName": "犍为县",
                        "parent_code": 511100,
                        "level": 3
                    },
                    {
                        "code": 511124,
                        "areaName": "井研县",
                        "parent_code": 511100,
                        "level": 3
                    },
                    {
                        "code": 511126,
                        "areaName": "夹江县",
                        "parent_code": 511100,
                        "level": 3
                    },
                    {
                        "code": 511129,
                        "areaName": "沐川县",
                        "parent_code": 511100,
                        "level": 3
                    },
                    {
                        "code": 511132,
                        "areaName": "峨边彝族自治县",
                        "parent_code": 511100,
                        "level": 3
                    },
                    {
                        "code": 511133,
                        "areaName": "马边彝族自治县",
                        "parent_code": 511100,
                        "level": 3
                    },
                    {
                        "code": 511181,
                        "areaName": "峨眉山市",
                        "parent_code": 511100,
                        "level": 3
                    },
                    {
                        "code": 511182,
                        "areaName": "乐山市",
                        "parent_code": 511100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 511300,
                "areaName": "南充市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 511302,
                        "areaName": "顺庆区",
                        "parent_code": 511300,
                        "level": 3
                    },
                    {
                        "code": 511303,
                        "areaName": "高坪区",
                        "parent_code": 511300,
                        "level": 3
                    },
                    {
                        "code": 511304,
                        "areaName": "嘉陵区",
                        "parent_code": 511300,
                        "level": 3
                    },
                    {
                        "code": 511321,
                        "areaName": "南部县",
                        "parent_code": 511300,
                        "level": 3
                    },
                    {
                        "code": 511322,
                        "areaName": "营山县",
                        "parent_code": 511300,
                        "level": 3
                    },
                    {
                        "code": 511323,
                        "areaName": "蓬安县",
                        "parent_code": 511300,
                        "level": 3
                    },
                    {
                        "code": 511324,
                        "areaName": "仪陇县",
                        "parent_code": 511300,
                        "level": 3
                    },
                    {
                        "code": 511325,
                        "areaName": "西充县",
                        "parent_code": 511300,
                        "level": 3
                    },
                    {
                        "code": 511381,
                        "areaName": "阆中市",
                        "parent_code": 511300,
                        "level": 3
                    },
                    {
                        "code": 511382,
                        "areaName": "南充市",
                        "parent_code": 511300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 511400,
                "areaName": "眉山市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 511402,
                        "areaName": "东坡区",
                        "parent_code": 511400,
                        "level": 3
                    },
                    {
                        "code": 511421,
                        "areaName": "仁寿县",
                        "parent_code": 511400,
                        "level": 3
                    },
                    {
                        "code": 511422,
                        "areaName": "彭山区",
                        "parent_code": 511400,
                        "level": 3
                    },
                    {
                        "code": 511423,
                        "areaName": "洪雅县",
                        "parent_code": 511400,
                        "level": 3
                    },
                    {
                        "code": 511424,
                        "areaName": "丹棱县",
                        "parent_code": 511400,
                        "level": 3
                    },
                    {
                        "code": 511425,
                        "areaName": "青神县",
                        "parent_code": 511400,
                        "level": 3
                    },
                    {
                        "code": 511426,
                        "areaName": "眉山市",
                        "parent_code": 511400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 511500,
                "areaName": "宜宾市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 511502,
                        "areaName": "翠屏区",
                        "parent_code": 511500,
                        "level": 3
                    },
                    {
                        "code": 511503,
                        "areaName": "南溪区",
                        "parent_code": 511500,
                        "level": 3
                    },
                    {
                        "code": 511521,
                        "areaName": "宜宾县",
                        "parent_code": 511500,
                        "level": 3
                    },
                    {
                        "code": 511523,
                        "areaName": "江安县",
                        "parent_code": 511500,
                        "level": 3
                    },
                    {
                        "code": 511524,
                        "areaName": "长宁县",
                        "parent_code": 511500,
                        "level": 3
                    },
                    {
                        "code": 511525,
                        "areaName": "高县",
                        "parent_code": 511500,
                        "level": 3
                    },
                    {
                        "code": 511526,
                        "areaName": "珙县",
                        "parent_code": 511500,
                        "level": 3
                    },
                    {
                        "code": 511527,
                        "areaName": "筠连县",
                        "parent_code": 511500,
                        "level": 3
                    },
                    {
                        "code": 511528,
                        "areaName": "兴文县",
                        "parent_code": 511500,
                        "level": 3
                    },
                    {
                        "code": 511529,
                        "areaName": "屏山县",
                        "parent_code": 511500,
                        "level": 3
                    },
                    {
                        "code": 511530,
                        "areaName": "宜宾市",
                        "parent_code": 511500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 511600,
                "areaName": "广安市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 511602,
                        "areaName": "广安区",
                        "parent_code": 511600,
                        "level": 3
                    },
                    {
                        "code": 511603,
                        "areaName": "前锋区",
                        "parent_code": 511600,
                        "level": 3
                    },
                    {
                        "code": 511621,
                        "areaName": "岳池县",
                        "parent_code": 511600,
                        "level": 3
                    },
                    {
                        "code": 511622,
                        "areaName": "武胜县",
                        "parent_code": 511600,
                        "level": 3
                    },
                    {
                        "code": 511623,
                        "areaName": "邻水县",
                        "parent_code": 511600,
                        "level": 3
                    },
                    {
                        "code": 511681,
                        "areaName": "华蓥市",
                        "parent_code": 511600,
                        "level": 3
                    },
                    {
                        "code": 511682,
                        "areaName": "广安市",
                        "parent_code": 511600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 511700,
                "areaName": "达州市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 511702,
                        "areaName": "通川区",
                        "parent_code": 511700,
                        "level": 3
                    },
                    {
                        "code": 511703,
                        "areaName": "达川区",
                        "parent_code": 511700,
                        "level": 3
                    },
                    {
                        "code": 511722,
                        "areaName": "宣汉县",
                        "parent_code": 511700,
                        "level": 3
                    },
                    {
                        "code": 511723,
                        "areaName": "开江县",
                        "parent_code": 511700,
                        "level": 3
                    },
                    {
                        "code": 511724,
                        "areaName": "大竹县",
                        "parent_code": 511700,
                        "level": 3
                    },
                    {
                        "code": 511725,
                        "areaName": "渠县",
                        "parent_code": 511700,
                        "level": 3
                    },
                    {
                        "code": 511781,
                        "areaName": "万源市",
                        "parent_code": 511700,
                        "level": 3
                    },
                    {
                        "code": 511782,
                        "areaName": "达州市",
                        "parent_code": 511700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 511800,
                "areaName": "雅安市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 511802,
                        "areaName": "雨城区",
                        "parent_code": 511800,
                        "level": 3
                    },
                    {
                        "code": 511803,
                        "areaName": "名山区",
                        "parent_code": 511800,
                        "level": 3
                    },
                    {
                        "code": 511822,
                        "areaName": "荥经县",
                        "parent_code": 511800,
                        "level": 3
                    },
                    {
                        "code": 511823,
                        "areaName": "汉源县",
                        "parent_code": 511800,
                        "level": 3
                    },
                    {
                        "code": 511824,
                        "areaName": "石棉县",
                        "parent_code": 511800,
                        "level": 3
                    },
                    {
                        "code": 511825,
                        "areaName": "天全县",
                        "parent_code": 511800,
                        "level": 3
                    },
                    {
                        "code": 511826,
                        "areaName": "芦山县",
                        "parent_code": 511800,
                        "level": 3
                    },
                    {
                        "code": 511827,
                        "areaName": "宝兴县",
                        "parent_code": 511800,
                        "level": 3
                    },
                    {
                        "code": 511828,
                        "areaName": "雅安市",
                        "parent_code": 511800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 511900,
                "areaName": "巴中市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 511902,
                        "areaName": "巴州区",
                        "parent_code": 511900,
                        "level": 3
                    },
                    {
                        "code": 511903,
                        "areaName": "恩阳区",
                        "parent_code": 511900,
                        "level": 3
                    },
                    {
                        "code": 511921,
                        "areaName": "通江县",
                        "parent_code": 511900,
                        "level": 3
                    },
                    {
                        "code": 511922,
                        "areaName": "南江县",
                        "parent_code": 511900,
                        "level": 3
                    },
                    {
                        "code": 511923,
                        "areaName": "平昌县",
                        "parent_code": 511900,
                        "level": 3
                    },
                    {
                        "code": 511924,
                        "areaName": "巴中市",
                        "parent_code": 511900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 512000,
                "areaName": "资阳市",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 512002,
                        "areaName": "雁江区",
                        "parent_code": 512000,
                        "level": 3
                    },
                    {
                        "code": 512021,
                        "areaName": "安岳县",
                        "parent_code": 512000,
                        "level": 3
                    },
                    {
                        "code": 512022,
                        "areaName": "乐至县",
                        "parent_code": 512000,
                        "level": 3
                    },
                    {
                        "code": 512081,
                        "areaName": "简阳市",
                        "parent_code": 512000,
                        "level": 3
                    },
                    {
                        "code": 512082,
                        "areaName": "资阳市",
                        "parent_code": 512000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 513200,
                "areaName": "阿坝藏族羌族自治州",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 513221,
                        "areaName": "汶川县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513222,
                        "areaName": "理县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513223,
                        "areaName": "茂县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513224,
                        "areaName": "松潘县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513225,
                        "areaName": "九寨沟县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513226,
                        "areaName": "金川县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513227,
                        "areaName": "小金县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513228,
                        "areaName": "黑水县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513229,
                        "areaName": "马尔康县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513230,
                        "areaName": "壤塘县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513231,
                        "areaName": "阿坝县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513232,
                        "areaName": "若尔盖县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513233,
                        "areaName": "红原县",
                        "parent_code": 513200,
                        "level": 3
                    },
                    {
                        "code": 513234,
                        "areaName": "阿坝藏族羌族自治州",
                        "parent_code": 513200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 513300,
                "areaName": "甘孜藏族自治州",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 513321,
                        "areaName": "康定县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513322,
                        "areaName": "泸定县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513323,
                        "areaName": "丹巴县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513324,
                        "areaName": "九龙县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513325,
                        "areaName": "雅江县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513326,
                        "areaName": "道孚县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513327,
                        "areaName": "炉霍县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513328,
                        "areaName": "甘孜县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513329,
                        "areaName": "新龙县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513330,
                        "areaName": "德格县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513331,
                        "areaName": "白玉县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513332,
                        "areaName": "石渠县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513333,
                        "areaName": "色达县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513334,
                        "areaName": "理塘县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513335,
                        "areaName": "巴塘县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513336,
                        "areaName": "乡城县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513337,
                        "areaName": "稻城县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513338,
                        "areaName": "得荣县",
                        "parent_code": 513300,
                        "level": 3
                    },
                    {
                        "code": 513339,
                        "areaName": "甘孜藏族自治州",
                        "parent_code": 513300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 513400,
                "areaName": "凉山彝族自治州",
                "parent_code": 510000,
                "level": 2,
                "children": [
                    {
                        "code": 513401,
                        "areaName": "西昌市",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513422,
                        "areaName": "木里藏族自治县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513423,
                        "areaName": "盐源县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513424,
                        "areaName": "德昌县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513425,
                        "areaName": "会理县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513426,
                        "areaName": "会东县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513427,
                        "areaName": "宁南县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513428,
                        "areaName": "普格县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513429,
                        "areaName": "布拖县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513430,
                        "areaName": "金阳县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513431,
                        "areaName": "昭觉县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513432,
                        "areaName": "喜德县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513433,
                        "areaName": "冕宁县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513434,
                        "areaName": "越西县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513435,
                        "areaName": "甘洛县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513436,
                        "areaName": "美姑县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513437,
                        "areaName": "雷波县",
                        "parent_code": 513400,
                        "level": 3
                    },
                    {
                        "code": 513438,
                        "areaName": "凉山彝族自治州",
                        "parent_code": 513400,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 520000,
        "areaName": "贵州省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 520100,
                "areaName": "贵阳市",
                "parent_code": 520000,
                "level": 2,
                "children": [
                    {
                        "code": 520102,
                        "areaName": "南明区",
                        "parent_code": 520100,
                        "level": 3
                    },
                    {
                        "code": 520103,
                        "areaName": "云岩区",
                        "parent_code": 520100,
                        "level": 3
                    },
                    {
                        "code": 520111,
                        "areaName": "花溪区",
                        "parent_code": 520100,
                        "level": 3
                    },
                    {
                        "code": 520112,
                        "areaName": "乌当区",
                        "parent_code": 520100,
                        "level": 3
                    },
                    {
                        "code": 520113,
                        "areaName": "白云区",
                        "parent_code": 520100,
                        "level": 3
                    },
                    {
                        "code": 520115,
                        "areaName": "观山湖区",
                        "parent_code": 520100,
                        "level": 3
                    },
                    {
                        "code": 520121,
                        "areaName": "开阳县",
                        "parent_code": 520100,
                        "level": 3
                    },
                    {
                        "code": 520122,
                        "areaName": "息烽县",
                        "parent_code": 520100,
                        "level": 3
                    },
                    {
                        "code": 520123,
                        "areaName": "修文县",
                        "parent_code": 520100,
                        "level": 3
                    },
                    {
                        "code": 520181,
                        "areaName": "清镇市",
                        "parent_code": 520100,
                        "level": 3
                    },
                    {
                        "code": 520182,
                        "areaName": "贵阳市",
                        "parent_code": 520100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 520200,
                "areaName": "六盘水市",
                "parent_code": 520000,
                "level": 2,
                "children": [
                    {
                        "code": 520201,
                        "areaName": "钟山区",
                        "parent_code": 520200,
                        "level": 3
                    },
                    {
                        "code": 520203,
                        "areaName": "六枝特区",
                        "parent_code": 520200,
                        "level": 3
                    },
                    {
                        "code": 520221,
                        "areaName": "水城县",
                        "parent_code": 520200,
                        "level": 3
                    },
                    {
                        "code": 520222,
                        "areaName": "盘县",
                        "parent_code": 520200,
                        "level": 3
                    },
                    {
                        "code": 520223,
                        "areaName": "六盘水市",
                        "parent_code": 520200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 520300,
                "areaName": "遵义市",
                "parent_code": 520000,
                "level": 2,
                "children": [
                    {
                        "code": 520302,
                        "areaName": "红花岗区",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520303,
                        "areaName": "汇川区",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520321,
                        "areaName": "遵义县",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520322,
                        "areaName": "桐梓县",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520323,
                        "areaName": "绥阳县",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520324,
                        "areaName": "正安县",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520325,
                        "areaName": "道真仡佬族苗族自治县",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520326,
                        "areaName": "务川仡佬族苗族自治县",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520327,
                        "areaName": "凤冈县",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520328,
                        "areaName": "湄潭县",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520329,
                        "areaName": "余庆县",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520330,
                        "areaName": "习水县",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520381,
                        "areaName": "赤水市",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520382,
                        "areaName": "仁怀市",
                        "parent_code": 520300,
                        "level": 3
                    },
                    {
                        "code": 520383,
                        "areaName": "遵义市",
                        "parent_code": 520300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 520400,
                "areaName": "安顺市",
                "parent_code": 520000,
                "level": 2,
                "children": [
                    {
                        "code": 520402,
                        "areaName": "西秀区",
                        "parent_code": 520400,
                        "level": 3
                    },
                    {
                        "code": 520421,
                        "areaName": "平坝区",
                        "parent_code": 520400,
                        "level": 3
                    },
                    {
                        "code": 520422,
                        "areaName": "普定县",
                        "parent_code": 520400,
                        "level": 3
                    },
                    {
                        "code": 520423,
                        "areaName": "镇宁布依族苗族自治县",
                        "parent_code": 520400,
                        "level": 3
                    },
                    {
                        "code": 520424,
                        "areaName": "关岭布依族苗族自治县",
                        "parent_code": 520400,
                        "level": 3
                    },
                    {
                        "code": 520425,
                        "areaName": "紫云苗族布依族自治县",
                        "parent_code": 520400,
                        "level": 3
                    },
                    {
                        "code": 520426,
                        "areaName": "安顺市",
                        "parent_code": 520400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 520500,
                "areaName": "毕节市",
                "parent_code": 520000,
                "level": 2,
                "children": [
                    {
                        "code": 520502,
                        "areaName": "七星关区",
                        "parent_code": 520500,
                        "level": 3
                    },
                    {
                        "code": 520521,
                        "areaName": "大方县",
                        "parent_code": 520500,
                        "level": 3
                    },
                    {
                        "code": 520522,
                        "areaName": "黔西县",
                        "parent_code": 520500,
                        "level": 3
                    },
                    {
                        "code": 520523,
                        "areaName": "金沙县",
                        "parent_code": 520500,
                        "level": 3
                    },
                    {
                        "code": 520524,
                        "areaName": "织金县",
                        "parent_code": 520500,
                        "level": 3
                    },
                    {
                        "code": 520525,
                        "areaName": "纳雍县",
                        "parent_code": 520500,
                        "level": 3
                    },
                    {
                        "code": 520526,
                        "areaName": "威宁彝族回族苗族自治县",
                        "parent_code": 520500,
                        "level": 3
                    },
                    {
                        "code": 520527,
                        "areaName": "赫章县",
                        "parent_code": 520500,
                        "level": 3
                    },
                    {
                        "code": 520528,
                        "areaName": "毕节市",
                        "parent_code": 520500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 520600,
                "areaName": "铜仁市",
                "parent_code": 520000,
                "level": 2,
                "children": [
                    {
                        "code": 520602,
                        "areaName": "碧江区",
                        "parent_code": 520600,
                        "level": 3
                    },
                    {
                        "code": 520603,
                        "areaName": "万山区",
                        "parent_code": 520600,
                        "level": 3
                    },
                    {
                        "code": 520621,
                        "areaName": "江口县",
                        "parent_code": 520600,
                        "level": 3
                    },
                    {
                        "code": 520622,
                        "areaName": "玉屏侗族自治县",
                        "parent_code": 520600,
                        "level": 3
                    },
                    {
                        "code": 520623,
                        "areaName": "石阡县",
                        "parent_code": 520600,
                        "level": 3
                    },
                    {
                        "code": 520624,
                        "areaName": "思南县",
                        "parent_code": 520600,
                        "level": 3
                    },
                    {
                        "code": 520625,
                        "areaName": "印江土家族苗族自治县",
                        "parent_code": 520600,
                        "level": 3
                    },
                    {
                        "code": 520626,
                        "areaName": "德江县",
                        "parent_code": 520600,
                        "level": 3
                    },
                    {
                        "code": 520627,
                        "areaName": "沿河土家族自治县",
                        "parent_code": 520600,
                        "level": 3
                    },
                    {
                        "code": 520628,
                        "areaName": "松桃苗族自治县",
                        "parent_code": 520600,
                        "level": 3
                    },
                    {
                        "code": 520629,
                        "areaName": "铜仁市",
                        "parent_code": 520600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 522300,
                "areaName": "黔西南布依族苗族自治州",
                "parent_code": 520000,
                "level": 2,
                "children": [
                    {
                        "code": 522301,
                        "areaName": "兴义市",
                        "parent_code": 522300,
                        "level": 3
                    },
                    {
                        "code": 522322,
                        "areaName": "兴仁县",
                        "parent_code": 522300,
                        "level": 3
                    },
                    {
                        "code": 522323,
                        "areaName": "普安县",
                        "parent_code": 522300,
                        "level": 3
                    },
                    {
                        "code": 522324,
                        "areaName": "晴隆县",
                        "parent_code": 522300,
                        "level": 3
                    },
                    {
                        "code": 522325,
                        "areaName": "贞丰县",
                        "parent_code": 522300,
                        "level": 3
                    },
                    {
                        "code": 522326,
                        "areaName": "望谟县",
                        "parent_code": 522300,
                        "level": 3
                    },
                    {
                        "code": 522327,
                        "areaName": "册亨县",
                        "parent_code": 522300,
                        "level": 3
                    },
                    {
                        "code": 522328,
                        "areaName": "安龙县",
                        "parent_code": 522300,
                        "level": 3
                    },
                    {
                        "code": 522329,
                        "areaName": "黔西南布依族苗族自治州",
                        "parent_code": 522300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 522600,
                "areaName": "黔东南苗族侗族自治州",
                "parent_code": 520000,
                "level": 2,
                "children": [
                    {
                        "code": 522601,
                        "areaName": "凯里市",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522622,
                        "areaName": "黄平县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522623,
                        "areaName": "施秉县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522624,
                        "areaName": "三穗县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522625,
                        "areaName": "镇远县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522626,
                        "areaName": "岑巩县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522627,
                        "areaName": "天柱县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522628,
                        "areaName": "锦屏县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522629,
                        "areaName": "剑河县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522630,
                        "areaName": "台江县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522631,
                        "areaName": "黎平县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522632,
                        "areaName": "榕江县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522633,
                        "areaName": "从江县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522634,
                        "areaName": "雷山县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522635,
                        "areaName": "麻江县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522636,
                        "areaName": "丹寨县",
                        "parent_code": 522600,
                        "level": 3
                    },
                    {
                        "code": 522637,
                        "areaName": "黔东南苗族侗族自治州",
                        "parent_code": 522600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 522700,
                "areaName": "黔南布依族苗族自治州",
                "parent_code": 520000,
                "level": 2,
                "children": [
                    {
                        "code": 522701,
                        "areaName": "都匀市",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522702,
                        "areaName": "福泉市",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522722,
                        "areaName": "荔波县",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522723,
                        "areaName": "贵定县",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522725,
                        "areaName": "瓮安县",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522726,
                        "areaName": "独山县",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522727,
                        "areaName": "平塘县",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522728,
                        "areaName": "罗甸县",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522729,
                        "areaName": "长顺县",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522730,
                        "areaName": "龙里县",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522731,
                        "areaName": "惠水县",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522732,
                        "areaName": "三都水族自治县",
                        "parent_code": 522700,
                        "level": 3
                    },
                    {
                        "code": 522733,
                        "areaName": "黔南布依族苗族自治州",
                        "parent_code": 522700,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 530000,
        "areaName": "云南省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 530100,
                "areaName": "昆明市",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 530102,
                        "areaName": "五华区",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530103,
                        "areaName": "盘龙区",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530111,
                        "areaName": "官渡区",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530112,
                        "areaName": "西山区",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530113,
                        "areaName": "东川区",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530114,
                        "areaName": "呈贡区",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530122,
                        "areaName": "晋宁县",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530124,
                        "areaName": "富民县",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530125,
                        "areaName": "宜良县",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530126,
                        "areaName": "石林彝族自治县",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530127,
                        "areaName": "嵩明县",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530128,
                        "areaName": "禄劝彝族苗族自治县",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530129,
                        "areaName": "寻甸回族彝族自治县",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530181,
                        "areaName": "安宁市",
                        "parent_code": 530100,
                        "level": 3
                    },
                    {
                        "code": 530182,
                        "areaName": "昆明市",
                        "parent_code": 530100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 530300,
                "areaName": "曲靖市",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 530302,
                        "areaName": "麒麟区",
                        "parent_code": 530300,
                        "level": 3
                    },
                    {
                        "code": 530321,
                        "areaName": "马龙县",
                        "parent_code": 530300,
                        "level": 3
                    },
                    {
                        "code": 530322,
                        "areaName": "陆良县",
                        "parent_code": 530300,
                        "level": 3
                    },
                    {
                        "code": 530323,
                        "areaName": "师宗县",
                        "parent_code": 530300,
                        "level": 3
                    },
                    {
                        "code": 530324,
                        "areaName": "罗平县",
                        "parent_code": 530300,
                        "level": 3
                    },
                    {
                        "code": 530325,
                        "areaName": "富源县",
                        "parent_code": 530300,
                        "level": 3
                    },
                    {
                        "code": 530326,
                        "areaName": "会泽县",
                        "parent_code": 530300,
                        "level": 3
                    },
                    {
                        "code": 530328,
                        "areaName": "沾益县",
                        "parent_code": 530300,
                        "level": 3
                    },
                    {
                        "code": 530381,
                        "areaName": "宣威市",
                        "parent_code": 530300,
                        "level": 3
                    },
                    {
                        "code": 530382,
                        "areaName": "曲靖市",
                        "parent_code": 530300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 530400,
                "areaName": "玉溪市",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 530402,
                        "areaName": "红塔区",
                        "parent_code": 530400,
                        "level": 3
                    },
                    {
                        "code": 530421,
                        "areaName": "江川县",
                        "parent_code": 530400,
                        "level": 3
                    },
                    {
                        "code": 530422,
                        "areaName": "澄江县",
                        "parent_code": 530400,
                        "level": 3
                    },
                    {
                        "code": 530423,
                        "areaName": "通海县",
                        "parent_code": 530400,
                        "level": 3
                    },
                    {
                        "code": 530424,
                        "areaName": "华宁县",
                        "parent_code": 530400,
                        "level": 3
                    },
                    {
                        "code": 530425,
                        "areaName": "易门县",
                        "parent_code": 530400,
                        "level": 3
                    },
                    {
                        "code": 530426,
                        "areaName": "峨山彝族自治县",
                        "parent_code": 530400,
                        "level": 3
                    },
                    {
                        "code": 530427,
                        "areaName": "新平彝族傣族自治县",
                        "parent_code": 530400,
                        "level": 3
                    },
                    {
                        "code": 530428,
                        "areaName": "元江哈尼族彝族傣族自治县",
                        "parent_code": 530400,
                        "level": 3
                    },
                    {
                        "code": 530429,
                        "areaName": "玉溪市",
                        "parent_code": 530400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 530500,
                "areaName": "保山市",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 530502,
                        "areaName": "隆阳区",
                        "parent_code": 530500,
                        "level": 3
                    },
                    {
                        "code": 530521,
                        "areaName": "施甸县",
                        "parent_code": 530500,
                        "level": 3
                    },
                    {
                        "code": 530522,
                        "areaName": "腾冲县",
                        "parent_code": 530500,
                        "level": 3
                    },
                    {
                        "code": 530523,
                        "areaName": "龙陵县",
                        "parent_code": 530500,
                        "level": 3
                    },
                    {
                        "code": 530524,
                        "areaName": "昌宁县",
                        "parent_code": 530500,
                        "level": 3
                    },
                    {
                        "code": 530525,
                        "areaName": "保山市",
                        "parent_code": 530500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 530600,
                "areaName": "昭通市",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 530602,
                        "areaName": "昭阳区",
                        "parent_code": 530600,
                        "level": 3
                    },
                    {
                        "code": 530621,
                        "areaName": "鲁甸县",
                        "parent_code": 530600,
                        "level": 3
                    },
                    {
                        "code": 530622,
                        "areaName": "巧家县",
                        "parent_code": 530600,
                        "level": 3
                    },
                    {
                        "code": 530623,
                        "areaName": "盐津县",
                        "parent_code": 530600,
                        "level": 3
                    },
                    {
                        "code": 530624,
                        "areaName": "大关县",
                        "parent_code": 530600,
                        "level": 3
                    },
                    {
                        "code": 530625,
                        "areaName": "永善县",
                        "parent_code": 530600,
                        "level": 3
                    },
                    {
                        "code": 530626,
                        "areaName": "绥江县",
                        "parent_code": 530600,
                        "level": 3
                    },
                    {
                        "code": 530627,
                        "areaName": "镇雄县",
                        "parent_code": 530600,
                        "level": 3
                    },
                    {
                        "code": 530628,
                        "areaName": "彝良县",
                        "parent_code": 530600,
                        "level": 3
                    },
                    {
                        "code": 530629,
                        "areaName": "威信县",
                        "parent_code": 530600,
                        "level": 3
                    },
                    {
                        "code": 530630,
                        "areaName": "水富县",
                        "parent_code": 530600,
                        "level": 3
                    },
                    {
                        "code": 530631,
                        "areaName": "昭通市",
                        "parent_code": 530600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 530700,
                "areaName": "丽江市",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 530702,
                        "areaName": "古城区",
                        "parent_code": 530700,
                        "level": 3
                    },
                    {
                        "code": 530721,
                        "areaName": "玉龙纳西族自治县",
                        "parent_code": 530700,
                        "level": 3
                    },
                    {
                        "code": 530722,
                        "areaName": "永胜县",
                        "parent_code": 530700,
                        "level": 3
                    },
                    {
                        "code": 530723,
                        "areaName": "华坪县",
                        "parent_code": 530700,
                        "level": 3
                    },
                    {
                        "code": 530724,
                        "areaName": "宁蒗彝族自治县",
                        "parent_code": 530700,
                        "level": 3
                    },
                    {
                        "code": 530725,
                        "areaName": "丽江市",
                        "parent_code": 530700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 530800,
                "areaName": "普洱市",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 530802,
                        "areaName": "思茅区",
                        "parent_code": 530800,
                        "level": 3
                    },
                    {
                        "code": 530821,
                        "areaName": "宁洱哈尼族彝族自治县",
                        "parent_code": 530800,
                        "level": 3
                    },
                    {
                        "code": 530822,
                        "areaName": "墨江哈尼族自治县",
                        "parent_code": 530800,
                        "level": 3
                    },
                    {
                        "code": 530823,
                        "areaName": "景东彝族自治县",
                        "parent_code": 530800,
                        "level": 3
                    },
                    {
                        "code": 530824,
                        "areaName": "景谷傣族彝族自治县",
                        "parent_code": 530800,
                        "level": 3
                    },
                    {
                        "code": 530825,
                        "areaName": "镇沅彝族哈尼族拉祜族自治县",
                        "parent_code": 530800,
                        "level": 3
                    },
                    {
                        "code": 530826,
                        "areaName": "江城哈尼族彝族自治县",
                        "parent_code": 530800,
                        "level": 3
                    },
                    {
                        "code": 530827,
                        "areaName": "孟连傣族拉祜族佤族自治县",
                        "parent_code": 530800,
                        "level": 3
                    },
                    {
                        "code": 530828,
                        "areaName": "澜沧拉祜族自治县",
                        "parent_code": 530800,
                        "level": 3
                    },
                    {
                        "code": 530829,
                        "areaName": "西盟佤族自治县",
                        "parent_code": 530800,
                        "level": 3
                    },
                    {
                        "code": 530830,
                        "areaName": "普洱市",
                        "parent_code": 530800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 530900,
                "areaName": "临沧市",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 530902,
                        "areaName": "临翔区",
                        "parent_code": 530900,
                        "level": 3
                    },
                    {
                        "code": 530921,
                        "areaName": "凤庆县",
                        "parent_code": 530900,
                        "level": 3
                    },
                    {
                        "code": 530922,
                        "areaName": "云县",
                        "parent_code": 530900,
                        "level": 3
                    },
                    {
                        "code": 530923,
                        "areaName": "永德县",
                        "parent_code": 530900,
                        "level": 3
                    },
                    {
                        "code": 530924,
                        "areaName": "镇康县",
                        "parent_code": 530900,
                        "level": 3
                    },
                    {
                        "code": 530925,
                        "areaName": "双江拉祜族佤族布朗族傣族自治县",
                        "parent_code": 530900,
                        "level": 3
                    },
                    {
                        "code": 530926,
                        "areaName": "耿马傣族佤族自治县",
                        "parent_code": 530900,
                        "level": 3
                    },
                    {
                        "code": 530927,
                        "areaName": "沧源佤族自治县",
                        "parent_code": 530900,
                        "level": 3
                    },
                    {
                        "code": 530928,
                        "areaName": "临沧市",
                        "parent_code": 530900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 532300,
                "areaName": "楚雄彝族自治州",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 532301,
                        "areaName": "楚雄市",
                        "parent_code": 532300,
                        "level": 3
                    },
                    {
                        "code": 532322,
                        "areaName": "双柏县",
                        "parent_code": 532300,
                        "level": 3
                    },
                    {
                        "code": 532323,
                        "areaName": "牟定县",
                        "parent_code": 532300,
                        "level": 3
                    },
                    {
                        "code": 532324,
                        "areaName": "南华县",
                        "parent_code": 532300,
                        "level": 3
                    },
                    {
                        "code": 532325,
                        "areaName": "姚安县",
                        "parent_code": 532300,
                        "level": 3
                    },
                    {
                        "code": 532326,
                        "areaName": "大姚县",
                        "parent_code": 532300,
                        "level": 3
                    },
                    {
                        "code": 532327,
                        "areaName": "永仁县",
                        "parent_code": 532300,
                        "level": 3
                    },
                    {
                        "code": 532328,
                        "areaName": "元谋县",
                        "parent_code": 532300,
                        "level": 3
                    },
                    {
                        "code": 532329,
                        "areaName": "武定县",
                        "parent_code": 532300,
                        "level": 3
                    },
                    {
                        "code": 532331,
                        "areaName": "禄丰县",
                        "parent_code": 532300,
                        "level": 3
                    },
                    {
                        "code": 532332,
                        "areaName": "楚雄彝族自治州",
                        "parent_code": 532300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 532500,
                "areaName": "红河哈尼族彝族自治州",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 532501,
                        "areaName": "个旧市",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532502,
                        "areaName": "开远市",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532503,
                        "areaName": "蒙自市",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532504,
                        "areaName": "弥勒市",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532523,
                        "areaName": "屏边苗族自治县",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532524,
                        "areaName": "建水县",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532525,
                        "areaName": "石屏县",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532527,
                        "areaName": "泸西县",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532528,
                        "areaName": "元阳县",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532529,
                        "areaName": "红河县",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532530,
                        "areaName": "金平苗族瑶族傣族自治县",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532531,
                        "areaName": "绿春县",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532532,
                        "areaName": "河口瑶族自治县",
                        "parent_code": 532500,
                        "level": 3
                    },
                    {
                        "code": 532533,
                        "areaName": "红河哈尼族彝族自治州",
                        "parent_code": 532500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 532600,
                "areaName": "文山壮族苗族自治州",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 532601,
                        "areaName": "文山市",
                        "parent_code": 532600,
                        "level": 3
                    },
                    {
                        "code": 532622,
                        "areaName": "砚山县",
                        "parent_code": 532600,
                        "level": 3
                    },
                    {
                        "code": 532623,
                        "areaName": "西畴县",
                        "parent_code": 532600,
                        "level": 3
                    },
                    {
                        "code": 532624,
                        "areaName": "麻栗坡县",
                        "parent_code": 532600,
                        "level": 3
                    },
                    {
                        "code": 532625,
                        "areaName": "马关县",
                        "parent_code": 532600,
                        "level": 3
                    },
                    {
                        "code": 532626,
                        "areaName": "丘北县",
                        "parent_code": 532600,
                        "level": 3
                    },
                    {
                        "code": 532627,
                        "areaName": "广南县",
                        "parent_code": 532600,
                        "level": 3
                    },
                    {
                        "code": 532628,
                        "areaName": "富宁县",
                        "parent_code": 532600,
                        "level": 3
                    },
                    {
                        "code": 532629,
                        "areaName": "文山壮族苗族自治州",
                        "parent_code": 532600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 532800,
                "areaName": "西双版纳傣族自治州",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 532801,
                        "areaName": "景洪市",
                        "parent_code": 532800,
                        "level": 3
                    },
                    {
                        "code": 532822,
                        "areaName": "勐海县",
                        "parent_code": 532800,
                        "level": 3
                    },
                    {
                        "code": 532823,
                        "areaName": "勐腊县",
                        "parent_code": 532800,
                        "level": 3
                    },
                    {
                        "code": 532824,
                        "areaName": "西双版纳傣族自治州",
                        "parent_code": 532800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 532900,
                "areaName": "大理白族自治州",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 532901,
                        "areaName": "大理市",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532922,
                        "areaName": "漾濞彝族自治县",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532923,
                        "areaName": "祥云县",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532924,
                        "areaName": "宾川县",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532925,
                        "areaName": "弥渡县",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532926,
                        "areaName": "南涧彝族自治县",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532927,
                        "areaName": "巍山彝族回族自治县",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532928,
                        "areaName": "永平县",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532929,
                        "areaName": "云龙县",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532930,
                        "areaName": "洱源县",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532931,
                        "areaName": "剑川县",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532932,
                        "areaName": "鹤庆县",
                        "parent_code": 532900,
                        "level": 3
                    },
                    {
                        "code": 532933,
                        "areaName": "大理白族自治州",
                        "parent_code": 532900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 533100,
                "areaName": "德宏傣族景颇族自治州",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 533102,
                        "areaName": "瑞丽市",
                        "parent_code": 533100,
                        "level": 3
                    },
                    {
                        "code": 533103,
                        "areaName": "芒市",
                        "parent_code": 533100,
                        "level": 3
                    },
                    {
                        "code": 533122,
                        "areaName": "梁河县",
                        "parent_code": 533100,
                        "level": 3
                    },
                    {
                        "code": 533123,
                        "areaName": "盈江县",
                        "parent_code": 533100,
                        "level": 3
                    },
                    {
                        "code": 533124,
                        "areaName": "陇川县",
                        "parent_code": 533100,
                        "level": 3
                    },
                    {
                        "code": 533125,
                        "areaName": "德宏傣族景颇族自治州",
                        "parent_code": 533100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 533300,
                "areaName": "怒江傈僳族自治州",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 533321,
                        "areaName": "泸水县",
                        "parent_code": 533300,
                        "level": 3
                    },
                    {
                        "code": 533323,
                        "areaName": "福贡县",
                        "parent_code": 533300,
                        "level": 3
                    },
                    {
                        "code": 533324,
                        "areaName": "贡山独龙族怒族自治县",
                        "parent_code": 533300,
                        "level": 3
                    },
                    {
                        "code": 533325,
                        "areaName": "兰坪白族普米族自治县",
                        "parent_code": 533300,
                        "level": 3
                    },
                    {
                        "code": 533326,
                        "areaName": "怒江傈僳族自治州",
                        "parent_code": 533300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 533400,
                "areaName": "迪庆藏族自治州",
                "parent_code": 530000,
                "level": 2,
                "children": [
                    {
                        "code": 533421,
                        "areaName": "香格里拉市",
                        "parent_code": 533400,
                        "level": 3
                    },
                    {
                        "code": 533422,
                        "areaName": "德钦县",
                        "parent_code": 533400,
                        "level": 3
                    },
                    {
                        "code": 533423,
                        "areaName": "维西傈僳族自治县",
                        "parent_code": 533400,
                        "level": 3
                    },
                    {
                        "code": 533424,
                        "areaName": "迪庆藏族自治州",
                        "parent_code": 533400,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 540000,
        "areaName": "西藏自治区",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 540100,
                "areaName": "拉萨市",
                "parent_code": 540000,
                "level": 2,
                "children": [
                    {
                        "code": 540102,
                        "areaName": "城关区",
                        "parent_code": 540100,
                        "level": 3
                    },
                    {
                        "code": 540121,
                        "areaName": "林周县",
                        "parent_code": 540100,
                        "level": 3
                    },
                    {
                        "code": 540122,
                        "areaName": "当雄县",
                        "parent_code": 540100,
                        "level": 3
                    },
                    {
                        "code": 540123,
                        "areaName": "尼木县",
                        "parent_code": 540100,
                        "level": 3
                    },
                    {
                        "code": 540124,
                        "areaName": "曲水县",
                        "parent_code": 540100,
                        "level": 3
                    },
                    {
                        "code": 540125,
                        "areaName": "堆龙德庆县",
                        "parent_code": 540100,
                        "level": 3
                    },
                    {
                        "code": 540126,
                        "areaName": "达孜县",
                        "parent_code": 540100,
                        "level": 3
                    },
                    {
                        "code": 540127,
                        "areaName": "墨竹工卡县",
                        "parent_code": 540100,
                        "level": 3
                    },
                    {
                        "code": 540128,
                        "areaName": "拉萨市",
                        "parent_code": 540100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 542100,
                "areaName": "昌都地区",
                "parent_code": 540000,
                "level": 2,
                "children": [
                    {
                        "code": 542121,
                        "areaName": "卡若区",
                        "parent_code": 542100,
                        "level": 3
                    },
                    {
                        "code": 542122,
                        "areaName": "江达县",
                        "parent_code": 542100,
                        "level": 3
                    },
                    {
                        "code": 542123,
                        "areaName": "贡觉县",
                        "parent_code": 542100,
                        "level": 3
                    },
                    {
                        "code": 542124,
                        "areaName": "类乌齐县",
                        "parent_code": 542100,
                        "level": 3
                    },
                    {
                        "code": 542125,
                        "areaName": "丁青县",
                        "parent_code": 542100,
                        "level": 3
                    },
                    {
                        "code": 542126,
                        "areaName": "察雅县",
                        "parent_code": 542100,
                        "level": 3
                    },
                    {
                        "code": 542127,
                        "areaName": "八宿县",
                        "parent_code": 542100,
                        "level": 3
                    },
                    {
                        "code": 542128,
                        "areaName": "左贡县",
                        "parent_code": 542100,
                        "level": 3
                    },
                    {
                        "code": 542129,
                        "areaName": "芒康县",
                        "parent_code": 542100,
                        "level": 3
                    },
                    {
                        "code": 542132,
                        "areaName": "洛隆县",
                        "parent_code": 542100,
                        "level": 3
                    },
                    {
                        "code": 542133,
                        "areaName": "边坝县",
                        "parent_code": 542100,
                        "level": 3
                    },
                    {
                        "code": 542134,
                        "areaName": "昌都地区",
                        "parent_code": 542100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 542200,
                "areaName": "山南地区",
                "parent_code": 540000,
                "level": 2,
                "children": [
                    {
                        "code": 542221,
                        "areaName": "乃东县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542222,
                        "areaName": "扎囊县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542223,
                        "areaName": "贡嘎县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542224,
                        "areaName": "桑日县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542225,
                        "areaName": "琼结县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542226,
                        "areaName": "曲松县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542227,
                        "areaName": "措美县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542228,
                        "areaName": "洛扎县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542229,
                        "areaName": "加查县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542231,
                        "areaName": "隆子县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542232,
                        "areaName": "错那县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542233,
                        "areaName": "浪卡子县",
                        "parent_code": 542200,
                        "level": 3
                    },
                    {
                        "code": 542234,
                        "areaName": "山南地区",
                        "parent_code": 542200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 542300,
                "areaName": "日喀则地区",
                "parent_code": 540000,
                "level": 2,
                "children": [
                    {
                        "code": 542301,
                        "areaName": "桑珠孜区",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542322,
                        "areaName": "南木林县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542323,
                        "areaName": "江孜县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542324,
                        "areaName": "定日县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542325,
                        "areaName": "萨迦县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542326,
                        "areaName": "拉孜县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542327,
                        "areaName": "昂仁县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542328,
                        "areaName": "谢通门县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542329,
                        "areaName": "白朗县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542330,
                        "areaName": "仁布县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542331,
                        "areaName": "康马县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542332,
                        "areaName": "定结县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542333,
                        "areaName": "仲巴县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542334,
                        "areaName": "亚东县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542335,
                        "areaName": "吉隆县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542336,
                        "areaName": "聂拉木县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542337,
                        "areaName": "萨嘎县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542338,
                        "areaName": "岗巴县",
                        "parent_code": 542300,
                        "level": 3
                    },
                    {
                        "code": 542339,
                        "areaName": "日喀则地区",
                        "parent_code": 542300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 542400,
                "areaName": "那曲地区",
                "parent_code": 540000,
                "level": 2,
                "children": [
                    {
                        "code": 542421,
                        "areaName": "那曲县",
                        "parent_code": 542400,
                        "level": 3
                    },
                    {
                        "code": 542422,
                        "areaName": "嘉黎县",
                        "parent_code": 542400,
                        "level": 3
                    },
                    {
                        "code": 542423,
                        "areaName": "比如县",
                        "parent_code": 542400,
                        "level": 3
                    },
                    {
                        "code": 542424,
                        "areaName": "聂荣县",
                        "parent_code": 542400,
                        "level": 3
                    },
                    {
                        "code": 542425,
                        "areaName": "安多县",
                        "parent_code": 542400,
                        "level": 3
                    },
                    {
                        "code": 542426,
                        "areaName": "申扎县",
                        "parent_code": 542400,
                        "level": 3
                    },
                    {
                        "code": 542427,
                        "areaName": "索县",
                        "parent_code": 542400,
                        "level": 3
                    },
                    {
                        "code": 542428,
                        "areaName": "班戈县",
                        "parent_code": 542400,
                        "level": 3
                    },
                    {
                        "code": 542429,
                        "areaName": "巴青县",
                        "parent_code": 542400,
                        "level": 3
                    },
                    {
                        "code": 542430,
                        "areaName": "尼玛县",
                        "parent_code": 542400,
                        "level": 3
                    },
                    {
                        "code": 542431,
                        "areaName": "双湖县",
                        "parent_code": 542400,
                        "level": 3
                    },
                    {
                        "code": 542432,
                        "areaName": "那曲地区",
                        "parent_code": 542400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 542500,
                "areaName": "阿里地区",
                "parent_code": 540000,
                "level": 2,
                "children": [
                    {
                        "code": 542521,
                        "areaName": "普兰县",
                        "parent_code": 542500,
                        "level": 3
                    },
                    {
                        "code": 542522,
                        "areaName": "札达县",
                        "parent_code": 542500,
                        "level": 3
                    },
                    {
                        "code": 542523,
                        "areaName": "噶尔县",
                        "parent_code": 542500,
                        "level": 3
                    },
                    {
                        "code": 542524,
                        "areaName": "日土县",
                        "parent_code": 542500,
                        "level": 3
                    },
                    {
                        "code": 542525,
                        "areaName": "革吉县",
                        "parent_code": 542500,
                        "level": 3
                    },
                    {
                        "code": 542526,
                        "areaName": "改则县",
                        "parent_code": 542500,
                        "level": 3
                    },
                    {
                        "code": 542527,
                        "areaName": "措勤县",
                        "parent_code": 542500,
                        "level": 3
                    },
                    {
                        "code": 542528,
                        "areaName": "阿里地区",
                        "parent_code": 542500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 542600,
                "areaName": "林芝地区",
                "parent_code": 540000,
                "level": 2,
                "children": [
                    {
                        "code": 542621,
                        "areaName": "林芝县",
                        "parent_code": 542600,
                        "level": 3
                    },
                    {
                        "code": 542622,
                        "areaName": "工布江达县",
                        "parent_code": 542600,
                        "level": 3
                    },
                    {
                        "code": 542623,
                        "areaName": "米林县",
                        "parent_code": 542600,
                        "level": 3
                    },
                    {
                        "code": 542624,
                        "areaName": "墨脱县",
                        "parent_code": 542600,
                        "level": 3
                    },
                    {
                        "code": 542625,
                        "areaName": "波密县",
                        "parent_code": 542600,
                        "level": 3
                    },
                    {
                        "code": 542626,
                        "areaName": "察隅县",
                        "parent_code": 542600,
                        "level": 3
                    },
                    {
                        "code": 542627,
                        "areaName": "朗县",
                        "parent_code": 542600,
                        "level": 3
                    },
                    {
                        "code": 542628,
                        "areaName": "林芝地区",
                        "parent_code": 542600,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 610000,
        "areaName": "陕西省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 610100,
                "areaName": "西安市",
                "parent_code": 610000,
                "level": 2,
                "children": [
                    {
                        "code": 610102,
                        "areaName": "新城区",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610103,
                        "areaName": "碑林区",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610104,
                        "areaName": "莲湖区",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610111,
                        "areaName": "灞桥区",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610112,
                        "areaName": "未央区",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610113,
                        "areaName": "雁塔区",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610114,
                        "areaName": "阎良区",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610115,
                        "areaName": "临潼区",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610116,
                        "areaName": "长安区",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610122,
                        "areaName": "蓝田县",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610124,
                        "areaName": "周至县",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610125,
                        "areaName": "户县",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610126,
                        "areaName": "高陵区",
                        "parent_code": 610100,
                        "level": 3
                    },
                    {
                        "code": 610127,
                        "areaName": "西安市",
                        "parent_code": 610100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 610200,
                "areaName": "铜川市",
                "parent_code": 610000,
                "level": 2,
                "children": [
                    {
                        "code": 610202,
                        "areaName": "王益区",
                        "parent_code": 610200,
                        "level": 3
                    },
                    {
                        "code": 610203,
                        "areaName": "印台区",
                        "parent_code": 610200,
                        "level": 3
                    },
                    {
                        "code": 610204,
                        "areaName": "耀州区",
                        "parent_code": 610200,
                        "level": 3
                    },
                    {
                        "code": 610222,
                        "areaName": "宜君县",
                        "parent_code": 610200,
                        "level": 3
                    },
                    {
                        "code": 610223,
                        "areaName": "铜川市",
                        "parent_code": 610200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 610300,
                "areaName": "宝鸡市",
                "parent_code": 610000,
                "level": 2,
                "children": [
                    {
                        "code": 610302,
                        "areaName": "渭滨区",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610303,
                        "areaName": "金台区",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610304,
                        "areaName": "陈仓区",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610322,
                        "areaName": "凤翔县",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610323,
                        "areaName": "岐山县",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610324,
                        "areaName": "扶风县",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610326,
                        "areaName": "眉县",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610327,
                        "areaName": "陇县",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610328,
                        "areaName": "千阳县",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610329,
                        "areaName": "麟游县",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610330,
                        "areaName": "凤县",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610331,
                        "areaName": "太白县",
                        "parent_code": 610300,
                        "level": 3
                    },
                    {
                        "code": 610332,
                        "areaName": "宝鸡市",
                        "parent_code": 610300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 610400,
                "areaName": "咸阳市",
                "parent_code": 610000,
                "level": 2,
                "children": [
                    {
                        "code": 610402,
                        "areaName": "秦都区",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610403,
                        "areaName": "杨陵区",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610404,
                        "areaName": "渭城区",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610422,
                        "areaName": "三原县",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610423,
                        "areaName": "泾阳县",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610424,
                        "areaName": "乾县",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610425,
                        "areaName": "礼泉县",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610426,
                        "areaName": "永寿县",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610427,
                        "areaName": "彬县",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610428,
                        "areaName": "长武县",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610429,
                        "areaName": "旬邑县",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610430,
                        "areaName": "淳化县",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610431,
                        "areaName": "武功县",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610481,
                        "areaName": "兴平市",
                        "parent_code": 610400,
                        "level": 3
                    },
                    {
                        "code": 610482,
                        "areaName": "咸阳市",
                        "parent_code": 610400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 610500,
                "areaName": "渭南市",
                "parent_code": 610000,
                "level": 2,
                "children": [
                    {
                        "code": 610502,
                        "areaName": "临渭区",
                        "parent_code": 610500,
                        "level": 3
                    },
                    {
                        "code": 610521,
                        "areaName": "华县",
                        "parent_code": 610500,
                        "level": 3
                    },
                    {
                        "code": 610522,
                        "areaName": "潼关县",
                        "parent_code": 610500,
                        "level": 3
                    },
                    {
                        "code": 610523,
                        "areaName": "大荔县",
                        "parent_code": 610500,
                        "level": 3
                    },
                    {
                        "code": 610524,
                        "areaName": "合阳县",
                        "parent_code": 610500,
                        "level": 3
                    },
                    {
                        "code": 610525,
                        "areaName": "澄城县",
                        "parent_code": 610500,
                        "level": 3
                    },
                    {
                        "code": 610526,
                        "areaName": "蒲城县",
                        "parent_code": 610500,
                        "level": 3
                    },
                    {
                        "code": 610527,
                        "areaName": "白水县",
                        "parent_code": 610500,
                        "level": 3
                    },
                    {
                        "code": 610528,
                        "areaName": "富平县",
                        "parent_code": 610500,
                        "level": 3
                    },
                    {
                        "code": 610581,
                        "areaName": "韩城市",
                        "parent_code": 610500,
                        "level": 3
                    },
                    {
                        "code": 610582,
                        "areaName": "华阴市",
                        "parent_code": 610500,
                        "level": 3
                    },
                    {
                        "code": 610583,
                        "areaName": "渭南市",
                        "parent_code": 610500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 610600,
                "areaName": "延安市",
                "parent_code": 610000,
                "level": 2,
                "children": [
                    {
                        "code": 610602,
                        "areaName": "宝塔区",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610621,
                        "areaName": "延长县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610622,
                        "areaName": "延川县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610623,
                        "areaName": "子长县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610624,
                        "areaName": "安塞县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610625,
                        "areaName": "志丹县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610626,
                        "areaName": "吴起县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610627,
                        "areaName": "甘泉县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610628,
                        "areaName": "富县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610629,
                        "areaName": "洛川县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610630,
                        "areaName": "宜川县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610631,
                        "areaName": "黄龙县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610632,
                        "areaName": "黄陵县",
                        "parent_code": 610600,
                        "level": 3
                    },
                    {
                        "code": 610633,
                        "areaName": "延安市",
                        "parent_code": 610600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 610700,
                "areaName": "汉中市",
                "parent_code": 610000,
                "level": 2,
                "children": [
                    {
                        "code": 610702,
                        "areaName": "汉台区",
                        "parent_code": 610700,
                        "level": 3
                    },
                    {
                        "code": 610721,
                        "areaName": "南郑县",
                        "parent_code": 610700,
                        "level": 3
                    },
                    {
                        "code": 610722,
                        "areaName": "城固县",
                        "parent_code": 610700,
                        "level": 3
                    },
                    {
                        "code": 610723,
                        "areaName": "洋县",
                        "parent_code": 610700,
                        "level": 3
                    },
                    {
                        "code": 610724,
                        "areaName": "西乡县",
                        "parent_code": 610700,
                        "level": 3
                    },
                    {
                        "code": 610725,
                        "areaName": "勉县",
                        "parent_code": 610700,
                        "level": 3
                    },
                    {
                        "code": 610726,
                        "areaName": "宁强县",
                        "parent_code": 610700,
                        "level": 3
                    },
                    {
                        "code": 610727,
                        "areaName": "略阳县",
                        "parent_code": 610700,
                        "level": 3
                    },
                    {
                        "code": 610728,
                        "areaName": "镇巴县",
                        "parent_code": 610700,
                        "level": 3
                    },
                    {
                        "code": 610729,
                        "areaName": "留坝县",
                        "parent_code": 610700,
                        "level": 3
                    },
                    {
                        "code": 610730,
                        "areaName": "佛坪县",
                        "parent_code": 610700,
                        "level": 3
                    },
                    {
                        "code": 610731,
                        "areaName": "汉中市",
                        "parent_code": 610700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 610800,
                "areaName": "榆林市",
                "parent_code": 610000,
                "level": 2,
                "children": [
                    {
                        "code": 610802,
                        "areaName": "榆阳区",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610821,
                        "areaName": "神木县",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610822,
                        "areaName": "府谷县",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610823,
                        "areaName": "横山县",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610824,
                        "areaName": "靖边县",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610825,
                        "areaName": "定边县",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610826,
                        "areaName": "绥德县",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610827,
                        "areaName": "米脂县",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610828,
                        "areaName": "佳县",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610829,
                        "areaName": "吴堡县",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610830,
                        "areaName": "清涧县",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610831,
                        "areaName": "子洲县",
                        "parent_code": 610800,
                        "level": 3
                    },
                    {
                        "code": 610832,
                        "areaName": "榆林市",
                        "parent_code": 610800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 610900,
                "areaName": "安康市",
                "parent_code": 610000,
                "level": 2,
                "children": [
                    {
                        "code": 610902,
                        "areaName": "汉滨区",
                        "parent_code": 610900,
                        "level": 3
                    },
                    {
                        "code": 610921,
                        "areaName": "汉阴县",
                        "parent_code": 610900,
                        "level": 3
                    },
                    {
                        "code": 610922,
                        "areaName": "石泉县",
                        "parent_code": 610900,
                        "level": 3
                    },
                    {
                        "code": 610923,
                        "areaName": "宁陕县",
                        "parent_code": 610900,
                        "level": 3
                    },
                    {
                        "code": 610924,
                        "areaName": "紫阳县",
                        "parent_code": 610900,
                        "level": 3
                    },
                    {
                        "code": 610925,
                        "areaName": "岚皋县",
                        "parent_code": 610900,
                        "level": 3
                    },
                    {
                        "code": 610926,
                        "areaName": "平利县",
                        "parent_code": 610900,
                        "level": 3
                    },
                    {
                        "code": 610927,
                        "areaName": "镇坪县",
                        "parent_code": 610900,
                        "level": 3
                    },
                    {
                        "code": 610928,
                        "areaName": "旬阳县",
                        "parent_code": 610900,
                        "level": 3
                    },
                    {
                        "code": 610929,
                        "areaName": "白河县",
                        "parent_code": 610900,
                        "level": 3
                    },
                    {
                        "code": 610930,
                        "areaName": "安康市",
                        "parent_code": 610900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 611000,
                "areaName": "商洛市",
                "parent_code": 610000,
                "level": 2,
                "children": [
                    {
                        "code": 611002,
                        "areaName": "商州区",
                        "parent_code": 611000,
                        "level": 3
                    },
                    {
                        "code": 611021,
                        "areaName": "洛南县",
                        "parent_code": 611000,
                        "level": 3
                    },
                    {
                        "code": 611022,
                        "areaName": "丹凤县",
                        "parent_code": 611000,
                        "level": 3
                    },
                    {
                        "code": 611023,
                        "areaName": "商南县",
                        "parent_code": 611000,
                        "level": 3
                    },
                    {
                        "code": 611024,
                        "areaName": "山阳县",
                        "parent_code": 611000,
                        "level": 3
                    },
                    {
                        "code": 611025,
                        "areaName": "镇安县",
                        "parent_code": 611000,
                        "level": 3
                    },
                    {
                        "code": 611026,
                        "areaName": "柞水县",
                        "parent_code": 611000,
                        "level": 3
                    },
                    {
                        "code": 611027,
                        "areaName": "商洛市",
                        "parent_code": 611000,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 620000,
        "areaName": "甘肃省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 620100,
                "areaName": "兰州市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 620102,
                        "areaName": "城关区",
                        "parent_code": 620100,
                        "level": 3
                    },
                    {
                        "code": 620103,
                        "areaName": "七里河区",
                        "parent_code": 620100,
                        "level": 3
                    },
                    {
                        "code": 620104,
                        "areaName": "西固区",
                        "parent_code": 620100,
                        "level": 3
                    },
                    {
                        "code": 620105,
                        "areaName": "安宁区",
                        "parent_code": 620100,
                        "level": 3
                    },
                    {
                        "code": 620111,
                        "areaName": "红古区",
                        "parent_code": 620100,
                        "level": 3
                    },
                    {
                        "code": 620121,
                        "areaName": "永登县",
                        "parent_code": 620100,
                        "level": 3
                    },
                    {
                        "code": 620122,
                        "areaName": "皋兰县",
                        "parent_code": 620100,
                        "level": 3
                    },
                    {
                        "code": 620123,
                        "areaName": "榆中县",
                        "parent_code": 620100,
                        "level": 3
                    },
                    {
                        "code": 620124,
                        "areaName": "兰州市",
                        "parent_code": 620100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 620200,
                "areaName": "嘉峪关市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 620201,
                        "areaName": "嘉峪关市",
                        "parent_code": 620200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 620300,
                "areaName": "金昌市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 620302,
                        "areaName": "金川区",
                        "parent_code": 620300,
                        "level": 3
                    },
                    {
                        "code": 620321,
                        "areaName": "永昌县",
                        "parent_code": 620300,
                        "level": 3
                    },
                    {
                        "code": 620322,
                        "areaName": "金昌市",
                        "parent_code": 620300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 620400,
                "areaName": "白银市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 620402,
                        "areaName": "白银区",
                        "parent_code": 620400,
                        "level": 3
                    },
                    {
                        "code": 620403,
                        "areaName": "平川区",
                        "parent_code": 620400,
                        "level": 3
                    },
                    {
                        "code": 620421,
                        "areaName": "靖远县",
                        "parent_code": 620400,
                        "level": 3
                    },
                    {
                        "code": 620422,
                        "areaName": "会宁县",
                        "parent_code": 620400,
                        "level": 3
                    },
                    {
                        "code": 620423,
                        "areaName": "景泰县",
                        "parent_code": 620400,
                        "level": 3
                    },
                    {
                        "code": 620424,
                        "areaName": "白银市",
                        "parent_code": 620400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 620500,
                "areaName": "天水市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 620502,
                        "areaName": "秦州区",
                        "parent_code": 620500,
                        "level": 3
                    },
                    {
                        "code": 620503,
                        "areaName": "麦积区",
                        "parent_code": 620500,
                        "level": 3
                    },
                    {
                        "code": 620521,
                        "areaName": "清水县",
                        "parent_code": 620500,
                        "level": 3
                    },
                    {
                        "code": 620522,
                        "areaName": "秦安县",
                        "parent_code": 620500,
                        "level": 3
                    },
                    {
                        "code": 620523,
                        "areaName": "甘谷县",
                        "parent_code": 620500,
                        "level": 3
                    },
                    {
                        "code": 620524,
                        "areaName": "武山县",
                        "parent_code": 620500,
                        "level": 3
                    },
                    {
                        "code": 620525,
                        "areaName": "张家川回族自治县",
                        "parent_code": 620500,
                        "level": 3
                    },
                    {
                        "code": 620526,
                        "areaName": "天水市",
                        "parent_code": 620500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 620600,
                "areaName": "武威市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 620602,
                        "areaName": "凉州区",
                        "parent_code": 620600,
                        "level": 3
                    },
                    {
                        "code": 620621,
                        "areaName": "民勤县",
                        "parent_code": 620600,
                        "level": 3
                    },
                    {
                        "code": 620622,
                        "areaName": "古浪县",
                        "parent_code": 620600,
                        "level": 3
                    },
                    {
                        "code": 620623,
                        "areaName": "天祝藏族自治县",
                        "parent_code": 620600,
                        "level": 3
                    },
                    {
                        "code": 620624,
                        "areaName": "武威市",
                        "parent_code": 620600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 620700,
                "areaName": "张掖市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 620702,
                        "areaName": "甘州区",
                        "parent_code": 620700,
                        "level": 3
                    },
                    {
                        "code": 620721,
                        "areaName": "肃南裕固族自治县",
                        "parent_code": 620700,
                        "level": 3
                    },
                    {
                        "code": 620722,
                        "areaName": "民乐县",
                        "parent_code": 620700,
                        "level": 3
                    },
                    {
                        "code": 620723,
                        "areaName": "临泽县",
                        "parent_code": 620700,
                        "level": 3
                    },
                    {
                        "code": 620724,
                        "areaName": "高台县",
                        "parent_code": 620700,
                        "level": 3
                    },
                    {
                        "code": 620725,
                        "areaName": "山丹县",
                        "parent_code": 620700,
                        "level": 3
                    },
                    {
                        "code": 620726,
                        "areaName": "张掖市",
                        "parent_code": 620700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 620800,
                "areaName": "平凉市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 620802,
                        "areaName": "崆峒区",
                        "parent_code": 620800,
                        "level": 3
                    },
                    {
                        "code": 620821,
                        "areaName": "泾川县",
                        "parent_code": 620800,
                        "level": 3
                    },
                    {
                        "code": 620822,
                        "areaName": "灵台县",
                        "parent_code": 620800,
                        "level": 3
                    },
                    {
                        "code": 620823,
                        "areaName": "崇信县",
                        "parent_code": 620800,
                        "level": 3
                    },
                    {
                        "code": 620824,
                        "areaName": "华亭县",
                        "parent_code": 620800,
                        "level": 3
                    },
                    {
                        "code": 620825,
                        "areaName": "庄浪县",
                        "parent_code": 620800,
                        "level": 3
                    },
                    {
                        "code": 620826,
                        "areaName": "静宁县",
                        "parent_code": 620800,
                        "level": 3
                    },
                    {
                        "code": 620827,
                        "areaName": "平凉市",
                        "parent_code": 620800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 620900,
                "areaName": "酒泉市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 620902,
                        "areaName": "肃州区",
                        "parent_code": 620900,
                        "level": 3
                    },
                    {
                        "code": 620921,
                        "areaName": "金塔县",
                        "parent_code": 620900,
                        "level": 3
                    },
                    {
                        "code": 620922,
                        "areaName": "瓜州县",
                        "parent_code": 620900,
                        "level": 3
                    },
                    {
                        "code": 620923,
                        "areaName": "肃北蒙古族自治县",
                        "parent_code": 620900,
                        "level": 3
                    },
                    {
                        "code": 620924,
                        "areaName": "阿克塞哈萨克族自治县",
                        "parent_code": 620900,
                        "level": 3
                    },
                    {
                        "code": 620981,
                        "areaName": "玉门市",
                        "parent_code": 620900,
                        "level": 3
                    },
                    {
                        "code": 620982,
                        "areaName": "敦煌市",
                        "parent_code": 620900,
                        "level": 3
                    },
                    {
                        "code": 620983,
                        "areaName": "酒泉市",
                        "parent_code": 620900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 621000,
                "areaName": "庆阳市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 621002,
                        "areaName": "西峰区",
                        "parent_code": 621000,
                        "level": 3
                    },
                    {
                        "code": 621021,
                        "areaName": "庆城县",
                        "parent_code": 621000,
                        "level": 3
                    },
                    {
                        "code": 621022,
                        "areaName": "环县",
                        "parent_code": 621000,
                        "level": 3
                    },
                    {
                        "code": 621023,
                        "areaName": "华池县",
                        "parent_code": 621000,
                        "level": 3
                    },
                    {
                        "code": 621024,
                        "areaName": "合水县",
                        "parent_code": 621000,
                        "level": 3
                    },
                    {
                        "code": 621025,
                        "areaName": "正宁县",
                        "parent_code": 621000,
                        "level": 3
                    },
                    {
                        "code": 621026,
                        "areaName": "宁县",
                        "parent_code": 621000,
                        "level": 3
                    },
                    {
                        "code": 621027,
                        "areaName": "镇原县",
                        "parent_code": 621000,
                        "level": 3
                    },
                    {
                        "code": 621028,
                        "areaName": "庆阳市",
                        "parent_code": 621000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 621100,
                "areaName": "定西市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 621102,
                        "areaName": "安定区",
                        "parent_code": 621100,
                        "level": 3
                    },
                    {
                        "code": 621121,
                        "areaName": "通渭县",
                        "parent_code": 621100,
                        "level": 3
                    },
                    {
                        "code": 621122,
                        "areaName": "陇西县",
                        "parent_code": 621100,
                        "level": 3
                    },
                    {
                        "code": 621123,
                        "areaName": "渭源县",
                        "parent_code": 621100,
                        "level": 3
                    },
                    {
                        "code": 621124,
                        "areaName": "临洮县",
                        "parent_code": 621100,
                        "level": 3
                    },
                    {
                        "code": 621125,
                        "areaName": "漳县",
                        "parent_code": 621100,
                        "level": 3
                    },
                    {
                        "code": 621126,
                        "areaName": "岷县",
                        "parent_code": 621100,
                        "level": 3
                    },
                    {
                        "code": 621127,
                        "areaName": "定西市",
                        "parent_code": 621100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 621200,
                "areaName": "陇南市",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 621202,
                        "areaName": "武都区",
                        "parent_code": 621200,
                        "level": 3
                    },
                    {
                        "code": 621221,
                        "areaName": "成县",
                        "parent_code": 621200,
                        "level": 3
                    },
                    {
                        "code": 621222,
                        "areaName": "文县",
                        "parent_code": 621200,
                        "level": 3
                    },
                    {
                        "code": 621223,
                        "areaName": "宕昌县",
                        "parent_code": 621200,
                        "level": 3
                    },
                    {
                        "code": 621224,
                        "areaName": "康县",
                        "parent_code": 621200,
                        "level": 3
                    },
                    {
                        "code": 621225,
                        "areaName": "西和县",
                        "parent_code": 621200,
                        "level": 3
                    },
                    {
                        "code": 621226,
                        "areaName": "礼县",
                        "parent_code": 621200,
                        "level": 3
                    },
                    {
                        "code": 621227,
                        "areaName": "徽县",
                        "parent_code": 621200,
                        "level": 3
                    },
                    {
                        "code": 621228,
                        "areaName": "两当县",
                        "parent_code": 621200,
                        "level": 3
                    },
                    {
                        "code": 621229,
                        "areaName": "陇南市",
                        "parent_code": 621200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 622900,
                "areaName": "临夏回族自治州",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 622901,
                        "areaName": "临夏市",
                        "parent_code": 622900,
                        "level": 3
                    },
                    {
                        "code": 622921,
                        "areaName": "临夏县",
                        "parent_code": 622900,
                        "level": 3
                    },
                    {
                        "code": 622922,
                        "areaName": "康乐县",
                        "parent_code": 622900,
                        "level": 3
                    },
                    {
                        "code": 622923,
                        "areaName": "永靖县",
                        "parent_code": 622900,
                        "level": 3
                    },
                    {
                        "code": 622924,
                        "areaName": "广河县",
                        "parent_code": 622900,
                        "level": 3
                    },
                    {
                        "code": 622925,
                        "areaName": "和政县",
                        "parent_code": 622900,
                        "level": 3
                    },
                    {
                        "code": 622926,
                        "areaName": "东乡族自治县",
                        "parent_code": 622900,
                        "level": 3
                    },
                    {
                        "code": 622927,
                        "areaName": "积石山保安族东乡族撒拉族自治县",
                        "parent_code": 622900,
                        "level": 3
                    },
                    {
                        "code": 622928,
                        "areaName": "临夏回族自治州",
                        "parent_code": 622900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 623000,
                "areaName": "甘南藏族自治州",
                "parent_code": 620000,
                "level": 2,
                "children": [
                    {
                        "code": 623001,
                        "areaName": "合作市",
                        "parent_code": 623000,
                        "level": 3
                    },
                    {
                        "code": 623021,
                        "areaName": "临潭县",
                        "parent_code": 623000,
                        "level": 3
                    },
                    {
                        "code": 623022,
                        "areaName": "卓尼县",
                        "parent_code": 623000,
                        "level": 3
                    },
                    {
                        "code": 623023,
                        "areaName": "舟曲县",
                        "parent_code": 623000,
                        "level": 3
                    },
                    {
                        "code": 623024,
                        "areaName": "迭部县",
                        "parent_code": 623000,
                        "level": 3
                    },
                    {
                        "code": 623025,
                        "areaName": "玛曲县",
                        "parent_code": 623000,
                        "level": 3
                    },
                    {
                        "code": 623026,
                        "areaName": "碌曲县",
                        "parent_code": 623000,
                        "level": 3
                    },
                    {
                        "code": 623027,
                        "areaName": "夏河县",
                        "parent_code": 623000,
                        "level": 3
                    },
                    {
                        "code": 623028,
                        "areaName": "甘南藏族自治州",
                        "parent_code": 623000,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 630000,
        "areaName": "青海省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 630100,
                "areaName": "西宁市",
                "parent_code": 630000,
                "level": 2,
                "children": [
                    {
                        "code": 630102,
                        "areaName": "城东区",
                        "parent_code": 630100,
                        "level": 3
                    },
                    {
                        "code": 630103,
                        "areaName": "城中区",
                        "parent_code": 630100,
                        "level": 3
                    },
                    {
                        "code": 630104,
                        "areaName": "城西区",
                        "parent_code": 630100,
                        "level": 3
                    },
                    {
                        "code": 630105,
                        "areaName": "城北区",
                        "parent_code": 630100,
                        "level": 3
                    },
                    {
                        "code": 630121,
                        "areaName": "大通回族土族自治县",
                        "parent_code": 630100,
                        "level": 3
                    },
                    {
                        "code": 630122,
                        "areaName": "湟中县",
                        "parent_code": 630100,
                        "level": 3
                    },
                    {
                        "code": 630123,
                        "areaName": "湟源县",
                        "parent_code": 630100,
                        "level": 3
                    },
                    {
                        "code": 630124,
                        "areaName": "西宁市",
                        "parent_code": 630100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 630200,
                "areaName": "海东市",
                "parent_code": 630000,
                "level": 2,
                "children": [
                    {
                        "code": 630202,
                        "areaName": "乐都区",
                        "parent_code": 630200,
                        "level": 3
                    },
                    {
                        "code": 630221,
                        "areaName": "平安县",
                        "parent_code": 630200,
                        "level": 3
                    },
                    {
                        "code": 630222,
                        "areaName": "民和回族土族自治县",
                        "parent_code": 630200,
                        "level": 3
                    },
                    {
                        "code": 630223,
                        "areaName": "互助土族自治县",
                        "parent_code": 630200,
                        "level": 3
                    },
                    {
                        "code": 630224,
                        "areaName": "化隆回族自治县",
                        "parent_code": 630200,
                        "level": 3
                    },
                    {
                        "code": 630225,
                        "areaName": "循化撒拉族自治县",
                        "parent_code": 630200,
                        "level": 3
                    },
                    {
                        "code": 630226,
                        "areaName": "海东市",
                        "parent_code": 630200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 632200,
                "areaName": "海北藏族自治州",
                "parent_code": 630000,
                "level": 2,
                "children": [
                    {
                        "code": 632221,
                        "areaName": "门源回族自治县",
                        "parent_code": 632200,
                        "level": 3
                    },
                    {
                        "code": 632222,
                        "areaName": "祁连县",
                        "parent_code": 632200,
                        "level": 3
                    },
                    {
                        "code": 632223,
                        "areaName": "海晏县",
                        "parent_code": 632200,
                        "level": 3
                    },
                    {
                        "code": 632224,
                        "areaName": "刚察县",
                        "parent_code": 632200,
                        "level": 3
                    },
                    {
                        "code": 632225,
                        "areaName": "海北藏族自治州",
                        "parent_code": 632200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 632300,
                "areaName": "黄南藏族自治州",
                "parent_code": 630000,
                "level": 2,
                "children": [
                    {
                        "code": 632321,
                        "areaName": "同仁县",
                        "parent_code": 632300,
                        "level": 3
                    },
                    {
                        "code": 632322,
                        "areaName": "尖扎县",
                        "parent_code": 632300,
                        "level": 3
                    },
                    {
                        "code": 632323,
                        "areaName": "泽库县",
                        "parent_code": 632300,
                        "level": 3
                    },
                    {
                        "code": 632324,
                        "areaName": "河南蒙古族自治县",
                        "parent_code": 632300,
                        "level": 3
                    },
                    {
                        "code": 632325,
                        "areaName": "黄南藏族自治州",
                        "parent_code": 632300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 632500,
                "areaName": "海南藏族自治州",
                "parent_code": 630000,
                "level": 2,
                "children": [
                    {
                        "code": 632521,
                        "areaName": "共和县",
                        "parent_code": 632500,
                        "level": 3
                    },
                    {
                        "code": 632522,
                        "areaName": "同德县",
                        "parent_code": 632500,
                        "level": 3
                    },
                    {
                        "code": 632523,
                        "areaName": "贵德县",
                        "parent_code": 632500,
                        "level": 3
                    },
                    {
                        "code": 632524,
                        "areaName": "兴海县",
                        "parent_code": 632500,
                        "level": 3
                    },
                    {
                        "code": 632525,
                        "areaName": "贵南县",
                        "parent_code": 632500,
                        "level": 3
                    },
                    {
                        "code": 632526,
                        "areaName": "海南藏族自治州",
                        "parent_code": 632500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 632600,
                "areaName": "果洛藏族自治州",
                "parent_code": 630000,
                "level": 2,
                "children": [
                    {
                        "code": 632621,
                        "areaName": "玛沁县",
                        "parent_code": 632600,
                        "level": 3
                    },
                    {
                        "code": 632622,
                        "areaName": "班玛县",
                        "parent_code": 632600,
                        "level": 3
                    },
                    {
                        "code": 632623,
                        "areaName": "甘德县",
                        "parent_code": 632600,
                        "level": 3
                    },
                    {
                        "code": 632624,
                        "areaName": "达日县",
                        "parent_code": 632600,
                        "level": 3
                    },
                    {
                        "code": 632625,
                        "areaName": "久治县",
                        "parent_code": 632600,
                        "level": 3
                    },
                    {
                        "code": 632626,
                        "areaName": "玛多县",
                        "parent_code": 632600,
                        "level": 3
                    },
                    {
                        "code": 632627,
                        "areaName": "果洛藏族自治州",
                        "parent_code": 632600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 632700,
                "areaName": "玉树藏族自治州",
                "parent_code": 630000,
                "level": 2,
                "children": [
                    {
                        "code": 632701,
                        "areaName": "玉树市",
                        "parent_code": 632700,
                        "level": 3
                    },
                    {
                        "code": 632722,
                        "areaName": "杂多县",
                        "parent_code": 632700,
                        "level": 3
                    },
                    {
                        "code": 632723,
                        "areaName": "称多县",
                        "parent_code": 632700,
                        "level": 3
                    },
                    {
                        "code": 632724,
                        "areaName": "治多县",
                        "parent_code": 632700,
                        "level": 3
                    },
                    {
                        "code": 632725,
                        "areaName": "囊谦县",
                        "parent_code": 632700,
                        "level": 3
                    },
                    {
                        "code": 632726,
                        "areaName": "曲麻莱县",
                        "parent_code": 632700,
                        "level": 3
                    },
                    {
                        "code": 632727,
                        "areaName": "玉树藏族自治州",
                        "parent_code": 632700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 632800,
                "areaName": "海西蒙古族藏族自治州",
                "parent_code": 630000,
                "level": 2,
                "children": [
                    {
                        "code": 632801,
                        "areaName": "格尔木市",
                        "parent_code": 632800,
                        "level": 3
                    },
                    {
                        "code": 632802,
                        "areaName": "德令哈市",
                        "parent_code": 632800,
                        "level": 3
                    },
                    {
                        "code": 632821,
                        "areaName": "乌兰县",
                        "parent_code": 632800,
                        "level": 3
                    },
                    {
                        "code": 632822,
                        "areaName": "都兰县",
                        "parent_code": 632800,
                        "level": 3
                    },
                    {
                        "code": 632823,
                        "areaName": "天峻县",
                        "parent_code": 632800,
                        "level": 3
                    },
                    {
                        "code": 632824,
                        "areaName": "海西蒙古族藏族自治州",
                        "parent_code": 632800,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 640000,
        "areaName": "宁夏回族自治区",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 640100,
                "areaName": "银川市",
                "parent_code": 640000,
                "level": 2,
                "children": [
                    {
                        "code": 640104,
                        "areaName": "兴庆区",
                        "parent_code": 640100,
                        "level": 3
                    },
                    {
                        "code": 640105,
                        "areaName": "西夏区",
                        "parent_code": 640100,
                        "level": 3
                    },
                    {
                        "code": 640106,
                        "areaName": "金凤区",
                        "parent_code": 640100,
                        "level": 3
                    },
                    {
                        "code": 640121,
                        "areaName": "永宁县",
                        "parent_code": 640100,
                        "level": 3
                    },
                    {
                        "code": 640122,
                        "areaName": "贺兰县",
                        "parent_code": 640100,
                        "level": 3
                    },
                    {
                        "code": 640181,
                        "areaName": "灵武市",
                        "parent_code": 640100,
                        "level": 3
                    },
                    {
                        "code": 640182,
                        "areaName": "银川市",
                        "parent_code": 640100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 640200,
                "areaName": "石嘴山市",
                "parent_code": 640000,
                "level": 2,
                "children": [
                    {
                        "code": 640202,
                        "areaName": "大武口区",
                        "parent_code": 640200,
                        "level": 3
                    },
                    {
                        "code": 640205,
                        "areaName": "惠农区",
                        "parent_code": 640200,
                        "level": 3
                    },
                    {
                        "code": 640221,
                        "areaName": "平罗县",
                        "parent_code": 640200,
                        "level": 3
                    },
                    {
                        "code": 640222,
                        "areaName": "石嘴山市",
                        "parent_code": 640200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 640300,
                "areaName": "吴忠市",
                "parent_code": 640000,
                "level": 2,
                "children": [
                    {
                        "code": 640302,
                        "areaName": "利通区",
                        "parent_code": 640300,
                        "level": 3
                    },
                    {
                        "code": 640303,
                        "areaName": "红寺堡区",
                        "parent_code": 640300,
                        "level": 3
                    },
                    {
                        "code": 640323,
                        "areaName": "盐池县",
                        "parent_code": 640300,
                        "level": 3
                    },
                    {
                        "code": 640324,
                        "areaName": "同心县",
                        "parent_code": 640300,
                        "level": 3
                    },
                    {
                        "code": 640381,
                        "areaName": "青铜峡市",
                        "parent_code": 640300,
                        "level": 3
                    },
                    {
                        "code": 640382,
                        "areaName": "吴忠市",
                        "parent_code": 640300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 640400,
                "areaName": "固原市",
                "parent_code": 640000,
                "level": 2,
                "children": [
                    {
                        "code": 640402,
                        "areaName": "原州区",
                        "parent_code": 640400,
                        "level": 3
                    },
                    {
                        "code": 640422,
                        "areaName": "西吉县",
                        "parent_code": 640400,
                        "level": 3
                    },
                    {
                        "code": 640423,
                        "areaName": "隆德县",
                        "parent_code": 640400,
                        "level": 3
                    },
                    {
                        "code": 640424,
                        "areaName": "泾源县",
                        "parent_code": 640400,
                        "level": 3
                    },
                    {
                        "code": 640425,
                        "areaName": "彭阳县",
                        "parent_code": 640400,
                        "level": 3
                    },
                    {
                        "code": 640426,
                        "areaName": "固原市",
                        "parent_code": 640400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 640500,
                "areaName": "中卫市",
                "parent_code": 640000,
                "level": 2,
                "children": [
                    {
                        "code": 640502,
                        "areaName": "沙坡头区",
                        "parent_code": 640500,
                        "level": 3
                    },
                    {
                        "code": 640521,
                        "areaName": "中宁县",
                        "parent_code": 640500,
                        "level": 3
                    },
                    {
                        "code": 640522,
                        "areaName": "海原县",
                        "parent_code": 640500,
                        "level": 3
                    },
                    {
                        "code": 640523,
                        "areaName": "中卫市",
                        "parent_code": 640500,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 650000,
        "areaName": "新疆维吾尔自治区",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 650100,
                "areaName": "乌鲁木齐市",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 650102,
                        "areaName": "天山区",
                        "parent_code": 650100,
                        "level": 3
                    },
                    {
                        "code": 650103,
                        "areaName": "沙依巴克区",
                        "parent_code": 650100,
                        "level": 3
                    },
                    {
                        "code": 650104,
                        "areaName": "新市区",
                        "parent_code": 650100,
                        "level": 3
                    },
                    {
                        "code": 650105,
                        "areaName": "水磨沟区",
                        "parent_code": 650100,
                        "level": 3
                    },
                    {
                        "code": 650106,
                        "areaName": "头屯河区",
                        "parent_code": 650100,
                        "level": 3
                    },
                    {
                        "code": 650107,
                        "areaName": "达坂城区",
                        "parent_code": 650100,
                        "level": 3
                    },
                    {
                        "code": 650109,
                        "areaName": "米东区",
                        "parent_code": 650100,
                        "level": 3
                    },
                    {
                        "code": 650121,
                        "areaName": "乌鲁木齐县",
                        "parent_code": 650100,
                        "level": 3
                    },
                    {
                        "code": 650122,
                        "areaName": "乌鲁木齐市",
                        "parent_code": 650100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 650200,
                "areaName": "克拉玛依市",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 650202,
                        "areaName": "独山子区",
                        "parent_code": 650200,
                        "level": 3
                    },
                    {
                        "code": 650203,
                        "areaName": "克拉玛依区",
                        "parent_code": 650200,
                        "level": 3
                    },
                    {
                        "code": 650204,
                        "areaName": "白碱滩区",
                        "parent_code": 650200,
                        "level": 3
                    },
                    {
                        "code": 650205,
                        "areaName": "乌尔禾区",
                        "parent_code": 650200,
                        "level": 3
                    },
                    {
                        "code": 650206,
                        "areaName": "克拉玛依市",
                        "parent_code": 650200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 652100,
                "areaName": "吐鲁番地区",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 652101,
                        "areaName": "吐鲁番市",
                        "parent_code": 652100,
                        "level": 3
                    },
                    {
                        "code": 652122,
                        "areaName": "鄯善县",
                        "parent_code": 652100,
                        "level": 3
                    },
                    {
                        "code": 652123,
                        "areaName": "托克逊县",
                        "parent_code": 652100,
                        "level": 3
                    },
                    {
                        "code": 652124,
                        "areaName": "吐鲁番地区",
                        "parent_code": 652100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 652200,
                "areaName": "哈密地区",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 652201,
                        "areaName": "哈密市",
                        "parent_code": 652200,
                        "level": 3
                    },
                    {
                        "code": 652222,
                        "areaName": "巴里坤哈萨克自治县",
                        "parent_code": 652200,
                        "level": 3
                    },
                    {
                        "code": 652223,
                        "areaName": "伊吾县",
                        "parent_code": 652200,
                        "level": 3
                    },
                    {
                        "code": 652224,
                        "areaName": "哈密地区",
                        "parent_code": 652200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 652300,
                "areaName": "昌吉回族自治州",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 652301,
                        "areaName": "昌吉市",
                        "parent_code": 652300,
                        "level": 3
                    },
                    {
                        "code": 652302,
                        "areaName": "阜康市",
                        "parent_code": 652300,
                        "level": 3
                    },
                    {
                        "code": 652323,
                        "areaName": "呼图壁县",
                        "parent_code": 652300,
                        "level": 3
                    },
                    {
                        "code": 652324,
                        "areaName": "玛纳斯县",
                        "parent_code": 652300,
                        "level": 3
                    },
                    {
                        "code": 652325,
                        "areaName": "奇台县",
                        "parent_code": 652300,
                        "level": 3
                    },
                    {
                        "code": 652327,
                        "areaName": "吉木萨尔县",
                        "parent_code": 652300,
                        "level": 3
                    },
                    {
                        "code": 652328,
                        "areaName": "木垒哈萨克自治县",
                        "parent_code": 652300,
                        "level": 3
                    },
                    {
                        "code": 652329,
                        "areaName": "昌吉回族自治州",
                        "parent_code": 652300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 652700,
                "areaName": "博尔塔拉蒙古自治州",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 652701,
                        "areaName": "博乐市",
                        "parent_code": 652700,
                        "level": 3
                    },
                    {
                        "code": 652702,
                        "areaName": "阿拉山口市",
                        "parent_code": 652700,
                        "level": 3
                    },
                    {
                        "code": 652722,
                        "areaName": "精河县",
                        "parent_code": 652700,
                        "level": 3
                    },
                    {
                        "code": 652723,
                        "areaName": "温泉县",
                        "parent_code": 652700,
                        "level": 3
                    },
                    {
                        "code": 652724,
                        "areaName": "博尔塔拉蒙古自治州",
                        "parent_code": 652700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 652800,
                "areaName": "巴音郭楞蒙古自治州",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 652801,
                        "areaName": "库尔勒市",
                        "parent_code": 652800,
                        "level": 3
                    },
                    {
                        "code": 652822,
                        "areaName": "轮台县",
                        "parent_code": 652800,
                        "level": 3
                    },
                    {
                        "code": 652823,
                        "areaName": "尉犁县",
                        "parent_code": 652800,
                        "level": 3
                    },
                    {
                        "code": 652824,
                        "areaName": "若羌县",
                        "parent_code": 652800,
                        "level": 3
                    },
                    {
                        "code": 652825,
                        "areaName": "且末县",
                        "parent_code": 652800,
                        "level": 3
                    },
                    {
                        "code": 652826,
                        "areaName": "焉耆回族自治县",
                        "parent_code": 652800,
                        "level": 3
                    },
                    {
                        "code": 652827,
                        "areaName": "和静县",
                        "parent_code": 652800,
                        "level": 3
                    },
                    {
                        "code": 652828,
                        "areaName": "和硕县",
                        "parent_code": 652800,
                        "level": 3
                    },
                    {
                        "code": 652829,
                        "areaName": "博湖县",
                        "parent_code": 652800,
                        "level": 3
                    },
                    {
                        "code": 652830,
                        "areaName": "巴音郭楞蒙古自治州",
                        "parent_code": 652800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 652900,
                "areaName": "阿克苏地区",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 652901,
                        "areaName": "阿克苏市",
                        "parent_code": 652900,
                        "level": 3
                    },
                    {
                        "code": 652922,
                        "areaName": "温宿县",
                        "parent_code": 652900,
                        "level": 3
                    },
                    {
                        "code": 652923,
                        "areaName": "库车县",
                        "parent_code": 652900,
                        "level": 3
                    },
                    {
                        "code": 652924,
                        "areaName": "沙雅县",
                        "parent_code": 652900,
                        "level": 3
                    },
                    {
                        "code": 652925,
                        "areaName": "新和县",
                        "parent_code": 652900,
                        "level": 3
                    },
                    {
                        "code": 652926,
                        "areaName": "拜城县",
                        "parent_code": 652900,
                        "level": 3
                    },
                    {
                        "code": 652927,
                        "areaName": "乌什县",
                        "parent_code": 652900,
                        "level": 3
                    },
                    {
                        "code": 652928,
                        "areaName": "阿瓦提县",
                        "parent_code": 652900,
                        "level": 3
                    },
                    {
                        "code": 652929,
                        "areaName": "柯坪县",
                        "parent_code": 652900,
                        "level": 3
                    },
                    {
                        "code": 652930,
                        "areaName": "阿克苏地区",
                        "parent_code": 652900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 653000,
                "areaName": "克孜勒苏柯尔克孜自治州",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 653001,
                        "areaName": "阿图什市",
                        "parent_code": 653000,
                        "level": 3
                    },
                    {
                        "code": 653022,
                        "areaName": "阿克陶县",
                        "parent_code": 653000,
                        "level": 3
                    },
                    {
                        "code": 653023,
                        "areaName": "阿合奇县",
                        "parent_code": 653000,
                        "level": 3
                    },
                    {
                        "code": 653024,
                        "areaName": "乌恰县",
                        "parent_code": 653000,
                        "level": 3
                    },
                    {
                        "code": 653025,
                        "areaName": "克孜勒苏柯尔克孜自治州",
                        "parent_code": 653000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 653100,
                "areaName": "喀什地区",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 653101,
                        "areaName": "喀什市",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653121,
                        "areaName": "疏附县",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653122,
                        "areaName": "疏勒县",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653123,
                        "areaName": "英吉沙县",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653124,
                        "areaName": "泽普县",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653125,
                        "areaName": "莎车县",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653126,
                        "areaName": "叶城县",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653127,
                        "areaName": "麦盖提县",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653128,
                        "areaName": "岳普湖县",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653129,
                        "areaName": "伽师县",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653130,
                        "areaName": "巴楚县",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653131,
                        "areaName": "塔什库尔干塔吉克自治县",
                        "parent_code": 653100,
                        "level": 3
                    },
                    {
                        "code": 653132,
                        "areaName": "喀什地区",
                        "parent_code": 653100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 653200,
                "areaName": "和田地区",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 653201,
                        "areaName": "和田市",
                        "parent_code": 653200,
                        "level": 3
                    },
                    {
                        "code": 653221,
                        "areaName": "和田县",
                        "parent_code": 653200,
                        "level": 3
                    },
                    {
                        "code": 653222,
                        "areaName": "墨玉县",
                        "parent_code": 653200,
                        "level": 3
                    },
                    {
                        "code": 653223,
                        "areaName": "皮山县",
                        "parent_code": 653200,
                        "level": 3
                    },
                    {
                        "code": 653224,
                        "areaName": "洛浦县",
                        "parent_code": 653200,
                        "level": 3
                    },
                    {
                        "code": 653225,
                        "areaName": "策勒县",
                        "parent_code": 653200,
                        "level": 3
                    },
                    {
                        "code": 653226,
                        "areaName": "于田县",
                        "parent_code": 653200,
                        "level": 3
                    },
                    {
                        "code": 653227,
                        "areaName": "民丰县",
                        "parent_code": 653200,
                        "level": 3
                    },
                    {
                        "code": 653228,
                        "areaName": "和田地区",
                        "parent_code": 653200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 654000,
                "areaName": "伊犁哈萨克自治州",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 654002,
                        "areaName": "伊宁市",
                        "parent_code": 654000,
                        "level": 3
                    },
                    {
                        "code": 654003,
                        "areaName": "奎屯市",
                        "parent_code": 654000,
                        "level": 3
                    },
                    {
                        "code": 654021,
                        "areaName": "伊宁县",
                        "parent_code": 654000,
                        "level": 3
                    },
                    {
                        "code": 654022,
                        "areaName": "察布查尔锡伯自治县",
                        "parent_code": 654000,
                        "level": 3
                    },
                    {
                        "code": 654023,
                        "areaName": "霍城县",
                        "parent_code": 654000,
                        "level": 3
                    },
                    {
                        "code": 654024,
                        "areaName": "巩留县",
                        "parent_code": 654000,
                        "level": 3
                    },
                    {
                        "code": 654025,
                        "areaName": "新源县",
                        "parent_code": 654000,
                        "level": 3
                    },
                    {
                        "code": 654026,
                        "areaName": "昭苏县",
                        "parent_code": 654000,
                        "level": 3
                    },
                    {
                        "code": 654027,
                        "areaName": "特克斯县",
                        "parent_code": 654000,
                        "level": 3
                    },
                    {
                        "code": 654028,
                        "areaName": "尼勒克县",
                        "parent_code": 654000,
                        "level": 3
                    },
                    {
                        "code": 654029,
                        "areaName": "伊犁哈萨克自治州",
                        "parent_code": 654000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 654200,
                "areaName": "塔城地区",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 654201,
                        "areaName": "塔城市",
                        "parent_code": 654200,
                        "level": 3
                    },
                    {
                        "code": 654202,
                        "areaName": "乌苏市",
                        "parent_code": 654200,
                        "level": 3
                    },
                    {
                        "code": 654221,
                        "areaName": "额敏县",
                        "parent_code": 654200,
                        "level": 3
                    },
                    {
                        "code": 654223,
                        "areaName": "沙湾县",
                        "parent_code": 654200,
                        "level": 3
                    },
                    {
                        "code": 654224,
                        "areaName": "托里县",
                        "parent_code": 654200,
                        "level": 3
                    },
                    {
                        "code": 654225,
                        "areaName": "裕民县",
                        "parent_code": 654200,
                        "level": 3
                    },
                    {
                        "code": 654226,
                        "areaName": "和布克赛尔蒙古自治县",
                        "parent_code": 654200,
                        "level": 3
                    },
                    {
                        "code": 654227,
                        "areaName": "塔城地区",
                        "parent_code": 654200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 654300,
                "areaName": "阿勒泰地区",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 654301,
                        "areaName": "阿勒泰市",
                        "parent_code": 654300,
                        "level": 3
                    },
                    {
                        "code": 654321,
                        "areaName": "布尔津县",
                        "parent_code": 654300,
                        "level": 3
                    },
                    {
                        "code": 654322,
                        "areaName": "富蕴县",
                        "parent_code": 654300,
                        "level": 3
                    },
                    {
                        "code": 654323,
                        "areaName": "福海县",
                        "parent_code": 654300,
                        "level": 3
                    },
                    {
                        "code": 654324,
                        "areaName": "哈巴河县",
                        "parent_code": 654300,
                        "level": 3
                    },
                    {
                        "code": 654325,
                        "areaName": "青河县",
                        "parent_code": 654300,
                        "level": 3
                    },
                    {
                        "code": 654326,
                        "areaName": "吉木乃县",
                        "parent_code": 654300,
                        "level": 3
                    },
                    {
                        "code": 654327,
                        "areaName": "阿勒泰地区",
                        "parent_code": 654300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 659000,
                "areaName": "自治区直辖县级行政区划",
                "parent_code": 650000,
                "level": 2,
                "children": [
                    {
                        "code": 659001,
                        "areaName": "石河子市",
                        "parent_code": 659000,
                        "level": 3
                    },
                    {
                        "code": 659002,
                        "areaName": "阿拉尔市",
                        "parent_code": 659000,
                        "level": 3
                    },
                    {
                        "code": 659003,
                        "areaName": "图木舒克市",
                        "parent_code": 659000,
                        "level": 3
                    },
                    {
                        "code": 659004,
                        "areaName": "五家渠市",
                        "parent_code": 659000,
                        "level": 3
                    },
                    {
                        "code": 659005,
                        "areaName": "自治区直辖县级行政区划",
                        "parent_code": 659000,
                        "level": 3
                    }
                ]
            }
        ]
    },
    {
        "code": 710000,
        "areaName": "台湾省",
        "parent_code": 0,
        "level": 1,
        "children": [
            {
                "code": 710100,
                "areaName": "台北",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 710101,
                        "areaName": "台北",
                        "parent_code": 710100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 710200,
                "areaName": "澎湖",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 710201,
                        "areaName": "澎湖",
                        "parent_code": 710200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 710300,
                "areaName": "台南",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 710301,
                        "areaName": "台南",
                        "parent_code": 710300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 710400,
                "areaName": "台中",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 710401,
                        "areaName": "台中",
                        "parent_code": 710400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 710500,
                "areaName": "高雄",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 710501,
                        "areaName": "高雄",
                        "parent_code": 710500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 710600,
                "areaName": "屏东",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 710601,
                        "areaName": "屏东",
                        "parent_code": 710600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 710700,
                "areaName": "南投",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 710701,
                        "areaName": "南投",
                        "parent_code": 710700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 710800,
                "areaName": "云林",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 710801,
                        "areaName": "云林",
                        "parent_code": 710800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 710900,
                "areaName": "新竹",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 710901,
                        "areaName": "新竹",
                        "parent_code": 710900,
                        "level": 3
                    }
                ]
            },
            {
                "code": 711000,
                "areaName": "彰化",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 711001,
                        "areaName": "彰化",
                        "parent_code": 711000,
                        "level": 3
                    }
                ]
            },
            {
                "code": 711100,
                "areaName": "苗栗",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 711101,
                        "areaName": "苗栗",
                        "parent_code": 711100,
                        "level": 3
                    }
                ]
            },
            {
                "code": 711200,
                "areaName": "嘉义",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 711201,
                        "areaName": "嘉义",
                        "parent_code": 711200,
                        "level": 3
                    }
                ]
            },
            {
                "code": 711300,
                "areaName": "花莲",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 711301,
                        "areaName": "花莲",
                        "parent_code": 711300,
                        "level": 3
                    }
                ]
            },
            {
                "code": 711400,
                "areaName": "桃园",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 711401,
                        "areaName": "桃园",
                        "parent_code": 711400,
                        "level": 3
                    }
                ]
            },
            {
                "code": 711500,
                "areaName": "宜兰",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 711501,
                        "areaName": "宜兰",
                        "parent_code": 711500,
                        "level": 3
                    }
                ]
            },
            {
                "code": 711600,
                "areaName": "台东",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 711601,
                        "areaName": "台东",
                        "parent_code": 711600,
                        "level": 3
                    }
                ]
            },
            {
                "code": 711700,
                "areaName": "金门",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 711701,
                        "areaName": "金门",
                        "parent_code": 711700,
                        "level": 3
                    }
                ]
            },
            {
                "code": 711800,
                "areaName": "马祖",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 711801,
                        "areaName": "马祖",
                        "parent_code": 711800,
                        "level": 3
                    }
                ]
            },
            {
                "code": 711900,
                "areaName": "基隆",
                "parent_code": 710000,
                "level": 2,
                "children": [
                    {
                        "code": 711901,
                        "areaName": "基隆",
                        "parent_code": 711900,
                        "level": 3
                    }
                ]
            }
        ]
    }
]