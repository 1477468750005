<template>
    <div class="loanInfo">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-tabs v-model="activeName" type="card" @tab-click="handleTab">
                <!-- 贷款记录 -->
                <el-tab-pane label="贷款记录" name="1">
                    <el-table
                        ref="loanTable"
                        :data="loanTable"
                        size="mini"
                        border
                        style="width: 100%"
                        v-loading="loanLoading"
                    >
                        <!-- id -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            fixed
                            prop="id"
                            label="序号"
                            min-width="80"
                        ></el-table-column>
                        <!-- 贷款订单号 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="order_num"
                            label="贷款订单号"
                            min-width="220"
                        ></el-table-column>
                        <!-- 借款代理商名称 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="borrow_name"
                            label="借款代理商名称"
                            min-width="150"
                        ></el-table-column>
                        <!-- 借款代理商账号 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="borrow_phone"
                            label="借款代理商账号"
                            min-width="100"
                        ></el-table-column>
                        <!-- POS终端类型 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="brand_name"
                            label="POS终端类型"
                            min-width="220"
                        ></el-table-column>
                        <!-- 划拨数量（台） -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="transfer_num"
                            label="划拨数量(台)"
                            min-width="100"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    @click="handleLoanNum(scope.row)"
                                    type="text"
                                    size="small"
                                >{{scope.row.transfer_num}}</el-button>
                            </template>
                        </el-table-column>
                        <!-- 订单总金额 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="money"
                            label="订单总金额"
                            min-width="120"
                        ></el-table-column>
                        <!-- 实付金额 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="down_money"
                            label="实付金额"
                            min-width="120"
                        ></el-table-column>
                        <!-- 贷款金额 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="loan_money"
                            label="贷款金额"
                            min-width="120"
                        ></el-table-column>
                        <!-- 出款代理商名称 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="loan_name"
                            label="出款代理商名称"
                            min-width="120"
                        ></el-table-column>
                        <!-- 出款代理商账号 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="loan_phone"
                            label="出款代理商账号"
                            min-width="120"
                        ></el-table-column>
                        <!-- 贷款日期 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="create_time"
                            label="贷款日期"
                            min-width="150"
                        ></el-table-column>
                    </el-table>
                    <!-- 分页部分 -->
                    <paginations
                        :currentPage="loanPage.currentPage"
                        :pageSize="loanPage.pageSize"
                        :total="loanPage.total"
                        @watchCurrent="getLoanCurrent"
                        @watchPageSize="getLoanPageSize"
                    ></paginations>
                </el-tab-pane>
                <!-- 还款记录 -->
                <el-tab-pane label="还款记录" name="2">
                    <el-table
                        ref="repaymentTable"
                        :data="repaymentTable"
                        size="mini"
                        border
                        style="width: 100%"
                        v-loading="repaymentLoading"
                    >
                        <!-- id -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            fixed
                            prop="id"
                            label="序号"
                            min-width="80"
                        ></el-table-column>
                        <!-- 订单号 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="order_num"
                            label="订单号"
                            min-width="220"
                        ></el-table-column>
                        <!-- 终端类型 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="name"
                            label="终端类型"
                            min-width="220"
                        ></el-table-column>
                        <!-- SN码 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="sn"
                            label="SN码"
                            min-width="200"
                        ></el-table-column>
                        <!-- 交易金额 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="transaction_money"
                            label="交易金额"
                            min-width="120"
                        ></el-table-column>
                        <!-- 还款类型 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="repayment_type"
                            label="还款类型"
                            min-width="120"
                            :formatter="activeFormat"
                        ></el-table-column>
                        <!-- 还款金额 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="repayment_money"
                            label="还款金额"
                            min-width="120"
                        ></el-table-column>
                        <!-- 还款时间 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="create_time"
                            label="还款时间"
                            min-width="150"
                        ></el-table-column>
                    </el-table>
                    <!-- 分页部分 -->
                    <paginations
                        :currentPage="repaymentPage.currentPage"
                        :pageSize="repaymentPage.pageSize"
                        :total="repaymentPage.total"
                        @watchCurrent="getRepaymentCurrent"
                        @watchPageSize="getRepaymentPageSize"
                    ></paginations>
                </el-tab-pane>
                <!-- 抵消记录 -->
                <el-tab-pane label="抵消记录" name="3">
                    <el-table
                        ref="offsetTable"
                        :data="offsetTable"
                        size="mini"
                        border
                        style="width: 100%"
                        v-loading="offsetLoading"
                    >
                        <!-- id -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            fixed
                            prop="id"
                            label="序号"
                            min-width="80"
                        ></el-table-column>
                        <!-- 抵消订单号 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="order_num"
                            label="抵消订单号"
                            min-width="220"
                        ></el-table-column>
                        <!-- 抵消主体 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="offset_type_name"
                            label="抵消主体"
                            min-width="220"
                        ></el-table-column>
                        <!-- 终端类型 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="brand_name"
                            label="终端类型"
                            min-width="220"
                        ></el-table-column>
                        <!-- 终端单价 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="terminal_price"
                            label="终端单价"
                            min-width="120"
                        ></el-table-column>
                        <!-- 终端数量 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="terminal_num"
                            label="终端数量"
                            min-width="100"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    @click="handleOffsetNum(scope.row)"
                                    type="text"
                                    size="small"
                                >{{scope.row.terminal_num}}</el-button>
                            </template>
                        </el-table-column>
                        <!-- 抵消金额 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="offset_money"
                            label="抵消金额"
                            min-width="120"
                        ></el-table-column>
                        <!-- 抵消时间 -->
                        <el-table-column
                            header-align="center"
                            align="center"
                            prop="create_time"
                            label="抵消时间"
                            min-width="150"
                        ></el-table-column>
                    </el-table>
                    <!-- 分页部分 -->
                    <paginations
                        :currentPage="offsetPage.currentPage"
                        :pageSize="offsetPage.pageSize"
                        :total="offsetPage.total"
                        @watchCurrent="getOffsetCurrent"
                        @watchPageSize="getOffsetPageSize"
                    ></paginations>
                </el-tab-pane>
            </el-tabs>
        </el-card>
        <terminal-count
            v-if="terminalIsShow"
            :terminalIsShow="terminalIsShow"
            :detail="detail"
            @terminalClose="terminalClose"
        ></terminal-count>
    </div>
</template>

<script>
import paginations from '../../../components/pagination/paginations';
import terminalCount from './UM_terminalCount';
export default {
    name: "UM_loanInfo",
    // loanRecord  贷款记录
    // repaymentRecord  还款记录
    // offsetRecord  抵消记录
    data() {
        return {
            userId: null,
            terminalIsShow: false,
            detail: {
                recordType: null, // 1.抵消记录数量 2.贷款记录数量
                id: null,
                count: null
            },
            activeName: '1', //默认被选中 1贷款记录 2还款记录 3抵消记录
            loanTable: [], //贷款记录列表
            loanLoading: true, //贷款记录loadding
            loanPage: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            repaymentTable: [], //还款记录列表
            repaymentLoading: false, //还款记录loadding
            repaymentPage: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            },
            offsetTable: [], //抵消记录列表
            offsetLoading: false, //抵消记录loadding
            offsetPage: {
                currentPage: 1, //当前页码
                pageSize: 10, //条数
                total: 0  //总数
            }
        }
    },
    mounted() {
        this.userId = this.$route.query.id; //列表带过来的id
        this.getLoanData({
            user_id: this.userId,
            page: 1,
            limit: 10,
        });
    },
    components: {
        paginations,
        terminalCount
    },
    methods: {
        handleInfo() { //查看
        },
        handleLoanNum(row) { //贷款记录划拨数量
            this.terminalIsShow = true;
            this.detail.recordType = 2;
            this.detail.id = row.transfer_id;
            this.detail.count = row.transfer_num;
        },
        handleOffsetNum(row) { //抵消记录终端数量
            this.terminalIsShow = true;
            this.detail.recordType = 1;
            this.detail.id = row.id;
            this.detail.count = row.terminal_num;
        },
        terminalClose() { //关闭弹窗
            this.terminalIsShow = false;
        },
        handleTab(tab) {
            let index = tab.name; //tab的name值
            switch (index) {
                case '1':
                    this.loanLoading = true;
                    this.getLoanData({
                        user_id: this.userId,
                        page: this.loanPage.currentPage,
                        limit: this.loanPage.pageSize
                    })
                    this.$refs.loanTable.doLayout();
                    break;
                case '2':
                    this.repaymentLoading = true;
                    this.getRepaymentData({
                        user_id: this.userId,
                        page: this.repaymentPage.currentPage,
                        limit: this.repaymentPage.pageSize
                    })
                    this.$refs.repaymentTable.doLayout();
                    break;
                default:
                    this.offsetLoading = true;
                    this.getOffsetData({
                        borrow_user_id: this.userId,
                        page: this.offsetPage.currentPage,
                        limit: this.offsetPage.pageSize
                    })
                    this.$refs.offsetTable.doLayout();
                    break;
            }
        },
        //贷款记录分页
        getLoanCurrent(page) {
            // 获取当前页修改的值
            this.loanLoading = true;
            this.loanPage.currentPage = page;
            this.getLoanData({
                user_id: this.userId,
                page: this.loanPage.currentPage,
                limit: this.loanPage.pageSize
            })
        },
        getLoanPageSize(limit) {
            // 获取条数发生改变的值
            this.loanLoading = true;
            this.loanPage.pageSize = limit;
            this.getLoanData({
                user_id: this.userId,
                page: this.loanPage.currentPage,
                limit: limit
            })
        },
        getLoanData(params) {
            this.$api.post("user/loan_user_list", params, res => {
                let data = res.data;
                this.loanPage.total = data.count; //总数
                this.loanTable = data.list;
                //渲染结束后取消加载
                this.loanLoading = false;
            })
        },
        //还款记录分页
        getRepaymentCurrent(page) {
            // 获取当前页修改的值
            this.repaymentLoading = true;
            this.repaymentPage.currentPage = page;
            this.getRepaymentData({
                user_id: this.userId,
                page: this.repaymentPage.currentPage,
                limit: this.repaymentPage.pageSize
            })
        },
        getRepaymentPageSize(limit) {
            // 获取条数发生改变的值
            this.repaymentLoading = true;
            this.repaymentPage.pageSize = limit;
            this.getRepaymentData({
                user_id: this.userId,
                page: this.repaymentPage.currentPage,
                limit: limit
            })
        },
        getRepaymentData(params) {
            this.$api.post("user/repayment_log", params, res => {
                let data = res.data;
                this.repaymentPage.total = data.count; //总数
                this.repaymentTable = data.list;
                //渲染结束后取消加载
                this.repaymentLoading = false;
            })
        },
        activeFormat(row) {
            let type = ''
            switch (row.repayment_type) {
                case 1:
                    type = '交易分润还款';
                    break;

                default:
                    type = '激活奖励还款';
                    break;
            }
            return type;
        },
        //抵消记录
        getOffsetCurrent(page) {
            // 获取当前页修改的值
            this.offsetLoading = true;
            this.offsetPage.currentPage = page;
            this.getOffsetData({
                borrow_user_id: this.userId,
                page: this.offsetPage.currentPage,
                limit: this.offsetPage.pageSize
            })
        },
        getOffsetPageSize(limit) {
            // 获取条数发生改变的值
            this.offsetLoading = true;
            this.offsetPage.pageSize = limit;
            this.getOffsetData({
                borrow_user_id: this.userId,
                page: this.offsetPage.currentPage,
                limit: limit
            })
        },
        getOffsetData(params) {
            this.$api.post("user_offset_log/offset_log_list", params, res => {
                let data = res.data;
                this.offsetPage.total = data.count; //总数
                this.offsetTable = data.list;
                //渲染结束后取消加载
                this.offsetLoading = false;
            })
        }
    }
};
</script>

<style lang="scss" scope>
.loanInfo {
    .el-tabs--card > .el-tabs__header .el-tabs__item {
        font-size: 12px;
    }
}
</style>
