<template>
    <div class="setUserReward">
        <el-dialog :visible="isShowReward" width="70%" @close="handleClose">
            <el-card class="box-card" shadow="hover">
                <div slot="header" class="clearfix">
                    <span
                        style="font-weight:700;font-size:13px"
                    >{{publickData.userName}}"{{publickData.brandName}}终端类型"的平台奖励</span>
                </div>
                <el-form ref="form" :label-position="'left'" :model="userRewardForm">
                    <el-tabs v-model="activeName">
                        <!-- Tab页 -->
                        <!-- v-for="(tabItem,tabIndex) in tabContent" -->
                        <el-tab-pane label="平台奖励" name="platform">
                            <template v-for="(titleItem,titleIndex) in tabContent">
                                <!-- 小标题 -->
                                <div :key="titleIndex">
                                    <div class="type_title">{{titleItem.title}}</div>
                                    <el-form-item
                                        v-for="(item,index) in titleItem.list"
                                        :label="item.title"
                                        :key="index"
                                    >
                                        <el-input
                                            v-model="item.value"
                                            size="medium"
                                            :placeholder="isEdit ==1? item.sub_title : ''"
                                            :disabled="isEdit != 1 || item.is_edit != 1"
                                        ></el-input>
                                    </el-form-item>
                                    <div class="endTime">活动最终截止日期：{{titleItem.cut_off_time}}</div>
                                    <el-divider></el-divider>
                                </div>
                            </template>
                            <el-button
                                @click="handleSubmit(tabContent,1)"
                                type="primary"
                                style="font-size:12px"
                                :disabled="isEdit != 1"
                                v-if="isEdit == 1"
                            >提 交</el-button>
                        </el-tab-pane>
                        <!-- <el-tab-pane label="个人奖励" name="personal">
                            <template v-for="(titleItem,titleIndex) in personalForm.data">
                                小标题
                                <div :key="titleIndex">
                                    <div class="type_title">{{titleItem.title}}</div>
                                    <div v-for="(v,i)  in titleItem.setting" :key="i">
                                        <el-form-item
                                            v-for="(item,index) in v.list"
                                            :label="item.title"
                                            :key="index"
                                        >
                                            <el-input
                                                v-model="item.value"
                                                size="medium"
                                                :placeholder="isEdit ==1? item.sub_title : ''"
                                                :disabled="item.is_edit == 0"
                                            ></el-input>
                                            <div class="endTime">{{item.sub_title}}</div>
                                        </el-form-item>
                                    </div>
                                    <div class="endTime">活动最终截止日期：{{titleItem.cut_off_time}}</div>
                                    <el-divider></el-divider>
                                </div>
                            </template>
                        </el-tab-pane> -->
                    </el-tabs>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "UM_setUserReward",
    props: {
        isShowReward: {
            type: Boolean,
            default: false
        },
        publickData: {
            type: Object
        }
    },
    data() {
        return {
            activeName: 'platform', //platform平台 personal个人
            tabContent: [],
            userRewardForm: {
                user: ''
            },
            cloneData: [],
            isEdit: null,
            personalForm: {
                data: []
            }
        }
    },
    created() {
        let { brandId, userId } = this.publickData;
        this.getPlatform({ brand_id: brandId, ally_user_id: userId });
        // this.getPersonal({ brand_id: brandId, ally_user_id: userId });
    },
    mounted() {
    },
    methods: {
        handleSubmit(val, reward_type) {
            let { brandId, userId } = this.publickData;
            let cloneDatas = this._.mapKeys(this.cloneData, (value) => {
                return value.reward_type;
            });
            let dataSource = cloneDatas[reward_type]; //平台奖励 or other
            let reqList = []; //请求的list
            let reqData = { //请求的数据
                brand_id: brandId,
                ally_user_id: userId
            };
            this._.forEach(val, (v, i) => {
                let dataVal = dataSource.list[i];
                this._.forEach(v.list, (item, index) => {
                    let dataNew = dataVal.list[index];
                    if (item.value != '') {
                        if (dataNew.value !== item.value) { //不一致等于修改过
                            reqList.push(this._.pick(item, ['brand_reward_conf_id', 'value']));
                        }
                    }
                })
            });
            if (!this._.isEmpty(reqList)) { //判断是否为空数组,如为空则不传data
                reqData.data = JSON.stringify(reqList);
            }
            this.$api.post('user_reward/user_reward_update', reqData, res => {
                if (res.code == 0) {
                    this.$message.success(res.msg);
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 5000
                    })
                }
            })
        },
        handleClose() {
            this.$emit('rewardClose');
        },
        getPlatform(params) { //获取平台奖励
            this.$api.post('user_reward/user_reward_list', params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.tabContent = data.list[0].list;
                    this.isEdit = data.is_edit;
                    this.cloneData = this._.cloneDeep(data.list); //存储原始值
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getPersonal(params) { //获取个人奖励
            this.$api.post('user_reward/user_reward_subsidy_list', params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.personalForm.data = data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.setUserReward {
    ::v-deep .el-form-item__label {
        font-size: 12px;
        font-weight: 700;
    }
    ::v-deep .el-dialog {
        height: 80%;
        .el-dialog__body {
            height: 82%;
            overflow: auto;
        }
    }
    .endTime {
        font-size: 12px;
        color: #aaaaaa;
    }
    .el-input {
        width: auto;
    }
    ::v-deep .el-input__inner {
        width: 200px;
    }

    .type_title {
        margin: 10px 0;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #40b8ff;
        padding: 0 0 0 10px;
    }
}
</style>
