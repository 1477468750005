<template>
    <div class="offsetRecord">
        <el-dialog
            title="核销记录"
            width="90%"
            :visible="offsetInfo.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <!-- 表格部分 -->
            <el-card class="box-card" style="margin-top: 20px;" shadow="always">
                <el-table
                    :data="recordList"
                    size="mini"
                    border
                    max-height="400px"
                    style="width: 100%"
                    v-loading="loading"
                >
                    <!-- id -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="id"
                        label="序号"
                        min-width="120"
                    ></el-table-column>
                    <!-- 核销订单号 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="order_num"
                        label="核销订单号"
                        min-width="220"
                    ></el-table-column>
                    <!-- 核销类型 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="type"
                        label="核销类型"
                        min-width="120"
                    >
                        <template slot-scope="scope">
                            <span v-show="scope.row.type == 1">订单核销</span>
                            <span v-show="scope.row.type == 2">本期核销</span>
                        </template>
                    </el-table-column>
                    <!-- 核销总金额 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="write_offs_money"
                        label="核销总金额"
                        min-width="120"
                    ></el-table-column>
                    <!-- 核销时间 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="create_time"
                        label="核销时间"
                        min-width="150"
                    ></el-table-column>
                    <!-- 操作 -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        fixed="right"
                        label="操作"
                        min-width="120"
                    >
                        <template slot-scope="scope">
                            <el-button
                                @click="handleRecordInfo(scope.row)"
                                type="text"
                                size="small"
                            >详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,50,100]"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    :total="total"
                    layout="total, sizes, prev, pager, next, jumper"
                ></el-pagination>
                <offset-record-info
                    v-if="recordInfo.isShow"
                    :recordInfo="recordInfo"
                    @handleClose="handleInfoClose"
                ></offset-record-info>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import offsetRecordInfo from './offsetRecordInfo';
export default {
    name: "offsetRecord",
    props: {
        offsetInfo: {
            type: Object
        }
    },
    data() {
        return {
            recordInfo: {
                isShow: false,
                info: {}
            },
            recordList: [], //列表
            loading: false, //列表loadding
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
        }
    },
    mounted() {
        this.getData({
            page: 1,
            limit: 10
        });
    },
    components: {
        offsetRecordInfo
    },
    methods: {
        handleRecordInfo(row) { //核销详情
            this.recordInfo.isShow = true;
            this.recordInfo.info = row;
        },
        handleInfoClose() { //关闭详情页面
            this.recordInfo.isShow = false;
            this.recordInfo.info = {};
        },
        handleClose() { //关闭
            this.$emit('handleClose');
        },
        //分页
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.getData({
                page: this.currentPage,
                limit: this.pageSize
            });
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.getData({
                page: this.currentPage,
                limit: this.pageSize
            });
        },
        getData(params) { //获取数据
            this.loading = true;
            let reqData = this._.assign({
                user_id: this.offsetInfo.info.borrow_user_id
            }, params);
            this.$api.post("user_installment_loan/get_debt_write_offs_list", reqData, res => {
                if (res.code == 0) {
                    this.recordList = res.data.list;
                    this.total = res.data.count;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped lang="scss">
</style>