<template>
    <div class="editPassword">
        <el-card class="box-card" shadow="hover">
            <el-form :model="passwordForm" label-position="right" label-width="100px">
                <el-form-item label="原密码：">
                    <el-input
                        size="medium"
                        type="password"
                        show-password
                        v-model="passwordForm.old_password"
                        placeholder="请输入原密码"
                    ></el-input>
                </el-form-item>
                <el-form-item label="新密码：">
                    <el-input
                        size="medium"
                        type="password"
                        show-password
                        v-model="passwordForm.password"
                        placeholder="请输入新密码"
                    ></el-input>
                    <span style="font-size:12px;color:#AAAAAA">数字、字母、下划线（6-20）位组成</span>
                </el-form-item>
                <el-form-item label="新密码确认：">
                    <el-input
                        size="medium"
                        type="password"
                        show-password
                        v-model="passwordForm.repassword"
                        placeholder="请确认新密码"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div style="margin-top:20px">
                <el-button
                    type="primary"
                    :loading="loading"
                    style="font-size: 12px;"
                    @click="editPassword(passwordForm)"
                >确认修改</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "editPassword",
    data() {
        return {
            loading: false,
            passwordForm: {
                id: '', //管理员id
                old_password: '', //原密码
                password: '', //新密码
                repassword: '', //确认新密码

            },
        }
    },
    methods: {
        editPassword(passwordForm) {
            let that = this;
            this.loading = true; //防重复
            this.passwordForm.id = sessionStorage.getItem('admin_id'); //动态获取
            this.$api.post('admin/change_password', passwordForm, res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.loading = false; //防重复
                            sessionStorage.clear(); //清缓存 退出登录
                            that.$router.push('/login');
                        }
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                        duration: 2000,
                        onClose() {
                            that.loading = false; //防重复
                        }
                    })
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.editPassword {
    ::v-deep .el-form-item__label {
        font-size: 12px;
        font-weight: 700;
    }
    .el-form {
        width: 500px;
    }
}
</style>
