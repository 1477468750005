<template>
    <div class>
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm">
                <el-form-item>
                    <el-input clearable v-model="searchForm.batch_no" placeholder="请输入批次号查询"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.sn" placeholder="请输入SN查询"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <div class="table-header-title">
                <div>
                    <span>库存总数量：</span>
                    <el-button
                        type="text"
                        @click="handleTerminalInfoOpen({},1,2)"
                    >{{sum.terminal_num}} 台</el-button>
                </div>
                <div>
                    <span>已入库：</span>
                    <el-button
                        type="text"
                        @click="handleTerminalInfoOpen({},3,2)"
                    >{{sum.import_num}} 台</el-button>
                </div>
                <div>
                    <span>未入库：</span>
                    <el-button
                        type="text"
                        @click="handleTerminalInfoOpen({},2,2)"
                    >{{sum.not_import_num}} 台</el-button>
                </div>
            </div>
            <el-table
                :data="tableList"
                size="small"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <!-- 序号 -->
                <el-table-column
                    type="index"
                    header-align="center"
                    align="center"
                    fixed
                    label="序号"
                    width="100"
                    :index="handleTableIndex"
                ></el-table-column>
                <!-- 总数量 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_num"
                    label="总数量"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleTerminalInfoOpen(scope.row,1,1)"
                            type="text"
                            size="small"
                        >{{scope.row.terminal_num}}</el-button>
                    </template>
                </el-table-column>
                <!-- 已入库 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="import_num"
                    label="已入库"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleTerminalInfoOpen(scope.row,3,1)"
                            type="text"
                            size="small"
                        >{{scope.row.import_num}}</el-button>
                    </template>
                </el-table-column>
                <!-- 未入库 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="not_import_num"
                    label="未入库"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            @click="handleTerminalInfoOpen(scope.row,2,1)"
                            type="text"
                            size="small"
                        >{{scope.row.not_import_num}}</el-button>
                    </template>
                </el-table-column>
                <!-- 批次号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="batch_no"
                    label="批次号"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 导入时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="导入时间"
                    min-width="150"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="状态"
                    min-width="150"
                    :formatter="formatStatus"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <!-- 0.未入库 1.已入库 2.部分入库 -->
                        <el-button
                            v-if="scope.row.status == 0 || scope.row.status == 2"
                            @click="handleStorage(scope.row)"
                            type="text"
                            size="small"
                        >入库</el-button>
                        <!-- <el-button
                            v-if="scope.row.status == 0"
                            @click="handleEdit(scope.row)"
                            type="text"
                            size="small"
                        >下载库存数据</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <TotalInventoryTerminal
            v-if="terminalInfo.isShow"
            :terminal-info="terminalInfo"
            @infoClose="handleTerminalInfoClose"
        />
    </div>
</template>

<script>
import TotalInventoryTerminal from './components/TotalInventoryTerminal';
export default {
    data() {
        return {
            searchForm: {
                page: 1,
                limit: 10,
                sn: '', // sn
                batch_no: '' // 批次号
            },
            terminalInfo: {
                isShow: false,
                info: {},
                type: '',
                statisticalType: 1, // 1.列表的 2.所有的
            },
            tableList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false,
            sum: {
                terminal_num: 0, // 库存总数量
                import_num: 0, // 已入库
                not_import_num: 0 // 未入库
            }
        }
    },
    components: {
        TotalInventoryTerminal
    },
    created() {
        this.getData({ //初始化表格数据
            page: 1,
            limit: 10
        })
    },
    methods: {
        handleSearch(searchForm) { //搜索
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleStorage(row) { // 入库
            this.$router.push({ name: 'totalInventoryStorage', params: { id: row.id } })
        },
        handleTerminalInfoOpen(row, type, sType) { // 查看终端明细
            this.terminalInfo.isShow = true;
            this.terminalInfo.info = row;
            this.terminalInfo.type = type;
            this.terminalInfo.statisticalType = sType;
        },
        handleTerminalInfoClose() { // 关闭终端明细
            this.terminalInfo.isShow = false;
            this.terminalInfo.info = {};
        },
        // 分页部分
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("sub_terminal_in_stock_log/get_list", params, res => {
                if (res.code == 0) {
                    let { count, list, terminal_num, import_num, not_import_num } = res.data;
                    this.total = count; //总数
                    this.tableList = list;
                    this.sum = {
                        terminal_num,
                        import_num,
                        not_import_num,
                    }
                    //渲染结束后取消加载
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        formatStatus(row) {
            let status = '';
            switch (row.status) {
                case 0:
                    status = '未入库';
                    break;
                case 1:
                    status = '已入库';
                    break;
                case 2:
                    status = '部分入库';
                    break;
            }
            return status;
        },
        handleTableIndex(index) { // 处理序号
            return (this.currentPage - 1) * this.pageSize + index + 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.table-header-title {
    font-size: 12px;
    div {
        margin: 0 10px 0 0;
        display: inline-block;
    }
}
</style>
