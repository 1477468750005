<template>
    <div class="activityCenterList">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-select
                        v-model="searchForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="searchForm.type" clearable placeholder="请选择活动类型">
                        <el-option label="风控(交易量达标)规则" value="1"></el-option>
                        <el-option label="交易量达标奖励" value="2"></el-option>
                        <el-option label="交易量阶梯奖励" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select size v-model="searchForm.status" clearable placeholder="请选择达标状态">
                        <el-option label="进行中" value="1"></el-option>
                        <el-option label="已达标" value="2"></el-option>
                        <el-option label="未达标" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.terminal_sn" placeholder="请输入终端SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.phone" placeholder="请输入终端归属代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size clearable v-model="searchForm.name" placeholder="请输入终端归属代理商姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <el-table :data="centerList" size="mini" border style="width: 100%" v-loading="loading">
                <!-- 序号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="150"
                ></el-table-column>
                <!-- 终端SN -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_sn"
                    label="终端SN"
                    min-width="200"
                ></el-table-column>
                <!-- 商户名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="merchant_name"
                    label="商户名称"
                    min-width="150"
                ></el-table-column>
                <!-- 终端归属代理商姓名 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="终端归属代理商姓名"
                    min-width="120"
                ></el-table-column>
                <!-- 终端归属代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="终端归属代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 活动类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="type_name"
                    label="活动类型"
                    min-width="150"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status_name"
                    label="状态"
                    min-width="100"
                ></el-table-column>
                <!-- 活动结束时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="cut_off_time"
                    label="活动结束时间"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row)" type="text" size="small">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <center-info v-if="info.isShow" :info="info" @infoClose="handleCloseInfo"></center-info>
    </div>
</template>

<script>
import centerInfo from './components/activityCenterInfo';
export default {
    name: "activityCenterList",
    data() {
        return {
            info: {
                isShow: false,
                row: {}
            },
            searchForm: {
                page: 1,
                limit: 10
            },
            rangeDate: '',
            brandList: [],
            centerList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: false
        }
    },
    mounted() {
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$message.success(res.msg);
            }
        });
        this.getData({
            page: 1,
            limit: 10
        })
    },
    components: {
        centerInfo
    },
    methods: {
        handleSearch(searchFrom) { //搜索
            this.currentPage = 1;
            searchFrom.page = 1;
            this.getData(searchFrom);
        },
        handleInfo(row) { //详情
            this.info.isShow = true;
            this.info.row = row;
        },
        handleCloseInfo() { //关闭详情
            this.info.isShow = false;
            this.info.row = {};
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("activity_merchant_status/get_activity_merchant_status_list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.centerList = res.data.list;
                    //渲染结束后取消加载
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false;
            })
        }
    }
}
</script>

<style scoped lang="scss">
</style>