<template>
	<!-- 分组管理 - 添加/编辑 -->
	<div class="InspectionFeeListImport">
		<el-dialog
			title="导入"
			width="600px"
			:visible="higher.isShow"
			:close-on-click-modal="false"
			@close="handleClose"
		>
			<el-card>
				<el-form label-position="left" label-width="60px">
					<el-form-item label="导入类型">
						<el-select
							style="width: 360px"
							v-model="field.type"
							placeholder="请选择导入类型"
						>
							<el-option
								label="未激活扣款"
								:value="1"
							></el-option>
							<el-option
								label="伪激活扣款"
								:value="2"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="上传附件">
						<el-upload
							class="upload-demo"
							ref="upload"
							:drag="true"
							:limit="1"
							:show-file-list="true"
							accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
							:on-change="beforeUpload"
							:auto-upload="false"
							:on-success="handleUploadSuccess"
							:on-error="handleUploadError"
							:on-remove="handleRemove"
							:action="action"
							:headers="headers"
							:data="{ type: field.type }"
						>
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">
								<em>点击选择文件</em>
							</div>
							<div class="el-upload__tip" slot="tip">
								<span style="color:#D9001B">
									*请按照模版规范进行导入
									<i class="el-icon-right"></i>
								</span>
								<el-button
									type="text"
									@click="handleDownload(templateUrl)"
								>
									下载模版
								</el-button>
								<div style="color:#D9001B">
									温馨提示：{{ limitHint }}
								</div>
							</div>
						</el-upload>
					</el-form-item>
					<el-form-item>
						<el-button
							:disabled="field.type == '' || fileMarker == ''"
							:loading="loading"
							type="primary"
							style="margin-top:10px;"
							@click="handleSubmit"
						>
							点击提交
						</el-button>
					</el-form-item>
				</el-form>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "InspectionFeeListImport",
	props: {
		higher: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			action: "", // 上传地址
			templateUrl: "", // 模版
			limitHint: "", // 提示
			fileMarker: "", // 文件标识
			field: {
				type: "", // 导入类型
			},
			headers: {
				"Organization-Code": location.hostname.split(".")[0],
				Authorization: "Bearer" + sessionStorage.getItem("token"),
			},
			shadeLoading: null,
			loading: false,
			timer: null,
		};
	},
	created() {
		const BASE_URL = `${this.$api.reqUrl}`;
		this.action = BASE_URL + "import/import_data";
		this.getTemplate();
	},
	methods: {
		handleSubmit() {
			if (this.fileMarker != "") {
				this.loading = true;
				let count = 60;
				this.shadeLoading = this.$loading({
					lock: true,
					text: `数据正在努力处理中,请勿关闭页面...60s`,
					// spinner: 'el-icon-loading',
					background: "rgba(0, 0, 0, 0.1)",
					customClass: "customLoing",
				});
				this.timer = setInterval(() => {
					count--;
					if (count == 0) {
						this.shadeLoading.close();
						clearInterval(this.timer);
					}
					this.shadeLoading.setText(
						`数据正在努力处理中,请勿关闭页面...${count}s`
					);
				}, 1000);
				this.$refs.upload.submit();
			} else {
				this.$message.error("请选择文件");
			}
		},
		beforeUpload(file) {
			this.fileMarker = file.name; // 标记--提交防重复使用
		},
		handleUploadSuccess(res) {
			const { code, msg } = res;
			let _this = this;
			if (code == 0) {
				//上传成功
				this.$message({
					type: "success",
					message: msg,
					duration: 1500,
					onClose() {
						_this.loading = false;
						clearInterval(_this.timer);
						_this.shadeLoading.close();
						_this.$refs.upload.clearFiles();
						_this.fileMarker = ""; // 标记--提交防重复使用
					},
				});
			} else {
				//上传失败
				this.$message({
					type: "error",
					message: msg,
					duration: 1500,
					onClose() {
						_this.loading = false;
						clearInterval(_this.timer);
						_this.shadeLoading.close();
						_this.$refs.upload.clearFiles();
						_this.fileMarker = ""; // 标记--提交防重复使用
					},
				});
			}
		},
		handleUploadError() {
			//上传报错
			this.$message.error("上传错误");
		},
		handleRemove() {
			// 触发清空方法重置标记
			this.fileMarker = "";
		},
		getTemplate() {
			// 获取下载模版
			this.$api.post(
				"import/get_import_limit_template",
				{ type: 1 },
				(res) => {
					if (res.code == 0) {
						const { limit_text, template_url } = res.data;
						this.limitHint = limit_text;
						this.templateUrl = template_url;
					} else {
						this.$message.error(res.msg);
					}
				}
			);
		},
		handleDownload(url) {
			// 下载模版(通用)
			window.open(url);
		},
		handleClose(ref) {
			this.$emit("onClose", ref);
		},
	},
};
</script>

<style scoped lang="scss"></style>
