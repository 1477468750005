<template>
    <div class="MAM_merchantRate">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <template>
                <el-tabs v-model="activeName">
                    <el-tab-pane
                        v-for="(item,index) in tabList"
                        :label="item.title"
                        :name="String(item.pay_type)"
                        :key="index"
                    >
                        <!-- 表格部分 -->
                        <el-table :data="[item]" size="mini" border style="width: 100%">
                            <!-- 交易方式 -->
                            <el-table-column
                                header-align="center"
                                fixed
                                align="center"
                                min-width="120"
                                label="交易方式"
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.title}}</span>
                                </template>
                            </el-table-column>
                            <!-- 结算周期 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="结算周期"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope">
                                        <!-- T0 -->
                                        <div>{{scope.row.t0.title}}</div>
                                        <!-- T1 -->
                                        <div>{{scope.row.t1.title}}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 卡类型 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="卡类型"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope">
                                        <div>{{scope.row.t0.card1.title}}</div>
                                        <div>{{scope.row.t0.card2.title}}</div>
                                        <div>{{scope.row.t1.card1.title}}</div>
                                        <div>{{scope.row.t1.card2.title}}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 标准费率 -->
                            <el-table-column header-align="center" align="center" label="标准费率%">
                                <!-- 最小值 -->
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    min-width="120"
                                    label="最小值"
                                >
                                    <template slot-scope="scope">
                                        <div class="tableScope">
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t0.card1.is_empty == 1"
                                                    v-model="scope.row.t0.card1.base_rate_min"
                                                    :placeholder="scope.row.t0.card1.brand_base_rate_min"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t0.card2.is_empty == 1"
                                                    v-model="scope.row.t0.card2.base_rate_min"
                                                    :placeholder="scope.row.t0.card2.brand_base_rate_min"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t1.card1.is_empty == 1"
                                                    v-model="scope.row.t1.card1.base_rate_min"
                                                    :placeholder="scope.row.t1.card1.brand_base_rate_min"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t1.card2.is_empty == 1"
                                                    v-model="scope.row.t1.card2.base_rate_min"
                                                    :placeholder="scope.row.t1.card2.brand_base_rate_min"
                                                ></el-input>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <!-- 最大值 -->
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    min-width="120"
                                    label="最大值"
                                >
                                    <template slot-scope="scope">
                                        <div class="tableScope">
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t0.card1.is_empty == 1"
                                                    v-model="scope.row.t0.card1.base_rate_max"
                                                    placeholder="1.0000"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t0.card2.is_empty == 1"
                                                    v-model="scope.row.t0.card2.base_rate_max"
                                                    placeholder="1.0000"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t1.card1.is_empty == 1"
                                                    v-model="scope.row.t1.card1.base_rate_max"
                                                    placeholder="1.0000"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t1.card2.is_empty == 1"
                                                    v-model="scope.row.t1.card2.base_rate_max"
                                                    placeholder="1.0000"
                                                ></el-input>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table-column>
                            <!-- 是否设置封顶值 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="是否设置封顶值"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope">
                                        <div>{{setOverrun(scope.row.t0.card1.is_overrun)}}</div>
                                        <div>{{setOverrun(scope.row.t0.card2.is_overrun)}}</div>
                                        <div>{{setOverrun(scope.row.t1.card1.is_overrun)}}</div>
                                        <div>{{setOverrun(scope.row.t1.card2.is_overrun)}}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 达到某一金额时 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="达到某一金额时"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope">
                                        <div>{{scope.row.t0.card1.overrun_money? scope.row.t0.card1.overrun_money : '--'}}</div>
                                        <div>{{scope.row.t0.card2.overrun_money? scope.row.t0.card2.overrun_money : '--'}}</div>
                                        <div>{{scope.row.t1.card1.overrun_money? scope.row.t1.card1.overrun_money : '--'}}</div>
                                        <div>{{scope.row.t1.card2.overrun_money? scope.row.t1.card2.overrun_money : '--'}}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 费率收取方式 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="费率收取方式"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope">
                                        <div>{{setOverrunType(scope.row.t0.card1.overrun_type)}}</div>
                                        <div>{{setOverrunType(scope.row.t0.card2.overrun_type)}}</div>
                                        <div>{{setOverrunType(scope.row.t1.card1.overrun_type)}}</div>
                                        <div>{{setOverrunType(scope.row.t1.card2.overrun_type)}}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 费率/金额 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="费率/金额"
                            >
                                <!-- 最小值 1.费率 2.金额-->
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    min-width="120"
                                    label="最小值"
                                >
                                    <template slot-scope="scope">
                                        <div class="tableScope">
                                            <div>
                                                <!-- 费率 -->
                                                <el-input
                                                    v-if="scope.row.t0.card1.overrun_type == 1"
                                                    :disabled="scope.row.t0.card1.is_empty == 1 || scope.row.t0.card1.is_overrun != 1"
                                                    v-model="scope.row.t0.card1.overrun_rate_min"
                                                    :placeholder="scope.row.t0.card1.brand_overrun_rate_min"
                                                ></el-input>
                                                <!-- 金额 -->
                                                <el-input
                                                    v-else
                                                    :disabled="scope.row.t0.card1.is_empty == 1 || scope.row.t0.card1.is_overrun != 1"
                                                    v-model="scope.row.t0.card1.overrun_cap_min"
                                                    :placeholder="scope.row.t0.card1.brand_overrun_cap_min"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <!-- 费率 -->
                                                <el-input
                                                    v-if="scope.row.t0.card2.overrun_type == 1"
                                                    :disabled="scope.row.t0.card2.is_empty == 1 || scope.row.t0.card2.is_overrun != 1"
                                                    v-model="scope.row.t0.card2.overrun_rate_min"
                                                    :placeholder="scope.row.t0.card2.brand_overrun_rate_min"
                                                ></el-input>
                                                <!-- 金额 -->
                                                <el-input
                                                    v-else
                                                    :disabled="scope.row.t0.card2.is_empty == 1 || scope.row.t0.card2.is_overrun != 1"
                                                    v-model="scope.row.t0.card2.overrun_cap_min"
                                                    :placeholder="scope.row.t0.card2.brand_overrun_cap_min"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <!-- 费率 -->
                                                <el-input
                                                    v-if="scope.row.t1.card1.overrun_type == 1"
                                                    :disabled="scope.row.t1.card1.is_empty == 1 || scope.row.t1.card1.is_overrun != 1"
                                                    v-model="scope.row.t1.card1.overrun_rate_min"
                                                    :placeholder="scope.row.t1.card1.brand_overrun_rate_min"
                                                ></el-input>
                                                <!-- 金额 -->
                                                <el-input
                                                    v-else
                                                    :disabled="scope.row.t1.card1.is_empty == 1 || scope.row.t1.card1.is_overrun != 1"
                                                    v-model="scope.row.t1.card1.overrun_cap_min"
                                                    :placeholder="scope.row.t1.card1.brand_overrun_cap_min"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <!-- 费率 -->
                                                <el-input
                                                    v-if="scope.row.t1.card2.overrun_type == 1"
                                                    :disabled="scope.row.t1.card2.is_empty == 1 || scope.row.t1.card2.is_overrun != 1"
                                                    v-model="scope.row.t1.card2.overrun_rate_min"
                                                    :placeholder="scope.row.t1.card2.brand_overrun_rate_min"
                                                ></el-input>
                                                <!-- 金额 -->
                                                <el-input
                                                    v-else
                                                    :disabled="scope.row.t1.card2.is_empty == 1 || scope.row.t1.card2.is_overrun != 1"
                                                    v-model="scope.row.t1.card2.overrun_cap_min"
                                                    :placeholder="scope.row.t1.card2.brand_overrun_cap_min"
                                                ></el-input>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <!-- 最大值 -->
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    min-width="120"
                                    label="最大值"
                                >
                                    <template slot-scope="scope">
                                        <div class="tableScope">
                                            <div>
                                                <!-- 费率 -->
                                                <el-input
                                                    v-if="scope.row.t0.card1.overrun_type == 1"
                                                    :disabled="scope.row.t0.card1.is_empty == 1 || scope.row.t0.card1.is_overrun != 1"
                                                    v-model="scope.row.t0.card1.overrun_rate_max"
                                                    placeholder="1.0000"
                                                ></el-input>
                                                <!-- 金额 -->
                                                <el-input
                                                    v-else
                                                    :disabled="scope.row.t0.card1.is_empty == 1 || scope.row.t0.card1.is_overrun != 1"
                                                    v-model="scope.row.t0.card1.overrun_cap_max"
                                                    placeholder="100.00"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <!-- 费率 -->
                                                <el-input
                                                    v-if="scope.row.t0.card2.overrun_type == 1"
                                                    :disabled="scope.row.t0.card2.is_empty == 1 || scope.row.t0.card2.is_overrun != 1"
                                                    v-model="scope.row.t0.card2.overrun_rate_max"
                                                    placeholder="1.0000"
                                                ></el-input>
                                                <!-- 金额 -->
                                                <el-input
                                                    v-else
                                                    :disabled="scope.row.t0.card2.is_empty == 1 || scope.row.t0.card2.is_overrun != 1"
                                                    v-model="scope.row.t0.card2.overrun_cap_max"
                                                    placeholder="100.00"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <!-- 费率 -->
                                                <el-input
                                                    v-if="scope.row.t1.card1.overrun_type == 1"
                                                    :disabled="scope.row.t1.card1.is_empty == 1 || scope.row.t1.card1.is_overrun != 1"
                                                    v-model="scope.row.t1.card1.overrun_rate_max"
                                                    placeholder="1.0000"
                                                ></el-input>
                                                <!-- 金额 -->
                                                <el-input
                                                    v-else
                                                    :disabled="scope.row.t1.card1.is_empty == 1 || scope.row.t1.card1.is_overrun != 1"
                                                    v-model="scope.row.t1.card1.overrun_cap_max"
                                                    placeholder="100.00"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <!-- 费率 -->
                                                <el-input
                                                    v-if="scope.row.t1.card2.overrun_type == 1"
                                                    :disabled="scope.row.t1.card2.is_empty == 1 || scope.row.t1.card2.is_overrun != 1"
                                                    v-model="scope.row.t1.card2.overrun_rate_max"
                                                    placeholder="1.0000"
                                                ></el-input>
                                                <!-- 金额 -->
                                                <el-input
                                                    v-else
                                                    :disabled="scope.row.t1.card2.is_empty == 1 || scope.row.t1.card2.is_overrun != 1"
                                                    v-model="scope.row.t1.card2.overrun_cap_max"
                                                    placeholder="100.00"
                                                ></el-input>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table-column>
                            <!-- 是否添加秒到费 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="120"
                                label="是否添加秒到费"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope">
                                        <div>{{setOverrun(scope.row.t0.card1.is_sec)}}</div>
                                        <div>{{setOverrun(scope.row.t0.card2.is_sec)}}</div>
                                        <div>{{setOverrun(scope.row.t1.card1.is_sec)}}</div>
                                        <div>{{setOverrun(scope.row.t1.card2.is_sec)}}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 秒到费（单笔添加）元 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                min-width="150"
                                label="秒到费（单笔添加）元"
                            >
                                <!-- 最小值 -->
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    min-width="120"
                                    label="最小值"
                                >
                                    <template slot-scope="scope">
                                        <div class="tableScope">
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t0.card1.is_empty == 1 || scope.row.t0.card1.is_sec != 1"
                                                    v-model="scope.row.t0.card1.sec_fee_min"
                                                    :placeholder="scope.row.t0.card1.brand_sec_fee_min"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t0.card2.is_empty == 1 || scope.row.t0.card2.is_sec != 1"
                                                    v-model="scope.row.t0.card2.sec_fee_min"
                                                    :placeholder="scope.row.t0.card2.brand_sec_fee_min"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t1.card1.is_empty == 1 || scope.row.t1.card1.is_sec != 1"
                                                    v-model="scope.row.t1.card1.sec_fee_min"
                                                    :placeholder="scope.row.t1.card1.brand_sec_fee_min"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t1.card2.is_empty == 1 || scope.row.t1.card2.is_sec != 1"
                                                    v-model="scope.row.t1.card2.sec_fee_min"
                                                    :placeholder="scope.row.t1.card2.brand_sec_fee_min"
                                                ></el-input>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <!-- 最大值 -->
                                <el-table-column
                                    header-align="center"
                                    align="center"
                                    min-width="120"
                                    label="最大值"
                                >
                                    <template slot-scope="scope">
                                        <div class="tableScope">
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t0.card1.is_empty == 1 || scope.row.t0.card1.is_sec != 1"
                                                    v-model="scope.row.t0.card1.sec_fee_max"
                                                    placeholder="10.00"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t0.card2.is_empty == 1 || scope.row.t0.card2.is_sec != 1"
                                                    v-model="scope.row.t0.card2.sec_fee_max"
                                                    placeholder="10.00"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t1.card1.is_empty == 1 || scope.row.t1.card1.is_sec != 1"
                                                    v-model="scope.row.t1.card1.sec_fee_max"
                                                    placeholder="10.00"
                                                ></el-input>
                                            </div>
                                            <div>
                                                <el-input
                                                    :disabled="scope.row.t1.card2.is_empty == 1 || scope.row.t1.card2.is_sec != 1"
                                                    v-model="scope.row.t1.card2.sec_fee_max"
                                                    placeholder="10.00"
                                                ></el-input>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table-column>
                            <!-- 是否支持修改 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                fixed="right"
                                min-width="150"
                                label="是否支持修改"
                            >
                                <template slot-scope="scope">
                                    <div class="tableScope">
                                        <div>
                                            <el-switch
                                                v-model="scope.row.t0.card1.is_modify"
                                                :disabled="scope.row.t0.card1.is_empty == 1"
                                                active-text="是"
                                                :active-value="1"
                                                inactive-text="否"
                                                :inactive-value="0"
                                            ></el-switch>
                                        </div>
                                        <div>
                                            <el-switch
                                                v-model="scope.row.t0.card2.is_modify"
                                                :disabled="scope.row.t0.card2.is_empty == 1"
                                                active-text="是"
                                                :active-value="1"
                                                inactive-text="否"
                                                :inactive-value="0"
                                            ></el-switch>
                                        </div>
                                        <div>
                                            <el-switch
                                                v-model="scope.row.t1.card1.is_modify"
                                                :disabled="scope.row.t1.card1.is_empty == 1"
                                                active-text="是"
                                                :active-value="1"
                                                inactive-text="否"
                                                :inactive-value="0"
                                            ></el-switch>
                                        </div>
                                        <div>
                                            <el-switch
                                                v-model="scope.row.t1.card2.is_modify"
                                                :disabled="scope.row.t1.card2.is_empty == 1"
                                                active-text="是"
                                                :active-value="1"
                                                inactive-text="否"
                                                :inactive-value="0"
                                            ></el-switch>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button
                            type="primary"
                            :loading="loading"
                            style="font-size: 12px;margin-top:10px"
                            @click="handleSubmit(item)"
                        >提 交</el-button>
                    </el-tab-pane>
                </el-tabs>
            </template>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "MAM_merchantRate",
    data() {
        return {
            loading: false,
            tabList: [],
            activeName: '101', //选项卡默认选中
            brandId: null,
            rewardTable: [],
            oldList: [] //旧数据
        }
    },
    mounted() {
        this.brandId = this.$route.query.id; //终端类型id
        this.getData();
    },
    methods: {
        handleSubmit(row) { //提交
            let compare;
            this._.forEach(this.oldList, item => { //找到当前编辑的选项卡
                if (item.pay_type == row.pay_type) {
                    compare = item;
                    return false;
                }
            });
            let oldTemp = []; //旧数据临时存储
            let newTemp = []; //新数据临时存储

            // 旧
            oldTemp.push(compare.t0.card1);
            oldTemp.push(compare.t0.card2);
            oldTemp.push(compare.t1.card1);
            oldTemp.push(compare.t1.card2);
            // 新
            newTemp.push(row.t0.card1);
            newTemp.push(row.t0.card2);
            newTemp.push(row.t1.card1);
            newTemp.push(row.t1.card2);

            let reqData = [];
            this._.forEach(oldTemp, (v, i) => {//对比
                if (!this._.isEqual(v, newTemp[i]) && v.is_empty != 1) {
                    reqData.push(newTemp[i])
                }
            });
            if (reqData.length != 0) {
                this.loading = true;
                this.$api.post('brand_terminal_rate/save', { rate_data: reqData }, res => {
                    if (res.code == 0) {
                        this.$message.success(res.msg);
                        this.getData();
                        this.loading = false;
                    } else {
                        this.$message.error(res.msg);
                        this.loading = false;
                    }
                })
            } else {
                this.$message.error('未检测到更改');
            }
        },
        getData() { //初始化数据
            this.$api.post("brand_terminal_rate/getInfo", { brand_id: this.brandId }, res => {
                if (res.code == 0) {
                    // 设置默认选中
                    this.activeName = res.data[0].pay_type + '';
                    this.tabList = res.data;
                    this.oldList = this._.cloneDeep(res.data);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        setOverrun(val) { //是否设置封顶、是否添加秒到费
            let value;
            switch (val) {
                case 0:
                    value = '否'
                    break;
                case 1:
                    value = '是'
                    break;
                default:
                    value = '--'
                    break;
            }
            return value;
        },
        setOverrunType(val) { //费率收取方式
            let value;
            switch (val) {
                case 1:
                    value = '比例(%)'
                    break;
                case 2:
                    value = '固定（元）'
                    break;
                default:
                    value = '--'
                    break;
            }
            return value;
        }
    }
};
</script>

<style lang="scss" scope>
.MAM_merchantRate {
    // .el-input__inner {
    //     font-size: 12px;
    // }
    // .el-form-item {
    //     margin-bottom: 5px;
    // }
    .el-table--mini td,
    .el-table--mini th {
        padding: 0;
    }
    .el-table .el-table__header td,
    .el-table .el-table__header th {
        padding: 12px 0;
    }
    .el-table .cell {
        padding: 0;
    }
    .el-switch__label *,
    .el-button--text {
        // border: none;
        font-size: 12px;
    }
    .el-switch__label--left {
        border: none !important;
    }
    .el-input {
        height: 100%;
        .el-input__inner {
            border: none;
            height: 100%;
            text-align: center;
            font-size: 12px;
        }
    }
    .tableScope {
        height: 180px;
        display: flex;
        flex-direction: column;
        div {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        :not(:last-child) {
            border-bottom: 1px solid #ebeef5;
        }
        .isNull {
            background-color: #f5f7fa;
        }
        .notNull {
            background-color: none;
        }
    }
}
</style>
