<template>
    <div class>
        <!-- 表单搜索部分 -->
        <el-card shadow="hover">
            <el-form :inline="true" :model="searchForm">
                <el-form-item>
                    <el-select
                        v-model="searchForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端政策"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.merchant_name" placeholder="请输入商户名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.merchant_number" placeholder="请输入商户编号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.terminal_sn" placeholder="请输入SN"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.real_name" placeholder="请输入代理商名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input clearable v-model="searchForm.phone" placeholder="请输入代理商账号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查 询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card style="margin-top: 20px;" shadow="hover">
            <div style="margin-bottom: 20px;"></div>
            <el-table
                :data="tableList"
                size="small"
                border
                style="width: 100%"
                v-loading="loading"
                max-height="500px"
            >
                <!-- 序号 -->
                <el-table-column
                    type="index"
                    header-align="center"
                    align="center"
                    fixed
                    label="序号"
                    width="100"
                    :index="handleTableIndex"
                ></el-table-column>
                <!-- 终端政策 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端政策"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 终端SN -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="terminal_sn"
                    label="终端SN"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 商户编号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="merchant_number"
                    label="商户编号"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 商户名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="merchant_name"
                    label="商户名称"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 终端归属代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="终端归属代理商名称"
                    min-width="200"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 终端归属代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="终端归属代理商账号"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 操作时间 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="create_time"
                    label="操作时间"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 操作人 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="operator"
                    label="操作人"
                    min-width="150"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleCheckDetail(scope.row)" type="text" size="small">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <CloseBindRecordListDetail
            v-if="bindDetail.isShow"
            :bind-detail="bindDetail"
            @detailClose="handleCloseCheckDetail"
        />
    </div>
</template>
<script>
import CloseBindRecordListDetail from './components/CloseBindRecordListDetail';
export default {
    name: "CloseBindRecordList",
    data() {
        return {
            bindDetail: {
                isShow: false,
                row: {}
            },
            activeRange: [], // 激活时间范围
            awardRange: [], // 奖励时间范围
            searchForm: {
                page: 1,
                limit: 10,
                brand_id: '', // 终端政策ID
                merchant_name: '', // 商户名称
                merchant_number: '', // 商户编号
                terminal_sn: '', // 原终端SN
                after_terminal_sn: '', // 换绑后SN
                real_name: '', // 代理商名称
                phone: '' // 代理商账号
            },
            brandList: [], // 品牌列表
            tableList: [],
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true,
            downLoading: false //导出loading
        }
    },
    created() {
        this.getBrand();
        this.getData({
            page: 1,
            limit: 10
        })
    },
    mounted() {
    },
    components: {
        CloseBindRecordListDetail
    },
    methods: {
        handleSearch(searchForm) {
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        handleCheckDetail(row) { // 详情
            this.bindDetail.isShow = true;
            this.bindDetail.row = row;
        },
        handleCloseCheckDetail() { // 关闭详情
            this.bindDetail.isShow = false;
            this.bindDetail.row = {};
        },
        getData(params) { // 获取数据
            this.loading = true;
            this.$api.post("unbind_terminal/get_unbind_terminal", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.tableList = data.list;
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        },
        getBrand() { // 品牌
            this.$api.post('brand/brand_list', {}, res => { //获取终端政策
                if (res.code == 0) {
                    this.brandList = res.data.list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleTableIndex(index) { // 处理序号
            return (this.currentPage - 1) * this.pageSize + index + 1;
        }
    }
}
</script>

<style scoped lang="scss">
.el-form-item {
    margin-bottom: 5px;
}
</style>