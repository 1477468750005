<template>
    <div class="setUserRate">
        <el-dialog :visible="isShowRate" width="70%" @close="handleClose">
            <el-card class="box-card" shadow="hover">
                <div slot="header" class="clearfix">
                    <span
                        style="font-weight:700;font-size:13px"
                    >{{publickData.userName}}"{{publickData.brandName}}终端类型"的结算成本</span>
                </div>
                <el-form ref="form" :inline="true">
                    <el-tabs v-model="baseOrRise">
                        <el-tab-pane label="基础费率" name="base">
                            <el-tabs v-model="baseActiveName">
                                <!-- Tab页 -->
                                <el-tab-pane
                                    v-for="(tabItem,tabIndex) in baseTabContent"
                                    :label="tabItem.title"
                                    :name="String(tabItem.settle_type)"
                                    :key="tabIndex"
                                >
                                    <template v-for="(titleItem,titleIndex) in tabItem.list">
                                        <!-- 小标题 -->
                                        <div :key="titleIndex">
                                            <div class="type_title">{{titleItem.title}}</div>
                                            <el-form-item
                                                v-for="(item,index) in titleItem.list"
                                                :label="item.title"
                                                :key="index"
                                            >
                                                <el-input
                                                    v-model="item.value"
                                                    size="medium"
                                                    :placeholder="isEdit ==1? item.sub_title : ''"
                                                    :disabled="isEdit != 1 || item.is_edit != 1"
                                                ></el-input>
                                            </el-form-item>
                                            <el-divider></el-divider>
                                        </div>
                                    </template>
                                </el-tab-pane>
                                <el-button
                                    :loading="btnLoading"
                                    @click="handleSubmit(baseTabContent,1)"
                                    type="primary"
                                    style="font-size:12px"
                                    :disabled="isEdit != 1"
                                    v-if="isEdit == 1"
                                >保存配置</el-button>
                            </el-tabs>
                        </el-tab-pane>
                        <el-tab-pane v-if="publickData.isRise == 1" label="涨价后费率" name="rise">
                            <el-tabs v-model="riseActiveName">
                                <!-- Tab页 -->
                                <el-tab-pane
                                    v-for="(tabItem,tabIndex) in riseTabContent"
                                    :label="tabItem.title"
                                    :name="String(tabItem.settle_type)"
                                    :key="tabIndex"
                                >
                                    <template v-for="(titleItem,titleIndex) in tabItem.list">
                                        <!-- 小标题 -->
                                        <div :key="titleIndex">
                                            <div class="type_title">{{titleItem.title}}</div>
                                            <el-form-item
                                                v-for="(item,index) in titleItem.list"
                                                :label="item.title"
                                                :key="index"
                                            >
                                                <el-input
                                                    v-model="item.value"
                                                    size="medium"
                                                    :placeholder="isEdit ==1? item.sub_title : ''"
                                                    :disabled="isEdit != 1 || item.is_edit != 1"
                                                ></el-input>
                                            </el-form-item>
                                            <el-divider></el-divider>
                                        </div>
                                    </template>
                                    <el-button
                                        :loading="btnLoading"
                                        @click="handleSubmit(tabItem.list,tabItem.settle_type,2)"
                                        type="primary"
                                        style="font-size:12px"
                                        :disabled="isEdit != 1"
                                        v-if="isEdit == 1"
                                    >提 交</el-button>
                                </el-tab-pane>
                            </el-tabs>
                        </el-tab-pane>
                    </el-tabs>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "UM_setUserRate",
    props: {
        isShowRate: {
            type: Boolean,
            default: false
        },
        publickData: {
            type: Object
        }
    },
    data() {
        return {
            baseOrRise: 'base', //基础费率or涨价后费率
            baseActiveName: '1', //基础费率 - Tab默认选中
            baseTabContent: [], //基础费率 -tab内容
            baseOldData: [], //基础费率 - 旧数据(对比使用)
            riseActiveName: '1',//涨价后费率 - Tab默认选中
            riseTabContent: [], //涨价后费率 -tab内容
            riseOldData: [], //涨价后费率 - 旧数据(对比使用)
            isEdit: 0, //是否可以编辑 1是 0否
            btnLoading: false,
        }
    },
    mounted() {
        this.getBaseRate(); //基础费率
        // if (this.publickData.isRise == 1) {
        //     this.getRiseRate(); //涨价后费率
        // }
    },
    methods: {
        handleSubmit(val, rateType) {
            let { brandId, userId } = this.publickData;
            let BASE_URL;
            let oldDataSource;
            switch (rateType) { //1.基础 2.涨价
                case 1:
                    BASE_URL = 'user_rate/user_rate_update';
                    oldDataSource = this.baseOldData
                    break;
                case 2:
                    BASE_URL = 'user_rate/user_rate_rise_update';
                    oldDataSource = this.riseOldData;
                    break;
                default:
                    break;
            }
            let reqData = { //请求的数据
                brand_id: brandId,
                ally_user_id: userId,
                data: []
            };
            this._.forEach(val, (v, i) => {
                let dataVal = oldDataSource[i];
                this._.forEach(v.list, (item, index) => {
                    let dataNew = dataVal.list[index];
                    let newObj = this._.cloneDeep(item);
                    newObj.list = [];
                    this._.forEach(item.list, (newItem, newIndex) => {
                        let newValue = dataNew.list[newIndex];
                        if (newItem.value != '') {
                            if (newValue.value !== newItem.value) {
                                newObj.list.push(this._.pick(newItem, ['type', 'value']));
                            }
                        }
                    })
                    if (newObj.list != 0) {
                        reqData.data.push(this._.omit(newObj, ['title']));
                    }
                })
            });
            if (!this._.isEmpty(reqData.data)) {
                reqData.data = JSON.stringify(reqData.data);
                this.btnLoading = true;
                this.$api.post(BASE_URL, reqData, res => {
                    let _this = this;
                    if (res.code == 0) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1500,
                            onClose() {
                                _this.getBaseRate();
                                if (_this.publickData.isRise == 1) {
                                    _this.getRiseRate();
                                }
                                _this.btnLoading = false;
                            }
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg,
                            duration: 3000
                        })
                        this.btnLoading = false;
                    }
                })
            } else {
                this.$message({
                    type: 'error',
                    message: '未发现更改',
                    duration: 1000
                })
            }
        },
        getBaseRate() { //获取基础费率
            let { brandId, userId } = this.publickData;
            this.$api.post('user_rate/user_rate_list', { brand_id: brandId, ally_user_id: userId }, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.baseTabContent = data.list;
                    this.isEdit = data.is_edit;
                    this.baseOldData = this._.cloneDeep(data.list);
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        getRiseRate() { //获取涨价后费率
            let { brandId, userId } = this.publickData;
            this.$api.post('user_rate/user_rate_rise_list', { brand_id: brandId, ally_user_id: userId }, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.riseTabContent = data.list;
                    this.riseOldData = this._.cloneDeep(data.list);
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        handleClose() {
            this.$emit('rateClose');
        }
    }
}
</script>

<style scoped lang="scss">
.setUserRate {
    ::v-deep .el-form-item__label {
        font-size: 12px;
        font-weight: 700;
    }
    ::v-deep .el-input__inner {
        width: 150px;
    }
    ::v-deep .el-dialog {
        height: 80%;
        .el-dialog__body {
            height: 82%;
            overflow: auto;
        }
    }
    .type_title {
        margin: 10px 0;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #40b8ff;
        padding: 0 0 0 10px;
    }
}
</style>
