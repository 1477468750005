<template>
    <div class="block">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10,20,50,100]"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'paginations',
    data() {
        return {
        }
    },
    props: {
        currentPage: { //当前页码
            type: [Number, String],
            default: 1
        },
        pageSize: { //条数
            type: [Number, String],
            default: 10
        },
        total: { //总数
            type: [Number, String],
            default: 0
        }

    },
    methods: {
        handleSizeChange(limit) {
            // 切换条数的时候设置值并重置当前页面的值
            this.$emit('watchPageSize',limit);
        },
        handleCurrentChange(page) {
            //记录当前页码
            this.$emit('watchCurrent',page);
        }
    }
}
</script>

<style scoped lang="scss">
.block{
    margin-top:10px;
}
</style>
