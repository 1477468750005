<template>
    <!-- 上级补贴记录 -->
    <div class="DM_attainRecord">
        <!-- 表单搜索部分 -->
        <el-card class="box-card" shadow="hover">
            <el-form :inline="true" :model="searchForm">
                <el-form-item>
                    <el-input
                        size="medium"
                        clearable
                        v-model="searchForm.superior_real_name"
                        placeholder="请输入上级代理商名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size="medium"
                        clearable
                        v-model="searchForm.superior_phone"
                        placeholder="请输入上级代理商账号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size="medium"
                        clearable
                        v-model="searchForm.owner_real_name"
                        placeholder="请输入归属代理商名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        size="medium"
                        clearable
                        v-model="searchForm.owner_phone"
                        placeholder="请输入归属代理商账号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        size="medium"
                        v-model="searchForm.brand_id"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择终端类型"
                    >
                        <el-option
                            v-for="(item,index) in brandList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        size="medium"
                        v-model="searchForm.reward_type"
                        filterable
                        clearable
                        style="width:100%"
                        placeholder="请选择奖励类型"
                    >
                        <el-option label="激活奖励" value="1"></el-option>
                        <el-option label="达标奖励" value="2"></el-option>
                        <el-option label="阶梯奖励" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        style="font-size: 12px;"
                        @click="handleSearch(searchForm)"
                    >查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="hover">
            <!-- <div style="margin-bottom: 20px;">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-download"
                    :loading="downLoading"
                    @click="handleDownLoad"
                >导出数据</el-button>
            </div>-->
            <el-table :data="recordList" size="mini" border style="width: 100%" v-loading="loading">
                <!-- ID -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed
                    prop="id"
                    label="序号"
                    min-width="80"
                ></el-table-column>
                <!-- 上级代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="parent_real_name"
                    label="上级代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 上级代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="parent_phone"
                    label="上级代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 归属代理商名称 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="real_name"
                    label="归属代理商名称"
                    min-width="120"
                ></el-table-column>
                <!-- 归属代理商账号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="phone"
                    label="归属代理商账号"
                    min-width="120"
                ></el-table-column>
                <!-- 终端类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="brand_name"
                    label="终端类型"
                    min-width="120"
                    show-overflow-tooltip
                ></el-table-column>
                <!-- 奖励类型 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="type"
                    label="奖励类型"
                    min-width="150"
                ></el-table-column>
                <!-- 奖励描述 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="reward_description"
                    label="奖励描述"
                    min-width="150"
                ></el-table-column>
                <!-- 当前设置奖励金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="current_money"
                    label="当前设置奖励金额"
                    min-width="150"
                ></el-table-column>
                <!-- 剩余个数 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="lave_num"
                    label="剩余个数"
                    min-width="150"
                ></el-table-column>
                <!-- 状态 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="status"
                    label="状态"
                    min-width="150"
                ></el-table-column>
                <!-- 操作 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    fixed="right"
                    label="操作"
                    min-width="180"
                >
                    <template slot-scope="scope">
                        <el-button @click="handleInfo(scope.row,1)" type="text" size="small">配置记录</el-button>
                        <el-button @click="handleInfo(scope.row,2)" type="text" size="small">触发记录</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页部分 -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10,20,50,100]"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </el-card>
        <subsidy-Info v-if="info.isShow" :info="info" @infoClose="handleInfoClose"></subsidy-Info>
    </div>
</template>
<script>
import subsidyInfo from './components/subsidyInfo';
export default {
    data() {
        return {
            info: {
                type: null,//1.配置记录 2.触发记录
                row: {},
                isShow: false
            },
            searchForm: {
                page: 1,
                limit: 10
            },
            brandList: [],//品牌列表
            recordList: [],//补贴列表
            currentPage: 1, //当前页码
            pageSize: 10, //条数
            total: 0,  //总数
            loading: true,
            downLoading: false //导出loading
        }
    },
    components: {
        subsidyInfo
    },
    mounted() {
        this.$api.post('brand/brand_list', {}, res => { //获取终端类型
            if (res.code == 0) {
                this.brandList = res.data.list;
            } else {
                this.$message.success(res.msg);
            }
        });
        this.getData({
            page: 1,
            limit: 10
        })
    },
    methods: {
        handleSearch(searchForm) {
            this.currentPage = 1;
            searchForm.page = 1;
            this.getData(searchForm);
        },
        handleInfo(row, type) { //详情
            this.info.row = row;
            this.info.type = type;
            this.info.isShow = true;
        },
        handleInfoClose() { //关闭详情
            this.info.isShow = false;
            this.info.type = null;
        },
        handleCurrentChange(page) { //当前选中页
            this.currentPage = page;
            this.searchForm.page = page;
            this.searchForm.limit = this.pageSize;
            this.getData(this.searchForm);
        },
        handleSizeChange(limit) { //条数
            this.currentPage = 1;
            this.pageSize = limit;
            this.searchForm.page = 1;
            this.searchForm.limit = limit;
            this.getData(this.searchForm);
        },
        handleDownLoad() { //导出
            let handleExportExcel = this.common.handleExportExcel;
            this.downLoading = true;
            let searchVal = this._.omit(this.searchForm, ['page', 'limit']);
            let value = {};
            this._.forEach(searchVal, (item, index) => {
                if (item != '') {
                    value[index] = item;
                }
            });
            handleExportExcel({ type: 4, value: JSON.stringify(value) }).then(() => {
                this.downLoading = false;
            });
        },
        getData(params) { //获取数据
            this.loading = true;
            this.$api.post("brand_reward_subsidy/list", params, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.total = data.count; //总数
                    this.recordList = data.list;
                }
                //渲染结束后取消加载
                this.loading = false;
            })
        }
    }
}
</script>

<style scoped lang="scss">
</style>