/** 权限管理 */
import roleManagement from '../../views/permissionManagement/roleList'; //角色管理
import operatorManagement from '../../views/permissionManagement/operatorList'; //操作员管理

export default [
    {
        path: '/roleManagement',
        name: 'roleManagement',
        meta: {
            title: '角色管理'
        },
        component: roleManagement
    },
    {
        path: '/operatorManagement',
        name: 'operatorManagement',
        meta: {
            title: '操作员管理'
        },
        component: operatorManagement
    }
]