<template>
    <div class="merchantInfo">
        <div style="margin-bottom: 20px;">
            <el-button size="small" icon="el-icon-back" @click="$router.back()">返 回</el-button>
        </div>
        <!--个人信息-->
        <el-card v-if="merchanttype == 1" class="box-card" shadow="hover">
            <div class="title">基础信息</div>
            <el-row :gutter="20">
                <el-col :sm="20" :md="20" :lg="20">
                    <el-row>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">商户类型：</span>
                            <span>{{baseInfo.type ==1? '个人商户' : '企业商户'}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">证件号码：</span>
                            <span>{{baseInfo.id_card_num}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">证件有效期：</span>
                            <span>{{baseInfo.id_card_validity_peroid}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">省市区：</span>
                            <span>{{baseInfo.prov_city_area}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">详细地址：</span>
                            <span>{{ _.isNull(baseInfo.addr_detail)? '--' : baseInfo.addr_detail}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">经营范围：</span>
                            <span>{{baseInfo.mcc_title}}</span>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <div class="title">结算信息</div>
            <el-row :gutter="20">
                <el-col :sm="20" :md="20" :lg="20">
                    <el-row>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">银行账户：</span>
                            <span>{{baseInfo.acc_num}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">开户银行：</span>
                            <span>{{baseInfo.bank_name}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">开户城市：</span>
                            <span>{{baseInfo.bank_prov_city}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">预留手机号：</span>
                            <span>{{baseInfo.phone_show}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">结算卡有效期：</span>
                            <span>{{ _.isNull(baseInfo.bankcard_validity_peroid)? '--' : baseInfo.bankcard_validity_peroid}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">是否开通D1:</span>
                            <span>{{baseInfo.is_open_d1}}</span>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <div class="title">资质信息</div>
            <el-row :gutter="20">
                <el-col :sm="12" :md="12" :lg="6">
                    <span class="labels">身份证正面：</span>
                    <span>
                        <el-button
                            type="text"
                            style="padding:0;font-size:12px"
                            @click="viewImage(baseInfo.card_positive_photo)"
                        >点击查看</el-button>
                    </span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="6">
                    <span class="labels">身份证反面：</span>
                    <span>
                        <el-button
                            type="text"
                            style="padding:0;font-size:12px"
                            @click="viewImage(baseInfo.card_negative_photo)"
                        >点击查看</el-button>
                    </span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="6">
                    <span class="labels">手持证件照：</span>
                    <span>
                        <el-button
                            type="text"
                            style="padding:0;font-size:12px"
                            @click="viewImage(baseInfo.card_hand_hold_photo)"
                        >点击查看</el-button>
                    </span>
                </el-col>
            </el-row>
            <div class="title">终端信息</div>
            <el-row :gutter="20">
                <el-col :sm="20" :md="20" :lg="20">
                    <el-row>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">终端类型：</span>
                            <span>{{baseInfo.brand_name}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">SN编码：</span>
                            <span>{{baseInfo.terminal_sn}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">SN状态：</span>
                            <span>{{baseInfo.is_active == 1 ? '已激活' : '未激活'}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">服务费模板：</span>
                            <span>{{baseInfo.cash_back}}</span>
                        </el-col>
                        <!-- </el-row> -->
                        <!-- <el-row> -->
                        <el-col :sm="24" v-if="baseInfo.status == 3">
                            <div class="reason">拒绝原因：{{baseInfo.denial_reason}}</div>
                        </el-col>
                        <!-- </el-row> -->
                    </el-row>
                </el-col>
            </el-row>
            <div v-if="baseInfo.merchant_net_method == 1 && baseInfo.status == 1">
                <el-button
                    size="medium"
                    type="primary"
                    icon="el-icon-check"
                    style="font-size: 12px;"
                    @click="handlePass"
                >审核通过</el-button>
                <el-button
                    size="medium"
                    type="danger"
                    icon="el-icon-close"
                    style="font-size: 12px;"
                    @click="handleRefuse"
                >审核拒绝</el-button>
            </div>
        </el-card>
        <!--企业信息-->
        <el-card v-if="merchanttype == 2" class="box-card" shadow="hover">
            <div class="title">基础信息</div>
            <el-row :gutter="20">
                <el-col :sm="20" :md="20" :lg="20">
                    <el-row>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">商户类型：</span>
                            <span>{{baseInfo.type == 1? '个人商户': '企业商户'}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">商户分类：</span>
                            <span>{{baseInfo.merc_type}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">商户名称：</span>
                            <span>{{baseInfo.merc_name}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">法人名称：</span>
                            <span>{{baseInfo.legal_person}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">身份证号：</span>
                            <span>{{ _.isNull(baseInfo.id_card_num)? '--' : baseInfo.id_card_num}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">证件有效期：</span>
                            <span>{{ _.isNull(baseInfo.id_card_validity_peroid)? '--' : baseInfo.id_card_validity_peroid}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">营业证件编号：</span>
                            <span>{{baseInfo.bus_license_no}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">营业执照有效期：</span>
                            <span>{{ _.isNull(baseInfo.bus_license_validity_peroid)? '--' : baseInfo.bus_license_validity_peroid}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">经营范围：</span>
                            <span>{{ _.isNull(baseInfo.mcc_title)? '--' : baseInfo.mcc_title}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">省市区：</span>
                            <span>{{baseInfo.prov_city_area}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">地址：</span>
                            <span>{{ _.isNull(baseInfo.addr_detail)? '--' : baseInfo.addr_detail}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">是否支持预授权：</span>
                            <span>{{baseInfo.is_support_authorize}}</span>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <div class="title">结算信息</div>
            <el-row :gutter="20">
                <el-col :sm="20" :md="20" :lg="20">
                    <el-row>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">账户类型：</span>
                            <span>{{baseInfo.acc_type}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8" v-if="baseInfo.acc_type == '对私'">
                            <span class="labels">是否法人结算卡：</span>
                            <span>{{baseInfo.is_legal_settle == 'Y'? '是' : '否'}}</span>
                        </el-col>
                        <div style="display:contents" v-if="baseInfo.is_legal_settle == 'N'">
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="labels">持卡人姓名：</span>
                                <span>{{ baseInfo.acc_name}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="labels">结算卡证件号：</span>
                                <span>{{ baseInfo.bankcard_id_card_num}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="labels">手机号：</span>
                                <span>{{ baseInfo.bankcard_phone}}</span>
                            </el-col>
                        </div>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">银行卡账户名称：</span>
                            <span>{{baseInfo.acc_name}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">银行账户：</span>
                            <span>{{baseInfo.acc_num}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">开户行：</span>
                            <span>{{baseInfo.bank_name}}</span>
                        </el-col>
                        <template v-if="baseInfo.acc_type == '对公'">
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="labels">开户支行：</span>
                                <span>{{baseInfo.bank_name_branch}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="labels">开户城市：</span>
                                <span>{{ _.isNull(baseInfo.bank_prov_city)? '--' : baseInfo.bank_prov_city}}</span>
                            </el-col>
                            <el-col :sm="12" :md="12" :lg="8">
                                <span class="labels">联行号：</span>
                                <span>{{ _.isNull(baseInfo.link_no)? '--' : baseInfo.link_no}}</span>
                            </el-col>
                        </template>
                        <el-col :sm="12" :md="12" :lg="8" v-if="baseInfo.acc_type == '对私'">
                            <span class="labels">结算卡有效期：</span>
                            <span>{{baseInfo.bankcard_validity_peroid}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">结算周期：</span>
                            <span>{{ _.isNull(baseInfo.settle_way)? '--' : baseInfo.settle_way}}</span>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <div class="title">资质信息</div>
            <el-row :gutter="20">
                <el-col :sm="12" :md="12" :lg="6">
                    <span class="labels">身份证正面：</span>
                    <span>
                        <el-button
                            type="text"
                            style="padding:0;font-size:12px"
                            @click="viewImage(baseInfo.card_positive_photo)"
                        >点击查看</el-button>
                    </span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="6">
                    <span class="labels">身份证反面：</span>
                    <span>
                        <el-button
                            type="text"
                            style="padding:0;font-size:12px"
                            @click="viewImage(baseInfo.card_negative_photo)"
                        >点击查看</el-button>
                    </span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="6">
                    <span class="labels">营业执照：</span>
                    <span>
                        <el-button
                            type="text"
                            style="padding:0;font-size:12px"
                            @click="viewImage(baseInfo.img_buslicense)"
                        >点击查看</el-button>
                    </span>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :sm="12" :md="12" :lg="6">
                    <span class="labels">营业场所：</span>
                    <span>
                        <el-button
                            type="text"
                            style="padding:0;font-size:12px"
                            @click="viewImage(baseInfo.img_business_place)"
                        >点击查看</el-button>
                    </span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="6">
                    <span class="labels">门头照：</span>
                    <span>
                        <el-button
                            type="text"
                            style="padding:0;font-size:12px"
                            @click="viewImage(baseInfo.img_door_photo)"
                        >点击查看</el-button>
                    </span>
                </el-col>
                <el-col :sm="12" :md="12" :lg="6">
                    <span class="labels">业务员与店员合照：</span>
                    <span>
                        <el-button
                            type="text"
                            style="padding:0;font-size:12px"
                            @click="viewImage(baseInfo.img_hand_group)"
                        >点击查看</el-button>
                    </span>
                </el-col>
            </el-row>
            <div class="title">终端信息</div>
            <el-row :gutter="20">
                <el-col :sm="20" :md="20" :lg="20">
                    <el-row>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">终端类型：</span>
                            <span>{{baseInfo.brand_name}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">SN编码：</span>
                            <span>{{baseInfo.terminal_sn}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">SN状态：</span>
                            <span>{{baseInfo.is_active == 1? '已激活': '未激活'}}</span>
                        </el-col>
                        <el-col :sm="12" :md="12" :lg="8">
                            <span class="labels">服务费模板：</span>
                            <span>{{baseInfo.cash_back}}</span>
                        </el-col>
                        <el-col :sm="24" v-if="baseInfo.status == 3">
                            <div class="reason">拒绝原因：{{baseInfo.denial_reason}}</div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <div v-if="baseInfo.merchant_net_method == 1 && baseInfo.status == 1">
                <el-button
                    size="medium"
                    type="primary"
                    icon="el-icon-check"
                    style="font-size: 12px;"
                    @click="handlePass"
                >审核通过</el-button>
                <el-button
                    size="medium"
                    type="danger"
                    icon="el-icon-close"
                    style="font-size: 12px;"
                    @click="handleRefuse"
                >审核拒绝</el-button>
            </div>
        </el-card>
        <!-- 查看图片 -->
        <image-viwe v-if="picDialog" :onClose="handleCloseImgView" :urlList="[picUrl]"></image-viwe>
    </div>
</template>

<script>
import imageViwe from '../../../node_modules/element-ui/packages/image/src/image-viewer';

export default {
    name: "merchant-net-info",
    data() {
        return {
            picDialog: false, //查看图片
            picUrl: '',
            dialogImageUrl: '', //弹窗图片地址
            baseInfo: {},
            merchanttype: '',

        }
    },
    components: {
        imageViwe
    },
    mounted() {
        this.merchanttype = this.$route.query.cerchant_type; //取商户类型
        this.$api.post('merchant_net/getInfo', { id: this.$route.query.id, cerchant_type: this.$route.query.cerchant_type }, res => { //初始化
            let data = res.data;
            if (res.code == 0) {
                this.baseInfo = data;
            } else {
                this.$message.error(res.msg);
            }
        })
    },
    methods: {
        handlePass() { //审核通过
            this.$confirm('是否确认通过？', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false,
                beforeClose: (action, instance, done) => {
                    if (action == 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post('merchant_net/checkMerchantNet', {
                            id: this.baseInfo.id,
                            status: 2,
                        }, res => {
                            if (res.code == 0) {
                                this.$message.success(res.msg);
                                instance.confirmButtonLoading = false;
                                done();
                                this.$router.push('merchantNet');
                            } else {
                                this.$message.error(res.msg);
                                instance.confirmButtonLoading = false;
                            }
                        });
                    } else
                        if (action == 'cancel') {
                            done();
                        }
                }
            }).then(() => {

            }).catch(() => { });
        },
        handleRefuse() { //审核拒绝
            this.$prompt('', '拒绝原因', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                inputPlaceholder: '拒绝原因',
                inputErrorMessage: '内容不能为空',
                inputPattern: /[\S]+/,
                inputType: 'textarea',
                customClass: 'refuseMessageBox',
                closeOnClickModal: false,
                beforeClose: (action, instance, done) => {
                    if (action == 'confirm') {
                        let denial_reason = instance.inputValue;
                        instance.confirmButtonLoading = true;
                        this.$api.post('merchant_net/checkMerchantNet', {
                            id: this.baseInfo.id,
                            status: 3,
                            denial_reason
                        }, res => {
                            if (res.code == 0) {
                                this.$message.success(res.msg);
                                instance.confirmButtonLoading = false;
                                done();
                                this.$router.push('merchantNet');
                            } else {
                                this.$message.error(res.msg);
                                instance.confirmButtonLoading = false;
                            }
                        });
                    } else
                        if (action == 'cancel') {
                            done();
                        }
                }
            }).then(() => {

            }).catch(() => { });
        },
        viewImage(picUrl) { //查看图片
            if (picUrl != '') {
                this.picUrl = picUrl;
                this.picDialog = true;
            } else {
                this.$message.error('没有可以预览的图片哦！');
            }
        },
        handleCloseImgView() { //关闭图片
            this.picDialog = false;
            this.picUrl = '';
        },
    }
}
</script>

<style scoped lang="scss">
.merchantInfo {
    font-size: 12px;
    // ::v-deep .el-form-item__label {
    //     font-size: 12px;
    //     font-weight: 700;
    // }
    // ::v-deep .el-input__inner {
    //     width: 150px;
    // }
    .labels {
        font-weight: 700;
    }
    .title {
        margin: 10px 0;
        font-size: 13px;
        font-weight: 700;
        border-left: 5px solid #40b8ff;
        padding: 0 0 0 10px;
        .el-button--text {
            color: #303133;
            font-size: 13px;
            font-weight: 700;
        }
    }
    .el-row {
        margin: 10px 0;
        &:last-child {
            margin-bottom: 0;
        }
        .el-col {
            margin: 10px 0;
        }
    }
    // 滚动条
    ::v-deep
        .el-table--scrollable-y
        .el-table__body-wrapper::-webkit-scrollbar {
        width: 4px;
    }
    ::v-deep
        .el-table--scrollable-y
        .el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }
    ::v-deep
        .el-table--scrollable-y
        .el-table__body-wrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }
    .reason {
        height: 80px;
        border: solid 1px #999;
        border-radius: 5px;
        padding: 5px;
    }
}
</style>