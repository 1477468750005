<template>
    <div class="previewPic">
        <!-- 查看图片 -->
        <image-viwe :onClose="handleCloseImgView" :urlList="previewImages"></image-viwe>
    </div>
</template>

<script>
import imageViwe from '../../node_modules/element-ui/packages/image/src/image-viewer';

export default {
    name: "previewPic",
    props: {
        picInfo: {
            type: Object
        }
    },
    data() {
        return {
        }
    },
    components: {
        imageViwe
    },
    mounted() {
    },
    computed: {
        previewImages() {
            if (Array.isArray(this.picInfo.imgUrl)) {
                return this.picInfo.imgUrl;
            } else {
                return [this.picInfo.imgUrl];
            }
        }
    },
    methods: {
        viewImage(picUrl) { //查看图片
            this.picUrl = picUrl;
            this.picDialog = true;
        },
        handleCloseImgView() { //关闭图片
            this.$emit('handleCloseImgView');
        }
    }
}
</script>

<style scoped lang="scss">
</style>