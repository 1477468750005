<template>
    <el-dialog
        title="核销详情"
        width="85%"
        custom-class="offsetRecordInfo"
        append-to-body
        :visible="recordInfo.isShow"
        :close-on-click-modal="false"
        @close="handleClose"
    >
        <!-- 表格部分 -->
        <el-card class="box-card" style="margin-top: 20px;" shadow="always">
            <div class="info-title">
                <div class="view-title">
                    <span>核销订单号：</span>
                    <span>{{order_num}}</span>
                </div>
                <div class="view-title">
                    <span>核销总金额：</span>
                    <span>{{Number(write_offs_money).toFixed(2)}}</span>
                </div>
                <div class="view-title">
                    <span>核销时间：</span>
                    <span>{{create_time}}</span>
                </div>
                <div class="table-title">核销贷款订单组成：</div>
            </div>
            <el-table
                :data="recordInfoList"
                size="mini"
                border
                max-height="300px"
                style="width: 100%"
                v-loading="loading"
            >
                <!-- 订单号 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="installment_order_num"
                    label="订单号"
                    min-width="250"
                ></el-table-column>
                <!-- 欠款金额-订单核销 -->
                <el-table-column
                    v-if="recordInfo.info.type == 1"
                    header-align="center"
                    align="center"
                    prop="loan_money"
                    label="欠款金额"
                    min-width="120"
                ></el-table-column>
                <!-- 还款期数 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="period_title"
                    label="还款期数"
                    min-width="120"
                ></el-table-column>
                <!-- 每期应还-订单核销 -->
                <el-table-column
                    v-if="recordInfo.info.type == 1"
                    header-align="center"
                    align="center"
                    prop="every_period_money"
                    label="每期应还"
                    min-width="120"
                ></el-table-column>
                <!-- 已还金额-订单核销 -->
                <el-table-column
                    v-if="recordInfo.info.type == 1"
                    header-align="center"
                    align="center"
                    prop="completed_money"
                    label="已还金额"
                    min-width="120"
                ></el-table-column>
                <!-- 应还金额 -->
                <el-table-column
                    v-if="recordInfo.info.type == 2"
                    header-align="center"
                    align="center"
                    prop="every_period_money"
                    label="应还金额"
                    min-width="120"
                ></el-table-column>
                <!-- 逾期金额-订单核销 -->
                <!-- <el-table-column
                    v-if="recordInfo.info.type == 1"
                    header-align="center"
                    align="center"
                    prop="overdue_money"
                    label="逾期金额"
                    min-width="120"
                ></el-table-column>-->
                <!-- 核销金额 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="write_offs_money"
                    label="核销金额"
                    min-width="120"
                ></el-table-column>
                <!-- 贷款日期 -->
                <el-table-column
                    header-align="center"
                    align="center"
                    prop="loan_time"
                    label="贷款日期"
                    min-width="150"
                ></el-table-column>
            </el-table>
        </el-card>
    </el-dialog>
</template>

<script>
export default {
    name: "offsetRecordInfo",
    props: {
        recordInfo: {
            type: Object
        }
    },
    data() {
        return {
            order_num: '', //核销订单号
            write_offs_money: '', //核销总金额
            create_time: '', //核销时间
            recordInfoList: [], //列表
            loading: false, //列表loadding
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        handleClose() { //关闭
            this.$emit('handleClose');
        },
        getData() { //获取数据
            this.loading = true;
            this.$api.post("user_installment_loan/get_debt_write_offs_detail", {
                id: this.recordInfo.info.id,
                user_id: this.recordInfo.info.user_id,
            }, res => {
                if (res.code == 0) {
                    let data = res.data;
                    this.order_num = data.order_num;
                    this.create_time = data.create_time;
                    this.write_offs_money = data.write_offs_money;
                    this.recordInfoList = data.side_log;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped lang="scss">
.offsetRecordInfo {
    .info-title {
        margin-bottom: 15px;
        .view-title {
            font-size: 12px;
            margin: 0px 10px;
            color: #606266;
            display: inline-block;
            :first-child {
                font-weight: 700;
            }
        }
        .table-title {
            font-weight: 700;
            font-size: 12px;
            margin: 10px 10px;
            color: #606266;
        }
    }
}
</style>