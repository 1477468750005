<template>
    <div class="offsetPage">
        <el-dialog
            title="核销"
            width="90%"
            :visible="offsetMoney.isShow"
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <!-- 表格部分 -->
            <el-card class="box-card" style="margin-top: 20px;" shadow="always">
                <el-form label-position="top" label-width="100px">
                    <el-form-item label="选择核销类型：">
                        <el-radio-group v-model="submitForm.type" @change="handleGroupChange">
                            <el-radio :label="1">订单核销</el-radio>
                            <el-radio :label="2">本期核销</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <template v-if="submitForm.type == 1">
                        <el-form-item label="选择贷款订单："></el-form-item>
                        <el-table
                            :data="offsetList"
                            size="mini"
                            border
                            max-height="400px"
                            style="width: 100%"
                            v-loading="loading"
                            @selection-change="handleSelectionChange"
                        >
                            <el-table-column type="selection" width="55"></el-table-column>
                            <!-- 订单号 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="order_num"
                                label="订单号"
                                min-width="220"
                            ></el-table-column>
                            <!-- 欠款总额 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="loan_money"
                                label="欠款总额"
                                min-width="120"
                            ></el-table-column>
                            <!-- 还款期数 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="type"
                                label="还款期数"
                                min-width="120"
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.lave_period}}/{{scope.row.loan_period}}</span>
                                </template>
                            </el-table-column>
                            <!-- 每期应还 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="every_period_money"
                                label="每期应还"
                                min-width="120"
                            ></el-table-column>
                            <!-- 已还金额 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="completed_money"
                                label="已还金额"
                                min-width="150"
                            ></el-table-column>
                            <!-- 逾期金额 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="overdue_money"
                                label="逾期金额"
                                min-width="150"
                            ></el-table-column>
                            <!-- 剩余欠款 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                prop="lave_money"
                                label="剩余欠款"
                                min-width="150"
                            ></el-table-column>
                            <!-- 贷款日期 -->
                            <el-table-column
                                header-align="center"
                                align="center"
                                fixed="right"
                                prop="create_time"
                                label="贷款日期"
                                min-width="150"
                            ></el-table-column>
                        </el-table>
                    </template>
                    <!-- <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,50,100]"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    :total="total"
                    layout="total, sizes, prev, pager, next, jumper"
                    ></el-pagination>-->
                    <el-form-item label="核销须知：">
                        <div v-show="submitForm.type == 2">
                            <span class="rm-label">下级本期应还：</span>
                            <span class="rm-word-aux">{{Number(submitForm.money).toFixed(2)}}</span>
                        </div>
                        <div>
                            <span class="rm-label">核销总金额：</span>
                            <span class="rm-word-aux">{{Number(submitForm.money).toFixed(2)}}</span>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            :disabled="submitForm.type == 2 && current_money == 0"
                            size="medium"
                            type="primary"
                            style="font-size: 12px;"
                            @click="handleSubmit(submitForm)"
                        >确认核销</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "offsetPage",
    props: {
        offsetMoney: {
            type: Object
        }
    },
    data() {
        return {
            submitForm: {
                type: 1, //1.订单核销 2.本期核销
                user_id: '', //代理商代理
                money: 0, //核销金额
            },
            loan_ids: [], //货款id 
            current_money: 0,
            offsetList: [], //列表
            loading: false, //列表loadding
            // currentPage: 1, //当前页码
            // pageSize: 10, //条数
            // total: 0,  //总数
        }
    },
    mounted() {
        this.submitForm.user_id = this.offsetMoney.info.borrow_user_id; //
        this.getData();
    },
    methods: {
        handleClose(ref) { //关闭
            this.$emit('handleClose', ref);
        },
        handleSelectionChange(row) { //表格多选
            this.loan_ids = [];
            let count = 0;
            if (row.length != 0) {
                this._.forEach(row, item => {
                    this.loan_ids.push(item.id);
                    count = count + Number(item.lave_money);
                })
            }
            this.submitForm.money = count;
        },
        handleGroupChange(val) { //类型
            this.loan_ids = [];
            this.submitForm.loan_ids = [];
            if (val == 1) {
                this.submitForm.money = 0;
            } else {
                this.submitForm.money = this.current_money;
            }
        },
        handleSubmit(submitForm) { //提交
            let _this = this;
            if (submitForm.type == 1) {
                if (this.loan_ids != 0) {
                    submitForm.loan_ids = JSON.stringify(this.loan_ids);
                } else {
                    this.btnLoading = false;
                    this.$message.error('请选择您想要核销的订单 ！')
                    return false;
                }
            }
            this.$confirm('是否确认核销已选择的订单？', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnPressEscape: false,
                beforeClose: (action, instance, done) => {
                    if (action == 'confirm') {
                        instance.confirmButtonLoading = true;
                        this.$api.post("user_installment_loan/debt_write_offs", submitForm, res => {
                            if (res.code == 0) {
                                this.$message({
                                    type: 'success',
                                    duration: 1500,
                                    message: res.msg,
                                    onClose() {
                                        instance.confirmButtonLoading = false;
                                        done();
                                        _this.handleClose(1);
                                    }
                                });
                            } else {
                                this.$message.error(res.msg);
                                instance.confirmButtonLoading = false;
                                done();
                            }
                        });
                    } else {
                        instance.confirmButtonLoading = false;
                        done();
                    }
                }
            }).then(() => {

            }).catch(() => { });
        },
        //分页
        // handleCurrentChange(page) { //当前选中页
        //     this.currentPage = page;
        //     this.getData({
        //         page: this.currentPage,
        //         limit: this.pageSize
        //     });
        // },
        // handleSizeChange(limit) { //条数
        //     this.currentPage = 1;
        //     this.pageSize = limit;
        //     this.getData({
        //         page: this.currentPage,
        //         limit: this.pageSize
        //     });
        // },
        getData(params) { //获取数据
            this.loading = true;
            let reqData = this._.assign({
                page: 1,
                limit: 10,
                user_id: this.offsetMoney.info.borrow_user_id
            }, params);
            this.$api.post("user_installment_loan/get_debt_write_offs_loan_list", reqData, res => {
                if (res.code == 0) {
                    this.offsetList = res.data.list;
                    this.current_money = res.data.current_money;
                } else {
                    this.$message.error(res.msg);
                }
                //渲染结束后取消加载
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped lang="scss">
</style>