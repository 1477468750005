
<template>
    <div class="big-data" ref="dataCenter">
        <div class="temp-big-data" v-loading="loading">
            <!-- <el-collapse-transition>
                <div class="top-titleBox">
                    <div class="top-title">
                        <span>{{topTitle}}数据展示</span>
                    </div>
                </div>
            </el-collapse-transition>-->
            <div class="fullScreen">
                <img
                    v-show="full"
                    src="../../assets/images/quanping.png"
                    @click.prevent="handleFullScreen"
                />
                <img
                    v-show="full != 1"
                    src="../../assets/images/feiquanping.png"
                    @click.prevent="handleExitScreen"
                />
            </div>
            <el-row :gutter="8">
                <el-col :xs="24" :sm="24" :md="24" :lg="5" :xl="6">
                    <el-card class="top-card box-card" style="margin-bottom:10px;min-width:215px">
                        <div class="top-around">
                            <div class="top-around-icon">
                                <img src="../../assets/images/totalActive.png" alt />
                            </div>
                            <div class="top-around-content">
                                <div class="top-around-title">总激活终端(台)</div>
                                <div class="top-around-data">{{topData.all_active_terminal}}</div>
                            </div>
                        </div>
                    </el-card>
                    <el-card class="top-card box-card" style="min-width:215px">
                        <div class="top-around">
                            <div class="top-around-icon">
                                <img src="../../assets/images/todayActive.png" alt />
                            </div>
                            <div class="top-around-content">
                                <div class="top-around-title">今日激活终端(台)</div>
                                <div class="top-around-data">{{topData.today_active_terminal}}</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="12">
                    <el-card class="box-card" style="height:210px">
                        <div class="rolling-num">
                            <div class="rolling-title">
                                <div class="title-l">
                                    <img src="../../assets/images/indexL.png" alt />
                                </div>
                                <div class="title-m">今日交易额(元)</div>
                                <div class="title-r">
                                    <img src="../../assets/images/indexR.png" alt />
                                </div>
                            </div>
                            <counter :counts="topData.today_transaction"></counter>
                        </div>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="5" :xl="6">
                    <el-card class="top-card box-card" style="margin-bottom:10px;min-width:215px">
                        <div class="top-around">
                            <div class="top-around-icon">
                                <img src="../../assets/images/totalPerson.png" alt />
                            </div>
                            <div class="top-around-content">
                                <div class="top-around-title">总人数(人)</div>
                                <div class="top-around-data">{{topData.all_user}}</div>
                            </div>
                        </div>
                    </el-card>
                    <el-card class="top-card box-card" style="min-width:215px">
                        <div class="top-around">
                            <div class="top-around-icon">
                                <img src="../../assets/images/todayPerson.png" alt />
                            </div>
                            <div class="top-around-content">
                                <div class="top-around-title">今日新增(人)</div>
                                <div class="top-around-data">{{topData.today_user}}</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="8">
                <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                    <el-card class="box-card">
                        <div ref="barChart" style="width:100%;height:35vh"></div>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                    <el-card class="box-card">
                        <div ref="pieChart" style="width:100%;height:35vh"></div>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="8">
                <el-col :span="24">
                    <el-card class="box-card">
                        <div ref="lineChart" style="width:100%;height:30vh"></div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>


<script>
import counter from './components/counter';
export default {
    name: 'home',
    data() {
        return {
            full: 1, //1全屏
            topTitle: '', //顶部titile
            common: {
                name: '交易',
                date: '',
                mark: 1, //1交易 2激活
            },
            customTimer: null,
            loading: false,
            topData: { //顶部数据
                all_active_terminal: 0, //总激活
                today_active_terminal: 0, //今日激活
                today_transaction: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", ".", "0", "0"], //今日交易额
                all_user: 0, //总人数
                today_user: 0, //今日新增人数
            },
            barChartXaxis: [], //柱状图xAxis
            barChartActive: [], //柱状图激活
            barChartTrans: [], //柱状图交易
            pieData: [], //饼图数据
            lineDays: [], //折线日期
            lineValue: [], //折线数据
        }
    },
    components: {
        counter
    },
    mounted() {
        this.topTitle = sessionStorage.getItem('title'); //获取顶部title
        this.handleBarInit();//柱状图实例化
        this.handlePieInit(); //饼形图
        this.handleLineInit(); //折线图
        this.chartInit(); //初始化
        this.handleReload(); //定时器
        window.onresize = () => { //动态改变图表尺寸
            this.lineChart.resize();
            this.barChart.resize();
            this.pieChart.resize();
        }
    },
    methods: {
        handleFullScreen() { //全屏
            this.full = 0;
            let ele = this.$refs.dataCenter,
                reqFullScreen = ele.requestFullScreen || ele.webkitRequestFullScreen
                    || ele.mozRequestFullScreen || ele.msRequestFullscreen;
            if (typeof reqFullScreen !== 'undefined' && reqFullScreen) {
                reqFullScreen.call(ele);
            }
        },
        handleExitScreen() { //取消全屏
            this.full = 1;
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        },
        handleBaseData() { //数据图基础数据
            let _this = this;
            return {
                setBarOption() { //柱状图基础数据
                    let option = {
                        title: {
                            text: `${_this.common.name}统计`,
                        },
                        tooltip: { //提示框
                            trigger: 'axis',
                            axisPointer: {
                                lineStyle: {
                                    type: 'line',
                                    color: "rgba(144,147,153,0.1)"
                                }
                            },
                            formatter: function (params) {
                                let { marker, axisValue, name, value } = params[0];
                                let wordAux = _this.common.mark == 1 ? '万元' : '台';
                                return `${axisValue}</br>${marker}${name}：${value} ${wordAux}`;
                            }
                        },
                        legend: { //图例
                            icon: 'circle',
                            right: '2%',
                            itemWidth: 8,
                            itemHeight: 8,
                            selectedMode: 'single',
                            // selected: {
                            //     '交易额（万元）': _this.common.mark == 1 ? true : false,
                            //     '激活（台）': _this.common.mark == 1 ? false : true
                            // }
                        },
                        grid: {
                            left: "5%",
                            top: 80,
                            right: "5%",
                            bottom: 10,
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            data: _this.barChartXaxis
                        },
                        yAxis: {
                            axisLine: {
                                show: false,
                            },
                            axisTick: {
                                show: false
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "rgba(231, 235, 239, 1)"
                                }
                            }
                        },
                        series: [
                            {
                                type: 'bar',
                                name: '交易额（万元）',
                                data: _this.barChartTrans, //交易
                            },
                            {
                                type: 'bar',
                                name: '激活（台）',
                                data: _this.barChartActive, //激活
                            }
                        ],
                        color: ['#4F9FFF', '#FF704F']
                    }
                    _this.barChart.setOption(option);
                },
                setPieOption() { //饼图
                    let option = {
                        title: {
                            text: _this.common.name + '占比',
                        },
                        tooltip: {
                            trigger: 'item',
                            formatter: function (p) {
                                let unit = _this.common.mark == 1 ? '万元' : '台';
                                return `${p.name}</br>${p.marker}${p.value} ${unit}(${p.percent}%)`;
                            }
                        },
                        grid: {
                            top: '0',
                            bottom: '20%',
                            containLabel: true
                        },
                        legend: {
                            icon: 'circle',
                            left: 'center',
                            type: 'scroll',
                            pageIconSize: '10',
                            bottom: 0,
                            itemGap: 20,
                            itemWidth: 8,
                            itemHeight: 15
                        },
                        series: [
                            {
                                type: 'pie',
                                radius: '55%',
                                center: ['50%', '50%'],
                                selectedMode: 'single',
                                data: _this.pieData,
                                itemStyle: {
                                    // normal: {
                                    //     label: {
                                    //         show: false
                                    //     },
                                    //     labelLine: {
                                    //         show: false
                                    //     }
                                    // }
                                },
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ],
                        color: ['#4ECB73', '#FBD437', '#F2637B', '#36CBCB', '#3AA1FF', '#4E7BF5', '#11C9E8', '#16E5E6', '#FF9F43', '#EE5353', '#F368E0', '#5F27CD', '#576573', '#736A57', '#6FDD55'],
                    }
                    // 绘制图表
                    _this.pieChart.setOption(option);
                },
                setLineOption() { //折线图
                    let option = {
                        title: {
                            text: _this.common.name + '走势',
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                lineStyle: {
                                    type: 'line',
                                    color: "rgba(144,147,153,0.3)"
                                }
                            },
                            formatter: function (p) {
                                let unit = _this.common.mark == 1 ? '万元' : '台';
                                let v = p[0];
                                return `${v.name}</br>${v.marker}${_this.common.name}：${v.value} ${unit}`;
                            }
                        },
                        grid: {
                            left: "5%",
                            top: 60,
                            right: "5%",
                            bottom: 30,
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: _this.lineDays
                        },
                        yAxis: {
                            axisLine: {
                                show: false,
                            },
                            axisTick: {
                                show: false
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "rgba(231, 235, 239, 1)"
                                }
                            }
                        },
                        series: [{
                            type: 'line',
                            smooth: true,
                            data: _this.lineValue,
                            symbol: 'circle',
                            symbolSize: '6',
                            itemStyle: {
                                normal: {
                                    color: "rgba(79, 154, 255, 1)",
                                    lineStyle: {
                                        color: "rgba(79, 154, 255, 0.8)"
                                    }
                                }
                            },
                            areaStyle: {
                                color: new _this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(79, 154, 255, 0.3)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(79, 154, 255, 0.1)'
                                }])
                            }
                        }],
                        // color: ['rgba(79, 154, 255, 0.1)']
                    }
                    _this.lineChart.setOption(option);
                }
            }
        },
        handleReload() { //轮询
            this.customTimer = setInterval(() => {
                this.chartInit();
            }, 60000);
        },
        handleBarInit() { //柱状图
            let _this = this;
            // 基于准备好的dom，初始化echarts实例
            this.barChart = this.$echarts.init(this.$refs.barChart);
            // 绘制图表
            this.handleBaseData().setBarOption();
            //先解绑事件再注册，否则会注册多次事件。
            this.barChart.getZr().off('click');
            this.barChart.off('legendselectchanged');
            this.barChart.getZr().on('click', function (params) {
                var pointInPixel = [params.offsetX, params.offsetY]
                if (_this.barChart.containPixel('grid', pointInPixel)) {
                    var xIndex = _this.barChart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0];
                    if (_this.common.mark == 2) {
                        let { date, data_type } = _this.barChartActive[xIndex];
                        _this.common.date = date; //记录当前点击的日期
                        _this.getPie({ date, data_type }).then(() => { //饼图
                            _this.handleBaseData().setPieOption(); //饼形图 
                        })
                        _this.getLine({ date, data_type }).then(() => { //折线
                            _this.handleBaseData().setLineOption(); //折线图
                        })
                    } else {
                        let { date, data_type } = _this.barChartTrans[xIndex];
                        _this.common.date = date; //记录当前点击的日期
                        _this.getPie({ date, data_type }).then(() => { //饼图
                            _this.handleBaseData().setPieOption(); //饼形图 
                        })
                        _this.getLine({ date, data_type }).then(() => { //折线
                            _this.handleBaseData().setLineOption(); //折线图
                        })
                    }
                }
            });
            this.barChart.on('legendselectchanged', function (params) {
                // 交易额（万元）
                // 激活（台）
                _this.common.mark = params.name === '激活（台）' ? 2 : 1;
                if (params.name === '激活（台）') { //激活
                    _this.getBar({
                        data_type: 2
                    }).then(() => {
                        _this.common.name = '激活';
                        _this.handleBaseData().setBarOption();
                        _this.getPie({ date: _this.common.date, data_type: 2 }).then(() => { //饼图
                            _this.handleBaseData().setPieOption(); //饼形图 
                        })
                        _this.getLine({ date: _this.common.date, data_type: 2 }).then(() => { //折线
                            _this.handleBaseData().setLineOption(); //折线图
                        })
                    })
                } else { //交易
                    _this.getBar({
                        data_type: 1
                    }).then(() => {
                        _this.common.name = '交易';
                        _this.handleBaseData().setBarOption();
                        _this.getPie({ date: _this.common.date, data_type: 1 }).then(() => { //饼图
                            _this.handleBaseData().setPieOption(); //饼形图 
                        })
                        _this.getLine({ date: _this.common.date, data_type: 1 }).then(() => { //折线
                            _this.handleBaseData().setLineOption(); //折线图
                        })
                    })
                }
            })
        },
        handlePieInit() { //饼状图
            // 初始化echarts实例
            this.pieChart = this.$echarts.init(this.$refs.pieChart);
            //绘制图表
            this.handleBaseData().setPieOption();
        },
        handleLineInit() { //折线图
            // 基于准备好的dom，初始化echarts实例
            this.lineChart = this.$echarts.init(this.$refs.lineChart);
            //绘制图表
            this.handleBaseData().setLineOption();
        },
        getBar(params) { //获取柱状图数据
            return new Promise(resolve => {
                this.$api.post('screen/get_screen_middle_left', params, res => {
                    let data = res.data;
                    if (res.code == 0) {
                        this.barChartXaxis = data.month;
                        if (params.data_type == 1) { //交易
                            this.barChartTrans = data.transaction;
                        } else { //激活
                            this.barChartActive = data.active;
                        }
                        resolve(res.data);
                    }
                })
            })
        },
        getPie(params) { //获取饼图数据
            return new Promise((resolve) => {
                this.$api.post('screen/get_screen_middle_right', params, res => {
                    let data = res.data;
                    if (res.code == 0) {
                        this.pieData = data;
                        resolve(res);
                    }
                })
            })
        },
        getLine(params) { //获取折线图数据
            return new Promise((resolve) => {
                this.$api.post('screen/get_screen_bottom', params, res => {
                    let data = res.data;
                    if (res.code == 0) {
                        this.lineDays = data.day_list;
                        this.lineValue = data.value;
                        resolve(res);
                    }
                })
            })
        },
        getTopInfo() { //获取顶部数据
            return new Promise((resolve) => {
                this.$api.post('screen/get_screen_top', {}, res => {
                    let data = res.data;
                    if (res.code == 0) {
                        this.topData = data;
                        resolve();
                    }
                })
            })
        },
        chartInit() { //初始化数据
            const TLREQ = [this.getBar({ data_type: 1 }), this.getBar({ data_type: 2 }), this.getTopInfo()];
            Promise.all(TLREQ).then(() => {
                Promise.all([this.getPie({ date: this.common.date, data_type: this.common.mark }), this.getLine({ date: this.common.date, data_type: this.common.mark })]).then(() => {
                    this.loading = false;
                    this.handleBaseData().setBarOption();//柱状图实例化
                    this.handleBaseData().setPieOption(); //饼形图
                    this.handleBaseData().setLineOption(); //折线图
                })
            })
        }
    },
    destroyed() { //销毁定时器
        clearInterval(this.customTimer);
    }
}
</script>

<style scoped lang="scss">
.top-card {
    height: 100px;
}
.temp-big-data {
    position: relative;
    padding: 10px;
}
.fullScreen {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
    }
}
.box-card {
    border: none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
}

.top-titleBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    .top-title {
        position: relative;
        width: 300px;
        height: 50px;
        color: #ffffff;
        font-size: 20px;
        text-align: center;
        line-height: 50px;
        span {
            position: absolute;
            left: 0;
            width: 100%;
            line-height: 50px;
            z-index: 9999;
        }
    }
    .top-title:before {
        content: "";
        position: absolute;
        top: 5px;
        left: -50px;
        right: -50px;
        bottom: 0;
        z-index: 1;
        background-color: #3ba1ff;
        transform-origin: bottom;
        transform: perspective(100px) rotateX(-30deg);
    }
}
.big-data {
    background-color: #ffffff;
}
.el-row {
    .el-col {
        margin-bottom: 10px;
    }
}
.rolling-num {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    .rolling-title {
        // position: relative;
        display: flex;
        color: #545454;
        font-size: 18px;
        white-space: nowrap;
        .title-l {
            text-align: right;
        }
        .title-m {
            padding: 0 10px;
        }
        .title-r {
            text-align: left;
        }
        img {
            height: 40%;
            vertical-align: middle;
        }
        padding: 24px 0px;
    }
}
.top-around {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .top-around-icon {
        display: inline-block;
        width: 46px;
        height: 46px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .top-around-content {
        display: inline-block;
        margin-left: 10px;
        font-family: "Helvetica Neue", Helvetica, "PingFang SC",
            "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        .top-around-title {
            color: #8b8b8b;
            font-size: 15px;
            white-space: nowrap;
        }
        .top-around-data {
            color: #606266;
            margin-top: 5px;
            font-size: 30px;
            font-weight: 700;
        }
    }
}
</style>